import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import type {
  DraftEditorState,
  TrackedStateChange,
} from "@core/features/editor/editorSlice/editorSlice";
import { Layer, Layers } from "@folds/shared/types";

/**
 * Get the updated version of previous changes
 */
export const getCurrentChanges = (
  newLayers: Layers,
  trackedChanges: TrackedStateChange["changes"]
) => {
  const currentChanges = Object.entries(trackedChanges).reduce(
    (acc, [layerId, changes]) => {
      if (changes === undefined) {
        const layer = newLayers[layerId];

        acc[layerId] = layer;

        return acc;
      }

      const newChangesEntries = Object.entries(changes).map(
        ([key, originalValue]) => {
          const layer = newLayers[layerId];

          if (layer === undefined || !(key in layer)) {
            return [key, originalValue];
          }

          const updatedValue = layer[key as keyof Layer];

          return [key, updatedValue];
        }
      );

      const newChanges = Object.fromEntries(newChangesEntries);

      acc[layerId] = newChanges;

      return acc;
    },
    {} as Record<string, Partial<Layer> | undefined>
  );

  return currentChanges;
};

/**
 * Applies a tracked state change to the current state
 */
export const applyTrackedState = (
  state: DraftEditorState,
  trackedState: TrackedStateChange
) => {
  Object.entries(trackedState.changes).forEach(([layerId, changes]) => {
    // The layer was added
    if (changes === undefined) {
      delete state.layers[layerId];
      return;
    }

    const targetLayer = state.layers[layerId];

    // The layer was deleted
    if (targetLayer === undefined) {
      Object.assign(state.layers, {
        [layerId]: changes,
      });

      return;
    }

    Object.assign(targetLayer, changes);
  });

  setSelectedLayerIdsAction(state, trackedState.selectedLayerIds);
};
