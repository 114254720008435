import { useProductImages } from "@/features/sidebar/api/getProductImages";
import { useEventConnector } from "@core/events";
import { useProductImagesCarouselProductId } from "@core/user-components/ProductImageCarousel/Root";
import { SwiperCarousel } from "@core/user-components/SwiperCarousel";
import {
  generateProductImageCarouselThumbnailStyles,
  generateProductImageCarouselThumbnailsStyles,
} from "@folds/shared/layers";
import {
  ProductImageCarouselThumbnailLayer,
  ProductImageCarouselThumbnailsLayer,
} from "@folds/shared/types";
import { MouseEvent, PropsWithChildren } from "react";

function ThumbnailImage({
  layer,
  src,
  onMouseDown,
  onMouseOver,
  id,
}: {
  layer: ProductImageCarouselThumbnailLayer;
  src: string;
  onMouseDown: (event: MouseEvent) => void;
  onMouseOver: (event: MouseEvent) => void;
  id: string | undefined;
}) {
  const style = generateProductImageCarouselThumbnailStyles(layer);

  return (
    <swiper-slide
      id={id}
      className="swiper-slide"
      aria-label="Product images thumbnail"
      style={{
        width: layer.width,
        height: layer.height,
      }}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
    >
      <img
        src={src}
        style={{ ...style, userSelect: "none" }}
        alt=""
        draggable={false}
      />
    </swiper-slide>
  );
}

export function ProductImageCarouselThumbnail({
  layer,
}: {
  layer: ProductImageCarouselThumbnailLayer;
}) {
  const { handleMouseDown, handleMouseOver } = useEventConnector(layer.id);

  const productId = useProductImagesCarouselProductId();
  const { data: productImages } = useProductImages(productId);

  if (productImages === undefined || productImages.length === 0)
    return (
      <ThumbnailImage
        id={layer.id}
        layer={layer}
        onMouseOver={handleMouseOver}
        onMouseDown={handleMouseDown}
        src="/image-placeholder.svg"
      />
    );

  const thumbnails = productImages.map((src, index) => {
    if (index === 0) {
      return (
        <ThumbnailImage
          id={layer.id}
          key={src}
          src={src}
          layer={layer}
          onMouseDown={handleMouseDown}
          onMouseOver={handleMouseOver}
        />
      );
    }

    return (
      <ThumbnailImage
        id={undefined}
        key={src}
        src={src}
        layer={layer}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      />
    );
  });

  return thumbnails;
}

export function ProductImageCarouselThumbnails({
  layer,
  children,
}: PropsWithChildren<{
  layer: ProductImageCarouselThumbnailsLayer;
}>) {
  const style = generateProductImageCarouselThumbnailsStyles(layer);

  return (
    <SwiperCarousel layer={layer} style={style}>
      {children}
    </SwiperCarousel>
  );
}
