import { usePageId } from "@/features/editor/context/PageId";
import { UpdatePageInformationBody } from "@folds/shared";
import { useAxios } from "@lib/axios";
import { Static, Type } from "@sinclair/typebox";
import useSWR from "swr";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { toast } from "@core/toast";

const fetchPageInformationResultSchema = Type.Union([
  Type.Object({
    type: Type.Literal("home"),
    shopifyId: Type.Null(),
    name: Type.String(),
    publishedAt: Type.Union([Type.Null(), Type.String()]),
  }),
  Type.Object({
    type: Type.Literal("standalone"),
    shopifyId: Type.Union([Type.String(), Type.Null()]),
    name: Type.String(),
    publishedAt: Type.Union([Type.Null(), Type.String()]),
  }),
  Type.Object({
    type: Type.Literal("product"),
    shopifyId: Type.Union([Type.String(), Type.Null()]),
    name: Type.String(),
    publishedAt: Type.Union([Type.Null(), Type.String()]),
  }),
  Type.Object({
    type: Type.Literal("not-found"),
    shopifyId: Type.Null(),
    name: Type.String(),
    publishedAt: Type.Union([Type.Null(), Type.String()]),
  }),
]);

type FetchPageInformationResult = Static<
  typeof fetchPageInformationResultSchema
>;

const compiledFetchPageInformationResultSchema = TypeCompiler.Compile(
  fetchPageInformationResultSchema
);

export type PageInformationWithNumberId =
  | {
      type: "home";
      shopifyId: null;
      name: string;
      publishedAt: string | null;
    }
  | {
      type: "standalone";
      shopifyId: number | null;
      name: string;
      publishedAt: string | null;
    }
  | {
      type: "product";
      shopifyId: number | null;
      name: string;
      publishedAt: string | null;
    }
  | {
      type: "not-found";
      shopifyId: null;
      name: string;
      publishedAt: string | null;
    };

const convertShopifyIdToNumber = (
  result: FetchPageInformationResult
): PageInformationWithNumberId => {
  if (result.type === "home" || result.type === "not-found") {
    return result;
  }

  return {
    ...result,
    shopifyId: result.shopifyId === null ? null : Number(result.shopifyId),
  };
};

export const usePageInformation = () => {
  const authAxios = useAxios();
  const pageId = usePageId();

  const fetchPageInformation = async (key: string) => {
    const result = await authAxios.get(key);

    const pageInformationResult =
      compiledFetchPageInformationResultSchema.Decode(result.data);

    const pageInformationWithNumberId = convertShopifyIdToNumber(
      pageInformationResult
    );

    return pageInformationWithNumberId;
  };

  return useSWR(`/pages/${pageId}/information`, fetchPageInformation);
};

export const useUpdatePageInformation = () => {
  const { mutate } = usePageInformation();
  const authAxios = useAxios();
  const pageId = usePageId();

  const updatePageInformation = async (data: UpdatePageInformationBody) => {
    try {
      toast.loading("Saving changes...", { id: "save-page-informaiton" });

      await authAxios.put(`/pages/${pageId}/information`, data);
      await mutate();

      toast.success("Changes saved", { id: "save-page-informaiton" });
    } catch {
      toast.error("Failed to save changes", {
        id: "save-page-informaiton",
      });
    }
  };

  return updatePageInformation;
};
