import { usePageUrlsSearch } from "@/features/sidebar/api/getPageUrls";
import {
  GoBackButton,
  NoResultsFound,
  SearchURLResult,
  SearchURLResultsError,
  SearchURLResultsLoading,
} from "@/features/sidebar/components/SelectURL/Common";

export function PageUrlsSearch({
  onSelect,
  searchValue,
  onBack,
}: {
  onSelect: (url: string) => void;
  searchValue: string;
  onBack: () => void;
}) {
  const {
    data: searchResults,
    error,
    isLoading,
  } = usePageUrlsSearch(searchValue);

  if (error !== undefined) return <SearchURLResultsError onBack={onBack} />;

  if (isLoading) return <SearchURLResultsLoading onBack={onBack} />;

  if (!searchResults || searchResults.length === 0) return <NoResultsFound />;

  const results = searchResults.map((result) => (
    <SearchURLResult key={result.id} onSelect={onSelect} result={result} />
  ));

  return (
    <div className="flex h-64 flex-col">
      <GoBackButton onClick={onBack} />
      {results}
    </div>
  );
}
