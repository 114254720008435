import {
  alignHorizontalLeft,
  alignHorizontalMiddle,
  alignHorizontalRight,
  alignVerticalBottom,
  alignVerticalMiddle,
  alignVerticalTop,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

function AlignHorizontalLeft() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.466545 0.933561C0.208812 0.933561 -0.000122091 1.1425 -0.000122091 1.40023L-0.000122091 12.6002C-0.000122091 12.858 0.208812 13.0669 0.466545 13.0669C0.724278 13.0669 0.933211 12.858 0.933211 12.6002L0.933211 8.40023L11.2 8.40023C11.7155 8.40023 12.1333 7.98236 12.1333 7.46689L12.1333 6.53356C12.1333 6.01809 11.7155 5.60022 11.2 5.60022L0.933211 5.60023L0.933211 1.40023C0.933211 1.1425 0.724277 0.933561 0.466545 0.933561Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function AlignHorizontalMiddle() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86653 5.59977C1.35106 5.59977 0.933194 6.01764 0.933194 6.53311L0.933194 7.46644C0.933194 7.9819 1.35106 8.39977 1.86653 8.39977L6.53319 8.39977L6.53319 12.5998C6.53319 12.8575 6.74213 13.0664 6.99986 13.0664C7.25759 13.0664 7.46653 12.8575 7.46653 12.5998L7.46653 8.39977L12.1332 8.39977C12.6487 8.39977 13.0665 7.9819 13.0665 7.46644L13.0665 6.53311C13.0665 6.01764 12.6487 5.59977 12.1332 5.59977L7.46653 5.59977L7.46653 1.39977C7.46653 1.14204 7.25759 0.933105 6.99986 0.933105C6.74213 0.933105 6.53319 1.14204 6.53319 1.39977L6.53319 5.59977L1.86653 5.59977Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function AlignHorizontalRight() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86653 5.59977C1.35106 5.59977 0.933194 6.01764 0.933194 6.53311L0.933194 7.46644C0.933194 7.9819 1.35106 8.39977 1.86653 8.39977L6.53319 8.39977L6.53319 12.5998C6.53319 12.8575 6.74213 13.0664 6.99986 13.0664C7.25759 13.0664 7.46653 12.8575 7.46653 12.5998L7.46653 8.39977L12.1332 8.39977C12.6487 8.39977 13.0665 7.9819 13.0665 7.46644L13.0665 6.53311C13.0665 6.01764 12.6487 5.59977 12.1332 5.59977L7.46653 5.59977L7.46653 1.39977C7.46653 1.14204 7.25759 0.933105 6.99986 0.933105C6.74213 0.933105 6.53319 1.14204 6.53319 1.39977L6.53319 5.59977L1.86653 5.59977Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function AlignVerticalTop() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.40002 0C1.14228 0 0.93335 0.208933 0.93335 0.466666C0.93335 0.724399 1.14228 0.933333 1.40002 0.933333H5.60002V11.1333C5.60002 11.6856 6.04773 12.1333 6.60002 12.1333H7.40002C7.9523 12.1333 8.40002 11.6856 8.40002 11.1333V0.933333H12.6C12.8577 0.933333 13.0667 0.724399 13.0667 0.466666C13.0667 0.208933 12.8577 0 12.6 0H8.40002H5.60002H1.40002Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function AlignVerticalMiddle() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60002 0.933105C6.04773 0.933105 5.60002 1.38082 5.60002 1.93311V6.53311H1.40002C1.14228 6.53311 0.93335 6.74204 0.93335 6.99977C0.93335 7.25751 1.14228 7.46644 1.40002 7.46644H5.60002V12.0664C5.60002 12.6187 6.04773 13.0664 6.60002 13.0664H7.40002C7.9523 13.0664 8.40002 12.6187 8.40002 12.0664V7.46644H12.6C12.8577 7.46644 13.0667 7.25751 13.0667 6.99977C13.0667 6.74204 12.8577 6.53311 12.6 6.53311H8.40002V1.93311C8.40002 1.38082 7.9523 0.933106 7.40002 0.933106L6.60002 0.933105Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function AlignVerticalBottom() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40002 2.8667C8.40002 2.31441 7.9523 1.8667 7.40002 1.8667L6.60002 1.8667C6.04773 1.8667 5.60002 2.31441 5.60002 2.8667L5.60002 13.0667H1.40002C1.14228 13.0667 0.93335 13.2756 0.93335 13.5334C0.93335 13.7911 1.14228 14 1.40002 14L5.60002 14H8.40002L12.6 14C12.8577 14 13.0667 13.7911 13.0667 13.5334C13.0667 13.2756 12.8577 13.0667 12.6 13.0667H8.40002L8.40002 2.8667Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function AlignmentButtons() {
  const dispatch = useAppDispatch();

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  if (selectedLayerIds.length < 2) return null;

  const handleAlignHorizontalLeft = () => {
    dispatch(alignHorizontalLeft());
  };

  const handleAlignHorizontalMiddle = () => {
    dispatch(alignHorizontalMiddle());
  };

  const handleAlignHorizontalRight = () => {
    dispatch(alignHorizontalRight());
  };

  const handleAlignVerticalTop = () => {
    dispatch(alignVerticalTop());
  };

  const handleAlignVerticalMiddle = () => {
    dispatch(alignVerticalMiddle());
  };

  const handleAlignVerticalBottom = () => {
    dispatch(alignVerticalBottom());
  };

  return (
    <div className="flex justify-between border-b border-gray-6 p-3">
      <div className="flex gap-1">
        <button
          onClick={handleAlignHorizontalLeft}
          type="button"
          aria-label="Align horizontal left"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignHorizontalLeft />
        </button>
        <button
          onClick={handleAlignHorizontalMiddle}
          type="button"
          aria-label="Align horizontal middle"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignHorizontalMiddle />
        </button>
        <button
          onClick={handleAlignHorizontalRight}
          type="button"
          aria-label="Align horizontal right"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignHorizontalRight />
        </button>
      </div>
      <div className="flex gap-1">
        <button
          onClick={handleAlignVerticalTop}
          type="button"
          aria-label="Align vertical top"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignVerticalTop />
        </button>
        <button
          onClick={handleAlignVerticalMiddle}
          type="button"
          aria-label="Align vertical middle"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignVerticalMiddle />
        </button>
        <button
          onClick={handleAlignVerticalBottom}
          type="button"
          aria-label="Align vertical bottom"
          className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11 hover:fill-gray-12"
        >
          <AlignVerticalBottom />
        </button>
      </div>
    </div>
  );
}
