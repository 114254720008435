import { Search } from "@/features/sidebar/Sidebar.icons";
import { twMerge } from "tailwind-merge";

type Props = {
  value: string;
  onValueChange: (value: string) => void;
  placeholder: string;
} & React.HTMLAttributes<HTMLInputElement>;

export function SearchBar({
  value,
  onValueChange,
  placeholder,
  className,
  ...restOfProps
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value);
  };

  return (
    <div className="relative">
      <input
        type="text"
        id="icons-search"
        className={twMerge(
          "h-9 w-full rounded border border-solid border-gray-6 bg-gray-2 pl-8 pr-2 text-sm",
          className
        )}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      />
      <label
        htmlFor="icons-search"
        className="absolute left-3 top-1/2 -translate-y-1/2"
      >
        <Search />
      </label>
    </div>
  );
}
