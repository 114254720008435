import { useEffect, useState } from "react";
import { store } from "@core/store";
import { twMerge } from "tailwind-merge";
import Lottie from "react-lottie";
import trackpadMoveAround from "./lotties/trackpadMoveAround.json";
import middleMousePress from "./lotties/middleMousePress.json";
import dragMouse from "./lotties/dragMouse.json";

function PlusIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#687076"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrackpadMoveInstructions({
  onSkipTutorial,
  onContinue,
}: {
  onSkipTutorial: () => void;
  onContinue: () => void;
}) {
  const [initialPosition] = useState({
    x: store.getState().editor.translateX,
    y: store.getState().editor.translateY,
  });

  const [hasMoved, setHasMoved] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      const { translateX, translateY } = store.getState().editor;

      if (
        translateX !== initialPosition.x ||
        translateY !== initialPosition.y
      ) {
        setHasMoved(true);
      }
    });
  }, [initialPosition.x, initialPosition.y]);

  return (
    <div
      onMouseDown={(event) => event.stopPropagation()}
      className="absolute bottom-3 left-[calc((100vw_+_326px)_/_2)] z-[999] flex w-[756px] -translate-x-1/2 justify-between gap-8 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
    >
      <div className="flex flex-1 items-center justify-center rounded bg-gray-3">
        <Lottie
          options={{ animationData: trackpadMoveAround }}
          width={125}
          height={125}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-12">Move</h3>
            <button
              className="text-base text-gray-11"
              type="button"
              onClick={onSkipTutorial}
            >
              Skip tutorial
            </button>
          </div>
          <p className="max-w-80 text-base text-gray-12">
            With two fingers touching the trackpad, swipe in any direction
          </p>
        </div>
        <button
          type="button"
          className={twMerge(
            "hover:bg-primary rounded bg-gray-12 py-3 text-gray-1 transition-colors duration-200",
            hasMoved && "bg-primary"
          )}
          onClick={onContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export function KeyboardMoveInstructions({
  onSkipTutorial,
  onContinue,
}: {
  onSkipTutorial: () => void;
  onContinue: () => void;
}) {
  const [initialPosition] = useState({
    x: store.getState().editor.translateX,
    y: store.getState().editor.translateY,
  });

  const [hasMoved, setHasMoved] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      const { translateX, translateY } = store.getState().editor;

      if (
        translateX !== initialPosition.x ||
        translateY !== initialPosition.y
      ) {
        setHasMoved(true);
      }
    });
  }, [initialPosition.x, initialPosition.y]);

  return (
    <div
      onMouseDown={(event) => event.stopPropagation()}
      className="absolute bottom-3 left-[calc((100vw_+_326px)_/_2)] z-[999] flex w-[756px] -translate-x-1/2 justify-between gap-8 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
    >
      <div className="flex flex-1 items-center justify-center rounded bg-gray-3">
        <Lottie
          options={{ animationData: middleMousePress }}
          width={125}
          height={125}
        />
        <PlusIcon />
        <Lottie
          options={{ animationData: dragMouse }}
          width={125}
          height={125}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-12">Move</h3>
            <button
              className="text-base text-gray-11"
              type="button"
              onClick={onSkipTutorial}
            >
              Skip tutorial
            </button>
          </div>
          <p className="max-w-80 text-base text-gray-12">
            Drag your mouse while holding the middle mouse button down
          </p>
        </div>
        <button
          type="button"
          className={twMerge(
            "hover:bg-primary rounded bg-gray-12 py-3 text-gray-1 transition-colors duration-200",
            hasMoved && "bg-primary"
          )}
          onClick={onContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
