import {
  selectAllSidesBorderWidth,
  selectBorderBottomWidth,
  selectBorderLeftWidth,
  selectBorderRightWidth,
  selectBorderTopWidth,
} from "@core/features/editor/editorSlice";
import {
  useAppSelector,
  useDebounceSetSelectedLayerProperties,
} from "@core/hooks";
import { useState } from "react";
import * as i from "../../Sidebar.icons";
import { Input } from "../Input";
import { Toggle } from "../Toggle";

const converBorderWidthToString = (value: number | string | null) => {
  if (value === null) return "";

  return String(value);
};

export function SidebarBorderWidthSettings() {
  const setSelectedLayerProperties = useDebounceSetSelectedLayerProperties();

  const [editAllSides, setEditAllSides] = useState(false);

  const borderTopWidth = useAppSelector(selectBorderTopWidth);
  const borderRightWidth = useAppSelector(selectBorderRightWidth);
  const borderBottomWidth = useAppSelector(selectBorderBottomWidth);
  const borderLeftWidth = useAppSelector(selectBorderLeftWidth);
  const allSidesBorderWidth = useAppSelector(selectAllSidesBorderWidth);

  const handleToggleEditAllSides = () => {
    setEditAllSides((previous) => !previous);
  };

  const handleChangeBorderWidth = (
    value: string,
    type: "top" | "right" | "bottom" | "left" | "all"
  ) => {
    const borderWidth = Number(value);
    if (Number.isNaN(borderWidth)) return;

    switch (type) {
      case "all": {
        setSelectedLayerProperties({
          borderTopWidth: borderWidth,
          borderRightWidth: borderWidth,
          borderBottomWidth: borderWidth,
          borderLeftWidth: borderWidth,
        });
        break;
      }
      case "top": {
        setSelectedLayerProperties({ borderTopWidth: borderWidth });
        break;
      }
      case "right": {
        setSelectedLayerProperties({ borderRightWidth: borderWidth });
        break;
      }
      case "bottom": {
        setSelectedLayerProperties({ borderBottomWidth: borderWidth });

        break;
      }
      case "left": {
        setSelectedLayerProperties({ borderLeftWidth: borderWidth });
        break;
      }
    }
  };

  return (
    <div className="flex gap-1">
      {editAllSides ? (
        <div className="grid flex-1 grid-cols-2 gap-1">
          <Input
            minValue={0}
            unit="px"
            icon={<i.BorderTop />}
            value={converBorderWidthToString(borderTopWidth)}
            onValueChange={(value) => handleChangeBorderWidth(value, "top")}
          />
          <Input
            minValue={0}
            unit="px"
            icon={<i.BorderLeft />}
            value={converBorderWidthToString(borderLeftWidth)}
            onValueChange={(value) => handleChangeBorderWidth(value, "left")}
          />
          <Input
            minValue={0}
            unit="px"
            icon={<i.BorderBottom />}
            value={converBorderWidthToString(borderBottomWidth)}
            onValueChange={(value) => handleChangeBorderWidth(value, "bottom")}
          />

          <Input
            minValue={0}
            unit="px"
            icon={<i.BorderRight />}
            value={converBorderWidthToString(borderRightWidth)}
            onValueChange={(value) => handleChangeBorderWidth(value, "right")}
          />
        </div>
      ) : (
        <Input
          minValue={0}
          unit="px"
          className="!px-2"
          fill
          value={converBorderWidthToString(allSidesBorderWidth)}
          onValueChange={(value) => handleChangeBorderWidth(value, "all")}
        />
      )}
      <Toggle
        onClick={handleToggleEditAllSides}
        pressed={editAllSides}
        icon={i.ExpandSides}
      />
    </div>
  );
}
