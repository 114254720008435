import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import type { LayerId, Layers, SelectedLayerIds } from "@folds/shared/types";

const checkIfAllLayersHaveSameParentId = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const parentIds = selectedLayerIds.map((id) => layers[id]?.parentId);

  const isSameParentId = parentIds.every((id) => id === parentIds[0]);

  return isSameParentId;
};

export const selectLayerAction = (state: DraftEditorState, id: LayerId) => {
  const newSelectedLayerIds = [...new Set([...state.selectedLayerIds, id])];

  const hasSameParentId = checkIfAllLayersHaveSameParentId(
    state.layers,
    newSelectedLayerIds
  );

  if (!hasSameParentId) {
    setSelectedLayerIdsAction(state, [id]);
    return;
  }

  setSelectedLayerIdsAction(state, newSelectedLayerIds);
};
