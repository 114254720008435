import { Type, Static } from "@sinclair/typebox";

export const Shop = Type.Object({
  shopifyDomain: Type.String(),
  accessToken: Type.String(),
  accessTokenScopes: Type.String(),
  email: Type.String(),
  isInstalled: Type.Boolean(),
  images: Type.Array(Type.String()),
  shopOwner: Type.String(),
  publishedShopifyThemeId: Type.Union([Type.String(), Type.Null()]),
  shopifyStorefrontPassword: Type.Union([Type.String(), Type.Null()]),
  createdAt: Type.String(),
  headerDesktopImage: Type.Union([Type.String(), Type.Null()]),
  headerTabletImage: Type.Union([Type.String(), Type.Null()]),
  headerMobileImage: Type.Union([Type.String(), Type.Null()]),
  footerDesktopImage: Type.Union([Type.String(), Type.Null()]),
  footerTabletImage: Type.Union([Type.String(), Type.Null()]),
  footerMobileImage: Type.Union([Type.String(), Type.Null()]),
});

export type ShopType = Static<typeof Shop>;
