import { BackgroundImageOverlaySettings } from "@/features/sidebar/components/FillSettings/BackgroundImageOverlaySettings";
import { FillPicker } from "@/features/sidebar/components/FillSettings/FillPicker";
import { ImagePositionSettings } from "@/features/sidebar/components/FillSettings/ImagePositionSettings";
import {
  selectBackgroundColor,
  selectBackgroundImage,
  selectFillType,
  selectFirstSelectedLayer,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { FillType, compiledFillTypeSchema } from "@folds/shared/types";
import { useMemo } from "react";

export function FillSettings() {
  const dispatch = useAppDispatch();

  const firstSelectedLayer = useAppSelector(selectFirstSelectedLayer);
  const backgroundImage = useAppSelector(selectBackgroundImage);
  const backgroundColor = useAppSelector(selectBackgroundColor);
  const fillType = useAppSelector(selectFillType);

  const parsedFillType = useMemo(() => {
    if (firstSelectedLayer === null) return "color";

    if (!("backgroundColor" in firstSelectedLayer)) return "color";

    try {
      return compiledFillTypeSchema.Decode(fillType);
    } catch (error) {
      return "color";
    }
  }, [fillType, firstSelectedLayer]);

  const handleChangeFillType = (value: FillType) => {
    dispatch(setSelectedLayerProperties({ fillType: value }));
  };

  const handleColorChange = (value: string) => {
    dispatch(
      setSelectedLayerProperties({ backgroundColor: value, fillType: "color" })
    );
  };

  const handleChangeBackgroundImage = (value: string) => {
    dispatch(
      setSelectedLayerProperties({ backgroundImage: value, fillType: "image" })
    );
  };

  return (
    <>
      <div className="flex flex-col gap-2 border-t border-solid border-gray-6 p-3">
        <h3 className="text-sm font-medium">Fill</h3>
        <FillPicker
          ariaLabel="Fill color"
          color={backgroundColor ?? ""}
          fillType={parsedFillType}
          backgroundImage={backgroundImage}
          onChangeFillType={handleChangeFillType}
          onColorChange={handleColorChange}
          onBackgroundImageUrlChange={handleChangeBackgroundImage}
        />
      </div>
      {parsedFillType === "image" && (
        <>
          <BackgroundImageOverlaySettings />
          <ImagePositionSettings />
        </>
      )}
    </>
  );
}
