import { ShopifyProduct } from "@folds/shared";
import { createContext, useContext } from "react";

const PreviewProducts = createContext<null | ShopifyProduct[]>(null);

export const usePreviewProducts = () => {
  const value = useContext(PreviewProducts);

  return value;
};

export const PreviewProductsProvider = PreviewProducts.Provider;
