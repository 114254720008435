import { RectangleLayer } from '../types';
import { generateFillStyles } from './utils/generateFillStyles';

export const generateRectangleStyles = (
  layer: RectangleLayer
): React.CSSProperties => {
  const fillStyles = generateFillStyles(layer);

  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    width,
    zIndex,
    left,
    top,
    visible,
    action,
  } = layer;

  return {
    all: 'initial',
    ...fillStyles,
    display: visible ? 'block' : 'none',
    boxSizing: 'border-box',
    zIndex,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    top,
    left,
    position: 'absolute',
    width,
    height,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderStyle,
    borderColor,
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
    // When inside of a button, the cursor should be a pointer so we use inherit
    cursor: action !== null ? 'pointer' : 'inherit',
  };
};
