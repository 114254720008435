import { useSections } from "@/features/editor/api/getSections";
import { useSectionsDisabled } from "@/features/editor/context/IsHeaderDisabled";
import { useEventConnector } from "@core/events";
import { selectBreakpointWidth } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { LayerId } from "@folds/shared/types";

const parseImageURL = (key: string) => {
  if (import.meta.env.DEV) {
    return `https://sections-dev.folds.app/${key}`;
  }

  return `https://sections.folds.app/${key}`;
};

type Props = {
  layerId: LayerId;
  children: React.ReactNode;
};

export function Breakpoint({ children, layerId }: Props) {
  const width = useAppSelector(selectBreakpointWidth);
  const breakpoint = useAppSelector((state) => state.editor.breakpoint);
  const sectionsDisabled = useSectionsDisabled();
  const { data: sections } = useSections();

  const { handleMouseLeave } = useEventConnector(layerId);

  const style: React.CSSProperties = {
    width,
  };

  const headerStyle: React.CSSProperties = {
    userSelect: "none",
  };

  return (
    <div
      aria-label="Breakpoint"
      className="flex w-[1440px] justify-center overflow-hidden bg-[#F5F5F5]"
      id={layerId}
      onMouseLeave={handleMouseLeave}
    >
      <div style={style}>
        {!sectionsDisabled && sections && sections[breakpoint].header && (
          <img
            src={parseImageURL(sections[breakpoint].header)}
            alt="Header"
            style={headerStyle}
          />
        )}
        {children}
        {!sectionsDisabled && sections && sections[breakpoint].footer && (
          <img
            style={headerStyle}
            src={parseImageURL(sections[breakpoint].footer)}
            alt="Footer"
          />
        )}
      </div>
    </div>
  );
}
