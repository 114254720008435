import { FontItem, FontItems } from "@folds/shared/types";

const parseFontUrl = (url: string) => {
  // We need to use https for the font to load properly
  const fontUrlWithProperProtocol = url.replace("http://", "https://");

  return `url(${fontUrlWithProperProtocol})`;
};

// https://www.kirupa.com/html5/detect_whether_font_is_installed.htm
function checkIfFontExists(fontName: string, iFrameDocument: Document) {
  const canvas = iFrameDocument.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context === null) return false;

  // The text whose final pixel size I want to measure
  const text = "abcdefghijklmnopqrstuvwxyz0123456789";

  // Specifying the baseline font
  context.font = "72px monospace";

  // Checking the size of the baseline text
  const baselineSize = context.measureText(text).width;

  // Specifying the font whose existence we want to check
  context.font = `72px '${fontName}', monospace`;

  // Checking the size of the font we want to check
  const newSize = context.measureText(text).width;

  // Removing the Canvas element we created
  canvas.remove();

  //
  // If the size of the two text instances is the same, the font does not exist because it is being rendered
  // using the default sans-serif font
  //
  if (newSize === baselineSize) {
    return false;
  }
  return true;
}

const getFontUrls = (font: FontItem) => {
  const regularFontUrl = parseFontUrl(font.files.regular);

  const thinFontUrl =
    font.files["100"] !== undefined ? parseFontUrl(font.files["100"]) : null;
  const extraLightFontUrl =
    font.files["200"] !== undefined ? parseFontUrl(font.files["200"]) : null;
  const lightFontUrl =
    font.files["300"] !== undefined ? parseFontUrl(font.files["300"]) : null;
  const mediumFontUrl =
    font.files["500"] !== undefined ? parseFontUrl(font.files["500"]) : null;
  const semiBoldFontUrl =
    font.files["600"] !== undefined ? parseFontUrl(font.files["600"]) : null;
  const boldFontUrl =
    font.files["700"] !== undefined ? parseFontUrl(font.files["700"]) : null;
  const extraBoldFontUrl =
    font.files["800"] !== undefined ? parseFontUrl(font.files["800"]) : null;
  const blackFontUrl =
    font.files["900"] !== undefined ? parseFontUrl(font.files["900"]) : null;

  return {
    400: regularFontUrl,
    ...(thinFontUrl !== null && { 100: thinFontUrl }),
    ...(extraLightFontUrl !== null && { 200: extraLightFontUrl }),
    ...(lightFontUrl !== null && { 300: lightFontUrl }),
    ...(mediumFontUrl !== null && { 500: mediumFontUrl }),
    ...(semiBoldFontUrl !== null && { 600: semiBoldFontUrl }),
    ...(boldFontUrl !== null && { 700: boldFontUrl }),
    ...(extraBoldFontUrl !== null && { 800: extraBoldFontUrl }),
    ...(blackFontUrl !== null && { 900: blackFontUrl }),
  };
};

const QUOTE_REGEX = /"/g;

export const loadFontFamilies = (
  fontFamilies: string[],
  fonts: FontItems,
  targetDocument: Document
) => {
  fontFamilies.forEach((fontFamily) => {
    const fontExists = checkIfFontExists(fontFamily, targetDocument);
    if (fontExists) return;

    // Font family nanes may have quotes if they contain numbers, but the object keys in the fonts object do not
    const fontFamilyWithoutQuotes = fontFamily.replaceAll(QUOTE_REGEX, "");

    const font = fonts.find(
      (value) => value.family === fontFamilyWithoutQuotes
    );

    if (font === undefined) return;

    const fontUrls = getFontUrls(font);

    Object.entries(fontUrls).forEach(async ([weight, url]) => {
      // The font face must be created with the font family name without quotes
      const fontFace = new FontFace(fontFamilyWithoutQuotes, url, {
        weight,
      });

      await fontFace
        .load()
        .then((addedFontFace) => targetDocument.fonts.add(addedFontFace));
    });
  });
};
