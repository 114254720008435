import {
  addAccordionItem,
  selectSelectedAccordionItemId,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import type { RootState } from "@core/store";
import { getAncestorIds, getElement } from "@core/utils";
import { LayerType } from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";

const selectSelectedAccordionRootId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    let selectedAccordionItemId: string | null = null as string | null;

    selectedLayerIds.forEach((selectedLayerId) => {
      const ancestorIds = getAncestorIds(layers, selectedLayerId);

      const accordionItemId = [selectedLayerId, ...ancestorIds].find((id) => {
        const layer = layers[id];

        if (!layer || layer.type !== LayerType.Accordion.Root) return false;

        return true;
      });

      if (accordionItemId === undefined) return;

      selectedAccordionItemId = accordionItemId;
    });

    return selectedAccordionItemId;
  }
);

export function AddAccordionItemButton() {
  const dispatch = useAppDispatch();
  const selectedAccordionRootId = useAppSelector(selectSelectedAccordionRootId);

  if (selectedAccordionRootId === null) return null;

  const handleClick = () => {
    dispatch(addAccordionItem(selectedAccordionRootId));
  };

  return (
    <div className="fixed left-[326px] z-50 w-[calc(100vw-326px)]">
      <button
        type="button"
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
        className="absolute left-1/2 top-2 h-10 w-32 -translate-x-full rounded bg-gray-12 text-xs text-gray-1"
      >
        Add item
      </button>
    </div>
  );
}

export function ToggleAccordionItem() {
  const selectedAccordionItemId = useAppSelector(selectSelectedAccordionItemId);

  if (selectedAccordionItemId === null) return null;

  const handleClick = () => {
    const element = getElement(selectedAccordionItemId);

    if (element === null) return;

    const accordionTrigger = element.querySelector("folds-accordion-trigger");

    if (accordionTrigger === null) return;

    accordionTrigger.dispatchEvent(
      new CustomEvent("accordionTrigger", { bubbles: true, composed: true })
    );
  };

  return (
    <div className="fixed bottom-3 left-[338px] flex gap-2">
      <button
        type="button"
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
        className="h-10 w-44 rounded bg-gray-12 text-xs text-gray-1"
      >
        Toggle accordion item
      </button>
    </div>
  );
}
