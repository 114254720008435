import { getParentLayerId } from "@core/utils/getParentLayerId";
import { LayerId, Layers } from "@folds/shared/types";

export const getParentLayer = (layers: Layers, layerId: LayerId) => {
  const parentLayerId = getParentLayerId(layers, layerId);

  if (parentLayerId === null) return null;

  const parentLayer = layers[parentLayerId];

  if (!parentLayer || !("children" in parentLayer)) return null;

  return parentLayer;
};
