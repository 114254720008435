import { selectTextVariables } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import {
  LayerType,
  TextLayer,
  compiledTextLayerContentSchema,
} from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";
import { toast } from "@core/toast";
import { captureException } from "@sentry/react";

const selectParentIsOptionSelectorItem = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const id = selectedLayerIds[0];

    if (id === undefined) return false;

    const layer = layers[id];

    if (!layer || typeof layer.parentId !== "string") return false;

    const parentLayer = layers[layer.parentId];

    if (!parentLayer) return false;

    return parentLayer.type === LayerType.OptionSelector.Item;
  }
);

type Props = {
  onValueChange: (value: {
    content: TextLayer["content"];
    variableId: string | null;
  }) => void;
  value: TextLayer["content"];
};

export function SidebarTextTypes({ onValueChange, value }: Props) {
  const textVariables = useAppSelector(selectTextVariables);
  const parentIsOptionSelectorItem = useAppSelector(
    selectParentIsOptionSelectorItem
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      if (event.target.value.startsWith("variable-")) {
        const variableId = event.target.value.split("-")[1];

        if (variableId === undefined) {
          toast.error("There was an issue updating the text");
          return;
        }

        onValueChange({ content: "variable", variableId });
        return;
      }

      const type = compiledTextLayerContentSchema.Decode(event.target.value);

      onValueChange({ content: type, variableId: null });
    } catch (error) {
      captureException(error);
      toast.error("There was an issue updating the text");
    }
  };

  return (
    <select
      onChange={handleChange}
      value={value}
      className="h-8 rounded-sm border-r-4 border-transparent bg-gray-2 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
    >
      <option value="custom">Custom text</option>
      {parentIsOptionSelectorItem && (
        <option value="option-value">Option value</option>
      )}
      <option value="product-title">Product title</option>
      <option value="product-price">Product price</option>
      <option value="product-compare-at-price">Compare at price</option>
      <option value="selected-option-value">Selected option value</option>
      {textVariables.map((variable) => (
        <option key={variable.id} value={`variable-${variable.id}`}>
          {variable.name}
        </option>
      ))}
    </select>
  );
}
