import { LiquidLayer } from '../types';

export const generateLiquidStyles = (
  layer: LiquidLayer
): React.CSSProperties => ({
  position: 'absolute',
  top: layer.top,
  left: layer.left,
  zIndex: layer.zIndex,
});
