import { Type, type Static } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import type { LayerId } from './core';

/**
 * Layers slice
 */

export type AddLayerOptions = { select?: SelectedLayerId; index?: number };

export type SelectedLayerId = LayerId;
export type SelectedLayerIds = LayerId[];

const breakpointSchema = Type.Union([
  Type.Literal('desktop'),
  Type.Literal('tablet'),
  Type.Literal('mobile'),
]);

export const compiledBreakpointSchema = TypeCompiler.Compile(breakpointSchema);

export type Breakpoint = Static<typeof breakpointSchema>;

type HorizontalGuideStopLine = {
  y: number;
  layerId: LayerId;
};

type VerticalGuideStopLine = {
  x: number;
  layerId: LayerId;
};

export type GuideStopLines = {
  horizontal: HorizontalGuideStopLine[];
  vertical: VerticalGuideStopLine[];
};

export type Guide = {
  side: 'start' | 'center' | 'end';
  layerId: LayerId;
  id: string;
};
