import { useFrame } from "@/features/editor/context/FrameContext";
import {
  selectIsDragSelecting,
  selectIsDragging,
  selectIsResizing,
} from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { useEffect } from "react";

/**
 * Prevents text selection when dragging / selecting / resizing over a text layer
 */
export const usePreventHighlightingTextWhenDraggingOrDragSelecting = () => {
  const { document: IFrameDocument } = useFrame();
  const isDragging = useAppSelector(selectIsDragging);
  const isDragSelecting = useAppSelector(selectIsDragSelecting);
  const isResizing = useAppSelector(selectIsResizing);

  useEffect(() => {
    const handleMouseMove = (event: Event) => {
      if (isDragging || isDragSelecting || isResizing) {
        event.preventDefault();
      }
    };

    IFrameDocument?.addEventListener("mousemove", handleMouseMove);

    return () => {
      IFrameDocument?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [IFrameDocument, isDragSelecting, isDragging, isResizing]);
};
