import { useFrame } from "@/features/editor/context/FrameContext";
import { drag, dragEnd } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { store } from "@core/store";
import { useCallback, useEffect } from "react";

export const useCanvasEvents = () => {
  const dispatch = useAppDispatch();

  const { window: iFrameWindow } = useFrame();

  const scale = useAppSelector((state) => state.editor.scale);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const {
        editor: { canvasMode, dragging },
      } = store.getState();

      if (canvasMode !== "Select" || dragging === null) return;

      dispatch(
        drag({
          event: {
            clientX: event.clientX,
            clientY: event.clientY,
            altKey: event.altKey,
          },
          scale,
        })
      );
    },
    [dispatch, scale]
  );

  const handleMouseUp = useCallback(() => {
    const {
      editor: { dragging },
    } = store.getState();

    if (dragging === null) return;

    dispatch(dragEnd());
  }, [dispatch]);

  useEffect(() => {
    iFrameWindow?.addEventListener("mousemove", handleMouseMove);
    iFrameWindow?.addEventListener("mouseup", handleMouseUp);

    return () => {
      iFrameWindow?.removeEventListener("mousemove", handleMouseMove);
      iFrameWindow?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, iFrameWindow]);
};
