import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import axios from "axios";
import { toast } from "@core/toast";

const expectedPresignedUrlResponse = TypeCompiler.Compile(
  Type.Object({
    fileName: Type.String(),
    url: Type.String(),
  })
);
const getFileExtension = (name: File["name"]) => name.split(".").pop();

export const useUploadImages = () => {
  const authAxios = useAxios();

  async function uploadImage(file: File) {
    const fileExtension = getFileExtension(file.name);

    if (fileExtension === undefined) {
      toast.error("Invalid file type");
      return;
    }

    const presignedUrlResult = await authAxios.get(
      `/images/presigned-url?extension=${fileExtension}&contentLength=${file.size}`
    );

    const parsedResponse = expectedPresignedUrlResponse.Decode(
      presignedUrlResult.data
    );

    const fileWithNewName = new File([file], parsedResponse.fileName, {
      type: file.type,
    });

    await axios.put(parsedResponse.url, fileWithNewName, {
      headers: {
        "Content-Type": file.type,
      },
    });

    await authAxios.post(`/images/?image=${parsedResponse.fileName}`);
  }

  const uploadImages = async (fileList: FileList, callback: () => void) => {
    try {
      toast.loading("Uploading images...", { id: "upload-images" });

      const uploadImagePromises = Array.from(fileList).map((file) =>
        uploadImage(file)
      );

      await Promise.all(uploadImagePromises);

      toast.success("Uploaded", { id: "upload-images" });

      callback();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data.message;

        if (typeof message === "string") {
          toast.error(message, { id: "upload-images" });
          callback();
          return;
        }
      }

      toast.error(
        "There was an issue uploading the images. Please try again.",
        { id: "upload-images" }
      );
    }
  };

  return uploadImages;
};
