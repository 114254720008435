import { compiledComponentApiResponseSchema } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export const useTemplates = () => {
  const axios = useAxios();

  const fetchTemplates = async (key: string) => {
    const response = await axios.get(key);

    return compiledComponentApiResponseSchema.Decode(response.data);
  };

  return useSWR("/templates", fetchTemplates);
};
