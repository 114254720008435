import type {
  ElementLayerInformation,
  ResizingInitialLayersInformation,
  TextLayerInformation,
} from "@core/features/editor/editorSlice";
import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { getAbsolutePosition, getLayerDimensions } from "@core/utils";
import { getOverridenLayer } from "@folds/shared";
import {
  Box,
  Breakpoint,
  LayerType,
  Layers,
  SelectedLayerIds,
} from "@folds/shared/types";
import { current } from "@reduxjs/toolkit";

const getLayerIdsToResize = ({
  layers,
  selectedLayerIds,
}: {
  selectedLayerIds: SelectedLayerIds;
  layers: Layers;
}) => {
  const layerIdsToResize = selectedLayerIds.flatMap((id) => {
    const layer = layers[id];
    if (layer === undefined) return [];

    if (checkIfLayerIsIntrinsicallyPositioned(layer)) {
      return layer.children;
    }

    return id;
  });

  return layerIdsToResize;
};

export const getResizingInitialLayersInformation = ({
  layers,
  selectedLayerIds,
  box,
  breakpoint,
}: {
  selectedLayerIds: SelectedLayerIds;
  layers: Layers;
  box: Box;
  breakpoint: Breakpoint;
}) => {
  const layerIdsToResize = getLayerIdsToResize({ layers, selectedLayerIds });

  const initialLayersInformation = layerIdsToResize.map((selectedLayerId) => {
    const layer = getOverridenLayer({
      breakpoint,
      id: selectedLayerId,
      layers: current(layers),
    });
    if (layer === null) return;

    if (checkIfLayerIsIntrinsicallyPositioned(layer)) {
      return null;
    }

    const position = getAbsolutePosition(selectedLayerId);
    const sizing = getLayerDimensions(selectedLayerId);

    const positions = {
      distanceFromTop: position.top - box.top,
      distanceFromLeft: position.left - box.left,
      distanceFromRight: box.left + box.width - (position.left + sizing.width),
      distanceFromBottom: box.top + box.height - (position.top + sizing.height),
    };

    if (layer.type === LayerType.Text) {
      const textInformation: TextLayerInformation = {
        type: "text",
        fontSize: layer.fontSize,
        layerId: selectedLayerId,
        fitContent: layer.width === null,
        ...positions,
        ...position,
        ...sizing,
      };

      return textInformation;
    }

    const elementInformation: ElementLayerInformation = {
      type: "element",
      fontSize: null,
      fitContent: false,
      layerId: selectedLayerId,
      ...positions,
      ...position,
      ...sizing,
    };

    return elementInformation;
  });

  const filteredInitialLayersInformation = initialLayersInformation.filter(
    (layerInformation) => layerInformation !== null
  ) as ResizingInitialLayersInformation;

  return filteredInitialLayersInformation;
};
