import * as i from "@/features/sidebar/Sidebar.icons";
import { Input } from "@/features/sidebar/components/Input";
import {
  selectGap,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function GapSettings() {
  const dispatch = useAppDispatch();
  const gap = useAppSelector(selectGap);

  const handleChange = (value: string) => {
    const newGap = Number(value);
    if (Number.isNaN(newGap)) return;

    dispatch(setSelectedLayerProperties({ gap: newGap }));
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Spacing</p>
      <Input
        unit="px"
        className="w-[111.5px]"
        icon={<i.Gap />}
        value={String(gap)}
        minValue={0}
        onValueChange={handleChange}
      />
    </div>
  );
}
