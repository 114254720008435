import type { AutoCompleteValues } from "@/features/sidebar/components";
import type { RootState } from "@core/store";
import {
  getAncestorIds,
  getBreakpointWidth,
  getLayerName,
  getLayerType,
} from "@core/utils";
import { checkIfCanUngroupLayers } from "@core/utils/checkIfCanUngroupLayers";
import { checkIfCanGroupLayers } from "@core/utils/checkIfCanGroupLayers";
import { getOverridenLayer } from "@folds/shared/layers";
import {
  Guide,
  Layer,
  LayerId,
  LayerType,
  RectangleLayer,
  ShopifyProduct,
  Variables,
  Viewport,
} from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";
import { colord } from "colord";

type AllSidesType = (state: RootState) => number | null | "Mixed";

export const selectAllSidesBorderWidth: AllSidesType = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      breakpoint,
      id: firstSelectedLayerId,
      layers,
    });

    if (layer === null) return null;

    if (
      !("borderTopWidth" in layer) ||
      !("borderRightWidth" in layer) ||
      !("borderBottomWidth" in layer) ||
      !("borderLeftWidth" in layer)
    )
      return null;

    const {
      borderTopWidth,
      borderRightWidth,
      borderBottomWidth,
      borderLeftWidth,
    } = layer;

    const isEverySideTheSame =
      borderTopWidth === borderRightWidth &&
      borderTopWidth === borderBottomWidth &&
      borderTopWidth === borderLeftWidth;

    if (!isEverySideTheSame) {
      return "Mixed";
    }

    return borderTopWidth;
  }
);

export const selectAllCornersBorderRadius: AllSidesType = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      breakpoint,
      id: firstSelectedLayerId,
      layers,
    });

    if (layer === null) return null;

    if (
      !("borderTopLeftRadius" in layer) ||
      !("borderTopRightRadius" in layer) ||
      !("borderBottomRightRadius" in layer) ||
      !("borderBottomLeftRadius" in layer)
    )
      return null;

    const {
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
    } = layer;

    const isEveryCornerTheSame =
      borderTopLeftRadius === borderTopRightRadius &&
      borderTopLeftRadius === borderBottomRightRadius &&
      borderTopLeftRadius === borderBottomLeftRadius;

    if (!isEveryCornerTheSame) {
      return "Mixed";
    }

    return borderTopLeftRadius;
  }
);

type SelectFirstSelectedLayer = (state: RootState) => Layer | null;

export const selectFirstSelectedLayer: SelectFirstSelectedLayer =
  createSelector(
    [
      (state: RootState) => state.editor.selectedLayerIds,
      (state: RootState) => state.editor.breakpoint,
      (state: RootState) => state.editor.layers,
    ],
    (selectedLayerIds, breakpoint, layers) => {
      const firstSelectedLayerId = selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return null;

      return getOverridenLayer({
        breakpoint,
        id: firstSelectedLayerId,
        layers,
      });
    }
  );

type SelectLayerId = (state: RootState) => LayerId | null;

export const selectFirstSelectedLayerId: SelectLayerId = createSelector(
  [(state: RootState) => state.editor.selectedLayerIds],
  (selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    return firstSelectedLayerId;
  }
);

type SelectLayerName = (state: RootState) => string;

export const selectFirstSelectedLayerName: SelectLayerName = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, ids): string => {
    const firstSelectedLayerId = ids[0];

    if (firstSelectedLayerId === undefined) return "";

    const layer = layers[firstSelectedLayerId];

    if (layer === undefined) return "";

    return getLayerName(layer);
  }
);

type SelectFirstLayerType = (state: RootState) => Layer["type"] | null;

export const selectFirstSelectedLayerType: SelectFirstLayerType =
  createSelector(
    [
      (state: RootState) => state.editor.layers,
      (state: RootState) => state.editor.selectedLayerIds,
    ],
    (layers, selectedLayerIds) => {
      const firstSelectedLayerId = selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return null;

      const layer = layers[firstSelectedLayerId];

      if (layer === undefined) return null;

      return layer.type;
    }
  );

type SelectAllBackgroundImages = (state: RootState) => string[];

export const selectAllBackgroundImages: SelectAllBackgroundImages =
  createSelector([(state: RootState) => state.editor.layers], (layers) => {
    const layersWithBackgroundImages = Object.values(layers).filter(
      (layer) => "backgroundImage" in layer && layer.backgroundImage !== null
    ) as RectangleLayer[];

    const uniqueBackgroundImages = layersWithBackgroundImages.reduce(
      (acc, layer) => {
        if (
          layer.backgroundImage === null ||
          acc.includes(layer.backgroundImage)
        )
          return acc;

        return [...acc, layer.backgroundImage];
      },
      [] as string[]
    );

    return uniqueBackgroundImages;
  });

type SelectBreakpointWidth = (state: RootState) => number;

export const selectBreakpointWidth: SelectBreakpointWidth = createSelector(
  [(state: RootState) => state.editor.breakpoint],
  (breakpoint) => {
    const breakpointWidth = getBreakpointWidth(breakpoint);

    return breakpointWidth;
  }
);

type SelectBackgroundColor = (state: RootState) => string | null;

export const selectBackgroundColor: SelectBackgroundColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("backgroundColor" in layer)) return null;

    return layer.backgroundColor;
  }
);

type SelectProductId = (state: RootState) => ShopifyProduct["id"] | null;

export const selectProductId: SelectProductId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("productId" in layer)) return null;

    return layer.productId;
  }
);

type SelectOptionName = (
  state: RootState
) => ShopifyProduct["options"][number]["name"] | null;

export const selectOptionName: SelectOptionName = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("optionName" in layer)) return null;

    return layer.optionName;
  }
);

type SelectBorderStyle = (state: RootState) => "solid" | "dashed" | "dotted";

export const selectBorderStyle: SelectBorderStyle = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "solid";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderStyle" in layer)) return "solid";

    return layer.borderStyle;
  }
);

type SelectBorderColor = (state: RootState) => string;

export const selectBorderColor: SelectBorderColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderColor" in layer)) return "rgb(0,0,0)";

    return layer.borderColor;
  }
);

type SelectBorderWidth = (state: RootState) => number;

export const selectBorderTopWidth: SelectBorderWidth = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderTopWidth" in layer)) return 0;

    return layer.borderTopWidth;
  }
);

export const selectBorderRightWidth: SelectBorderWidth = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderRightWidth" in layer)) return 0;

    return layer.borderRightWidth;
  }
);

export const selectBorderBottomWidth: SelectBorderWidth = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderBottomWidth" in layer)) return 0;

    return layer.borderBottomWidth;
  }
);

export const selectBorderLeftWidth: SelectBorderWidth = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderLeftWidth" in layer)) return 0;

    return layer.borderLeftWidth;
  }
);

type SelectFillType = (state: RootState) => "color" | "image" | "none";

export const selectFillType: SelectFillType = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "none";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("fillType" in layer)) return "none";

    return layer.fillType;
  }
);

type SelectBorderRadius = (state: RootState) => number;

export const selectBorderTopLeftRadius: SelectBorderRadius = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderTopLeftRadius" in layer)) return 0;

    return layer.borderTopLeftRadius;
  }
);

export const selectBorderTopRightRadius: SelectBorderRadius = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderTopRightRadius" in layer)) return 0;

    return layer.borderTopRightRadius;
  }
);

type SelectBackgroundImage = (state: RootState) => string | null;

export const selectBackgroundImage: SelectBackgroundImage = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("backgroundImage" in layer)) return null;

    return layer.backgroundImage;
  }
);

export const selectBorderBottomRightRadius: SelectBorderRadius = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderBottomRightRadius" in layer)) return 0;

    return layer.borderBottomRightRadius;
  }
);

export const selectBorderBottomLeftRadius: SelectBorderRadius = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("borderBottomLeftRadius" in layer)) return 0;

    return layer.borderBottomLeftRadius;
  }
);

type SelectGap = (state: RootState) => number;

export const selectGap: SelectGap = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("gap" in layer)) return 0;

    return layer.gap;
  }
);

type SelectOutlineWidth = (state: RootState) => number;

export const selectOutlineWidth: SelectOutlineWidth = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("outlineWidth" in layer)) return 0;

    return layer.outlineWidth;
  }
);

type SelectOutlineColor = (state: RootState) => string;

export const selectOutlineColor: SelectOutlineColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("outlineColor" in layer)) return "rgb(0,0,0)";

    return layer.outlineColor;
  }
);

type SelectOutlineOffset = (state: RootState) => number;

export const selectOutlineOffset: SelectOutlineOffset = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("outlineOffset" in layer)) return 0;

    return layer.outlineOffset;
  }
);

type SelectFontFamily = (state: RootState) => string;

export const selectFontFamily: SelectFontFamily = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "Inter";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("fontFamily" in layer)) return "Inter";

    return layer.fontFamily;
  }
);

type SelectColor = (state: RootState) => string;

export const selectColor: SelectColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("color" in layer)) return "rgb(0,0,0)";

    return layer.color;
  }
);

type SelectFontWeight = (state: RootState) => number;

export const selectFontWeight: SelectFontWeight = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 400;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("fontWeight" in layer)) return 400;

    return layer.fontWeight;
  }
);

type SelectFontSize = (state: RootState) => number;

export const selectFontSize: SelectFontSize = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 16;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("fontSize" in layer)) return 16;

    const fontSizeRoundedToNearestHundredth =
      Math.round(layer.fontSize * 100) / 100;

    return fontSizeRoundedToNearestHundredth;
  }
);

type SelectIsFormat = (state: RootState) => boolean;

export const selectIsItalicized: SelectIsFormat = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("italic" in layer)) return false;

    return layer.italic;
  }
);

export const selectIsLineThrough: SelectIsFormat = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("lineThrough" in layer)) return false;

    return layer.lineThrough;
  }
);

export const selectIsUnderlined: SelectIsFormat = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("underline" in layer)) return false;

    return layer.underline;
  }
);

type SelectTextTransform = (
  state: RootState
) => "none" | "uppercase" | "lowercase" | "capitalize";

export const selectTextTransform: SelectTextTransform = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "none";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("textTransform" in layer)) return "none";

    return layer.textTransform;
  }
);

type SelectTextAlign = (state: RootState) => "left" | "center" | "right";

export const selectTextAlign: SelectTextAlign = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "left";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("textAlign" in layer)) return "left";

    return layer.textAlign;
  }
);

type SelectLineHeight = (state: RootState) => number;

export const selectLineHeight: SelectLineHeight = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 120;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("lineHeight" in layer)) return 120;

    return layer.lineHeight;
  }
);

type SelectFlexDirection = (state: RootState) => "row" | "column" | null;

export const selectFlexDirection: SelectFlexDirection = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("flexDirection" in layer)) return null;

    return layer.flexDirection;
  }
);

type SelectLetterSpacing = (state: RootState) => number;

export const selectLetterSpacing: SelectLetterSpacing = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("letterSpacing" in layer)) return 0;

    return layer.letterSpacing;
  }
);

export const selectContentType = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "custom";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("content" in layer)) return "custom";

    return layer.content;
  }
);

type SelectLayerType = (state: RootState, id: LayerId) => Layer["type"] | null;

export const selectLayerType: SelectLayerType = createSelector(
  [(state: RootState) => state.editor.layers, (_, id: LayerId) => id],
  (layers, id) => {
    const type = getLayerType(layers, id);

    return type;
  }
);

type SelectIsDragging = (state: RootState) => boolean;

export const selectIsDragging: SelectIsDragging = createSelector(
  [(state: RootState) => state.editor.dragging],
  (dragging) => dragging !== null
);

type SelectIsResizing = (state: RootState) => boolean;

export const selectIsResizing: SelectIsResizing = createSelector(
  [(state: RootState) => state.editor.resizing],
  (resizing) => resizing !== null
);

type SelectGuides = (state: RootState) => Guide[];

export const selectHorizontalGuides: SelectGuides = createSelector(
  [
    (state: RootState) => state.editor.dragging,
    (state: RootState) => state.editor.resizing,
  ],
  (dragging, resizing) => {
    const guides: Guide[] = [];

    if (dragging !== null) {
      guides.push(...dragging.horizontalGuides);
    }

    if (resizing !== null) {
      guides.push(...resizing.horizontalGuides);
    }

    return guides;
  }
);

export const selectVerticalGuides: SelectGuides = createSelector(
  [
    (state: RootState) => state.editor.dragging,
    (state: RootState) => state.editor.resizing,
  ],
  (dragging, resizing) => {
    const guides: Guide[] = [];

    if (dragging !== null) {
      guides.push(...dragging.verticalGuides);
    }

    if (resizing !== null) {
      guides.push(...resizing.verticalGuides);
    }

    return guides;
  }
);

type SelectIsVisible = (state: RootState) => boolean;

export const selectIsVisibleOnDesktop: SelectIsVisible = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint: "desktop",
    });

    if (layer === null || !("visible" in layer)) return false;

    return layer.visible;
  }
);

export const selectIsVisibleOnTablet: SelectIsVisible = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint: "tablet",
    });

    if (layer === null || !("visible" in layer)) return false;

    return layer.visible;
  }
);

export const selectIsVisibleOnMobile: SelectIsVisible = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint: "mobile",
    });

    if (layer === null || !("visible" in layer)) return false;

    return layer.visible;
  }
);

type SelectFontFamilies = (state: RootState) => string[];

export const selectFontFamilies: SelectFontFamilies = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers) => {
    const fontFamilies: string[] = [];

    Object.values(layers).forEach((layer) => {
      if (!("fontFamily" in layer)) return;

      fontFamilies.push(layer.fontFamily);
    });

    const uniqueFontFamilies = [...new Set(fontFamilies)];

    return uniqueFontFamilies;
  }
);

type SelectBreakpointId = (state: RootState) => string | null;

export const selectBreakpointId: SelectBreakpointId = createSelector(
  [(state: RootState) => state.editor.layers],
  (layers) => {
    const breakpointId = Object.values(layers).find(
      (layer) => layer.type === LayerType.Breakpoint
    )?.id;

    return breakpointId ?? null;
  }
);

type SelectViewport = (state: RootState) => Viewport;

export const selectViewport: SelectViewport = createSelector(
  [
    (state: RootState) => state.editor.translateX,
    (state: RootState) => state.editor.translateY,
    (state: RootState) => state.editor.scale,
  ],
  (translateX, translateY, scale) => ({
    translateX,
    translateY,
    scale,
  })
);

type SelectVariables = (state: RootState) => Variables;

export const selectVariables: SelectVariables = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return [];

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("variables" in layer)) return [];

    return layer.variables;
  }
);

export const selectTextVariable = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, variableId: string) => variableId,
  ],
  (layers, selectedLayerIds, variableId) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    const variable = variables.find(
      (currentVariable) => currentVariable.id === variableId
    );

    if (variable?.type !== "text") return null;

    return variable;
  }
);

export const selectCascadingVariables: SelectVariables = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return [];

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    return variables;
  }
);

export const selectCascadingVariablesForId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, selectedLayerIds, layerId) => {
    const ancestorIds = getAncestorIds(layers, layerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    return variables;
  }
);

export const selectHasCascadingOptionValue = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const hasCascadingOptionValue = ancestorIds.some((id) => {
      const layer = layers[id];

      if (!layer || layer.type !== LayerType.OptionSelector.Root) return false;

      return true;
    });

    return hasCascadingOptionValue;
  }
);

export const selectAutoCompleteColorValues = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return [];

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    const autoCompleteValues: AutoCompleteValues = variables.reduce(
      (acc, variable) => {
        if (variable.type !== "color") return acc;

        return [
          ...acc,
          {
            type: "color" as const,
            name: variable.name,
            value: `--${variable.id}`,
          },
        ];
      },
      [] as AutoCompleteValues
    );

    return autoCompleteValues;
  }
);

export const selectAutoCompleteFillValues = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return [];

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    const autoCompleteValues: AutoCompleteValues = variables.reduce(
      (acc, variable) => {
        switch (variable.type) {
          case "text":
            return acc;
          case "image": {
            return [
              ...acc,
              {
                type: "image" as const,
                name: variable.name,
                value: `--${variable.id}`,
              },
            ];
          }
          case "color": {
            return [
              ...acc,
              {
                type: "color" as const,
                name: variable.name,
                value: `--${variable.id}`,
              },
            ];
          }
        }
      },
      [] as AutoCompleteValues
    );

    return autoCompleteValues;
  }
);

export const selectTextVariables = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return [];

    const ancestorIds = getAncestorIds(layers, firstSelectedLayerId);

    const variables: Variables = ancestorIds.reduce((acc, id) => {
      const layer = layers[id];

      if (!layer || !("variables" in layer)) return acc;

      return [...acc, ...layer.variables];
    }, [] as Variables);

    const textVaraibles = variables.reduce(
      (acc, variable) => {
        if (variable.type !== "text") return acc;

        return [
          ...acc,
          {
            name: variable.name,
            id: variable.id,
          },
        ];
      },
      [] as { id: string; name: string }[]
    );

    return textVaraibles;
  }
);

type SelectFillColor = (state: RootState) => string | null;

export const selectFillColor: SelectFillColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("fill" in layer)) return null;

    return layer.fill;
  }
);

export const selectCollectionContent = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (
      layer === null ||
      layer.type !== LayerType.OptionSelector.Root ||
      !("content" in layer)
    )
      return null;

    return layer.content;
  }
);

type SelectAcitionType = (
  state: RootState
) => "none" | "add-to-cart" | "redirect" | "select-option" | undefined;

export const selectActionType: SelectAcitionType = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "none";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("action" in layer)) return "none";

    if (layer.action === null) {
      return "none";
    }

    return layer.action.type;
  }
);

export const selectAction = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("action" in layer)) return;

    return layer.action;
  }
);

export const selectSpaceBetweeen = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("spaceBetween" in layer)) return 0;

    return layer.spaceBetween;
  }
);

export const selectLoopSlides = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("loop" in layer)) return false;

    return layer.loop;
  }
);

export const selectNavigationEnabled = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("navigationEnabled" in layer)) return false;

    return layer.navigationEnabled;
  }
);

export const selectPaginationEnabled = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("paginationEnabled" in layer)) return false;

    return layer.paginationEnabled;
  }
);

export const selectScrollbarEnabled = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("scrollbarEnabled" in layer)) return false;

    return layer.scrollbarEnabled;
  }
);

export const selectNavigationColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("navigationColor" in layer)) return "rgb(0,0,0)";

    return layer.navigationColor;
  }
);

export const selectPaginationActiveBulletColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("paginationActiveBulletColor" in layer))
      return "rgb(0,0,0)";

    return layer.paginationActiveBulletColor;
  }
);

export const selectPaginationInactiveBulletColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("paginationInactiveBulletColor" in layer))
      return "rgb(0,0,0)";

    return layer.paginationInactiveBulletColor;
  }
);

export const selectScrollbarTrackColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("scrollbarTrackColor" in layer))
      return "rgb(0,0,0)";

    return layer.scrollbarTrackColor;
  }
);

export const selectScrollbarThumbColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("scrollbarThumbColor" in layer))
      return "rgb(0,0,0)";

    return layer.scrollbarThumbColor;
  }
);

export const selectNavigationSize = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("navigationSize" in layer)) return 0;

    return layer.navigationSize;
  }
);

type SelectSelectedCarouselId = (state: RootState) => string | null;

export const selectSelectedCarouselId: SelectSelectedCarouselId =
  createSelector(
    [
      (state: RootState) => state.editor.layers,
      (state: RootState) => state.editor.selectedLayerIds,
    ],
    (layers, selectedLayerIds) => {
      let selectedCarouselId: string | null = null as string | null;

      selectedLayerIds.forEach((selectedLayerId) => {
        const ancestorIds = getAncestorIds(layers, selectedLayerId);

        const carouselId = [selectedLayerId, ...ancestorIds].find((id) => {
          const layer = layers[id];

          if (!layer || layer.type !== LayerType.Carousel.Root) return false;

          return true;
        });

        if (carouselId === undefined) return;

        selectedCarouselId = carouselId;
      });

      return selectedCarouselId;
    }
  );

/**
 * Checks for any layers using a splide carousel (ex: product images)
 * Not only a carousel root
 */
export const selectAnySelectedSplideCarouselId: SelectSelectedCarouselId =
  createSelector(
    [
      (state: RootState) => state.editor.layers,
      (state: RootState) => state.editor.selectedLayerIds,
    ],
    (layers, selectedLayerIds) => {
      let selectedCarouselId: string | null = null as string | null;

      selectedLayerIds.forEach((selectedLayerId) => {
        const ancestorIds = getAncestorIds(layers, selectedLayerId);

        const carouselId = [selectedLayerId, ...ancestorIds].find((id) => {
          const layer = layers[id];

          if (!layer) return false;

          if (
            layer.type !== LayerType.Carousel.Root &&
            layer.type !== LayerType.ProductImageCarousel.Thumbnails &&
            layer.type !== LayerType.ProductImageCarousel.FeaturedImage
          )
            return false;

          return true;
        });

        if (carouselId === undefined) return;

        selectedCarouselId = carouselId;
      });

      return selectedCarouselId;
    }
  );

export const selectIconColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgb(0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("iconColor" in layer)) return "rgb(0,0,0)";

    return layer.iconColor;
  }
);

export const selectIconSize = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("iconSize" in layer)) return 0;

    return layer.iconSize;
  }
);

export const selectProductUnavailableId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("productUnavailableId" in layer)) return null;

    return layer.productUnavailableId;
  }
);

export const selectLiquid = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("liquid" in layer)) return "";

    return layer.liquid;
  }
);

export const selectIsDragSelecting = createSelector(
  [(state: RootState) => state.editor.dragSelect],
  (value) => value !== null
);

export const selectAllColors = createSelector(
  [(state: RootState) => state.editor.layers],
  (layers) => {
    const allColors: string[] = [];

    Object.values(layers).forEach((layer) => {
      if ("backgroundColor" in layer) {
        allColors.push(layer.backgroundColor);
      }

      if ("color" in layer) {
        allColors.push(layer.color);
      }

      if ("borderColor" in layer) {
        allColors.push(layer.borderColor);
      }

      if ("fill" in layer) {
        allColors.push(layer.fill);
      }

      if ("outlineColor" in layer) {
        allColors.push(layer.outlineColor);
      }
    });

    const validColors = allColors.filter((color) => colord(color).isValid());

    const colorsConvertedToRgb = validColors.map((color) =>
      colord(color).toRgbString()
    );

    const uniqueColors = [...new Set(colorsConvertedToRgb)];

    return uniqueColors;
  }
);

export const selectSelectedAccordionItemId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    let selectedAccordionItemId: string | null = null as string | null;

    selectedLayerIds.forEach((selectedLayerId) => {
      const ancestorIds = getAncestorIds(layers, selectedLayerId);

      const accordionItemId = [selectedLayerId, ...ancestorIds].find((id) => {
        const layer = layers[id];

        if (!layer || layer.type !== LayerType.Accordion.Item) return false;

        return true;
      });

      if (accordionItemId === undefined) return;

      selectedAccordionItemId = accordionItemId;
    });

    return selectedAccordionItemId;
  }
);

export const selectShouldDisplayGroupLayersAction = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => checkIfCanGroupLayers(layers, selectedLayerIds)
);

export const selectShouldDisplayUngroupLayersAction = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) =>
    checkIfCanUngroupLayers(layers, selectedLayerIds)
);

export const selectBackgroundImageOverlay = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return null;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("backgroundImageOverlay" in layer)) return null;

    return layer.backgroundImageOverlay;
  }
);

export const selectBackgroundPositionX = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("backgroundPositionX" in layer)) return 0;

    return layer.backgroundPositionX;
  }
);

export const selectBackgroundPositionY = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("backgroundPositionY" in layer)) return 0;

    return layer.backgroundPositionY;
  }
);

export const selectLayoutGuideColor = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return "rgba(0,0,0,0)";

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideColor" in layer))
      return "rgba(0,0,0,0)";

    return layer.layoutGuideColor;
  }
);

export const selectLayoutGuideColumns = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideColumns" in layer)) return 0;

    return layer.layoutGuideColumns;
  }
);

type SelectLayoutMargin = (state: RootState) => number | "Mixed";

export const selectLayoutGuideVerticalMargin: SelectLayoutMargin =
  createSelector(
    [
      (state: RootState) => state.editor.layers,
      (state: RootState) => state.editor.selectedLayerIds,
      (state: RootState) => state.editor.breakpoint,
    ],
    (layers, selectedLayerIds, breakpoint) => {
      const firstSelectedLayerId = selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return "Mixed";

      const layer = getOverridenLayer({
        layers,
        id: firstSelectedLayerId,
        breakpoint,
      });

      if (
        layer === null ||
        !("layoutGuideMarginTop" in layer) ||
        !("layoutGuideMarginBottom" in layer)
      )
        return "Mixed";

      if (layer.layoutGuideMarginTop !== layer.layoutGuideMarginBottom)
        return "Mixed";

      return layer.layoutGuideMarginTop;
    }
  );

export const selectLayoutGuideHorizontalMargin: SelectLayoutMargin =
  createSelector(
    [
      (state: RootState) => state.editor.layers,
      (state: RootState) => state.editor.selectedLayerIds,
      (state: RootState) => state.editor.breakpoint,
    ],
    (layers, selectedLayerIds, breakpoint) => {
      const firstSelectedLayerId = selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return "Mixed";

      const layer = getOverridenLayer({
        layers,
        id: firstSelectedLayerId,
        breakpoint,
      });

      if (
        layer === null ||
        !("layoutGuideMarginLeft" in layer) ||
        !("layoutGuideMarginRight" in layer)
      )
        return "Mixed";

      if (layer.layoutGuideMarginLeft !== layer.layoutGuideMarginRight)
        return "Mixed";

      return layer.layoutGuideMarginLeft;
    }
  );

export const selectLayoutGuideColumnGap = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideColumnGap" in layer)) return 0;

    return layer.layoutGuideColumnGap;
  }
);

export const selectLayoutGuideMarginTop = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideMarginTop" in layer)) return 0;

    return layer.layoutGuideMarginTop;
  }
);

export const selectLayoutGuideMarginRight = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideMarginRight" in layer)) return 0;

    return layer.layoutGuideMarginRight;
  }
);

export const selectLayoutGuideMarginBottom = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideMarginBottom" in layer)) return 0;

    return layer.layoutGuideMarginBottom;
  }
);

export const selectLayoutGuideMarginLeft = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return 0;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideMarginLeft" in layer)) return 0;

    return layer.layoutGuideMarginLeft;
  }
);

export const selectLayoutGuideEnabled = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.breakpoint,
  ],
  (layers, selectedLayerIds, breakpoint) => {
    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) return false;

    const layer = getOverridenLayer({
      layers,
      id: firstSelectedLayerId,
      breakpoint,
    });

    if (layer === null || !("layoutGuideEnabled" in layer)) return false;

    return layer.layoutGuideEnabled;
  }
);
