import { useImages } from "@/features/sidebar/api/getImages";
import { useUploadImages } from "@/features/sidebar/hooks";
import React, { ChangeEventHandler } from "react";
import { toast } from "@core/toast";
import Masonry from "react-responsive-masonry";
import { PopoverContent, PopoverPortal, PopoverRoot } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { parseImageUrl } from "@/utils/parseImageUrl";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";

function MasonryImages({
  images,
  error,
  isLoading,
  onBackgroundImageUrlChange,
}: {
  images: string[] | undefined;
  onBackgroundImageUrlChange: (url: string) => void;
  error: unknown;
  isLoading: boolean;
}) {
  if (isLoading) {
    return (
      <div className="grid grid-cols-2 gap-2">
        <div className="aspect-square animate-pulse bg-gray-4" />
        <div className="aspect-square animate-pulse bg-gray-4" />
      </div>
    );
  }

  if (error !== undefined) {
    return <p className="text-xs">There was an issue loading the images</p>;
  }

  if (images === undefined) return null;

  const imagesUrl = images.map((handle) => parseImageUrl(handle)).reverse();

  return (
    <Masonry columnsCount={2} gutter="8px">
      {imagesUrl.map((url) => (
        <button
          type="button"
          onClick={() => onBackgroundImageUrlChange(url)}
          key={url}
        >
          <img src={url} alt="User uploaded" />
        </button>
      ))}
    </Masonry>
  );
}

export function SelectImage({
  onBackgroundImageUrlChange,
}: {
  onBackgroundImageUrlChange: (url: string) => void;
}) {
  const {
    data: images,
    mutate: revalidateImages,
    error,
    isLoading,
  } = useImages();

  const isInsidePlayground = useIsInsidePlayground();
  const uploadImages = useUploadImages();

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const clearFileInput = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.value = "";
  };

  const handleUploadImages: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    if (event.target.files === null) return;

    if (isInsidePlayground) {
      toast.error(
        "Image uploads are currently not supported in the playground"
      );
      return;
    }

    await uploadImages(event.target.files, async () => {
      clearFileInput();
      await revalidateImages();
    });
  };

  return (
    <>
      <input
        type="file"
        className="hidden"
        onChange={handleUploadImages}
        ref={fileInputRef}
        accept="image/*"
        multiple
      />
      <div className="scroll-without-scrollbar flex max-h-60 w-60 flex-col gap-3">
        <button
          className="rounded-sm border border-gray-6 bg-gray-3 py-2 text-xs text-gray-12"
          type="button"
          onClick={handleClick}
        >
          Upload image
        </button>
        <MasonryImages
          error={error}
          images={images}
          isLoading={isLoading}
          onBackgroundImageUrlChange={onBackgroundImageUrlChange}
        />
      </div>
    </>
  );
}

export function ImagePicker({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (url: string) => void;
}) {
  return (
    <PopoverRoot>
      <Popover.Trigger className="flex h-8 items-center gap-2 rounded-sm border border-gray-6 bg-gray-2 px-2">
        <div className="h-5 w-5 rounded border border-gray-6 bg-white">
          <img
            src={value}
            className="h-full w-full object-contain"
            alt="Selected background"
          />
        </div>
        <p className="text-xs text-gray-12">Image</p>
      </Popover.Trigger>
      <PopoverPortal>
        <PopoverContent
          align="center"
          side="right"
          sideOffset={16}
          className="z-50"
        >
          <div className="flex flex-col gap-2 rounded-md border border-solid border-gray-6 bg-gray-1 p-2">
            <SelectImage onBackgroundImageUrlChange={onValueChange} />
          </div>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
}
