import { LeftIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/HorizontalIndicator/LeftIndicator";
import { RightIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/HorizontalIndicator/RightIndicator";
import { checkLayersAreIntersectingHorizontally } from "@/features/editor/components/AltHoverDistanceIndicator/utils/checkLayersAreIntersectingHorizontally";
import { Box, LayerId } from "@folds/shared";

const checkIfTargetLayerIsRight = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) =>
  targetLayerBoundingBox.left + targetLayerBoundingBox.width >
  selectionBoundingBox.left;

export function HorizontalIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const isIntersectingHorizontally = checkLayersAreIntersectingHorizontally({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  if (isIntersectingHorizontally) {
    return null;
  }

  const isPositionedToTheRight = checkIfTargetLayerIsRight({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  if (isPositionedToTheRight) {
    return (
      <RightIndicator
        targetLayerId={targetLayerId}
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
    );
  }

  return (
    <LeftIndicator
      selectionBoundingBox={selectionBoundingBox}
      targetLayerBoundingBox={targetLayerBoundingBox}
      targetLayerId={targetLayerId}
    />
  );
}
