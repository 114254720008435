import {
  Layer,
  accordionChevronLayerKeys,
  accordionContentLayerKeys,
  accordionItemLayerKeys,
  accordionRootKeys,
  accordionTriggerLayerKeys,
  blockLayerKeys,
  breakpointLayerKeys,
  buttonLayerKeys,
  rootOptionSelectorKeys,
  optionSelectorItemKeys,
  pageLayerKeys,
  productImageCarouselFeaturedImageKeys,
  productImageCarouselRootKeys,
  productImageCarouselThumbnailKeys,
  productImageCarouselThumbnailsKeys,
  rectangleLayerKeys,
  textLayerKeys,
  carouselRootLayerKeys,
  carouselSlideLayerKeys,
  quantityPickerKeys,
  optionDropdownLayerKeys,
  iconKeys,
  groupLayerKeys,
  liquidLayerKeys,
} from "@folds/shared/types";

export const checkIfLayerTypeHasProperty = (
  layerType: Layer["type"],
  key: string
) => {
  switch (layerType) {
    case "breakpoint": {
      return breakpointLayerKeys.Check(key);
    }
    case "block": {
      return blockLayerKeys.Check(key);
    }
    case "rectangle": {
      return rectangleLayerKeys.Check(key);
    }
    case "text": {
      return textLayerKeys.Check(key);
    }
    case "page": {
      return pageLayerKeys.Check(key);
    }
    case "button": {
      return buttonLayerKeys.Check(key);
    }
    case "product-image-carousel-featured-image": {
      return productImageCarouselFeaturedImageKeys.Check(key);
    }
    case "product-image-carousel-thumbnail": {
      return productImageCarouselThumbnailKeys.Check(key);
    }
    case "product-image-carousel-thumbnails": {
      return productImageCarouselThumbnailsKeys.Check(key);
    }
    case "product-image-carousel-root": {
      return productImageCarouselRootKeys.Check(key);
    }
    case "accordion-chevron": {
      return accordionChevronLayerKeys.Check(key);
    }
    case "accordion-content": {
      return accordionContentLayerKeys.Check(key);
    }
    case "accordion-item": {
      return accordionItemLayerKeys.Check(key);
    }
    case "accordion-root": {
      return accordionRootKeys.Check(key);
    }
    case "accordion-trigger": {
      return accordionTriggerLayerKeys.Check(key);
    }
    case "option-selector-root": {
      return rootOptionSelectorKeys.Check(key);
    }
    case "option-selector-item": {
      return optionSelectorItemKeys.Check(key);
    }
    case "carousel-root": {
      return carouselRootLayerKeys.Check(key);
    }
    case "carousel-slide": {
      return carouselSlideLayerKeys.Check(key);
    }
    case "quantity-picker": {
      return quantityPickerKeys.Check(key);
    }
    case "option-dropdown": {
      return optionDropdownLayerKeys.Check(key);
    }
    case "icon": {
      return iconKeys.Check(key);
    }
    case "group": {
      return groupLayerKeys.Check(key);
    }
    case "liquid": {
      return liquidLayerKeys.Check(key);
    }
  }
};
