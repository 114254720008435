import { useDrop } from "@/features/editor/dragging/drop";
import { useEventConnector } from "@core/events";
import { selectIsResizing } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { nanoid } from "@core/lib";
import { RootState } from "@core/store";
import {
  generateAccordionChevronStyles,
  generateAccordionContentStyles,
  generateAccordionContentWrapperStyles,
  generateAccordionItemStyles,
  generateAccordionRootStyles,
  generateAccordionTriggerStyles,
  getOverridenLayer,
} from "@folds/shared/layers";
import {
  AccordionChevronLayer,
  AccordionContentLayer,
  AccordionRootLayer,
  AccordionTriggerLayer,
  LayerId,
  LayerType,
} from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

export function AccordionRoot({
  children,
  layer,
}: PropsWithChildren<{ layer: AccordionRootLayer }>) {
  const { handleMouseLeave, handleMouseOver } = useEventConnector(layer.id);

  const [key, rerender] = useState(nanoid());

  // When an accordion item is added or removed, we need to force a rerender since the event listeners are added on mount.
  useEffect(() => {
    rerender(nanoid());
  }, [layer.children]);

  const styles = generateAccordionRootStyles(layer);

  return (
    <folds-accordion-root
      style={styles}
      id={layer.id}
      aria-label="Accordion root"
      key={key}
      // We want to move layers out of the entire accordion when the mouse leaves the accordion.
      // If we have onMouseLeave on the content / trigger, the layer will end up as a child of the root accordion element instead of outside of it.
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      {children}
    </folds-accordion-root>
  );
}

export function AccordionItem({
  children,
  id,
}: PropsWithChildren<{ id: LayerId }>) {
  const accordionItemStyle = generateAccordionItemStyles();

  return (
    <folds-accordion-item
      aria-label="Accordion item"
      id={id}
      style={accordionItemStyle}
    >
      {children}
    </folds-accordion-item>
  );
}

const selectAccordionContentIdFromTriggerId = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState, id: LayerId) => id,
  ],
  (layers, id) => {
    const layer = layers[id];

    if (layer?.type !== LayerType.Accordion.Trigger) return null;

    const accoordionItem = layers[layer.parentId];

    if (accoordionItem?.type !== LayerType.Accordion.Item) return null;

    const accordionItemChildLayers = accoordionItem.children.map(
      (childId) => layers[childId]
    );

    const accordionContentLayer = accordionItemChildLayers.find(
      (child) => child?.type === LayerType.Accordion.Content
    );

    return accordionContentLayer?.id ?? null;
  }
);

export function AccordionTrigger({
  children,
  layer,
}: PropsWithChildren<{ layer: AccordionTriggerLayer }>) {
  const accordionContentId = useAppSelector((state) =>
    selectAccordionContentIdFromTriggerId(state, layer.id)
  );

  const { handleMouseDown, handleMouseOver } = useEventConnector(layer.id);

  const styles = generateAccordionTriggerStyles(layer);

  if (accordionContentId === null) return null;

  return (
    <folds-accordion-trigger
      role="button"
      style={styles}
      id={layer.id}
      aria-disabled
      aria-label="Accordion trigger layer"
      aria-controls={accordionContentId}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
    >
      {children}
    </folds-accordion-trigger>
  );
}

const selectLayerHeight = createSelector(
  [
    (_, id: LayerId) => id,
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.breakpoint,
  ],
  (id, layers, breakpoint) => {
    const layer = getOverridenLayer({ breakpoint, id, layers });

    if (!layer || !("height" in layer)) return 0;

    return layer.height;
  }
);

const useSyncAccordionContentHeight = (layerId: LayerId) => {
  const ref = useRef<HTMLElement>(null);

  const height = useAppSelector((state) => selectLayerHeight(state, layerId));

  useEffect(() => {
    if (ref.current === null || ref.current.ariaHidden !== "false") return;

    // When resizing the accordion content, we need to set the height of the wrapper to the height of the content.
    ref.current.style.height = `${height}px`;
  }, [height]);

  return ref;
};

export function AccordionContent({
  children,
  layer,
}: PropsWithChildren<{ layer: AccordionContentLayer }>) {
  const { handleMouseDown, handleMouseEnter, handleMouseOver } =
    useEventConnector(layer.id);

  const isReiszing = useAppSelector(selectIsResizing);
  const ref = useSyncAccordionContentHeight(layer.id);

  const handleDrop = useDrop(layer.id);
  const wrapperStyle = generateAccordionContentWrapperStyles();

  const style = generateAccordionContentStyles(layer);

  return (
    <folds-accordion-content
      role="region"
      id={layer.id}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      aria-label="Accordion content layer"
      style={{ ...wrapperStyle, ...(isReiszing && { transition: "none" }) }}
      ref={ref}
      onDrop={handleDrop}
      onMouseOver={handleMouseOver}
    >
      <div style={style}>{children}</div>
    </folds-accordion-content>
  );
}

export function AccordionChevron({ layer }: { layer: AccordionChevronLayer }) {
  const { handleMouseDown, handleMouseOver } = useEventConnector(layer.id);

  const style = generateAccordionChevronStyles(layer);

  return (
    <folds-accordion-chevron
      style={style}
      id={layer.id}
      aria-label="Accordion chevron layer"
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
    />
  );
}
