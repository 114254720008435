import { LayerType, Layers, SelectedLayerIds } from "@folds/shared/types";
import { useCallback, useEffect, useState } from "react";

import { getElement } from "@core/utils";
import {
  selectIsDragging,
  selectIsResizing,
} from "@core/features/editor/editorSlice";
import { useAppSelector, useResize } from "../../hooks";
import * as s from "./ResizeIndicator.styles";
import { ElementOutlines } from "./ElementOutlines";

const checkIfTextLayerSelected = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) =>
  Object.values(selectedLayerIds).some((layerId) => {
    const layer = layers[layerId];

    if (!layer) return false;

    return layer.type === LayerType.Text;
  });

export const getFixedBoundingClientRect = (
  selectedLayerIds: SelectedLayerIds
) => {
  const topPositionArray: number[] = [];
  const leftPositionArray: number[] = [];
  const bottomPositionArray: number[] = [];
  const rightPositionArray: number[] = [];

  selectedLayerIds.forEach((selectedLayerId) => {
    const element = getElement(selectedLayerId);
    if (!element) return;

    const { top, left, bottom, right } = element.getBoundingClientRect();

    topPositionArray.push(top);
    leftPositionArray.push(left);
    bottomPositionArray.push(bottom);
    rightPositionArray.push(right);
  });

  const top = topPositionArray.length > 0 ? Math.min(...topPositionArray) : 0;
  const left =
    leftPositionArray.length > 0 ? Math.min(...leftPositionArray) : 0;
  const bottom =
    bottomPositionArray.length > 0 ? Math.max(...bottomPositionArray) : 0;
  const right =
    rightPositionArray.length > 0 ? Math.max(...rightPositionArray) : 0;

  return {
    top,
    left,
    height: bottom - top,
    width: right - left,
  };
};

const useResizeIndicatorBoundingBox = () => {
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const calculateBoundingRect = useCallback(() => {
    const box = getFixedBoundingClientRect(selectedLayerIds);

    const isIdentical =
      box.width === width &&
      box.height === height &&
      box.top === top &&
      box.left === left;

    if (isIdentical) return;

    setTop(box.top);
    setLeft(box.left);
    setWidth(box.width);
    setHeight(box.height);
  }, [height, left, selectedLayerIds, top, width]);

  // Haven't found a good way to check when boundingBoxRect changes, so we just check every 25ms for changes
  useEffect(() => {
    const cleanup = setInterval(() => {
      calculateBoundingRect();
    }, 25);

    calculateBoundingRect();

    return () => {
      clearInterval(cleanup);
    };
  }, [calculateBoundingRect]);

  return {
    top,
    left,
    width,
    height,
  };
};

// Needs to not be a child of layer or else it will be affected by the layer's overflow property
export function ResizeIndicator() {
  const layers = useAppSelector((state) => state.editor.layers);
  const isDragging = useAppSelector(selectIsDragging);
  const isResizing = useAppSelector(selectIsResizing);
  const isViewportTransforming = useAppSelector(
    (state) => state.editor.isViewportTransforming
  );
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const { height, left, top, width } = useResizeIndicatorBoundingBox();

  const initializeDrag = useResize();

  if (Object.keys(selectedLayerIds).length === 0) return null;

  // Prevent the event from bubbling up to the parent layer and de-selecting the layer
  const stopEventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const isTextLayerSelected = checkIfTextLayerSelected(
    layers,
    selectedLayerIds
  );

  const isDisplaying =
    isDragging === false &&
    isResizing === false &&
    isViewportTransforming === false;

  if (!isDisplaying) return null;

  return (
    <>
      <ElementOutlines selectedLayerIds={selectedLayerIds} />
      <div
        className="pointer-events-none fixed z-40 outline outline-accent-9"
        data-testid="resize-indicator"
        style={{
          top,
          left,
          width,
          height,
        }}
        onMouseDown={stopEventPropagation}
      >
        {/* Side handle */}
        <s.HandleLeftSide
          resizing
          onMouseDown={(event) => initializeDrag(event, "left")}
          data-testid="left-resize-handle"
          aria-label="Resize left side"
        />
        <s.HandleTopSide
          aria-label="Resize top side"
          data-testid="top-resize-handle"
          resizing={!isTextLayerSelected}
          onMouseDown={(event) => {
            if (!isTextLayerSelected) initializeDrag(event, "top");
          }}
        />
        <s.HandleRightSide
          resizing
          aria-label="Resize right side"
          onMouseDown={(event) => initializeDrag(event, "right")}
          data-testid="right-resize-handle"
        />
        <s.HandleBottomSide
          resizing={!isTextLayerSelected}
          data-testid="bottom-resize-handle"
          aria-label="Resize bottom side"
          onMouseDown={(event) => {
            if (!isTextLayerSelected) initializeDrag(event, "bottom");
          }}
        />
        {/* Corner handle */}
        <s.HandleTopLeft
          data-testid="top-left-resize-handle"
          aria-label="Resize top left corner"
          onMouseDown={(event) => initializeDrag(event, "top-left")}
        />
        <s.HandleTopRight
          data-testid="top-right-resize-handle"
          aria-label="Resize top right corner"
          onMouseDown={(event) => initializeDrag(event, "top-right")}
        />
        <s.HandleBottomLeft
          data-testid="bottom-left-resize-handle"
          aria-label="Resize bottom left corner"
          onMouseDown={(event) => initializeDrag(event, "bottom-left")}
        />
        <s.HandleBottomRight
          data-testid="bottom-right-resize-handle"
          aria-label="Resize bottom right corner"
          onMouseDown={(event) => initializeDrag(event, "bottom-right")}
        />
      </div>
    </>
  );
}
