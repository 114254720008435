import { AddNewBlock } from "@/features/editor/components/AddNewBlock";
import { CanvasWrapper } from "@/features/editor/components/CanvasWrapper";
import { DraggedIntoLayerIndicator } from "@/features/editor/components/DraggedIntoLayerIndicator";
import { SelectionCover } from "@/features/editor/components/SelectionCover";
import { useCopyPaste } from "@/features/editor/hooks/useCopyPaste";
import { DragSelectSeleciton } from "@/features/editor/hooks/useDragSelect";
import { useLoadFonts } from "@/features/editor/hooks/useLoadFonts";
import { useCanvasEvents } from "@/features/editor/utils/useCanvasEvents";
import { loadPage } from "@core/features/editor/editorSlice";
import { useTransformViewport } from "@core/features/viewport/useTransformViewport";
import { AbsoluteGuides, ResizeIndicator } from "@core/indicators";
import { useHandPan } from "@core/indicators/useHandPan";
import { useAxios } from "@lib/axios";
import { ContextMenu } from "@/features/editor/components/ContextMenu";
import { useUnsavedChangesPrompt } from "@/features/editor/hooks/useUnsavedChangesPrompt";
import { useTypographyHotkeys } from "@/features/editor/hooks/useTypographyHotkeys";
import { usePreventHighlightingTextWhenDraggingOrDragSelecting } from "@/features/editor/hooks/usePreventSelectingTextWhenDragging";
import {
  useAppDispatch,
  useAppSelector,
  useCanvasHotkeys,
  useInitialViewport,
} from "@core/hooks";
import { RenderPage } from "@/core/render";
import { useAltHoverDistanceKeyEvents } from "@core/hooks/useAltHoverDistanceEvents";
import { AltHoverDistanceIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/AltHoverDistanceIndicator";
import { useRecalculateGuideStopLinesAfterDuplicating } from "@/features/editor/hooks/useRecalculateGuideStopLinesAfterDuplicating";
import { useEffect } from "react";
import { DevelopmentSaveBlock } from "@/features/editor/components/DevelopmentSaveComponent/DevelopmentSaveBlock";
import { DevelopmentSaveTemplate } from "@/features/editor/components/DevelopmentSaveComponent/DevelopmentSaveTemplate";
import { DevelopmentSaveElement } from "@/features/editor/components/DevelopmentSaveComponent/DevelopmentSaveElement";
import { CanvasErrorBoundary } from "@/features/editor/components/CanvasErrorBoundary";
import { useCenterViewportPositionToBlockOnBreakpointUpdate } from "@/features/editor/hooks/useCenterViewportPositionToBlockOnBreakpointUpdate";
import { useDisableAltHoverDistanceWhenSwitchingTabs } from "@core/hooks/useDisableAltHoverDistanceWhenSwitchingTabs";
import { usePageId } from "@/features/editor/context/PageId";
import { store } from "@core/store";
import { usePages } from "@/features/dashboard/api/getPages";
import { Spinner } from "@common";
import { SmallScreenSizeWarning } from "@/features/editor/components/MinScreenSize";

/**
 * Fetches the page data from the server and loads it into the editor.
 */
const useLoadPage = () => {
  const axios = useAxios();
  const dispatch = useAppDispatch();
  const pageId = usePageId();
  const { mutate: refetchPages } = usePages();

  useEffect(() => {
    const loadPages = async () => {
      const { layers } = store.getState().editor;
      if (Object.keys(layers).length > 0) return;

      await dispatch(loadPage({ axios, pageId }));
      // The viewedAt property is updated when the page is viewed which affects the order of the pages in the dashboard
      await refetchPages();
    };

    loadPages().catch(() => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export function Canvas() {
  useLoadPage();

  const loadingState = useAppSelector((state) => state.editor.loadingState);
  const error = useAppSelector((state) => state.editor.error);

  const translateX = useAppSelector((state) => state.editor.translateX);
  const translateY = useAppSelector((state) => state.editor.translateY);
  const scale = useAppSelector((state) => state.editor.scale);

  useCanvasEvents();
  useInitialViewport();
  useTransformViewport();
  useCanvasHotkeys();
  useLoadFonts({ isInsideIframe: true });
  useHandPan();
  useCopyPaste();
  useUnsavedChangesPrompt();
  useTypographyHotkeys();
  usePreventHighlightingTextWhenDraggingOrDragSelecting();
  useAltHoverDistanceKeyEvents();
  useDisableAltHoverDistanceWhenSwitchingTabs();
  useRecalculateGuideStopLinesAfterDuplicating();
  useCenterViewportPositionToBlockOnBreakpointUpdate();

  const transform = `translateX(${translateX}px) translateY(${translateY}px) scale(${scale})`;

  if (loadingState === "loading") {
    return (
      <div className="absolute left-[calc((100vw_+_225px)_/_2)] top-72 -translate-x-1/2">
        <Spinner />
      </div>
    );
  }

  if (loadingState === "error") {
    return <div>{error ?? "There was an unexpected error"}</div>;
  }

  return (
    <CanvasErrorBoundary>
      <CanvasWrapper>
        <ResizeIndicator />
        <ContextMenu />
        <AltHoverDistanceIndicator />
        <DevelopmentSaveElement />
        <DevelopmentSaveBlock />
        <DevelopmentSaveTemplate />
        <SmallScreenSizeWarning />
        <div style={{ transform }} className="fixed left-0 top-0" id="canvas">
          <DragSelectSeleciton />
          <AddNewBlock />
          <SelectionCover />
          <DraggedIntoLayerIndicator />
          <AbsoluteGuides />
          <RenderPage />
        </div>
      </CanvasWrapper>
    </CanvasErrorBoundary>
  );
}
