import { generateTypographyStyles } from './utils';
import { OptionDropdownLayer } from '../types';

export const generateOptionDropdownSelectStyles = (
  layer: OptionDropdownLayer
): React.CSSProperties => {
  const typographyStyles = generateTypographyStyles(layer);

  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    backgroundColor,
    width,
    height,
    outlineColor,
    outlineOffset,
    outlineWidth,
  } = layer;

  return {
    all: 'initial',
    ...typographyStyles,
    boxSizing: 'border-box',
    backgroundColor,
    width,
    height,
    borderBottomLeftRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderLeftWidth,
    borderTopWidth,
    borderRightWidth,
    borderStyle,
    borderColor,
    paddingLeft: 16,
    backgroundImage: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="${layer.iconColor}" viewBox="0 -960 960 960"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/></svg>')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${layer.iconSize}px ${layer.iconSize}px`,
    backgroundPosition: 'calc(100% - 16px)',
    display: 'flex',
    alignItems: 'center',
    outlineColor,
    outlineOffset,
    outlineWidth,
    outlineStyle: 'solid',
  };
};

export const generateOptionDropdownWrapperStyles = (
  layer: OptionDropdownLayer
): React.CSSProperties => {
  const { top, left, zIndex, visible } = layer;

  return {
    all: 'initial',
    zIndex,
    top,
    left,
    position: 'absolute',
    display: visible ? 'block' : 'none',
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};
