import { useEventConnector } from "@core/events";
import { generateRectangleStyles } from "@folds/shared/layers";
import { RectangleLayer } from "@folds/shared/types";

type Props = {
  layer: RectangleLayer;
};

export function Rectangle({ layer }: Props) {
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleContextMenu,
    handleMouseOver,
  } = useEventConnector(layer.id);

  const style = generateRectangleStyles(layer);

  return (
    <div
      className="rectangle"
      aria-label="Rectangle layer"
      id={layer.id}
      onContextMenu={handleContextMenu}
      style={{ ...style, cursor: "pointer" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
    />
  );
}
