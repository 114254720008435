export function CompletedOnboarding({
  onComplete,
}: {
  onComplete: () => void;
}) {
  return (
    <div
      onMouseDown={(event) => event.stopPropagation()}
      className="absolute bottom-3 left-[calc((100vw_+_326px)_/_2)] z-[999]  flex w-[500px] -translate-x-1/2 flex-col gap-6 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
    >
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-12">
            Tutorial completed
          </h3>
        </div>
        <p className="text-base text-gray-12">
          We can&apos;t wait to see what you&apos;ll build!
        </p>
      </div>
      <button
        type="button"
        onClick={onComplete}
        className="hover:bg-primary rounded bg-gray-12 py-3 text-gray-1 transition-colors duration-200"
      >
        Complete tutorial
      </button>
    </div>
  );
}
