import type { DraftEditorState } from "@core/features/editor/editorSlice";
import { checkIfIsResizingAnIcon } from "@core/features/editor/editorSlice/actions/resizingAction/utils/checkIfIsResizingAnIcon";
import {
  resizeBottomSide,
  resizeLeftSide,
  resizeRightSide,
  resizeTopSide,
} from "@core/features/editor/editorSlice/actions/resizingAction/utils/resizeSide";
import {
  scaleBottomLeft,
  scaleBottomRight,
  scaleTopLeft,
  scaleTopRight,
} from "@core/features/editor/editorSlice/actions/resizingAction/utils/scaling";
import {
  getSnappedDeltaY,
  getSnappedMouseX,
} from "@core/features/editor/editorSlice/actions/resizingAction/utils/snapping";
import { Point } from "@folds/shared/types";

export const resizeAction = (state: DraftEditorState, mousePoint: Point) => {
  if (state.resizing === null) return;

  const {
    type,
    initialMousePoint,
    initialLayersInformation,
    shiftKey,
    box,
    guideStopLines,
  } = state.resizing;

  const mouseDeltaY =
    mousePoint.y / state.scale - initialMousePoint.y / state.scale;

  const mouseDeltaX =
    mousePoint.x / state.scale - initialMousePoint.x / state.scale;

  const isResizingIcon = checkIfIsResizingAnIcon(
    state.layers,
    state.selectedLayerIds
  );

  const isTextLayerSelected = state.selectedLayerIds.some(
    (layerId) => state.layers[layerId]?.type === "text"
  );

  const isScalingProportionally =
    shiftKey || initialLayersInformation.length > 1 || isResizingIcon;

  switch (type) {
    case "top": {
      if (isScalingProportionally) return;

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type,
      });

      resizeTopSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "right": {
      if (isScalingProportionally) return;

      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type,
      });

      resizeRightSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "bottom": {
      if (isScalingProportionally) return;

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type,
      });

      resizeBottomSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "left": {
      if (isScalingProportionally) return;

      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type,
      });

      resizeLeftSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "top-left": {
      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type: "left",
      });

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type: "top",
      });

      if (isTextLayerSelected || isScalingProportionally) {
        scaleTopLeft({
          deltaX,
          deltaY,
          layers: state.layers,
          resizing: state.resizing,
          breakpoint: state.breakpoint,
        });

        return;
      }

      resizeTopSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      resizeLeftSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "top-right": {
      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type: "right",
      });

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type: "top",
      });

      if (isTextLayerSelected || isScalingProportionally) {
        scaleTopRight({
          deltaX,
          deltaY,
          layers: state.layers,
          resizing: state.resizing,
          breakpoint: state.breakpoint,
        });

        return;
      }

      resizeTopSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      resizeRightSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "bottom-right": {
      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type: "right",
      });

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type: "bottom",
      });

      if (isTextLayerSelected || isScalingProportionally) {
        scaleBottomRight({
          deltaX,
          deltaY,
          breakpoint: state.breakpoint,
          layers: state.layers,
          resizing: state.resizing,
        });
        return;
      }

      resizeBottomSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      resizeRightSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
    case "bottom-left": {
      const deltaX = getSnappedMouseX({
        mouseDeltaX,
        box,
        lines: guideStopLines.vertical,
        type: "left",
      });

      const deltaY = getSnappedDeltaY({
        box,
        lines: guideStopLines.horizontal,
        mouseDeltaY,
        type: "bottom",
      });

      if (isScalingProportionally || isTextLayerSelected) {
        scaleBottomLeft({
          deltaX,
          deltaY,
          layers: state.layers,
          resizing: state.resizing,
          breakpoint: state.breakpoint,
        });
        return;
      }

      resizeBottomSide({
        breakpoint: state.breakpoint,
        deltaY,
        initialLayersInformation,
        layers: state.layers,
      });

      resizeLeftSide({
        breakpoint: state.breakpoint,
        deltaX,
        initialLayersInformation,
        layers: state.layers,
      });

      break;
    }
  }
};
