import type { LayerInformationToReplace } from "@core/features/editor/editorSlice/editorSlice";
import { LayerType, type Layers } from "@folds/shared";

export const getLayerInformationToReplace = (
  layers: Layers
): LayerInformationToReplace[] => {
  const informationToReplace: LayerInformationToReplace[] = [];

  Object.values(layers).map((layer) => {
    const isTextSelectedOptionValue =
      layer.type === LayerType.Text &&
      layer.content === "selected-option-value";

    const isReplacingOption =
      layer.type === LayerType.OptionDropdown ||
      layer.type === LayerType.OptionSelector.Root ||
      isTextSelectedOptionValue;

    if (isReplacingOption) {
      informationToReplace.push({
        replaced: false,
        layerId: layer.id,
        type: "option",
      });

      // Since layers that have the "optionName" property in them also have "productId" and "productHandle", so we need to skip the rest of the checks to prevent duplicate entries
      return;
    }

    if (
      "action" in layer &&
      layer.action?.type === "redirect" &&
      layer.action.url !== null
    ) {
      informationToReplace.push({
        replaced: false,
        layerId: layer.id,
        type: "url",
      });
    }

    if (
      "action" in layer &&
      layer.action?.type === "add-to-cart" &&
      layer.action.productId !== null &&
      layer.action.productHandle !== null
    ) {
      informationToReplace.push({
        replaced: false,
        layerId: layer.id,
        type: "addToCartAction",
      });
    }

    if (
      "productId" in layer &&
      layer.productId !== null &&
      layer.productHandle !== null
    ) {
      informationToReplace.push({
        replaced: false,
        layerId: layer.id,
        type: "product",
      });
    }
  });

  return informationToReplace;
};
