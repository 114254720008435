import { useDrop } from "@/features/editor/dragging/drop";
import { useEventConnector } from "@core/events";
import { SwiperCarousel } from "@core/user-components/SwiperCarousel";
import {
  generateCarouselRootStyles,
  generateCarouselSlideStyles,
} from "@folds/shared/layers";
import { CarouselRootLayer, CarouselSlideLayer } from "@folds/shared/types";

export function CarouselRoot({
  children,
  layer,
}: {
  children: React.ReactNode;
  layer: CarouselRootLayer;
}) {
  const style = generateCarouselRootStyles(layer);

  const { handleMouseLeave, handleMouseOver, handleContextMenu } =
    useEventConnector(layer.id);

  return (
    <SwiperCarousel
      layer={layer}
      style={style}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      onContextMenu={handleContextMenu}
    >
      {children}
    </SwiperCarousel>
  );
}

export function CarouselSlide({
  layer,
  children,
}: {
  layer: CarouselSlideLayer;
  children: React.ReactNode;
}) {
  const {
    handleMouseDown,
    handleMouseEnter,
    handleMouseOver,
    handleContextMenu,
  } = useEventConnector(layer.id);

  const handleDrop = useDrop(layer.id);

  const style = generateCarouselSlideStyles(layer);

  return (
    <swiper-slide
      id={layer.id}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onDrop={handleDrop}
      onMouseOver={handleMouseOver}
      onContextMenu={handleContextMenu}
    >
      {children}
    </swiper-slide>
  );
}
