import { useSaveLayers } from "@/features/editor/api/saveLayers";
import { useFrame } from "@/features/editor/context/FrameContext";
import { checkIfIsModKey } from "@/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect } from "react";

export function LexicalHotkeysPlugin() {
  const [editor] = useLexicalComposerContext();
  const { window: iFrameWindow } = useFrame();
  const saveLayers = useSaveLayers();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "Escape": {
          event.stopPropagation();
          if (iFrameWindow === undefined) return;

          const selection = iFrameWindow.getSelection();

          selection?.empty();

          editor.setEditable(false);

          break;
        }
        // Prevents the default save page dialog from opening
        case "s": {
          const isModKey = checkIfIsModKey(event);

          if (!isModKey) return;

          saveLayers();

          event.preventDefault();
        }
      }
    },
    [editor, iFrameWindow, saveLayers]
  );

  useEffect(() => {
    const contentEditable = editor.getRootElement();

    if (contentEditable === null) return;

    contentEditable.addEventListener("keydown", handleKeyDown);

    return () => {
      contentEditable.removeEventListener("keydown", handleKeyDown);
    };
  }, [editor, handleKeyDown]);

  return null;
}
