import { Breakpoint, BreakpointLayer, LayerId } from "@folds/shared/types";

import { moveBlockDown, moveBlockUp } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { nanoid } from "@core/lib";
import { clampScale, getSelectedBlocksIds } from "@core/render/blocks/utils";
import { getAbsolutePosition } from "@core/utils";
import { useEffect, useState } from "react";

import * as i from "./BlockActions.icons";
import * as s from "./BlockActions.styles";

function BlockAction({
  blockId,
  scale,
  breakpoint,
  breakpointChildren,
}: {
  blockId: LayerId;
  breakpointChildren: BreakpointLayer["children"];
  scale: number;
  breakpoint: Breakpoint;
}) {
  const dispatch = useAppDispatch();

  const [key, rerender] = useState(nanoid());

  // Stops from deselecting the block
  const stopPropogation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleMoveBlockUp = () => {
    dispatch(moveBlockUp(blockId));
  };

  const handleMoveBlockDown = () => {
    dispatch(moveBlockDown(blockId));
  };

  const { top: blockLayerTop } = getAbsolutePosition(blockId);

  useEffect(() => {
    rerender(nanoid());
  }, [breakpointChildren, breakpoint]);

  return (
    <s.SectionWrapper
      className="z-10"
      key={key}
      $scale={scale}
      $breakpoint={breakpoint}
      $layerId={blockId}
      $top={blockLayerTop}
    >
      <s.BlockActionsButton
        $scale={scale}
        onMouseDown={stopPropogation}
        aria-label="Move block up"
        onClick={handleMoveBlockUp}
      >
        <i.UpArrow />
      </s.BlockActionsButton>
      <s.BlockActionsButton
        $scale={scale}
        onMouseDown={stopPropogation}
        aria-label="Move block down"
        onClick={handleMoveBlockDown}
      >
        <i.DownArrow />
      </s.BlockActionsButton>
      <s.LineSeparator />
    </s.SectionWrapper>
  );
}

type Props = {
  breakpointLayer: BreakpointLayer;
};

export function BlockActions({ breakpointLayer }: Props) {
  const breakpoint = useAppSelector((state) => state.editor.breakpoint);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );
  const scale = useAppSelector((state) => state.editor.scale);

  const selectedBlocks = getSelectedBlocksIds(
    selectedLayerIds,
    breakpointLayer.children
  );

  const clampedScaled = clampScale(scale);

  return (
    <>
      {selectedBlocks.map((blockId) => (
        <BlockAction
          breakpointChildren={breakpointLayer.children}
          key={blockId}
          blockId={blockId}
          breakpoint={breakpoint}
          scale={clampedScaled}
        />
      ))}
    </>
  );
}
