import { Editor } from "@/features/editor/Editor";
import { store } from "@core/store";
import { Provider } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/**
 * Used for running playwright integration tests.
 */
export function Playwright() {
  const { pageId } = useParams();
  const navigate = useNavigate();

  if (import.meta.env.VITE_APP_ENV !== "E2E") {
    navigate("/");
    return null;
  }

  return (
    <Provider store={store}>
      <Editor pageId={pageId as string} />
    </Provider>
  );
}
