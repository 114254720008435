import { setViewport } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { store } from "@core/store";
import { getElement } from "@core/utils";
import { LayerId } from "@folds/shared";
import { useCallback } from "react";

const SIDEBAR_WIDTH = 326;
const OFFSET_TRANSLATE_Y = 150;

export const useCenterViewportPositionToLayer = () => {
  const dispatch = useAppDispatch();

  const updateViewportPosition = useCallback(
    (layerId: LayerId) => {
      const selectedElement = getElement(layerId);
      if (selectedElement === null) return;

      const canvasElement = getElement("canvas");
      if (canvasElement === null) return;

      const box = selectedElement.getBoundingClientRect();
      const canvasBox = canvasElement.getBoundingClientRect();

      const offsetTranslateX = canvasBox.x - box.x;
      const offsetTranslateY = canvasBox.y - box.y;

      const editorScreenSize = window.screen.width - SIDEBAR_WIDTH;

      const translateX =
        offsetTranslateX + 326 + editorScreenSize / 2 - box.width / 2;
      const translateY = offsetTranslateY + OFFSET_TRANSLATE_Y;

      dispatch(
        setViewport({
          scale: store.getState().editor.scale,
          translateX,
          translateY,
        })
      );
    },
    [dispatch]
  );

  return updateViewportPosition;
};
