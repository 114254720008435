import { toast } from "@core/toast";
import { useAppDispatch } from "@core/hooks/redux";
import { usePageId } from "@/features/editor/context/PageId";
import { store } from "@core/store";
import { useAxios } from "@lib/axios";
import { updateSavedLayers } from "@core/features/editor/editorSlice";
import { usePage } from "@/features/dashboard/api/getPage";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";
import * as Sentry from "@sentry/react";

export const useSaveLayers = () => {
  const dispatch = useAppDispatch();
  const pageId = usePageId();
  const axios = useAxios();
  const isInsideOfPlayground = useIsInsidePlayground();
  const { mutate: refetchPage } = usePage(pageId);

  return () => {
    const { layers } = store.getState().editor;

    if (Object.keys(layers).length === 0) return;

    if (isInsideOfPlayground) {
      toast.error("You can't save a page inside of the playground");
      return;
    }

    toast.loading("Saving page...", { id: "save-page" });

    axios
      .put(`/pages/${pageId}`, layers)
      .then(async () => {
        dispatch(updateSavedLayers(layers));
        toast.success("Page saved", { id: "save-page" });

        await refetchPage();
      })
      .catch(() => {
        toast.error(
          "There was an issue saving the page. Contact support if this error persists.",
          { id: "save-page" }
        );

        Sentry.captureMessage(`Failed to save page for page ${pageId}`);
      });
  };
};
