import { getUnixTimestampFromDateString } from "@/features/dashboard/hooks/useLoadIntercom";
import { useShop } from "@/features/editor/api/getShop";
import { useModalHead } from "@/features/editor/hooks/useModalHead";
import { setIsIntercomLoaded } from "@core/features/editor/editorSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useLoadIntercomInModal = () => {
  const modalHead = useModalHead();
  const { data: shop } = useShop();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!modalHead || shop === undefined) return;

    const modalDocument = modalHead.ownerDocument;
    if (!modalDocument.defaultView) return;

    const script = modalDocument.createElement("script");

    modalDocument.defaultView.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "mjl5ou8r",
      name: shop.shopOwner,
      email: shop.email,
      created_at: getUnixTimestampFromDateString(shop.createdAt),
      user_hash: shop.intercomUserHash,
      custom_launcher_selector: "#open-intercom",
    };

    script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/mjl5ou8r';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    modalHead.appendChild(script);

    dispatch(setIsIntercomLoaded(true));
  }, [modalHead, shop, dispatch]);
};
