import { useEventConnector } from "@core/events";
import { LiquidLayer, generateLiquidStyles } from "@folds/shared";

// https://css-tricks.com/snippets/css/system-font-stack/
const fontStack =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export function Liquid({ layer }: { layer: LiquidLayer }) {
  const styles = generateLiquidStyles(layer);

  const { handleContextMenu, handleMouseDown } = useEventConnector(layer.id);

  return (
    <div
      style={styles}
      className="cursor-default select-none"
      id={layer.id}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
    >
      <p
        style={{
          fontFamily: fontStack,
        }}
      >
        Preview the page to view the rendered liquid element
      </p>
    </div>
  );
}
