import { LayerType, Layers, SelectedLayerIds } from "@folds/shared";

export const checkIfCanUngroupLayers = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  if (selectedLayerIds.length !== 1) return false;

  const firstSelectedLayerId = selectedLayerIds[0];

  if (typeof firstSelectedLayerId !== "string") return false;

  const layer = layers[firstSelectedLayerId];

  return layer?.type === LayerType.Group;
};
