import type { EditorState } from "@core/features/editor/editorSlice";
import { Draft } from "immer";

import {
  addIdToNewParent,
  getCurrentLayer,
  getNewParent,
  getParentLayer,
  removeLayerFromParent,
  updateCurrentLayerParentId,
} from "./utils/utils";

type Props = {
  originalLayerId: string;
  newParentId: string;
  newIndex: number | undefined;
};

export const rearrangeLayerAction = (
  state: Draft<EditorState>,
  { originalLayerId, newIndex, newParentId }: Props
) => {
  if (originalLayerId === newParentId) return;

  const currentLayer = getCurrentLayer(state.layers, originalLayerId);
  const currentParent = getParentLayer(state.layers, currentLayer);
  const newParent = getNewParent(state.layers, newParentId);

  removeLayerFromParent(currentParent, originalLayerId);
  addIdToNewParent(newParent, originalLayerId, newIndex);
  updateCurrentLayerParentId(currentLayer, newParentId);
};
