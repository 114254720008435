import { getChildIdsNested } from "@core/utils";
import { LayerId, SelectedLayerIds } from "@folds/shared/types";
import { store } from "@store";

export const getSelectedBlocksIds = (
  selectedLayerIds: SelectedLayerIds,
  breakpointChildren: LayerId[]
) => {
  const {
    editor: { layers },
  } = store.getState();

  return breakpointChildren.filter((blockId) => {
    const deepChildren = getChildIdsNested(layers, blockId);

    const allTargetIds = [blockId, ...deepChildren];

    return allTargetIds.some((targetId) =>
      selectedLayerIds.some((selectedLayerId) => selectedLayerId === targetId)
    );
  });
};
