import { selectAllColors } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";

export function ExistingColorsSwatches({
  onSelect,
}: {
  onSelect: (color: string) => void;
}) {
  const allColors = useAppSelector(selectAllColors);

  const colorPickers = allColors.map((color) => (
    <button
      key={color}
      onClick={() => onSelect(color)}
      type="button"
      aria-label="Swatch"
      style={{ backgroundColor: color }}
      className="aspect-square rounded border border-solid border-gray-6"
    />
  ));

  return (
    <div className="grid max-h-[52px] grid-cols-8 gap-1 overflow-y-scroll">
      {colorPickers}
    </div>
  );
}
