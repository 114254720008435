import { randomizeLayerIds } from "@/features/editor/utils/randomizeLayerIds";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getChildIdsNested } from "@core/utils";
import { LayerId, LayerType, Layers, SelectedLayerIds } from "@folds/shared";
import { current } from "@reduxjs/toolkit";

const getSlideId = ({
  layers,
  carouselId,
  selectedLayerIds,
}: {
  layers: Layers;
  selectedLayerIds: SelectedLayerIds;
  carouselId: LayerId;
}): null | LayerId => {
  const carousel = layers[carouselId];
  if (!carousel || carousel.type !== LayerType.Carousel.Root) return null;

  const lastSlideId = carousel.children[carousel.children.length - 1];
  if (typeof lastSlideId !== "string") return null;

  const selectedLayerId = selectedLayerIds[0];
  if (typeof selectedLayerId !== "string") return lastSlideId;

  const selectedLayer = layers[selectedLayerId];
  if (!selectedLayer || selectedLayer.type !== LayerType.Carousel.Slide)
    return lastSlideId;

  return selectedLayer.id;
};

export const addCarouselSlideAction = (
  state: DraftEditorState,
  carouselId: LayerId
) => {
  const rootCarouselLayer = state.layers[carouselId];

  if (!rootCarouselLayer || rootCarouselLayer.type !== LayerType.Carousel.Root)
    return;

  const slideId = getSlideId({
    carouselId,
    layers: state.layers,
    selectedLayerIds: state.selectedLayerIds,
  });

  if (typeof slideId !== "string") return;

  const slideChildrenIds = getChildIdsNested(state.layers, slideId);

  const getLayersToDuplicate = [...slideChildrenIds, slideId].reduce(
    (acc, id) => {
      const layer = state.layers[id];

      if (!layer) return acc;

      acc[id] = current(layer);

      return acc;
    },
    {} as Layers
  );

  const { layers: randomizedLayers, rootLayerId: randomizedSlideLayerId } =
    randomizeLayerIds({
      layers: getLayersToDuplicate,
      rootLayerId: slideId,
      targetLayerId: carouselId,
    });

  if (typeof randomizedSlideLayerId !== "string") return;

  Object.values(randomizedLayers).forEach((layer) => {
    state.layers[layer.id] = layer;
  });

  rootCarouselLayer.children.push(randomizedSlideLayerId);
};
