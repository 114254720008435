import { Canvas } from "@/features/editor/Canvas";
import { BreakpointsToolbar } from "@/features/editor/components/BreakpointsToolbar";
import { IFrameContent, Iframe } from "@/features/editor/components/Iframe";
import { PreviewPublishActions } from "@/features/editor/components/PreviewPublishActions";
import {
  AddSlideButton,
  ChangeSlideButtons,
} from "@/features/editor/components/Carousel";
import {
  AddAccordionItemButton,
  ToggleAccordionItem,
} from "@/features/editor/components/Accordion";
import { store } from "@core/store";
import { Sidebar } from "@/features/sidebar/Sidebar";
import { Provider } from "react-redux";
import { PageIdProvider } from "@/features/editor/context/PageId";
import { PageInformation } from "@/features/editor/components/PageInformation";
import { ShopProvider } from "@/features/dashboard/context/ShopContext";
import { GroupLayers } from "@/features/editor/components/GroupLayers";
import { UngroupLayer } from "@/features/editor/components/UngroupLayer";
import { CanvasErrorBoundary } from "@/features/editor/components/CanvasErrorBoundary";
import { SentryErrorBoundary } from "@common/SentryErrorBoundary";
import { ModalProvider } from "@core/modalStore";
import { useLoadSwiperScriptInModal } from "@/features/editor/hooks/useLoadSwiperScriptInModal";
import { useLoadWebComponentsInModal } from "@/features/editor/hooks/useLoadWebComponentsInModal";
import { useLoadReactHotToastStyles } from "@/features/editor/hooks/useLoadReactHotToastStyles";
import { Toaster } from "@core/Toaster";
import { ReplaceDroppedLayersInformation } from "@/features/editor/components/ReplaceDroppedLayersInformation";
import { useLoadPreviouslySelectedFontFamilyInModal } from "@/features/editor/hooks/useLoadPreviouslySelectedFontFamily";
import { useLoadIntercomInModal } from "@/features/editor/hooks/useLoadIntercomInModal";
import { Onboarding } from "@/features/editor/components/Onboarding/Onboarding";
import { PlaygroundChangesNotSavedWarning } from "@/features/editor/components/PlaygroundChangesNotSavedWarning";
import { WaitForModalToLoad } from "@/features/editor/components/WaitForModalToLoad";

export function Editor({ pageId }: { pageId: string }) {
  useLoadSwiperScriptInModal();
  useLoadWebComponentsInModal();
  useLoadReactHotToastStyles();
  useLoadPreviouslySelectedFontFamilyInModal();
  useLoadIntercomInModal();

  return (
    <WaitForModalToLoad>
      <ModalProvider>
        <SentryErrorBoundary>
          <ShopProvider>
            <PageIdProvider pageId={pageId}>
              <Toaster />
              <Provider store={store}>
                <AddSlideButton />
                <GroupLayers />
                <UngroupLayer />
                <ChangeSlideButtons />
                <AddAccordionItemButton />
                <ToggleAccordionItem />
                <div className="pointer-events-none fixed left-[calc(326px_+_12px)] right-3 top-2 z-50 flex  justify-between">
                  <BreakpointsToolbar />
                  <PageInformation />
                  <PreviewPublishActions />
                </div>
                <ReplaceDroppedLayersInformation />
                <Onboarding />
                <PlaygroundChangesNotSavedWarning />
                <CanvasErrorBoundary>
                  <Iframe>
                    <IFrameContent>
                      <Canvas />
                    </IFrameContent>
                  </Iframe>
                </CanvasErrorBoundary>
                <Sidebar />
              </Provider>
            </PageIdProvider>
          </ShopProvider>
        </SentryErrorBoundary>
      </ModalProvider>
    </WaitForModalToLoad>
  );
}
