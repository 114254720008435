import { Breakpoint, Layer, LayerId, Layers } from '@folds/shared/types';
import { Draft, produce } from 'immer';

type Args = {
  layers: Layers;
  id: LayerId;
  breakpoint: Breakpoint;
  customState?:
    | null
    | 'hover'
    | 'parent-hover'
    | 'checked'
    | 'product-unavailable'
    | 'parent-checked'
    | 'option-unavailable'
    | 'parent-option-unavailable';
};

/**
 * Allows changing customizing the layer for different states.
 * They states should be mapped to pseudo selectors
 */
const assignState = ({
  customState,
  draft,
  layers,
  breakpoint,
}: {
  draft: Draft<Layer>;
  customState:
    | 'hover'
    | 'parent-hover'
    | 'checked'
    | 'product-unavailable'
    | 'parent-checked'
    | 'option-unavailable'
    | 'parent-option-unavailable'
    | undefined
    | null;
  layers: Layers;
  breakpoint: Breakpoint;
}) => {
  if (!('state' in draft)) return;

  const state = customState !== undefined ? customState : draft.state;

  switch (state) {
    case 'hover': {
      if (!('hover' in draft)) return;

      Object.assign(draft, draft.hover);
      break;
    }
    case 'checked': {
      if (!('checked' in draft)) return;

      Object.assign(draft, draft.checked);
      break;
    }
    case 'product-unavailable': {
      if (!('productUnavailable' in draft)) return;

      Object.assign(draft, draft.productUnavailable);
      break;
    }
    case 'option-unavailable': {
      if (!('optionUnavailable' in draft)) return;

      Object.assign(draft, draft.optionUnavailable);
      break;
    }
    case 'parent-hover': {
      if (!('parentId' in draft)) return;

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parentLayer = getOverridenLayer({
        breakpoint,
        id: draft.parentId,
        layers,
        customState: null,
      });

      if (!parentLayer || !('hoverChildren' in parentLayer)) return;

      Object.assign(draft, parentLayer.hoverChildren[draft.id]);

      break;
    }
    case 'parent-option-unavailable': {
      if (!('parentId' in draft)) return;

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parentLayer = getOverridenLayer({
        breakpoint,
        id: draft.parentId,
        layers,
        customState: null,
      });

      if (!parentLayer || !('optionUnavailableChildren' in parentLayer)) return;

      Object.assign(draft, parentLayer.optionUnavailableChildren[draft.id]);

      break;
    }
    case 'parent-checked': {
      if (!('parentId' in draft)) return;

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const parentLayer = getOverridenLayer({
        breakpoint,
        id: draft.parentId,
        layers,
        customState: null,
      });

      if (!parentLayer || !('checkedChildren' in parentLayer)) return;

      Object.assign(draft, parentLayer.checkedChildren[draft.id]);

      break;
    }
    case null:
      // Do nothing
      break;
  }
};

/**
 * Gets layer with overrides applied
 */
export const getOverridenLayer = ({
  id: layerId,
  layers,
  breakpoint,
  customState,
}: Args): Layer | null => {
  const originalLayer = layers[layerId];
  if (!originalLayer) return null;

  if (breakpoint === 'mobile' && 'mobile' in originalLayer) {
    const newLayer = produce(originalLayer, (draft) => {
      // Allow the state changes to be inherited from the desktop breakpoint
      assignState({ draft, customState, layers, breakpoint: 'desktop' });
      Object.assign(draft, draft.tablet);
      // Allow the state changes to be inherited from the tablet breakpoint
      assignState({ draft, customState, layers, breakpoint: 'tablet' });
      Object.assign(draft, draft.mobile);

      assignState({ draft, customState, layers, breakpoint });
    });

    return newLayer;
  }

  if (breakpoint === 'tablet' && 'tablet' in originalLayer) {
    const newLayer = produce(originalLayer, (draft) => {
      // Allow the state changes to be inherited from the desktop breakpoint
      assignState({ draft, customState, layers, breakpoint: 'desktop' });
      Object.assign(draft, draft.tablet);
      assignState({ draft, customState, layers, breakpoint });
    });

    return newLayer;
  }

  if ('state' in originalLayer) {
    const newLayer = produce(originalLayer, (draft) => {
      assignState({ draft, customState, layers, breakpoint });
    });

    return newLayer;
  }

  return originalLayer;
};
