import { useAppSelector } from "@core/hooks";
import { TextAddedEvent } from "@folds/shared/types/src/events";

export function TextElements() {
  const previouslySelectedFontFamily = useAppSelector(
    (state) => state.editor.previouslySelectedFontFamily
  );

  const handleDragStart = (
    event: React.DragEvent,
    {
      fontSize,
      lineHeight,
      text,
      fontFamily,
    }: {
      fontSize: number;
      lineHeight: number;
      text: string;
      fontFamily: string;
    }
  ) => {
    const data: TextAddedEvent = {
      type: "text",
      fontSize,
      lineHeight,
      text,
      fontFamily,
    };

    // Disable drag image since the default one has a white background
    event.dataTransfer.setDragImage(new Image(), 0, 0);

    event.dataTransfer.setData("application/json", JSON.stringify(data));
  };

  const fontFamily = `${previouslySelectedFontFamily}, sans-serif`;

  return (
    <div className="flex flex-col gap-1">
      <div
        className="cursor-pointer rounded-sm px-3 py-2 text-5xl leading-[120%] hover:bg-gray-3"
        draggable
        // TODO: Use the last selected font
        style={{ fontFamily }}
        onDragStart={(event) =>
          handleDragStart(event, {
            fontSize: 60,
            lineHeight: 120,
            text: "Heading",
            fontFamily: previouslySelectedFontFamily,
          })
        }
      >
        Heading
      </div>
      <div
        className="cursor-pointer rounded-sm px-3 py-2 text-3xl leading-[120%] hover:bg-gray-3"
        style={{ fontFamily }}
        draggable
        aria-label="Small Heading"
        onDragStart={(event) =>
          handleDragStart(event, {
            fontSize: 36,
            lineHeight: 120,
            text: "Small Heading",
            fontFamily: previouslySelectedFontFamily,
          })
        }
      >
        Small Heading
      </div>
      <div
        className="cursor-pointer rounded-sm px-3 py-2 text-2xl leading-[120%] hover:bg-gray-3"
        style={{ fontFamily }}
        draggable
        onDragStart={(event) =>
          handleDragStart(event, {
            fontSize: 24,
            lineHeight: 120,
            text: "Subheading",
            fontFamily: previouslySelectedFontFamily,
          })
        }
      >
        Subheading
      </div>
      <div
        className="cursor-pointer rounded-sm px-3 py-2 text-xl leading-[120%] hover:bg-gray-3"
        style={{ fontFamily }}
        draggable
        onDragStart={(event) =>
          handleDragStart(event, {
            fontSize: 20,
            lineHeight: 120,
            text: "Large text",
            fontFamily: previouslySelectedFontFamily,
          })
        }
      >
        Large text
      </div>
      <div
        className="cursor-pointer rounded-sm px-3 py-2 text-base leading-[120%] hover:bg-gray-3"
        style={{ fontFamily }}
        draggable
        onDragStart={(event) =>
          handleDragStart(event, {
            fontSize: 16,
            lineHeight: 120,
            text: "Paragraph text",
            fontFamily: previouslySelectedFontFamily,
          })
        }
      >
        Paragraph text
      </div>
    </div>
  );
}
