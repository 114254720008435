import styled from "styled-components";
import {
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  color,
  flexbox,
  space,
} from "styled-system";

interface Props extends SpaceProps, ColorProps, LayoutProps, FlexboxProps {
  children: React.ReactNode;
  gap?: number;
}

export const Flex = styled.div<Props>`
  ${space}
  ${color}
  ${flexbox}
  gap: ${({ gap }) => gap}px;
  display: flex;
`;
