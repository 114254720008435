import { useEventConnector } from "@core/events";
import {
  CarouselRootLayer,
  ProductImageCarouselFeaturedImageLayer,
  ProductImageCarouselThumbnailsLayer,
} from "@folds/shared/types";
import { useEffect, useRef } from "react";

type SwiperElement = HTMLDivElement & {
  swiper:
    | { detachEvents: () => void; update: () => void; destroy: () => void }
    | undefined;
};

export function SwiperCarousel({
  children,
  layer,
  style,
  ...restOfProps
}: {
  children: React.ReactNode;
  layer:
    | CarouselRootLayer
    | ProductImageCarouselThumbnailsLayer
    | ProductImageCarouselFeaturedImageLayer;
  style: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>) {
  const swiperElement = useRef<SwiperElement>(null);

  useEffect(() => {
    const swiper = swiperElement.current?.swiper;

    // If the swiper js script is not loaded yet
    if (!swiper) return;

    swiper.update();
    swiper.detachEvents();
  }, [layer.width, layer.children]);

  // Hacky solution to fix: https://linear.app/folds/issue/FOL-436/moving-block-breaks-swiper-styles
  // If we don't use an interval, the swiper styles will break when moving a block the second time
  useEffect(() => {
    const interval = setInterval(() => {
      const swiper = swiperElement.current?.swiper;
      if (!swiper) return;

      swiper.detachEvents();
      swiper.destroy = () => {
        swiperElement.current?.swiper?.update();
      };
    }, 25);

    return () => clearInterval(interval);
  }, []);

  const { handleMouseDown, handleMouseOver, handleContextMenu } =
    useEventConnector(layer.id);

  return (
    <swiper-container
      ref={swiperElement}
      style={style}
      aria-label="Carousel layer"
      id={layer.id}
      slides-per-view="auto"
      loop={layer.loop}
      navigation={layer.navigationEnabled}
      pagination={layer.paginationEnabled}
      scrollbar={layer.scrollbarEnabled}
      space-between={layer.gap}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
      onContextMenu={handleContextMenu}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
    >
      {children}
    </swiper-container>
  );
}
