import { HorizontalIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/HorizontalIndicator/HorizontalIndicator";
import { OverlappingIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/OverlappingIndicator";
import { ParentIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/ParentIndicator";
import { VerticalIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/VerticalIndicator/VerticalIndicator";
import { useAppSelector } from "@core/hooks";
import { getFixedBoundingClientRect } from "@core/indicators";
import { RootState } from "@core/store";
import { getAncestorIds } from "@core/utils";
import { LayerId, Layers, SelectedLayerIds } from "@folds/shared";
import { createSelector } from "@reduxjs/toolkit";

const selectAltHoverTargetLayerId = createSelector(
  [
    (state: RootState) => state.editor.altHoverDistanceEnabled,
    (state: RootState) => state.editor.hoveredLayerId,
    (state: RootState) => state.editor.selectedLayerIds,
    (state: RootState) => state.editor.dragging,
  ],
  (altHoverDistanceEnabled, hoveredLayerId, selectedLayerIds, dragging) => {
    // Disable when alt drag duplicating
    if (dragging !== null) return null;

    // Must have a layer selected to show the indicator
    if (selectedLayerIds.length === 0) return null;

    if (altHoverDistanceEnabled === false || hoveredLayerId === null)
      return null;

    const selectedLayerIdsContainHoveredId =
      selectedLayerIds.includes(hoveredLayerId);

    if (selectedLayerIdsContainHoveredId) {
      return null;
    }

    return hoveredLayerId;
  }
);

const checkIfIsTargetingParentLayer = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds,
  targetLayerId: LayerId
) => {
  const isTargetingParentLayer = selectedLayerIds.some((id) =>
    getAncestorIds(layers, id).includes(targetLayerId)
  );

  return isTargetingParentLayer;
};

export function AltHoverDistanceIndicator() {
  const targetLayerId = useAppSelector(selectAltHoverTargetLayerId);

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );
  const layers = useAppSelector((state) => state.editor.layers);
  const isViewportTransforming = useAppSelector(
    (state) => state.editor.isViewportTransforming
  );

  if (isViewportTransforming || targetLayerId === null) return null;

  const isTargetingParentLayer = checkIfIsTargetingParentLayer(
    layers,
    selectedLayerIds,
    targetLayerId
  );

  const selectionBoundingBox = getFixedBoundingClientRect(selectedLayerIds);
  const targetLayerBoundingBox = getFixedBoundingClientRect([targetLayerId]);

  if (isTargetingParentLayer) {
    return (
      <ParentIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
    );
  }

  return (
    <>
      <VerticalIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
      <HorizontalIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
      <OverlappingIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
    </>
  );
}
