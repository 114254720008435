import { getProductIdsFromLayers } from "@core/utils/getProductIdsFromLayers";
import { Layers, ShopifyProduct } from "@folds/shared";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";

export const useGetProductsInformationFromLayers = () => {
  const axios = useAxios();

  const getProductInformation = async (layers: Layers) => {
    try {
      const productIds = getProductIdsFromLayers(layers);

      const productInformationPromises = productIds.map((productId) =>
        axios.get(`/shopify/products/${productId}`)
      );

      toast.loading("Loading product information...", {
        id: "loading-product-information",
      });

      const productsInformationResult = await Promise.all(
        productInformationPromises
      );

      toast.success("Product information loaded", {
        id: "loading-product-information",
      });

      const productsInformation = productsInformationResult.reduce(
        (acc, response) => {
          if (response.data === undefined) return acc;

          acc.push(response.data);

          return acc;
        },
        [] as ShopifyProduct[]
      );

      return productsInformation;
    } catch (error) {
      toast.error("Failed to load product information", {
        id: "loading-product-information",
      });

      return null;
    }
  };

  return getProductInformation;
};
