import { LayerId } from "@folds/shared/types";
import { getElement } from "@core/utils/getElement";

export const getAbsolutePosition = (id: LayerId) => {
  const canvasElement = getElement("canvas");
  const element = getElement(id);

  if (element === null || canvasElement === null) {
    return { top: 0, left: 0 };
  }

  const scaleString =
    canvasElement.style.transform.match(/scale\(([0-9.-]+)\)/)?.[1];

  if (scaleString === undefined) {
    return { top: 0, left: 0 };
  }

  const scale = Number(scaleString);

  const pageElementRect = canvasElement.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.top / scale - pageElementRect.top / scale,
    left: elementRect.left / scale - pageElementRect.left / scale,
  };
};
