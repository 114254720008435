import { memo, useEffect, useRef, useState } from "react";
import { useAppSelector } from "@core/hooks";
import { RenderLayers } from "@core/render/RenderLayer";
import { store } from "@store";
import { ROOT_LAYER_ID } from "@folds/shared/constants";

const arraysAreEqual = (a: string[], b: string[]) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

/**
 * Recursively build the canvas tree
 * Starts from ROOT node and traverses down
 */
export const RenderPage = memo(() => {
  const breakpoint = useAppSelector((state) => state.editor.breakpoint);
  const [layerIds, setLayerIds] = useState(
    Object.keys(store.getState().editor.layers)
  );

  useEffect(() => {
    const storeChanged = () => {
      const {
        editor: { layers },
      } = store.getState();

      const newLayerIds = Object.keys(layers);

      if (!arraysAreEqual(layerIds, newLayerIds)) {
        setLayerIds(newLayerIds);
      }
    };

    const unsubscribe = store.subscribe(storeChanged);

    return () => {
      unsubscribe();
    };
  }, [layerIds]);

  const previousLayersIds = useRef(layerIds);

  useEffect(() => {
    previousLayersIds.current = layerIds;
  }, [layerIds]);

  return <RenderLayers breakpoint={breakpoint} id={ROOT_LAYER_ID} />;
});

RenderPage.displayName = "RenderPage";
