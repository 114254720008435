import { useIntrinsicallySizedBoundingBox } from "@core/user-components/ProductImageCarousel";
import { useEventConnector } from "@core/events";
import {
  Box,
  GroupLayer,
  LayerId,
  LayerType,
  getOverridenLayer,
} from "@folds/shared";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@core/store";
import { getChildIdsNested } from "@core/utils";
import { useAppSelector } from "@core/hooks";
import { selectIsDragging } from "@core/features/editor/editorSlice";

const selectIsChildSelected = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, groupId: LayerId) => groupId,
  ],
  (layers, selectedLayerIds, groupId) => {
    const nestedChildren = getChildIdsNested(layers, groupId);

    return selectedLayerIds.some((id) => nestedChildren.includes(id));
  }
);

const generateGroupStyles = (box: Box): React.CSSProperties => ({
  position: "absolute",
  height: box.height,
  width: box.width,
  top: box.top,
  left: box.left,
});

const generateChildSelectedGroupStyles = ({
  isChildSelected,
  isDragging,
}: {
  isChildSelected: boolean;
  isDragging: boolean;
}): React.CSSProperties => {
  if (isChildSelected === false || isDragging === true) return {};

  return {
    border: 1,
    borderColor: "#0090ff",
    borderStyle: "dashed",
  };
};

/**
 * If the group is positioned in front of other layers, it will still be selectable.
 */
const selectLowestGroupIndex = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.breakpoint,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, breakpoint, layerId) => {
    const group = layers[layerId];
    if (group === undefined || group.type !== LayerType.Group) return 0;

    const lowestZIndex = group.children.reduce((acc, id) => {
      const layer = getOverridenLayer({ breakpoint, id, layers });

      if (layer === null || !("zIndex" in layer)) return acc;

      if (layer.zIndex < acc) return layer.zIndex;

      return acc;
    }, Infinity);

    return lowestZIndex;
  }
);

const selectLayerOrChildrenIdsAreSelected = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, selectedLayerIds, layerId) => {
    const childrenIds = getChildIdsNested(layers, layerId);

    const ids = [layerId, ...childrenIds];

    return ids.some((id) => selectedLayerIds.includes(id));
  }
);

export function Group({
  layer,
  children,
}: {
  layer: GroupLayer;
  children: React.ReactNode;
}) {
  const isChildSelected = useAppSelector((state) =>
    selectIsChildSelected(state, layer.id)
  );
  const { handleMouseDown, handleMouseOver, handleContextMenu } =
    useEventConnector(layer.id);
  const isDragging = useAppSelector(selectIsDragging);
  const box = useIntrinsicallySizedBoundingBox(layer.children);
  const zIndex = useAppSelector((state) =>
    selectLowestGroupIndex(state, layer.id)
  );

  const isLayerSelected = useAppSelector((state) =>
    selectLayerOrChildrenIdsAreSelected(state, layer.id)
  );

  const selectedStyles = generateChildSelectedGroupStyles({
    isChildSelected,
    isDragging,
  });

  const groupStyles = generateGroupStyles(box);

  const style: React.CSSProperties = {
    ...groupStyles,
    ...selectedStyles,
    zIndex,
  };

  return (
    <>
      <div
        aria-label="Group layer"
        style={style}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        onContextMenu={handleContextMenu}
        data-selected={isLayerSelected}
      />
      <div
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        onContextMenu={handleContextMenu}
      >
        {children}
      </div>
    </>
  );
}
