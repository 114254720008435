import { getChildIdsNested } from "@core/utils";
import {
  Layer,
  LayerId,
  LayerType,
  Layers,
  SelectedLayerIds,
} from "@folds/shared/types";

export const getAllChildLayers = (
  layers: Layers,
  rootLayerId: LayerId
): Layer[] => {
  const rootLayer = layers[rootLayerId];

  if (rootLayer === undefined || !("children" in rootLayer)) return [];

  const children = getChildIdsNested(layers, rootLayerId);

  const carouselLayers = children
    .map((childId) => layers[childId])
    .filter((layer) => layer !== undefined) as Layer[];

  return [rootLayer, ...carouselLayers];
};

export const getLayersToCopy = (
  selectedLayerIds: SelectedLayerIds,
  layers: Layers
): Layer[] => {
  const selectedLayers = Object.values(layers).filter((layer) =>
    selectedLayerIds.includes(layer.id)
  );

  const mappedLayers = selectedLayers.flatMap((layer) => {
    switch (layer.type) {
      case "block": {
        return getAllChildLayers(layers, layer.id);
      }
      case "breakpoint": {
        return [];
      }
      case "button": {
        return getAllChildLayers(layers, layer.id);
      }
      case "page": {
        return [];
      }
      case "carousel-root": {
        return getAllChildLayers(layers, layer.id);
      }
      case "group": {
        return getAllChildLayers(layers, layer.id);
      }
      case "carousel-slide": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.Carousel.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      case "product-image-carousel-featured-image": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.ProductImageCarousel.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      case "product-image-carousel-root": {
        return getAllChildLayers(layers, layer.id);
      }
      case "product-image-carousel-thumbnail": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.ProductImageCarousel.Thumbnails)
          return [];

        const grandParent = layers[parent.parentId];

        if (grandParent?.type !== LayerType.ProductImageCarousel.Root)
          return [];

        return getAllChildLayers(layers, grandParent.id);
      }
      case "product-image-carousel-thumbnails": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.ProductImageCarousel.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      case "rectangle": {
        return layer;
      }
      case "text": {
        return layer;
      }
      case "option-selector-root": {
        return getAllChildLayers(layers, layer.id);
      }
      case "option-selector-item": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.OptionSelector.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      // TODO: Replace with icon
      case "accordion-chevron": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.Accordion.Trigger) return [];

        const grandParent = layers[parent.parentId];

        if (grandParent?.type !== LayerType.Accordion.Item) return [];

        const greatGrandParent = layers[grandParent.parentId];

        if (greatGrandParent?.type !== LayerType.Accordion.Root) return [];

        return getAllChildLayers(layers, greatGrandParent.id);
      }
      case "accordion-content": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.Accordion.Item) return [];

        const grandParent = layers[parent.parentId];

        if (grandParent?.type !== LayerType.Accordion.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      case "accordion-item": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.Accordion.Root) return [];

        return getAllChildLayers(layers, layer.id);
      }
      case "accordion-root": {
        return getAllChildLayers(layers, layer.id);
      }
      case "accordion-trigger": {
        const parent = layers[layer.parentId];

        if (parent?.type !== LayerType.Accordion.Item) return [];

        const grandParent = layers[parent.parentId];

        if (grandParent?.type !== LayerType.Accordion.Root) return [];

        return getAllChildLayers(layers, parent.id);
      }
      case "quantity-picker": {
        return layer;
      }
      case "option-dropdown": {
        return layer;
      }
      case "icon": {
        return layer;
      }
      case "liquid": {
        return layer;
      }
    }
  });

  return mappedLayers;
};
