import { useFrame } from "@/features/editor/context/FrameContext";
import { finishAltHoverDistance } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import { modalStore } from "@core/modalStore";
import { store } from "@core/store";
import { useCallback, useEffect } from "react";

export const useDisableAltHoverDistanceWhenSwitchingTabs = () => {
  const dispatch = useAppDispatch();
  const { window: iFrameWindow } = useFrame();

  const handleDisableAltHoverDistance = useCallback(() => {
    const { altHoverDistanceEnabled } = store.getState().editor;

    if (altHoverDistanceEnabled) {
      dispatch(finishAltHoverDistance());
    }
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      handleDisableAltHoverDistance
    );

    const modalWindow = modalStore.getWindow();

    // Disable the indicator when selecing the dev tools
    // https://linear.app/folds/issue/FOL-376/fix-disable-distance-indicators-when-dev-tools-are-selected
    modalWindow?.addEventListener("blur", handleDisableAltHoverDistance);
    iFrameWindow?.addEventListener("blur", handleDisableAltHoverDistance);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleDisableAltHoverDistance
      );

      modalWindow?.removeEventListener("blur", handleDisableAltHoverDistance);
      iFrameWindow?.removeEventListener("blur", handleDisableAltHoverDistance);
    };
  }, [handleDisableAltHoverDistance, iFrameWindow]);
};
