import { AutoCompleteValues, Input } from "@/features/sidebar/components/Input";
import { PopoverContent, PopoverPortal, PopoverRoot } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { colord } from "colord";
import { forwardRef, useDeferredValue } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import { useAppSelector, useDebounceHistory } from "@core/hooks";
import { checkIfIsCssVariable } from "@core/utils/checkIfIsCssVariable";
import { selectCascadingVariables } from "@core/features/editor/editorSlice";
import { ExistingColorsSwatches } from "@/features/sidebar/components/ExistingColorsSwatches";
import clsx from "clsx";
import classes from "./ColorPicker.module.css";

export const useParseHexValue = () => {
  const colorVariables = useAppSelector(selectCascadingVariables);

  const parseInputValue = (value: string) => {
    if (!checkIfIsCssVariable(value))
      return colord(value).alpha(1).toHex().toUpperCase();

    const colorVariable = colorVariables.find(
      (variable) => `--${variable.id}` === value
    );

    if (colorVariable === undefined) return "";

    return colorVariable.name;
  };

  return parseInputValue;
};

export const useParseColorPickerRgbValue = () => {
  const parseInputValue = (value: string) => {
    if (checkIfIsCssVariable(value)) return "rgba(0, 0, 0, 1)";

    return colord(value).toRgbString();
  };

  return parseInputValue;
};

const ColorPopoverTrigger = forwardRef<HTMLButtonElement, { color: string }>(
  ({ color }, forwardedRef) => {
    if (checkIfIsCssVariable(color)) {
      return (
        <Popover.Trigger
          ref={forwardedRef}
          className={clsx(
            classes["rainbow-background"],
            "absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6"
          )}
        />
      );
    }

    const opaqueColor = colord(color).alpha(1).toRgbString();

    return (
      <Popover.Trigger
        ref={forwardedRef}
        className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6"
        style={{ backgroundColor: opaqueColor }}
      />
    );
  }
);

ColorPopoverTrigger.displayName = "ColorPopoverTrigger";

export function ColorPicker({
  onValueChange,
  value = "rgb(0,0,0)",
  autoCompleteValues,
  onSelectAutoCompleteValue,
}: {
  onValueChange: (value: string) => void;
  value: string | undefined;
  autoCompleteValues?: AutoCompleteValues;
  onSelectAutoCompleteValue?: (data: AutoCompleteValues[number]) => void;
}) {
  // When the user is frequently updating the color, we can get stuck in a infinite loop of updating the color picker
  // See: https://linear.app/folds/issue/FOL-449/fix-color-picker-stuck-in-loop-when-performance-is-throttled
  const deferredValue = useDeferredValue(value);

  const parseInputValue = useParseHexValue();
  const parseRgbValue = useParseColorPickerRgbValue();
  const debounceHistory = useDebounceHistory();

  const handleChangeAlpha = (updatedAlphaPercentage: string) => {
    const alphaPercentage = Number(updatedAlphaPercentage);
    if (Number.isNaN(alphaPercentage)) return;

    const updatedAlphaValue = alphaPercentage / 100;

    const newColor = colord(value).alpha(updatedAlphaValue).toRgbString();

    const isNewColorValid = colord(newColor).isValid();

    if (isNewColorValid === false) return;

    onValueChange(newColor);
  };

  const handleChangeColor = (updatedColorString: string) => {
    const isValidColor = colord(updatedColorString).isValid();
    if (isValidColor === false) return;

    const newRGBColor = colord(updatedColorString).toRgbString();

    onValueChange(newRGBColor);
  };

  const handleChangeHexColor = (color: string) => {
    const isValidColor = colord(color).isValid();
    if (isValidColor === false) return;

    const originalColorAlpha = colord(value).alpha();

    const newColor = colord(color).alpha(originalColorAlpha).toRgbString();

    onValueChange(newColor);
  };

  const handleRGBColorPickerChange = debounceHistory((updatedColor: string) => {
    onValueChange(updatedColor);
  });

  const alphaValue = colord(deferredValue).alpha();
  const alphaPercentage = String(Math.round(alphaValue * 100));

  const rgbString = parseRgbValue(deferredValue);
  const hexString = parseInputValue(deferredValue);

  return (
    <PopoverRoot>
      <Popover.Anchor className="flex gap-1">
        <Input
          onSelectAutoCompleteValue={onSelectAutoCompleteValue}
          onValueChange={handleChangeHexColor}
          className="flex-1 !pl-10"
          value={hexString}
          minValue={null}
          inputType="color"
          unit={null}
          autoCompleteValues={autoCompleteValues}
        >
          <ColorPopoverTrigger color={value} />
        </Input>
        <Input
          minValue={0}
          className="!w-16 !px-2 text-center"
          unit="%"
          displayUnit
          onValueChange={handleChangeAlpha}
          value={alphaPercentage}
        />
      </Popover.Anchor>
      <PopoverPortal>
        <PopoverContent
          align="center"
          side="right"
          sideOffset={16}
          className="z-50 flex w-[239px] flex-col gap-3 rounded-md border border-solid border-gray-6 bg-gray-1 p-2"
        >
          <RgbaStringColorPicker
            color={rgbString}
            onChange={handleRGBColorPickerChange}
          />
          <div className="flex gap-1">
            <Input
              minValue={null}
              onValueChange={handleChangeColor}
              unit={null}
              inputType="color"
              value={hexString}
              className="!pl-2"
              fill
            />
            <Input
              minValue={0}
              unit="%"
              displayUnit
              inputType="number"
              className="!w-16 !px-2 text-center"
              value={alphaPercentage}
              onValueChange={handleChangeAlpha}
            />
          </div>
          <ExistingColorsSwatches onSelect={handleChangeColor} />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
}
