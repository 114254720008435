import {
  getBreakpointPropertyName,
  BreakpointPropertyName,
  LayerBreakpointKeys,
} from "@/features/editor/utils/getBreakpointPropertyValue";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";

export const deleteTabletOverrideAction = (
  state: DraftEditorState,
  name: BreakpointPropertyName
) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];
    if (layer === undefined || !("tablet" in layer)) return;

    const newTabletEntries = Object.entries(layer.tablet).filter(
      ([currentKey]) => {
        const propertyValue = getBreakpointPropertyName(
          currentKey as LayerBreakpointKeys
        );

        return propertyValue !== name;
      }
    );

    const newTablet = Object.fromEntries(newTabletEntries);

    layer.tablet = newTablet;
  });
};

export const deleteMobileOverrideAction = (
  state: DraftEditorState,
  name: BreakpointPropertyName
) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];
    if (layer === undefined || !("mobile" in layer)) return;

    const newMobileEntries = Object.entries(layer.mobile).filter(
      ([currentKey]) => {
        const propertyValue = getBreakpointPropertyName(
          currentKey as LayerBreakpointKeys
        );

        return propertyValue !== name;
      }
    );

    const newMobile = Object.fromEntries(newMobileEntries);

    layer.mobile = newMobile;
  });
};
