import { randomizeLayerIds } from "@/features/editor/utils/randomizeLayerIds";
import { assignPosition } from "@core/features/editor/editorSlice/actions/elementAddedEventAction/assignPosition";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import {
  Breakpoint,
  LayerId,
  Layers,
  Position,
  ROOT_LAYER_ID,
  ReturnValue,
} from "@folds/shared";
import { produce } from "immer";
import { toast } from "@core/toast";
import { replaceLayersInformationWithNull } from "@core/features/editor/editorSlice/actions/utils/replaceLayersInformationWithNull";
import { getLayerInformationToReplace } from "@core/features/editor/editorSlice/actions/utils/getValuesToReplaceFromLayers";

/**
 * Replaces the default parent id with the proper target id
 * randomize the layer ids, sets the top and left properties, and z-index
 */
const parseLayers = ({
  position,
  targetLayerId,
  breakpoint,
  layers,
  elementLayers,
  elementWidth,
}: {
  elementLayers: Layers;
  elementWidth: number;
  targetLayerId: string;
  position: Position;
  breakpoint: Breakpoint;
  layers: Layers;
}): ReturnValue<{ layers: Layers; layerId: LayerId }> => {
  const parsedElements = produce(elementLayers, (draft) => {
    assignPosition(draft, {
      breakpoint,
      layers,
      targetLayerId,
      position,
      width: elementWidth,
    });

    const rootLayer = draft[ROOT_LAYER_ID];
    if (rootLayer === undefined) return;

    rootLayer.parentId = targetLayerId;

    if ("zIndex" in rootLayer) {
      const highestZIndex = getHighestZIndex(layers, targetLayerId);
      rootLayer.zIndex = highestZIndex + 1;
    }
  });

  const result = randomizeLayerIds({
    layers: parsedElements,
    rootLayerId: ROOT_LAYER_ID,
    targetLayerId,
  });

  if (result.rootLayerId === null) {
    return {
      value: null,
      error: "Could not find new layer id",
    };
  }

  return {
    value: { layers: result.layers, layerId: result.rootLayerId },
    error: null,
  };
};

type Args = {
  layers: Layers;
  width: number;
  targetLayerId: string;
  position: Position;
};

export const elementAddedEventAction = (
  state: DraftEditorState,
  { layers, position, targetLayerId, width }: Args
) => {
  const { value, error } = parseLayers({
    elementLayers: layers,
    elementWidth: width,
    targetLayerId,
    position,
    breakpoint: state.breakpoint,
    layers: state.layers,
  });

  const targetLayer = state.layers[targetLayerId];

  if (targetLayer === undefined) {
    toast.error("There was an issue adding the element");
    return;
  }

  if (!("children" in targetLayer)) {
    toast.error("There was an issue adding the element");
    return;
  }

  if (typeof error === "string") {
    toast.error("There was an issue adding the element");
    return;
  }

  const replacedLayers = replaceLayersInformationWithNull(value.layers);
  const informationToReplace = getLayerInformationToReplace(value.layers);

  targetLayer.children.push(value.layerId);

  state.droppedLayersInformationToReplace = informationToReplace;

  state.layers = {
    ...state.layers,
    ...replacedLayers,
  };
};
