import { useModal } from "@core/modalStore";
import * as Popover from "@radix-ui/react-popover";
import * as Dialog from "@radix-ui/react-dialog";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { PopoverProps } from "@radix-ui/react-popover";
import { forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { createPortal } from "react-dom";

/**
 * Wrapper over Popover.Root that allow radix to be closed when clicking on the editor
 */
export function PopoverRoot(props: PopoverProps) {
  const { children, onOpenChange, open } = props;

  const modal = useModal();

  const [isOpen, setIsOpen] = useState(open ?? false);

  const handleOnOpenChange = (value: boolean) => {
    setIsOpen(value);

    onOpenChange?.(value);
  };

  useEffect(() => {
    setIsOpen(open ?? false);
  }, [open]);

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Popover.Root {...props} onOpenChange={handleOnOpenChange}>
        {children}
      </Popover.Root>
      {/* Covers the entire editor, so click events won't be triggered inside the iframe */}
      {isOpen &&
        modal &&
        createPortal(
          <div
            id="iframe-cover"
            className="fixed left-60 top-0 h-screen w-[calc(100vw-240px)] bg-transparent"
          />,
          modal
        )}
    </>
  );
}

type PopoverContentProps = Popover.PortalProps & {
  children: React.ReactNode;
};

export function PopoverPortal({
  children,
  ...restOfProps
}: PopoverContentProps) {
  const modal = useModal();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popover.Portal container={modal} {...restOfProps}>
      {children}
    </Popover.Portal>
  );
}

export function DialogPortal({
  children,
  ...restOfProps
}: PopoverContentProps) {
  const modal = useModal();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog.Portal container={modal} {...restOfProps}>
      {children}
    </Dialog.Portal>
  );
}

export const DialogOverlay = forwardRef<
  HTMLDivElement,
  Dialog.DialogOverlayProps
>((props, ref) => {
  const { className, ...restOfProps } = props;

  return (
    <Dialog.Overlay
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
      className={twMerge(
        "fixed bottom-0 left-0 right-0 top-0 z-[99] bg-black opacity-20",
        className
      )}
    />
  );
});

DialogOverlay.displayName = "DialogOverlay";

export const DialogContent = forwardRef<
  HTMLDivElement,
  Dialog.DialogContentProps
>((props, ref) => {
  const { className, ...restOfProps } = props;

  return (
    <Dialog.Content
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
      className={twMerge(
        "fixed left-1/2 top-1/2 z-[100] flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-5 rounded-lg bg-gray-1 p-6 shadow",
        className
      )}
    />
  );
});

DialogContent.displayName = "DialogContent";

export function DropdownMenuPortal({
  children,
  ...restOfProps
}: PopoverContentProps) {
  const modal = useModal();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DropdownMenu.Portal container={modal} {...restOfProps}>
      {children}
    </DropdownMenu.Portal>
  );
}

export const PopoverContent = forwardRef<
  HTMLDivElement,
  Popover.PopperContentProps
>((props, ref) => {
  const { className, ...restOfProps } = props;

  return (
    <Popover.Content
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
      className={twMerge("z-[99]", className)}
    />
  );
});

PopoverContent.displayName = "PopoverContent";
