import * as Dialog from "@radix-ui/react-dialog";

export function CloseDialogIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        className="stroke-gray-12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DeleteConfirmationDialog({
  children,
  onConfirm,
  body,
  title,
  confirmText = "Delete",
}: {
  children: React.ReactNode;
  onConfirm: () => void;
  body: string;
  title: string;
  confirmText?: string;
}) {
  return (
    <Dialog.Root>
      <Dialog.Trigger type="button" asChild>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-20" />
        <Dialog.Content className="fixed left-1/2 top-1/2 flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-5 rounded-lg bg-gray-1 p-6 shadow">
          <div className="flex justify-between">
            <Dialog.Title className="text-lg font-medium">{title}</Dialog.Title>
            <Dialog.Close>
              <CloseDialogIcon />
            </Dialog.Close>
          </div>
          <p className="text-base">{body}</p>
          <div className="flex gap-3">
            <Dialog.Close
              type="button"
              className="flex-1 rounded border border-gray-6 bg-gray-2 py-3 text-sm"
            >
              Cancel
            </Dialog.Close>
            <Dialog.Close
              type="button"
              onClick={onConfirm}
              className="flex-1 rounded border border-gray-6 bg-red-9 py-3 text-sm text-white"
            >
              {confirmText}
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
