import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { Input } from "@/features/sidebar/components/Input";
import {
  selectIconColor,
  selectIconSize,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function IconSettings() {
  const dispatch = useAppDispatch();
  const iconColor = useAppSelector(selectIconColor);
  const iconSize = useAppSelector(selectIconSize);

  const handleValueChange = (value: string) => {
    dispatch(setSelectedLayerProperties({ iconColor: value }));
  };

  const handleIconSizeChange = (value: string) => {
    const updatedIconSize = Number(value);

    if (Number.isNaN(updatedIconSize)) return;

    dispatch(setSelectedLayerProperties({ iconSize: updatedIconSize }));
  };

  return (
    <div className="flex flex-col gap-3 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Icon</p>
      <div className="flex flex-col gap-2">
        <ColorPicker onValueChange={handleValueChange} value={iconColor} />
        <div className="flex items-center justify-between">
          <p className="text-xs">Icon size</p>
          <Input
            minValue={0}
            onValueChange={handleIconSizeChange}
            unit="px"
            value={String(iconSize)}
            className="w-28 px-3"
          />
        </div>
      </div>
    </div>
  );
}
