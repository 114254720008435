import type { TrackedStateChange } from "@core/features/editor/editorSlice";
import type { Layer, Layers, SelectedLayerIds } from "@folds/shared/types";
import isEqual from "lodash.isequal";

export const getNewTrackedChange = ({
  newLayers,
  oldLayers,
  oldSelectedLayerIds,
}: {
  oldLayers: Layers;
  newLayers: Layers;
  oldSelectedLayerIds: SelectedLayerIds;
}) => {
  const changedLayers = Object.values(newLayers).filter((layer) => {
    const oldLayer = oldLayers[layer.id];
    return !isEqual(layer, oldLayer);
  });

  const deletedLayers = Object.values(oldLayers).filter((layer) => {
    const newLayer = newLayers[layer.id];

    if (newLayer === undefined) return true;

    return false;
  });

  const changedLayersValues = changedLayers.reduce(
    (acc, layer) => {
      const oldLayer = oldLayers[layer.id];

      // When layer is added
      if (!oldLayer) {
        acc[layer.id] = undefined;
        return acc;
      }

      Object.keys(oldLayer).forEach((key) => {
        const oldLayerValue = oldLayer[key as keyof Layer];
        const newLayerValue = layer[key as keyof Layer];

        if (isEqual(oldLayerValue, newLayerValue)) return acc;

        if (acc[layer.id] !== undefined) {
          acc[layer.id] = {
            ...acc[layer.id],
            [key]: oldLayerValue,
          };

          return acc;
        }

        acc[layer.id] = {
          [key]: oldLayerValue,
        };

        return acc;
      });

      return acc;
    },
    {} as Record<string, Partial<Layer> | undefined>
  );

  // Adds the deleted layers to the changes
  const allChanges = deletedLayers.reduce((acc, layer) => {
    acc[layer.id] = layer;

    return acc;
  }, changedLayersValues);

  const trackedStateChange: TrackedStateChange = {
    changes: allChanges,
    selectedLayerIds: oldSelectedLayerIds,
  };

  return trackedStateChange;
};
