import { Input } from "@/features/sidebar/components/Input";
import {
  selectBackgroundPositionX,
  selectBackgroundPositionY,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function ImagePositionSettings() {
  const dispatch = useAppDispatch();

  const backgroundPositionX = useAppSelector(selectBackgroundPositionX);
  const backgroundPositionY = useAppSelector(selectBackgroundPositionY);

  const handleChangeBackgroundPositionX = (value: string) => {
    const numberValue = Number(value);

    if (Number.isNaN(numberValue)) return;

    dispatch(setSelectedLayerProperties({ backgroundPositionX: numberValue }));
  };

  const handleChangeBackgroundPositionY = (value: string) => {
    const numberValue = Number(value);

    if (Number.isNaN(numberValue)) return;

    dispatch(setSelectedLayerProperties({ backgroundPositionY: numberValue }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Image position</p>
      <div className="flex items-center gap-2">
        <Input
          minValue={null}
          unit="px"
          icon={<p className="block text-xs text-gray-11">X</p>}
          inputType="number"
          value={String(backgroundPositionX)}
          onValueChange={handleChangeBackgroundPositionX}
          fill
        />
        <Input
          minValue={null}
          unit="px"
          icon={<p className="block text-xs text-gray-11">Y</p>}
          inputType="number"
          value={String(backgroundPositionY)}
          onValueChange={handleChangeBackgroundPositionY}
          fill
        />
      </div>
    </div>
  );
}
