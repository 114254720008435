import { usePages } from "@/features/dashboard/api/getPages";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as i from "@/features/dashboard/Dashboard.icons";
import { PageThumbnailImage } from "@/features/dashboard/components/PageThumbnailImage";
import { CreatePageDialog } from "@/features/dashboard/components/CreatePageDialog";

const getPageText = (
  pageType: "home" | "product" | "standalone" | "not-found"
) => {
  switch (pageType) {
    case "home":
      return "Home page";
    case "product":
      return "Product page";
    case "standalone":
      return "Page";
    case "not-found":
      return "404 page";
  }
};

function PageThumbnail({
  page,
  onDelete,
  onRename,
  onSelect,
}: {
  page: Exclude<ReturnType<typeof usePages>["data"], undefined>[number];
  onDelete: (id: string) => void;
  onRename: (id: string, name: string) => void;
  onSelect: () => void;
}) {
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [renamePageDialogOpen, setRenamePageDialogOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState("");

  const handleDelete = () => {
    setConfirmDeleteDialogOpen(false);
    onDelete(page.id);
  };

  const handleRename = () => {
    setRenamePageDialogOpen(false);
    onRename(page.id, updatedName);
  };

  const handleExpandRenameModal = () => {
    setRenamePageDialogOpen(true);
    setUpdatedName(page.name);
  };

  return (
    <>
      <PageThumbnailImage pageId={page.id} onSelect={onSelect}>
        <Dropdown.Root>
          <Dropdown.Trigger className="absolute right-3 top-3 rounded bg-gray-12 p-1">
            <i.TrippleDots />
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content
              onClick={(event) => event.stopPropagation()}
              align="center"
              sideOffset={4}
              className="flex w-36 flex-col gap-1 rounded border border-gray-6 bg-gray-1 p-1"
            >
              <Dropdown.Item
                onSelect={handleExpandRenameModal}
                className="cursor-pointer px-2 py-1 text-xs outline-none focus:bg-gray-3"
              >
                Rename
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => setConfirmDeleteDialogOpen(true)}
                className="cursor-pointer px-2 py-1 text-xs outline-none focus:bg-gray-3"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
        <div className="absolute bottom-4 left-3 right-3">
          <p className="truncate text-sm text-gray-12">{page.name}</p>
          <div className="flex justify-between">
            <p className="text-xs text-gray-11">{getPageText(page.type)}</p>
            <p className="text-xs text-gray-11">
              {page.publishedAt !== null ? "Published" : "Draft"}
            </p>
          </div>
        </div>
      </PageThumbnailImage>
      <Dialog.Root
        open={confirmDeleteDialogOpen}
        onOpenChange={setConfirmDeleteDialogOpen}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-20" />
          <Dialog.Content className="fixed left-1/2 top-1/2 flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-5 rounded-lg bg-gray-1 p-6 shadow">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium">
                Delete page
              </Dialog.Title>
              <Dialog.Close>
                <i.CloseDialogIcon />
              </Dialog.Close>
            </div>
            <p className="text-base">
              Are you sure you want to delete this page? This action cannot be
              undone.
            </p>
            <div className="flex gap-4">
              <Dialog.Close
                type="button"
                className="flex-1 rounded border border-gray-6 bg-gray-2 py-2 text-sm"
              >
                Cancel
              </Dialog.Close>
              <button
                onClick={handleDelete}
                type="button"
                className="flex-1 rounded bg-[#D92D20] py-2 text-sm text-white"
              >
                Delete
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <Dialog.Root
        open={renamePageDialogOpen}
        onOpenChange={setRenamePageDialogOpen}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-20" />
          <Dialog.Content className="fixed left-1/2 top-1/2 flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-5 rounded-lg bg-gray-1 p-6 shadow">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium">
                Rename page
              </Dialog.Title>
              <Dialog.Close>
                <i.CloseDialogIcon />
              </Dialog.Close>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="page-name" className="text-sm text-gray-11">
                Name
              </label>
              <input
                value={updatedName}
                onChange={(event) => setUpdatedName(event.target.value)}
                className="h-11 rounded border border-gray-6 bg-gray-2 px-2 text-sm text-gray-12"
                id="page-name"
              />
            </div>
            <div className="flex gap-4">
              <Dialog.Close
                type="button"
                className="flex-1 rounded border border-gray-6 bg-gray-2 py-2 text-sm"
              >
                Cancel
              </Dialog.Close>
              <button
                onClick={handleRename}
                type="button"
                className="flex-1 rounded bg-gray-12 py-2 text-sm text-white"
              >
                Rename
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}

export function PageThumbnails({
  onSelect,
}: {
  onSelect: (pageId: string) => void;
}) {
  const { data: pages, isLoading, error, mutate } = usePages();
  const axios = useAxios();

  const handleDeletePage = async (pageId: string) => {
    toast.loading("Deleting page", { id: "delete-page" });

    try {
      await axios.delete(`/pages/${pageId}`);
      toast.success("Page deleted", { id: "delete-page" });

      const newPages = pages?.filter((page) => page.id !== pageId);

      await mutate(newPages);
    } catch {
      toast.error("There was an issue deleting the page", {
        id: "delete-page",
      });
    }
  };

  const handleRenamePage = async (pageId: string, newName: string) => {
    toast.loading("Updating page", { id: "rename-page" });

    try {
      await axios.put(`/pages/${pageId}/name`, {
        name: newName,
      });

      toast.success("Page updated", { id: "rename-page" });

      const newPages = pages?.map((page) => {
        if (page.id === pageId) {
          return {
            ...page,
            name: newName,
          };
        }

        return page;
      });

      await mutate(newPages);
    } catch {
      toast.error("There was an issue updating the page", {
        id: "rename-page",
      });
    }
  };

  if (isLoading) {
    return new Array(4).fill(4).map((_, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className="relative h-44 animate-pulse cursor-pointer rounded-xl bg-[#F2F3F4] px-3 py-2"
      />
    ));
  }

  if (pages === undefined || error !== undefined) {
    return <p>There was an issue fetching the pages</p>;
  }

  if (pages.length === 0) {
    return (
      <div className="mt-16 flex flex-col items-center gap-6 max-[1090px]:mt-6">
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-3xl text-gray-12">Create your first page</h1>
          <p className="text-base text-gray-11">
            You can view your pages here once you create a page
          </p>
        </div>
        <CreatePageDialog onPageCreated={onSelect}>
          <button
            type="button"
            className="w-60 rounded-lg bg-gray-12 py-3 text-sm text-white"
          >
            Create page
          </button>
        </CreatePageDialog>
      </div>
    );
  }

  const sortedPagesByViewedAt = pages.sort(
    (pageA, pageB) =>
      new Date(pageB.viewedAt).getTime() - new Date(pageA.viewedAt).getTime()
  );

  const pageThumbnails = sortedPagesByViewedAt.map((page) => (
    <PageThumbnail
      page={page}
      key={page.id}
      onSelect={() => onSelect(page.id)}
      onRename={handleRenamePage}
      onDelete={handleDeletePage}
    />
  ));

  return <div className="flex flex-wrap gap-3">{pageThumbnails}</div>;
}
