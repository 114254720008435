import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR from "swr";

const responseSchema = TypeCompiler.Compile(
  Type.Object({
    desktop: Type.Object({
      header: Type.String(),
      footer: Type.String(),
    }),
    tablet: Type.Object({
      header: Type.String(),
      footer: Type.String(),
    }),
    mobile: Type.Object({
      header: Type.String(),
      footer: Type.String(),
    }),
  })
);

export function useSections() {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);

    const parsedResult = responseSchema.Decode(result.data);

    return parsedResult;
  };

  return useSWR("/shopify/sections", getResult);
}
