import {
  deselectLayer,
  dragStart,
  hoverLayer,
  mouseEnter,
  mouseLeave,
  openContextMenu,
  selectLayer,
  setSelectedLayerIds,
  startDuplicating,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { modalStore } from "@core/modalStore";
import { store } from "@core/store";
import {
  getAncestorIds,
  getChildIdsNested,
  getLayerType,
  getParentLayerId,
} from "@core/utils";
import { isMiddleClick, isRightClick } from "@core/utils/click";
import {
  LayerId,
  LayerType,
  Layers,
  SelectedLayerIds,
} from "@folds/shared/types";
import { MouseEvent, useCallback } from "react";

const clearTextSelection = () => {
  const selection = window.getSelection();

  selection?.empty();
};

const checkIfIsCurrentlyEditingText = (target: Element) => {
  const firstChild = target.children[0];

  if (firstChild === undefined) return false;

  const isCurrentlyEditingText =
    (firstChild as HTMLElement).dataset.isEditable === "true";

  return isCurrentlyEditingText;
};

const checkSelectionContainsACanvasElement = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const containsCanvasElement = selectedLayerIds.some((id) => {
    const layer = layers[id];

    if (layer === undefined) return false;

    return "children" in layer && layer.type !== LayerType.Group;
  });

  return containsCanvasElement;
};

const checkIfIsMovingIntoIteself = ({
  parentId,
  selectedLayerIds,
  layers,
}: {
  parentId: LayerId;
  selectedLayerIds: SelectedLayerIds;
  layers: Layers;
}) => {
  const isMovingIntoItself = selectedLayerIds.some((id) => {
    const draggingLayerDeepChildren = getChildIdsNested(layers, id);

    const isTheEnteredLayerADeepChildOfTheDraggingLayer =
      draggingLayerDeepChildren.includes(parentId);

    return isTheEnteredLayerADeepChildOfTheDraggingLayer;
  });

  return isMovingIntoItself;
};

const checkIfLayerIsParent = ({
  layers,
  parentId,
  ids,
}: {
  layers: Layers;
  parentId: LayerId;
  ids: LayerId[];
}) => {
  const isAlreadyParent = ids.some((id) => {
    const ancestorIds = getAncestorIds(layers, id);

    return ancestorIds.includes(parentId);
  });

  return isAlreadyParent;
};

/**
 * Select a sibling of a selected layer or the highest parent
 */
const getLayerIdToSelect = ({
  layerId,
  layers,
  selectedLayerIds,
}: {
  layers: Layers;
  selectedLayerIds: SelectedLayerIds;
  layerId: LayerId;
}) => {
  const isAlreadySelected = selectedLayerIds.includes(layerId);
  if (isAlreadySelected) return layerId;

  const layer = layers[layerId];

  if (layer === undefined) return layerId;

  if (layer.type === LayerType.Block || layer.type === LayerType.Page)
    return layerId;

  const ancestorIds = getAncestorIds(layers, layerId);

  const combinedIds = [layerId, ...ancestorIds];

  const selectedLayersParents = selectedLayerIds
    .map((id) => {
      const selectedLayer = layers[id];

      if (selectedLayer === undefined) return null;

      return selectedLayer.parentId;
    })
    .filter((id) => id !== null) as LayerId[];

  const findLayerToSelect = combinedIds.find((id) => {
    const currentLayer = layers[id];

    if (currentLayer === undefined || typeof currentLayer.parentId !== "string")
      return false;

    const parentLayer = layers[currentLayer.parentId];

    if (parentLayer === undefined) return false;

    const isSiblingLayer = selectedLayersParents.includes(
      currentLayer.parentId
    );

    const isParentABlockOrPageLayer =
      parentLayer.type === LayerType.Block ||
      parentLayer.type === LayerType.Page;

    return isParentABlockOrPageLayer || isSiblingLayer;
  });

  return findLayerToSelect ?? layerId;
};

export const useEventConnector = (layerId: string) => {
  const dispatch = useAppDispatch();

  const select = useCallback(
    (event: React.MouseEvent, selectLayerId: LayerId) => {
      event.stopPropagation();

      // We want to prevent highlighting text when we select a text layer
      // https://linear.app/folds/issue/FOL-129/shift-clicking-selecting-text
      if (event.shiftKey) {
        event.preventDefault();
      }

      // Blurring the focused element allows to make sure we apply changes to the correct selected layers
      // https://linear.app/folds/issue/FOL-90/applying-properties-to-wrong-selected-layers-on-blur
      const modalDocument = modalStore.getDocument();
      const currentlyFocusedElement = modalDocument?.activeElement;

      if (
        currentlyFocusedElement?.tagName === "INPUT" &&
        "blur" in currentlyFocusedElement &&
        typeof currentlyFocusedElement.blur === "function"
      ) {
        currentlyFocusedElement.blur();
      }

      const {
        editor: { selectedLayerIds },
      } = store.getState();

      // If the previous selected layer was a text layer, clear the selection
      clearTextSelection();

      const layerIsAlreadySelected = selectedLayerIds.includes(selectLayerId);

      if (event.shiftKey) {
        if (layerIsAlreadySelected) {
          dispatch(deselectLayer(selectLayerId));
        } else {
          dispatch(selectLayer(selectLayerId));
        }

        return;
      }

      if (!layerIsAlreadySelected) {
        dispatch(setSelectedLayerIds([selectLayerId]));
      }
    },
    [dispatch]
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (isMiddleClick(event) || isRightClick(event)) return;

      event.stopPropagation();

      const {
        editor: { canvasMode, layers, selectedLayerIds },
      } = store.getState();

      const layerIdToSelect = getLayerIdToSelect({
        layers,
        selectedLayerIds,
        layerId,
      });

      /*
       * When we hold down the alt key, and drag we want to duplicate the layers and move the original layers.
       */
      if (event.altKey) {
        if (selectedLayerIds.includes(layerIdToSelect)) {
          dispatch(startDuplicating(selectedLayerIds));
        } else {
          dispatch(startDuplicating([layerIdToSelect]));
        }
      }

      if (canvasMode !== "Select") return;

      const isCurrentlyEditingText = checkIfIsCurrentlyEditingText(
        event.currentTarget
      );

      if (isCurrentlyEditingText) {
        return;
      }

      const parentLayerId = getParentLayerId(layers, layerIdToSelect);
      if (parentLayerId === null) return;

      const parentLayerType = getLayerType(layers, parentLayerId);
      if (parentLayerType === null) return;

      select(event, layerIdToSelect);

      const isParentABlockLayer = parentLayerType === LayerType.Breakpoint;
      if (isParentABlockLayer) return;

      dispatch(
        dragStart({
          event: { clientX: event.clientX, clientY: event.clientY },
          // If shift is held down, we don't want to select the layer on drag end
          initializedDraggingLayerId: event.shiftKey ? null : layerId,
        })
      );
    },
    [dispatch, layerId, select]
  );

  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      const {
        editor: { layers, dragging },
      } = store.getState();

      if (dragging === null) return;

      const isAlreadyParent = checkIfLayerIsParent({
        layers,
        parentId: layerId,
        ids: dragging.draggingLayerIds,
      });

      if (isAlreadyParent) {
        return;
      }

      const isMovingIntoItself = checkIfIsMovingIntoIteself({
        selectedLayerIds: dragging.draggingLayerIds,
        layers,
        parentId: layerId,
      });

      // Dont allow moving a layer into itself˝
      if (isMovingIntoItself) {
        return;
      }

      const mouseEnteredLayer = layers[layerId];

      if (
        mouseEnteredLayer === undefined ||
        !("children" in mouseEnteredLayer) ||
        mouseEnteredLayer.type === LayerType.Breakpoint
      ) {
        return;
      }

      const isSelectingACanvasElement = checkSelectionContainsACanvasElement(
        layers,
        dragging.draggingLayerIds
      );

      const isCanvasLayerMovingIntoNonBlockElement =
        isSelectingACanvasElement && mouseEnteredLayer.type !== LayerType.Block;

      if (isCanvasLayerMovingIntoNonBlockElement) {
        return;
      }

      dispatch(mouseEnter(layerId));
    },
    [dispatch, layerId]
  );

  const handleMouseLeave = useCallback(
    (event: MouseEvent) => {
      const {
        editor: { selectedLayerIds, dragging, layers },
      } = store.getState();

      if (dragging === null) return;

      const isLayerParent = checkIfLayerIsParent({
        layers,
        parentId: layerId,
        ids: dragging.draggingLayerIds,
      });

      if (!isLayerParent) return;

      // Can't move out of itself
      const isLayerMovingOutOfItself = selectedLayerIds.some(
        (selectedLayerId) => selectedLayerId === layerId
      );

      if (isLayerMovingOutOfItself) return;

      event.stopPropagation();

      dispatch(mouseLeave({ layerMovedOutOfId: layerId }));
    },
    [dispatch, layerId]
  );

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const { selectedLayerIds } = store.getState().editor;

      if (!selectedLayerIds.includes(layerId)) {
        return;
      }

      event.stopPropagation();

      dispatch(
        openContextMenu({
          position: { x: event.clientX, y: event.clientY },
          layerId,
        })
      );
    },
    [dispatch, layerId]
  );

  const handleMouseOver = (event: MouseEvent) => {
    event.stopPropagation();
    dispatch(hoverLayer(layerId));
  };

  return {
    handleMouseDown,
    handleMouseLeave,
    handleMouseEnter,
    handleContextMenu,
    handleMouseOver,
  };
};
