export const RUNNING_SHOE = {
  id: 7595754717319,
  title: "Running shoe",
  body_html:
    '<p data-mce-fragment="1">Designed for runners, this shoe provides optimal support and cushioning for a comfortable and efficient stride. The lightweight design and durable materials make it perfect for long-distance runs. With a breathable upper material and responsive sole, this shoe will enhance your performance and keep you going strong.</p>',
  vendor: "Quickstart (b0e6decb)",
  product_type: "",
  created_at: "2024-03-27T07:33:50-04:00",
  handle: "running-shoe",
  updated_at: "2024-03-27T07:42:10-04:00",
  published_at: "2024-03-27T07:33:50-04:00",
  template_suffix: "",
  published_scope: "global",
  tags: "",
  status: "active",
  admin_graphql_api_id: "gid://shopify/Product/7595754717319",
  variants: [
    {
      id: 41123259187335,
      product_id: 7595754717319,
      title: "6 / Black",
      price: "59.95",
      sku: "",
      position: 1,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440795783,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259187335",
      image_id: 31391223382151,
    },
    {
      id: 41123259220103,
      product_id: 7595754717319,
      title: "6 / White",
      price: "59.95",
      sku: "",
      position: 2,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440828551,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259220103",
      image_id: 31391223971975,
    },
    {
      id: 41123259252871,
      product_id: 7595754717319,
      title: "6 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 3,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440861319,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259252871",
      image_id: 31391220695175,
    },
    {
      id: 41123340779655,
      product_id: 7595754717319,
      title: "6 / Brown",
      price: "59.95",
      sku: "",
      position: 4,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:44-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522388103,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340779655",
      image_id: 31391247007879,
    },
    {
      id: 41123259318407,
      product_id: 7595754717319,
      title: "6.5 / Black",
      price: "59.95",
      sku: "",
      position: 5,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6.5",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440926855,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259318407",
      image_id: 31391223382151,
    },
    {
      id: 41123259351175,
      product_id: 7595754717319,
      title: "6.5 / White",
      price: "59.95",
      sku: "",
      position: 6,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6.5",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440959623,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259351175",
      image_id: 31391223971975,
    },
    {
      id: 41123259383943,
      product_id: 7595754717319,
      title: "6.5 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 7,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6.5",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219440992391,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259383943",
      image_id: 31391220695175,
    },
    {
      id: 41123340812423,
      product_id: 7595754717319,
      title: "6.5 / Brown",
      price: "59.95",
      sku: "",
      position: 8,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "6.5",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:45-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522420871,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340812423",
      image_id: 31391247007879,
    },
    {
      id: 41123259449479,
      product_id: 7595754717319,
      title: "7 / Black",
      price: "59.95",
      sku: "",
      position: 9,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441057927,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259449479",
      image_id: 31391223382151,
    },
    {
      id: 41123259482247,
      product_id: 7595754717319,
      title: "7 / White",
      price: "59.95",
      sku: "",
      position: 10,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441090695,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259482247",
      image_id: 31391223971975,
    },
    {
      id: 41123259515015,
      product_id: 7595754717319,
      title: "7 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 11,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441123463,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259515015",
      image_id: 31391220695175,
    },
    {
      id: 41123340845191,
      product_id: 7595754717319,
      title: "7 / Brown",
      price: "59.95",
      sku: "",
      position: 12,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:45-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522453639,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340845191",
      image_id: 31391247007879,
    },
    {
      id: 41123259580551,
      product_id: 7595754717319,
      title: "7.5 / Black",
      price: "59.95",
      sku: "",
      position: 13,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7.5",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441188999,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259580551",
      image_id: 31391223382151,
    },
    {
      id: 41123259613319,
      product_id: 7595754717319,
      title: "7.5 / White",
      price: "59.95",
      sku: "",
      position: 14,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7.5",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441221767,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259613319",
      image_id: 31391223971975,
    },
    {
      id: 41123259646087,
      product_id: 7595754717319,
      title: "7.5 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 15,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7.5",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441254535,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259646087",
      image_id: 31391220695175,
    },
    {
      id: 41123340877959,
      product_id: 7595754717319,
      title: "7.5 / Brown",
      price: "59.95",
      sku: "",
      position: 16,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "7.5",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:45-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522486407,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340877959",
      image_id: 31391247007879,
    },
    {
      id: 41123259711623,
      product_id: 7595754717319,
      title: "8 / Black",
      price: "59.95",
      sku: "",
      position: 17,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441320071,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259711623",
      image_id: 31391223382151,
    },
    {
      id: 41123259744391,
      product_id: 7595754717319,
      title: "8 / White",
      price: "59.95",
      sku: "",
      position: 18,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441352839,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259744391",
      image_id: 31391223971975,
    },
    {
      id: 41123259777159,
      product_id: 7595754717319,
      title: "8 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 19,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441385607,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259777159",
      image_id: 31391220695175,
    },
    {
      id: 41123340910727,
      product_id: 7595754717319,
      title: "8 / Brown",
      price: "59.95",
      sku: "",
      position: 20,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522519175,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340910727",
      image_id: 31391247007879,
    },
    {
      id: 41123259842695,
      product_id: 7595754717319,
      title: "8.5 / Black",
      price: "59.95",
      sku: "",
      position: 21,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8.5",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441451143,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259842695",
      image_id: 31391223382151,
    },
    {
      id: 41123259875463,
      product_id: 7595754717319,
      title: "8.5 / White",
      price: "59.95",
      sku: "",
      position: 22,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8.5",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441483911,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259875463",
      image_id: 31391223971975,
    },
    {
      id: 41123259908231,
      product_id: 7595754717319,
      title: "8.5 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 23,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8.5",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441516679,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259908231",
      image_id: 31391220695175,
    },
    {
      id: 41123340943495,
      product_id: 7595754717319,
      title: "8.5 / Brown",
      price: "59.95",
      sku: "",
      position: 24,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "8.5",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522551943,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340943495",
      image_id: 31391247007879,
    },
    {
      id: 41123259973767,
      product_id: 7595754717319,
      title: "9 / Black",
      price: "59.95",
      sku: "",
      position: 25,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441582215,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123259973767",
      image_id: 31391223382151,
    },
    {
      id: 41123260006535,
      product_id: 7595754717319,
      title: "9 / White",
      price: "59.95",
      sku: "",
      position: 26,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441614983,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260006535",
      image_id: 31391223971975,
    },
    {
      id: 41123260039303,
      product_id: 7595754717319,
      title: "9 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 27,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441647751,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260039303",
      image_id: 31391220695175,
    },
    {
      id: 41123340976263,
      product_id: 7595754717319,
      title: "9 / Brown",
      price: "59.95",
      sku: "",
      position: 28,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522584711,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123340976263",
      image_id: 31391247007879,
    },
    {
      id: 41123260104839,
      product_id: 7595754717319,
      title: "9.5 / Black",
      price: "59.95",
      sku: "",
      position: 29,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9.5",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441713287,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260104839",
      image_id: 31391223382151,
    },
    {
      id: 41123260137607,
      product_id: 7595754717319,
      title: "9.5 / White",
      price: "59.95",
      sku: "",
      position: 30,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9.5",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441746055,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260137607",
      image_id: 31391223971975,
    },
    {
      id: 41123260170375,
      product_id: 7595754717319,
      title: "9.5 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 31,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9.5",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441778823,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260170375",
      image_id: 31391220695175,
    },
    {
      id: 41123341009031,
      product_id: 7595754717319,
      title: "9.5 / Brown",
      price: "59.95",
      sku: "",
      position: 32,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "9.5",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522617479,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123341009031",
      image_id: 31391247007879,
    },
    {
      id: 41123260235911,
      product_id: 7595754717319,
      title: "10 / Black",
      price: "59.95",
      sku: "",
      position: 33,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "10",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441844359,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260235911",
      image_id: 31391223382151,
    },
    {
      id: 41123260268679,
      product_id: 7595754717319,
      title: "10 / White",
      price: "59.95",
      sku: "",
      position: 34,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "10",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441877127,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260268679",
      image_id: 31391223971975,
    },
    {
      id: 41123260301447,
      product_id: 7595754717319,
      title: "10 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 35,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "10",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441909895,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260301447",
      image_id: 31391220695175,
    },
    {
      id: 41123341041799,
      product_id: 7595754717319,
      title: "10 / Brown",
      price: "59.95",
      sku: "",
      position: 36,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "10",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:46-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522650247,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123341041799",
      image_id: 31391247007879,
    },
    {
      id: 41123260366983,
      product_id: 7595754717319,
      title: "11 / Black",
      price: "59.95",
      sku: "",
      position: 37,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "11",
      option2: "Black",
      option3: null,
      created_at: "2024-03-27T07:35:47-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219441975431,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260366983",
      image_id: 31391223382151,
    },
    {
      id: 41123260399751,
      product_id: 7595754717319,
      title: "11 / White",
      price: "59.95",
      sku: "",
      position: 38,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "11",
      option2: "White",
      option3: null,
      created_at: "2024-03-27T07:35:47-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219442008199,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260399751",
      image_id: 31391223971975,
    },
    {
      id: 41123260432519,
      product_id: 7595754717319,
      title: "11 / Navy Blue",
      price: "59.95",
      sku: "",
      position: 39,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "11",
      option2: "Navy Blue",
      option3: null,
      created_at: "2024-03-27T07:35:47-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219442040967,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123260432519",
      image_id: 31391220695175,
    },
    {
      id: 41123341074567,
      product_id: 7595754717319,
      title: "11 / Brown",
      price: "59.95",
      sku: "",
      position: 40,
      inventory_policy: "deny",
      compare_at_price: null,
      fulfillment_service: "manual",
      inventory_management: null,
      option1: "11",
      option2: "Brown",
      option3: null,
      created_at: "2024-03-27T07:40:47-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      taxable: false,
      barcode: "",
      grams: 0,
      weight: 0,
      weight_unit: "kg",
      inventory_item_id: 43219522683015,
      inventory_quantity: 0,
      old_inventory_quantity: 0,
      requires_shipping: true,
      admin_graphql_api_id: "gid://shopify/ProductVariant/41123341074567",
      image_id: 31391247007879,
    },
  ],
  options: [
    {
      id: 10073506054279,
      product_id: 7595754717319,
      name: "Size",
      position: 1,
      values: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "11"],
    },
    {
      id: 10073506087047,
      product_id: 7595754717319,
      name: "Color",
      position: 2,
      values: ["Black", "White", "Navy Blue", "Brown"],
    },
  ],
  images: [
    {
      id: 31391220695175,
      alt: null,
      position: 1,
      product_id: 7595754717319,
      created_at: "2024-03-27T07:38:46-04:00",
      updated_at: "2024-03-27T07:38:47-04:00",
      admin_graphql_api_id: "gid://shopify/ProductImage/31391220695175",
      width: 700,
      height: 700,
      src: "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_navy_blue_running_shoes_for_an_e-co_1b5a5b44-2abe-4384-84bc-f737ad995b02.webp?v=1711539527",
      variant_ids: [
        41123259252871, 41123259383943, 41123259515015, 41123259646087,
        41123259777159, 41123259908231, 41123260039303, 41123260170375,
        41123260301447, 41123260432519,
      ],
    },
    {
      id: 31391223382151,
      alt: null,
      position: 2,
      product_id: 7595754717319,
      created_at: "2024-03-27T07:39:05-04:00",
      updated_at: "2024-03-27T07:39:06-04:00",
      admin_graphql_api_id: "gid://shopify/ProductImage/31391223382151",
      width: 700,
      height: 700,
      src: "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_black_runner_shoes_for_an_e-commer__4f998c15-291d-4da8-8ea8-bebdd65d3085.webp?v=1711539546",
      variant_ids: [
        41123259187335, 41123259318407, 41123259449479, 41123259580551,
        41123259711623, 41123259842695, 41123259973767, 41123260104839,
        41123260235911, 41123260366983,
      ],
    },
    {
      id: 31391223971975,
      alt: null,
      position: 3,
      product_id: 7595754717319,
      created_at: "2024-03-27T07:39:09-04:00",
      updated_at: "2024-03-27T07:39:11-04:00",
      admin_graphql_api_id: "gid://shopify/ProductImage/31391223971975",
      width: 1024,
      height: 1024,
      src: "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/0_2_26.webp?v=1711539551",
      variant_ids: [
        41123259220103, 41123259351175, 41123259482247, 41123259613319,
        41123259744391, 41123259875463, 41123260006535, 41123260137607,
        41123260268679, 41123260399751,
      ],
    },
    {
      id: 31391247007879,
      alt: null,
      position: 4,
      product_id: 7595754717319,
      created_at: "2024-03-27T07:41:47-04:00",
      updated_at: "2024-03-27T07:42:07-04:00",
      admin_graphql_api_id: "gid://shopify/ProductImage/31391247007879",
      width: 700,
      height: 700,
      src: "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_brown_running_shoes_for_an_e-commer_b50b9173-b2d5-41c6-85d1-4aad25f9ab0e.webp?v=1711539709",
      variant_ids: [
        41123340779655, 41123340812423, 41123340845191, 41123340877959,
        41123340910727, 41123340943495, 41123340976263, 41123341009031,
        41123341041799, 41123341074567,
      ],
    },
  ],
  image: {
    id: 31391220695175,
    alt: null,
    position: 1,
    product_id: 7595754717319,
    created_at: "2024-03-27T07:38:46-04:00",
    updated_at: "2024-03-27T07:38:47-04:00",
    admin_graphql_api_id: "gid://shopify/ProductImage/31391220695175",
    width: 700,
    height: 700,
    src: "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_navy_blue_running_shoes_for_an_e-co_1b5a5b44-2abe-4384-84bc-f737ad995b02.webp?v=1711539527",
    variant_ids: [
      41123259252871, 41123259383943, 41123259515015, 41123259646087,
      41123259777159, 41123259908231, 41123260039303, 41123260170375,
      41123260301447, 41123260432519,
    ],
  },
};
