import { Input } from "@/features/sidebar/components/Input";
import {
  setSelectedLayersHorizontalGap,
  setSelectedLayersVerticalGap,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getAbsolutePosition, getLayerDimensions } from "@core/utils";
import { useEffect, useState } from "react";

function HorziontalGap() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75008 1.16669C2.07225 1.16669 2.33341 1.42785 2.33341 1.75002V12.25C2.33341 12.5722 2.07225 12.8334 1.75008 12.8334C1.42792 12.8334 1.16675 12.5722 1.16675 12.25V1.75002C1.16675 1.42785 1.42792 1.16669 1.75008 1.16669ZM12.2501 1.16669C12.5722 1.16669 12.8334 1.42785 12.8334 1.75002V12.25C12.8334 12.5722 12.5722 12.8334 12.2501 12.8334C11.9279 12.8334 11.6667 12.5722 11.6667 12.25V1.75002C11.6667 1.42785 11.9279 1.16669 12.2501 1.16669ZM3.79175 4.66669C4.11391 4.66669 4.37508 4.92785 4.37508 5.25002L4.37508 6.41669H9.62508V5.25002C9.62508 4.92785 9.88625 4.66669 10.2084 4.66669C10.5306 4.66669 10.7917 4.92785 10.7917 5.25002V8.75002C10.7917 9.07219 10.5306 9.33335 10.2084 9.33335C9.88625 9.33335 9.62508 9.07219 9.62508 8.75002V7.58335H4.37508L4.37508 8.75002C4.37508 9.07219 4.11391 9.33335 3.79175 9.33335C3.46958 9.33335 3.20842 9.07219 3.20842 8.75002L3.20842 7.00052C3.20842 7.00035 3.20842 7.00069 3.20842 7.00052C3.20842 7.00035 3.20841 6.99969 3.20841 6.99952L3.20841 5.25002C3.20841 4.92785 3.46958 4.66669 3.79175 4.66669Z"
        className="fill-gray-11"
      />
    </svg>
  );
}

function VerticalGap() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16675 12.25C1.16675 11.9278 1.42792 11.6666 1.75008 11.6666H12.2501C12.5722 11.6666 12.8334 11.9278 12.8334 12.25C12.8334 12.5721 12.5722 12.8333 12.2501 12.8333H1.75008C1.42792 12.8333 1.16675 12.5721 1.16675 12.25ZM1.16675 1.74998C1.16675 1.42781 1.42792 1.16665 1.75008 1.16665H12.2501C12.5722 1.16665 12.8334 1.42781 12.8334 1.74998C12.8334 2.07215 12.5722 2.33331 12.2501 2.33331H1.75008C1.42792 2.33331 1.16675 2.07215 1.16675 1.74998ZM4.66675 10.2083C4.66675 9.88615 4.92792 9.62498 5.25008 9.62498L6.41675 9.62498L6.41675 4.37498H5.25008C4.92792 4.37498 4.66675 4.11381 4.66675 3.79165C4.66675 3.46948 4.92792 3.20831 5.25008 3.20831H8.75008C9.07225 3.20831 9.33341 3.46948 9.33341 3.79165C9.33341 4.11381 9.07225 4.37498 8.75008 4.37498H7.58341L7.58341 9.62498L8.75008 9.62498C9.07225 9.62498 9.33341 9.88615 9.33341 10.2083C9.33341 10.5305 9.07225 10.7916 8.75008 10.7916L7.00058 10.7916C7.00041 10.7916 7.00075 10.7916 7.00058 10.7916C7.00041 10.7916 6.99975 10.7916 6.99958 10.7916L5.25008 10.7916C4.92792 10.7916 4.66675 10.5305 4.66675 10.2083Z"
        fill="#687076"
      />
    </svg>
  );
}

const useHorizontalGap = () => {
  const [horizontalGap, setHorizontalGap] = useState<null | number | "Mixed">(
    null
  );

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const layers = useAppSelector((state) => state.editor.layers);
  const breakpoint = useAppSelector((state) => state.editor.breakpoint);

  useEffect(() => {
    if (selectedLayerIds.length < 2) {
      setHorizontalGap(null);
      return;
    }

    const selectedLayersPositions = selectedLayerIds.map((id) => ({
      id,
      box: { ...getAbsolutePosition(id), ...getLayerDimensions(id) },
    }));

    const sortedSelectedLayersPositions = selectedLayersPositions.sort(
      (a, b) => a.box.left - b.box.left
    );

    let layersAreOverlapping = false as boolean;

    const gap = sortedSelectedLayersPositions.reduce(
      (acc, layer, index) => {
        const nextLayer = sortedSelectedLayersPositions[index + 1];

        if (!nextLayer) return acc;

        const currentGap = Math.round(
          nextLayer.box.left - (layer.box.left + layer.box.width)
        );

        // This means they are overlapping
        if (currentGap < 0) {
          layersAreOverlapping = true;
        }

        if (typeof acc === "number" && acc !== currentGap) return "Mixed";

        return currentGap;
      },
      null as null | number | "Mixed"
    );

    if (layersAreOverlapping === true) {
      setHorizontalGap(null);
      return;
    }

    if (typeof gap === "number") {
      setHorizontalGap(Math.round(gap));
      return;
    }

    setHorizontalGap(gap);
  }, [selectedLayerIds, layers, breakpoint]);

  return horizontalGap;
};

const useVerticalGap = () => {
  const [verticalGap, setVerticalGap] = useState<null | number | "Mixed">(null);

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const layers = useAppSelector((state) => state.editor.layers);
  const breakpoint = useAppSelector((state) => state.editor.breakpoint);

  useEffect(() => {
    if (selectedLayerIds.length < 2) {
      setVerticalGap(null);
      return;
    }

    const selectedLayersPositions = selectedLayerIds.map((id) => ({
      id,
      box: { ...getAbsolutePosition(id), ...getLayerDimensions(id) },
    }));

    const sortedSelectedLayersPositions = selectedLayersPositions.sort(
      (a, b) => a.box.top - b.box.top
    );

    let layersAreOverlapping = false as boolean;

    const gap = sortedSelectedLayersPositions.reduce(
      (acc, layer, index) => {
        const nextLayer = sortedSelectedLayersPositions[index + 1];

        if (!nextLayer) return acc;

        const currentGap = Math.round(
          nextLayer.box.top - (layer.box.top + layer.box.height)
        );

        // This means they are overlapping
        if (currentGap < 0) {
          layersAreOverlapping = true;
        }

        if (typeof acc === "number" && acc !== currentGap) return "Mixed";

        return currentGap;
      },
      null as null | number | "Mixed"
    );

    if (layersAreOverlapping === true) {
      setVerticalGap(null);
      return;
    }

    if (typeof gap === "number") {
      setVerticalGap(Math.round(gap));
      return;
    }

    setVerticalGap(gap);
  }, [selectedLayerIds, layers, breakpoint]);

  return verticalGap;
};

export function MainInformationGapSettings() {
  const horizontalGap = useHorizontalGap();
  const verticalGap = useVerticalGap();

  const dispatch = useAppDispatch();

  const handleChangeHorizontalGap = (value: string) => {
    const parsedValue = Number(value);

    if (Number.isNaN(parsedValue)) return;

    dispatch(setSelectedLayersHorizontalGap(parsedValue));
  };

  const handleChangeVerticalGap = (value: string) => {
    const parsedValue = Number(value);

    if (Number.isNaN(parsedValue)) return;

    dispatch(setSelectedLayersVerticalGap(parsedValue));
  };

  if (horizontalGap === null && verticalGap === null) return null;

  return (
    <div className="flex gap-2">
      {horizontalGap !== null && (
        <Input
          unit="px"
          fill
          icon={<HorziontalGap />}
          value={String(horizontalGap)}
          minValue={0}
          onValueChange={handleChangeHorizontalGap}
        />
      )}
      {verticalGap !== null && (
        <Input
          unit="px"
          fill
          icon={<VerticalGap />}
          value={String(verticalGap)}
          minValue={0}
          onValueChange={handleChangeVerticalGap}
        />
      )}
    </div>
  );
}
