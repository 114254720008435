import {
  selectHorizontalGuides,
  selectVerticalGuides,
} from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import { getBoundingBoxRect } from "@core/utils";
import { Guide, LayerId } from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";

function HorizontalGuide({
  guide,
  scale,
  ids,
}: {
  guide: Guide;
  scale: number;
  ids: LayerId[];
}) {
  const selectionBox = getBoundingBoxRect(ids);

  const selectionAndSnappedToLayerBox = getBoundingBoxRect([
    ...ids,
    guide.layerId,
  ]);

  const getIndicatorTop = (side: Guide["side"]) => {
    switch (side) {
      case "start": {
        return selectionBox.top;
      }
      case "center": {
        return selectionBox.top + selectionBox.height / 2;
      }
      case "end": {
        return selectionBox.top + selectionBox.height;
      }
    }
  };

  const getIndicatorLeft = () => selectionAndSnappedToLayerBox.left;
  const getIndicatorWidth = () => selectionAndSnappedToLayerBox.width;

  const top = getIndicatorTop(guide.side);
  const left = getIndicatorLeft();
  const width = getIndicatorWidth();

  const style: React.CSSProperties = {
    height: 1 / scale,
    left,
    top,
    width,
    zIndex: 101,
  };

  return (
    <div
      className="pointer-events-none absolute bg-[red] opacity-80"
      style={style}
    />
  );
}

function VerticalGuide({
  guide,
  scale,
  ids,
}: {
  guide: Guide;
  scale: number;
  ids: LayerId[];
}) {
  const selectionBox = getBoundingBoxRect(ids);

  const selectionAndSnappedToLayerBox = getBoundingBoxRect([
    ...ids,
    guide.layerId,
  ]);

  const getIndicatorLeft = (side: Guide["side"]) => {
    switch (side) {
      case "start": {
        return selectionBox.left;
      }
      case "center": {
        return selectionBox.left + selectionBox.width / 2;
      }
      case "end": {
        return selectionBox.left + selectionBox.width;
      }
    }
  };

  const getIndicatorTop = () => selectionAndSnappedToLayerBox.top;
  const getIndicatorHeight = () => selectionAndSnappedToLayerBox.height;

  const left = getIndicatorLeft(guide.side);
  const top = getIndicatorTop();
  const height = getIndicatorHeight();

  const style: React.CSSProperties = {
    left,
    top,
    height,
    width: 1 / scale,
    zIndex: 101,
  };

  return (
    <div
      className="pointer-events-none absolute bg-[red] opacity-80"
      style={style}
    />
  );
}

const selectSelectedIds = createSelector(
  [
    (state: RootState) => state.editor.resizing,
    (state: RootState) => state.editor.dragging,
  ],
  (resizing, dragging) => {
    if (dragging !== null) {
      return dragging.draggingLayerIds;
    }

    if (resizing !== null) {
      return resizing.initialLayersInformation.map(
        (initialLayerInformation) => initialLayerInformation.layerId
      );
    }

    return null;
  }
);

export function AbsoluteGuides() {
  const horizontalGuides = useAppSelector(selectHorizontalGuides);
  const verticalGuides = useAppSelector(selectVerticalGuides);

  const selectedIds = useAppSelector(selectSelectedIds);
  const scale = useAppSelector((state) => state.editor.scale);

  if (selectedIds === null) return null;

  const horizontalGuideIndicators = horizontalGuides.map((guide) => (
    <HorizontalGuide
      ids={selectedIds}
      key={guide.id}
      scale={scale}
      guide={guide}
    />
  ));

  const verticalGuideIndicators = verticalGuides.map((guide) => (
    <VerticalGuide
      key={guide.id}
      scale={scale}
      guide={guide}
      ids={selectedIds}
    />
  ));

  return (
    <>
      {horizontalGuideIndicators}
      {verticalGuideIndicators}
    </>
  );
}
