import { usePreviewProducts } from "@/features/editor/context/PreviewProductsContext";
import { ShopifyProduct } from "@folds/shared";

const getMatchingPreviewProduct = (
  previewProducts: ShopifyProduct[] | null,
  id: ShopifyProduct["id"] | null
) => {
  if (previewProducts === null) return null;

  const matchingPreviewProduct = previewProducts.find(
    (currentProduct) => currentProduct.id === id
  );

  return matchingPreviewProduct ?? null;
};

export const useMatchingPreviewProduct = (
  productId: ShopifyProduct["id"] | null
) => {
  const previewProducts = usePreviewProducts();

  if (productId === null) return null;

  const matchingPreviewProduct = getMatchingPreviewProduct(
    previewProducts,
    productId
  );

  return matchingPreviewProduct;
};
