import {
  blockAddedEvent,
  imageAddedEvent,
  elementAddedEvent,
  textAddedEvent,
  iconAddedEvent,
  templateAddedEvent,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { store } from "@core/store";
import { getElement } from "@core/utils";
import { LayerId } from "@folds/shared/types";
import { compiledEventSchema } from "@folds/shared/types/src/events";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";
import { captureException } from "@sentry/react";

const getDropPosiion = (event: React.DragEvent, parentId: LayerId) => {
  const {
    editor: { scale },
  } = store.getState();

  const parentElement = getElement(parentId);

  if (parentElement === null) {
    return { top: 0, left: 0 };
  }

  const targetBoundingBox = parentElement.getBoundingClientRect();

  const left = event.clientX - targetBoundingBox.left;
  const top = event.clientY - targetBoundingBox.top;

  // Adjust the position based on the viewport scale
  const scaledLeft = left / scale;
  const scaledTop = top / scale;

  return { top: scaledTop, left: scaledLeft };
};

const getJSONData = (data: string): Record<string, unknown> | null => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return null;
  }
};

const getImageDimensions = (
  url: string
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      });
    img.onerror = (error) => reject(error);
    img.src = url;
  });

export const useDrop = (layerId: LayerId) => {
  const dispatch = useAppDispatch();
  const authenticatedAxios = useAxios();

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    // Don't trigger drop events on parent elements
    event.stopPropagation();

    const { dataTransfer } = event;

    // Get the data attributes from the draggable element
    const data = dataTransfer.getData("application/json");

    const parsedData = getJSONData(data);

    try {
      const parsedEvent = compiledEventSchema.Decode(parsedData);
      const position = getDropPosiion(event, layerId);

      switch (parsedEvent.type) {
        case "text": {
          dispatch(
            textAddedEvent({
              targetLayerId: layerId,
              event: parsedEvent,
              position,
            })
          );

          return;
        }
        case "element": {
          dispatch(
            elementAddedEvent({
              event: parsedEvent,
              targetLayerId: layerId,
              position,
            })
          );

          break;
        }
        case "block": {
          dispatch(blockAddedEvent(parsedEvent));

          break;
        }
        case "template": {
          dispatch(templateAddedEvent(parsedEvent));

          break;
        }
        case "icon": {
          const { id } = parsedEvent;

          toast.loading("Adding icon...", { id: "adding-icon" });

          authenticatedAxios
            .get(`/icons/${id}`)
            .then((respnse) => {
              dispatch(
                iconAddedEvent({
                  svg: respnse.data,
                  targetId: layerId,
                  position,
                })
              );

              toast.success("Icon added", { id: "adding-icon" });
            })
            .catch((error) => {
              captureException(error);
              toast.error("There was an issue adding the icon", {
                id: "adding-icon",
              });
            });

          break;
        }
        case "image": {
          const { src } = parsedEvent;

          getImageDimensions(src)
            .then(({ height, width }) => {
              dispatch(
                imageAddedEvent({
                  height,
                  width,
                  src,
                  position,
                  targetId: layerId,
                })
              );
            })
            .catch((error) => {
              captureException(error);
              toast.error("There was an issue adding the image");
            });
        }
      }
    } catch {
      // Do nothing
    }
  };

  return handleDrop;
};
