import {
  BreakpointPropertyName,
  LayerBreakpointKeys,
  getBreakpointPropertyName,
} from "@/features/editor/utils/getBreakpointPropertyValue";
import {
  deleteMobileOverride,
  deleteMobileOverrides,
  deleteTabletOverride,
  deleteTabletOverrides,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import type { RootState } from "@core/store";
import { createSelector } from "@reduxjs/toolkit";

function MinusIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41422 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41422 3 9Z"
        className="fill-gray-12"
      />
    </svg>
  );
}

const selectTabletOverrides = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];
    if (firstSelectedLayerId === undefined) return null;

    const layer = layers[firstSelectedLayerId];
    if (layer === undefined || !("tablet" in layer)) return null;

    const overridesWithNull = Object.keys(layer.tablet).map((key) =>
      getBreakpointPropertyName(key as LayerBreakpointKeys)
    );

    const overrides = overridesWithNull.filter(
      (property) => property !== null
    ) as Exclude<(typeof overridesWithNull)[number], null>[];

    const uniqueOverrides = Array.from(new Set(overrides));

    return uniqueOverrides;
  }
);

const selectMobileOverrides = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const firstSelectedLayerId = selectedLayerIds[0];
    if (firstSelectedLayerId === undefined) return null;

    const layer = layers[firstSelectedLayerId];
    if (layer === undefined || !("mobile" in layer)) return null;

    const overridesWithNull = Object.keys(layer.mobile).map((key) =>
      getBreakpointPropertyName(key as LayerBreakpointKeys)
    );

    const overrides = overridesWithNull.filter(
      (property) => property !== null
    ) as Exclude<(typeof overridesWithNull)[number], null>[];

    const uniqueOverrides = Array.from(new Set(overrides));

    return uniqueOverrides;
  }
);

export function BreakpointOverrides() {
  const tabletOverrides = useAppSelector(selectTabletOverrides);
  const mobileOverrides = useAppSelector(selectMobileOverrides);

  const dispatch = useAppDispatch();

  const handleDeleteTabletOverrides = () => {
    dispatch(deleteTabletOverrides());
  };

  const handleDeleteMobileOverrides = () => {
    dispatch(deleteMobileOverrides());
  };

  const handleDeleteTabletOverride = (value: BreakpointPropertyName) => {
    dispatch(deleteTabletOverride(value));
  };

  const handleDeleteMobileOverride = (value: BreakpointPropertyName) => {
    dispatch(deleteMobileOverride(value));
  };

  if (tabletOverrides === null || mobileOverrides === null) return null;
  if (tabletOverrides.length === 0 && mobileOverrides.length === 0) return null;

  return (
    <div className="flex flex-col gap-4 border-t border-solid border-gray-6 p-3">
      <p className="color-gray-12 text-sm font-medium">Breakpoint overrides</p>
      {tabletOverrides.length > 0 && (
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <p className="text-xs font-medium text-gray-12">Tablet</p>
            <button
              type="button"
              aria-label="Delete tablet overrides"
              onClick={handleDeleteTabletOverrides}
            >
              <MinusIcon />
            </button>
          </div>
          <div className="flex flex-col gap-3">
            {tabletOverrides.map((override) => (
              <div className="flex justify-between" key={override}>
                <p className="text-xs text-gray-12 first-letter:capitalize">
                  {override}
                </p>
                <button
                  type="button"
                  aria-label={`Delete tablet ${override} override`}
                  onClick={() => handleDeleteTabletOverride(override)}
                >
                  <MinusIcon />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      {mobileOverrides.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <p className="text-xs font-medium text-gray-12">Mobile</p>
            <button
              type="button"
              aria-label="Delete mobile overrides"
              onClick={handleDeleteMobileOverrides}
            >
              <MinusIcon />
            </button>
          </div>
          <div className="flex flex-col gap-2">
            {mobileOverrides.map((override) => (
              <div className="flex justify-between" key={override}>
                <p className="text-xs text-gray-12 first-letter:capitalize">
                  {override}
                </p>
                <button
                  type="button"
                  aria-label={`Delete mobile ${override} override`}
                  onClick={() => handleDeleteMobileOverride(override)}
                >
                  <MinusIcon />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
