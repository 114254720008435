import { useState } from "react";
import { ShopifyPage } from "@folds/shared";
import { useAllPages } from "@/features/sidebar/api/getAllPages";
import { matchSorter } from "match-sorter";
import { twMerge } from "tailwind-merge";

export function PageSelect({
  onSelect,
  inputClassName,
  itemClassName,
  wrapperClassName,
}: {
  onSelect: (id: ShopifyPage["id"]) => void;
  wrapperClassName?: string;
  itemClassName?: string;
  inputClassName?: string;
}) {
  const [searchValue, setsSearchValue] = useState("");

  const { data: pages, isLoading, error } = useAllPages();

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setsSearchValue(event.target.value);
  };

  const filteredPages = matchSorter(pages ?? [], searchValue, {
    keys: ["title"],
  });

  return (
    <div
      className={twMerge(
        "flex w-56 flex-col gap-1 rounded border border-gray-6 bg-gray-1 p-2",
        wrapperClassName
      )}
    >
      <input
        placeholder="Search products"
        value={searchValue}
        onChange={handleSearchValueChange}
        className={twMerge(
          "h-8 border border-solid border-gray-6 px-3 text-sm text-gray-12",
          inputClassName
        )}
      />
      <div className="flex h-64 flex-col overflow-y-auto">
        {isLoading && (
          <div className="flex flex-col gap-1">
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
          </div>
        )}
        {pages !== undefined && pages.length === 0 && (
          <p className="whitespace-normal text-center text-sm text-gray-11">
            No products found
          </p>
        )}
        {pages === undefined && error !== undefined && (
          <p className="whitespace-normal text-center text-sm text-gray-11">
            Something went wrong
          </p>
        )}
        {pages !== undefined &&
          filteredPages.map((page) => (
            <button
              type="button"
              className={twMerge(
                "min-h-[28px] shrink-0 grow-0 basis-auto rounded-sm px-2 py-1 text-left text-xs text-gray-12 hover:bg-gray-4",
                itemClassName
              )}
              key={page.id}
              onClick={() => onSelect(page.id)}
            >
              <p className="line-clamp-2">{page.title}</p>
            </button>
          ))}
      </div>
    </div>
  );
}
