import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";
import { ROOT_LAYER_ID } from "@folds/shared";
import {
  Breakpoint,
  Layer,
  LayerId,
  LayerType,
  Layers,
  Position,
} from "@folds/shared/types";
import { Draft } from "immer";

const assignLeftPosition = (
  rootLayer: Draft<Layer>,
  {
    isTargetLayerABlock,
    originalLeft,
    responsiveLeft,
    breakpoint,
  }: {
    isTargetLayerABlock: boolean;
    originalLeft: number;
    responsiveLeft: ReturnType<typeof getLayerResponsiveLeftPosition>;
    breakpoint: Breakpoint;
  }
) => {
  if (!("top" in rootLayer) || !("left" in rootLayer)) return;

  if (isTargetLayerABlock === false) {
    rootLayer.left = originalLeft;
    return;
  }

  // Since the element widths may not be 100% perfect, we should use the original left position when possible
  switch (breakpoint) {
    case "desktop": {
      rootLayer.left = originalLeft;
      rootLayer.tablet.left = responsiveLeft.tablet;
      rootLayer.mobile.left = responsiveLeft.mobile;
      break;
    }
    case "tablet": {
      rootLayer.left = responsiveLeft.desktop;
      rootLayer.tablet.left = originalLeft;
      rootLayer.mobile.left = responsiveLeft.mobile;
      break;
    }
    case "mobile": {
      rootLayer.left = responsiveLeft.desktop;
      rootLayer.tablet.left = responsiveLeft.tablet;
      rootLayer.mobile.left = originalLeft;
    }
  }
};

export const assignPosition = (
  draft: Draft<Layers>,
  {
    breakpoint,
    layers,
    targetLayerId,
    position,
    width,
  }: {
    breakpoint: Breakpoint;
    layers: Layers;
    targetLayerId: LayerId;
    position: Position;
    width: number;
  }
) => {
  const rootLayer = draft[ROOT_LAYER_ID];

  if (rootLayer === undefined) return;

  const isExtrinsicallyPositioned = "top" in rootLayer && "left" in rootLayer;
  const isTargetLayerABlock = layers[targetLayerId]?.type === LayerType.Block;

  // Ex: Rectangles are extrinsically positioned, groups are intrinsically positioned
  if (isExtrinsicallyPositioned) {
    const responsiveLeft = getLayerResponsiveLeftPosition({
      breakpoint,
      left: position.left,
      width,
    });

    assignLeftPosition(rootLayer, {
      isTargetLayerABlock,
      originalLeft: position.left,
      responsiveLeft,
      breakpoint,
    });

    rootLayer.top = position.top;

    return;
  }

  if (!("children" in rootLayer)) return;

  rootLayer.children.forEach((childId) => {
    const child = draft[childId];

    if (child === undefined) {
      return;
    }

    if (!("top" in child) || !("left" in child)) {
      return;
    }

    if ("width" in child && typeof child.width === "number") {
      const responsiveLeft = getLayerResponsiveLeftPosition({
        breakpoint,
        left: child.left,
        width: child.width,
      });

      assignLeftPosition(child, {
        isTargetLayerABlock,
        originalLeft: child.left,
        responsiveLeft,
        breakpoint,
      });
    }

    child.top = position.top + child.top;
    child.left = position.left + child.left;
  });
};
