import { Layers, ShopifyProduct } from "@folds/shared";

export const getProductIdsFromLayers = (layers: Layers) => {
  const productIds = Object.values(layers).reduce<ShopifyProduct["id"][]>(
    (acc, layer) => {
      if ("productId" in layer && layer.productId !== null) {
        acc.push(layer.productId);
      }

      if (
        "action" in layer &&
        layer.action &&
        "productId" in layer.action &&
        typeof layer.action.productId === "number"
      ) {
        acc.push(layer.action.productId);
      }

      if (
        "productUnavailableId" in layer &&
        typeof layer.productUnavailableId === "number"
      ) {
        acc.push(layer.productUnavailableId);
      }

      return acc;
    },
    []
  );

  const uniqueProductIds = Array.from(new Set(productIds));

  return uniqueProductIds;
};
