import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { LayerId, Layers } from "@folds/shared/types";

export const getParentLayerId = (layers: Layers, id: LayerId) => {
  const layer = layers[id];

  if (!layer) return null;

  const { parentId } = layer;

  if (parentId === null) return null;

  const parentLayer = layers[parentId];

  if (!parentLayer) return null;

  const isInstrinsicallyPositioned =
    checkIfLayerIsIntrinsicallyPositioned(parentLayer);

  if (isInstrinsicallyPositioned) return parentLayer.parentId;

  return parentId;
};
