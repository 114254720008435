import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  PASTE_COMMAND,
} from "lexical";
import { useEffect } from "react";

/**
 * Overrides the paste to paste as plain text
 * We don't want to paste as html because it will break the styles when pasting from an external source.
 * For example: pasting from google docs will currently break the styles
 */
export function LexicalPasteAsPlainTextPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const cleanupPasteCommandListener = editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        if (!("clipboardData" in event)) return true;

        const pastedText = event.clipboardData?.getData("text/plain");
        if (pastedText === undefined) return true;

        const seleciton = $getSelection();

        if (seleciton == null) return true;
        seleciton.insertText(pastedText);

        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );

    return () => {
      cleanupPasteCommandListener();
    };
  }, [editor]);

  return null;
}
