import { selectIsResizing } from "@core/features/editor/editorSlice";
import { LexicalPasteAsPlainTextPlugin } from "@core/features/text/plugins/LexicalPasteAsPlainTextPlugin";
import { LexicalSyncPlainTextPlugin } from "@core/features/text/plugins/LexicalSyncPlainTextPlugin";
import { LexicalUpdatePlainTextPlugin } from "@core/features/text/plugins/LexicalUpdatePlainTextPlugin";
import { useAppSelector } from "@core/hooks";
import { SyncContentEditableAttributes } from "@core/user-components/LexicalPlainText/SyncContentEditableAttributes";
import { DoubleClickToEditPlugin } from "@core/user-components/LexicalPlainText/DoubleClickToEditPlugin";
import { LexicalHotkeysPlugin } from "@core/user-components/LexicalPlainText/LexicalHotkeysPlugin";
import { LayerId } from "@folds/shared/types";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import clsx from "clsx";

export function LexicalPlainText({
  layerId,
  contentEditableStyle,
  text,
}: {
  layerId: LayerId;
  contentEditableStyle: React.CSSProperties;
  text: string;
}) {
  const isResizing = useAppSelector(selectIsResizing);

  return (
    <LexicalComposer
      initialConfig={{
        namespace: layerId,
        editable: false,
        // eslint-disable-next-line no-console
        onError: (error) => console.log(error),
        theme: {
          paragraph: "paragraph",
          text: {
            base: "lexical-plain-text",
          },
        },
      }}
    >
      <PlainTextPlugin
        contentEditable={
          <ContentEditable
            style={contentEditableStyle}
            // Workaround to prevent text from being highlighted when resizing
            className={clsx([
              `"lexical-content-editable outline-0`,
              isResizing && "selection:bg-none",
            ])}
          />
        }
        placeholder={<div />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <SyncContentEditableAttributes />
      <HistoryPlugin />
      <DoubleClickToEditPlugin layerId={layerId} />
      <LexicalPasteAsPlainTextPlugin />
      <LexicalSyncPlainTextPlugin text={text} />
      <LexicalUpdatePlainTextPlugin layerId={layerId} />
      <LexicalHotkeysPlugin />
    </LexicalComposer>
  );
}
