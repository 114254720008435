import * as RadixToggle from "@radix-ui/react-toggle";
import clsx from "clsx";

type Props = {
  pressed: boolean;
  icon: React.ElementType;
} & Omit<React.ComponentProps<typeof RadixToggle.Root>, "key">;

export function Toggle({ icon, onPressedChange, pressed, ...props }: Props) {
  const Icon = icon;

  return (
    <RadixToggle.Root
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pressed={pressed}
      onPressedChange={onPressedChange}
      className={clsx([
        "flex h-8 w-8 items-center justify-center rounded-sm border border-solid border-gray-6 bg-gray-2 fill-gray-11 p-1 active:bg-gray-5 active:fill-gray-12 data-[state=on]:border-gray-7 data-[state=on]:bg-gray-5 data-[state=on]:fill-gray-12",
        props.className,
      ])}
    >
      <Icon />
    </RadixToggle.Root>
  );
}
