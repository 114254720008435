import { setLayerProperties } from "@core/features/editor/editorSlice";
import { SYNC_EDITOR_TAG } from "@core/features/text/plugins/LexicalSyncPlainTextPlugin";
import {
  useAppDispatch,
  useAppSelector,
  useDebounceHistory,
} from "@core/hooks";
import { LayerId } from "@folds/shared/types";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, BLUR_COMMAND, COMMAND_PRIORITY_HIGH } from "lexical";
import { useEffect } from "react";

export function LexicalUpdatePlainTextPlugin({
  layerId,
}: {
  layerId: LayerId;
}) {
  const dispatch = useAppDispatch();

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const debounceHistory = useDebounceHistory();

  const [editor] = useLexicalComposerContext();

  const debouncedHistoryUpdateText = debounceHistory((text: string) => {
    dispatch(setLayerProperties({ properties: { text }, layerIds: [layerId] }));
  });

  useEffect(() => {
    const cleanupUpdateListener = editor.registerUpdateListener(
      ({ editorState, tags }) => {
        // Ignore updates from the sync plugin
        const containsSyncEditorTag =
          Array.from(tags).includes(SYNC_EDITOR_TAG);
        if (containsSyncEditorTag === true) return;

        editorState.read(() => {
          const text = $getRoot().getTextContent();

          debouncedHistoryUpdateText(text);
        });
      }
    );

    const cleanupBlurListener = editor.registerCommand(
      BLUR_COMMAND,
      () => {
        const editorState = editor.getEditorState();

        editorState.read(() => {
          const text = $getRoot().getTextContent();

          debouncedHistoryUpdateText(text);
        });

        editor.setEditable(false);

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );

    return () => {
      cleanupBlurListener();
      cleanupUpdateListener();
    };
  }, [debouncedHistoryUpdateText, dispatch, editor, layerId, selectedLayerIds]);

  return null;
}
