import {
  upsertColorVariable,
  upsertTextVariable,
  upsertImageVariable,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { computedVariableTypeSchema } from "@folds/shared/types";
import * as Dialog from "@radix-ui/react-dialog";
import { forwardRef, useState } from "react";
import { toast } from "@core/toast";
import { RgbaStringColorPicker } from "react-colorful";
import { colord } from "colord";
import { Input } from "@/features/sidebar/components/Input";
import clsx from "clsx";
import { nanoid } from "@core/lib";
import type { InitialDialogContent } from "@/features/sidebar/components/VariablesSettings/VariablesSettings";

function CloseSign() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const checkIfDataIsValid = ({
  defaultValue,
  name,
  type,
}: {
  defaultValue: string;
  name: string;
  type: "color" | "image" | "text";
}) => {
  if (name.length === 0) return false;

  switch (type) {
    case "color": {
      const isValid = colord(defaultValue).isValid();

      return isValid;
    }
    case "image": {
      return true;
    }
    case "text": {
      return true;
    }
  }
};

function ColorInformation({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (value: string) => void;
}) {
  const alphaValue = colord(value).alpha();
  const alphaPercentage = String(Math.round(alphaValue * 100));
  const hexString = colord(value).alpha(1).toHex().toUpperCase();

  const handleColorChange = (color: string) => {
    const isValid = colord(color).isValid();
    if (!isValid) return;

    const rgbString = colord(color).toRgbString();

    onValueChange(rgbString);
  };

  const handleChangeAlpha = (updatedAlphaPercentage: string) => {
    const updatedAlphaNumberPercentage = Number(updatedAlphaPercentage);
    if (Number.isNaN(updatedAlphaPercentage)) return;

    const updatedAlphaValue = updatedAlphaNumberPercentage / 100;

    const newColor = colord(value).alpha(updatedAlphaValue).toRgbString();

    const isNewColorValid = colord(newColor).isValid();

    if (isNewColorValid === false) return;

    onValueChange(newColor);
  };

  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm text-gray-11" htmlFor="name-input">
        Default value
      </label>
      <div className="flex flex-col gap-2">
        <RgbaStringColorPicker
          color={value}
          className="full-width-color-picker"
          onChange={handleColorChange}
        />
        <div className="flex gap-1">
          <Input
            className="h-10 flex-1 rounded border border-gray-5 bg-gray-2 p-2 text-sm"
            value={hexString}
            unit={null}
            minValue={null}
            inputType="color"
            fill
            onValueChange={handleColorChange}
          />
          <Input
            minValue={0}
            displayUnit
            inputType="number"
            onValueChange={handleChangeAlpha}
            unit="%"
            className="h-10 w-24 rounded border border-gray-5 bg-gray-2 p-2 text-center text-sm"
            value={alphaPercentage}
          />
        </div>
      </div>
    </div>
  );
}

function TextInformation({
  onValueChange,
  value,
}: {
  value: string;
  onValueChange: (value: string) => void;
}) {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm text-gray-11">Default value</label>
      <input
        placeholder="Default value"
        type="text"
        className="h-10 rounded border border-gray-5 bg-gray-2 p-2 px-3 text-sm"
        value={value}
        onChange={(event) => onValueChange(event.target.value)}
      />
    </div>
  );
}

type Props = {
  onDialogClose: () => void;
  initialDialogContent: InitialDialogContent;
};

export const UpsertVariableDialogContent = forwardRef<HTMLDivElement, Props>(
  ({ onDialogClose, initialDialogContent }, forwardedRef) => {
    const dispatch = useAppDispatch();

    const [name, setName] = useState(initialDialogContent.name);
    const [type, setType] = useState<"color" | "image" | "text">(
      initialDialogContent.type
    );
    const [defaultValue, setDefaultValue] = useState<string>(
      initialDialogContent.defaultValue
    );

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };

    const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
      try {
        const value = computedVariableTypeSchema.Decode(event.target.value);

        switch (value) {
          case "color": {
            setDefaultValue("rgba(0, 0, 0, 1)");
            break;
          }
          case "image": {
            setDefaultValue("");
            break;
          }
          case "text": {
            setDefaultValue("");
          }
        }

        setType(value);
      } catch (error) {
        toast.error("There was an issue with the type selection");
      }
    };

    const handleConfirm = () => {
      const isValid = checkIfDataIsValid({ defaultValue, name, type });
      if (!isValid) return;

      const variableId = initialDialogContent.variableId ?? nanoid();

      switch (type) {
        case "color": {
          dispatch(
            upsertColorVariable({
              name,
              defaultColor: defaultValue,
              id: variableId,
            })
          );
          break;
        }
        case "text": {
          dispatch(
            upsertTextVariable({
              name,
              defaultText: defaultValue,
              id: variableId,
            })
          );
          break;
        }
        case "image": {
          dispatch(upsertImageVariable({ name, id: variableId }));
          break;
        }
      }

      onDialogClose();
    };

    const isInputValid = checkIfDataIsValid({ defaultValue, name, type });

    return (
      <Dialog.Content
        ref={forwardedRef}
        className="fixed left-1/2 top-1/2 z-[100] flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-6 rounded-xl border border-gray-5 bg-white px-8 py-6"
      >
        <div className="flex justify-between">
          <div className="text-xl font-medium">
            {typeof initialDialogContent.variableId === "string"
              ? "Edit variable"
              : "Add variable"}
          </div>
          <Dialog.Close>
            <CloseSign />
          </Dialog.Close>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-11" htmlFor="name-input">
              Name
            </label>
            <input
              value={name}
              placeholder="Variable name"
              onChange={handleChangeName}
              type="text"
              autoComplete="off"
              id="name-input"
              className="h-10 rounded-md border border-gray-5 bg-gray-2 px-3 text-sm"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-11">Type</label>
            <select
              className="h-10 rounded-md border-r-4 border-transparent bg-gray-2 px-3 py-2 text-sm outline outline-1 -outline-offset-1 outline-gray-5"
              onChange={handleChangeType}
              value={type}
            >
              <option value="color">Color</option>
              <option value="image">Image</option>
              <option value="text">Text</option>
            </select>
          </div>
          {type === "color" && (
            <ColorInformation
              value={defaultValue}
              onValueChange={setDefaultValue}
            />
          )}
          {type === "text" && (
            <TextInformation
              value={defaultValue}
              onValueChange={setDefaultValue}
            />
          )}
          <div className="flex justify-between gap-2">
            <Dialog.Close
              type="button"
              className="h-10 flex-1  rounded-lg border border-gray-5 bg-gray-2 py-2 text-center text-sm"
            >
              Cancel
            </Dialog.Close>
            <button
              type="button"
              className={clsx([
                "h-10 flex-1 rounded-lg border border-gray-5 bg-black text-center text-sm text-white",
                !isInputValid && "cursor-default opacity-50",
              ])}
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog.Content>
    );
  }
);

UpsertVariableDialogContent.displayName = "VariableDialogContent";
