import { KeysOfUnion, Layer } from "@folds/shared";

export type LayerBreakpointKeys = Exclude<
  KeysOfUnion<Layer>,
  | "tablet"
  | "mobile"
  | "children"
  | "id"
  | "parentId"
  | "liquid"
  | "productId"
  | "optionName"
  | "state"
  | "productHandle"
  | "productUnavailableHandle"
  | "productUnavailableId"
  | "svg"
  | "text"
  | "variableId"
  | "type"
  | "variables"
  | "tag"
  | "action"
>;

export const getBreakpointPropertyName = (key: LayerBreakpointKeys) => {
  switch (key) {
    case "backgroundColor": {
      return "fill";
    }
    case "borderColor": {
      return "border color";
    }
    case "backgroundImage": {
      return "fill";
    }
    case "backgroundImageOverlay": {
      return "fill";
    }
    case "backgroundPositionX": {
      return "background position";
    }
    case "backgroundPositionY": {
      return "background position";
    }
    case "backgroundSize": {
      return "fill";
    }
    case "borderBottomLeftRadius": {
      return "rounded corners";
    }
    case "borderBottomRightRadius": {
      return "rounded corners";
    }
    case "borderTopLeftRadius": {
      return "rounded corners";
    }
    case "borderTopRightRadius": {
      return "rounded corners";
    }
    case "borderBottomWidth": {
      return "border width";
    }
    case "borderLeftWidth": {
      return "border width";
    }
    case "borderRightWidth": {
      return "border width";
    }
    case "borderStyle": {
      return "border style";
    }
    case "borderTopWidth": {
      return "border width";
    }
    case "color": {
      return "color";
    }
    case "content": {
      return "content";
    }
    case "fillType": {
      return null;
    }
    case "fill": {
      return "fill";
    }
    case "flexDirection": {
      return "direction";
    }
    case "fontSize": {
      return "font size";
    }
    case "fontFamily": {
      return "font family";
    }
    case "fontWeight": {
      return "font weight";
    }
    case "gap": {
      return "gap";
    }
    case "hasEditedDesktopPosition": {
      return null;
    }
    case "hasEditedMobilePosition": {
      return null;
    }
    case "hasEditedTabletPosition": {
      return null;
    }
    case "height": {
      return "height";
    }

    case "left": {
      return null;
    }
    case "top": {
      return null;
    }
    case "width": {
      return "width";
    }
    case "visible": {
      return null;
    }
    case "iconColor": {
      return "icon color";
    }
    case "iconSize": {
      return "icon size";
    }
    case "italic": {
      return "italic";
    }
    case "layoutGuideColor": {
      return null;
    }
    case "layoutGuideColumnGap": {
      return null;
    }
    case "checked": {
      return null;
    }
    case "checkedChildren": {
      return null;
    }
    case "hover": {
      return null;
    }
    case "hoverChildren": {
      return null;
    }
    case "layoutGuideColumns": {
      return null;
    }
    case "layoutGuideEnabled": {
      return "layout guide enabled";
    }
    case "layoutGuideMarginBottom": {
      return null;
    }
    case "layoutGuideMarginLeft": {
      return null;
    }
    case "layoutGuideMarginRight": {
      return null;
    }
    case "layoutGuideMarginTop": {
      return null;
    }
    case "letterSpacing": {
      return "letter spacing";
    }
    case "lineHeight": {
      return "line height";
    }
    case "lineThrough": {
      return "line through";
    }
    case "textAlign": {
      return "text align";
    }
    case "textTransform": {
      return "text transform";
    }
    case "navigationColor": {
      return "navigation color";
    }
    case "navigationEnabled": {
      return "navigation enabled";
    }
    case "navigationSize": {
      return "navigation size";
    }
    case "underline": {
      return "underline";
    }
    case "outlineColor": {
      return "outline color";
    }
    case "optionUnavailable": {
      return null;
    }
    case "loop": {
      return "loop";
    }
    case "optionUnavailableChildren": {
      return null;
    }
    case "outlineOffset": {
      return "outline offset";
    }
    case "outlineWidth": {
      return "outline width";
    }
    case "paginationActiveBulletColor": {
      return "pagination active bullet color";
    }
    case "paginationEnabled": {
      return "pagination enabled";
    }
    case "paginationInactiveBulletColor": {
      return "pagination inactive bullet color";
    }
    case "productUnavailable": {
      return null;
    }
    case "scrollbarEnabled": {
      return "scrollbar enabled";
    }
    case "scrollbarThumbColor": {
      return "scrollbar thumb color";
    }
    case "scrollbarTrackColor": {
      return "scrollbar track color";
    }
    case "zIndex": {
      return "stacking order";
    }
  }

  return null;
};

export type BreakpointPropertyName = ReturnType<
  typeof getBreakpointPropertyName
>;
