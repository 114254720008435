import { useParseColorPickerRgbValue } from "@/features/sidebar/components/ColorPicker";
import { SelectImage } from "@/features/sidebar/components/ImagePicker";
import { AutoCompleteValue, Input } from "@/features/sidebar/components/Input";
import {
  MultipleOptions,
  MultipleOptionsProps,
} from "@/features/sidebar/components/MultipleOptions";
import { selectAutoCompleteFillValues } from "@core/features/editor/editorSlice";
import { useAppSelector, useDebounceHistory } from "@core/hooks";
import { FillType, compiledFillTypeSchema } from "@folds/shared/types";
import { PopoverContent, PopoverPortal, PopoverRoot } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { colord } from "colord";
import { FillPopoverTrigger } from "@/features/sidebar/components/FillSettings/FillPopoverTrigger";
import { RgbaStringColorPicker } from "react-colorful";
import { toast } from "@core/toast";
import { useParseInputValue } from "@/features/sidebar/components/FillSettings/useParseInputValue";
import { ExistingColorsSwatches } from "@/features/sidebar/components/ExistingColorsSwatches";

const fillTypeOptions: MultipleOptionsProps["options"] = [
  {
    icon: <p className="text-xs">Color</p>,
    value: "color",
  },
  {
    icon: <p className="text-xs">Image</p>,
    value: "image",
  },
];

export function FillPicker({
  onColorChange,
  onBackgroundImageUrlChange,
  color,
  fillType,
  onChangeFillType,
  ariaLabel,
  backgroundImage,
}: {
  onColorChange: (color: string) => void;
  onBackgroundImageUrlChange: (url: string) => void;
  color: string;
  fillType: FillType;
  onChangeFillType: (fillType: FillType) => void;
  backgroundImage: string | null;
  ariaLabel?: string;
}) {
  const parseRgbValue = useParseColorPickerRgbValue();
  const parseInputValue = useParseInputValue();
  const debounceHistory = useDebounceHistory();
  const fillAutoCompleteValues = useAppSelector(selectAutoCompleteFillValues);

  const handleChangeAlpha = (updatedAlphaPercentage: string) => {
    if (fillType === "image") return;

    const alphaPercentage = Number(updatedAlphaPercentage);
    if (Number.isNaN(alphaPercentage)) return;

    const updatedAlphaValue = alphaPercentage / 100;

    const newColor = colord(color).alpha(updatedAlphaValue).toRgbString();

    const isNewColorValid = colord(newColor).isValid();

    if (isNewColorValid === false) return;

    onColorChange(newColor);
  };

  const handleChangeColor = (updatedColor: string) => {
    if (fillType === "image") return;

    const isValidColor = colord(updatedColor).isValid();
    if (isValidColor === false) return;

    const newRGBColor = colord(updatedColor).toRgbString();

    onColorChange(newRGBColor);
  };

  const handleChangeHexColor = (updatedColor: string) => {
    if (fillType === "image") return;

    const isValidColor = colord(updatedColor).isValid();
    if (isValidColor === false) return;

    const originalColorAlpha = colord(color).alpha();

    const newColor = colord(updatedColor)
      .alpha(originalColorAlpha)
      .toRgbString();

    onColorChange(newColor);
  };

  const handleRGBColorPickerChange = debounceHistory((updatedColor: string) => {
    onColorChange(updatedColor);
  });

  const handleFillTypeChange = (value: string) => {
    try {
      const fill = compiledFillTypeSchema.Decode(value);

      onChangeFillType(fill);
    } catch (error) {
      toast.error("There was an issue changing the fill type");
    }
  };

  const handleAutoCompleteSelect = (data: AutoCompleteValue) => {
    if (data.type === "image") {
      onBackgroundImageUrlChange(data.value);
      return;
    }

    onColorChange(data.value);
  };

  const alphaValue = colord(color).alpha();
  const alphaPercentage = String(Math.round(alphaValue * 100));

  const rgbString = parseRgbValue(color);
  const inputValue = parseInputValue({
    color,
    fillType,
    backgroundImage,
  });

  return (
    <PopoverRoot>
      <Popover.Anchor className="flex gap-1">
        <Input
          onValueChange={handleChangeHexColor}
          className="flex-1 !pl-10"
          value={inputValue}
          minValue={null}
          inputType="color"
          unit={null}
          aria-label={ariaLabel}
          onSelectAutoCompleteValue={handleAutoCompleteSelect}
          autoCompleteValues={fillAutoCompleteValues}
          fill={fillType === "image"}
        >
          <FillPopoverTrigger
            fillType={fillType}
            color={color}
            backgroundImage={backgroundImage}
          />
        </Input>
        {fillType === "color" && (
          <Input
            minValue={0}
            className="!w-16 !px-2 text-center"
            unit="%"
            displayUnit
            onValueChange={handleChangeAlpha}
            value={alphaPercentage}
          />
        )}
      </Popover.Anchor>
      <PopoverPortal>
        <PopoverContent align="center" side="right" sideOffset={16}>
          <div className="flex flex-col gap-2 rounded-md border border-solid border-gray-6 bg-gray-1 p-2">
            <MultipleOptions
              options={fillTypeOptions}
              value={fillType}
              onValueChange={handleFillTypeChange}
            />
            {fillType === "image" && (
              <SelectImage
                onBackgroundImageUrlChange={onBackgroundImageUrlChange}
              />
            )}
            {fillType === "color" && (
              <>
                <RgbaStringColorPicker
                  color={rgbString}
                  onChange={handleRGBColorPickerChange}
                />
                <div className="flex gap-1">
                  <Input
                    minValue={null}
                    onValueChange={handleChangeColor}
                    unit={null}
                    inputType="color"
                    value={inputValue}
                    className="!pl-2"
                    fill
                  />
                  <Input
                    minValue={0}
                    unit="%"
                    displayUnit
                    inputType="number"
                    className="!w-16 !px-2 text-center"
                    value={alphaPercentage}
                    onValueChange={handleChangeAlpha}
                  />
                </div>
                <ExistingColorsSwatches onSelect={handleChangeColor} />
              </>
            )}
          </div>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
}
