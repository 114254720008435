import React, { useEffect, useRef, useState } from "react";
import { usePageInformation } from "@/features/editor/api/getPageInformation";
import { useRenamePage } from "@/features/sidebar/api/renamePage";

export function PageName() {
  const { data: pageInformation, isLoading, error } = usePageInformation();

  const renamePage = useRenamePage();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [updatedPageName, setUpdatedPageName] = useState<string>("null");

  // Select the input text once it's mounted
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  if (isLoading) {
    return <div className="w-[12ch] animate-pulse bg-gray-3 text-sm" />;
  }

  if (pageInformation === undefined || error !== undefined) {
    return null;
  }

  const handleClick = () => {
    setIsEditing(true);
    setUpdatedPageName(pageInformation.name);
  };

  const handleBlur = () => {
    if (updatedPageName === pageInformation.name) {
      setIsEditing(false);
      return;
    }

    renamePage(updatedPageName)
      .then(() => {
        setIsEditing(false);
      })
      .catch(() => {});
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    switch (event.key) {
      case "Enter": {
        renamePage(updatedPageName)
          .then(() => {
            setIsEditing(false);
          })
          .catch(() => {});

        break;
      }
      case "Escape": {
        setUpdatedPageName(pageInformation.name);
      }
    }
  };

  if (isEditing) {
    return (
      <input
        ref={inputRef}
        type="text"
        value={updatedPageName}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={(event) => setUpdatedPageName(event.target.value)}
        className="m-0 w-full truncate bg-transparent p-0 text-sm text-gray-12"
      />
    );
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="m-0 w-full cursor-pointer truncate p-0 text-left text-sm text-gray-12"
    >
      {pageInformation.name}
    </button>
  );
}
