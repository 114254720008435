import { INDICATOR_SIZE } from "@/features/editor/components/AltHoverDistanceIndicator/constants";
import { roundDistance } from "@/features/editor/components/AltHoverDistanceIndicator/utils/roundDistance";
import { useAppSelector } from "@core/hooks";
import { getBoundingBoxRect } from "@core/utils";
import { Box, LayerId } from "@folds/shared";
import React, { useEffect, useState } from "react";

const useRightDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value =
      targetLayerBoundingBox.left -
      (selectionBoundingBox.left + selectionBoundingBox.width);

    setDistance(roundDistance(value));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

function DashedRightTopIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const selectionBoundingBoxMiddle =
    selectionBoundingBox.top + selectionBoundingBox.height / 2;

  const targetLayerBoundingBoxBottom =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height;

  const style: React.CSSProperties = {
    top: targetLayerBoundingBoxBottom,
    left: targetLayerBoundingBox.left,
    height: selectionBoundingBoxMiddle - targetLayerBoundingBoxBottom,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

function DashedRightBottomIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const selectionBoundingBoxMiddle =
    selectionBoundingBox.top + selectionBoundingBox.height / 2;

  const style: React.CSSProperties = {
    top:
      selectionBoundingBox.top +
      selectionBoundingBox.height / 2 +
      INDICATOR_SIZE / 2,
    left: targetLayerBoundingBox.left,
    height: targetLayerBoundingBox.top - selectionBoundingBoxMiddle,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

export function RightIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useRightDistance(targetLayerId);

  const top =
    selectionBoundingBox.top +
    selectionBoundingBox.height / 2 +
    INDICATOR_SIZE / 2;

  const left = selectionBoundingBox.left + selectionBoundingBox.width;

  const width = targetLayerBoundingBox.left - left + INDICATOR_SIZE;

  const indicatorStyle: React.CSSProperties = {
    top,
    left,
    width,
    height: INDICATOR_SIZE,
  };

  const selectionMiddlePosition =
    selectionBoundingBox.top + selectionBoundingBox.height / 2;

  const isSelectionMiddlePositionBelowTheTargetLayer =
    selectionMiddlePosition >
    targetLayerBoundingBox.top + targetLayerBoundingBox.height;

  const isSelectionMiddlePositionAboveTheTargetLayer =
    selectionMiddlePosition < targetLayerBoundingBox.top;

  return (
    <>
      {isSelectionMiddlePositionBelowTheTargetLayer && (
        <DashedRightTopIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      {isSelectionMiddlePositionAboveTheTargetLayer && (
        <DashedRightBottomIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
        <p
          aria-label="right distance"
          className="absolute left-1/2 top-1 z-50 -translate-x-1/2 rounded bg-[red] px-1 text-sm text-white"
        >
          {distance}
        </p>
      </div>
    </>
  );
}
