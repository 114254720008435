import {
  GroupLayer,
  Layer,
  LayerType,
  ProductImageCarouselRootLayer,
} from "@folds/shared";

export type IntrinsicallyPositionedLayer =
  | ProductImageCarouselRootLayer
  | GroupLayer;

export const checkIfLayerIsIntrinsicallyPositioned = (
  layer: Layer
): layer is IntrinsicallyPositionedLayer =>
  layer.type === LayerType.ProductImageCarousel.Root ||
  layer.type === LayerType.Group;
