import { useDrop } from "@/features/editor/dragging/drop";
import { useEventConnector } from "@core/events";
import { generateButtonStyles } from "@folds/shared/layers";
import { ButtonLayer } from "@folds/shared/types";

export function Button({
  layer,
  children,
}: {
  layer: ButtonLayer;
  children: React.ReactNode;
}) {
  const {
    handleMouseDown,
    handleContextMenu,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseOver,
  } = useEventConnector(layer.id);

  const handleDrop = useDrop(layer.id);

  const styles = generateButtonStyles(layer);

  return (
    <button
      type="button"
      style={{ ...styles, cursor: "default" }}
      onMouseDown={handleMouseDown}
      id={layer.id}
      aria-label="Button layer"
      onDrop={handleDrop}
      onContextMenu={handleContextMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      {children}
    </button>
  );
}
