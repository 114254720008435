import { pauseHistory, resumeHistory } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import debounce from "lodash.debounce";
import { useCallback, useRef } from "react";

/**
 * Debounces history changes to prevent history from being spammed
 * Useful for frequently changed properties
 */
export function useDebounceHistory() {
  const dispatch = useAppDispatch();

  const isInitialized = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetHistory = useCallback(
    debounce(() => {
      dispatch(resumeHistory());
      isInitialized.current = false;
    }, 350),
    []
  );

  return <T>(cb: (props: T) => void) =>
    (props: T) => {
      if (!isInitialized.current) {
        dispatch(pauseHistory());
        isInitialized.current = true;
      }

      cb(props);
      debouncedSetHistory();
    };
}
