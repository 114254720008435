import {
  Block,
  Breakpoint,
  Button,
  Page,
  ProductImageCarouselFeaturedImage,
  ProductImageCarouselRoot,
  ProductImageCarouselThumbnail,
  ProductImageCarouselThumbnails,
  Rectangle,
  Text,
  AccordionChevron,
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
  OptionSelectorItem,
  OptionSelectorRoot,
  CarouselRoot,
  CarouselSlide,
  Icon,
  Group,
} from "@core/user-components";

import { getOverridenLayer } from "@folds/shared/layers";
import {
  Breakpoint as BreakpointType,
  Layer,
  LayerId,
  LayerType,
} from "@folds/shared/types";
import { store } from "@store";
import isEqual from "lodash.isequal";
import { QuantityPicker } from "@core/user-components/QuantityPicker";
import { OptionDropdown } from "@core/user-components/OptionDropdown";
import { Liquid } from "@core/user-components/Liquid";

import React, { PropsWithChildren, memo, useEffect, useState } from "react";
import { BlockActions } from "./blocks";

export function RenderLayer({
  layer,
  children,
}: PropsWithChildren<{ layer: Layer }>) {
  switch (layer.type) {
    case LayerType.Block: {
      return <Block layer={layer}>{children}</Block>;
    }
    case LayerType.Page: {
      return <Page>{children}</Page>;
    }
    case LayerType.Rectangle: {
      return <Rectangle layer={layer} />;
    }
    case LayerType.Breakpoint: {
      return (
        <>
          <BlockActions breakpointLayer={layer} />
          <Breakpoint layerId={layer.id}>{children}</Breakpoint>
        </>
      );
    }
    case LayerType.Text: {
      return <Text layer={layer} />;
    }

    case LayerType.Button: {
      return <Button layer={layer}>{children}</Button>;
    }
    // Product image carousel
    case LayerType.ProductImageCarousel.Root: {
      return (
        <ProductImageCarouselRoot layer={layer}>
          {children}
        </ProductImageCarouselRoot>
      );
    }
    case LayerType.ProductImageCarousel.Thumbnails: {
      return (
        <ProductImageCarouselThumbnails layer={layer}>
          {children}
        </ProductImageCarouselThumbnails>
      );
    }
    case LayerType.ProductImageCarousel.Thumbnail: {
      return <ProductImageCarouselThumbnail layer={layer} />;
    }
    case LayerType.ProductImageCarousel.FeaturedImage: {
      return <ProductImageCarouselFeaturedImage layer={layer} />;
    }
    /*
      Accordion
    */
    case LayerType.Accordion.Root: {
      return <AccordionRoot layer={layer}>{children}</AccordionRoot>;
    }
    case LayerType.Accordion.Item: {
      return <AccordionItem id={layer.id}>{children}</AccordionItem>;
    }
    case LayerType.Accordion.Trigger: {
      return <AccordionTrigger layer={layer}>{children}</AccordionTrigger>;
    }
    case LayerType.Accordion.Chevron: {
      return <AccordionChevron layer={layer} />;
    }
    case LayerType.Accordion.Content: {
      return <AccordionContent layer={layer}>{children}</AccordionContent>;
    }
    case LayerType.OptionSelector.Root: {
      return <OptionSelectorRoot layer={layer}>{children}</OptionSelectorRoot>;
    }
    case LayerType.OptionSelector.Item: {
      return <OptionSelectorItem layer={layer}>{children}</OptionSelectorItem>;
    }

    case LayerType.Carousel.Root: {
      return <CarouselRoot layer={layer}>{children}</CarouselRoot>;
    }
    case LayerType.Carousel.Slide: {
      return <CarouselSlide layer={layer}>{children}</CarouselSlide>;
    }
    case LayerType.QuantityPicker: {
      return <QuantityPicker layer={layer} />;
    }
    case LayerType.OptionDropdown: {
      return <OptionDropdown layer={layer} />;
    }
    case LayerType.Icon: {
      return <Icon layer={layer} />;
    }
    case LayerType.Group: {
      return <Group layer={layer}>{children}</Group>;
    }
    case LayerType.Liquid: {
      return <Liquid layer={layer} />;
    }
  }
}

export const isCanvasElement = (layer: Layer) => "children" in layer;

type Props = {
  id: string;
  breakpoint: BreakpointType;
};

export const RenderLayers = memo(({ id, breakpoint }: Props) => {
  const {
    editor: { layers },
  } = store.getState();

  const initialLayer = getOverridenLayer({
    layers,
    id,
    breakpoint,
  });

  const [layer, setLayer] = useState<Layer | null>(initialLayer);

  useEffect(() => {
    const updateLayerIfChanged = () => {
      const {
        editor: { layers: newLayers, breakpoint: newBreakpoint },
      } = store.getState();

      if (!newLayers[id]) return;

      const newLayer = getOverridenLayer({
        layers: newLayers,
        id,
        breakpoint: newBreakpoint,
      });

      if (newLayer !== null && !isEqual(layer, newLayer)) {
        setLayer(newLayer);
      }
    };

    const unsubscribe = store.subscribe(updateLayerIfChanged);

    return () => {
      unsubscribe();
    };
  }, [breakpoint, id, layer, layers]);

  if (!layer) return null;

  const getLayerComponent = (layerId: LayerId, children: React.ReactNode) => {
    const layerComponent = getOverridenLayer({
      breakpoint,
      id: layerId,
      layers,
    });

    if (layerComponent === null) return null;

    return <RenderLayer layer={layerComponent}>{children}</RenderLayer>;
  };

  if ("children" in layer) {
    const descendants = layer.children.map((layerId) => (
      <RenderLayers key={layerId} breakpoint={breakpoint} id={layerId} />
    ));

    const LayerComponent = getLayerComponent(id, descendants);

    return LayerComponent;
  }

  const LayerComponent = getLayerComponent(id, null);

  return LayerComponent;
});

RenderLayers.displayName = "RenderLayer";
