import { rearrangeLayerAction } from "@core/features/editor/editorSlice/actions/rearrangeLayer";
import { getLayerType, getParentLayerId } from "@core/utils";
import { Layer, LayerId, LayerType } from "@folds/shared/types";

import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { calculateGuideStopLines } from "@core/features/editor/editorSlice/actions/utils/calculateGuideStopLines";

const moveLayers = (
  state: DraftEditorState,
  layerMovedOutOfParentType: Layer["type"],
  layerMovedOutOfParentId: LayerId
) => {
  if (!state.dragging) return;

  state.dragging.draggingLayerIds.forEach((draggingLayerId) => {
    if (layerMovedOutOfParentType === LayerType.Breakpoint) {
      const grandParentId = getParentLayerId(
        state.layers,
        layerMovedOutOfParentId
      );

      if (grandParentId === null) return;

      rearrangeLayerAction(state, {
        originalLayerId: draggingLayerId,
        newParentId: grandParentId,
        newIndex: 0,
      });

      return;
    }

    // Move dragged layer into parent
    rearrangeLayerAction(state, {
      originalLayerId: draggingLayerId,
      newParentId: layerMovedOutOfParentId,
      newIndex: 0,
    });
  });
};

const clearTabletAndMobilePositionOverrides = (state: DraftEditorState) => {
  state.selectedLayerIds.forEach((layerId) => {
    const layer = state.layers[layerId];

    if (!layer || !("top" in layer) || !("left" in layer)) return;

    if (layer.tablet.top !== undefined) {
      delete layer.tablet.top;
    }

    if (layer.tablet.left !== undefined) {
      delete layer.tablet.left;
    }

    if (layer.mobile.top !== undefined) {
      delete layer.mobile.top;
    }

    if (layer.mobile.left !== undefined) {
      delete layer.mobile.left;
    }
  });
};

export const mouseLeaveAction = (
  state: DraftEditorState,
  { layerMovedOutOfId }: { layerMovedOutOfId: LayerId }
) => {
  const { layers, dragging } = state;

  if (dragging === null) return;

  const layerMovedOutOfParentId = getParentLayerId(layers, layerMovedOutOfId);

  if (layerMovedOutOfParentId === null) return;

  const layerMovedOutOfParentType = getLayerType(
    layers,
    layerMovedOutOfParentId
  );

  if (layerMovedOutOfParentType === null) return;

  dragging.draggedIntoLayerId = null;

  moveLayers(state, layerMovedOutOfParentType, layerMovedOutOfParentId);

  // Only use position overrides for layers inside a block
  if (layerMovedOutOfParentType === LayerType.Page) {
    clearTabletAndMobilePositionOverrides(state);
  }

  // Re-calculate guide stop lines
  const guideStopLines = calculateGuideStopLines({
    layers,
    ids: dragging.draggingLayerIds,
    breakpoint: state.breakpoint,
  });

  dragging.guideStopLines = guideStopLines;
};
