import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { getBoundingBoxRect, getParentLayerId } from "@core/utils";
import { getOverridenLayer } from "@folds/shared";
import {
  BlockLayer,
  Box,
  Breakpoint,
  GuideStopLines,
  Layer,
  LayerId,
  LayerType,
  Layers,
} from "@folds/shared/types";
import { current } from "@reduxjs/toolkit";

const getGuideStopLines = (ids: LayerId[]): GuideStopLines => {
  const vertical: GuideStopLines["vertical"] = [];
  const horizontal: GuideStopLines["horizontal"] = [];

  ids.forEach((id) => {
    const box = getBoundingBoxRect([id]);

    const right = box.left + box.width;
    const bottom = box.top + box.height;

    const { left, width, top, height } = box;

    vertical.push(
      { x: left, layerId: id },
      { x: left + width / 2, layerId: id },
      { x: right, layerId: id }
    );

    horizontal.push(
      { y: top, layerId: id },
      { y: top + height / 2, layerId: id },
      { y: bottom, layerId: id }
    );
  });

  return { horizontal, vertical };
};

const getSiblingIds = ({ id, layers }: { id: LayerId; layers: Layers }) => {
  const layer = layers[id];

  if (!layer) return [];

  const parentId = getParentLayerId(layers, id);

  if (parentId === null) return [];

  const parent = layers[parentId];

  if (!parent || !("children" in parent)) return [];

  const isInstrinsicallyPositioned =
    checkIfLayerIsIntrinsicallyPositioned(layer);

  // Prevents from including it's own children as siblings
  if (isInstrinsicallyPositioned) return parent.children;

  return parent.children.flatMap((childId) => {
    const child = layers[childId];

    if (!child) return [];

    const isChildIntrinsicallyPositioned =
      checkIfLayerIsIntrinsicallyPositioned(child);

    if (isChildIntrinsicallyPositioned) {
      return child.children;
    }

    return childId;
  });
};

const getSiblingGuideStopLines = ({
  layerId,
  layers,
  ids,
}: {
  layerId: LayerId;
  layers: Layers;
  ids: LayerId[];
}) => {
  const siblingIds = getSiblingIds({
    id: layerId,
    layers,
  });

  const siblingIdsWithoutSelected = siblingIds.filter(
    (siblingId) => !ids.some((id) => id === siblingId)
  );

  const guideStopLines = getGuideStopLines(siblingIdsWithoutSelected);

  return guideStopLines;
};

const getParentGuideStopLines = (
  layers: Layers,
  layerId: LayerId
): GuideStopLines => {
  const parentId = getParentLayerId(layers, layerId);

  if (parentId === null) return { horizontal: [], vertical: [] };

  const parentGuideStopLines = getGuideStopLines([parentId]);

  return parentGuideStopLines;
};

const getChildrenGuideStopLines = (layer: Layer): GuideStopLines => {
  if (!("children" in layer)) return { horizontal: [], vertical: [] };

  const childrenGuideStopLines = getGuideStopLines(layer.children);

  return childrenGuideStopLines;
};

const getBlockLayer = ({
  breakpoint,
  layerId,
  layers,
}: {
  layerId: LayerId;
  layers: Layers;
  breakpoint: Breakpoint;
}): BlockLayer | null => {
  const layer = getOverridenLayer({
    layers: current(layers),
    id: layerId,
    breakpoint,
  });
  if (!layer || layer.type === LayerType.Page) return null;

  if (layer.type === LayerType.Block) {
    return layer;
  }

  return getBlockLayer({ breakpoint, layerId: layer.parentId, layers });
};

const getColumnWidth = ({ box, layer }: { box: Box; layer: BlockLayer }) => {
  const rightLayout = box.left + box.width - layer.layoutGuideMarginRight;
  const leftLayout = box.left + layer.layoutGuideMarginLeft;

  const totalLayoutWidth = rightLayout - leftLayout;
  const totoalGapBetweenColumns =
    (layer.layoutGuideColumns - 1) * layer.layoutGuideColumnGap;
  const totalColumnWidth = totalLayoutWidth - totoalGapBetweenColumns;

  const columnWidth = totalColumnWidth / layer.layoutGuideColumns;

  return columnWidth;
};

const getLayoutGuideStopLines = (layer: BlockLayer | null): GuideStopLines => {
  if (layer === null || !layer.layoutGuideEnabled) {
    return {
      horizontal: [],
      vertical: [],
    };
  }

  const box = getBoundingBoxRect([layer.id]);
  const columnWidth = getColumnWidth({ box, layer });

  const vertical: GuideStopLines["vertical"] = [];

  Array.from({ length: layer.layoutGuideColumns }).reduce<number>((acc) => {
    const startOfColumnGuideStopLine: GuideStopLines["vertical"][number] = {
      x: acc,
      layerId: layer.id,
    };

    const endOfColumnGuideStopLine: GuideStopLines["vertical"][number] = {
      x: acc + columnWidth,
      layerId: layer.id,
    };

    vertical.push(startOfColumnGuideStopLine);
    vertical.push(endOfColumnGuideStopLine);

    return acc + columnWidth + layer.layoutGuideColumnGap;
  }, box.left + layer.layoutGuideMarginLeft);

  const horizontal: GuideStopLines["horizontal"] = [
    { y: box.top + layer.layoutGuideMarginTop, layerId: layer.id },
    {
      y: box.top + box.height - layer.layoutGuideMarginBottom,
      layerId: layer.id,
    },
  ];

  return {
    horizontal,
    vertical,
  };
};

export const calculateGuideStopLines = ({
  layers,
  ids,
  breakpoint,
}: {
  layers: Layers;
  ids: LayerId[];
  breakpoint: Breakpoint;
}) => {
  const firstSelectedLayerId = ids[0];

  if (firstSelectedLayerId === undefined)
    return { horizontal: [], vertical: [] };

  const layer = layers[firstSelectedLayerId];

  if (!layer) {
    return { horizontal: [], vertical: [] };
  }

  if (layer.type === LayerType.Block) {
    const childrenGuideStopLines = getChildrenGuideStopLines(layer);

    return {
      horizontal: childrenGuideStopLines.horizontal,
      vertical: childrenGuideStopLines.vertical,
    };
  }

  const parentGuideStopLines = getParentGuideStopLines(
    layers,
    firstSelectedLayerId
  );

  const siblingGuideStopLines = getSiblingGuideStopLines({
    layerId: firstSelectedLayerId,
    layers,
    ids,
  });

  const blockLayer = getBlockLayer({
    layerId: firstSelectedLayerId,
    layers,
    breakpoint,
  });

  const layoutGuideStopLines = getLayoutGuideStopLines(blockLayer);

  const combinedHorizontalGuideStopLines = [
    ...parentGuideStopLines.horizontal,
    ...siblingGuideStopLines.horizontal,
    ...layoutGuideStopLines.horizontal,
  ];

  const combinedVerticalGuideStopLines = [
    ...parentGuideStopLines.vertical,
    ...siblingGuideStopLines.vertical,
    ...layoutGuideStopLines.vertical,
  ];

  return {
    horizontal: combinedHorizontalGuideStopLines,
    vertical: combinedVerticalGuideStopLines,
  };
};
