import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";
import type { EditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getLayerDimensions } from "@core/utils";
import { Layer } from "@folds/shared";
import { Draft } from "@reduxjs/toolkit";

const resetLeftPosition = (layer: Draft<Layer>) => {
  if (!("left" in layer) || !("mobile" in layer)) return;

  const { width } = getLayerDimensions(layer.id);

  if (layer.hasEditedTabletPosition && layer.tablet.left !== undefined) {
    const responsiveLeft = getLayerResponsiveLeftPosition({
      breakpoint: "tablet",
      left: layer.tablet.left,
      width,
    });

    layer.mobile.left = responsiveLeft.mobile;
    layer.hasEditedMobilePosition = false;

    return;
  }

  const responsiveLeft = getLayerResponsiveLeftPosition({
    breakpoint: "desktop",
    left: layer.left,
    width,
  });

  layer.mobile.left = responsiveLeft.mobile;
  layer.tablet.left = responsiveLeft.tablet;
  layer.hasEditedMobilePosition = false;
};

export const deleteMobileOverridesAction = (state: EditorState) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];

    if (!layer || !("mobile" in layer)) return;

    layer.mobile = { visible: true };

    resetLeftPosition(layer);
  });
};
