import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { DialogContent, DialogPortal } from "@lib/radix";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";

export function SmallScreenSizeWarning() {
  const [
    isSmallScreenSizeWarningExpanded,
    setIsSmallScreenSizeWarningExpanded,
  ] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 870) {
      setIsSmallScreenSizeWarningExpanded(true);
    }
  }, []);

  return (
    <Dialog.Root
      onOpenChange={setIsSmallScreenSizeWarningExpanded}
      open={isSmallScreenSizeWarningExpanded}
    >
      <DialogPortal>
        <DialogContent className="max-[404px]:w-[90%]">
          <div className="flex justify-between">
            <Dialog.Title className="text-lg font-medium">
              Small screen size
            </Dialog.Title>
            <Dialog.Close>
              <CloseDialogIcon />
            </Dialog.Close>
          </div>
          <p className="text-base">
            We current do not support small screen sizes. You may experience
            issues with the editor.
          </p>
          <div className="flex justify-start">
            <Dialog.Close
              type="button"
              className="w-full rounded border border-gray-6 bg-gray-12 py-3 text-sm text-white"
            >
              Continue
            </Dialog.Close>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}
