import {
  ProductImageCarouselFeaturedImageLayer,
  ProductImageCarouselThumbnailLayer,
  ProductImageCarouselThumbnailsLayer,
} from '../types';

export const generateProductFeaturedImageCarouselStyles = (
  layer: ProductImageCarouselFeaturedImageLayer
): React.CSSProperties => {
  const { top, left, width, height } = layer;

  return {
    position: 'absolute',
    top,
    left,
    width,
    height,
    display: layer.visible ? 'block' : 'none',
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};

export const generateProductFeaturedImageSlideStyles = (
  layer: ProductImageCarouselFeaturedImageLayer
): React.CSSProperties => {
  const {
    width,
    height,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  } = layer;

  return {
    width,
    height,
    objectFit: 'cover',
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  };
};

export const generateProductImageCarouselThumbnailStyles = (
  layer: ProductImageCarouselThumbnailLayer
): React.CSSProperties => {
  const {
    width,
    height,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
  } = layer;

  return {
    cursor: 'pointer',
    objectFit: 'cover',
    width,
    height,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    display: layer.visible ? 'block' : 'none',
  };
};

export const generateProductImageCarouselThumbnailsStyles = (
  layer: ProductImageCarouselThumbnailsLayer
): React.CSSProperties => ({
  position: 'absolute',
  top: layer.top,
  left: layer.left,
  width: layer.width,
  display: layer.visible ? 'block' : 'none',
  transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
});
