import { useProduct } from "@/features/sidebar/api/getProduct";
import { useEventConnector } from "@core/events";
import {
  generateOptionDropdownSelectStyles,
  generateOptionDropdownWrapperStyles,
} from "@folds/shared/layers";
import { OptionDropdownLayer } from "@folds/shared/types";

export function OptionDropdown({ layer }: { layer: OptionDropdownLayer }) {
  const selectStyles = generateOptionDropdownSelectStyles(layer);
  const wrapperStyles = generateOptionDropdownWrapperStyles(layer);

  const { data: product, isLoading, error } = useProduct(layer.productId);

  const { handleMouseDown, handleMouseOver } = useEventConnector(layer.id);

  // Prevent select from being opened when clicking on the layer
  const handleSelectMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();

    handleMouseDown(event);
  };

  if (isLoading)
    return (
      <div
        style={{ ...wrapperStyles, ...selectStyles }}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      >
        Loading...
      </div>
    );

  if (error !== undefined)
    return (
      <div
        style={{ ...wrapperStyles, ...selectStyles }}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      >
        An error occurred
      </div>
    );

  if (layer.productId === null || layer.optionName === null) {
    return (
      <div
        style={{ ...wrapperStyles, ...selectStyles }}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      >
        No option selected
      </div>
    );
  }

  const option = product?.options.find(
    (currentOption) => currentOption.name === layer.optionName
  );

  if (option === undefined)
    return (
      <div
        style={{ ...wrapperStyles, ...selectStyles }}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      >
        No option found
      </div>
    );

  return (
    <div style={wrapperStyles}>
      <select
        style={selectStyles}
        id={layer.id}
        onMouseDown={handleSelectMouseDown}
        onMouseOver={handleMouseOver}
      >
        {option.values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
