import { Type } from '@sinclair/typebox';

export const LayerType = {
  Rectangle: 'rectangle',
  Text: 'text',
  Page: 'page',
  Header: 'header',
  Breakpoint: 'breakpoint',
  Block: 'block',
  Button: 'button',
  Icon: 'icon',
  Group: 'group',
  QuantityPicker: 'quantity-picker',
  OptionDropdown: 'option-dropdown',
  Liquid: 'liquid',
  Carousel: {
    Root: 'carousel-root',
    Slide: 'carousel-slide',
  },
  OptionSelector: {
    Root: 'option-selector-root',
    Item: 'option-selector-item',
  },
  ProductImageCarousel: {
    Root: 'product-image-carousel-root',
    FeaturedImage: 'product-image-carousel-featured-image',
    Thumbnails: 'product-image-carousel-thumbnails',
    Thumbnail: 'product-image-carousel-thumbnail',
  },

  YotpoReviews: {
    ProductReviews: 'yotpo-product-reviews',
    Rating: 'yotpo-rating',
  },
  Accordion: {
    Root: 'accordion-root',
    Item: 'accordion-item',
    Content: 'accordion-content',
    Trigger: 'accordion-trigger',
    Chevron: 'accordion-chevron',
  },
} as const;

export const ConditionType = {
  Option: 'option',
  Element: 'element',
  Variant: 'variant',
  Variable: 'variable',
} as const;

export const breakpointLayerType = Type.Literal(LayerType.Breakpoint);
export const pageLayerType = Type.Literal(LayerType.Page);

export const rootNodeId = Type.Literal('ROOT');

export const backgroundSizeSchema = Type.Enum({
  cover: 'cover',
  contain: 'contain',
});
