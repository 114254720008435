import { setSelectedLayerProperties } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import { Layer } from "@folds/shared/types";
import { useDebounceHistory } from "./useDebounceHistory";

/**
 * Wrapper over 'useSetHistory' hook to make it more re-usable to set properties
 * @param properties
 */
export const useDebounceSetSelectedLayerProperties = () => {
  const dispatch = useAppDispatch();

  const debounceHistory = useDebounceHistory();

  return debounceHistory((properties: Partial<Layer>) => {
    dispatch(setSelectedLayerProperties(properties));
  });
};
