import { generateFillStyles, parseVariable } from './utils';
import { OptionSelectorItemLayer, OptionSelectorRootLayer } from '../types';

export const generateOptionSelectorRootStyles = (
  layer: OptionSelectorRootLayer
): React.CSSProperties => {
  const { gap, left, top, width, visible, flexDirection, zIndex } = layer;

  return {
    all: 'initial',
    boxSizing: 'border-box',
    position: 'absolute',
    alignItems: 'center',
    userSelect: 'none',
    width: width === null ? 'max-content' : width,
    gap,
    left,
    top,
    flexWrap: 'wrap',
    flexDirection,
    display: visible ? 'flex' : 'none',
    justifyContent: 'flex-start',
    zIndex,
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};

export const generateOptionSelectorItemStyles = (
  layer: OptionSelectorItemLayer
): React.CSSProperties => {
  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    width,
    outlineColor,
    outlineOffset,
    outlineWidth,
  } = layer;

  const fillStyles = generateFillStyles(layer);
  const parsedBorderColor = parseVariable(borderColor);
  const parsedOutlineColor = parseVariable(outlineColor);

  return {
    all: 'initial',
    ...fillStyles,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    position: 'relative',
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor: parsedBorderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    width,
    overflow: 'clip',
    userSelect: 'none',
    outlineStyle: 'solid',
    outlineColor: parsedOutlineColor,
    outlineOffset,
    outlineWidth,
  };
};
