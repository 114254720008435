import { useShopDomain } from "@/features/dashboard/context/ShopContext";
import { usePageId } from "@/features/editor/context/PageId";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";
import { store } from "@core/store";
import { toast } from "@core/toast";
import { useAxios } from "@lib/axios";

export const usePreviewPage = () => {
  const axios = useAxios();
  const pageId = usePageId();
  const shop = useShopDomain();
  const isInsideOfPlayground = useIsInsidePlayground();

  const previewPage = async () => {
    if (isInsideOfPlayground) {
      toast.error("You can't preview a page inside of the playground");
      return;
    }

    try {
      const { layers } = store.getState().editor;

      toast.loading("Creating a preview", { id: "preview-page" });

      await axios.post(`/pages/${pageId}/preview`, layers);

      toast.success("Preview created", { id: "preview-page" });

      const url = `https://${shop}.myshopify.com/apps/folds-preview/previews/${pageId}`;
      window.open(url, "_blank");
    } catch (error) {
      toast.error("There was an issue creating a preview", {
        id: "preview-page",
      });
    }
  };

  return previewPage;
};
