import {
  BorderSettings,
  MainInformationSettings,
} from "@/features/sidebar/components";
import { ActionSettings } from "@/features/sidebar/components/ActionSettings";
import { BackgroundColorSettings } from "@/features/sidebar/components/BackgroundColorSettings";
import { Breadcrumbs } from "@/features/sidebar/components/Breadcrumbs";
import { BreakpointOverrides } from "@/features/sidebar/components/BreakpointOverrides";
import { FillColorSettings } from "@/features/sidebar/components/FillColorSettings";
import { FillSettings } from "@/features/sidebar/components/FillSettings";
import { FlexDirectionSettings } from "@/features/sidebar/components/FlexDirectionSettings";
import { GapSettings } from "@/features/sidebar/components/GapSettings";
import { OptionSelector } from "@/features/sidebar/components/OptionSelector";
import { OutlineSettings } from "@/features/sidebar/components/OutlineSettings";
import { ProductSettings } from "@/features/sidebar/components/ProductSettings";
import { CarouselSettings } from "@/features/sidebar/components/CarouselSettings";
import { StateSelector } from "@/features/sidebar/components/StateSelector";
import { VariablesSettings } from "@/features/sidebar/components/VariablesSettings/VariablesSettings";
import { VisibilitySettings } from "@/features/sidebar/components/VisibilitySettings";
import {
  selectFirstSelectedLayer,
  selectFirstSelectedLayerId,
  selectFirstSelectedLayerType,
} from "@core/features/editor/editorSlice";
import { BasicTypography } from "@core/features/text";
import { useAppSelector } from "@core/hooks";
import { Layer } from "@folds/shared/types";
import { IconSettings } from "@/features/sidebar/components/IconSettings";
import { AlignmentButtons } from "@/features/sidebar/AlignmentButtons";
import { LiquidSettings } from "@/features/sidebar/components/LiquidSettings";
import { LayoutGuideSettings } from "@/features/sidebar/components/LayoutGuideSettings";

function SidebarStylesLayerSpecificContent({ layer }: { layer: Layer }) {
  switch (layer.type) {
    case "rectangle": {
      return (
        <>
          <ActionSettings />
          <FillSettings />
          <BorderSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "text": {
      switch (layer.content) {
        case "custom": {
          return (
            <>
              <BasicTypography includeTextType />
              <ActionSettings />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "product-title": {
          return (
            <>
              <ProductSettings />
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "product-price": {
          return (
            <>
              <ProductSettings />
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "product-compare-at-price": {
          return (
            <>
              <ProductSettings />
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "variable": {
          return (
            <>
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "selected-option-value": {
          return (
            <>
              <OptionSelector />
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
        case "option-value": {
          return (
            <>
              <BasicTypography includeTextType />
              <VisibilitySettings />
              <BreakpointOverrides />
            </>
          );
        }
      }

      break;
    }
    case "block": {
      return (
        <>
          <LayoutGuideSettings />
          <FillSettings />
          <BorderSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "button": {
      return (
        <>
          <ActionSettings />
          <FillSettings />
          <BorderSettings />
          <OutlineSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "breakpoint": {
      return null;
    }
    case "page": {
      return null;
    }
    case "product-image-carousel-featured-image": {
      return (
        <>
          <CarouselSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "product-image-carousel-thumbnails": {
      return (
        <>
          <GapSettings />
          <CarouselSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "product-image-carousel-thumbnail": {
      return (
        <>
          <BorderSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "product-image-carousel-root": {
      return (
        <>
          <ProductSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "accordion-chevron": {
      return (
        <>
          <FillColorSettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "accordion-content": {
      return (
        <>
          <BackgroundColorSettings />
          <BorderSettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "accordion-item": {
      return null;
    }
    case "accordion-root": {
      return <VisibilitySettings />;
    }
    case "accordion-trigger": {
      return (
        <>
          <BackgroundColorSettings />
          <BorderSettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "option-selector-root": {
      return (
        <>
          <OptionSelector />
          <FlexDirectionSettings />
          <GapSettings />
          <VariablesSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "option-selector-item": {
      return (
        <>
          <FillSettings />
          <BorderSettings />
          <OutlineSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "carousel-root": {
      return (
        <>
          <GapSettings />
          <CarouselSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "carousel-slide": {
      return (
        <>
          <ActionSettings />
          <FillSettings />
          <BorderSettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "quantity-picker": {
      return (
        <>
          <ProductSettings />
          <IconSettings />
          <BasicTypography includeTextType={false} />
          <FillSettings />
          <BorderSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "option-dropdown": {
      return (
        <>
          <OptionSelector />
          <BackgroundColorSettings />
          <IconSettings />
          <BasicTypography includeTextType={false} />
          <BorderSettings />
          <OutlineSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "icon": {
      return (
        <>
          <ActionSettings />
          <FillColorSettings />
          <VisibilitySettings />
          <BreakpointOverrides />
        </>
      );
    }
    case "liquid": {
      return <LiquidSettings />;
    }
    case "group": {
      return null;
    }
  }
}

export function SidebarStyles() {
  const sidebarState = useAppSelector((state) => state.editor.sidebarState);

  const firstSelectedLayerId = useAppSelector(selectFirstSelectedLayerId);
  const firstSelectedLayerType = useAppSelector(selectFirstSelectedLayerType);
  const firstSelectedLayer = useAppSelector(selectFirstSelectedLayer);

  if (sidebarState !== "styles") return null;

  if (
    firstSelectedLayerId === null ||
    firstSelectedLayerType === null ||
    firstSelectedLayer === null
  )
    return (
      <p className="mx-3 mt-2 text-left text-sm text-gray-11">
        No element selected. Select an element to edit its styles
      </p>
    );

  return (
    <>
      <AlignmentButtons />
      <Breadcrumbs />
      <StateSelector />
      <MainInformationSettings type={firstSelectedLayerType} />
      <SidebarStylesLayerSpecificContent layer={firstSelectedLayer} />
    </>
  );
}
