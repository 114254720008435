import { PageThumbnails } from "@/features/dashboard/components/PageThumbnails";
import { Sidebar } from "@/features/dashboard/components/Sidebar";
import { Modal } from "@shopify/app-bridge-react";
import { useRef, useState } from "react";
import { Editor } from "@/features/editor/Editor";
import { LoseUnsavedChangesConfirmation } from "@/features/dashboard/components/LoseUnsavedChangesConfirmation";
import { CreatePageDialog } from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog";
import { ShopifyModalProvider } from "@/features/dashboard/context/ShopifyModalContext";
import { StorePassword } from "@/features/editor/components/StorePassword";
import { checkIfIsIntegrationTest } from "@/utils/checkIfIsIntegrationTest";

const isIntegrationTest = checkIfIsIntegrationTest();

export function Dashboard() {
  const [selectedPageId, setSelectedPageId] = useState<null | string>(null);

  const [confirmLoseChangesDialogOpen, setConfirmLoseChangesDialogOpen] =
    useState(false);

  const shopifyModalRef = useRef<UIModalElement>(null);

  const handleSelectPage = async (pageId: string) => {
    await shopifyModalRef.current?.show();
    setSelectedPageId(pageId);
  };

  // This is so we force the editor to re-mount when the modal is reopened
  // Fixes: https://linear.app/folds/issue/FOL-408/fix-fonts-sometimes-not-loading-when-modal-is-reopened
  const isDisplayingEditor =
    typeof selectedPageId === "string" && !confirmLoseChangesDialogOpen;

  return (
    <>
      <LoseUnsavedChangesConfirmation
        confirmLoseChangesDialogOpen={confirmLoseChangesDialogOpen}
        setConfirmLoseChangesDialogOpen={setConfirmLoseChangesDialogOpen}
        clearPageId={() => setSelectedPageId(null)}
        modalRef={shopifyModalRef}
      />
      <Sidebar />
      <div className="absolute left-56 flex h-screen w-[calc(100vw-224px)] flex-col gap-6 overflow-y-auto p-6">
        <div className="flex items-center justify-between">
          <h3 className="text-xl text-gray-12">Pages</h3>
          <CreatePageDialog onPageCreated={handleSelectPage}>
            <button
              type="button"
              className="h-10 rounded bg-primary px-14 text-sm text-white"
            >
              New page
            </button>
          </CreatePageDialog>
        </div>
        <StorePassword />
        <PageThumbnails onSelect={handleSelectPage} />
        {!isIntegrationTest && (
          <Modal variant="max" ref={shopifyModalRef}>
            {isDisplayingEditor && (
              <ShopifyModalProvider modal={shopifyModalRef.current}>
                <Editor pageId={selectedPageId} />
              </ShopifyModalProvider>
            )}
          </Modal>
        )}
      </div>
    </>
  );
}
