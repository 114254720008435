import { nanoid } from "@core/lib";
import { toastStore } from "@core/toastStore";
import { Toasts } from "@folds/shared";
import { useEffect, useState } from "react";

type ToastArgs = {
  id?: string;
  customIcon?: React.ReactNode;
  props?: React.HTMLProps<HTMLDivElement>;
};

export const toast = (message: React.ReactNode, args?: ToastArgs) => {
  const toastId = args?.id ?? nanoid();

  toastStore.addToast({
    message,
    state: "normal",
    id: toastId,
    customIcon: args?.customIcon,
    props: args?.props,
  });
};

toast.success = (message: React.ReactNode, args?: ToastArgs) => {
  const toastId = args?.id ?? nanoid();

  toastStore.addToast({
    message,
    state: "success",
    id: toastId,
    customIcon: args?.customIcon,
    props: args?.props,
  });
};

toast.error = (message: React.ReactNode, args?: ToastArgs) => {
  const toastId = args?.id ?? nanoid();

  toastStore.addToast({
    message,
    state: "error",
    id: toastId,
    customIcon: args?.customIcon,
    props: args?.props,
  });
};

toast.loading = (message: React.ReactNode, args?: ToastArgs) => {
  const toastId = args?.id ?? nanoid();

  toastStore.addToast({
    message,
    state: "loading",
    id: toastId,
    customIcon: args?.customIcon,
    props: args?.props,
  });
};

toast.dismiss = (id: string) => {
  toastStore.removeToast(id);
};

export const useToasts = () => {
  const [toastsValue, setToastsValue] = useState<Toasts>({});

  useEffect(() => {
    const unsubscribe = toastStore.subscribe((newToasts) => {
      // Need to create a new object to trigger a re-render
      const newObject = { ...newToasts };

      setToastsValue(newObject);
    });

    return unsubscribe;
  }, []);

  return toastsValue;
};
