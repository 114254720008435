import { modalStore } from "@core/modalStore";
import { useCallback, useEffect, useRef, useState } from "react";

const useLoadFont = (
  fontFamily: string | undefined,
  url: string | undefined,
  onLoadFont: () => void
) => {
  const ref = useRef<HTMLButtonElement>(null);

  const loadFont = useCallback(async () => {
    if (url === undefined || fontFamily === undefined) return;

    // Webfonts uses http and not https so we need to replace it or else we get a mixed content error
    const urlWithProperProtocol = url.replace("http://", "https://");

    const font = new FontFace(fontFamily, `url(${urlWithProperProtocol})`);

    const modalDocument = modalStore.getDocument();
    if (modalDocument === null) return;

    modalDocument.fonts.add(font);
    await font.load();
    onLoadFont();
  }, [fontFamily, onLoadFont, url]);

  useEffect(() => {
    // Load font when option is visible
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry === undefined) return;

        await loadFont();
      },
      { threshold: 0.1 }
    );

    const { current: element } = ref;

    if (element === null) return;

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [loadFont]);

  return ref;
};

type FontProps = {
  fontFamily: string | undefined;
  url: string | undefined;
  onSelect: (font: string) => void;
  style: React.CSSProperties;
};

const QUOTES_REGEX = /[']+/g;

export function Font({ fontFamily, onSelect, style, url }: FontProps) {
  const [, rerender] = useState<symbol>(Symbol(""));

  // The fonts aren't loading properly if we don't force a re-render
  // https://linear.app/folds/issue/FOL-381/fix-fonts-loading-incorrectly
  const onLoadFont = () => {
    rerender(Symbol(""));
  };

  const ref = useLoadFont(fontFamily, url, onLoadFont);

  const handleClick = () => {
    if (fontFamily === undefined) return;

    onSelect(fontFamily);
  };

  if (fontFamily === undefined) return null;

  return (
    <button
      type="button"
      ref={ref}
      onClick={handleClick}
      style={{ ...style, fontFamily: `"${fontFamily}"` }}
      className="truncate pl-2 text-left text-sm hover:bg-gray-4"
    >
      {/* Fonts with numbers in their names are wrapped in quotes */}
      {fontFamily.replace(QUOTES_REGEX, "")}
    </button>
  );
}
