import { BlockLayer } from '../types';
import { generateFillStyles } from './utils/generateFillStyles';

export const generateBlockStyles = (layer: BlockLayer): React.CSSProperties => {
  const fillStyles = generateFillStyles(layer);

  const {
    height,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopWidth,
    visible,
  } = layer;

  return {
    all: 'initial',
    ...fillStyles,
    // We need to use overflow: clip to avoid this: https://linear.app/folds/issue/FOL-420/fix-max-content-text-is-scrolling-block
    overflow: 'clip',
    boxSizing: 'border-box',
    height,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopWidth,
    position: 'relative',
    display: visible ? 'block' : 'none',
    transition: 'height 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};
