import { usePageInformation } from "@/features/editor/api/getPageInformation";
import { usePageId } from "@/features/editor/context/PageId";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";
import { usePages } from "@/features/dashboard/api/getPages";

export const useUnpublishPage = () => {
  const axios = useAxios();
  const pageId = usePageId();

  const { mutate: updatePageInformation } = usePageInformation();
  const { mutate: updatePages } = usePages();

  const unpublishPage = async () => {
    try {
      toast.loading("Unpublishing page...", { id: "unpublish-page" });
      await axios.post(`/pages/${pageId}/unpublish`);
      toast.success("Page unpublished", { id: "unpublish-page" });

      await updatePageInformation();
      await updatePages();
    } catch (error) {
      toast.error(
        "Failed to unpublish page. Please contact support if this error persists.",
        { id: "unpublish-page" }
      );
    }
  };

  return unpublishPage;
};
