import { useEventConnector } from "@core/events";
import {
  getElement,
  getLayerDimensions,
  getLayerRelativePosition,
} from "@core/utils";
import {
  Box,
  LayerId,
  ProductImageCarouselRootLayer,
  ShopifyProduct,
} from "@folds/shared/types";
import {
  CSSProperties,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const ProductId = createContext<ShopifyProduct["id"] | null>(null);

export const useProductImagesCarouselProductId = () => {
  const productId = useContext(ProductId);

  return productId;
};

export const useIntrinsicallySizedBoundingBox = (children: LayerId[]) => {
  const [box, setBox] = useState<Box>({ top: 0, left: 0, width: 0, height: 0 });

  const calculateBox = useCallback(() => {
    const positions = children
      .map((id) => {
        const child = getElement(id);

        if (!child || child.style.display === "none") return null;

        const position = getLayerRelativePosition(id);
        const dimensions = getLayerDimensions(id);

        return {
          top: position.top,
          left: position.left,
          width: dimensions.width,
          height: dimensions.height,
        };
      })
      .filter((position) => position !== null) as Box[];

    const top =
      positions.length > 0
        ? Math.min(...positions.map((position) => position.top))
        : 0;

    const left =
      positions.length > 0
        ? Math.min(...positions.map((position) => position.left))
        : 0;

    const bottom =
      positions.length > 0
        ? Math.max(
            ...positions.map((position) => position.top + position.height)
          )
        : 0;

    const right =
      positions.length > 0
        ? Math.max(
            ...positions.map((position) => position.left + position.width)
          )
        : 0;

    const width = right - left;
    const height = bottom - top;

    setBox((previousBox) => {
      const isIdentical =
        previousBox.top === top &&
        previousBox.left === left &&
        previousBox.width === width &&
        previousBox.height === height;

      if (isIdentical) {
        return previousBox;
      }

      return {
        top,
        left,
        width,
        height,
      };
    });
  }, [children]);

  useEffect(() => {
    const interval = setInterval(calculateBox, 25);

    return () => {
      clearInterval(interval);
    };
  }, [calculateBox]);

  return box;
};

const generateRootStyles = (box: Box): CSSProperties => ({
  position: "absolute",
  height: box.height,
  width: box.width,
  top: box.top,
  left: box.left,
});

export function ProductImageCarouselRoot({
  children,
  layer,
}: PropsWithChildren<{ layer: ProductImageCarouselRootLayer }>) {
  const { handleMouseDown, handleMouseOver } = useEventConnector(layer.id);

  const box = useIntrinsicallySizedBoundingBox(layer.children);
  const style = generateRootStyles(box);

  return (
    <ProductId.Provider value={layer.productId}>
      <div
        style={style}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        id={layer.id}
      />
      {children}
    </ProductId.Provider>
  );
}
