import { useEventConnector } from "@core/events";
import { generateIconStyles } from "@folds/shared/layers";
import { IconLayer } from "@folds/shared/types";
import DOMPurify from "dompurify";

export function Icon({ layer }: { layer: IconLayer }) {
  const { handleContextMenu, handleMouseDown, handleMouseOver } =
    useEventConnector(layer.id);
  const styles = generateIconStyles(layer);

  const sanitizedSvg = DOMPurify.sanitize(layer.svg);

  return (
    <div
      style={styles}
      id={layer.id}
      onContextMenu={handleContextMenu}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedSvg }}
    />
  );
}
