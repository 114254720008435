import { useMatchingPreviewProduct } from "@/utils/getMatchingPreviewProduct";
import { ShopifyProduct } from "@folds/shared";
import { useAxios } from "@lib/axios";
import { Static, Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR, { SWRResponse } from "swr";

const productPriceResponseSchema = Type.Object({
  price: Type.String(),
  compareAtPrice: Type.Union([Type.String(), Type.Null()]),
});

type ProdcutPriceResponse = Static<typeof productPriceResponseSchema>;

const compiledProductPriceResponseSchema = TypeCompiler.Compile(
  productPriceResponseSchema
);

export const useProductPrice = (
  id: ShopifyProduct["id"] | null
): SWRResponse<ProdcutPriceResponse, unknown, unknown> => {
  const axios = useAxios();

  const matchingPreviewProduct = useMatchingPreviewProduct(id);

  const getProductPrice = async (key: string) => {
    const { data } = await axios.get(key);

    const result = compiledProductPriceResponseSchema.Decode(data);

    return result;
  };

  const result = useSWR(
    typeof id === "number" && matchingPreviewProduct === null
      ? `/shopify/products/${id}/price`
      : null,
    getProductPrice
  );

  if (matchingPreviewProduct !== null) {
    const firstVariant = matchingPreviewProduct.variants[0];

    if (firstVariant === undefined) {
      return {
        data: undefined,
        error: undefined,
        isLoading: false,
        isValidating: false,
        mutate: () => new Promise(() => {}),
      };
    }

    const data: ProdcutPriceResponse = {
      compareAtPrice: firstVariant.compare_at_price,
      price: firstVariant.price,
    };

    return {
      data,
      error: undefined,
      isLoading: false,
      isValidating: false,
      mutate: () => new Promise(() => {}),
    };
  }

  return result;
};
