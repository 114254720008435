import { ShopifyPage } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export const useAllPages = () => {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);
    return result.data as ShopifyPage[];
  };

  return useSWR("/shopify/pages", getResult);
};
