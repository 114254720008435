import { useEffect, useState } from "react";
import { store } from "@core/store";
import { twMerge } from "tailwind-merge";
import Lottie from "react-lottie";
import trackpadPinch from "./lotties/trackpadPinch.json";
import commandButtonPress from "./lotties/commandButtonPress.json";
import scrollMouseWheel from "./lotties/scrollMouseWheel.json";
import ctrlButtonPress from "./lotties/ctrlButtonPress.json";

function PlusIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#687076"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function KeyboardZoomInstructions({
  onSkipTutorial,
  onContinue,
}: {
  onSkipTutorial: () => void;
  onContinue: () => void;
}) {
  const [intialZoom] = useState(store.getState().editor.scale);

  const [hasZoomed, setHasZoomed] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      const { scale } = store.getState().editor;

      if (scale !== intialZoom) {
        setHasZoomed(true);
      }
    });
  }, [intialZoom]);

  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

  return (
    <div
      onMouseDown={(event) => event.stopPropagation()}
      className="absolute bottom-3 left-[calc((100vw_+_326px)_/_2)] z-[999] flex w-[756px] -translate-x-1/2 justify-between gap-8 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
    >
      <div className="flex flex-1 items-center justify-center rounded bg-gray-3">
        <Lottie
          options={{
            animationData: isMac ? commandButtonPress : ctrlButtonPress,
            loop: false,
          }}
          width={85}
          height={85}
        />
        <PlusIcon />
        <Lottie
          options={{ animationData: scrollMouseWheel }}
          width={85}
          height={85}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-12">Zoom</h3>
            <button
              className="text-base text-gray-11"
              type="button"
              onClick={onSkipTutorial}
            >
              Skip tutorial
            </button>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-base text-gray-12">Hold</p>
            <div className="rounded border border-gray-6 bg-gray-3 px-3 py-1">
              {isMac ? " ⌘ Command" : "Ctrl"}
            </div>
            <p className="text-base text-gray-12">
              and scroll your mouse wheel
            </p>
          </div>
        </div>
        <button
          type="button"
          className={twMerge(
            "hover:bg-primary rounded bg-gray-12 py-3 text-gray-1 transition-colors duration-200",
            hasZoomed && "bg-primary"
          )}
          onClick={onContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export function TrackpadZoomInstructions({
  onSkipTutorial,
  onContinue,
}: {
  onSkipTutorial: () => void;
  onContinue: () => void;
}) {
  const [intialZoom] = useState(store.getState().editor.scale);

  const [hasZoomed, setHasZoomed] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      const { scale } = store.getState().editor;

      if (scale !== intialZoom) {
        setHasZoomed(true);
      }
    });
  }, [intialZoom]);

  return (
    <div
      onMouseDown={(event) => event.stopPropagation()}
      className="absolute bottom-3 left-[calc((100vw_+_326px)_/_2)] z-[999] flex w-[756px] -translate-x-1/2 justify-between gap-8 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
    >
      <div className="flex flex-1 items-center justify-center rounded bg-gray-3">
        <Lottie
          options={{ animationData: trackpadPinch }}
          width={125}
          height={125}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-12">Zoom</h3>
            <button
              className="text-base text-gray-11"
              type="button"
              onClick={onSkipTutorial}
            >
              Skip tutorial
            </button>
          </div>
          <p className="max-w-80 text-base text-gray-12">
            Spread two fingers to zoom in and bring two fingers together to zoom
            out
          </p>
        </div>
        <button
          type="button"
          className={twMerge(
            "hover:bg-primary rounded bg-gray-12 py-3 text-gray-1 transition-colors duration-200",
            hasZoomed && "bg-primary"
          )}
          onClick={onContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
