import { compiledLayersSchema } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export const usePage = (pageId: string) => {
  const axios = useAxios();

  const getPage = async (key: string) => {
    const { data } = await axios.get(key);
    const parsedResult = compiledLayersSchema.Decode(data);
    return parsedResult;
  };

  return useSWR(`/pages/${pageId}`, getPage);
};
