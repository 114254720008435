import { useFonts } from "@core/features/text/api/getFonts";
import { loadFontFamilies } from "@core/features/text/utils";
import { useAppSelector } from "@core/hooks";
import { useModalDocument } from "@core/modalStore";
import { useEffect } from "react";

export const useLoadPreviouslySelectedFontFamilyInModal = () => {
  const { data: fonts } = useFonts();

  const modalDocument = useModalDocument();

  const previouslySelectedFontFamily = useAppSelector(
    (state) => state.editor.previouslySelectedFontFamily
  );

  useEffect(() => {
    if (!fonts || !modalDocument) return;

    loadFontFamilies([previouslySelectedFontFamily], fonts, modalDocument);
  }, [fonts, modalDocument, previouslySelectedFontFamily]);
};
