import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR from "swr";

const expectedResponse = TypeCompiler.Compile(Type.Array(Type.String()));

export const useImages = () => {
  const authAxios = useAxios();

  const getImages = async (key: string) => {
    const response = await authAxios.get(key);

    const parsedData = expectedResponse.Decode(response.data);

    return parsedData;
  };

  return useSWR("/images", getImages);
};
