import {
  ColorPicker,
  MultipleOptions,
  MultipleOptionsProps,
} from "@/features/sidebar/components";
import { Input } from "@/features/sidebar/components/Input";
import { parseNumericalValue } from "@/features/sidebar/utils/parseNumericalValue";
import {
  selectColor,
  selectContentType,
  selectFontFamily,
  selectFontSize,
  selectFontWeight,
  selectIsItalicized,
  selectIsLineThrough,
  selectIsUnderlined,
  selectLetterSpacing,
  selectLineHeight,
  selectTextAlign,
  selectTextTransform,
  setSelectedLayerProperties,
  changeTextContent,
} from "@core/features/editor/editorSlice";
import { SidebarFontPicker } from "@core/features/text/components/SidebarFontPicker";
import { SidebarFontWeightSelect } from "@core/features/text/components/SidebarFontWeightSelect";
import { SidebarMarks } from "@core/features/text/components/SidebarMarks";
import { SidebarTextTypes } from "@core/features/text/components/SidebarTextType";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import {
  TextLayer,
  compiledTextAlignSchema,
  compiledTextTransformSchema,
} from "@folds/shared/types";
import { colord } from "colord";
import { toast } from "@core/toast";

import * as i from "./Typography.icons";

const textAlignOptions: MultipleOptionsProps["options"] = [
  { icon: <i.TextAlignLeft />, value: "left" },
  {
    value: "center",
    icon: <i.TextAlignCenter />,
  },
  { value: "right", icon: <i.TextAlignRight /> },
];

const textTransformOptions: MultipleOptionsProps["options"] = [
  { icon: <i.NoTextCase />, value: "none" },
  {
    value: "capitalize",
    icon: <p className="text-xs">Aa</p>,
  },
  {
    value: "uppercase",
    icon: <p className="text-xs">AA</p>,
  },
  {
    value: "lowercase",
    icon: <p className="text-xs">aa</p>,
  },
];

export function BasicTypography({
  includeTextType,
}: {
  includeTextType: boolean;
}) {
  const dispatch = useAppDispatch();

  const fontFamily = useAppSelector(selectFontFamily);
  const color = useAppSelector(selectColor);
  const fontSize = useAppSelector(selectFontSize);
  const textAlign = useAppSelector(selectTextAlign);
  const textTransform = useAppSelector(selectTextTransform);
  const lineHeight = useAppSelector(selectLineHeight);
  const letterSpacing = useAppSelector(selectLetterSpacing);
  const fontWeight = useAppSelector(selectFontWeight);

  const isItalicized = useAppSelector(selectIsItalicized);
  const lineThrough = useAppSelector(selectIsLineThrough);
  const isUnderlined = useAppSelector(selectIsUnderlined);

  const contentType = useAppSelector(selectContentType);

  const handleChangeFontSize = (value: string) => {
    const newFontSize = parseNumericalValue(value);
    if (newFontSize === null) return;

    dispatch(setSelectedLayerProperties({ fontSize: newFontSize }));
  };

  const handleChangeLineHeight = (value: string) => {
    const newLineHeight = parseNumericalValue(value);
    if (newLineHeight === null) return;

    dispatch(setSelectedLayerProperties({ lineHeight: newLineHeight }));
  };

  const handleChangeTextTransform = (value: string) => {
    try {
      const transform = compiledTextTransformSchema.Decode(value);

      dispatch(setSelectedLayerProperties({ textTransform: transform }));
    } catch (error) {
      toast.error("There was an issue changing the text transform");
    }
  };

  const handleChangeTextAlign = (value: string) => {
    try {
      const alignment = compiledTextAlignSchema.Decode(value);

      dispatch(setSelectedLayerProperties({ textAlign: alignment }));
    } catch {
      toast.error("There was an issue changing the alignment");
    }
  };

  const handleChangeLetterSpacing = (value: string) => {
    const newLetterSpacing = parseNumericalValue(value);
    if (newLetterSpacing === null) return;

    dispatch(setSelectedLayerProperties({ letterSpacing: newLetterSpacing }));
  };

  const handleChangeFontWeight = (value: string) => {
    const newFontWeight = parseNumericalValue(value);
    if (newFontWeight === null) return;

    dispatch(setSelectedLayerProperties({ fontWeight: newFontWeight }));
  };

  const handleChangeFontFamily = (value: string) => {
    dispatch(setSelectedLayerProperties({ fontFamily: value }));
  };

  const handleToggleItalic = () => {
    dispatch(setSelectedLayerProperties({ italic: !isItalicized }));
  };

  const handleToggleUnderline = () => {
    dispatch(setSelectedLayerProperties({ underline: !isUnderlined }));
  };

  const handleToggleLineThrough = () => {
    dispatch(setSelectedLayerProperties({ lineThrough: !lineThrough }));
  };

  const handleChangeColor = (value: string) => {
    const isColor = colord(value).isValid();

    if (!isColor) return;

    dispatch(setSelectedLayerProperties({ color: value }));
  };

  const handleTextTypeChange = ({
    content,
    variableId,
  }: {
    content: TextLayer["content"];
    variableId: string | null;
  }) => {
    if (content === "variable" && variableId === null) return;

    dispatch(changeTextContent({ content, variableId }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-solid border-gray-6 px-3 py-2">
      <div className="flex h-8 items-center justify-between">
        <h3 className="text-sm font-medium">Text</h3>
      </div>
      <div className="flex flex-col gap-2">
        {includeTextType && (
          <SidebarTextTypes
            value={contentType}
            onValueChange={handleTextTypeChange}
          />
        )}
        <SidebarFontPicker
          fontFamily={fontFamily}
          onValueChange={handleChangeFontFamily}
        />
        <ColorPicker value={color} onValueChange={handleChangeColor} />
        <SidebarFontWeightSelect
          onValueChange={handleChangeFontWeight}
          value={String(fontWeight)}
        />
        <div className="grid grid-cols-2 gap-2">
          <Input
            minValue={1}
            unit="px"
            className="!px-3"
            onValueChange={handleChangeFontSize}
            value={String(fontSize)}
          />
          <SidebarMarks
            onToggleItalic={handleToggleItalic}
            onToggleLineThrough={handleToggleLineThrough}
            onToggleUnderline={handleToggleUnderline}
            isItalicized={isItalicized}
            isLineThrough={lineThrough}
            isUnderlined={isUnderlined}
          />
          <MultipleOptions
            options={textAlignOptions}
            rootClassName="flex-1"
            value={textAlign}
            onValueChange={handleChangeTextAlign}
          />
          <MultipleOptions
            options={textTransformOptions}
            value={textTransform}
            onValueChange={handleChangeTextTransform}
            rootClassName="flex-1"
          />
          <Input
            minValue={0}
            displayUnit
            icon={<i.LineHeight />}
            value={String(lineHeight)}
            onValueChange={handleChangeLineHeight}
            unit="%"
          />
          <Input
            icon={<i.LetterSpacing />}
            unit="px"
            value={String(letterSpacing)}
            minValue={null}
            onValueChange={handleChangeLetterSpacing}
          />
        </div>
      </div>
    </div>
  );
}
