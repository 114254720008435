import { useMatchingPreviewProduct } from "@/utils/getMatchingPreviewProduct";
import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR, { SWRResponse } from "swr";

const responseSchema = TypeCompiler.Compile(Type.Array(Type.String()));

export const useProductImages = (
  productId: number | null
): SWRResponse<string[], unknown, unknown> => {
  const axios = useAxios();

  const matchingPreviewProduct = useMatchingPreviewProduct(productId);

  const getResult = async (key: string) => {
    const result = await axios.get(key);

    const parsedData = responseSchema.Decode(result.data);

    return parsedData;
  };

  const result = useSWR(
    matchingPreviewProduct === null && typeof productId === "number"
      ? `/shopify/products/${productId}/images`
      : null,
    getResult
  );

  if (matchingPreviewProduct !== null) {
    return {
      data: matchingPreviewProduct.images.map((image) => image.src),
      error: undefined,
      isLoading: false,
      isValidating: false,
      mutate: () => new Promise(() => {}),
    };
  }

  return result;
};
