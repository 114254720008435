import * as RadioGroup from "@radix-ui/react-radio-group";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export type Options = {
  icon: React.ReactNode;
  value: string;
  label?: string;
}[];

export type MultipleOptionsProps = {
  options: Options;
  rootClassName?: string;
  itemClassName?: string;
  onValueChange?: (value: string) => void;
  value?: string | null;
};

export function MultipleOptions({
  options,
  rootClassName,
  onValueChange,
  value,
  itemClassName,
}: MultipleOptionsProps) {
  const className = twMerge(
    clsx(
      "flex h-8 items-stretch rounded-sm border border-gray-6 bg-gray-2 p-0.5",
      rootClassName
    )
  );

  return (
    <RadioGroup.Root
      // Have to use null to reset the value
      // https://github.com/radix-ui/primitives/discussions/1587#discussion-4271513
      value={value as string | undefined}
      onValueChange={onValueChange}
      className={className}
    >
      {options.map((option) => (
        <RadioGroup.Item
          aria-label={option.label}
          value={option.value}
          key={option.value}
          className={twMerge(
            "flex flex-1 items-center justify-center rounded-sm fill-gray-11 text-gray-11 data-[state=checked]:bg-gray-5 data-[state=checked]:fill-gray-12 data-[state=checked]:text-gray-12",
            itemClassName
          )}
        >
          {option.icon}
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
}
