import {
  addBlock,
  selectBreakpointId,
  selectBreakpointWidth,
  selectIsDragging,
  selectIsResizing,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { clampScale } from "@core/render/blocks/utils";
import { getLayerDimensions } from "@core/utils";
import { useCallback, useEffect, useState } from "react";

const useBreakpointHeight = () => {
  const [breakpointHeight, setBreakpointHeight] = useState<null | number>(null);
  const breakpointId = useAppSelector(selectBreakpointId);

  const calculateBreakpointHeight = useCallback(() => {
    if (breakpointId === null) return;

    const breakpoint = getLayerDimensions(breakpointId);

    if (breakpointHeight === breakpoint.height) return;

    setBreakpointHeight(breakpoint.height);
  }, [breakpointHeight, breakpointId]);

  // Resize observer isn't working inside the App Bridge Modal, so instead we can poll the height of the breakpoint layer
  // We can't use a reselect selector since we need to account for the height of the header / footer images which are not part of the layer tree
  useEffect(() => {
    const interval = setInterval(calculateBreakpointHeight, 16);

    return () => clearInterval(interval);
  }, [calculateBreakpointHeight]);

  return breakpointHeight;
};

const BREAKPOINT_GAP = 12;

export function AddNewBlock() {
  const dispatch = useAppDispatch();

  const breakpointHeight = useBreakpointHeight();

  const scale = useAppSelector((state) => state.editor.scale);
  const breakpointWidth = useAppSelector(selectBreakpointWidth);
  const isResizing = useAppSelector(selectIsResizing);
  const isDragging = useAppSelector(selectIsDragging);

  if (breakpointHeight === null || isResizing || isDragging) return null;

  const clampedScale = clampScale(scale);

  const wrapperStyle: React.CSSProperties = {
    top: breakpointHeight + BREAKPOINT_GAP,
  };

  const buttonStyle: React.CSSProperties = {
    fontSize: 12 / clampedScale,
    left: 0,
    height: 40 / clampedScale,
    width: breakpointWidth,
  };

  const handleClick = () => {
    dispatch(addBlock());
  };

  return (
    <div
      className="pointer-events-none absolute flex w-[1440px] justify-center"
      style={wrapperStyle}
    >
      <button
        onClick={handleClick}
        style={buttonStyle}
        type="button"
        className="pointer-events-auto z-50 origin-top select-none rounded border border-gray-6 bg-gray-1 py-3 text-xs text-gray-12"
      >
        New block
      </button>
    </div>
  );
}
