import { searchResourcesResultSchema } from "@/features/sidebar/api/common";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export function usePageUrlsSearch(searchValue: string) {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);

    const parsedResult = searchResourcesResultSchema.Decode(result.data);

    return parsedResult;
  };

  return useSWR(`/shopify/urls/pages?search=${searchValue}`, getResult);
}
