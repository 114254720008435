import { Type, Static } from "@sinclair/typebox";

export const ProductPageInput = Type.Object({
  id: Type.String(),
  name: Type.String(),
  shopifyId: Type.Optional(Type.String()),
  createdAt: Type.String(),
  viewedAt: Type.String(),
  updatedAt: Type.String(),
  publishedAt: Type.Optional(Type.String()),
  shopifyDomain: Type.String(),
});

export type ProductPageInputType = Static<typeof ProductPageInput>;
