function LeftChevron() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7803 3.96967C12.0732 4.26256 12.0732 4.73744 11.7803 5.03033L7.81066 9L11.7803 12.9697C12.0732 13.2626 12.0732 13.7374 11.7803 14.0303C11.4874 14.3232 11.0126 14.3232 10.7197 14.0303L6.21967 9.53033C5.92678 9.23744 5.92678 8.76256 6.21967 8.46967L10.7197 3.96967C11.0126 3.67678 11.4874 3.67678 11.7803 3.96967Z"
        fill="#687076"
      />
    </svg>
  );
}

export function GoBackButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      className="flex h-7 w-16 shrink-0 items-center gap-1 rounded-sm"
      onClick={onClick}
    >
      <LeftChevron />
      <p className="text-xs text-gray-12">Back</p>
    </button>
  );
}

export function SearchURLResultsLoading({ onBack }: { onBack: () => void }) {
  return (
    <div className="h-64">
      <GoBackButton onClick={onBack} />
      <div className="flex flex-col gap-1">
        <div className="h-7 animate-pulse bg-gray-4" />
        <div className="h-7 animate-pulse bg-gray-4" />
        <div className="h-7 animate-pulse bg-gray-4" />
      </div>
    </div>
  );
}

export function SearchURLResultsError({ onBack }: { onBack: () => void }) {
  return (
    <div className="h-64">
      <GoBackButton onClick={onBack} />
      <p className="text-sm text-gray-11">Something went wrong</p>
    </div>
  );
}

export function NoResultsFound() {
  return (
    <div className="flex h-64 justify-center text-sm text-gray-11">
      No results found
    </div>
  );
}

export function SearchURLResult({
  onSelect,
  result,
}: {
  onSelect: (url: string) => void;
  result: { id: string; title: string; url: string };
}) {
  return (
    <button
      onClick={() => onSelect(result.url)}
      type="button"
      className="flex min-h-[28px] shrink-0 grow-0 basis-auto items-center justify-between rounded-sm px-2 py-1 text-left text-xs text-gray-12 hover:bg-gray-4"
    >
      {result.title}
    </button>
  );
}
