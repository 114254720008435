import { blueDark, purpleDark, slateDark } from "@radix-ui/colors";

export const theme = {
  colors: {
    primary: "#16171a",
    blackHighlight: "#373737",
    primaryContrast: "#088CFF",
    text: "#D2D3E0",
    border: "#2C2D3C",
    secondary: "#24272b",
    tertiary: "#1e1e1e",
    secondaryContrast: "#6EB9FB",
    green: "#017E5D",
    destructive: "#FF2200",
    destructiveContrast: "#FF4E33",
    disabled: "#BBBBBB",
    gray: "#4F5B67",
    primary900: "#14137A",
    primary800: "#211F93",
    primary700: "#3432B7",
    primary600: "#4B49DB",
    primary500: "#6764FF",
    primary400: "#8D8AFF",
    primary300: "#A4A2FF",
    primary200: "#C2C1FF",
    primary100: "#E0E0FF",
    gray50: "#F9F9FA",
    gray100: "#F1F1F3",
    gray200: "#E7E7EA",
    gray300: "#D3D4D8",
    gray400: "#7F8599",
    gray500: "#6A6F80",
    gray600: "#555966",
    gray700: "#40434D",
    gray800: "#2B2D33",
    gray900: "#16171A",
    ...slateDark,
    ...blueDark,
    ...purpleDark,
  },
};

export type Theme = typeof theme;
