import { setSidebarState } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import {
  SidebarStateValues,
  compiledSidebarStateSchema,
} from "@folds/shared/types";
import { toast } from "@core/toast";
import * as RadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";
import { captureException } from "@sentry/react";
import * as i from "./Sidebar.icons";

export function SidebarStateOptions() {
  const dispatch = useAppDispatch();

  const sidebarState = useAppSelector((state) => state.editor.sidebarState);

  const handleChangeSidebarState = (value: string) => {
    try {
      const updatedSidebarState = compiledSidebarStateSchema.Decode(value);

      dispatch(setSidebarState(updatedSidebarState));
    } catch (error) {
      toast.error("There was an issue changing the sidebar state");
      captureException(error);
    }
  };

  const isStylesSelected = sidebarState === "styles";

  return (
    <RadioGroup.Root
      orientation="vertical"
      className="flex w-[70px] flex-col items-stretch bg-gray-3"
      value={sidebarState}
      onValueChange={handleChangeSidebarState}
    >
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "templates" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Templates}
      >
        <i.Templates selected={sidebarState === "templates"} />
        Templates
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "styles" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Styles}
      >
        <i.Styles selected={isStylesSelected} />
        Styles
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "elements" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Elements}
      >
        <i.Elements selected={sidebarState === "elements"} />
        Elements
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "icons" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Icons}
      >
        <i.Icons selected={sidebarState === "icons"} />
        Icons
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "text" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Text}
      >
        <i.Text selected={sidebarState === "text"} />
        Text
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "images" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Images}
      >
        <i.Images selected={sidebarState === "images"} />
        Images
      </RadioGroup.Item>
      <RadioGroup.Item
        className={clsx(
          "flex h-[70px] flex-col items-center justify-center gap-2 text-xs text-gray-11",
          sidebarState === "apps" && "bg-gray-5 text-gray-12"
        )}
        value={SidebarStateValues.Apps}
      >
        <i.Apps selected={sidebarState === "apps"} />
        Apps
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
}
