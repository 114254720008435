import { TextLayer } from '../types';
import { generateTypographyStyles } from './utils/generateTypographyStyles';

export const generateTextStyles = (layer: TextLayer): React.CSSProperties => {
  const { width, top, left, zIndex, visible } = layer;

  const typographyStyles = generateTypographyStyles(layer);

  return {
    ...typographyStyles,
    width: width === null ? 'max-content' : width,
    zIndex,
    top,
    left,
    position: 'absolute',
    margin: 0,
    display: visible ? 'block' : 'none',
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
    // In order for new lines to be displayed correctly, we need to use pre-line
    // https://stackoverflow.com/a/45178556/23031008
    whiteSpace: 'pre-line',
  };
};
