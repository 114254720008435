import {
  MultipleOptions,
  Options,
} from "@/features/sidebar/components/MultipleOptions";
import {
  selectIsVisibleOnDesktop,
  selectIsVisibleOnMobile,
  selectIsVisibleOnTablet,
  setVisibility,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Breakpoint } from "@folds/shared/types";

const options: Options = [
  {
    value: "true",
    icon: <p className="text-xs">Visible</p>,
  },
  {
    value: "false",
    icon: <p className="text-xs">Hidden</p>,
  },
];

const getBooleanFromValue = (value: string) => {
  if (value === "true") return true;
  if (value === "false") return false;

  return null;
};

export function VisibilitySettings() {
  const dispatch = useAppDispatch();

  const visibleOnDesktop = useAppSelector(selectIsVisibleOnDesktop);
  const visibleOnTablet = useAppSelector(selectIsVisibleOnTablet);
  const visibleOnMobile = useAppSelector(selectIsVisibleOnMobile);

  const onValueChange = (value: string, breakpoint: Breakpoint) => {
    const booleanValue = getBooleanFromValue(value);

    if (booleanValue === null) return;

    dispatch(setVisibility({ breakpoint, visible: booleanValue }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Visibility</p>
      <div className="flex flex-col gap-1">
        <p className="text-xs">Desktop</p>
        <MultipleOptions
          options={options}
          value={String(visibleOnDesktop)}
          onValueChange={(value) => onValueChange(value, "desktop")}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-xs">Tablet</p>
        <MultipleOptions
          options={options}
          value={String(visibleOnTablet)}
          onValueChange={(value) => onValueChange(value, "tablet")}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-xs">Mobile</p>
        <MultipleOptions
          options={options}
          value={String(visibleOnMobile)}
          onValueChange={(value) => onValueChange(value, "mobile")}
        />
      </div>
    </div>
  );
}
