import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import { twMerge } from "tailwind-merge";
import { ChevronLeft } from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog.icons";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";
import { useAllPages } from "@/features/sidebar/api/getAllPages";

export function CreateShopifyPageDialogContent({
  onBack,
}: {
  onBack: () => void;
}) {
  const [title, setTitle] = useState("");
  const [handle, setHandle] = useState("");

  const { mutate: refetchPages } = useAllPages();

  const axios = useAxios();

  const handleChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const spacesConvertedToDashes = event.target.value.replace(/\s/g, "-");
    const parsedhandle = spacesConvertedToDashes.replace(/[^a-z0-9-]/g, "");

    setHandle(parsedhandle);
  };

  const handleCreate = async () => {
    if (title.length === 0 || handle.length === 0) return;

    try {
      toast.loading("Creating page...", { id: "create-page" });
      await axios.post("/shopify/pages", { title, handle });
      toast.success("Page created", { id: "create-page" });
      await refetchPages();

      onBack();
    } catch (error) {
      toast.error("Failed to create page", { id: "create-page" });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-medium">
            Create Shopify page
          </Dialog.Title>
          <Dialog.Close>
            <CloseDialogIcon />
          </Dialog.Close>
        </div>
        <button
          type="button"
          className="flex w-max items-center gap-2 text-base text-gray-12"
          onClick={onBack}
        >
          <ChevronLeft />
          Back
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label className="text-sm">Name</label>
          <input
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            className="h-11 rounded border border-gray-6 bg-gray-2 px-4 text-sm"
            placeholder="Enter page name"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-sm">Handle</label>
          <input
            type="text"
            value={`/${handle}`}
            onChange={handleChangeHandle}
            className="h-11 rounded border border-gray-6 bg-gray-2 px-4 text-sm"
            placeholder="/example-page"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <Dialog.Close
          type="button"
          className="flex-1 rounded-lg border border-gray-6 bg-gray-2 py-3"
        >
          Cancel
        </Dialog.Close>
        <button
          type="button"
          className={twMerge(
            "flex-1 rounded-lg border border-gray-6 bg-gray-12 py-3 text-white",
            (title.length === 0 || handle.length === 0) &&
              "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleCreate}
        >
          Create
        </button>
      </div>
    </>
  );
}
