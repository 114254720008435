import type { Category } from "@/features/sidebar/components/SelectURL/SelectURL";

function ChevronRightIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52864 3.5286C5.78899 3.26825 6.2111 3.26825 6.47144 3.5286L10.4714 7.5286C10.7318 7.78895 10.7318 8.21106 10.4714 8.47141L6.47144 12.4714C6.2111 12.7318 5.78899 12.7318 5.52864 12.4714C5.26829 12.2111 5.26829 11.7889 5.52864 11.5286L9.05723 8L5.52864 4.47141C5.26829 4.21106 5.26829 3.78895 5.52864 3.5286Z"
        fill="black"
      />
    </svg>
  );
}

export function SelectCategory({
  onSelectCategory,
  onSelectURL,
}: {
  onSelectCategory: (category: Category) => void;
  onSelectURL: (url: string) => void;
}) {
  return (
    <div className="flex h-64 flex-col">
      <button
        onClick={() => onSelectURL("/")}
        type="button"
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Home page
      </button>
      <button
        type="button"
        onClick={() => onSelectCategory("products")}
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Products
        <ChevronRightIcon />
      </button>
      <button
        type="button"
        onClick={() => onSelectCategory("collections")}
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Collections
        <ChevronRightIcon />
      </button>
      <button
        onClick={() => onSelectCategory("pages")}
        type="button"
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Pages
        <ChevronRightIcon />
      </button>
      <button
        onClick={() => onSelectCategory("blogs")}
        type="button"
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Blogs
        <ChevronRightIcon />
      </button>
      <button
        onClick={() => onSelectCategory("articles")}
        type="button"
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Articles
        <ChevronRightIcon />
      </button>
      <button
        onClick={() => onSelectCategory("policies")}
        type="button"
        className="flex h-7 items-center justify-between rounded-sm px-2 text-left text-xs text-gray-12 hover:bg-gray-4"
      >
        Policies
        <ChevronRightIcon />
      </button>
    </div>
  );
}
