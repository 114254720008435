import {
  dragSelect,
  finishDragSelect,
  initializeDragSelect,
  selectIsDragSelecting,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Point } from "@folds/shared/types";
import { MouseEvent, useCallback } from "react";

export function useDragSelect() {
  const dispatch = useAppDispatch();

  const isDraggingSelecting = useAppSelector(selectIsDragSelecting);
  const canvasMode = useAppSelector((state) => state.editor.canvasMode);

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      if (canvasMode !== "Select") return;
      if (event.button !== 0) return;

      dispatch(initializeDragSelect({ x: event.clientX, y: event.clientY }));
    },
    [canvasMode, dispatch]
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isDraggingSelecting) return;

      const mousePoint: Point = {
        x: event.clientX,
        y: event.clientY,
      };

      dispatch(dragSelect(mousePoint));
    },
    [dispatch, isDraggingSelecting]
  );

  const handleMouseUp = useCallback(() => {
    if (!isDraggingSelecting) return;

    dispatch(finishDragSelect());
  }, [dispatch, isDraggingSelecting]);

  return {
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
  };
}
