import { nanoid } from "@core/lib";
import { checkIfIsCssVariable } from "@core/utils/checkIfIsCssVariable";
import * as Label from "@radix-ui/react-label";
import clsx from "clsx";
import { colord } from "colord";
import { useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = React.PropsWithChildren<
  {
    value: string;
    /**
     * Whether to display the unit next to the input
     */
    displayUnit?: boolean;
    inputType?: "color" | "number";
    onValueChange: (value: string) => void;
    icon?: React.ReactNode;
    autoCompleteValues?: AutoCompleteValues;
    onSelectAutoCompleteValue?: (data: AutoCompleteValues[number]) => void;
    className?: string;
    fill?: boolean;
    minValue: number | null;
    unit: "px" | "%" | null;
  } & React.InputHTMLAttributes<HTMLInputElement>
>;

const parsedInputValue = ({
  minValue,
  inputType,
  unit,
  inputValue,
}: {
  inputType: Props["inputType"];
  unit: Props["unit"];
  inputValue: string;
  minValue: number | null;
}) => {
  if (inputType === "color") {
    // Add a # if the user didn't type it
    const inputValueWithHash = inputValue.startsWith("#")
      ? inputValue
      : `#${inputValue}`;

    const isValid = colord(inputValueWithHash).isValid();
    return isValid ? inputValueWithHash : null;
  }

  const inputValueWithoutUnit =
    typeof unit === "string" ? inputValue.replace(unit, "") : inputValue;

  const numberValue = Number(inputValueWithoutUnit);

  if (Number.isNaN(numberValue)) return null;

  if (typeof minValue === "number" && numberValue < minValue)
    return String(minValue);

  return String(numberValue);
};

export type AutoCompleteValues = {
  name: string;
  value: string;
  type: "color" | "image";
}[];

export type AutoCompleteValue = AutoCompleteValues[number];

export function Input({
  icon,
  className,
  fill,
  children,
  value,
  unit,
  onValueChange,
  onSelectAutoCompleteValue,
  displayUnit,
  autoCompleteValues = [],
  inputType = "number",
  minValue,
  ...rest
}: Props) {
  const id = useMemo(() => nanoid(), []);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(value);
  const [isDisplayingSuggestions, setIsDisplayingSuggestions] = useState(false);

  useEffect(() => {
    const shouldDisplayWithUnit =
      displayUnit === true && typeof unit === "string";

    const valueWithUnit = shouldDisplayWithUnit ? `${value}${unit}` : value;

    setInputValue(valueWithUnit);
  }, [displayUnit, unit, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const submitValue = () => {
    const inputValueAttribute = inputRef.current?.getAttribute("data-value");
    if (typeof inputValueAttribute !== "string") return;

    // Don't allow the user to set a custom css variable
    if (checkIfIsCssVariable(inputValueAttribute)) {
      return;
    }

    const updatedValue = parsedInputValue({
      minValue,
      inputType,
      unit,
      inputValue: inputValueAttribute,
    });

    if (updatedValue === value) return;

    if (updatedValue === null) {
      setInputValue(value);
      return;
    }

    setInputValue(updatedValue);
    onValueChange(updatedValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = inputRef.current;
    if (inputElement === null) return;

    switch (event.key) {
      case "Escape": {
        event.preventDefault();
        setInputValue(value);

        break;
      }
      case "Enter": {
        submitValue();
        inputElement.blur();
        break;
      }
      case "ArrowUp": {
        if (inputType === "color") return;

        const inputValueWithoutUnit =
          typeof unit === "string" ? inputValue.replace(unit, "") : inputValue;

        const parsedValue = Number(inputValueWithoutUnit);

        if (Number.isNaN(parsedValue)) return;

        // Without this, the cursor will move to the start of the input
        event.preventDefault();

        setInputValue(String(parsedValue + 1));

        break;
      }
      case "ArrowDown": {
        if (inputType === "color") return;

        const inputValueWithoutUnit =
          typeof unit === "string" ? inputValue.replace(unit, "") : inputValue;

        const parsedValue = Number(inputValueWithoutUnit);

        if (Number.isNaN(parsedValue)) return;
        if (typeof minValue === "number" && parsedValue <= minValue) return;

        // Without this, the cursor will move to the end of the input
        event.preventDefault();

        setInputValue(String(parsedValue - 1));

        break;
      }
    }
  };

  const handleFocus: React.HTMLProps<HTMLInputElement>["onFocus"] = (event) => {
    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.select();
    }

    setIsDisplayingSuggestions(true);
    rest.onFocus?.(event);
  };

  const handleBlur: React.HTMLProps<HTMLInputElement>["onBlur"] = (event) => {
    setIsDisplayingSuggestions(false);
    submitValue();

    rest.onBlur?.(event);
  };

  const handleSelectAutoCompleteValue = (
    autoCompleteValue: AutoCompleteValues[number]
  ) => {
    if (onSelectAutoCompleteValue === undefined) return;

    setIsDisplayingSuggestions(false);
    onSelectAutoCompleteValue(autoCompleteValue);
  };

  return (
    <div className={clsx("relative", fill === true && "flex-1")}>
      <input
        ref={inputRef}
        id={id}
        className={twMerge(
          clsx(
            "h-8 w-full items-center rounded-sm border border-solid border-gray-6 bg-gray-2 pb-2 pl-7 pr-2 pt-2 text-xs text-gray-12 focus:ring-1 focus:ring-accent-8",
            className
          )
        )}
        autoComplete="off"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        data-value={inputValue}
        onKeyDown={handleKeyDown}
      />
      {children}
      {icon !== undefined && (
        <Label.Root
          className="absolute left-2 top-1/2 -translate-y-1/2"
          htmlFor={id}
        >
          {icon}
        </Label.Root>
      )}
      {isDisplayingSuggestions && autoCompleteValues.length > 0 && (
        <div className="absolute top-[calc(100%+4px)] z-10 flex w-full flex-col gap-2 rounded border border-gray-6 bg-gray-2 p-2 shadow-sm">
          {autoCompleteValues.map((autoCompleteValue) => (
            <button
              type="button"
              // Prevent the blur event when clicking on the autocomplete values
              onMouseDown={(event) => event.preventDefault()}
              key={`${autoCompleteValue.name}-${autoCompleteValue.value}`}
              className="h-5 truncate text-left text-xs text-gray-12"
              onClick={() => handleSelectAutoCompleteValue(autoCompleteValue)}
            >
              {autoCompleteValue.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
