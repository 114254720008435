import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import { Layer, Layers } from "@folds/shared/types";
import { Draft } from "immer";

export const addLayerAction = (
  draftLayers: Draft<Layers>,
  layer: Layer,
  index: undefined | number
) => {
  if (layer.parentId === null) return;

  const parent = draftLayers[layer.parentId];

  if (parent === undefined || !("children" in parent)) return;

  if (index !== undefined) {
    parent.children.splice(index, 0, layer.id);
  } else {
    parent.children.push(layer.id);
  }

  draftLayers[layer.id] = layer;

  if ("zIndex" in layer) {
    const highestZIndex = getHighestZIndex(draftLayers, layer.parentId);

    layer.zIndex = highestZIndex + 1;
  }
};
