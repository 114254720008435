import type { DraftEditorState } from "@core/features/editor/editorSlice";
import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import { nanoid } from "@core/lib/nanoid";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import { Layer, LayerId, LayerType, Layers } from "@folds/shared/types";
import { produce } from "immer";
import { toast } from "@core/toast";
import { getBreakpointId } from "@core/utils";

const getFirstBlockLayer = (layers: Layers) => {
  const breakpointLayer = Object.values(layers).find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (
    breakpointLayer === undefined ||
    breakpointLayer.type !== LayerType.Breakpoint
  )
    return null;

  const firstBreakpointChild = breakpointLayer.children[0];

  if (firstBreakpointChild === undefined) return null;

  const firstBlockLayer = layers[firstBreakpointChild];

  if (firstBlockLayer === undefined || firstBlockLayer.type !== LayerType.Block)
    return null;

  return firstBlockLayer;
};

const getTargetLayer = (layers: Layers, targetLayerId: LayerId | null) => {
  if (targetLayerId === null) return null;

  const targetLayer = layers[targetLayerId];

  if (!targetLayer || !("children" in targetLayer)) return null;

  return targetLayer;
};

export const getRootLayerIds = (layers: Layer[]) => {
  const rootLayerIds = layers
    .filter((layer) => {
      const isAChildLayer = layers.some(
        (currentLayer) => currentLayer.id === layer.parentId
      );

      if (isAChildLayer) return false;

      return true;
    })
    .map((layer) => layer.id);

  return rootLayerIds;
};

export const randomizeLayers = (layers: Layer[]) => {
  const randomizedLayers = produce(layers, (draft) => {
    draft.forEach((layer) => {
      const originalLayerId = layer.id;

      const randomizedLayerId = nanoid();
      layer.id = randomizedLayerId;

      draft.forEach((draftLayer) => {
        if (draftLayer.parentId === originalLayerId) {
          draftLayer.parentId = layer.id;
        }

        if ("checkedChildren" in draftLayer) {
          const checkedChild = draftLayer.checkedChildren[originalLayerId];

          if (checkedChild !== undefined) {
            draftLayer.checkedChildren[randomizedLayerId] = checkedChild;
            delete draftLayer.checkedChildren[originalLayerId];
          }
        }

        if ("hoverChildren" in draftLayer) {
          const hoverChild = draftLayer.hoverChildren[originalLayerId];

          if (hoverChild !== undefined) {
            draftLayer.hoverChildren[randomizedLayerId] = hoverChild;
            delete draftLayer.hoverChildren[originalLayerId];
          }
        }

        if ("optionUnavailableChildren" in draftLayer) {
          const optionUnavailableChild =
            draftLayer.optionUnavailableChildren[originalLayerId];

          if (optionUnavailableChild !== undefined) {
            draftLayer.optionUnavailableChildren[randomizedLayerId] =
              optionUnavailableChild;
            delete draftLayer.optionUnavailableChildren[originalLayerId];
          }
        }

        if ("children" in draftLayer) {
          draftLayer.children = draftLayer.children.map((childId) => {
            if (childId === originalLayerId) {
              return randomizedLayerId;
            }

            return childId;
          });
        }
      });
    });
  });

  return randomizedLayers;
};

export const updateLayerParentIds = (
  layers: Layer[],
  targetLayerIds: LayerId[],
  replacementValue: string
) => {
  const layersWithUpdatedParentIds = layers.map((layer) => {
    if (
      targetLayerIds.includes(layer.id) &&
      typeof layer.parentId === "string"
    ) {
      const newLayer: Layer = {
        ...layer,
        parentId: replacementValue,
      };

      return newLayer;
    }

    return layer;
  });

  return layersWithUpdatedParentIds;
};

const pasteToBreakpoint = (
  state: DraftEditorState,
  {
    randomizedLayers,
    rootLayerIds,
  }: {
    randomizedLayers: ReturnType<typeof randomizeLayers>;
    rootLayerIds: LayerId[];
  }
) => {
  const breakpointLayerId = getBreakpointId(state.layers);

  if (typeof breakpointLayerId !== "string") {
    toast.error("Failed to paste layers");
    return;
  }

  const breakpointLayer = state.layers[breakpointLayerId];

  if (!breakpointLayer || breakpointLayer.type !== LayerType.Breakpoint) {
    toast.error("Failed to paste layers");
    return;
  }

  const layersWithUpdatedParentIds = updateLayerParentIds(
    randomizedLayers,
    rootLayerIds,
    breakpointLayer.id
  );

  breakpointLayer.children.push(...rootLayerIds);

  layersWithUpdatedParentIds.forEach((layer) => {
    state.layers[layer.id] = layer;
  });

  setSelectedLayerIdsAction(state, rootLayerIds);
};

const pasteToFirstBlock = (
  state: DraftEditorState,
  {
    randomizedLayers,
    rootLayerIds,
  }: { randomizedLayers: Layer[]; rootLayerIds: LayerId[] }
) => {
  const firstBlockLayer = getFirstBlockLayer(state.layers);

  if (firstBlockLayer === null) {
    toast.error("Failed to paste layers");
    return;
  }

  const layersWithUpdatedParentIds = updateLayerParentIds(
    randomizedLayers,
    rootLayerIds,
    firstBlockLayer.id
  );

  firstBlockLayer.children.push(...rootLayerIds);

  layersWithUpdatedParentIds.forEach((layer) => {
    state.layers[layer.id] = layer;
  });

  setSelectedLayerIdsAction(state, rootLayerIds);
};

export const pasteLayersAction = (
  state: DraftEditorState,
  {
    layers,
    targetLayerId,
  }: {
    layers: Layer[];
    targetLayerId: LayerId | null;
  }
) => {
  const targetLayer = getTargetLayer(state.layers, targetLayerId);

  const randomizedLayers = randomizeLayers(layers);
  const rootLayerIds = getRootLayerIds(randomizedLayers);

  if (!targetLayer) {
    const isPastingABlockLayer = rootLayerIds.some((rootLayerId) => {
      const layer = randomizedLayers.find(
        (currentLayer) => currentLayer.id === rootLayerId
      );

      if (!layer) return false;

      return layer.type === LayerType.Block;
    });

    // Paste to the breakpoint layer if pasting block layers
    if (isPastingABlockLayer) {
      const isOnlyPastingBlockLayers = rootLayerIds.every((rootLayerId) => {
        const layer = randomizedLayers.find(
          (currentLayer) => currentLayer.id === rootLayerId
        );

        if (!layer) return false;

        return layer.type === LayerType.Block;
      });

      if (!isOnlyPastingBlockLayers) {
        toast.error("Failed to paste layers");
        return;
      }

      pasteToBreakpoint(state, {
        randomizedLayers,
        rootLayerIds,
      });

      return;
    }

    pasteToFirstBlock(state, {
      randomizedLayers,
      rootLayerIds,
    });

    return;
  }

  const layersWithUpdatedParentIds = updateLayerParentIds(
    randomizedLayers,
    rootLayerIds,
    targetLayer.id
  );

  targetLayer.children.push(...rootLayerIds);

  const highestZIndex = getHighestZIndex(state.layers, targetLayer.id);

  layersWithUpdatedParentIds.forEach((layer) => {
    const parsedLayer =
      "zIndex" in layer ? { ...layer, zIndex: highestZIndex + 1 } : layer;

    state.layers[layer.id] = parsedLayer;
  });

  setSelectedLayerIdsAction(state, rootLayerIds);
};
