import {
  MultipleOptions,
  MultipleOptionsProps,
} from "@/features/sidebar/components/MultipleOptions";
import {
  selectAutoCompleteColorValues,
  selectBorderColor,
  selectBorderStyle,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { compiledBorderStyleSchema } from "@folds/shared/types";
import { AutoCompleteValue } from "@/features/sidebar/components/Input";
import * as i from "../../Sidebar.icons";
import { ColorPicker } from "../ColorPicker";
import { SidebarBorderWidthSettings } from "./BorderWidthSettings";

const borderStyleOptions: MultipleOptionsProps["options"] = [
  { icon: <i.BorderSolid />, value: "solid" },
  { icon: <i.BorderDashed />, value: "dashed" },
  { icon: <i.BorderDotted />, value: "dotted" },
];

const getParsedBorderStyle = (borderStyle: string) => {
  try {
    const style = compiledBorderStyleSchema.Decode(borderStyle);

    return style;
  } catch (error) {
    return null;
  }
};

export function BorderSettings() {
  const dispatch = useAppDispatch();

  const borderStyle = useAppSelector(selectBorderStyle);
  const borderColor = useAppSelector(selectBorderColor);
  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);

  const handleChangeBorderStyle = (value: string) => {
    const updatedBorderStyle = getParsedBorderStyle(value);

    if (updatedBorderStyle === null) return;

    dispatch(setSelectedLayerProperties({ borderStyle: updatedBorderStyle }));
  };

  const handleChangeBorderColor = (value: string) => {
    dispatch(setSelectedLayerProperties({ borderColor: value }));
  };

  const handleSelectAutoCompleteValue = (data: AutoCompleteValue) => {
    dispatch(setSelectedLayerProperties({ borderColor: data.value }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-solid border-gray-6 p-3">
      <h3 className="text-sm font-medium">Border</h3>
      <div className="flex flex-col gap-2">
        <ColorPicker
          value={borderColor}
          onValueChange={handleChangeBorderColor}
          onSelectAutoCompleteValue={handleSelectAutoCompleteValue}
          autoCompleteValues={autoCompleteColors}
        />
        <SidebarBorderWidthSettings />
        <MultipleOptions
          onValueChange={handleChangeBorderStyle}
          options={borderStyleOptions}
          value={borderStyle}
        />
      </div>
    </div>
  );
}
