import { useAppSelector } from "@core/hooks";
import type { RootState } from "@core/store";
import { getChildIdsNested } from "@core/utils";
import { BlockLayer, LayerId, LayerType } from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";

const selectIsResizingChild = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.resizing,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, resizing, selectedLayerIds, layerId) => {
    if (resizing === null) return false;

    const block = layers[layerId];
    if (block === undefined || block.type !== LayerType.Block) return false;

    const children = getChildIdsNested(layers, block.id);

    return children.some((id) => selectedLayerIds.includes(id));
  }
);
const selectIsDraggingChild = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.dragging,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, dragging, layerId) => {
    if (dragging === null) return false;

    const block = layers[layerId];
    if (block === undefined || block.type !== LayerType.Block) return false;

    const children = getChildIdsNested(layers, block.id);

    return children.some((id) => dragging.draggingLayerIds.includes(id));
  }
);

const selectIsChildOrLayerSelected = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
    (_, layerId: LayerId) => layerId,
  ],
  (layers, selectedLayerIds, layerId) => {
    const block = layers[layerId];
    if (block === undefined || block.type !== LayerType.Block) return false;

    const nestedChildren = getChildIdsNested(layers, block.id);

    const combinedLayerIds = [...nestedChildren, block.id];

    return combinedLayerIds.some((id) => selectedLayerIds.includes(id));
  }
);

export function LayoutGuide({ layer }: { layer: BlockLayer }) {
  const isChilOrLayerdSelected = useAppSelector((state) =>
    selectIsChildOrLayerSelected(state, layer.id)
  );

  const isResizingChild = useAppSelector((state) =>
    selectIsResizingChild(state, layer.id)
  );

  const isDraggingChild = useAppSelector((state) =>
    selectIsDraggingChild(state, layer.id)
  );

  if (!isResizingChild && !isDraggingChild && !isChilOrLayerdSelected)
    return null;

  const wrapperStyle: React.CSSProperties = {
    paddingTop: layer.layoutGuideMarginTop,
    paddingBottom: layer.layoutGuideMarginBottom,
    paddingLeft: layer.layoutGuideMarginLeft,
    paddingRight: layer.layoutGuideMarginRight,
    columnGap: layer.layoutGuideColumnGap,
    gridTemplateColumns: `repeat(${layer.layoutGuideColumns}, 1fr)`,
  };

  const columnStyle: React.CSSProperties = {
    background: layer.layoutGuideColor,
  };

  return (
    <div
      className="pointer-events-none absolute left-0 top-0 grid h-full w-full"
      style={wrapperStyle}
    >
      {Array.from({ length: layer.layoutGuideColumns }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} style={columnStyle} className="z-[999]" />
      ))}
    </div>
  );
}
