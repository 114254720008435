import { LayerId, Layers } from "@folds/shared/types";

export const getChildIdsNested = (
  layers: Layers,
  layerId: LayerId
): LayerId[] => {
  const layer = layers[layerId];

  if (layer === undefined || !("children" in layer)) {
    return [];
  }

  const childIds = layer.children;

  const childIdsNested = childIds.flatMap((childId) => {
    const child = layers[childId];

    // Child sometimes doesn't exist when deleting a layer very quickly
    if (child === undefined) return [];

    return [childId, ...getChildIdsNested(layers, child.id)];
  });

  return childIdsNested;
};
