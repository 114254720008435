import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { AutoCompleteValue, Input } from "@/features/sidebar/components/Input";
import {
  selectAutoCompleteColorValues,
  selectOutlineColor,
  selectOutlineOffset,
  selectOutlineWidth,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

import * as i from "@/features/sidebar/Sidebar.icons";

export function OutlineSettings() {
  const dispatch = useAppDispatch();

  const outlineColor = useAppSelector(selectOutlineColor);
  const outlineWidth = useAppSelector(selectOutlineWidth);
  const outlineOffset = useAppSelector(selectOutlineOffset);
  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);

  const handleChangeColor = (color: string) => {
    dispatch(setSelectedLayerProperties({ outlineColor: color }));
  };

  const handleChangeOutlineWidth = (value: string) => {
    const newOutlineWidth = Number(value);

    if (Number.isNaN(newOutlineWidth)) return;

    dispatch(setSelectedLayerProperties({ outlineWidth: newOutlineWidth }));
  };

  const handleChangeOutlineOffset = (value: string) => {
    const newOutlineOffset = Number(value);

    if (Number.isNaN(newOutlineOffset)) return;

    dispatch(setSelectedLayerProperties({ outlineOffset: newOutlineOffset }));
  };

  const handleSelectAutoCompleteValue = (data: AutoCompleteValue) => {
    dispatch(setSelectedLayerProperties({ outlineColor: data.value }));
  };

  return (
    <div className="flex flex-col gap-3 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Outline</p>
      <div className="flex flex-col gap-2">
        <ColorPicker
          value={outlineColor}
          onValueChange={handleChangeColor}
          onSelectAutoCompleteValue={handleSelectAutoCompleteValue}
          autoCompleteValues={autoCompleteColors}
        />
        <div className="flex gap-2">
          <Input
            icon={<i.OutlineWidth />}
            minValue={0}
            onValueChange={handleChangeOutlineWidth}
            unit="px"
            value={String(outlineWidth)}
          />
          <Input
            icon={<i.OutlineOffset />}
            minValue={0}
            onValueChange={handleChangeOutlineOffset}
            unit="px"
            value={String(outlineOffset)}
          />
        </div>
      </div>
    </div>
  );
}
