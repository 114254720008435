import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { AutoCompleteValue, Input } from "@/features/sidebar/components/Input";
import {
  MultipleOptions,
  Options,
} from "@/features/sidebar/components/MultipleOptions";
import {
  setSelectedLayerProperties,
  selectLoopSlides,
  selectNavigationEnabled,
  selectNavigationColor,
  selectAutoCompleteColorValues,
  selectPaginationEnabled,
  selectScrollbarEnabled,
  selectScrollbarTrackColor,
  selectScrollbarThumbColor,
  selectPaginationActiveBulletColor,
  selectPaginationInactiveBulletColor,
  selectNavigationSize,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

const enabledDisabledOptions: Options = [
  { icon: <p className="text-xs text-gray-12">Enabled</p>, value: "true" },
  {
    icon: <p className="text-xs text-gray-12">Disabled</p>,
    value: "false",
  },
];

function LoopSlidesSettings() {
  const dispatch = useAppDispatch();

  const loopSlides = useAppSelector(selectLoopSlides);

  const handleValueChange = (value: string) => {
    const updatedValue = value === "true";

    dispatch(setSelectedLayerProperties({ loop: updatedValue }));
  };

  return (
    <div className="flex flex-col gap-3 border-t border-solid border-gray-6 p-3">
      <p className="text-sm font-medium">Loop slides</p>
      <MultipleOptions
        options={enabledDisabledOptions}
        value={String(loopSlides)}
        onValueChange={handleValueChange}
      />
    </div>
  );
}

function NavigationSettings() {
  const dispatch = useAppDispatch();

  const navigationEnabled = useAppSelector(selectNavigationEnabled);
  const navigationColor = useAppSelector(selectNavigationColor);
  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);
  const navigationSize = useAppSelector(selectNavigationSize);

  const handleEnabledChange = (value: string) => {
    const updatedValue = value === "true";

    dispatch(setSelectedLayerProperties({ navigationEnabled: updatedValue }));
  };

  const handleChangeColor = (value: string) => {
    dispatch(setSelectedLayerProperties({ navigationColor: value }));
  };

  const handleSelectAutoCompleteValue = (data: AutoCompleteValue) => {
    dispatch(setSelectedLayerProperties({ navigationColor: data.value }));
  };

  const handleChangeNavigationSize = (value: string) => {
    const updatedValue = Number(value);

    if (Number.isNaN(updatedValue)) return;

    dispatch(setSelectedLayerProperties({ navigationSize: updatedValue }));
  };

  return (
    <div className="flex flex-col gap-3 border-t border-solid border-gray-6 p-3">
      <p className="text-sm font-medium">Navigation</p>

      <div className="flex flex-col gap-2">
        <MultipleOptions
          options={enabledDisabledOptions}
          value={String(navigationEnabled)}
          onValueChange={handleEnabledChange}
        />
        <ColorPicker
          value={navigationColor}
          onValueChange={handleChangeColor}
          onSelectAutoCompleteValue={handleSelectAutoCompleteValue}
          autoCompleteValues={autoCompleteColors}
        />
        <div className="flex items-center justify-between">
          <p className="text-xs text-gray-12">Icon size</p>
          <Input
            value={String(navigationSize)}
            minValue={0}
            unit="px"
            onValueChange={handleChangeNavigationSize}
            className="w-24 px-2"
          />
        </div>
      </div>
    </div>
  );
}

function PaginationSettings() {
  const dispatch = useAppDispatch();

  const paginationEnabled = useAppSelector(selectPaginationEnabled);
  const paginationActiveBulletColor = useAppSelector(
    selectPaginationActiveBulletColor
  );
  const paginationInactiveBulletColor = useAppSelector(
    selectPaginationInactiveBulletColor
  );

  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);

  const handleEnabledChange = (value: string) => {
    const updatedValue = value === "true";

    dispatch(setSelectedLayerProperties({ paginationEnabled: updatedValue }));
  };

  const handleChangeActiveBulletColor = (value: string) => {
    dispatch(
      setSelectedLayerProperties({ paginationActiveBulletColor: value })
    );
  };

  const handleActiveBulletColorSelectAutoCompleteValue = (
    data: AutoCompleteValue
  ) => {
    dispatch(
      setSelectedLayerProperties({ paginationActiveBulletColor: data.value })
    );
  };

  const handleChangeInactiveBulletColor = (value: string) => {
    dispatch(
      setSelectedLayerProperties({ paginationInactiveBulletColor: value })
    );
  };

  const handleInactiveBulletColorSelectAutoCompleteValue = (
    data: AutoCompleteValue
  ) => {
    dispatch(
      setSelectedLayerProperties({ paginationInactiveBulletColor: data.value })
    );
  };

  return (
    <div className="flex flex-col gap-3 border-t border-solid border-gray-6 p-3">
      <p className="text-sm font-medium">Pagination</p>

      <div className="flex flex-col gap-2">
        <MultipleOptions
          options={enabledDisabledOptions}
          value={String(paginationEnabled)}
          onValueChange={handleEnabledChange}
        />
        <div className="flex flex-col gap-1">
          <p className="text-xs text-gray-12">Bullet active color</p>
          <ColorPicker
            value={paginationActiveBulletColor}
            onValueChange={handleChangeActiveBulletColor}
            onSelectAutoCompleteValue={
              handleActiveBulletColorSelectAutoCompleteValue
            }
            autoCompleteValues={autoCompleteColors}
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-xs text-gray-12">Bullet inactive color</p>
          <ColorPicker
            value={paginationInactiveBulletColor}
            onValueChange={handleChangeInactiveBulletColor}
            onSelectAutoCompleteValue={
              handleInactiveBulletColorSelectAutoCompleteValue
            }
            autoCompleteValues={autoCompleteColors}
          />
        </div>
      </div>
    </div>
  );
}

function ScrollbarSettings() {
  const dispatch = useAppDispatch();

  const scrollbarEnabled = useAppSelector(selectScrollbarEnabled);
  const scrollbarTrackColor = useAppSelector(selectScrollbarTrackColor);
  const scrollbarThumbColor = useAppSelector(selectScrollbarThumbColor);
  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);

  const handleEnabledChange = (value: string) => {
    const updatedValue = value === "true";

    dispatch(setSelectedLayerProperties({ scrollbarEnabled: updatedValue }));
  };

  const handleChangeTrackColor = (value: string) => {
    dispatch(setSelectedLayerProperties({ scrollbarTrackColor: value }));
  };

  const handleTrackSelectAutoCompleteValue = (data: AutoCompleteValue) => {
    dispatch(setSelectedLayerProperties({ scrollbarTrackColor: data.value }));
  };

  const handleChangeThumbColor = (value: string) => {
    dispatch(setSelectedLayerProperties({ scrollbarThumbColor: value }));
  };

  const handleThumbSelectAutoCompleteValue = (data: AutoCompleteValue) => {
    dispatch(setSelectedLayerProperties({ scrollbarThumbColor: data.value }));
  };

  return (
    <div className="flex flex-col gap-3 border-t border-solid border-gray-6 p-3">
      <p className="text-sm font-medium">Scrollbar</p>

      <div className="flex flex-col gap-2">
        <MultipleOptions
          options={enabledDisabledOptions}
          value={String(scrollbarEnabled)}
          onValueChange={handleEnabledChange}
        />
        <div className="flex flex-col gap-1">
          <p className="text-xs text-gray-12">Track color</p>
          <ColorPicker
            value={scrollbarTrackColor}
            onValueChange={handleChangeTrackColor}
            onSelectAutoCompleteValue={handleTrackSelectAutoCompleteValue}
            autoCompleteValues={autoCompleteColors}
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-xs text-gray-12">Thumb color</p>
          <ColorPicker
            value={scrollbarThumbColor}
            onValueChange={handleChangeThumbColor}
            onSelectAutoCompleteValue={handleThumbSelectAutoCompleteValue}
            autoCompleteValues={autoCompleteColors}
          />
        </div>
      </div>
    </div>
  );
}

export function CarouselSettings() {
  return (
    <>
      <LoopSlidesSettings />
      <NavigationSettings />
      <PaginationSettings />
      <ScrollbarSettings />
    </>
  );
}
