import {
  groupSelectedLayers,
  selectShouldDisplayGroupLayersAction,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function GroupLayers() {
  const dispatch = useAppDispatch();

  const shouldDisplayGroupLayersAction = useAppSelector(
    selectShouldDisplayGroupLayersAction
  );

  const handleClick = () => {
    dispatch(groupSelectedLayers());
  };

  if (shouldDisplayGroupLayersAction === false) return null;

  return (
    <div className="fixed left-[326px] z-50 w-[calc(100vw-326px)]">
      <button
        type="button"
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
        className="absolute left-1/2 top-2 h-10 w-32 -translate-x-full rounded bg-gray-12 text-xs text-gray-1"
      >
        Group layers
      </button>
    </div>
  );
}
