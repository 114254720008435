import { getElement } from "@core/utils";
import { SelectedLayerId, SelectedLayerIds } from "@folds/shared/types";
import { useCallback, useEffect, useState } from "react";

type ElementOutlineProps = {
  selectedLayerId: SelectedLayerId;
};

function ElementOutline({ selectedLayerId }: ElementOutlineProps) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const calculateBoundingRect = useCallback(() => {
    const element = getElement(selectedLayerId);

    if (element === null) return;

    const box = element.getBoundingClientRect();

    const isIdentical =
      box.width === width &&
      box.height === height &&
      box.top === top &&
      box.left === left;

    if (isIdentical) return;

    setTop(box.top);
    setLeft(box.left);
    setWidth(box.width);
    setHeight(box.height);
  }, [height, left, selectedLayerId, top, width]);

  useEffect(() => {
    const cleanup = setInterval(calculateBoundingRect, 25);
    return () => clearInterval(cleanup);
  }, [calculateBoundingRect]);

  const style: React.CSSProperties = {
    top,
    left,
    width,
    height,
  };

  return (
    <div
      className="pointer-events-none fixed z-40 border border-solid border-accent-9"
      data-testid="element-outline"
      style={style}
    />
  );
}

type Props = {
  selectedLayerIds: SelectedLayerIds;
};

export function ElementOutlines({ selectedLayerIds }: Props) {
  if (selectedLayerIds.length < 2) return null;

  return (
    <>
      {selectedLayerIds.map((selectedLayerId) => (
        <ElementOutline
          selectedLayerId={selectedLayerId}
          key={selectedLayerId}
        />
      ))}
    </>
  );
}
