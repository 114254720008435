import { LoadingPage } from "@common/LoadingPage";
import { useEffect } from "react";

/**
 * This component is used to redirect the user to the Shopify authentication page
 */
export function ExitFrame() {
  useEffect(() => {
    const redirect = async () => {
      const { shop } = window.shopify.config;

      const token = await window.shopify.idToken();

      window.open(`/api/auth?shop=${shop}&token=${token}`, "_self");
    };

    redirect().catch(() => null);
  }, []);

  return <LoadingPage />;
}
