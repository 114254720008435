import { selectVariables } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { UpsertVariableDialogContent } from "@/features/sidebar/components/VariablesSettings/VariableDialogContent";
import { Variable } from "@/features/sidebar/components/VariablesSettings/Variable";
import { DialogOverlay, DialogPortal } from "@lib/radix";

function PlusSign() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H9.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V9.75H3.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25H8.25V3.75C8.25 3.33579 8.58579 3 9 3Z"
        fill="black"
      />
    </svg>
  );
}

export type InitialDialogContent = {
  name: string;
  type: "color" | "image" | "text";
  defaultValue: string;
  variableId: string | null;
};

const initialDialogContentValue: InitialDialogContent = {
  variableId: null,
  defaultValue: "rgba(0, 0, 0, 1)",
  name: "",
  type: "color",
};

export function VariablesSettings() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [initialDialogContent, setInitialDialogContent] =
    useState<InitialDialogContent>(initialDialogContentValue);
  const variables = useAppSelector(selectVariables);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleEdit = (variableId: string) => {
    setIsDialogOpen(true);

    const selectedVariable = variables.find(
      (variable) => variable.id === variableId
    );

    if (!selectedVariable) return;

    setInitialDialogContent({
      name: selectedVariable.name,
      type: selectedVariable.type,
      defaultValue: selectedVariable.defaultValue,
      variableId,
    });
  };

  const handleAddVariable = () => {
    setInitialDialogContent(initialDialogContentValue);
    setIsDialogOpen(true);
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <div className="flex flex-col gap-3 border-t border-gray-5 p-3">
        <div className="flex justify-between">
          <p className="text-sm font-medium text-gray-12">Variables</p>
          <button
            type="button"
            aria-label="Add variable"
            onClick={handleAddVariable}
          >
            <PlusSign />
          </button>
        </div>
        <div className="flex flex-col gap-4">
          <Variable onEdit={handleEdit} />
        </div>
      </div>
      <DialogPortal>
        <DialogOverlay />
        <UpsertVariableDialogContent
          onDialogClose={handleDialogClose}
          initialDialogContent={initialDialogContent}
        />
      </DialogPortal>
    </Dialog.Root>
  );
}
