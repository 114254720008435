import {
  OptionDropdownLayer,
  QuantityPickerLayer,
  TextLayer,
} from '../../types/src/layers/layer-types';

export const generateTypographyStyles = (
  layer: TextLayer | QuantityPickerLayer | OptionDropdownLayer
): React.CSSProperties => {
  const {
    color,
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    textAlign,
    textTransform,
    lineThrough,
    italic,
    underline,
  } = layer;

  const textDecorationArray: string[] = [];

  if (lineThrough === true) textDecorationArray.push('line-through');
  if (underline === true) textDecorationArray.push('underline');

  const textDecoration =
    textDecorationArray.length > 0 ? textDecorationArray.join(' ') : 'none';

  return {
    textAlign,
    textTransform,
    color,
    fontFamily: `${fontFamily}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
    fontSize,
    textDecoration,
    fontStyle: italic ? 'italic' : 'normal',
    fontWeight,
    letterSpacing,
    wordBreak: 'break-word',
    lineHeight: `${lineHeight}%`,
  };
};
