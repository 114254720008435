import { useSaveLayers } from "@/features/editor/api/saveLayers";
import { useFrame } from "@/features/editor/context/FrameContext";
import { checkIfEventTargetIsTextElement, checkIfIsModKey } from "@/utils";
import {
  deleteSelectedLayers,
  groupSelectedLayers,
  moveSelectedLayersDown,
  moveSelectedLayersLeft,
  moveSelectedLayersRight,
  moveSelectedLayersUp,
  redo,
  setCanvasMode,
  undo,
  ungroupLayer,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks/redux";
import { useModalDocument } from "@core/modalStore";
import { store } from "@core/store";
import { checkIfCanGroupLayers } from "@core/utils/checkIfCanGroupLayers";
import { checkIfCanUngroupLayers } from "@core/utils/checkIfCanUngroupLayers";
import { useCallback, useEffect, useRef, useState } from "react";

export const useCanvasHotkeys = () => {
  const dispatch = useAppDispatch();
  const canvasMode = useAppSelector((state) => state.editor.canvasMode);

  const isHoldingSpacebar = useRef(false);

  const { window: iframeWindow } = useFrame();

  const saveLayers = useSaveLayers();
  const modalDocument = useModalDocument();

  const [previousCanvasMode, setPreviousCanvasMode] = useState(canvasMode);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isCurrentlyEditingTextElement = checkIfEventTargetIsTextElement(
        event.target
      );

      if (isCurrentlyEditingTextElement === true) {
        return;
      }

      switch (event.key) {
        case "s": {
          const isModKeyPressed = checkIfIsModKey(event);
          if (!isModKeyPressed) return;

          // Stop the browser from opening the save dialog
          event.preventDefault();

          saveLayers();

          break;
        }
        case "z": {
          const isModKeyPressed = checkIfIsModKey(event);
          if (!isModKeyPressed) return;

          if (event.shiftKey) {
            dispatch(redo());
            break;
          }

          dispatch(undo());

          break;
        }
        case "Backspace": {
          dispatch(deleteSelectedLayers());
          break;
        }
        case "Delete": {
          dispatch(deleteSelectedLayers());
          break;
        }
        case "g": {
          if (!checkIfIsModKey(event)) return;
          event.preventDefault();

          const { layers, selectedLayerIds } = store.getState().editor;

          if (event.shiftKey) {
            const canUngroupLayers = checkIfCanUngroupLayers(
              layers,
              selectedLayerIds
            );

            if (canUngroupLayers === false) return;

            dispatch(ungroupLayer());

            return;
          }

          const canGroupLayers = checkIfCanGroupLayers(
            layers,
            selectedLayerIds
          );

          if (canGroupLayers === false) return;

          dispatch(groupSelectedLayers());

          break;
        }
        case " ": {
          if (isHoldingSpacebar.current) return;

          isHoldingSpacebar.current = true;
          setPreviousCanvasMode(canvasMode);

          dispatch(setCanvasMode("Pan"));
          break;
        }
        case "v": {
          if (checkIfIsModKey(event) === true || event.shiftKey) return;

          dispatch(setCanvasMode("Select"));
          break;
        }
        case "ArrowUp": {
          if (event.shiftKey) {
            dispatch(moveSelectedLayersUp(10));
            return;
          }

          const isRadioElement = (event.target as HTMLElement).role === "radio";
          if (isRadioElement) return;

          dispatch(moveSelectedLayersUp(1));

          break;
        }
        case "ArrowDown": {
          if (event.shiftKey) {
            dispatch(moveSelectedLayersDown(10));
            return;
          }
          const isRadioElement = (event.target as HTMLElement).role === "radio";
          if (isRadioElement) return;

          dispatch(moveSelectedLayersDown(1));
          break;
        }
        case "ArrowRight": {
          if (event.shiftKey) {
            dispatch(moveSelectedLayersRight(10));
            return;
          }

          const isRadioElement = (event.target as HTMLElement).role === "radio";
          if (isRadioElement) return;

          dispatch(moveSelectedLayersRight(1));
          break;
        }
        case "ArrowLeft": {
          if (event.shiftKey) {
            dispatch(moveSelectedLayersLeft(10));
            return;
          }

          const isRadioElement = (event.target as HTMLElement).role === "radio";
          if (isRadioElement) return;

          dispatch(moveSelectedLayersLeft(1));
          break;
        }
      }
    },
    [canvasMode, dispatch, saveLayers]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case " ": {
          isHoldingSpacebar.current = false;
          dispatch(setCanvasMode(previousCanvasMode));
        }
      }
    },
    [dispatch, previousCanvasMode]
  );

  useEffect(() => {
    modalDocument?.addEventListener("keydown", handleKeyDown);
    modalDocument?.addEventListener("keyup", handleKeyUp);

    iframeWindow?.addEventListener("keydown", handleKeyDown);
    iframeWindow?.addEventListener("keyup", handleKeyUp);
    return () => {
      modalDocument?.removeEventListener("keydown", handleKeyDown);
      modalDocument?.removeEventListener("keyup", handleKeyUp);

      iframeWindow?.removeEventListener("keydown", handleKeyDown);
      iframeWindow?.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp, iframeWindow, modalDocument]);
};
