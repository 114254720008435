import { PageInformationWithNumberId } from "@/features/editor/api/getPageInformation";
import { ShopifyPage, ShopifyProduct } from "@folds/shared";
import { useEffect, useState } from "react";

export const usePageInformationCurrentValue = (
  pageInformation: PageInformationWithNumberId | undefined
) => {
  const [pageType, setPageType] = useState<
    "standalone" | "home" | "product" | "not-found" | null
  >(null);
  const [productId, setProductId] = useState<ShopifyProduct["id"] | null>(null);
  const [standalonePageId, setStandalonePageId] = useState<
    ShopifyPage["id"] | null
  >(null);

  useEffect(() => {
    if (typeof pageInformation?.type === "string") {
      setPageType(pageInformation.type);
    }

    if (pageInformation?.type === "product") {
      setProductId(pageInformation.shopifyId);
    }

    if (pageInformation?.type === "standalone") {
      setStandalonePageId(pageInformation.shopifyId);
    }
  }, [pageInformation]);

  return {
    pageType,
    setPageType,
    productId,
    setProductId,
    standalonePageId,
    setStandalonePageId,
  };
};
