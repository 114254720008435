import { modalStore } from "@core/modalStore";
import { Toasts } from "@folds/shared";

const createEmitter = () => {
  const subscriptions = new Map();
  return {
    emit: (value: Toasts) => subscriptions.forEach((fn) => fn(value)),
    subscribe: (fn: (modal: Toasts) => void) => {
      const key = Symbol("key");
      subscriptions.set(key, fn);
      return () => {
        subscriptions.delete(key);
      };
    },
  };
};

const toasts: Toasts = {};

const timeouts: Record<string, NodeJS.Timeout> = {};

const emitter = createEmitter();

const animateToastOut = (id: string) => {
  const modalDocument = modalStore.getDocument();

  if (!modalDocument) return;

  const toastElement = modalDocument.getElementById(`toast-${id}`);

  if (!toastElement) return;

  toastElement.animate(
    [
      { transform: "translateY(0)", opacity: 1 },
      { transform: `translateY(150px)`, opacity: 0 },
    ],
    {
      duration: 125,
      fill: "forwards",
    }
  );
};

export const toastStore = {
  addToast: (toast: Toasts[string]) => {
    toasts[toast.id] = toast;

    emitter.emit(toasts);

    if (timeouts[toast.id]) {
      clearTimeout(timeouts[toast.id]);
    }

    if (toast.state === "loading") {
      const timeout = setTimeout(() => {
        setTimeout(() => {
          delete toasts[toast.id];
          emitter.emit(toasts);
        }, 125);

        animateToastOut(toast.id);
      }, 15000);

      timeouts[toast.id] = timeout;

      return;
    }

    const timeout = setTimeout(() => {
      setTimeout(() => {
        delete toasts[toast.id];
        emitter.emit(toasts);
      }, 125);

      animateToastOut(toast.id);
    }, 3500);

    timeouts[toast.id] = timeout;
  },
  removeToast: (id: string) => {
    delete toasts[id];
  },
  get: () => toasts,
  subscribe: (fn: (toasts: Toasts) => void) => emitter.subscribe(fn),
};
