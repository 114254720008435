import {
  Layer,
  LayerId,
  LayerType,
  Layers,
  PageLayer,
} from "@folds/shared/types";
import invariant from "tiny-invariant";

export const getCurrentLayer = (draft: Layers, layerId: LayerId) => {
  const currentLayer = draft[layerId];

  invariant(currentLayer, `Layer ${layerId} not found`);
  invariant(
    currentLayer.type !== LayerType.Page,
    "Cannot rearrange page layer"
  );

  return currentLayer;
};

export const getParentLayer = (
  draft: Layers,
  currentLayer: Exclude<Layer, PageLayer>
) => {
  const currentParentId = currentLayer.parentId;
  const currentParent = draft[currentLayer.parentId];

  invariant(currentParent, `Parent ${currentParentId} not found`);

  // Parent should always have children
  invariant(
    "children" in currentParent,
    `Parent ${currentParentId} has no children`
  );

  return currentParent;
};

export const removeLayerFromParent = (
  currentParent: ReturnType<typeof getParentLayer>,
  layerIdToFilter: LayerId
) => {
  const childrenWithoutLayerId = currentParent.children.filter(
    (childId) => childId !== layerIdToFilter
  );

  Object.assign(currentParent, { children: childrenWithoutLayerId });
};

export const getNewParent = (draft: Layers, newParentId: LayerId) => {
  // Add id to new parent
  const newParent = draft[newParentId];

  invariant(newParent, `Parent ${newParentId} not found`);

  // Parent should always have children
  invariant("children" in newParent, `Parent ${newParentId} has no children`);

  return newParent;
};

export const addIdToNewParent = (
  newParent: ReturnType<typeof getNewParent>,
  layerId: LayerId,
  newIndex: number | undefined
) => {
  if (newIndex !== undefined) {
    // Check if new index is out of bounds
    if (newIndex < 0 || newIndex > newParent.children.length) {
      throw new Error("Index out of bounds");
    }

    newParent.children.splice(newIndex, 0, layerId);
  } else {
    newParent.children.push(layerId);
  }
};

export const updateCurrentLayerParentId = (
  layer: ReturnType<typeof getCurrentLayer>,
  newParentId: LayerId
) => {
  // Clear state
  if ("state" in layer) {
    Object.assign(layer, { state: null });
  }

  Object.assign(layer, { parentId: newParentId });
};
