import { useCenterViewportPositionToLayer } from "@/features/editor/hooks/useCenterViewportPositionToLayer";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { store } from "@core/store";
import { getAncestorIds, getBreakpointId, getElement } from "@core/utils";
import { LayerId, LayerType, Layers } from "@folds/shared";
import { useEffect } from "react";

const getSelectedBlockId = (layers: Layers, id: LayerId) => {
  const ancestorIds = getAncestorIds(layers, id);

  const blockId = [id, ...ancestorIds].find((currentId) => {
    const currentBlock = layers[currentId];

    if (currentBlock === undefined) return false;

    return currentBlock.type === LayerType.Block;
  });

  return blockId ?? null;
};

const getFirstBlockId = (layers: Layers): LayerId | null => {
  const breakpointId = getBreakpointId(layers);
  if (breakpointId === null) return null;

  const breakpoint = layers[breakpointId];
  if (breakpoint === undefined || breakpoint.type !== LayerType.Breakpoint)
    return null;

  return breakpoint.children[0] ?? null;
};

/**
 * Centers the viewport to the selected block, or the first block if no layers are selected.
 * @link Fixes: https://linear.app/folds/issue/FOL-324/fix-recalculate-viewport-when-changing-breakpoint
 */
export const useCenterViewportPositionToBlockOnBreakpointUpdate = () => {
  const dispatch = useAppDispatch();

  const breakpoint = useAppSelector((state) => state.editor.breakpoint);
  const centerViewportPositionToLayer = useCenterViewportPositionToLayer();

  useEffect(() => {
    const { selectedLayerIds, layers } = store.getState().editor;
    if (Object.keys(layers).length === 0) return;

    const canvasElement = getElement("canvas");
    if (canvasElement === null) return;

    const firstSelectedLayerId = selectedLayerIds[0];

    if (firstSelectedLayerId === undefined) {
      const firstBlockId = getFirstBlockId(layers);
      if (firstBlockId === null) return;

      centerViewportPositionToLayer(firstBlockId);

      return;
    }

    const selectedBlockId = getSelectedBlockId(layers, firstSelectedLayerId);

    if (selectedBlockId === null) {
      const firstBlockId = getFirstBlockId(layers);
      if (firstBlockId === null) return;

      centerViewportPositionToLayer(firstBlockId);

      return;
    }

    centerViewportPositionToLayer(selectedBlockId);
  }, [breakpoint, centerViewportPositionToLayer, dispatch]);
};
