export function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="min-h-5 min-w-5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C5.85787 2.5 2.5 5.85787 2.5 10C2.5 14.1421 5.85787 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85787 14.1421 2.5 10 2.5ZM0.833336 10C0.833336 4.93739 4.93739 0.833333 10 0.833333C15.0626 0.833333 19.1667 4.93739 19.1667 10C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.93739 19.1667 0.833336 15.0626 0.833336 10ZM14.3393 6.91075C14.6647 7.23618 14.6647 7.76382 14.3393 8.08926L9.33926 13.0893C9.01382 13.4147 8.48619 13.4147 8.16075 13.0893L5.66075 10.5893C5.33531 10.2638 5.33531 9.73618 5.66075 9.41075C5.98618 9.08531 6.51382 9.08531 6.83926 9.41075L8.75 11.3215L13.1608 6.91075C13.4862 6.58531 14.0138 6.58531 14.3393 6.91075Z"
        className="fill-green-9"
      />
    </svg>
  );
}

export function LoadingIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin"
    >
      <path
        className="fill-blue-5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00001 2.25C8.11358 2.25 7.23584 2.42459 6.41689 2.76381C5.59794 3.10303 4.85383 3.60023 4.22703 4.22703C3.60024 4.85382 3.10303 5.59794 2.76381 6.41688C2.42459 7.23583 2.25 8.11357 2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23584 15.2362 6.41689C14.897 5.59794 14.3998 4.85383 13.773 4.22703C13.1462 3.60024 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88643 2.25 9.00001 2.25ZM18 9.00001C18 10.1819 17.7672 11.3522 17.3149 12.4442C16.8626 13.5361 16.1997 14.5282 15.364 15.364C14.5282 16.1997 13.5361 16.8626 12.4442 17.3149C11.3522 17.7672 10.1819 18 9 18C7.8181 18 6.64778 17.7672 5.55585 17.3149C4.46392 16.8626 3.47177 16.1997 2.63604 15.364C1.80031 14.5282 1.13738 13.5361 0.685083 12.4441C0.232791 11.3522 -4.02331e-07 10.1819 0 9C4.02332e-07 7.8181 0.232793 6.64778 0.685086 5.55585C1.13738 4.46392 1.80031 3.47176 2.63604 2.63604C3.47177 1.80031 4.46392 1.13737 5.55585 0.685083C6.64779 0.232791 7.81811 -5.36441e-07 9.00001 0C10.1819 6.03498e-07 11.3522 0.232793 12.4442 0.685086C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.81811 18 9.00001Z"
      />
      <path
        className="fill-blue-11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 1.125C7.875 0.50368 8.37868 0 9 0C10.1819 0 11.3522 0.232792 12.4442 0.685084C13.5361 1.13738 14.5282 1.80031 15.364 2.63604C16.1997 3.47177 16.8626 4.46392 17.3149 5.55585C17.7672 6.64778 18 7.81811 18 9C18 9.62132 17.4963 10.125 16.875 10.125C16.2537 10.125 15.75 9.62132 15.75 9C15.75 8.11358 15.5754 7.23584 15.2362 6.41689C14.897 5.59794 14.3998 4.85383 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.37868 2.25 7.875 1.74632 7.875 1.125Z"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="min-h-5 min-w-5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C5.85787 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85787 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5ZM0.833336 10C0.833336 4.93739 4.93739 0.833328 10 0.833328C15.0626 0.833328 19.1667 4.93739 19.1667 10C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.93739 19.1667 0.833336 15.0626 0.833336 10ZM9.16667 6.66666C9.16667 6.20642 9.53977 5.83333 10 5.83333H10.0083C10.4686 5.83333 10.8417 6.20642 10.8417 6.66666C10.8417 7.1269 10.4686 7.5 10.0083 7.5H10C9.53977 7.5 9.16667 7.1269 9.16667 6.66666ZM10 9.16666C10.4602 9.16666 10.8333 9.53976 10.8333 10V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10C9.16667 9.53976 9.53977 9.16666 10 9.16666Z"
        className="fill-blue-9"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="min-h-5 min-w-5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C5.85787 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85787 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5ZM0.833336 10C0.833336 4.93739 4.93739 0.833328 10 0.833328C15.0626 0.833328 19.1667 4.93739 19.1667 10C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.93739 19.1667 0.833336 15.0626 0.833336 10ZM9.16667 6.66666C9.16667 6.20642 9.53977 5.83333 10 5.83333H10.0083C10.4686 5.83333 10.8417 6.20642 10.8417 6.66666C10.8417 7.1269 10.4686 7.5 10.0083 7.5H10C9.53977 7.5 9.16667 7.1269 9.16667 6.66666ZM10 9.16666C10.4602 9.16666 10.8333 9.53976 10.8333 10V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10C9.16667 9.53976 9.53977 9.16666 10 9.16666Z"
        className="fill-red-9"
      />
    </svg>
  );
}
