import { changeBreakpoint } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { twMerge } from "tailwind-merge";

function DesktopBreakpointIcon() {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      className="fill-inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8788 17.1819H5.78789V15.3637H7.60607V14.4546H2.15153C1.14737 14.4546 0.333344 13.6406 0.333344 12.6364V2.63642C0.333344 1.63227 1.14737 0.818237 2.15153 0.818237H18.5152C19.5193 0.818237 20.3333 1.63227 20.3333 2.63642V12.6364C20.3333 13.6406 19.5193 14.4546 18.5152 14.4546H13.0606V15.3637H14.8788V17.1819ZM2.15153 2.63642V12.6364H18.5152V2.63642H2.15153Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function TabletBreakpointIcon() {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-inherit"
    >
      <path
        d="M13.43 17.8571H1.71445C0.905656 17.8571 0.25 17.1361 0.25 16.2467V1.75321C0.25 0.863818 0.905656 0.142822 1.71445 0.142822H13.43C14.2388 0.142822 14.8945 0.863818 14.8945 1.75321V16.2467C14.8945 17.1361 14.2388 17.8571 13.43 17.8571ZM1.71445 1.75321V16.2467H13.43V1.75321H1.71445ZM7.57224 15.4415C7.16784 15.4415 6.84002 15.081 6.84002 14.6363C6.84002 14.1916 7.16784 13.8311 7.57224 13.8311C7.97664 13.8311 8.30447 14.1916 8.30447 14.6363C8.30447 15.081 7.97664 15.4415 7.57224 15.4415Z"
        className="fill-inherit"
      />
    </svg>
  );
}

function MobileBreakpointIcon() {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      className="fill-inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55228 17.6651H1.78085C0.92244 17.6651 0.226562 16.9693 0.226562 16.1109V2.12228C0.226562 1.26387 0.92244 0.567993 1.78085 0.567993H9.55228C10.4107 0.567993 11.1066 1.26387 11.1066 2.12228V16.1109C11.1066 16.9693 10.4107 17.6651 9.55228 17.6651ZM1.78085 2.12228V16.1109H9.55228V2.12228H1.78085ZM5.66656 15.3337C5.23736 15.3337 4.88942 14.9858 4.88942 14.5566C4.88942 14.1274 5.23736 13.7794 5.66656 13.7794C6.09577 13.7794 6.44371 14.1274 6.44371 14.5566C6.44371 14.9858 6.09577 15.3337 5.66656 15.3337Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function BreakpointsToolbar() {
  const dispatch = useAppDispatch();

  const breakpoint = useAppSelector((state) => state.editor.breakpoint);

  const handleSelectDesktopBreakpoint = () => {
    dispatch(changeBreakpoint("desktop"));
  };

  const handleSelectTabletBreakpoint = () => {
    dispatch(changeBreakpoint("tablet"));
  };

  const handleSelectMobileBreakpoint = () => {
    dispatch(changeBreakpoint("mobile"));
  };

  // We dont use a <MultipleOptions /> because of: https://linear.app/folds/issue/FOL-331/fix-only-change-breakpoint-when-pressing-breakpoint-button
  return (
    <div className="pointer-events-auto flex h-11 items-stretch rounded-sm border border-gray-6 bg-gray-2 p-0.5">
      <button
        type="button"
        aria-label="Desktop breakpoint"
        onClick={handleSelectDesktopBreakpoint}
        className={twMerge(
          "flex w-12 flex-1 items-center justify-center rounded-sm fill-gray-11 text-gray-11",
          breakpoint === "desktop" && "bg-gray-5 fill-gray-12 text-gray-12"
        )}
      >
        <DesktopBreakpointIcon />
      </button>
      <button
        type="button"
        aria-label="Tablet breakpoint"
        onClick={handleSelectTabletBreakpoint}
        className={twMerge(
          "flex w-12 flex-1 items-center justify-center rounded-sm fill-gray-11 text-gray-11",
          breakpoint === "tablet" && "bg-gray-5 fill-gray-12 text-gray-12"
        )}
      >
        <TabletBreakpointIcon />
      </button>
      <button
        type="button"
        aria-label="Mobile breakpoint"
        onClick={handleSelectMobileBreakpoint}
        className={twMerge(
          "flex w-12 flex-1 items-center justify-center rounded-sm fill-gray-11 text-gray-11",
          breakpoint === "mobile" && "bg-gray-5 fill-gray-12 text-gray-12"
        )}
      >
        <MobileBreakpointIcon />
      </button>
    </div>
  );
}
