import {
  addCarouselSlide,
  selectAnySelectedSplideCarouselId,
  selectSelectedCarouselId,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getElement } from "@core/utils";

export function AddSlideButton() {
  const dispatch = useAppDispatch();
  const selectedSliderId = useAppSelector(selectSelectedCarouselId);

  if (typeof selectedSliderId !== "string") return null;

  const handleClick = () => {
    dispatch(addCarouselSlide(selectedSliderId));
  };

  return (
    <div className="fixed left-[326px] z-50 w-[calc(100vw-326px)]">
      <button
        type="button"
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
        className="absolute left-1/2 top-2 h-10 w-32 -translate-x-full rounded bg-gray-12 text-xs text-gray-1"
      >
        Add slide
      </button>
    </div>
  );
}

export function ChangeSlideButtons() {
  const selectedSliderId = useAppSelector(selectAnySelectedSplideCarouselId);

  if (typeof selectedSliderId !== "string") return null;

  const handleClickNextSlide = () => {
    const element = getElement(selectedSliderId);

    if (element === null || !("swiper" in element)) return;

    (element as { swiper: { slideNext: () => void } }).swiper.slideNext();
  };

  const handleClickPreviousSlide = () => {
    const element = getElement(selectedSliderId);

    if (element === null || !("swiper" in element)) return;

    (element as { swiper: { slidePrev: () => void } }).swiper.slidePrev();
  };

  return (
    <div className="fixed bottom-3 left-[338px] flex gap-2">
      <button
        type="button"
        onClick={handleClickPreviousSlide}
        onMouseDown={(event) => event.stopPropagation()}
        className="h-10 w-32 rounded bg-gray-12 text-xs text-gray-1"
      >
        Previous slide
      </button>
      <button
        type="button"
        onClick={handleClickNextSlide}
        onMouseDown={(event) => event.stopPropagation()}
        className="h-10 w-32 rounded bg-gray-12 text-xs text-gray-1"
      >
        Next slide
      </button>
    </div>
  );
}
