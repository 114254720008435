import {
  HomePage,
  NotFoundPage,
  ProductPage,
  StandalonePage,
} from "@folds/shared/typebox";
import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR from "swr";

const compiledPagesSchema = TypeCompiler.Compile(
  Type.Array(
    Type.Union([
      Type.Intersect([HomePage, Type.Object({ type: Type.Literal("home") })]),
      Type.Intersect([
        ProductPage,
        Type.Object({ type: Type.Literal("product") }),
      ]),
      Type.Intersect([
        StandalonePage,
        Type.Object({ type: Type.Literal("standalone") }),
      ]),
      Type.Intersect([
        NotFoundPage,
        Type.Object({ type: Type.Literal("not-found") }),
      ]),
    ])
  )
);

export const usePages = () => {
  const axios = useAxios();

  const fetchPages = async (key: string) => {
    const { data } = await axios.get(key);

    return compiledPagesSchema.Decode(data);
  };

  return useSWR("/pages", fetchPages);
};
