import { PageName } from "@/features/sidebar/components/PageName";
import { useShopifyModal } from "@/features/dashboard/context/ShopifyModalContext";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";

import * as i from "../Sidebar.icons";

export function SidebarPageInformation() {
  const shopfiyModal = useShopifyModal();
  const isInsidePlayground = useIsInsidePlayground();

  const closeModal = async () => {
    if (isInsidePlayground) {
      window.open("https://folds.app", "_top");
      return;
    }

    await shopfiyModal?.hide();
  };

  return (
    <div className="absolute top-0 z-10 flex h-12 w-[326px] gap-4 border-b border-r border-solid border-gray-6 bg-gray-2 p-3 align-middle text-base">
      <button
        type="button"
        onClick={closeModal}
        aria-label="Return to dashboard"
      >
        <i.BackChevron />
      </button>
      <div className="h-6 w-px bg-gray-9" />
      <PageName />
    </div>
  );
}
