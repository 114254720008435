import { LoadingPage } from "@common/LoadingPage";
import { useEffect } from "react";

export function NotFound() {
  useEffect(() => {
    window.location.href = "/";
  }, []);

  return <LoadingPage />;
}
