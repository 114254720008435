import React from 'react';
import { parseVariable } from './parseVariable';
import {
  BlockLayer,
  ButtonLayer,
  OptionSelectorItemLayer,
  RectangleLayer,
  CarouselSlideLayer,
  QuantityPickerLayer,
} from '../../types';

const parseImageValue = (value: string | null) => {
  if (value === null) return 'url(/image-placeholder.svg)';

  // If the images have spaces in the name, we need to replace them with %20 or else they won't render properly and will appear broken
  const parsedValue = value.replace(/ /g, '%20');

  if (value.startsWith('--')) return `var(${value})`;

  return `url(${parsedValue})`;
};

export const generateFillStyles = (
  layer:
    | RectangleLayer
    | ButtonLayer
    | BlockLayer
    | OptionSelectorItemLayer
    | CarouselSlideLayer
    | QuantityPickerLayer
): React.CSSProperties => {
  const {
    backgroundColor,
    backgroundImage,
    backgroundSize,
    fillType,
    backgroundImageOverlay,
    backgroundPositionX,
    backgroundPositionY,
  } = layer;

  let fillProperties = {
    backgroundSize: 'contain',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    backgroundPositionX: '0px',
    backgroundPositionY: '0px',
  };

  switch (fillType) {
    case 'color': {
      const parsedBackgroundColor = parseVariable(backgroundColor);

      fillProperties = {
        ...fillProperties,
        backgroundColor: parsedBackgroundColor,
      };

      break;
    }
    case 'image': {
      const parsedBackgroundImage = parseImageValue(backgroundImage);

      const backgroundImageValue =
        typeof backgroundImageOverlay === 'string'
          ? `linear-gradient(${backgroundImageOverlay}, ${backgroundImageOverlay}), ${parsedBackgroundImage}`
          : parsedBackgroundImage;

      const backgroundPositionXValue =
        typeof backgroundImageOverlay === 'string'
          ? `0px, ${backgroundPositionX}px`
          : `${backgroundPositionX}px`;

      const backgroundPositionYValue =
        typeof backgroundImageOverlay === 'string'
          ? `0px, ${backgroundPositionY}px`
          : `${backgroundPositionY}px`;

      fillProperties = {
        ...fillProperties,
        backgroundSize,
        backgroundImage: backgroundImageValue,
        backgroundPositionX: backgroundPositionXValue,
        backgroundPositionY: backgroundPositionYValue,
      };

      break;
    }
    default:
  }

  return {
    backgroundImage: fillProperties.backgroundImage,
    backgroundColor: fillProperties.backgroundColor,
    backgroundSize: fillProperties.backgroundSize,
    backgroundPositionX: fillProperties.backgroundPositionX,
    backgroundPositionY: fillProperties.backgroundPositionY,
    backgroundRepeat: 'no-repeat',
  };
};
