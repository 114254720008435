import { assignLayerProperties } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/assignLayerProperties";
import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import { getParentLayer } from "@core/utils/getParentLayer";
import { Layer, LayerId, Layers } from "@folds/shared";

const getLayerIdsToAssignHighestZIndex = ({
  layers,
  layerId,
}: {
  layers: Layers;
  layerId: LayerId;
}) => {
  const layer = layers[layerId];
  if (!layer) return [];

  if (checkIfLayerIsIntrinsicallyPositioned(layer)) {
    return layer.children;
  }

  return [layerId];
};

export const bringLayerToFrontAction = (
  state: DraftEditorState,
  layerId: LayerId
) => {
  const layer = state.layers[layerId];

  if (layer === undefined) return;

  const parentLayer = getParentLayer(state.layers, layerId);
  if (parentLayer === null) return;

  const highestZIndex = getHighestZIndex(state.layers, parentLayer.id);
  const newHighestZIndex = highestZIndex + 1;

  const layerIdsToAssignHighestZIndex = getLayerIdsToAssignHighestZIndex({
    layerId,
    layers: state.layers,
  });

  const layersToAssignHighestZIndex = layerIdsToAssignHighestZIndex
    .map((id) => state.layers[id])
    .filter((currentLayer) => currentLayer !== undefined) as Layer[];

  layersToAssignHighestZIndex.forEach((currentLayer) => {
    assignLayerProperties({
      breakpoint: state.breakpoint,
      layer: currentLayer,
      key: "zIndex",
      value: newHighestZIndex,
      layers: state.layers,
    });
  });
};
