import axios, { AxiosError } from "axios";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";
import { mockRequest } from "@lib/axios/mocking/mockRequest";
import { checkIfIsIntegrationTest } from "@/utils/checkIfIsIntegrationTest";

const isIntegrationTest = checkIfIsIntegrationTest();

/**
 * Creates an authenticated axios instance
 */
export const useAxios = () => {
  const axiosInstance = axios.create({
    // The mock.folds.app doesn't actually exist, but it prevents the request from being sent to an actual server
    baseURL: isIntegrationTest ? "https://mock.folds.app/api" : "/api",
    withCredentials: true,
  });

  const isInsidePlayground = useIsInsidePlayground();

  axiosInstance.defaults.adapter = (config) =>
    mockRequest(config, isInsidePlayground);

  axiosInstance.interceptors.request.use(
    async (interceptorConfig) => {
      if (isIntegrationTest || isInsidePlayground) {
        return interceptorConfig;
      }

      const token = await window.shopify.idToken();

      interceptorConfig.headers.Authorization = `Bearer ${token}`;
      interceptorConfig.headers.shop = window.shopify.config.shop;

      return interceptorConfig;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error instanceof AxiosError) {
        const responseHeaders = error.response?.headers;

        if (responseHeaders && typeof responseHeaders.get === "function") {
          const verifyRequest = responseHeaders.get(
            "Folds-Verify-Request-Failure"
          );

          const redirectURL = responseHeaders.get("Folds-Redirect-URL");

          if (verifyRequest === "1" && typeof redirectURL === "string") {
            window.location.href = redirectURL;
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
