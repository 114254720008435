import { ErrorBoundary } from "@sentry/react";

function Fallback() {
  return <p>Something went wrong. Please try again or contact support.</p>;
}

export function SentryErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ErrorBoundary showDialog fallback={Fallback}>
      {children}
    </ErrorBoundary>
  );
}
