import { useShopifyPassword } from "@/features/editor/api/getShopifyPassword";
import { toast } from "@core/toast";
import { useAxios } from "@lib/axios";

export const useSaveShopifyPassword = () => {
  const { mutate: refetchShopifyPassword } = useShopifyPassword();
  const axios = useAxios();

  const saveShopifyPassword = async (password: string) => {
    try {
      toast.loading("Saving password...", { id: "shopify-password" });
      await axios.put("/shopify/password", { password });
      toast.success("Password saved", { id: "shopify-password" });
      await refetchShopifyPassword();
    } catch (error) {
      toast.error("Failed to save password", { id: "shopify-password" });
    }
  };

  return saveShopifyPassword;
};
