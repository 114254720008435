import { ONE_COLUMN_ELEMENT_WIDTH } from "@/features/sidebar/SidebarElements";
import { useBlocks } from "@/features/sidebar/api/getBlocks";
import { useTemplates } from "@/features/sidebar/api/getTemplates";
import {
  MultipleOptions,
  Options,
} from "@/features/sidebar/components/MultipleOptions";
import { PreviewLayers } from "@/features/sidebar/components/PreviewLayers";
import { setExpandedBlockCollection } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getBreakpointId } from "@core/utils";
import {
  DESKTOP_BREAKPOINT_WIDTH,
  LayerType,
  Layers,
  ROOT_LAYER_ID,
  getOverridenLayer,
  BlockCollection,
} from "@folds/shared";
import { useState } from "react";

const tabOptions: Options = [
  {
    icon: <p className="text-xs">Blocks</p>,
    value: "blocks",
  },
  {
    icon: <p className="text-xs">Templates</p>,
    value: "templates",
  },
];

export function PreviewBlock({
  block,
  breakpoint = "desktop",
  ...previewLayersProps
}: {
  block: Exclude<ReturnType<typeof useBlocks>["data"], undefined>[number];
} & Partial<React.ComponentProps<typeof PreviewLayers>>) {
  const blockLayer = getOverridenLayer({
    id: ROOT_LAYER_ID,
    breakpoint,
    layers: block.layers,
  });

  if (blockLayer === null || blockLayer.type !== LayerType.Block) return null;

  return (
    <PreviewLayers
      type="block"
      // eslint-disable-next-line no-underscore-dangle
      key={block._id}
      breakpoint={breakpoint}
      products={block.products}
      description={block.description}
      elementHeight={blockLayer.height}
      elementWidth={DESKTOP_BREAKPOINT_WIDTH}
      layers={block.layers}
      targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...previewLayersProps}
    />
  );
}

function ChevronLeft() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4716 3.52827C10.7319 3.78862 10.7319 4.21073 10.4716 4.47108L6.94297 7.99967L10.4716 11.5283C10.7319 11.7886 10.7319 12.2107 10.4716 12.4711C10.2112 12.7314 9.78911 12.7314 9.52876 12.4711L5.52876 8.47108C5.26841 8.21073 5.26841 7.78862 5.52876 7.52827L9.52876 3.52827C9.78911 3.26792 10.2112 3.26792 10.4716 3.52827Z"
        fill="black"
      />
    </svg>
  );
}

function ExpandedCollection({ collection }: { collection: BlockCollection }) {
  const dispatch = useAppDispatch();

  const isSwiperScriptLoaded = useAppSelector(
    (state) => state.editor.isSwiperScriptLoaded
  );

  const {
    data: blocks,
    error,
    isLoading,
  } = useBlocks({ limit: 100, collection });

  const handleClearExpandedCollection = () => {
    dispatch(setExpandedBlockCollection(null));
  };

  if (isLoading)
    return (
      <>
        <button type="button" className="flex items-center gap-[6px] text-sm">
          <ChevronLeft />
          Back
        </button>
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
      </>
    );

  if (error !== undefined || blocks === undefined)
    return <p className="text-sm">There was an issue loading the blocks</p>;

  return (
    <div className="flex flex-col gap-3">
      <button
        type="button"
        className="flex items-center gap-[6px] text-sm"
        onClick={handleClearExpandedCollection}
      >
        <ChevronLeft />
        Back
      </button>
      <div className="flex flex-col gap-1">
        {blocks.map((block) => (
          // Hacky way to force a re-render when the swiper script is loaded: https://linear.app/folds/issue/FOL-495/fix-swiper-not-loading-properly-before-rendering
          <PreviewBlock
            // eslint-disable-next-line no-underscore-dangle
            key={`${block._id}-${isSwiperScriptLoaded}`}
            block={block}
          />
        ))}
      </div>
    </div>
  );
}

function CollectionPreview({
  name,
  collection,
}: {
  name: string;
  collection: BlockCollection;
}) {
  const dispatch = useAppDispatch();

  const isSwiperScriptLoaded = useAppSelector(
    (state) => state.editor.isSwiperScriptLoaded
  );

  const {
    data: blocks,
    error,
    isLoading,
  } = useBlocks({ limit: 4, collection });

  const handleExpandCollection = () => {
    dispatch(setExpandedBlockCollection(collection));
  };

  if (isLoading)
    return (
      <>
        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-12">{name}</p>
          <button
            type="button"
            className="text-xs text-gray-11"
            onClick={handleExpandCollection}
          >
            See more
          </button>
        </div>
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
      </>
    );

  if (error !== undefined || blocks === undefined)
    return <p className="text-sm">There was an issue loading the blocks</p>;

  return (
    <>
      <div className="flex  items-center justify-between">
        <p className="text-sm text-gray-12">{name}</p>
        <button
          type="button"
          className="text-xs text-gray-11"
          onClick={handleExpandCollection}
        >
          See more
        </button>
      </div>
      <div className="flex flex-col gap-1">
        {blocks.map((block) => (
          // Hacky way to force a re-render when the swiper script is loaded: https://linear.app/folds/issue/FOL-495/fix-swiper-not-loading-properly-before-rendering
          <PreviewBlock
            // eslint-disable-next-line no-underscore-dangle
            key={`${block._id}-${isSwiperScriptLoaded}`}
            block={block}
          />
        ))}
      </div>
    </>
  );
}

function NoSelectedBlockCollection() {
  return (
    <div className="flex flex-col gap-3">
      <CollectionPreview collection="hero" name="Hero" />
      <CollectionPreview collection="product" name="Product" />
      <CollectionPreview
        collection="product-carousel"
        name="Product carousel"
      />
      <CollectionPreview collection="features" name="Features" />
      <CollectionPreview collection="categories" name="Categories" />
      <CollectionPreview collection="about-us" name="About us" />
      <CollectionPreview collection="as-featured-in" name="As featured in" />
      <CollectionPreview collection="frequently-asked-questions" name="FAQ" />
      <CollectionPreview collection="testimonials" name="Testimonials" />
    </div>
  );
}

function Blocks() {
  const expandedBlockCollection = useAppSelector(
    (state) => state.editor.expandedBlockCollection
  );

  if (expandedBlockCollection === null) {
    return <NoSelectedBlockCollection />;
  }

  return <ExpandedCollection collection={expandedBlockCollection} />;
}

const getTemplateHeight = (layers: Layers) => {
  const breakpointId = getBreakpointId(layers);
  if (breakpointId === null) return null;

  const breakpoint = layers[breakpointId];
  if (breakpoint === undefined || breakpoint.type !== LayerType.Breakpoint)
    return null;

  const firstBlockId = breakpoint.children[0];
  if (firstBlockId === undefined) return null;

  const firstBlock = layers[firstBlockId];
  if (firstBlock === undefined || firstBlock.type !== LayerType.Block)
    return null;

  return firstBlock.height;
};

function SearchTemplatesResults() {
  const { data: templates, error, isLoading } = useTemplates();

  if (isLoading)
    return (
      <>
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
        <div className="h-36 animate-pulse bg-gray-3" />
      </>
    );

  if (error !== undefined || templates === undefined)
    return <p className="text-sm">There was an issue loading the templates</p>;

  return templates.map((template) => {
    const templateHeight = getTemplateHeight(template.layers);
    if (templateHeight === null) return null;

    return (
      <PreviewLayers
        type="template"
        // eslint-disable-next-line no-underscore-dangle
        key={template._id}
        products={template.products}
        description={template.description}
        elementHeight={templateHeight}
        elementWidth={DESKTOP_BREAKPOINT_WIDTH}
        layers={template.layers}
        targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
      />
    );
  });
}

export function BlocksAndTemplates() {
  const [tab, setTab] = useState<"templates" | "blocks">("blocks");

  const handleTabValueChange = (value: string) => {
    if (value !== "templates" && value !== "blocks") return;

    setTab(value);
  };

  return (
    <>
      <div className="border-b border-gray-6 px-3 py-2">
        <MultipleOptions
          rootClassName="h-9"
          options={tabOptions}
          value={tab}
          onValueChange={handleTabValueChange}
        />
      </div>

      <div className="flex flex-col gap-1 p-3">
        {tab === "blocks" && <Blocks />}
        {tab === "templates" && <SearchTemplatesResults />}
      </div>
    </>
  );
}
