import {
  selectFirstSelectedLayerId,
  togglePlainTextBold,
  togglePlainTextItalic,
  togglePlainTextStrikethrough,
  togglePlainTextUnderline,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

import { checkIfIsModKey } from "@/utils";
import { useCallback, useEffect } from "react";
import { useFrame } from "@/features/editor/context/FrameContext";
import { modalStore } from "@core/modalStore";

export const useTypographyHotkeys = () => {
  const dispatch = useAppDispatch();
  const { document: iFrameWindow } = useFrame();
  const firstSelectedLayerId = useAppSelector(selectFirstSelectedLayerId);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (typeof firstSelectedLayerId !== "string") return;

      switch (event.key) {
        case "b": {
          if (!checkIfIsModKey(event)) return;
          event.preventDefault();

          dispatch(togglePlainTextBold(firstSelectedLayerId));

          break;
        }
        case "i": {
          if (!checkIfIsModKey(event)) return;
          event.preventDefault();

          dispatch(togglePlainTextItalic(firstSelectedLayerId));

          break;
        }
        case "u": {
          if (!checkIfIsModKey(event)) return;
          event.preventDefault();

          dispatch(togglePlainTextUnderline(firstSelectedLayerId));

          break;
        }
        case "x": {
          if (!checkIfIsModKey(event) || !event.shiftKey) return;
          event.preventDefault();

          dispatch(togglePlainTextStrikethrough(firstSelectedLayerId));

          break;
        }
      }
    },
    [dispatch, firstSelectedLayerId]
  );

  useEffect(() => {
    iFrameWindow?.addEventListener("keydown", handleKeyDown);

    const modalDocument = modalStore.getDocument();
    modalDocument?.addEventListener("keydown", handleKeyDown);

    return () => {
      iFrameWindow?.removeEventListener("keydown", handleKeyDown);
      modalDocument?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, iFrameWindow]);
};
