import { recalculateDraggingGuideStopLines } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { useEffect } from "react";

/**
 * Since guide stop lines are calculated based on the position of the elements in the dom, we need to recalculate them as a side effect when duplicating elements.
 * If we don't reclculate the guide stop lines, the user won't be able to snap to the original layer it was duplicated from.
 */
export const useRecalculateGuideStopLinesAfterDuplicating = () => {
  const dispatch = useAppDispatch();

  const duplicatingLayerIds = useAppSelector(
    (state) => state.editor.duplicatingLayerIds
  );

  useEffect(() => {
    if (duplicatingLayerIds !== null) return;

    // Doesn't work properly without a delay. We should find a better way to do this in the future.
    setTimeout(() => {
      dispatch(recalculateDraggingGuideStopLines());
    }, 250);
  }, [dispatch, duplicatingLayerIds]);
};
