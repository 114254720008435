import { useSelectedProductTitle } from "@/features/sidebar/components/OptionSelector";
import {
  selectFirstSelectedLayer,
  selectProductUnavailableId,
  setLayerProperties,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import { createSelector } from "@reduxjs/toolkit";
import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import {
  ShopifyProduct,
  compiledButtonStateSchema,
  compiledOptionSelectorItemState,
  compiledRectangleStateSchema,
  compiledTextStateSchema,
} from "@folds/shared/types";
import { toast } from "@core/toast";
import { PopoverContent, PopoverPortal } from "@lib/radix";

const parseState = (state: string | null) => {
  if (state === null) return "default";

  return state;
};

const selectParentHasHoverChildren = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const id = selectedLayerIds[0];

    if (id === undefined) return false;

    const layer = layers[id];

    if (!layer || typeof layer.parentId !== "string") return false;

    const parentLayer = layers[layer.parentId];

    if (!parentLayer) return false;

    return "hoverChildren" in parentLayer;
  }
);

const selectParentHasCheckedChildren = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const id = selectedLayerIds[0];

    if (id === undefined) return false;

    const layer = layers[id];

    if (!layer || typeof layer.parentId !== "string") return false;

    const parentLayer = layers[layer.parentId];

    if (!parentLayer) return false;

    return "checkedChildren" in parentLayer;
  }
);

const selectParentHasOptionUnavailableChildren = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const id = selectedLayerIds[0];

    if (id === undefined) return false;

    const layer = layers[id];

    if (!layer || typeof layer.parentId !== "string") return false;

    const parentLayer = layers[layer.parentId];

    if (!parentLayer) return false;

    return "optionUnavailableChildren" in parentLayer;
  }
);

function UnavailableProductSelector() {
  const dispatch = useAppDispatch();
  const productUnavailableId = useAppSelector(selectProductUnavailableId);
  const productTitle = useSelectedProductTitle(productUnavailableId);
  const [productSelectIsOpen, setProductSelectIsOpen] = useState(false);

  const handleSelectProduct = ({
    productHandle,
    productId,
  }: {
    productId: ShopifyProduct["id"];
    productHandle: string;
  }) => {
    dispatch(
      setSelectedLayerProperties({
        productUnavailableId: productId,
        productUnavailableHandle: productHandle,
      })
    );
    setProductSelectIsOpen(false);
  };

  return (
    <Popover.Root
      open={productSelectIsOpen}
      onOpenChange={setProductSelectIsOpen}
    >
      <Popover.Trigger className="flex h-8 items-center truncate rounded-sm border border-gray-6 bg-gray-2 p-2 text-left text-xs text-gray-12">
        {productTitle}
      </Popover.Trigger>
      <PopoverPortal>
        <PopoverContent align="center" side="right" sideOffset={21}>
          <ProductSelect onSelect={handleSelectProduct} />
        </PopoverContent>
      </PopoverPortal>
    </Popover.Root>
  );
}

export function StateSelector() {
  const dispatch = useAppDispatch();

  const layer = useAppSelector(selectFirstSelectedLayer);

  const parentHasHoverChildren = useAppSelector(selectParentHasHoverChildren);
  const parentHasOptionUnavailableChildren = useAppSelector(
    selectParentHasOptionUnavailableChildren
  );
  const parentHasCheckedChildren = useAppSelector(
    selectParentHasCheckedChildren
  );

  if (!layer || !("state" in layer)) return null;

  switch (layer.type) {
    case "button": {
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "default") {
          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: null },
            })
          );
          return;
        }

        try {
          const result = compiledButtonStateSchema.Decode(event.target.value);

          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: result },
            })
          );
        } catch (error) {
          toast.error("There was an issue updating the state");
        }
      };

      return (
        <div className="flex flex-col gap-2 border-b border-gray-6 p-3">
          <select
            onChange={handleChange}
            value={parseState(layer.state)}
            aria-label="Select state"
            className="flex h-8 w-full rounded-sm border-r-8 border-transparent bg-gray-1 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
          >
            <option value="default">Default</option>
            <option value="hover">Hover</option>
            <option value="product-unavailable">Product unavailable</option>
          </select>
          {layer.state === "product-unavailable" && (
            <UnavailableProductSelector />
          )}
        </div>
      );
    }
    case "text": {
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "default") {
          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: null },
            })
          );

          return;
        }

        try {
          const result = compiledTextStateSchema.Decode(event.target.value);

          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: result },
            })
          );
        } catch (error) {
          toast.error("There was an issue updating the state");
        }
      };

      return (
        <div className="flex flex-col gap-2 border-b border-gray-6 p-3">
          <select
            onChange={handleChange}
            value={parseState(layer.state)}
            aria-label="Select state"
            className="flex h-8 w-full rounded-sm border-r-8 border-transparent bg-gray-1 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
          >
            <option value="default">Default</option>
            <option value="hover">Hover</option>
            <option value="product-unavailable">Product unavailable</option>
            {parentHasCheckedChildren && (
              <option value="parent-checked">Parent checked</option>
            )}
            {parentHasHoverChildren && (
              <option value="parent-hover">Parent hover</option>
            )}
            {parentHasOptionUnavailableChildren && (
              <option value="parent-option-unavailable">
                Option unavailable
              </option>
            )}
          </select>
          {layer.state === "product-unavailable" && (
            <UnavailableProductSelector />
          )}
        </div>
      );
    }
    case "rectangle": {
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "default") {
          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: null },
            })
          );

          return;
        }

        try {
          const result = compiledRectangleStateSchema.Decode(
            event.target.value
          );

          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: result },
            })
          );
        } catch (error) {
          toast.error("There was an issue updating the state");
        }
      };

      return (
        <div className="flex flex-col gap-2 border-b border-gray-6 p-3">
          <select
            onChange={handleChange}
            value={parseState(layer.state)}
            aria-label="Select state"
            className="flex h-8 w-full rounded-sm border-r-8 border-transparent bg-gray-1 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
          >
            <option value="default">Default</option>
            <option value="hover">Hover</option>
            <option value="product-unavailable">Product unavailable</option>
            {parentHasCheckedChildren && (
              <option value="parent-checked">Parent checked</option>
            )}
            {parentHasHoverChildren && (
              <option value="parent-hover">Parent hover</option>
            )}
            {parentHasOptionUnavailableChildren && (
              <option value="parent-option-unavailable">
                Option unavailable
              </option>
            )}
          </select>
          {layer.state === "product-unavailable" && (
            <UnavailableProductSelector />
          )}
        </div>
      );
    }
    case "option-selector-item": {
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "default") {
          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: null },
            })
          );

          return;
        }

        try {
          const result = compiledOptionSelectorItemState.Decode(
            event.target.value
          );

          dispatch(
            setLayerProperties({
              layerIds: [layer.id],
              properties: { state: result },
            })
          );
        } catch (error) {
          toast.error("There was an issue updating the state");
        }
      };

      return (
        <div className="flex flex-col gap-2 border-b border-gray-6 p-3">
          <select
            onChange={handleChange}
            value={parseState(layer.state)}
            aria-label="Select state"
            className="flex h-8 w-full rounded-sm border-r-8 border-transparent bg-gray-1 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
          >
            <option value="default">Default</option>
            <option value="hover">Hover</option>
            <option value="checked">Checked</option>
            <option value="option-unavailable">Option unavailable</option>
          </select>
        </div>
      );
    }
  }
}
