import { store } from "@core/store";
import {
  BlockCollection,
  LayerType,
  Layers,
  ShopifyProduct,
  blockCollectionEnum,
} from "@folds/shared";
import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { toast } from "@core/toast";
import { useGetProductsInformationFromLayers } from "@/features/editor/components/DevelopmentSaveComponent/useGetProductsInformationFromLayers";
import { getLayersToSave } from "@/features/editor/components/DevelopmentSaveComponent/utils/getLayersToSave";
import { CloseDialogIcon } from "@/features/dashboard/Dashboard.icons";
import { DialogContent, DialogPortal } from "@lib/radix";
import { useAxios } from "@lib/axios";
import * as RadioGroup from "@radix-ui/react-radio-group";

declare global {
  interface Window {
    folds: {
      toggleSaveBlock: () => void;
      toggleSaveTemplate: () => void;
      toggleSaveElement: () => void;
    };
  }
}

function SelectCollection({
  collection,
  setCollection,
}: {
  collection: BlockCollection;
  setCollection: (collection: BlockCollection) => void;
}) {
  return (
    <RadioGroup.Root onValueChange={setCollection} value={collection}>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.Hero}
      >
        Hero
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.Product}
      >
        Product
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.ProductCarousel}
      >
        ProductCarousel
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.Testimonials}
      >
        Testimonials
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.Categories}
      >
        Categories
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.Features}
      >
        Features
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.AsFeaturedIn}
      >
        As Featured In
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.AboutUs}
      >
        About Us
      </RadioGroup.Item>

      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={blockCollectionEnum.FrequentlyAskedQuestions}
      >
        Frequently Asked Questions
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
}

/**
 * Saves templates to the server
 *
 * Only render in development environment
 */
export function DevelopmentSaveBlock() {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const axios = useAxios();
  const [products, setProducts] = useState<ShopifyProduct[]>([]);
  const [description, setDescription] = useState("");
  const [layersToSave, setLayersToSave] = useState<Layers>({});
  const [collection, setCollection] = useState<BlockCollection>("hero");
  const getProductInformation = useGetProductsInformationFromLayers();

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") return;

    window.folds = {
      ...window.folds,
      toggleSaveBlock: () => {
        setIsDisplayed((prev) => !prev);
      },
    };
  }, []);

  if (process.env.NODE_ENV !== "development") return null;

  const handleOpenChange = async (open: boolean) => {
    if (open === false) return;

    const { layers, selectedLayerIds } = store.getState().editor;

    const firstSelectedLayerId = selectedLayerIds[0];
    if (firstSelectedLayerId === undefined) return;

    const targetLayer = layers[firstSelectedLayerId];

    if (targetLayer?.type !== LayerType.Block) {
      toast.error("Selected layer is not a block");
      return;
    }

    const saveLayers = getLayersToSave(layers, selectedLayerIds);

    if (saveLayers === undefined) return;

    const productInformation = await getProductInformation(saveLayers);
    if (productInformation === null) return;

    setProducts(productInformation);
    setLayersToSave(saveLayers);
  };

  const handleSaveBlock = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (Object.keys(layersToSave).length === 0) {
      toast.error("No layers to save");
      return;
    }

    if (description.length === 0) {
      toast.error("Description is missing");
      return;
    }

    try {
      toast.loading("Saving block...", { id: "saving-block" });

      await axios.post("/blocks", {
        products,
        layers: layersToSave,
        description,
        collection,
      });

      toast.success("Block saved", { id: "saving-block" });
    } catch (error) {
      toast.error("Failed to save block", { id: "saving-block" });
    }
  };

  if (isDisplayed === false) return null;

  return (
    <Dialog.Root onOpenChange={handleOpenChange} modal={false}>
      <Dialog.Trigger
        type="button"
        // Prevent unselecting the selected elements
        onMouseDown={(event) => event.stopPropagation()}
        className="red-100 fixed bottom-0 right-0 z-50 rounded bg-gray-12 p-3 text-white"
      >
        Save block
      </Dialog.Trigger>
      <DialogPortal>
        <DialogContent className="fixed left-0 top-0 flex h-screen w-96 translate-x-0 translate-y-0 flex-col gap-5 overflow-y-scroll rounded-lg bg-gray-1 p-6 shadow">
          <div className="flex items-center justify-between">
            <p>Save block</p>
            <Dialog.Close>
              <CloseDialogIcon />
            </Dialog.Close>
          </div>
          <input
            autoComplete="true"
            className="bg-gray-6 p-2"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <SelectCollection
            collection={collection}
            setCollection={setCollection}
          />
          <button
            onClick={handleSaveBlock}
            type="button"
            className="h-8 w-full rounded-sm bg-gray-12 text-white"
          >
            Save
          </button>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}
