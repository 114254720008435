import * as RadioGroup from "@radix-ui/react-radio-group";
import { toast } from "@core/toast";
import { Type, Static } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";

const inputTypeSchema = Type.Union([
  Type.Literal("mouse"),
  Type.Literal("trackpad"),
]);

export type InputType = Static<typeof inputTypeSchema>;

const compiledInputTypeSchema = TypeCompiler.Compile(inputTypeSchema);

export function SelectInputType({
  setInputType,
  inputType,
  onContinue,
  onSkipTutorial,
}: {
  setInputType: (type: InputType) => void;
  inputType: InputType;
  onContinue: () => void;
  onSkipTutorial: () => void;
}) {
  const handleChangeInputType = (type: string) => {
    try {
      const parsedValue = compiledInputTypeSchema.Decode(type);

      setInputType(parsedValue);
    } catch (error) {
      toast.error("Failed to change device type");
    }
  };

  return (
    <>
      <div
        className="fixed left-0 top-0 z-[998] h-screen w-screen bg-black opacity-20"
        onClick={onSkipTutorial}
      />
      <div
        onMouseDown={(event) => event.stopPropagation()}
        className="absolute left-1/2 top-1/2 z-[999] flex w-[500px] -translate-x-1/2 -translate-y-1/2 flex-col gap-6 rounded-lg border border-gray-6 bg-gray-1 p-6 shadow-sm"
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">
              Let&apos;s learn how to navigate the editor
            </h3>
            <button
              type="button"
              onClick={onSkipTutorial}
              className="text-gray-11"
            >
              Skip tutorial
            </button>
          </div>
          <p className="text-base text-gray-11">
            This will only take one minute to complete
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p>Select your input type:</p>
          <RadioGroup.Root
            className="flex flex-col gap-2"
            value={inputType}
            onValueChange={handleChangeInputType}
          >
            <RadioGroup.Item
              value="mouse"
              className="flex justify-start rounded border border-gray-6 bg-gray-3 px-6 py-4 text-base data-[state=checked]:border-blue-9"
            >
              Mouse
            </RadioGroup.Item>
            <RadioGroup.Item
              value="trackpad"
              className="flex justify-start rounded border border-gray-6 bg-gray-3 px-6 py-4 text-base data-[state=checked]:border-blue-9"
            >
              Trackpad
            </RadioGroup.Item>
          </RadioGroup.Root>
        </div>
        <button
          type="button"
          className="hover:bg-primary w-full rounded-md bg-gray-12 py-3 text-gray-1 transition-colors duration-200"
          onClick={onContinue}
        >
          Continue
        </button>
      </div>
    </>
  );
}
