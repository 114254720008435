import { useSaveLayers } from "@/features/editor/api/saveLayers";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@/features/dashboard/Dashboard.icons";
import { DialogContent, DialogOverlay, DialogPortal } from "@lib/radix";
import { usePublishPage } from "@/features/editor/api/publishPage";
import { usePreviewPage } from "@/features/editor/api/previewPage";

export function PreviewPublishActions() {
  const saveLayers = useSaveLayers();
  const publishPage = usePublishPage();
  const previewPage = usePreviewPage();

  return (
    <div className="pointer-events-auto flex items-center gap-5">
      <button
        className="bg-transparent text-xs"
        type="button"
        onClick={previewPage}
      >
        Preview
      </button>
      <div className="flex gap-1">
        <button
          onClick={saveLayers}
          className="h-10 w-24 rounded border border-gray-8 bg-white text-center text-xs text-gray-12"
          type="button"
        >
          Save
        </button>
        <Dialog.Root>
          <Dialog.Trigger
            className="h-10 w-32 rounded bg-gray-12 text-center text-xs text-white"
            type="button"
          >
            Publish
          </Dialog.Trigger>

          <DialogPortal>
            <DialogOverlay />

            <DialogContent>
              <div className="flex justify-between">
                <Dialog.Title className="text-lg font-medium">
                  Publish page
                </Dialog.Title>
                <Dialog.Close>
                  <CloseDialogIcon />
                </Dialog.Close>
              </div>
              <p className="text-base">
                Are you sure you want to publish this page?
              </p>
              <div className="flex gap-3">
                <Dialog.Close
                  type="button"
                  className="flex-1 rounded border border-gray-6 bg-gray-2 py-3 text-sm"
                >
                  Cancel
                </Dialog.Close>
                <Dialog.Close
                  type="button"
                  onClick={publishPage}
                  className="flex-1 rounded border border-gray-6 bg-gray-12 py-3 text-sm text-white"
                >
                  Publish
                </Dialog.Close>
              </div>
            </DialogContent>
          </DialogPortal>
        </Dialog.Root>
      </div>
    </div>
  );
}
