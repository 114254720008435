import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";

export const useUninstallShop = () => {
  const axios = useAxios();

  const uninstallShop = async () => {
    try {
      toast.loading("Uninstalling...", { id: "uninstall" });
      await axios.post("/uninstall");
      toast.success("Uninstalled", { id: "uninstall" });
    } catch (error) {
      toast.error("There was an issue uninstalling. Please contact support.", {
        id: "uninstall",
      });
    }
  };

  return uninstallShop;
};
