import { layersSchema } from '@folds/shared/types';
import { Type, type Static } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';

export const EventTypes = {
  Element: 'element',
  Text: 'text',
  Image: 'image',
  Icon: 'icon',
  Block: 'block',
  Template: 'template',
} as const;

const elementAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Element),
  layers: layersSchema,
  width: Type.Number(),
});

export type ElementAddedEvent = Static<typeof elementAddedEventSchema>;

const blockAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Block),
  layers: layersSchema,
});

export type BlockAddedEvent = Static<typeof blockAddedEventSchema>;

const templateAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Template),
  layers: layersSchema,
});

export type TemplateAddedEvent = Static<typeof templateAddedEventSchema>;

const textAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Text),
  fontSize: Type.Number(),
  lineHeight: Type.Number(),
  text: Type.String(),
  fontFamily: Type.String(),
});

export type TextAddedEvent = Static<typeof textAddedEventSchema>;

const iconAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Icon),
  id: Type.String(),
});

export type IconAddedEvent = Static<typeof iconAddedEventSchema>;

const imageAddedEventSchema = Type.Object({
  type: Type.Literal(EventTypes.Image),
  src: Type.String(),
});

export type ImageAddedEvent = Static<typeof imageAddedEventSchema>;

const eventSchema = Type.Union([
  elementAddedEventSchema,
  iconAddedEventSchema,
  textAddedEventSchema,
  imageAddedEventSchema,
  blockAddedEventSchema,
  templateAddedEventSchema,
]);

export const compiledEventSchema = TypeCompiler.Compile(eventSchema);
