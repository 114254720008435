import type { DraftEditorState } from "@core/features/editor/editorSlice";
import { nanoid } from "@core/lib/nanoid";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import { DEFAULT_RECTANGLE_PROPERTIES } from "@folds/shared/constants";
import {
  LayerId,
  LayerType,
  Position,
  RectangleLayer,
} from "@folds/shared/types";
import { toast } from "@core/toast";
import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";

const MAX_IMAGE_WIDTH = 500;

const getScaledImageDimensions = ({
  height,
  width,
}: {
  width: number;
  height: number;
}): { width: number; height: number } => {
  if (width < MAX_IMAGE_WIDTH) {
    return { width, height };
  }

  const ratio = width / height;

  return {
    width: MAX_IMAGE_WIDTH,
    height: MAX_IMAGE_WIDTH / ratio,
  };
};

type Args = {
  src: string;
  width: number;
  height: number;
  position: Position;
  targetId: LayerId;
};

export const imageAddedEventAction = (
  state: DraftEditorState,
  payload: Args
) => {
  const { height, position, src, targetId, width } = payload;
  const { breakpoint } = state;

  const targetLayer = state.layers[targetId];

  if (targetLayer === undefined) {
    toast.error("There was an issue adding the image");
    return;
  }

  if (!("children" in targetLayer)) {
    toast.error("There was an issue adding the image");
    return;
  }

  const newImageId = nanoid();

  const zIndex = getHighestZIndex(state.layers, targetId);

  const scaledDimensions = getScaledImageDimensions({ width, height });

  const responsiveLeft = getLayerResponsiveLeftPosition({
    breakpoint,
    left: position.left,
    width: scaledDimensions.width,
  });

  const newImageLayer: RectangleLayer = {
    ...DEFAULT_RECTANGLE_PROPERTIES,
    id: newImageId,
    parentId: targetId,
    left: responsiveLeft.desktop,
    top: position.top,
    type: LayerType.Rectangle,
    width: scaledDimensions.width,
    height: scaledDimensions.height,
    fillType: "image",
    backgroundImage: src,
    zIndex,
    tablet: { visible: true, left: responsiveLeft.tablet },
    mobile: { visible: true, left: responsiveLeft.mobile },
  };

  targetLayer.children.push(newImageId);
  state.layers[newImageId] = newImageLayer;
};
