import { useLocation } from "react-router-dom";

/**
 * Checks if the editor is inside the playground: https://folds.app/playground
 */
export const useIsInsidePlayground = () => {
  const location = useLocation();

  const isInsidePlayground = location.pathname === "/playground";

  return isInsidePlayground;
};
