import { Layers } from "@folds/shared";
import { produce } from "immer";

/**
 * When adding layers from blocks / elements / templates, we need to replace the store specific information with null
 */
export const replaceLayersInformationWithNull = (layers: Layers): Layers => {
  const updatedLayersEntries = Object.entries(layers).map(([id, layer]) => {
    const updatedLater = produce(layer, (draft) => {
      if ("productId" in draft && draft.productId !== null) {
        draft.productId = null;
      }

      if ("productHandle" in draft && draft.productHandle !== null) {
        draft.productHandle = null;
      }

      if (
        "action" in draft &&
        draft.action?.type === "redirect" &&
        draft.action.url !== null
      ) {
        draft.action.url = null;
      }

      if (
        "action" in draft &&
        draft.action?.type === "add-to-cart" &&
        draft.action.productId !== null
      ) {
        draft.action.productId = null;
      }

      if (
        "action" in draft &&
        draft.action?.type === "add-to-cart" &&
        draft.action.productHandle !== null
      ) {
        draft.action.productHandle = null;
      }

      if (
        "productUnavailableId" in draft &&
        draft.productUnavailableId !== null
      ) {
        draft.productUnavailableId = null;
      }

      if (
        "productUnavailableHandle" in draft &&
        draft.productUnavailableHandle !== null
      ) {
        draft.productUnavailableHandle = null;
      }

      if ("optionName" in draft && draft.optionName !== null) {
        draft.optionName = null;
      }
    });

    return [id, updatedLater];
  });

  const updatedLayers = Object.fromEntries(updatedLayersEntries);

  return updatedLayers;
};
