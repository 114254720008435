import { generateFillStyles } from './utils';
import { CarouselRootLayer, CarouselSlideLayer } from '../types';

export const generateCarouselRootStyles = (
  layer: CarouselRootLayer
): React.CSSProperties => {
  const { height, width, zIndex, left, top, visible } = layer;

  const cssVariables = {
    '--swiper-pagination-color': layer.paginationActiveBulletColor,
    '--swiper-pagination-bullet-inactive-color':
      layer.paginationInactiveBulletColor,
    '--swiper-navigation-color': layer.navigationColor,
    '--swiper-scrollbar-bg-color': layer.scrollbarTrackColor,
    '--swiper-scrollbar-drag-bg-color': layer.scrollbarThumbColor,
    '--swiper-pagination-bullet-inactive-opacity': 1,
    '--swiper-navigation-size': `${layer.navigationSize}px`,
    ...(layer.navigationEnabled === false && {
      '--swiper-navigation-size': '0px',
    }),
    ...(layer.paginationEnabled === false && {
      '--swiper-pagination-bullet-width': '0px',
      '--swiper-pagination-bullet-height': '0px',
    }),
    ...(layer.scrollbarEnabled === false && {
      '--swiper-scrollbar-size': '0px',
    }),
  };

  return {
    ...cssVariables,
    all: 'initial',
    display: visible ? 'block' : 'none',
    boxSizing: 'border-box',
    zIndex,
    top,
    left,
    overflow: 'clip',
    position: 'absolute',
    width,
    height,
  };
};

export const generateCarouselSlideStyles = (
  layer: CarouselSlideLayer
): React.CSSProperties => {
  const fillStyles = generateFillStyles(layer);

  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    width,
    visible,
  } = layer;

  return {
    ...fillStyles,
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
    display: visible ? 'block' : 'none',
    boxSizing: 'border-box',
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    overflow: 'clip',
    width,
    height: 'auto',
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderStyle,
    borderColor,
  };
};
