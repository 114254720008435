import { getAllChildLayers } from "@/features/editor/hooks/useCopyPaste/getLayersToCopy";
import { calculateMousePointRelativeToCanvas } from "@/features/editor/hooks/useDragSelect/utils/calculateMousePointRelativeToCanvas";
import { randomizeLayerIds } from "@/features/editor/utils/randomizeLayerIds";
import { imageAddedEventAction } from "@core/features/editor/editorSlice/actions/addImageEventAction";
import { addLayerAction } from "@core/features/editor/editorSlice/actions/addLayerAction";
import { addTextLayerAction } from "@core/features/editor/editorSlice/actions/addTextLayer/addTextLayer";
import { deleteLayerAction } from "@core/features/editor/editorSlice/actions/deleteLayerAction";
import { dragAction } from "@core/features/editor/editorSlice/actions/dragAction";
import { dragEndAction } from "@core/features/editor/editorSlice/actions/dragEndAction";
import { dragSelectAction } from "@core/features/editor/editorSlice/actions/dragSelectAction";
import { dragStartAction } from "@core/features/editor/editorSlice/actions/dragStartAction";
import { duplicateLayersAction } from "@core/features/editor/editorSlice/actions/duplicateLayersAction";
import { groupLayersAction } from "@core/features/editor/editorSlice/actions/groupLayersAction";
import { initializeResizingAction } from "@core/features/editor/editorSlice/actions/initializeResizingAction";
import { mouseEnterAction } from "@core/features/editor/editorSlice/actions/mouseEnterAction";
import { mouseLeaveAction } from "@core/features/editor/editorSlice/actions/mouseLeaveAction";
import { pasteLayersAction } from "@core/features/editor/editorSlice/actions/pasteLayersAction";
import { pauseHistoryAction } from "@core/features/editor/editorSlice/actions/pauseHistoryAction";
import { rearrangeLayerAction } from "@core/features/editor/editorSlice/actions/rearrangeLayer";
import { redoAction } from "@core/features/editor/editorSlice/actions/redoAction";
import { deleteMobileOverridesAction } from "@core/features/editor/editorSlice/actions/resetMobileLayerOverridesAction";
import { deleteTabletOverridesAction } from "@core/features/editor/editorSlice/actions/resetTabletLayerOverridesActions";
import { resizeAction } from "@core/features/editor/editorSlice/actions/resizingAction/resizeAction";
import { resumeHistoryAction } from "@core/features/editor/editorSlice/actions/resumeHistoryAction";
import { selectLayerAction } from "@core/features/editor/editorSlice/actions/selectLayerAction";
import {
  setLayerAbsoluteLeftAction,
  setLayerAbsoluteTopAction,
} from "@core/features/editor/editorSlice/actions/setLayerAbsolutePositionAction";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/setLayerPropertiesAction";
import { setLeftPosition } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/setLeftPosition";
import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import {
  setSelectedLayersHorizontalGapAction,
  setSelectedLayersVerticalGapAction,
} from "@core/features/editor/editorSlice/actions/setSelectedLayersGap";
import { undoAction } from "@core/features/editor/editorSlice/actions/undoAction";
import { ungroupLayerAction } from "@core/features/editor/editorSlice/actions/ungroupLayerAction";
import { calculateAbsolutePositionGuides } from "@core/features/editor/editorSlice/actions/utils/dragging";
import { nanoid } from "@core/lib/nanoid";
import {
  getLayerDimensions,
  getBoundingBoxRect,
  getAbsolutePosition,
} from "@core/utils";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import {
  DEFAULT_BLOCK_PROPERTIES,
  ROOT_LAYER_ID,
} from "@folds/shared/constants";
import {
  BlockLayer,
  Box,
  Breakpoint,
  CanvasMode,
  Guide,
  GuideStopLines,
  Layer,
  LayerId,
  LayerType,
  Layers,
  Point,
  Position,
  SelectedLayerId,
  SelectedLayerIds,
  SidebarState,
  TextLayer,
  Variables,
  Viewport,
  compiledLayersSchema,
  IconLayer,
  BlockCollection,
} from "@folds/shared/types";
import {
  BlockAddedEvent,
  ElementAddedEvent,
  TemplateAddedEvent,
  TextAddedEvent,
} from "@folds/shared/types/src/events";
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { addCarouselSlideAction } from "@core/features/editor/editorSlice/actions/addCarouselSlideAction";
import { colord } from "colord";
import { Draft } from "immer";
import { blockAddedEventAction } from "@core/features/editor/editorSlice/actions/blockAddedEventAction";
import {
  appendTemplateToLayersAction,
  replaceLayersWithTemplateAction,
} from "@core/features/editor/editorSlice/actions/templateActions";
import { elementAddedEventAction } from "@core/features/editor/editorSlice/actions/elementAddedEventAction/elementAddedEventAction";
import { sendLayerToBackAction } from "@core/features/editor/editorSlice/actions/sendLayerToBackAction";
import { bringLayerToFrontAction } from "@core/features/editor/editorSlice/actions/bringLayerToFrontAction";
import { calculateGuideStopLines } from "@core/features/editor/editorSlice/actions/utils/calculateGuideStopLines";
import { toast } from "@core/toast";
import {
  deleteMobileOverrideAction,
  deleteTabletOverrideAction,
} from "@core/features/editor/editorSlice/actions/deleteOverrideAction";
import { BreakpointPropertyName } from "@/features/editor/utils/getBreakpointPropertyValue";
import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";

const getBreakpoint = (layers: Layers) => {
  const breakpoint = Object.values(layers).find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (!breakpoint || breakpoint.type !== LayerType.Breakpoint) {
    return null;
  }

  return breakpoint;
};

const getBlockIndex = (breakpointChildren: LayerId[], blockId: LayerId) => {
  const index = breakpointChildren.findIndex((childId) => childId === blockId);

  if (index === -1) return null;

  return index;
};

export type InitialLayersInformation = (
  | {
      position: Position;
      type: "extrisic";
      layerId: LayerId;
    }
  | {
      position: { [key: string]: Position };
      type: "intrinsic";
      layerId: LayerId;
    }
)[];

/**
 * All layers except for text layers
 */
export type ElementLayerInformation = {
  type: "element";
  fitContent: false;
  fontSize: null;
  layerId: LayerId;
  top: number;
  left: number;
  width: number;
  height: number;
  distanceFromTop: number;
  distanceFromRight: number;
  distanceFromBottom: number;
  distanceFromLeft: number;
};

export type TextLayerInformation = {
  type: "text";
  fitContent: boolean;
  fontSize: number;
  layerId: LayerId;
  top: number;
  left: number;
  width: number;
  height: number;
  distanceFromTop: number;
  distanceFromRight: number;
  distanceFromBottom: number;
  distanceFromLeft: number;
};

export type ResizingInitialLayersInformation = (
  | ElementLayerInformation
  | TextLayerInformation
)[];

export type DraftEditorState = Draft<EditorState>;

export type TrackedStateChange = {
  selectedLayerIds: SelectedLayerIds;
  changes: Record<string, Partial<Layer> | undefined>;
};

export type Dragging = {
  draggedIntoLayerId: SelectedLayerId | null;
  /**
   * The layer id that started the drag event
   */
  initializedDraggingLayerId: LayerId | null;
  guideStopLines: GuideStopLines;
  initialMousePoint: Point;
  initialLayersInformation: InitialLayersInformation;
  initialBox: { left: number; top: number; width: number; height: number };
  verticalGuides: Guide[];
  horizontalGuides: Guide[];
  draggingLayerIds: SelectedLayerIds;
};

export type LayerInformationToReplace = {
  type: "url" | "product" | "addToCartAction" | "option";
  layerId: LayerId;
  replaced: boolean;
};

export type Resizing = {
  type:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
  initialMousePoint: Point;
  initialLayersInformation: ResizingInitialLayersInformation;
  shiftKey: boolean;
  box: Box & { right: number; bottom: number };
  guideStopLines: GuideStopLines;
  verticalGuides: Guide[];
  horizontalGuides: Guide[];
};

export type DragSelect = {
  initialPosition: Position;
  currentPosition: Position;
};

export interface EditorState {
  /**
   * The font family that was previously selected
   *
   * When we are creating new text layers or elements, we want to use the last selected font family
   */
  previouslySelectedFontFamily: string;
  /**
   * If the swiper script has been loaded
   * We need to track if the script has been loaded and re-render when it has to ensure the content is displayed correctly: https://linear.app/folds/issue/FOL-495/fix-swiper-not-loading-properly-before-rendering
   */
  isSwiperScriptLoaded: boolean;
  expandedBlockCollection: BlockCollection | null;
  loadingState: "initial" | "loading" | "loaded" | "error";
  error: string | null;
  layers: Layers;
  dragSelect: DragSelect | null;
  pendingDropTemplateLayers: Layers | null;
  copiedLayerProperties: Layer | null;
  /**
   * Tracks if the intercom widget has been loaded inside of the modal editor
   */
  isIntercomLoaded: boolean;
  /**
   * When a user drops a block that has product elements or links, the user needs to use products and links from their store to replace them
   */
  droppedLayersInformationToReplace: LayerInformationToReplace[] | null;
  /**
   * Used to compare against the current layers to see if changes have been made
   */
  savedLayers: Layers;
  sidebarState: SidebarState;
  selectedLayerIds: SelectedLayerIds;
  changeVariantIndicatorsEnabled: boolean;
  canvasMode: CanvasMode;
  breakpoint: Breakpoint;
  /**
   * When we are duplicating a layer with alt + drag this is true, until the layer is duplicated
   */
  duplicatingLayerIds: LayerId[] | null;

  contextMenu: {
    position: { x: number; y: number };
    layerId: LayerId;
  } | null;
  /*
   * Dragging
   */
  dragging: Dragging | null;

  /**
   * Changes are only saved in the history stack when this is true
   */
  isHistoryEnabled:
    | { value: true; initialLayers: null; initialSelectedLayerIds: null }
    | {
        value: false;
        initialLayers: Layers;
        initialSelectedLayerIds: SelectedLayerIds;
      };
  /*
   * Viewport
   */
  translateX: number;
  translateY: number;
  scale: number;
  isViewportLocked: boolean;
  isViewportTransforming: boolean;
  /**
   * History
   */
  previousStates: TrackedStateChange[];
  futureStates: TrackedStateChange[];
  /**
   * Resizing
   */
  resizing: Resizing | null;
  altHoverDistanceEnabled: boolean;
  hoveredLayerId: LayerId | null;
}

export const initialEditorState: EditorState = {
  previouslySelectedFontFamily: "Inter",
  expandedBlockCollection: null,
  isSwiperScriptLoaded: false,
  layers: {},
  savedLayers: {},
  loadingState: "initial",
  error: null,
  dragSelect: null,
  canvasMode: "Select",
  sidebarState: "templates",
  resizing: null,
  dragging: null,
  contextMenu: null,
  selectedLayerIds: [],
  changeVariantIndicatorsEnabled: true,
  breakpoint: "desktop",
  isHistoryEnabled: {
    value: true,
    initialLayers: null,
    initialSelectedLayerIds: null,
  },
  translateX: 0,
  translateY: 0,
  scale: 1,
  duplicatingLayerIds: null,
  isViewportLocked: false,
  isViewportTransforming: false,
  previousStates: [],
  futureStates: [],
  altHoverDistanceEnabled: false,
  hoveredLayerId: null,
  droppedLayersInformationToReplace: null,
  pendingDropTemplateLayers: null,
  copiedLayerProperties: null,
  isIntercomLoaded: false,
};

export const loadPage = createAsyncThunk(
  "editor/loadPage",
  async ({ axios, pageId }: { pageId: string; axios: AxiosInstance }) => {
    const response = await axios.get(`/pages/${pageId}?updateViewedAt=true`);
    return response.data;
  }
);

export const editorSlice = createSlice({
  name: "editor",
  initialState: initialEditorState,
  reducers: {
    deleteSelectedLayers(state) {
      const { selectedLayerIds } = state;

      selectedLayerIds.forEach((selectedLayerId) => {
        deleteLayerAction(state, selectedLayerId);
      });
    },
    setLayerProperties(
      state,
      action: PayloadAction<{
        layerIds: SelectedLayerIds;
        properties: Partial<Layer>;
        breakpoint?: Breakpoint;
      }>
    ) {
      setLayerPropertiesAction({
        layers: state.layers,
        updatedLayerIds: action.payload.layerIds,
        properties: action.payload.properties,
        breakpoint: action.payload.breakpoint ?? state.breakpoint,
      });
    },
    addLayer(
      state,
      action: PayloadAction<{
        layer: Layer;
        options?: { selectLayer?: boolean; index?: number };
      }>
    ) {
      const { layer, options } = action.payload;

      addLayerAction(state.layers, layer, options?.index);

      if (options?.selectLayer === true) {
        setSelectedLayerIdsAction(state, [layer.id]);
        state.sidebarState = "styles";
      }
    },
    selectLayer: (state, action: PayloadAction<SelectedLayerId>) => {
      state.sidebarState = "styles";

      selectLayerAction(state, action.payload);
    },
    deselectLayer: (state, action: PayloadAction<SelectedLayerId>) => {
      const id = action.payload;

      const updatedSelectedLayerIds = state.selectedLayerIds.filter(
        (selectedLayerId) => !(selectedLayerId === id)
      );

      setSelectedLayerIdsAction(state, updatedSelectedLayerIds);
    },
    clearSelectedLayers: (state) => {
      setSelectedLayerIdsAction(state, []);

      if (state.sidebarState === "styles") {
        state.sidebarState = "templates";
      }
    },
    rearrangeLayer(
      state: DraftEditorState,
      action: PayloadAction<{
        layerId: LayerId;
        newParentId: LayerId;
        newIndex: number | undefined;
      }>
    ) {
      const { layerId, newIndex, newParentId } = action.payload;

      rearrangeLayerAction(state, {
        newIndex,
        newParentId,
        originalLayerId: layerId,
      });
    },
    disableChangeVariantIndicators(state) {
      return {
        ...state,
        changeVariantIndicatorsEnabled: false,
      };
    },
    enableChangeVariantIndicator(state) {
      return {
        ...state,
        changeVariantIndicatorsEnabled: true,
      };
    },

    /**
     * Creates a new block layer
     *
     * @action New block background color
     */
    addBlock(state) {
      const { layers } = state;

      const breakpointLayer = Object.values(layers).find(
        (layer) => layer.type === LayerType.Breakpoint
      );

      if (!breakpointLayer || breakpointLayer.type !== LayerType.Breakpoint)
        return;

      const newBlockId = nanoid();

      const blockLayer: BlockLayer = {
        ...DEFAULT_BLOCK_PROPERTIES,
        backgroundColor: "rgb(255, 255, 255)",
        id: newBlockId,
        parentId: breakpointLayer.id,
        height: 500,
      };

      breakpointLayer.children.push(newBlockId);

      layers[newBlockId] = blockLayer;
    },
    moveBlockDown(state, action: PayloadAction<LayerId>) {
      const { layers } = state;

      const breakpoint = getBreakpoint(layers);
      if (breakpoint === null) return;

      const index = getBlockIndex(breakpoint.children, action.payload);
      if (index === null) return;

      // Do not move block out of bounds
      if (index === breakpoint.children.length - 1) return;

      rearrangeLayerAction(state, {
        originalLayerId: action.payload,
        newIndex: index + 1,
        newParentId: breakpoint.id,
      });
    },
    moveBlockUp(state, action: PayloadAction<LayerId>) {
      const { layers } = state;

      const breakpoint = getBreakpoint(layers);
      if (breakpoint === null) return;

      const index = getBlockIndex(breakpoint.children, action.payload);

      if (index === null) return;

      if (index === 0) return;

      rearrangeLayerAction(state, {
        originalLayerId: action.payload,
        newIndex: index - 1,
        newParentId: breakpoint.id,
      });
    },
    drag(
      state,
      action: PayloadAction<{
        event: {
          clientX: number;
          clientY: number;
          altKey: boolean;
        };
        scale: number;
      }>
    ) {
      if (!state.dragging) return;

      if (action.payload.event.altKey && state.duplicatingLayerIds !== null) {
        const deltaX =
          action.payload.event.clientX - state.dragging.initialMousePoint.x;
        const deltaY =
          action.payload.event.clientY - state.dragging.initialMousePoint.y;

        const isMoreThan25pxAwayFromInitialMousePoint =
          Math.abs(deltaX) > 25 || Math.abs(deltaY) > 25;

        if (isMoreThan25pxAwayFromInitialMousePoint) {
          duplicateLayersAction(state);
        }

        return;
      }

      dragAction(state, {
        event: {
          clientX: action.payload.event.clientX,
          clientY: action.payload.event.clientY,
        },
        scale: action.payload.scale,
      });
    },
    mouseEnter(state, action: PayloadAction<LayerId>) {
      mouseEnterAction(state, action.payload);
    },
    mouseLeave(state, action: PayloadAction<{ layerMovedOutOfId: LayerId }>) {
      mouseLeaveAction(state, action.payload);
    },
    dragEnd(state) {
      dragEndAction(state);
    },
    dragStart(
      state,
      action: PayloadAction<{
        event: {
          clientX: number;
          clientY: number;
        };
        initializedDraggingLayerId: LayerId | null;
      }>
    ) {
      dragStartAction(
        state,
        action.payload.event,
        action.payload.initializedDraggingLayerId
      );
    },
    changeBreakpoint(state, action: PayloadAction<Breakpoint>) {
      return {
        ...state,
        breakpoint: action.payload,
      };
    },
    setSelectedLayerIds(state, action: PayloadAction<SelectedLayerIds>) {
      if (action.payload.length > 0) {
        state.sidebarState = "styles";
      }

      setSelectedLayerIdsAction(state, action.payload);
    },
    setCanvasMode(state, action: PayloadAction<CanvasMode>) {
      state.canvasMode = action.payload;
    },
    setSelectedLayerProperties(state, action: PayloadAction<Partial<Layer>>) {
      const { breakpoint, selectedLayerIds, layers } = state;

      setLayerPropertiesAction({
        breakpoint,
        layers,
        properties: action.payload,
        updatedLayerIds: selectedLayerIds,
      });
    },
    setSidebarState(state, action: PayloadAction<SidebarState>) {
      state.sidebarState = action.payload;
    },
    /**
     * Adds a text layer to the first block layer.
     * Is triggered when the user click a text element in the sidebar.
     *
     * By default, the text layer is centered in the first block layer.
     * However, a custom position or parent can be passed in.
     */
    textAddedEvent(
      state,
      action: PayloadAction<{
        event: TextAddedEvent;
        position: Position;
        targetLayerId: LayerId;
      }>
    ) {
      const {
        event: { fontSize, lineHeight, text, fontFamily },
        position,
        targetLayerId,
      } = action.payload;

      addTextLayerAction(state, {
        fontSize,
        lineHeight,
        text,
        position,
        targetLayerId,
        fontFamily,
      });
    },
    elementAddedEvent(
      state,
      action: PayloadAction<{
        event: ElementAddedEvent;
        targetLayerId: LayerId;
        position: Position;
      }>
    ) {
      elementAddedEventAction(state, {
        layers: action.payload.event.layers,
        width: action.payload.event.width,
        position: action.payload.position,
        targetLayerId: action.payload.targetLayerId,
      });
    },
    blockAddedEvent(state, action: PayloadAction<BlockAddedEvent>) {
      blockAddedEventAction(state, action.payload);
    },
    imageAddedEvent(
      state,
      action: PayloadAction<{
        src: string;
        width: number;
        height: number;
        position: Position;
        targetId: LayerId;
      }>
    ) {
      imageAddedEventAction(state, action.payload);
    },
    iconAddedEvent(
      state,
      action: PayloadAction<{
        svg: string;
        targetId: LayerId;
        position: Position;
      }>
    ) {
      const { svg, targetId, position } = action.payload;
      const { breakpoint } = state;

      const targetLayer = state.layers[targetId];

      if (targetLayer === undefined) {
        toast.error("There was an issue adding the image");
        return;
      }

      if (!("children" in targetLayer)) {
        toast.error("There was an issue adding the image");
        return;
      }

      const newIconId = nanoid();

      const zIndex = getHighestZIndex(state.layers, targetId);

      const responsiveLeft = getLayerResponsiveLeftPosition({
        breakpoint,
        left: position.left,
        width: 36,
      });

      const newIconLayer: IconLayer = {
        type: LayerType.Icon,
        top: position.top,
        left: responsiveLeft.desktop,
        mobile: {
          visible: true,
          left: responsiveLeft.mobile,
        },
        tablet: {
          visible: true,
          left: responsiveLeft.tablet,
        },
        visible: true,
        zIndex,
        svg,
        id: newIconId,
        fill: "rgba(0, 0, 0, 1)",
        parentId: targetId,
        height: 36,
        width: 36,
        hasEditedDesktopPosition: false,
        hasEditedMobilePosition: false,
        hasEditedTabletPosition: false,
        action: null,
      };

      targetLayer.children.push(newIconId);
      state.layers[newIconId] = newIconLayer;
    },
    templateAddedEvent(state, action: PayloadAction<TemplateAddedEvent>) {
      state.pendingDropTemplateLayers = action.payload.layers;
    },
    clearDroppedLayersInformationToReplace(state) {
      state.droppedLayersInformationToReplace = null;
    },
    clearPendingDropTemplateLayers(state) {
      state.pendingDropTemplateLayers = null;
    },
    appendTemplateToLayers(state) {
      appendTemplateToLayersAction(state);
      state.pendingDropTemplateLayers = null;
    },
    replaceLayersWithTemplate(state) {
      replaceLayersWithTemplateAction(state);
      state.pendingDropTemplateLayers = null;
      setSelectedLayerIdsAction(state, []);
    },
    deleteAllOverrides(state) {
      state.selectedLayerIds.forEach((id) => {
        const layer = state.layers[id];

        if (!layer || !("tablet" in layer) || !("mobile" in layer)) return;

        if ("hasEditedTabletLeftPosition" in layer) {
          layer.hasEditedTabletLeftPosition = false;
        }

        if ("hasEditedMobileLeftPosition" in layer) {
          layer.hasEditedMobileLeftPosition = false;
        }

        // Re-calculate left position, to override the current layer.tablet.left and layer.mobile.left
        if ("left" in layer) {
          setLeftPosition({
            breakpoint: state.breakpoint,
            id,
            layers: state.layers,
            value: layer.left,
          });
        }

        layer.tablet = {};
        layer.mobile = {};
      });
    },
    /**
     * Stops viewport from moving
     */
    lockViewport: (state) => {
      state.isViewportLocked = true;
    },
    /**
     * Allows viewport to move
     */
    unlockViewport: (state) => {
      state.isViewportLocked = false;
    },
    setViewport: (state, action: PayloadAction<Viewport>) => {
      state.scale = action.payload.scale;
      state.translateX = action.payload.translateX;
      state.translateY = action.payload.translateY;
    },
    setIsViewportTransforming: (state, action: PayloadAction<boolean>) => {
      state.isViewportTransforming = action.payload;
    },
    /**
     * Pauses history
     */
    pauseHistory: (state) => {
      pauseHistoryAction(state);
    },
    /**
     * Resumes history
     */
    resumeHistory: (state) => {
      resumeHistoryAction(state);
    },
    /**
     * Undo changes to layers / selected layer ids
     */
    undo: (state) => {
      undoAction(state);
    },
    /**
     * Redo changes to layers / selected layer ids
     */
    redo: (state) => {
      redoAction(state);
    },
    initializeResizing(
      state,
      action: PayloadAction<{
        type: Resizing["type"];
        point: Point;
        shiftKey: boolean;
      }>
    ) {
      initializeResizingAction(
        state,
        action.payload.type,
        action.payload.point,
        action.payload.shiftKey
      );
    },
    /**
     * Allows to change if scaling portionally during resizing
     */
    setResizingShiftKey(state, action: PayloadAction<boolean>) {
      if (state.resizing === null) return;

      state.resizing.shiftKey = action.payload;
    },
    /**
     * Resets the resizing state and re-enables history
     */
    finishResizing(state) {
      resumeHistoryAction(state);
      state.resizing = null;
    },
    resize(state, action: PayloadAction<Point>) {
      if (state.resizing === null) return;

      resizeAction(state, action.payload);

      const guides = calculateAbsolutePositionGuides({
        guideStopLines: state.resizing.guideStopLines,
        ids: state.selectedLayerIds,
      });

      state.resizing.verticalGuides = guides.verticalGuides;
      state.resizing.horizontalGuides = guides.horizontalGuides;
    },
    moveSelectedLayersUp(state, action: PayloadAction<number>) {
      state.selectedLayerIds.forEach((id) => {
        const { top } = getAbsolutePosition(id);

        const newTop = top - action.payload;

        setLayerAbsoluteTopAction(state, id, newTop);
      });
    },
    moveSelectedLayersRight(state, action: PayloadAction<number>) {
      state.selectedLayerIds.forEach((id) => {
        const { left } = getAbsolutePosition(id);

        const newLeft = left + action.payload;

        setLayerAbsoluteLeftAction(state, id, newLeft);
      });
    },
    moveSelectedLayersDown(state, action: PayloadAction<number>) {
      state.selectedLayerIds.forEach((id) => {
        const { top } = getAbsolutePosition(id);

        const layer = state.layers[id];
        if (layer === undefined) return;

        const newTop = top + action.payload;
        setLayerAbsoluteTopAction(state, id, newTop);
      });
    },
    moveSelectedLayersLeft(state, action: PayloadAction<number>) {
      state.selectedLayerIds.forEach((id) => {
        const { left } = getAbsolutePosition(id);

        const newLeft = left - action.payload;

        setLayerAbsoluteLeftAction(state, id, newLeft);
      });
    },
    pasteLayers(
      state,
      action: PayloadAction<{
        layers: Layer[];
        targetLayerId: LayerId | null;
      }>
    ) {
      pasteLayersAction(state, action.payload);
    },
    startDuplicating(state, action: PayloadAction<LayerId[]>) {
      state.duplicatingLayerIds = action.payload;
    },
    /**
     * Duplicates the selected layers in the same position
     */
    duplicateSelectedLayers(state) {
      duplicateLayersAction(state);
    },
    recalculateDraggingGuideStopLines(state) {
      if (state.dragging === null) return;

      const guideStopLines = calculateGuideStopLines({
        layers: state.layers,
        ids: state.dragging.draggingLayerIds,
        breakpoint: state.breakpoint,
      });

      state.dragging.guideStopLines = guideStopLines;
    },
    setVisibility(
      state,
      action: PayloadAction<{ visible: boolean; breakpoint: Breakpoint }>
    ) {
      state.selectedLayerIds.forEach((id) => {
        setLayerPropertiesAction({
          breakpoint: action.payload.breakpoint,
          layers: state.layers,
          updatedLayerIds: [id],
          properties: { visible: action.payload.visible },
        });
      });
    },
    openContextMenu(
      state,
      action: PayloadAction<{
        position: { x: number; y: number };
        layerId: LayerId;
      }>
    ) {
      state.isViewportLocked = true;
      setSelectedLayerIdsAction(state, [action.payload.layerId]);

      state.contextMenu = {
        position: action.payload.position,
        layerId: action.payload.layerId,
      };
    },
    closeContextMenu(state) {
      state.isViewportLocked = false;
      state.contextMenu = null;
    },
    bringLayerToFront(state, action: PayloadAction<LayerId>) {
      bringLayerToFrontAction(state, action.payload);
    },
    sendLayerToBack(state, action: PayloadAction<LayerId>) {
      sendLayerToBackAction(state, action.payload);
    },
    deleteLayer(state, action: PayloadAction<LayerId>) {
      deleteLayerAction(state, action.payload);
    },
    togglePlainTextBold(state, action: PayloadAction<LayerId>) {
      const layer = state.layers[action.payload];

      if (!layer || !("fontWeight" in layer)) return;

      const fontWeight = layer.fontWeight === 400 ? 700 : 400;

      setLayerPropertiesAction({
        breakpoint: state.breakpoint,
        layers: state.layers,
        updatedLayerIds: [action.payload],
        properties: { fontWeight },
      });
    },
    togglePlainTextUnderline(state, action: PayloadAction<LayerId>) {
      const layer = state.layers[action.payload];

      if (!layer || !("underline" in layer)) return;

      setLayerPropertiesAction({
        breakpoint: state.breakpoint,
        layers: state.layers,
        updatedLayerIds: [action.payload],
        properties: { underline: !layer.underline },
      });
    },
    togglePlainTextItalic(state, action: PayloadAction<LayerId>) {
      const layer = state.layers[action.payload];

      if (!layer || !("italic" in layer)) return;

      setLayerPropertiesAction({
        breakpoint: state.breakpoint,
        layers: state.layers,
        updatedLayerIds: [action.payload],
        properties: { italic: !layer.italic },
      });
    },
    togglePlainTextStrikethrough(state, action: PayloadAction<LayerId>) {
      const layer = state.layers[action.payload];

      if (!layer || !("lineThrough" in layer)) return;

      setLayerPropertiesAction({
        breakpoint: state.breakpoint,
        layers: state.layers,
        updatedLayerIds: [action.payload],
        properties: { lineThrough: !layer.lineThrough },
      });
    },
    updateSavedLayers(state, action: PayloadAction<Layers>) {
      state.savedLayers = action.payload;
    },
    /**
     * Deletes a variable from variant radio buttons
     *
     * @param state
     * @param action Variable id
     */
    deleteVariable(state, action: PayloadAction<string>) {
      const firstSelectedLayerId = state.selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return;

      const firstSelectedLayer = state.layers[firstSelectedLayerId];

      if (firstSelectedLayer === undefined) return;

      if (!("variables" in firstSelectedLayer)) return;

      firstSelectedLayer.variables = firstSelectedLayer.variables.filter(
        (variable) => variable.id !== action.payload
      );
    },
    /**
     * Upserts color variable to variant radio buttons
     */
    upsertColorVariable(
      state,
      action: PayloadAction<{ name: string; defaultColor: string; id: string }>
    ) {
      if (colord(action.payload.defaultColor).isValid() === false) {
        toast.error("There was an error adding the color variable");
        return;
      }

      const newVariable: Variables[number] = {
        type: "color",
        id: action.payload.id,
        name: action.payload.name,
        defaultValue: action.payload.defaultColor,
        values: [],
      };

      const firstSelectedLayerId = state.selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return;

      const firstSelectedLayer = state.layers[firstSelectedLayerId];

      if (firstSelectedLayer === undefined) return;

      if (!("variables" in firstSelectedLayer)) return;

      // Filter out current variable with the same id
      firstSelectedLayer.variables = firstSelectedLayer.variables.filter(
        (variable) => variable.id !== action.payload.id
      );

      firstSelectedLayer.variables.push(newVariable);
    },
    /**
     * Upserts text variable to variant radio buttons
     *
     * @param state
     * @param action default text value, and variable name
     *
     */
    upsertTextVariable(
      state,
      action: PayloadAction<{ defaultText: string; name: string; id: string }>
    ) {
      const newVariable: Variables[number] = {
        id: action.payload.id,
        type: "text",
        name: action.payload.name,
        defaultValue: action.payload.defaultText,
        values: [],
      };

      const firstSelectedLayerId = state.selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return;

      const firstSelectedLayer = state.layers[firstSelectedLayerId];

      if (firstSelectedLayer === undefined) return;

      if (!("variables" in firstSelectedLayer)) return;

      // Filter out current variable with the same id
      firstSelectedLayer.variables = firstSelectedLayer.variables.filter(
        (variable) => variable.id !== action.payload.id
      );

      firstSelectedLayer.variables.push(newVariable);
    },
    /**
     * Upserts image variable to variant radio buttons
     *
     * @param state
     * @param action variable name and id
     *
     */
    upsertImageVariable(
      state,
      action: PayloadAction<{ name: string; id: string }>
    ) {
      const newVariable: Variables[number] = {
        id: action.payload.id,
        type: "image",
        name: action.payload.name,
        defaultValue: "/image-placeholder.svg",
        values: [],
      };

      const firstSelectedLayerId = state.selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return;

      const firstSelectedLayer = state.layers[firstSelectedLayerId];

      if (firstSelectedLayer === undefined) return;

      if (!("variables" in firstSelectedLayer)) return;

      // Filter out current variable with the same id
      firstSelectedLayer.variables = firstSelectedLayer.variables.filter(
        (variable) => variable.id !== action.payload.id
      );

      firstSelectedLayer.variables.push(newVariable);
    },
    editVariable(
      state,
      action: PayloadAction<{
        variableId: string;
        optionValue: string;
        value: string;
      }>
    ) {
      const firstSelectedLayerId = state.selectedLayerIds[0];

      if (firstSelectedLayerId === undefined) return;

      const firstSelectedLayer = state.layers[firstSelectedLayerId];

      if (firstSelectedLayer === undefined) return;

      if (!("variables" in firstSelectedLayer)) return;

      const variableToUpdate = firstSelectedLayer.variables.find(
        (variable) => variable.id === action.payload.variableId
      );

      if (variableToUpdate === undefined) return;

      const valueAlreadyExists = variableToUpdate.values.some(
        (value) => value.optionValue === action.payload.optionValue
      );

      if (valueAlreadyExists === false) {
        variableToUpdate.values.push({
          value: action.payload.value,
          optionValue: action.payload.optionValue,
        });

        return;
      }

      variableToUpdate.values = variableToUpdate.values.map((value) => {
        if (value.optionValue === action.payload.optionValue) {
          return {
            value: action.payload.value,
            optionValue: value.optionValue,
          };
        }

        return value;
      });
    },
    /**
     * Updates the content type of the text layer
     */
    changeTextContent(
      state,
      action: PayloadAction<{
        content: TextLayer["content"];
        variableId: null | string;
      }>
    ) {
      state.selectedLayerIds.forEach((layerId) => {
        const originalLayer = state.layers[layerId];

        if (!originalLayer || originalLayer.type !== LayerType.Text) return;

        switch (action.payload.content) {
          case "product-price": {
            originalLayer.content = "product-price";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
          case "product-compare-at-price": {
            originalLayer.content = "product-compare-at-price";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
          case "custom": {
            originalLayer.content = "custom";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
          case "variable": {
            originalLayer.content = "variable";
            originalLayer.variableId = action.payload.variableId;
            originalLayer.optionName = null;

            break;
          }
          case "selected-option-value": {
            originalLayer.content = "selected-option-value";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
          case "option-value": {
            originalLayer.content = "option-value";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
          case "product-title": {
            originalLayer.content = "product-title";
            originalLayer.productId = null;
            originalLayer.optionName = null;

            break;
          }
        }
      });
    },
    /**
     * Duplicates the last slide and add it to the end of the slides
     * @param action The root carousel layer id
     */
    addCarouselSlide(state, action: PayloadAction<LayerId>) {
      addCarouselSlideAction(state, action.payload);
    },
    /**
     * Adds an accordion item to the accordion layer
     */
    addAccordionItem(state, action: PayloadAction<LayerId>) {
      const accordionLayer = state.layers[action.payload];

      if (!accordionLayer || accordionLayer.type !== LayerType.Accordion.Root)
        return;

      const lastItemId =
        accordionLayer.children[accordionLayer.children.length - 1];

      if (typeof lastItemId !== "string") return;

      const childLayers = getAllChildLayers(
        current(state.layers),
        lastItemId
      ).reduce((acc, layer) => {
        acc[layer.id] = layer;

        return acc;
      }, {} as Layers);

      const { layers: duplicatedLayers, rootLayerId } = randomizeLayerIds({
        layers: childLayers,
        rootLayerId: lastItemId,
        targetLayerId: accordionLayer.id,
      });

      if (rootLayerId === null) return;

      accordionLayer.children.push(rootLayerId);

      Object.values(duplicatedLayers).forEach((layer) => {
        state.layers[layer.id] = layer;
      });
    },
    /**
     * Initializes the drag select
     *
     * @param action The initial mouse point
     */
    initializeDragSelect(state, action: PayloadAction<Point>) {
      if (state.canvasMode !== "Select") return;

      const viewport: Viewport = {
        scale: state.scale,
        translateX: state.translateX,
        translateY: state.translateY,
      };

      const initialPosition = calculateMousePointRelativeToCanvas(
        action.payload,
        viewport
      );

      state.dragSelect = {
        initialPosition,
        currentPosition: initialPosition,
      };
    },
    /**
     * Selects elements within the drag select
     * @param action The current mouse point
     */
    dragSelect(state, action: PayloadAction<Point>) {
      dragSelectAction(state, action.payload);
    },
    finishDragSelect(state) {
      state.dragSelect = null;
    },
    /**
     * Updates the horizontal gap between the selected layers
     *
     * @action The new gap between the selected layers
     */
    setSelectedLayersHorizontalGap(state, action: PayloadAction<number>) {
      setSelectedLayersHorizontalGapAction(state, action.payload);
    },
    /**
     * Updates the vertical gap between the selected layers
     *
     * @action The new gap between the selected layers
     */
    setSelectedLayersVerticalGap(state, action: PayloadAction<number>) {
      setSelectedLayersVerticalGapAction(state, action.payload);
    },
    alignHorizontalLeft(state) {
      const boundingBoxLeft = getBoundingBoxRect(state.selectedLayerIds).left;

      state.selectedLayerIds.forEach((id) => {
        setLayerAbsoluteLeftAction(state, id, boundingBoxLeft);
      });
    },
    alignHorizontalMiddle(state) {
      const boundingBox = getBoundingBoxRect(state.selectedLayerIds);

      const middleAbsolutePosition = boundingBox.left + boundingBox.width / 2;

      state.selectedLayerIds.forEach((id) => {
        const layerDimensions = getLayerDimensions(id);

        const updatedAbsoluteLeftPositon =
          middleAbsolutePosition - layerDimensions.width / 2;

        setLayerAbsoluteLeftAction(state, id, updatedAbsoluteLeftPositon);
      });
    },
    alignHorizontalRight(state) {
      const boundingBox = getBoundingBoxRect(state.selectedLayerIds);
      const rightPosition = boundingBox.left + boundingBox.width;

      state.selectedLayerIds.forEach((id) => {
        const layerDimensions = getLayerDimensions(id);

        const updatedAbsoluteLeftPositon =
          rightPosition - layerDimensions.width;

        setLayerAbsoluteLeftAction(state, id, updatedAbsoluteLeftPositon);
      });
    },
    alignVerticalTop(state) {
      const boundingBoxTop = getBoundingBoxRect(state.selectedLayerIds).top;

      state.selectedLayerIds.forEach((id) => {
        setLayerAbsoluteTopAction(state, id, boundingBoxTop);
      });
    },
    alignVerticalMiddle(state) {
      const boundingBox = getBoundingBoxRect(state.selectedLayerIds);

      const middleAbsolutePosition = boundingBox.top + boundingBox.height / 2;

      state.selectedLayerIds.forEach((id) => {
        const layerDimensions = getLayerDimensions(id);

        const updatedAbsoluteTopPositon =
          middleAbsolutePosition - layerDimensions.height / 2;

        setLayerAbsoluteTopAction(state, id, updatedAbsoluteTopPositon);
      });
    },
    alignVerticalBottom(state) {
      const boundingBox = getBoundingBoxRect(state.selectedLayerIds);
      const topPosition = boundingBox.top + boundingBox.height;

      state.selectedLayerIds.forEach((id) => {
        const layerDimensions = getLayerDimensions(id);

        const updatedAbsoluteTopPositon = topPosition - layerDimensions.height;

        setLayerAbsoluteTopAction(state, id, updatedAbsoluteTopPositon);
      });
    },
    hoverLayer(state, action: PayloadAction<LayerId>) {
      if (action.payload === ROOT_LAYER_ID) {
        state.hoveredLayerId = null;
        return;
      }

      state.hoveredLayerId = action.payload;
    },
    startAltHoverDistance(state) {
      state.altHoverDistanceEnabled = true;
    },
    finishAltHoverDistance(state) {
      state.altHoverDistanceEnabled = false;
    },
    deleteTabletOverrides(state) {
      deleteTabletOverridesAction(state);
    },
    deleteMobileOverrides(state) {
      deleteMobileOverridesAction(state);
    },
    resetEditorState() {
      return initialEditorState;
    },
    groupSelectedLayers(state) {
      groupLayersAction(state);
    },
    ungroupLayer(state) {
      ungroupLayerAction(state);
    },
    startPanning(state) {
      state.isViewportTransforming = true;
      state.canvasMode = "Pan";
    },
    finishPanning(state) {
      state.isViewportTransforming = false;
      state.canvasMode = "Select";
    },
    setTextMaxContentWidth(state) {
      const { selectedLayerIds, layers, breakpoint } = state;

      selectedLayerIds.forEach((id) => {
        const layer = layers[id];

        if (layer?.type !== LayerType.Text) return;

        setLayerPropertiesAction({
          breakpoint,
          layers,
          updatedLayerIds: [id],
          properties: { width: null },
        });
      });
    },
    deleteTabletOverride(state, action: PayloadAction<BreakpointPropertyName>) {
      deleteTabletOverrideAction(state, action.payload);
    },
    deleteMobileOverride(state, action: PayloadAction<BreakpointPropertyName>) {
      deleteMobileOverrideAction(state, action.payload);
    },
    markLayerInformationAsReplaced(
      state,
      action: PayloadAction<{
        layerId: LayerId;
        type: LayerInformationToReplace["type"];
      }>
    ) {
      if (!state.droppedLayersInformationToReplace) return;

      const newValuesToReplace = state.droppedLayersInformationToReplace.map(
        (value) => {
          if (
            value.type === action.payload.type &&
            value.layerId === action.payload.layerId
          ) {
            return {
              ...value,
              replaced: true,
            };
          }

          return value;
        }
      );

      if (newValuesToReplace.every((value) => value.replaced === true)) {
        state.droppedLayersInformationToReplace = null;
        return;
      }

      state.droppedLayersInformationToReplace = newValuesToReplace;
    },
    copyLayerProperties: (state, action: PayloadAction<LayerId>) => {
      const layer = state.layers[action.payload];
      if (!layer) return;

      state.copiedLayerProperties = layer;
    },
    pasteLayerProperties: (state, action: PayloadAction<LayerId>) => {
      if (!state.copiedLayerProperties) {
        toast.error(
          "No properties to paste. Make sure you have copied properties first"
        );
        return;
      }

      const pasteToLayer = state.layers[action.payload];

      if (!pasteToLayer) {
        toast.error("Failed to paste properties");
        return;
      }

      if (pasteToLayer.type !== state.copiedLayerProperties.type) {
        toast.error(
          "We only support pasting properties to layers of the same type"
        );
        return;
      }

      const filteredProperties = Object.entries(
        state.copiedLayerProperties
      ).filter(([key]) => {
        if (
          key === "id" ||
          key === "parentId" ||
          key === "children" ||
          key === "hoverChildren" ||
          key === "optionUnavailableChildren" ||
          key === "checkedChildren" ||
          key === "top" ||
          key === "left" ||
          key === "text"
        ) {
          return false;
        }

        return true;
      });

      const properties = Object.fromEntries(filteredProperties);

      setLayerPropertiesAction({
        breakpoint: "desktop",
        layers: state.layers,
        properties,
        updatedLayerIds: [pasteToLayer.id],
      });
    },
    setExpandedBlockCollection(
      state,
      action: PayloadAction<BlockCollection | null>
    ) {
      state.expandedBlockCollection = action.payload;
    },
    setPreviouslySelectedFontFamily(state, action: PayloadAction<string>) {
      state.previouslySelectedFontFamily = action.payload;
    },
    setIsSwiperScriptLoaded(state, action: PayloadAction<boolean>) {
      state.isSwiperScriptLoaded = action.payload;
    },
    setIsIntercomLoaded(state, action: PayloadAction<boolean>) {
      state.isIntercomLoaded = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadPage.rejected, (state, action) => {
      state.error = action.error.message ?? null;
      state.loadingState = "error";
    });

    builder.addCase(loadPage.pending, (state) => {
      state.loadingState = "loading";
    });

    builder.addCase(loadPage.fulfilled, (state, action) => {
      try {
        const layers = compiledLayersSchema.Decode(action.payload);

        state.layers = layers;
        state.savedLayers = layers;
        state.loadingState = "loaded";

        const firstTextLayer = Object.values(layers).find(
          (layer) => layer.type === LayerType.Text
        ) as TextLayer | undefined;

        const firstFontFamily = firstTextLayer?.fontFamily ?? "Inter";
        state.previouslySelectedFontFamily = firstFontFamily;
      } catch (error) {
        state.error = "Invalid layers";
        state.loadingState = "error";
      }
    });
  },
});

export const editorSliceReducer = editorSlice.reducer;

export const {
  deleteMobileOverrides,
  deleteTabletOverrides,
  updateSavedLayers,
  bringLayerToFront,
  sendLayerToBack,
  openContextMenu,
  setVisibility,
  duplicateSelectedLayers,
  startDuplicating,
  pasteLayers,
  moveSelectedLayersDown,
  moveSelectedLayersUp,
  moveSelectedLayersLeft,
  moveSelectedLayersRight,
  addBlock,
  elementAddedEvent,
  addLayer,
  textAddedEvent,
  changeBreakpoint,
  clearSelectedLayers,
  deleteAllOverrides,
  deleteSelectedLayers,
  deleteLayer,
  deselectLayer,
  disableChangeVariantIndicators,
  drag,
  dragEnd,
  dragStart,
  enableChangeVariantIndicator,
  mouseEnter,
  mouseLeave,
  moveBlockDown,
  moveBlockUp,
  rearrangeLayer,
  selectLayer,
  setCanvasMode,
  setLayerProperties,
  setSelectedLayerIds,
  setSelectedLayerProperties,
  setSidebarState,
  unlockViewport,
  lockViewport,
  setViewport,
  setIsViewportTransforming,
  pauseHistory,
  resumeHistory,
  undo,
  redo,
  imageAddedEvent,
  initializeResizing,
  resize,
  finishResizing,
  setResizingShiftKey,
  closeContextMenu,
  togglePlainTextBold,
  togglePlainTextItalic,
  togglePlainTextUnderline,
  togglePlainTextStrikethrough,
  deleteVariable,
  editVariable,
  upsertColorVariable,
  upsertImageVariable,
  upsertTextVariable,
  changeTextContent,
  addCarouselSlide,
  addAccordionItem,
  initializeDragSelect,
  dragSelect,
  finishDragSelect,
  iconAddedEvent,
  setSelectedLayersHorizontalGap,
  setSelectedLayersVerticalGap,
  alignHorizontalLeft,
  alignHorizontalMiddle,
  alignHorizontalRight,
  alignVerticalTop,
  alignVerticalMiddle,
  alignVerticalBottom,
  startAltHoverDistance,
  finishAltHoverDistance,
  hoverLayer,
  recalculateDraggingGuideStopLines,
  resetEditorState,
  groupSelectedLayers,
  ungroupLayer,
  blockAddedEvent,
  templateAddedEvent,
  appendTemplateToLayers,
  replaceLayersWithTemplate,
  finishPanning,
  startPanning,
  setTextMaxContentWidth,
  deleteTabletOverride,
  deleteMobileOverride,
  clearDroppedLayersInformationToReplace,
  markLayerInformationAsReplaced,
  clearPendingDropTemplateLayers,
  copyLayerProperties,
  pasteLayerProperties,
  setExpandedBlockCollection,
  setPreviouslySelectedFontFamily,
  setIsSwiperScriptLoaded,
  setIsIntercomLoaded,
} = editorSlice.actions;
