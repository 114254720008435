import {
  calculateAbsolutePositionGuides,
  calculateAbsolutePositions,
} from "@core/features/editor/editorSlice/actions/utils/dragging";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getElement } from "@core/utils";
import { SelectedLayerIds } from "@folds/shared/types";

const disablePointerEvents = (selectedLayerIds: SelectedLayerIds) => {
  selectedLayerIds.forEach((layerId) => {
    const element = getElement(layerId);
    if (element === null) return;

    element.style.setProperty("pointer-events", "none");
  });
};

type Args = {
  event: {
    clientX: number;
    clientY: number;
  };
  scale: number;
};

export const dragAction = (state: DraftEditorState, { event, scale }: Args) => {
  if (state.dragging === null) return;

  // Calculate new position
  calculateAbsolutePositions(state, event, scale);

  // Calculate absolute position indicators
  const guides = calculateAbsolutePositionGuides({
    guideStopLines: state.dragging.guideStopLines,
    ids: state.dragging.draggingLayerIds,
  });

  // Mouse move in don't work properly without this, since the event won't reach layers under the dragged layer
  disablePointerEvents(state.dragging.draggingLayerIds);

  state.dragging.verticalGuides = guides.verticalGuides;
  state.dragging.horizontalGuides = guides.horizontalGuides;
};
