import { INDICATOR_SIZE } from "@/features/editor/components/AltHoverDistanceIndicator/constants";
import { checkIfLayersAreIntersectingVertically } from "@/features/editor/components/AltHoverDistanceIndicator/utils/checkIfLayersAreIntersectingVertically";
import { checkLayersAreIntersectingHorizontally } from "@/features/editor/components/AltHoverDistanceIndicator/utils/checkLayersAreIntersectingHorizontally";
import { roundDistance } from "@/features/editor/components/AltHoverDistanceIndicator/utils/roundDistance";
import { Box } from "@folds/shared";

type Args = {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
};

const getHorizontalIndicatorTop = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) => {
  const selectionBoundingBoxBottom =
    selectionBoundingBox.top + selectionBoundingBox.height;
  const targetLayerBoundingBoxBottom =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height;

  const top = Math.max(selectionBoundingBox.top, targetLayerBoundingBox.top);
  const bottom = Math.min(
    selectionBoundingBoxBottom,
    targetLayerBoundingBoxBottom
  );

  const topValue = top + (bottom - top) / 2;

  return topValue;
};

function RightDistanceIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) {
  const selectionBoundingBoxRight =
    selectionBoundingBox.left + selectionBoundingBox.width;
  const targetLayerBoundingBoxRight =
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const leftPosition = Math.min(
    selectionBoundingBoxRight,
    targetLayerBoundingBoxRight
  );

  const width = Math.abs(
    selectionBoundingBoxRight - targetLayerBoundingBoxRight
  );

  const top = getHorizontalIndicatorTop({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  const style: React.CSSProperties = {
    top,
    left: leftPosition,
    width,
    height: INDICATOR_SIZE,
  };

  const distance = roundDistance(width);

  return (
    <div className="absolute z-50 bg-[red]" style={style}>
      <p
        aria-label="right distance"
        className="absolute left-1/2 top-1 z-50 -translate-x-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

const getVerticalIndicatorLeft = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) => {
  const selectionBoxRightPosition =
    selectionBoundingBox.left + selectionBoundingBox.width;

  const targetLayerBoundingBoxRightPosition =
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const left = Math.max(selectionBoundingBox.left, targetLayerBoundingBox.left);

  const right = Math.min(
    selectionBoxRightPosition,
    targetLayerBoundingBoxRightPosition
  );

  const leftValue = left + (right - left) / 2;

  return leftValue;
};

function TopDistanceIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) {
  const top = Math.min(selectionBoundingBox.top, targetLayerBoundingBox.top);

  const height = Math.abs(
    selectionBoundingBox.top - targetLayerBoundingBox.top
  );

  const left = getVerticalIndicatorLeft({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  const style: React.CSSProperties = {
    top,
    height,
    left,
    width: INDICATOR_SIZE,
  };

  const distance = roundDistance(height);

  return (
    <div className="absolute z-50 bg-[red]" style={style}>
      <p
        aria-label="above distance"
        className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

function BottomDistanceIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) {
  const selectionBoundingBoxBottom =
    selectionBoundingBox.top + selectionBoundingBox.height;

  const targetLayerBoundingBoxBottom =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height;

  const top = Math.min(
    selectionBoundingBoxBottom,
    targetLayerBoundingBoxBottom
  );

  const height = Math.abs(
    selectionBoundingBoxBottom - targetLayerBoundingBoxBottom
  );

  const left = getVerticalIndicatorLeft({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  const style: React.CSSProperties = {
    top,
    left,
    width: INDICATOR_SIZE,
    height,
  };

  const distance = roundDistance(height);

  return (
    <div className="absolute z-50 bg-[red]" style={style}>
      <p
        aria-label="below distance"
        className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

function LeftDistanceIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) {
  const leftPosition = Math.min(
    selectionBoundingBox.left,
    targetLayerBoundingBox.left
  );

  const width = Math.abs(
    selectionBoundingBox.left - targetLayerBoundingBox.left
  );

  const top = getHorizontalIndicatorTop({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  const style: React.CSSProperties = {
    top,
    left: leftPosition,
    width,
    height: INDICATOR_SIZE,
  };

  const distance = roundDistance(width);

  return (
    <div className="absolute z-50 bg-[red]" style={style}>
      <p
        aria-label="left distance"
        className="absolute left-1/2 top-1 z-50 -translate-x-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

export function OverlappingIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) {
  const isIntersectingHorizontally = checkLayersAreIntersectingHorizontally({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });
  const isIntersectingVertically = checkIfLayersAreIntersectingVertically({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  if (!isIntersectingHorizontally || !isIntersectingVertically) {
    return null;
  }

  return (
    <>
      <RightDistanceIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
      <BottomDistanceIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
      <LeftDistanceIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
      <TopDistanceIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
    </>
  );
}
