import { SectionsDisabledProvider } from "@/features/editor/context/IsHeaderDisabled";
import { RenderLayer } from "@core/render";
import { useLoadFonts } from "@/features/editor/hooks/useLoadFonts";
import { getPreviewStore } from "@core/store";
import { ROOT_LAYER_ID } from "@folds/shared/constants";
import { Breakpoint, Layers, ShopifyProduct } from "@folds/shared/types";
import {
  BlockAddedEvent,
  ElementAddedEvent,
  TemplateAddedEvent,
} from "@folds/shared/types/src/events";
import { Provider } from "react-redux";
import { PreviewProductsProvider } from "@/features/editor/context/PreviewProductsContext";
import { twMerge } from "tailwind-merge";
import { RefObject, useRef } from "react";
import { getOverridenLayer } from "@folds/shared";

export function RenderPreviewLayers({
  layerId,
  layers,
  breakpoint,
}: {
  layers: Layers;
  layerId: string;
  breakpoint: Breakpoint;
}) {
  // Since we only use the desktop breakpoint we can use the initial values
  const layer = getOverridenLayer({ breakpoint, id: layerId, layers });

  if (!layer) return null;

  if ("children" in layer) {
    const childrenLayers = layer.children.map((childLayerId) => (
      <RenderPreviewLayers
        layerId={childLayerId}
        layers={layers}
        key={childLayerId}
        breakpoint={breakpoint}
      />
    ));

    const LayerComponent = RenderLayer({
      layer,
      children: childrenLayers,
    });

    return LayerComponent;
  }

  const LayerComponent = RenderLayer({ layer, children: null });

  return LayerComponent;
}

function LoadFonts({ children }: { children: React.ReactNode }) {
  useLoadFonts({ isInsideIframe: false });

  return children;
}

const useDragStart = ({
  layers,
  type,
  elementWidth,
}: {
  type: "element" | "block" | "template";
  layers: Layers;
  elementWidth: number;
}) => {
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    switch (type) {
      case "element": {
        const dragEvent: ElementAddedEvent = {
          type: "element",
          layers,
          width: elementWidth,
        };

        event.dataTransfer.setData(
          "application/json",
          JSON.stringify(dragEvent)
        );

        break;
      }
      case "block": {
        const dragEvent: BlockAddedEvent = {
          type: "block",
          layers,
        };

        event.dataTransfer.setData(
          "application/json",
          JSON.stringify(dragEvent)
        );

        break;
      }
      case "template": {
        const dragEvent: TemplateAddedEvent = {
          type: "template",
          layers,
        };

        event.dataTransfer.setData(
          "application/json",
          JSON.stringify(dragEvent)
        );

        break;
      }
    }
  };

  return handleDragStart;
};

const calculateTemplateHeight = (layers: Layers) => {
  const height = Object.values(layers).reduce((acc, layer) => {
    if (layer.type === "block") {
      return acc + layer.height;
    }

    return acc;
  }, 0);

  return height;
};

const useAutoScrollTemplate = ({
  type,
  layers,
  elementHeight,
  templateRef,
}: {
  type: "element" | "block" | "template";
  layers: Layers;
  elementHeight: number;
  templateRef: RefObject<HTMLDivElement>;
}) => {
  const animation = useRef<Animation | null>(null);

  const handleMouseEnter = () => {
    if (type !== "template" || !templateRef.current) return;

    const templateHeight = calculateTemplateHeight(layers);

    const translateDistance = templateHeight - elementHeight;

    const newAnimation = templateRef.current.animate(
      [
        { transform: "translateY(0)" },
        { transform: `translateY(-${translateDistance}px)` },
      ],
      {
        duration: 3500,
        fill: "forwards",
      }
    );

    animation.current = newAnimation;
  };

  const handleMouseLeave = () => {
    if (animation.current === null) return;

    animation.current.cancel();
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
  };
};

export function PreviewLayers({
  elementWidth,
  targetWidth,
  elementHeight,
  description,
  layers,
  type,
  products,
  breakpoint = "desktop",
  disableSections = true,
}: {
  elementWidth: number;
  elementHeight: number;
  targetWidth: number;
  layers: Layers;
  description: string;
  type: "element" | "block" | "template";
  products: ShopifyProduct[];
  breakpoint?: Breakpoint;
  disableSections?: boolean;
}) {
  const width = elementWidth > targetWidth ? targetWidth : elementWidth;
  const scale = width / elementWidth;
  const templateRef = useRef<HTMLDivElement>(null);

  const style: React.CSSProperties = {
    transform: `scale(${scale})`,
    width: elementWidth,
    height: elementHeight,
  };

  // We need to define these styles, since the css grid is sized based on the original size of the element not the scaled size
  // This allows the grid to wrap the elements correctly
  const outerWrapperStyle: React.CSSProperties = {
    width,
    height: elementHeight * scale,
  };

  const placeholderStore = getPreviewStore(layers, breakpoint);
  const handleDragStart = useDragStart({ layers, type, elementWidth });

  const { handleMouseEnter, handleMouseLeave } = useAutoScrollTemplate({
    elementHeight,
    layers,
    type,
    templateRef,
  });

  return (
    <PreviewProductsProvider
      // eslint-disable-next-line no-underscore-dangle
      value={products as unknown as ShopifyProduct[]}
    >
      <SectionsDisabledProvider value={disableSections}>
        <Provider store={placeholderStore}>
          <LoadFonts>
            <div
              style={outerWrapperStyle}
              className={twMerge(
                type !== "element" && "outline outline-1 outline-gray-5"
              )}
            >
              <div
                style={style}
                className="relative origin-top-left cursor-pointer overflow-hidden"
                draggable
                onDragStart={handleDragStart}
                aria-label={description}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
              >
                {/* Prevents click events from reaching children. This makes sure elements can be dragged. */}
                <div className="absolute left-0 top-0 z-50 h-full w-full" />
                <div ref={templateRef}>
                  <RenderPreviewLayers
                    layerId={ROOT_LAYER_ID}
                    layers={layers}
                    breakpoint={breakpoint}
                  />
                </div>
              </div>
            </div>
          </LoadFonts>
        </Provider>
      </SectionsDisabledProvider>
    </PreviewProductsProvider>
  );
}
