import { useIcons, Icons as IconsType } from "@/features/sidebar/api/getIcons";
import { SearchBar } from "@/features/sidebar/components/SearchBar";
import { useDebouncedValue } from "@/utils/useDebouncedValue";
import { IconAddedEvent } from "@folds/shared/types/src/events";
import { useState } from "react";
import { toast } from "@core/toast";

function IconsSearchResults({ searchValue }: { searchValue: string }) {
  const debouncedSearchValue = useDebouncedValue(searchValue, 250);

  const {
    data: iconsData,
    error,
    isLoading,
    setSize,
  } = useIcons(debouncedSearchValue);

  const handleLoadMore = async () => {
    try {
      await setSize((size) => size + 1);
    } catch {
      toast.error("Failed to load more icons");
    }
  };

  const handleDragStart = (event: React.DragEvent, iconId: string) => {
    const data: IconAddedEvent = {
      type: "icon",
      id: iconId,
    };

    event.dataTransfer.setData("application/json", JSON.stringify(data));
  };

  if (isLoading)
    return (
      <div className="grid grid-cols-4 gap-2">
        <div className="aspect-square animate-pulse rounded-sm bg-gray-4" />
        <div className="aspect-square animate-pulse rounded-sm bg-gray-4" />
        <div className="aspect-square animate-pulse rounded-sm bg-gray-4" />
        <div className="aspect-square animate-pulse rounded-sm bg-gray-4" />
      </div>
    );

  if (error !== undefined || iconsData === undefined)
    return <p className="text-sm">There was an issue loading the icons</p>;

  const icons = iconsData.reduce(
    (acc, value) => [...acc, ...value.icons],
    [] as IconsType
  );

  const iconsContent = icons.map((icon) => (
    <img
      src={icon.thumbnail_url}
      alt={icon.term}
      key={icon.id}
      onDragStart={(event) => handleDragStart(event, icon.id)}
    />
  ));

  const moreResultAvailable =
    typeof iconsData[iconsData.length - 1]?.next_page === "string";

  return (
    <div className="flex flex-col gap-3">
      {icons.length > 0 ? (
        <div className="grid grid-cols-4 gap-2">{iconsContent}</div>
      ) : (
        <p className="text-sm text-gray-11">
          No icons found. Try another search.
        </p>
      )}
      {moreResultAvailable && (
        <button
          className="w-full rounded bg-gray-12 py-3 text-sm text-white"
          type="button"
          onClick={handleLoadMore}
        >
          Load more
        </button>
      )}
    </div>
  );
}

function PlaceholderIcons() {
  const handleDragStart = (event: React.DragEvent, iconId: string) => {
    const data: IconAddedEvent = {
      type: "icon",
      id: iconId,
    };

    event.dataTransfer.setData("application/json", JSON.stringify(data));
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {placeholderIconIds.map((id) => (
        <img
          src={`https://static.thenounproject.com/png/${id}-200.png`}
          alt="Icon"
          key={id}
          onDragStart={(event) => handleDragStart(event, id)}
        />
      ))}
    </div>
  );
}

export function Icons() {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="m-3 flex flex-col gap-3">
      <SearchBar
        placeholder="Search icons"
        value={searchValue}
        onValueChange={setSearchValue}
      />
      {searchValue.length === 0 && <PlaceholderIcons />}
      {searchValue.length > 0 && (
        <IconsSearchResults searchValue={searchValue} />
      )}
    </div>
  );
}

// The icons are from this collection: https://thenounproject.com/browse/collection-icon/125261/
const placeholderIconIds = [
  "4100834",
  "4100833",
  "4100832",
  "4100831",
  "4100830",
  "4100829",
  "4100828",
  "4100827",
  "4100826",
  "4100825",
  "4100824",
  "4100823",
  "4100822",
  "4100821",
  "4100820",
  "4100819",
  "4100818",
  "4100817",
  "4100816",
  "4100815",
  "4100814",
  "4100813",
  "4100812",
  "4100811",
  "4100810",
  "4100809",
  "4100808",
  "4100807",
  "4100806",
  "4100805",
  "4100804",
  "4100803",
  "4100802",
  "4100801",
  "4100800",
  "4100799",
  "4100798",
  "4100797",
  "4100796",
  "4100795",
  "4100794",
  "4100793",
  "4100792",
  "4100791",
  "4100790",
  "4100789",
  "4100788",
  "4100787",
  "4100786",
  "4100785",
  "4100784",
  "4100783",
  "4100782",
  "4100781",
  "4100780",
  "4100779",
  "4100778",
  "4100777",
  "4100776",
  "4100775",
  "4100774",
  "4100773",
  "4100772",
  "4100771",
  "4100770",
  "4100769",
  "4100768",
  "4100767",
  "4100766",
  "4100765",
  "4100764",
  "4100763",
  "4100762",
  "4100761",
  "4100760",
  "4100759",
  "4100758",
  "4100757",
  "4100756",
  "4100755",
  "4100754",
  "4100753",
  "4100752",
  "4100751",
  "4100750",
  "4100749",
  "4100748",
  "4100747",
  "4100746",
  "4100745",
  "4100744",
  "4100743",
  "4100742",
  "4100741",
  "4100740",
  "4100739",
  "4100738",
  "4100737",
  "4100736",
  "4100735",
];
