import { useFrame } from "@/features/editor/context/FrameContext";
import { selectFontFamilies } from "@core/features/editor/editorSlice";
import { useFonts } from "@core/features/text/api/getFonts";
import { loadFontFamilies } from "@core/features/text/utils";
import { useAppSelector } from "@core/hooks";
import { modalStore } from "@core/modalStore";
import { useEffect } from "react";

/**
 * Loads font families into the document or iframe.
 */
export const useLoadFonts = ({
  isInsideIframe,
}: {
  isInsideIframe: boolean;
}) => {
  const fontFamilies = useAppSelector(selectFontFamilies);
  const { document: iFrameDocument } = useFrame();
  const { data: fonts } = useFonts();

  useEffect(() => {
    if (!fonts) return;

    if (isInsideIframe && iFrameDocument) {
      loadFontFamilies(fontFamilies, fonts, iFrameDocument);
      return;
    }
    const modalDocument = modalStore.getDocument();
    if (!modalDocument) return;
    loadFontFamilies(fontFamilies, fonts, modalDocument);
  }, [fontFamilies, fonts, iFrameDocument, isInsideIframe]);
};
