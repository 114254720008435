import { TypeCompiler } from "@sinclair/typebox/compiler";
import { useAxios } from "@lib/axios";
import { Shop } from "@folds/shared/typebox";
import useSWR from "swr";
import { Type } from "@sinclair/typebox";

// We need to pass a user hash to Intercom so bad actors can't impersonate users
// https://www.intercom.com/help/en/articles/183-set-up-identity-verification-for-web-and-mobile
const shopWithIntercomUserHash = Type.Intersect([
  Shop,
  Type.Object({ intercomUserHash: Type.String() }),
]);

const compiledShop = TypeCompiler.Compile(shopWithIntercomUserHash);

export const useShop = () => {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);
    const parsedResult = compiledShop.Decode(result.data);

    return parsedResult;
  };

  return useSWR("/shop", getResult);
};
