import { rearrangeLayerAction } from "@core/features/editor/editorSlice/actions/rearrangeLayer";
import { calculateGuideStopLines } from "@core/features/editor/editorSlice/actions/utils/calculateGuideStopLines";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { LayerId } from "@folds/shared/types";

export const mouseEnterAction = (
  state: DraftEditorState,
  parentId: LayerId
) => {
  const { layers, dragging } = state;

  if (dragging === null) return;

  // Move dragged layers into element
  dragging.draggingLayerIds.forEach((selectedLayerId) => {
    rearrangeLayerAction(state, {
      originalLayerId: selectedLayerId,
      newParentId: parentId,
      newIndex: 0,
    });
  });

  dragging.draggedIntoLayerId = parentId;

  const guideStopLines = calculateGuideStopLines({
    layers,
    ids: dragging.draggingLayerIds,
    breakpoint: state.breakpoint,
  });

  dragging.guideStopLines = guideStopLines;
};
