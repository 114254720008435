import { Type, type Static } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';

import { LayerType, backgroundSizeSchema } from './utils';

const fillTypeSchema = Type.Enum({
  color: 'color',
  image: 'image',
});

export const compiledFillTypeSchema = TypeCompiler.Compile(fillTypeSchema);

export type FillType = Static<typeof fillTypeSchema>;

const breakpointLayerSchema = Type.Object({
  type: Type.Literal(LayerType.Breakpoint),
  id: Type.String(),
  parentId: Type.String(),
  width: Type.Number(),
  left: Type.Number(),
  children: Type.Array(Type.String()),
});

export const breakpointLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(breakpointLayerSchema)
);

const pageLayerSchema = Type.Object({
  type: Type.Literal(LayerType.Page),
  id: Type.String(),
  parentId: Type.Null(),
  children: Type.Array(Type.String()),
});

export const pageLayerKeys = TypeCompiler.Compile(Type.KeyOf(pageLayerSchema));

const actionTypesSchema = Type.Union([
  Type.Literal('redirect'),
  Type.Literal('add-to-cart'),
]);

export type ActionTypes = Static<typeof actionTypesSchema>;

const addToCartActionSchema = Type.Object({
  type: Type.Literal('add-to-cart'),
  productId: Type.Union([Type.Number(), Type.Null()]),
  productHandle: Type.Union([Type.String(), Type.Null()]),
});

export type AddToCartAction = Static<typeof addToCartActionSchema>;

const redirectActionSchema = Type.Object({
  type: Type.Literal('redirect'),
  url: Type.Union([Type.String(), Type.Null()]),
});

export type RedirectAction = Static<typeof redirectActionSchema>;

const actionTypeSchema = Type.Union([
  Type.Literal('equals'),
  Type.Literal('add'),
  Type.Literal('subtract'),
]);
export type ActionType = Static<typeof actionTypeSchema>;

const actionSchema = Type.Union([addToCartActionSchema, redirectActionSchema]);

export type Action = Static<typeof actionSchema>;

const borderStyleEnum = Type.Union([
  Type.Literal('solid'),
  Type.Literal('dashed'),
  Type.Literal('dotted'),
]);

export const compiledBorderStyleSchema = TypeCompiler.Compile(borderStyleEnum);

const borderSchema = Type.Object({
  borderStyle: borderStyleEnum,
  borderTopWidth: Type.Number(),
  borderBottomWidth: Type.Number(),
  borderLeftWidth: Type.Number(),
  borderRightWidth: Type.Number(),
  borderColor: Type.String(),
});

const fillSchema = Type.Object({
  backgroundColor: Type.String(),
  backgroundImage: Type.Union([Type.String(), Type.Null()]),
  backgroundSize: backgroundSizeSchema,
  backgroundImageOverlay: Type.Union([Type.Null(), Type.String()]),
  fillType: fillTypeSchema,
  backgroundPositionX: Type.Number(),
  backgroundPositionY: Type.Number(),
});

const borderRadiusSchema = Type.Object({
  borderTopLeftRadius: Type.Number(),
  borderBottomLeftRadius: Type.Number(),
  borderBottomRightRadius: Type.Number(),
  borderTopRightRadius: Type.Number(),
});

const outlineSchema = Type.Object({
  outlineColor: Type.String(),
  outlineWidth: Type.Number(),
  outlineOffset: Type.Number(),
});

const textAlignEnum = Type.Union([
  Type.Literal('left'),
  Type.Literal('center'),
  Type.Literal('right'),
]);

export const compiledTextAlignSchema = TypeCompiler.Compile(textAlignEnum);

export type TextAlign = Static<typeof textAlignEnum>;

const textTransformEnum = Type.Union([
  Type.Literal('none'),
  Type.Literal('uppercase'),
  Type.Literal('lowercase'),
  Type.Literal('capitalize'),
]);

export const compiledTextTransformSchema =
  TypeCompiler.Compile(textTransformEnum);

export type TextTransform = Static<typeof textTransformEnum>;

const typographySchema = Type.Object({
  lineHeight: Type.Number(),
  letterSpacing: Type.Number(),
  textAlign: textAlignEnum,
  textTransform: textTransformEnum,
  italic: Type.Boolean(),
  underline: Type.Boolean(),
  lineThrough: Type.Boolean(),
  color: Type.String(),
  fontWeight: Type.Number(),
  fontSize: Type.Number(),
  fontFamily: Type.String(),
});

const alignmentSchema = Type.Union([
  Type.Literal('flex-start'),
  Type.Literal('center'),
  Type.Literal('flex-end'),
]);

export const compiledAlignmentSchema = TypeCompiler.Compile(alignmentSchema);

const carouselSchema = Type.Object({
  gap: Type.Number(),
  loop: Type.Boolean(),
  navigationEnabled: Type.Boolean(),
  navigationColor: Type.String(),
  paginationEnabled: Type.Boolean(),
  paginationActiveBulletColor: Type.String(),
  paginationInactiveBulletColor: Type.String(),
  scrollbarEnabled: Type.Boolean(),
  scrollbarTrackColor: Type.String(),
  scrollbarThumbColor: Type.String(),
  navigationSize: Type.Number(),
});

const positionSchema = Type.Object({
  top: Type.Number(),
  left: Type.Number(),
  // Needed so we know if we should cascade position changes to other breakpoints
  hasEditedDesktopPosition: Type.Boolean(),
  hasEditedTabletPosition: Type.Boolean(),
  hasEditedMobilePosition: Type.Boolean(),
});

const rootRectangleLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  positionSchema,
  fillSchema,
  borderSchema,
  borderRadiusSchema,
]);

const rectangleStateSchema = Type.Union([
  Type.Null(),
  Type.Literal('hover'),
  Type.Literal('parent-hover'),
  Type.Literal('parent-checked'),
  Type.Literal('product-unavailable'),
  Type.Literal('parent-option-unavailable'),
]);

export const compiledRectangleStateSchema =
  TypeCompiler.Compile(rectangleStateSchema);

const rectangleLayerSchemaWithStates = Type.Intersect([
  rootRectangleLayerSchema,
  Type.Object({
    hover: Type.Partial(rootRectangleLayerSchema),
    productUnavailable: Type.Partial(rootRectangleLayerSchema),
  }),
]);

const rectangleLayerSchema = Type.Intersect([
  rectangleLayerSchemaWithStates,
  Type.Object({
    type: Type.Literal(LayerType.Rectangle),
    id: Type.String(),
    parentId: Type.String(),
    action: Type.Union([actionSchema, Type.Null()]),
    state: rectangleStateSchema,
    productUnavailableId: Type.Union([Type.Number(), Type.Null()]),
    productUnavailableHandle: Type.Union([Type.String(), Type.Null()]),
    mobile: Type.Partial(rectangleLayerSchemaWithStates),
    tablet: Type.Partial(rectangleLayerSchemaWithStates),
  }),
]);

export const rectangleLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(rectangleLayerSchema)
);

const layoutGuideSchema = Type.Object({
  layoutGuideEnabled: Type.Boolean(),
  layoutGuideColumns: Type.Number(),
  layoutGuideColor: Type.String(),
  layoutGuideMarginTop: Type.Number(),
  layoutGuideMarginRight: Type.Number(),
  layoutGuideMarginBottom: Type.Number(),
  layoutGuideMarginLeft: Type.Number(),
  layoutGuideColumnGap: Type.Number(),
});

const rootBlockLayerSchema = Type.Intersect([
  Type.Object({
    type: Type.Literal(LayerType.Block),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  fillSchema,
  borderSchema,
  layoutGuideSchema,
]);

const blockLayerSchema = Type.Intersect([
  rootBlockLayerSchema,
  Type.Object({
    tablet: Type.Partial(rootBlockLayerSchema),
    mobile: Type.Partial(rootBlockLayerSchema),
  }),
]);

export const blockLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(blockLayerSchema)
);

const tagEnum = Type.Union([
  Type.Literal('h1'),
  Type.Literal('h2'),
  Type.Literal('h3'),
  Type.Literal('h4'),
  Type.Literal('h5'),
  Type.Literal('h6'),
  Type.Literal('p'),
]);

const textLayerContentSchema = Type.Union([
  Type.Literal('custom'),
  Type.Literal('variable'),
  Type.Literal('product-compare-at-price'),
  Type.Literal('product-price'),
  Type.Literal('selected-option-value'),
  Type.Literal('option-value'),
  Type.Literal('product-title'),
]);

export const compiledTextLayerContentSchema = TypeCompiler.Compile(
  textLayerContentSchema
);

const rootTextLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Union([Type.Number(), Type.Null()]),
    visible: Type.Boolean(),
  }),
  typographySchema,
  positionSchema,
]);

const textState = Type.Union([
  Type.Null(),
  Type.Literal('hover'),
  Type.Literal('parent-hover'),
  Type.Literal('parent-checked'),
  Type.Literal('product-unavailable'),
  Type.Literal('parent-option-unavailable'),
]);

export const compiledTextStateSchema = TypeCompiler.Compile(textState);

const textLayerSchemaWithStates = Type.Intersect([
  rootTextLayerSchema,
  Type.Object({
    hover: Type.Partial(rootTextLayerSchema),
    productUnavailable: Type.Partial(rootTextLayerSchema),
  }),
]);

const textLayerSchema = Type.Intersect([
  textLayerSchemaWithStates,
  Type.Object({
    type: Type.Literal(LayerType.Text),
    id: Type.String(),
    parentId: Type.String(),
    state: textState,
    content: textLayerContentSchema,
    text: Type.String(),
    tag: tagEnum,
    productUnavailableId: Type.Union([Type.Number(), Type.Null()]),
    productUnavailableHandle: Type.Union([Type.String(), Type.Null()]),
    productId: Type.Union([Type.Number(), Type.Null()]),
    productHandle: Type.Union([Type.String(), Type.Null()]),
    variableId: Type.Union([Type.String(), Type.Null()]),
    optionName: Type.Union([Type.String(), Type.Null()]),
    action: Type.Union([actionSchema, Type.Null()]),
    mobile: Type.Partial(textLayerSchemaWithStates),
    tablet: Type.Partial(textLayerSchemaWithStates),
  }),
]);

export type VariableTextLayer = Static<typeof textLayerSchema>;

export const textLayerKeys = TypeCompiler.Compile(Type.KeyOf(textLayerSchema));

const rootButtonLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  fillSchema,
  borderSchema,
  borderRadiusSchema,
  positionSchema,
  outlineSchema,
]);

const buttonState = Type.Union([
  Type.Null(),
  Type.Literal('hover'),
  Type.Literal('product-unavailable'),
]);

export const compiledButtonStateSchema = TypeCompiler.Compile(buttonState);

const rootButtonLayerSchemaWithStates = Type.Intersect([
  rootButtonLayerSchema,
  Type.Object({
    hover: Type.Partial(rootButtonLayerSchema),
    hoverChildren: Type.Record(
      Type.String(),
      Type.Record(Type.String(), Type.Unknown())
    ),
    productUnavailable: Type.Partial(rootButtonLayerSchema),
  }),
]);

const buttonLayerSchema = Type.Intersect([
  rootButtonLayerSchemaWithStates,
  Type.Object({
    type: Type.Literal(LayerType.Button),
    action: Type.Union([actionSchema, Type.Null()]),
    id: Type.String(),
    state: buttonState,
    productUnavailableId: Type.Union([Type.Number(), Type.Null()]),
    productUnavailableHandle: Type.Union([Type.String(), Type.Null()]),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(rootButtonLayerSchemaWithStates),
    tablet: Type.Partial(rootButtonLayerSchemaWithStates),
  }),
]);

export const buttonLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(buttonLayerSchema)
);

const flexDirection = Type.Union([Type.Literal('row'), Type.Literal('column')]);
export const compiledFlexDirectionSchema = TypeCompiler.Compile(flexDirection);

const variableTypeSchema = Type.Union([
  Type.Literal('image'),
  Type.Literal('color'),
  Type.Literal('text'),
]);

export const computedVariableTypeSchema =
  TypeCompiler.Compile(variableTypeSchema);

const variable = Type.Object({
  id: Type.String(),
  name: Type.String(),
  type: Type.Union([
    Type.Literal('image'),
    Type.Literal('color'),
    Type.Literal('text'),
  ]),
  defaultValue: Type.String(),
  values: Type.Array(
    Type.Object({ optionValue: Type.String(), value: Type.String() })
  ),
});

const variablesSchema = Type.Array(variable);

export type Variables = Static<typeof variablesSchema>;

const rootRootOptionSelectorSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Union([Type.Number(), Type.Null()]),
    gap: Type.Number(),
    visible: Type.Boolean(),
    flexDirection,
  }),
  positionSchema,
]);

const rootOptionSelectorSchema = Type.Intersect([
  rootRootOptionSelectorSchema,
  Type.Object({
    type: Type.Literal(LayerType.OptionSelector.Root),
    id: Type.String(),
    parentId: Type.String(),
    variables: variablesSchema,
    children: Type.Tuple([Type.String()]),
    productId: Type.Union([Type.Number(), Type.Null()]),
    productHandle: Type.Union([Type.String(), Type.Null()]),
    optionName: Type.Union([Type.String(), Type.Null()]),
    mobile: Type.Partial(rootRootOptionSelectorSchema),
    tablet: Type.Partial(rootRootOptionSelectorSchema),
  }),
]);

const rootOptionSelectorItemSchema = Type.Intersect([
  Type.Object({
    width: Type.Number(),
    height: Type.Number(),
  }),
  borderSchema,
  borderRadiusSchema,
  outlineSchema,
  fillSchema,
]);

const optionSelectorItemState = Type.Union([
  Type.Null(),
  Type.Literal('hover'),
  Type.Literal('option-unavailable'),
  Type.Literal('checked'),
]);

export const compiledOptionSelectorItemState = TypeCompiler.Compile(
  optionSelectorItemState
);

const optionSelectorItemWithStates = Type.Intersect([
  rootOptionSelectorItemSchema,
  Type.Object({
    hover: Type.Partial(rootOptionSelectorItemSchema),
    optionUnavailable: Type.Partial(rootRootOptionSelectorSchema),
    optionUnavailableChildren: Type.Record(
      Type.String(),
      Type.Record(Type.String(), Type.Unknown())
    ),
    checked: Type.Partial(rootOptionSelectorItemSchema),
    checkedChildren: Type.Record(
      Type.String(),
      Type.Record(Type.String(), Type.Unknown())
    ),
  }),
]);

const optionSelectorItemSchema = Type.Intersect([
  optionSelectorItemWithStates,
  Type.Object({
    type: Type.Literal(LayerType.OptionSelector.Item),
    state: optionSelectorItemState,
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(optionSelectorItemWithStates),
    tablet: Type.Partial(optionSelectorItemWithStates),
  }),
]);

export const rootOptionSelectorKeys = TypeCompiler.Compile(
  Type.KeyOf(rootOptionSelectorSchema)
);

export const optionSelectorItemKeys = TypeCompiler.Compile(
  Type.KeyOf(optionSelectorItemSchema)
);

const checkedItemStateEnum = Type.Union([
  Type.Literal('checked'),
  Type.Literal('default'),
]);

export const compiledCheckedItemStateEnum =
  TypeCompiler.Compile(checkedItemStateEnum);

const productImageCarouselRootSchema = Type.Object({
  type: Type.Literal(LayerType.ProductImageCarousel.Root),
  id: Type.String(),
  parentId: Type.String(),
  children: Type.Array(Type.String()),
  zIndex: Type.Number(),
  productId: Type.Union([Type.Number(), Type.Null()]),
  productHandle: Type.Union([Type.String(), Type.Null()]),
  visible: Type.Boolean(),
});

export const productImageCarouselRootKeys = TypeCompiler.Compile(
  Type.KeyOf(productImageCarouselRootSchema)
);

const rootProductImageCarouselFeaturedImageSchema = Type.Intersect([
  Type.Object({
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  positionSchema,
  carouselSchema,
  borderRadiusSchema,
]);

const productImageCarouselFeaturedImageSchema = Type.Intersect([
  rootProductImageCarouselFeaturedImageSchema,
  Type.Object({
    type: Type.Literal(LayerType.ProductImageCarousel.FeaturedImage),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    tablet: Type.Partial(rootProductImageCarouselFeaturedImageSchema),
    mobile: Type.Partial(rootProductImageCarouselFeaturedImageSchema),
  }),
]);

export const productImageCarouselFeaturedImageKeys = TypeCompiler.Compile(
  Type.KeyOf(productImageCarouselFeaturedImageSchema)
);

const rootProductImageCarouselThumbnailsSchema = Type.Intersect([
  Type.Object({
    gap: Type.Number(),
    width: Type.Number(),
    visible: Type.Boolean(),
  }),
  positionSchema,
  carouselSchema,
]);

const productImageCarouselThumbnailsSchema = Type.Intersect([
  rootProductImageCarouselThumbnailsSchema,
  Type.Object({
    type: Type.Literal(LayerType.ProductImageCarousel.Thumbnails),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Tuple([Type.String()]),
    tablet: Type.Partial(rootProductImageCarouselThumbnailsSchema),
    mobile: Type.Partial(rootProductImageCarouselThumbnailsSchema),
  }),
]);

export const productImageCarouselThumbnailsKeys = TypeCompiler.Compile(
  Type.KeyOf(productImageCarouselThumbnailsSchema)
);

const rootProductImageCarouselThumbnailSchema = Type.Intersect([
  Type.Object({
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  borderSchema,
  borderRadiusSchema,
]);

const productImageCarouselThumbnailSchema = Type.Intersect([
  rootProductImageCarouselThumbnailSchema,
  Type.Object({
    type: Type.Literal(LayerType.ProductImageCarousel.Thumbnail),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    tablet: Type.Partial(rootProductImageCarouselThumbnailSchema),
    mobile: Type.Partial(rootProductImageCarouselThumbnailSchema),
  }),
]);

export const productImageCarouselThumbnailKeys = TypeCompiler.Compile(
  Type.KeyOf(productImageCarouselThumbnailSchema)
);

/**
 * Accordion
 */
const rootAccordionRootLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    visible: Type.Boolean(),
  }),
  positionSchema,
]);

const accordionRootLayerSchema = Type.Intersect([
  rootAccordionRootLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Accordion.Root),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(rootAccordionRootLayerSchema),
    tablet: Type.Partial(rootAccordionRootLayerSchema),
  }),
]);

export const accordionRootKeys = TypeCompiler.Compile(
  Type.KeyOf(accordionRootLayerSchema)
);

const accordionItemLayerSchema = Type.Object({
  type: Type.Literal(LayerType.Accordion.Item),
  id: Type.String(),
  parentId: Type.String(),
  children: Type.Array(Type.String()),
});

export const accordionItemLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(accordionItemLayerSchema)
);

const rootAccordionTriggerLayerSchema = Type.Intersect([
  Type.Object({
    height: Type.Number(),
    backgroundColor: Type.String(),
  }),
  borderSchema,
  borderRadiusSchema,
]);

const accordionTriggerLayerSchema = Type.Intersect([
  rootAccordionTriggerLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Accordion.Trigger),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(rootAccordionTriggerLayerSchema),
    tablet: Type.Partial(rootAccordionTriggerLayerSchema),
  }),
]);

export const accordionTriggerLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(accordionTriggerLayerSchema)
);

const rootAccordionContentLayerSchema = Type.Intersect([
  Type.Object({
    height: Type.Number(),
    backgroundColor: Type.String(),
  }),
  borderSchema,
  borderRadiusSchema,
]);

const accordionContentLayerSchema = Type.Intersect([
  rootAccordionContentLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Accordion.Content),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(rootAccordionContentLayerSchema),
    tablet: Type.Partial(rootAccordionContentLayerSchema),
  }),
]);

export const accordionContentLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(accordionContentLayerSchema)
);

const rootAccordionChevronLayerSchema = Type.Object({
  fill: Type.String(),
});

const accordionChevronLayerSchema = Type.Intersect([
  rootAccordionChevronLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Accordion.Chevron),
    id: Type.String(),
    parentId: Type.String(),
    mobile: Type.Partial(rootAccordionChevronLayerSchema),
    tablet: Type.Partial(rootAccordionChevronLayerSchema),
  }),
]);

export const accordionChevronLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(accordionChevronLayerSchema)
);

const rootCarouselLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
  }),
  carouselSchema,
  positionSchema,
]);

const carouselRootLayerSchema = Type.Intersect([
  rootCarouselLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Carousel.Root),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    mobile: Type.Partial(rootCarouselLayerSchema),
    tablet: Type.Partial(rootCarouselLayerSchema),
  }),
]);

export const carouselRootLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(carouselRootLayerSchema)
);

const rootCarouselSlideLayerSchema = Type.Intersect([
  Type.Object({
    width: Type.Number(),
    visible: Type.Boolean(),
  }),
  fillSchema,
  borderSchema,
  borderRadiusSchema,
]);

const carouselSlideLayerSchema = Type.Intersect([
  rootCarouselSlideLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Carousel.Slide),
    id: Type.String(),
    parentId: Type.String(),
    children: Type.Array(Type.String()),
    action: Type.Union([actionSchema, Type.Null()]),
    mobile: Type.Partial(rootCarouselSlideLayerSchema),
    tablet: Type.Partial(rootCarouselSlideLayerSchema),
  }),
]);

export const carouselSlideLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(carouselSlideLayerSchema)
);

const rootQuantityPickerLayer = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
    iconColor: Type.String(),
    iconSize: Type.Number(),
  }),
  positionSchema,
  fillSchema,
  borderSchema,
  borderRadiusSchema,
  typographySchema,
]);

const quantityPickerLayerSchema = Type.Intersect([
  rootQuantityPickerLayer,
  Type.Object({
    type: Type.Literal(LayerType.QuantityPicker),
    id: Type.String(),
    parentId: Type.String(),
    productId: Type.Union([Type.Number(), Type.Null()]),
    productHandle: Type.Union([Type.String(), Type.Null()]),
    mobile: Type.Partial(rootQuantityPickerLayer),
    tablet: Type.Partial(rootQuantityPickerLayer),
  }),
]);

export const quantityPickerKeys = TypeCompiler.Compile(
  Type.KeyOf(quantityPickerLayerSchema)
);

const rootOptionDropdown = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
    iconColor: Type.String(),
    iconSize: Type.Number(),
    backgroundColor: Type.String(),
  }),
  positionSchema,
  borderSchema,
  borderRadiusSchema,
  typographySchema,
  outlineSchema,
]);

const optionDropdownLayerSchema = Type.Intersect([
  rootOptionDropdown,
  Type.Object({
    type: Type.Literal(LayerType.OptionDropdown),
    id: Type.String(),
    parentId: Type.String(),
    productId: Type.Union([Type.Number(), Type.Null()]),
    productHandle: Type.Union([Type.String(), Type.Null()]),
    optionName: Type.Union([Type.String(), Type.Null()]),
    mobile: Type.Partial(rootOptionDropdown),
    tablet: Type.Partial(rootOptionDropdown),
  }),
]);

export const optionDropdownLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(optionDropdownLayerSchema)
);

const rootIconLayer = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
    width: Type.Number(),
    height: Type.Number(),
    visible: Type.Boolean(),
    fill: Type.String(),
  }),
  positionSchema,
]);

export const iconLayer = Type.Intersect([
  rootIconLayer,
  Type.Object({
    type: Type.Literal(LayerType.Icon),
    id: Type.String(),
    parentId: Type.String(),
    svg: Type.String(),
    action: Type.Union([actionSchema, Type.Null()]),
    mobile: Type.Partial(rootIconLayer),
    tablet: Type.Partial(rootIconLayer),
  }),
]);

const groupLayerSchema = Type.Object({
  id: Type.String(),
  parentId: Type.String(),
  type: Type.Literal(LayerType.Group),
  children: Type.Array(Type.String()),
});

export const groupLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(groupLayerSchema)
);

const rootLiquidLayerSchema = Type.Intersect([
  Type.Object({
    zIndex: Type.Number(),
  }),
  positionSchema,
]);

const liquidLayerSchema = Type.Intersect([
  rootLiquidLayerSchema,
  Type.Object({
    type: Type.Literal(LayerType.Liquid),
    id: Type.String(),
    parentId: Type.String(),
    liquid: Type.String(),
    mobile: Type.Partial(rootLiquidLayerSchema),
    tablet: Type.Partial(rootLiquidLayerSchema),
  }),
]);

export const liquidLayerKeys = TypeCompiler.Compile(
  Type.KeyOf(liquidLayerSchema)
);

export const iconKeys = TypeCompiler.Compile(Type.KeyOf(iconLayer));

export type IconLayer = Static<typeof iconLayer>;

export const layerSchema = Type.Union([
  buttonLayerSchema,
  rectangleLayerSchema,
  pageLayerSchema,
  breakpointLayerSchema,
  textLayerSchema,
  blockLayerSchema,
  quantityPickerLayerSchema,
  optionDropdownLayerSchema,
  iconLayer,
  /**
   * Option selector
   */
  rootOptionSelectorSchema,
  optionSelectorItemSchema,
  /*
   * Accordion parts
   */
  accordionRootLayerSchema,
  accordionItemLayerSchema,
  accordionTriggerLayerSchema,
  accordionContentLayerSchema,
  accordionChevronLayerSchema,
  /*
   * Product image carousel
   */
  productImageCarouselRootSchema,
  productImageCarouselFeaturedImageSchema,
  productImageCarouselThumbnailsSchema,
  productImageCarouselThumbnailSchema,
  /**
   * Carousel
   */
  carouselRootLayerSchema,
  carouselSlideLayerSchema,
  groupLayerSchema,
  liquidLayerSchema,
]);

export const compiledLayerSchema = TypeCompiler.Compile(layerSchema);

export const layersSchema = Type.Record(Type.String(), layerSchema);
export const compiledLayersSchema = TypeCompiler.Compile(layersSchema);

export type Layer = Static<typeof layerSchema>;
export type Layers = Static<typeof layersSchema>;

export type QuantityPickerLayer = Static<typeof quantityPickerLayerSchema>;

/**
 * Accordion parts
 */
export type AccordionRootLayer = Static<typeof accordionRootLayerSchema>;
export type AccordionItemLayer = Static<typeof accordionItemLayerSchema>;
export type AccordionTriggerLayer = Static<typeof accordionTriggerLayerSchema>;
export type AccordionContentLayer = Static<typeof accordionContentLayerSchema>;
export type AccordionChevronLayer = Static<typeof accordionChevronLayerSchema>;

/**
 * Product image carousel
 */
export type ProductImageCarouselFeaturedImageLayer = Static<
  typeof productImageCarouselFeaturedImageSchema
>;
export type ProductImageCarouselThumbnailsLayer = Static<
  typeof productImageCarouselThumbnailsSchema
>;
export type ProductImageCarouselThumbnailLayer = Static<
  typeof productImageCarouselThumbnailSchema
>;
export type ProductImageCarouselRootLayer = Static<
  typeof productImageCarouselRootSchema
>;

/**
 * Carousel
 */
export type CarouselRootLayer = Static<typeof carouselRootLayerSchema>;
export type CarouselSlideLayer = Static<typeof carouselSlideLayerSchema>;

export type OptionSelectorRootLayer = Static<typeof rootOptionSelectorSchema>;
export type OptionSelectorItemLayer = Static<typeof optionSelectorItemSchema>;

export type ButtonLayer = Static<typeof buttonLayerSchema>;

export type BlockLayer = Static<typeof blockLayerSchema>;
export type RectangleLayer = Static<typeof rectangleLayerSchema>;

export type TextLayer = Static<typeof textLayerSchema>;

export type BreakpointLayer = Static<typeof breakpointLayerSchema>;
export type PageLayer = Static<typeof pageLayerSchema>;
export type OptionDropdownLayer = Static<typeof optionDropdownLayerSchema>;

export type GroupLayer = Static<typeof groupLayerSchema>;

export type LiquidLayer = Static<typeof liquidLayerSchema>;
