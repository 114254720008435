import { useCallback, useEffect } from "react";

import {
  Resizing,
  finishResizing,
  initializeResizing,
  resize,
  setResizingShiftKey,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import { store } from "@core/store";
import { useFrame } from "@/features/editor/context/FrameContext";

type Sides = "left" | "right" | "top" | "bottom";
type Corners = "top-left" | "top-right" | "bottom-left" | "bottom-right";

export type DragPosition = Sides | Corners;

export const useResize = () => {
  const dispatch = useAppDispatch();
  const { window: iFrameWindow } = useFrame();

  const handleMouseUp = useCallback(() => {
    const { resizing } = store.getState().editor;

    if (resizing === null) return;

    // Workaround to prevent text from being highlighted when resizing
    const selection = iFrameWindow?.getSelection();
    selection?.empty();

    dispatch(finishResizing());
  }, [dispatch, iFrameWindow]);

  const handleDrag = useCallback(
    (event: MouseEvent) => {
      const { resizing } = store.getState().editor;

      if (resizing === null) return;

      dispatch(resize({ x: event.clientX, y: event.clientY }));
    },
    [dispatch]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { resizing } = store.getState().editor;

      if (resizing === null) return;

      if (event.key === "Shift") {
        dispatch(setResizingShiftKey(true));
      }
    },
    [dispatch]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      const { resizing } = store.getState().editor;

      if (resizing === null) return;

      if (event.key === "Shift") {
        dispatch(setResizingShiftKey(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    iFrameWindow?.addEventListener("mousemove", handleDrag);
    iFrameWindow?.addEventListener("mouseup", handleMouseUp);
    iFrameWindow?.addEventListener("keydown", handleKeyDown);
    iFrameWindow?.addEventListener("keyup", handleKeyUp);

    return () => {
      iFrameWindow?.removeEventListener("mousemove", handleDrag);
      iFrameWindow?.removeEventListener("mouseup", handleMouseUp);
      iFrameWindow?.removeEventListener("keydown", handleKeyDown);
      iFrameWindow?.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleDrag, handleKeyDown, handleKeyUp, handleMouseUp, iFrameWindow]);

  return (event: React.MouseEvent, type: Resizing["type"]) => {
    event.stopPropagation();

    if (event.button !== 0) return;

    dispatch(
      initializeResizing({
        point: { x: event.clientX, y: event.clientY },
        type,
        shiftKey: event.shiftKey,
      })
    );
  };
};
