import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getNewTrackedChange } from "@core/utils/getNewTrackedChange";

export const resumeHistoryAction = (state: DraftEditorState) => {
  if (state.isHistoryEnabled.value === true) {
    return;
  }

  const { initialLayers, initialSelectedLayerIds } = state.isHistoryEnabled;

  const trackedChange = getNewTrackedChange({
    newLayers: state.layers,
    oldLayers: initialLayers,
    oldSelectedLayerIds: initialSelectedLayerIds,
  });

  // Limit to 250 changes to prevent running out of memory
  if (state.previousStates.length > 250) {
    state.previousStates.length = 250;
  }

  state.previousStates.unshift({
    changes: trackedChange.changes,
    selectedLayerIds: trackedChange.selectedLayerIds,
  });

  state.futureStates = [];

  state.isHistoryEnabled = {
    value: true,
    initialLayers: null,
    initialSelectedLayerIds: null,
  };
};
