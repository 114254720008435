import { usePage } from "@/features/dashboard/api/getPage";
import { PageIdProvider } from "@/features/editor/context/PageId";
import { PreviewLayers } from "@/features/sidebar/components/PreviewLayers";

const THUMBNAIL_WIDTH = 208;
const BORDER_WIDTH = 2;

function PagePreview({
  pageId,
  onSelect,
}: {
  pageId: string;
  onSelect: () => void;
}) {
  const { data: layers } = usePage(pageId);

  if (!layers) {
    return null;
  }

  return (
    <PageIdProvider pageId={pageId}>
      <div className="absolute left-0 top-0">
        <PreviewLayers
          layers={layers}
          description="Page thumbnail"
          products={[]}
          elementHeight={1000}
          elementWidth={1440}
          targetWidth={THUMBNAIL_WIDTH - BORDER_WIDTH}
          type="block"
          breakpoint="desktop"
          disableSections={false}
        />
      </div>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0) 0%, rgba(242,243,244,1) 70%)`,
          overflowClipMargin: "unset",
          overflow: "visible",
        }}
        className="absolute left-0 top-0 h-full w-full"
        onClick={onSelect}
      />
    </PageIdProvider>
  );
}

export function PageThumbnailImage({
  children,
  onSelect,
  pageId,
}: {
  children: React.ReactNode;
  onSelect: () => void;
  pageId: string;
}) {
  return (
    <div className="relative h-52 w-52 flex-shrink-0 cursor-pointer overflow-hidden rounded-xl border border-gray-4 bg-[#F2F3F4] bg-cover px-3 py-2">
      <PagePreview pageId={pageId} onSelect={onSelect} />
      {children}
    </div>
  );
}
