import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import {
  closeContextMenu,
  deleteLayer,
  bringLayerToFront,
  sendLayerToBack,
  copyLayerProperties,
  pasteLayerProperties,
} from "@core/features/editor/editorSlice";
import { DropdownMenuPortal } from "@lib/radix";

export function ContextMenu() {
  const dispatch = useAppDispatch();
  const contextMenu = useAppSelector((state) => state.editor.contextMenu);

  const handleCloseContextMenu = () => {
    dispatch(closeContextMenu());
  };

  if (contextMenu === null) return null;

  const handleDeleteLayer = () => {
    dispatch(deleteLayer(contextMenu.layerId));
  };

  const handleBringToFront = () => {
    dispatch(bringLayerToFront(contextMenu.layerId));
  };

  const handleSendToBack = () => {
    dispatch(sendLayerToBack(contextMenu.layerId));
  };

  const handleCopy = async () => {
    dispatch(copyLayerProperties(contextMenu.layerId));
  };

  const handlePaste = async () => {
    dispatch(pasteLayerProperties(contextMenu.layerId));
  };

  const style: React.CSSProperties = {
    top: contextMenu.position.y,
    left: contextMenu.position.x,
  };

  return (
    <DropdownMenu.Root open onOpenChange={handleCloseContextMenu}>
      <DropdownMenuPortal>
        <DropdownMenu.Content
          className="fixed z-50 w-44 rounded-lg border border-gray-6 bg-white shadow-sm"
          id="context-menu"
          // Stops from deselecting the selected layer when clicking on the context menu
          onMouseDown={(event) => event.stopPropagation()}
          style={style}
        >
          <DropdownMenu.Group className="p-1">
            <DropdownMenu.Item
              className="flex cursor-pointer justify-between rounded p-2 outline-0 hover:bg-gray-3"
              onSelect={handleDeleteLayer}
            >
              <p className="text-xs font-medium text-gray-12">Delete</p>
              <p className="text-xs">⌫</p>
            </DropdownMenu.Item>
          </DropdownMenu.Group>
          <DropdownMenu.Separator className="h-[1px] bg-gray-5" />
          <DropdownMenu.Group className="p-1">
            <DropdownMenu.Item
              onSelect={handleBringToFront}
              className="flex cursor-pointer justify-between rounded p-2 outline-0 hover:bg-gray-3"
            >
              <p className="text-xs font-medium text-gray-12">Bring to front</p>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onSelect={handleSendToBack}
              className="flex cursor-pointer justify-between rounded p-2 outline-0 hover:bg-gray-3"
            >
              <p className="text-xs font-medium text-gray-12">Send to back</p>
            </DropdownMenu.Item>
            {typeof navigator.clipboard !== "undefined" && (
              <DropdownMenu.Item
                onSelect={handleCopy}
                className="flex cursor-pointer justify-between rounded p-2 outline-0 hover:bg-gray-3"
              >
                <p className="text-xs font-medium text-gray-12">
                  Copy properties
                </p>
              </DropdownMenu.Item>
            )}
            {typeof navigator.clipboard !== "undefined" && (
              <DropdownMenu.Item
                onSelect={handlePaste}
                className="flex cursor-pointer justify-between rounded p-2 outline-0 hover:bg-gray-3"
              >
                <p className="text-xs font-medium text-gray-12">
                  Paste properties
                </p>
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenuPortal>
    </DropdownMenu.Root>
  );
}
