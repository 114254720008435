import { FontItems } from "@folds/shared/types";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export const useFonts = () => {
  const axios = useAxios();

  const getFonts = async (key: string) => {
    const { data: fonts } = await axios.get(key);

    return fonts;
  };

  return useSWR<FontItems>("/fonts", getFonts);
};
