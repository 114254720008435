import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";

export const searchResourcesResultSchema = TypeCompiler.Compile(
  Type.Array(
    Type.Object({
      id: Type.String(),
      title: Type.String(),
      url: Type.String(),
    })
  )
);
