import { useEventConnector } from "@core/events";
import { selectCascadingVariablesForId } from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import { OptionValueContext } from "@core/user-components/OptionSelector";
import { LexicalPlainText } from "@core/user-components/LexicalPlainText/LexicalPlainText";
import { CompareAtPrice, ProductPrice } from "@core/user-components/Price";
import { generateTextStyles } from "@folds/shared/layers";
import { useContext } from "react";
import { TextLayer, VariableTextLayer } from "@folds/shared/types";
import { useProduct } from "@/features/sidebar/api/getProduct";
import { ProductTitle } from "@core/user-components/ProductTitle";

const useTextVariableValue = (layerId: string, variableId: string | null) => {
  const variables = useAppSelector((state) =>
    selectCascadingVariablesForId(state, layerId)
  );

  const optionValue = useContext(OptionValueContext);

  const foundVariable = variables.find(
    (variable) => variable.id === variableId
  );

  if (foundVariable === undefined) {
    return "Missing text variable";
  }

  const foundValue = foundVariable.values.find(
    (value) => value.optionValue === optionValue
  )?.value;

  if (foundValue === undefined) {
    return foundVariable.defaultValue;
  }

  return foundValue;
};

function TextVariable({ layer }: { layer: VariableTextLayer }) {
  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(layer.id);

  const variableValue = useTextVariableValue(layer.id, layer.variableId);

  const style = generateTextStyles(layer);

  return (
    <div
      style={style}
      id={layer.id}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
    >
      {variableValue}
    </div>
  );
}

function SelectedOptionValue({ layer }: { layer: TextLayer }) {
  const { data: product, error, isLoading } = useProduct(layer.productId);

  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(layer.id);

  const style = generateTextStyles(layer);

  const matchingOption = product?.options.find(
    (option) => option.name === layer.optionName
  );

  if (
    layer.productId === null ||
    layer.optionName === null ||
    matchingOption === undefined
  ) {
    return (
      <div
        style={style}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        onMouseOver={handleMouseOver}
        className="select-none"
      >
        Select an option
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={style}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        onMouseOver={handleMouseOver}
        className="select-none"
      >
        Loading...
      </div>
    );
  }

  if (error !== undefined || product === undefined) {
    return (
      <div
        style={style}
        id={layer.id}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
        onMouseOver={handleMouseOver}
        className="select-none"
      >
        There was an issue loading the option
      </div>
    );
  }

  return (
    <div
      style={style}
      id={layer.id}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
      className="select-none"
    >
      {matchingOption.values[0] ?? "No value"}
    </div>
  );
}

function CustomText({ layer }: { layer: TextLayer }) {
  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(layer.id);
  const style = generateTextStyles(layer);

  return (
    <div
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
      id={layer.id}
      style={style}
      aria-label="Text layer"
    >
      <LexicalPlainText
        contentEditableStyle={{}}
        layerId={layer.id}
        text={layer.text}
      />
    </div>
  );
}

function OptionValue({ layer }: { layer: TextLayer }) {
  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(layer.id);
  const style = generateTextStyles(layer);

  const optionValue = useContext(OptionValueContext);

  return (
    <div
      style={style}
      id={layer.id}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
    >
      {optionValue ?? "No option found"}
    </div>
  );
}

export function Text({ layer }: { layer: TextLayer }) {
  switch (layer.content) {
    case "custom": {
      return <CustomText layer={layer} />;
    }
    case "product-compare-at-price": {
      return <CompareAtPrice layer={layer} />;
    }
    case "product-price": {
      return <ProductPrice layer={layer} />;
    }
    case "variable": {
      return <TextVariable layer={layer} />;
    }
    case "selected-option-value": {
      return <SelectedOptionValue layer={layer} />;
    }
    case "option-value": {
      return <OptionValue layer={layer} />;
    }
    case "product-title": {
      return <ProductTitle layer={layer} />;
    }
  }
}
