import type { DraftEditorState } from "@core/features/editor/editorSlice";
import { addLayerAction } from "@core/features/editor/editorSlice/actions/addLayerAction";
import { getResponsiveTextLayer } from "@core/features/editor/editorSlice/actions/addTextLayer/utils/getResponsiveTextLayer";
import { LayerId, LayerType, Layers, Position } from "@folds/shared/types";

const getBreakpointLayer = (layers: Layers) => {
  const breakpoint = Object.values(layers).find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (!breakpoint || breakpoint.type !== LayerType.Breakpoint) {
    return null;
  }

  return breakpoint;
};

type Args = {
  fontSize: number;
  lineHeight: number;
  text: string;
  position: Position;
  targetLayerId: LayerId;
  fontFamily: string;
};

export const addTextLayerAction = (
  state: DraftEditorState,
  { fontSize, lineHeight, text, position, targetLayerId, fontFamily }: Args
) => {
  const breakpointLayer = getBreakpointLayer(state.layers);

  if (breakpointLayer === null) return;

  const responsiveTextLayer = getResponsiveTextLayer({
    breakpoint: state.breakpoint,
    layers: state.layers,
    targetLayerId,
    fontFamily,
    text,
    fontSize,
    lineHeight,
    position,
  });

  addLayerAction(state.layers, responsiveTextLayer, undefined);
};
