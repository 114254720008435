import { PageInformationWithNumberId } from "@/features/editor/api/getPageInformation";
import { useSelectedPageTitle } from "@/features/editor/components/PageInformation/PageInformationProductSelector";
import { useSelectedProductTitle } from "@/features/sidebar/components/OptionSelector";
import { ShopifyPage, ShopifyProduct } from "@folds/shared";

function StandalonePageInformationTitle({
  pageId,
}: {
  pageId: ShopifyPage["id"] | null;
}) {
  const pageTitle = useSelectedPageTitle(pageId);

  return (
    <div className="flex min-w-0 gap-1">
      <div>Page</div>
      <div>-</div>
      <div className="min-w-0">{pageTitle}</div>
    </div>
  );
}

function ProductPageInformationTitle({
  productId,
}: {
  productId: ShopifyProduct["id"] | null;
}) {
  const pageTitle = useSelectedProductTitle(productId);

  return (
    <div className="flex min-w-0 gap-1">
      <div>Product</div>
      <div>-</div>
      <div className="min-w-0">{pageTitle}</div>
    </div>
  );
}

export function PageInformationTitle({
  pageInformation,
}: {
  pageInformation: PageInformationWithNumberId;
}) {
  switch (pageInformation.type) {
    case "product": {
      return (
        <ProductPageInformationTitle productId={pageInformation.shopifyId} />
      );
    }
    case "home": {
      return <div className="flex min-w-0 gap-1">Home</div>;
    }
    case "standalone": {
      return (
        <StandalonePageInformationTitle pageId={pageInformation.shopifyId} />
      );
    }
    case "not-found": {
      return <div className="flex min-w-0 gap-1">Not found / 404 page</div>;
    }
  }
}
