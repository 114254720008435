import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import {
  applyTrackedState,
  getCurrentChanges,
} from "@core/features/editor/editorSlice/actions/utils/changes";
import type {
  DraftEditorState,
  TrackedStateChange,
} from "@core/features/editor/editorSlice/editorSlice";

export const redoAction = (state: DraftEditorState) => {
  const futureState = state.futureStates.shift();

  if (!futureState) return;

  const currentSelectedLayerIds = state.selectedLayerIds;

  const currentChanges = getCurrentChanges(state.layers, futureState.changes);
  applyTrackedState(state, futureState);

  setSelectedLayerIdsAction(state, futureState.selectedLayerIds);

  const currentState: TrackedStateChange = {
    selectedLayerIds: currentSelectedLayerIds,
    changes: currentChanges,
  };

  state.previousStates.unshift(currentState);
};
