import { useFrame } from "@/features/editor/context/FrameContext";
import {
  finishPanning,
  setViewport,
  startPanning,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { isMiddleClick } from "@core/utils/click";
import { Point } from "@folds/shared/types";
import { store } from "@store";
import { useCallback, useEffect, useRef } from "react";

const getMousePositionDifference = ({
  initialMousePosition,
  event,
}: {
  initialMousePosition: Point;
  event: MouseEvent;
}) => {
  const { clientX, clientY } = event;

  return {
    x: clientX - initialMousePosition.x,
    y: clientY - initialMousePosition.y,
  };
};

export const useHandPan = () => {
  const initialMousePosition = useRef<Point>({ x: 0, y: 0 });
  const initialViewport = useRef({ translateX: 0, translateY: 0 });

  const { window: iFrameWindow } = useFrame();

  const isDragging = useRef(false);

  const dispatch = useAppDispatch();

  const handleDrag = useCallback(
    (event: MouseEvent) => {
      if (isDragging.current === false) return;

      const { x, y } = getMousePositionDifference({
        event,
        initialMousePosition: initialMousePosition.current,
      });

      const {
        editor: { scale },
      } = store.getState();

      dispatch(
        setViewport({
          scale,
          translateX: initialViewport.current.translateX + x,
          translateY: initialViewport.current.translateY + y,
        })
      );
    },
    [dispatch]
  );

  const handleMouseUp = useCallback(() => {
    initialMousePosition.current = { x: 0, y: 0 };
    initialViewport.current = { translateX: 0, translateY: 0 };

    dispatch(finishPanning());
    isDragging.current = false;
  }, [dispatch]);

  const initializeDrag = useCallback(
    (event: MouseEvent) => {
      const {
        editor: { translateX, translateY, isViewportLocked },
      } = store.getState();

      if (!isMiddleClick(event)) return;

      if (isViewportLocked) return;

      dispatch(startPanning());

      initialMousePosition.current = { x: event.clientX, y: event.clientY };
      initialViewport.current = {
        translateX,
        translateY,
      };
      isDragging.current = true;
    },
    [dispatch]
  );

  useEffect(() => {
    iFrameWindow?.addEventListener("mousedown", initializeDrag);
    iFrameWindow?.addEventListener("mousemove", handleDrag);
    iFrameWindow?.addEventListener("mouseup", handleMouseUp);

    return () => {
      iFrameWindow?.removeEventListener("mousedown", initializeDrag);
      iFrameWindow?.removeEventListener("mousemove", handleDrag);
      iFrameWindow?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleDrag, handleMouseUp, iFrameWindow, initializeDrag]);
};
