import type { EditorState } from "@core/features/editor/editorSlice/editorSlice";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction";
import { LayerId, LayerType } from "@folds/shared";
import { deleteLayerAction } from "@core/features/editor/editorSlice/actions/deleteLayerAction";

const deleteEmptyTextLayers = (state: EditorState, ids: LayerId[]) => {
  ids.forEach((id) => {
    const layer = state.layers[id];

    if (
      layer === undefined ||
      layer.type !== LayerType.Text ||
      layer.content !== "custom"
    )
      return;

    if (layer.text.length === 0) {
      deleteLayerAction(state, id);
    }
  });
};

/**
 * Updates the selected layer ids and resets the state of the layers that are no longer selected
 */
export const setSelectedLayerIdsAction = (
  state: EditorState,
  layerIds: string[]
) => {
  if (layerIds.length === 0) {
    state.sidebarState = "templates";
  }

  if (layerIds.length > 0) {
    state.sidebarState = "styles";
  }

  const previousSelectedLayerIds = state.selectedLayerIds;

  const layerIdsThatAreNoLongerSelected = previousSelectedLayerIds.filter(
    (id) => !layerIds.includes(id)
  );

  const layerIdsThatHaveNotBeenDeleted = layerIdsThatAreNoLongerSelected.filter(
    (id) => state.layers[id] !== undefined
  );

  // Reset the state of the layers that are no longer selected
  setLayerPropertiesAction({
    layers: state.layers,
    breakpoint: state.breakpoint,
    updatedLayerIds: layerIdsThatHaveNotBeenDeleted,
    properties: {
      state: null,
    },
  });

  deleteEmptyTextLayers(state, layerIdsThatHaveNotBeenDeleted);

  state.selectedLayerIds = layerIds;
};
