import { useAxios } from "@lib/axios";
import useSWRInfinite from "swr/infinite";

export type Icons = {
  thumbnail_url: string;
  id: string;
  term: string;
  collections: { id: string }[];
}[];

type IconsResponse = {
  next_page?: string;
  icons: Icons;
};

const getKey = (
  pageIndex: number,
  previousPageData: IconsResponse | undefined,
  query: string
) => {
  // Reached the end
  if (previousPageData && typeof previousPageData.next_page === "undefined")
    return null;

  // First page, we don't have `previousPageData`
  if (pageIndex === 0) return `/icons?query=${query}`;

  // Fetch the next page
  return `/icons?query=${query}&cursor=${previousPageData?.next_page}`;
};

export const useIcons = (query: string) => {
  const authAxios = useAxios();

  const getIcons = async (key: string) => {
    const response = await authAxios.get(key);

    return response.data as IconsResponse;
  };

  return useSWRInfinite(
    (pageIndex, previousPageData: IconsResponse | undefined) =>
      getKey(pageIndex, previousPageData, query),
    getIcons
  );
};
