import {
  BlockCollection,
  compiledComponentApiResponseSchema,
} from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

export const useBlocks = ({
  limit,
  collection,
}: {
  limit: number;
  collection: BlockCollection;
}) => {
  const axios = useAxios();
  const fetchBlocks = async (key: string) => {
    const response = await axios.get(key);

    return compiledComponentApiResponseSchema.Decode(response.data);
  };

  return useSWR(`/blocks?collection=${collection}&limit=${limit}`, fetchBlocks);
};
