import {
  selectFirstSelectedLayerName,
  setSelectedLayerIds,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getAncestorIds, getLayerName } from "@core/utils";
import { Layer, LayerId } from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@core/store";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { DropdownMenuPortal } from "@lib/radix";

const selectBreadcrumbs = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, ids): { name: string; id: LayerId }[] => {
    const firstSelectedLayerId = ids[0];

    if (firstSelectedLayerId === undefined) return [];

    const parentLayerIds = getAncestorIds(layers, firstSelectedLayerId);

    // Sort the parent layers so that the first layer is the top-most layer
    const reversedParentLayerIds = [...parentLayerIds].reverse();

    const parentLayers = reversedParentLayerIds
      .map((id) => layers[id])
      .filter((layer) => layer !== undefined) as Layer[];

    const breadcrumbs = parentLayers
      .map((parent) => ({ name: getLayerName(parent), id: parent.id }))
      .filter(
        (breadcrumb) =>
          breadcrumb.name !== "Page" &&
          breadcrumb.name !== "Breakpoint" &&
          breadcrumb.name !== "Block"
      );

    return breadcrumbs;
  }
);

function ChevronRightIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke-gray-8"
        d="M6 12L10 8L6 4"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function InBetweenBreadcrumbs({
  breadcrumbs,
  onSelect,
}: {
  breadcrumbs: { name: string; id: LayerId }[];
  onSelect: (id: LayerId) => void;
}) {
  const firstBreadcrumb = breadcrumbs[0];

  if (!firstBreadcrumb) return null;

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger type="button" className="text-xs text-gray-11">
          ...
        </DropdownMenu.Trigger>
        <DropdownMenuPortal>
          <DropdownMenu.Content
            sideOffset={5}
            className="z-50 rounded border border-gray-6 bg-gray-2 p-1"
          >
            {breadcrumbs.map((breadcrumb) => {
              if (breadcrumb.name === firstBreadcrumb.name) return null;

              return (
                <DropdownMenu.Item
                  onSelect={() => onSelect(breadcrumb.id)}
                  key={breadcrumb.id}
                  className="w-28 cursor-pointer rounded-sm text-xs text-gray-12 data-[highlighted]:bg-gray-3 data-[highlighted]:outline-none"
                >
                  <p className="truncate px-2 py-1 text-xs text-gray-12">
                    {breadcrumb.name}
                  </p>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenuPortal>
      </DropdownMenu.Root>
      <ChevronRightIcon />
    </>
  );
}

export function Breadcrumbs() {
  const dispatch = useAppDispatch();
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const firstSelectedLayerName = useAppSelector(selectFirstSelectedLayerName);

  const selectBreadcrumb = (id: LayerId) => {
    dispatch(setSelectedLayerIds([id]));
  };

  if (breadcrumbs.length === 0)
    return (
      <div className="flex items-center gap-1 border-b border-gray-6 py-3 pl-3">
        <p className="text-xs text-gray-12">{firstSelectedLayerName}</p>
      </div>
    );

  const firstBreadcrumb = breadcrumbs[0];

  if (breadcrumbs.length === 0 || firstBreadcrumb === undefined) return null;

  const isExtraLong = breadcrumbs.length > 1;

  return (
    <div className="flex items-center gap-1 border-b border-gray-6 py-3 pl-3">
      <button
        type="button"
        className="text-xs text-gray-11"
        onClick={() => selectBreadcrumb(firstBreadcrumb.id)}
      >
        {firstBreadcrumb.name}
      </button>
      <ChevronRightIcon />
      {isExtraLong && (
        <InBetweenBreadcrumbs
          breadcrumbs={breadcrumbs}
          onSelect={selectBreadcrumb}
        />
      )}
      <button type="button" className="text-xs text-gray-12">
        {firstSelectedLayerName}
      </button>
    </div>
  );
}
