import { editorSliceReducer } from "@core/features/editor/editorSlice";
import { syncStoreEnhancer } from "@core/syncStoreEnhancer";
import { Breakpoint, Layers } from "@folds/shared";
import * as Sentry from "@sentry/react";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  editor: editorSliceReducer,
});

/**
 * Wraps a previewed element in the sidebar
 */
export const getPreviewStore = (layers: Layers, breakpoint: Breakpoint) => {
  const state: RootState = {
    editor: {
      previouslySelectedFontFamily: "Inter",
      expandedBlockCollection: null,
      isSwiperScriptLoaded: false,
      isIntercomLoaded: false,
      breakpoint,
      layers,
      loadingState: "initial",
      contextMenu: null,
      error: null,
      canvasMode: "Select",
      sidebarState: "templates",
      resizing: null,
      dragging: null,
      dragSelect: null,
      selectedLayerIds: [],
      changeVariantIndicatorsEnabled: true,
      savedLayers: {},
      isHistoryEnabled: {
        value: true,
        initialLayers: null,
        initialSelectedLayerIds: null,
      },
      translateX: 0,
      translateY: 0,
      scale: 1,
      duplicatingLayerIds: null,
      isViewportLocked: false,
      isViewportTransforming: false,
      previousStates: [],
      futureStates: [],
      altHoverDistanceEnabled: false,
      hoveredLayerId: null,
      droppedLayersInformationToReplace: null,
      pendingDropTemplateLayers: null,
      copiedLayerProperties: null,
    },
  };

  return configureStore({
    reducer: rootReducer,
    preloadedState: state,
  });
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [syncStoreEnhancer, sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];
