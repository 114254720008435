export const checkIfEventTargetIsTextElement = (
  eventTarget: EventTarget | null
) => {
  const isContentEditable =
    eventTarget &&
    "isContentEditable" in eventTarget &&
    eventTarget.isContentEditable === true;

  const isInputElement =
    (eventTarget as HTMLElement | null)?.tagName === "INPUT";

  const isTextArea =
    (eventTarget as HTMLElement | null)?.tagName === "TEXTAREA";

  return (
    Boolean(isContentEditable) || Boolean(isInputElement) || Boolean(isTextArea)
  );
};
