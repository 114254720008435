import { getElement } from "@core/utils/getElement";
import { LayerId } from "@folds/shared/types";

type Dimensions = {
  width: number;
  height: number;
};

/**
 * Get the dimensions of an element with subpixel percision
 */
export const getLayerDimensions = (layerId: LayerId): Dimensions => {
  const canvasElement = getElement("canvas");
  const element = getElement(layerId);

  if (element === null || canvasElement === null) {
    return { width: 0, height: 0 };
  }

  const scaleString =
    canvasElement.style.transform.match(/scale\(([0-9.-]+)\)/)?.[1];

  if (scaleString === undefined) {
    return { width: 0, height: 0 };
  }

  const scale = Number(scaleString);

  const elementRect = element.getBoundingClientRect();

  return {
    width: elementRect.width / scale,
    height: elementRect.height / scale,
  };
};
