import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction";
import {
  IntrinsicallyPositionedLayer,
  checkIfLayerIsIntrinsicallyPositioned,
} from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { getRelativePosition } from "@core/features/editor/editorSlice/actions/utils/dragging";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getLayerRelativePosition } from "@core/utils";
import { LayerId } from "@folds/shared";

const assignIntrinsicallyPositionedTopProperty = (
  state: DraftEditorState,
  layer: IntrinsicallyPositionedLayer,
  value: number
) => {
  const lowestTopValue = layer.children.reduce((acc, childId) => {
    const childTop = getLayerRelativePosition(childId).top;

    return childTop < acc ? childTop : acc;
  }, Infinity);

  layer.children.forEach((childId) => {
    const topOffset = getLayerRelativePosition(childId).top;

    const positionFromTopEdge = topOffset - lowestTopValue;

    const relativePosition = getRelativePosition({
      layerId: childId,
      position: { top: value + positionFromTopEdge, left: 0 },
      layers: state.layers,
    });

    if (relativePosition === null) return;

    setLayerPropertiesAction({
      breakpoint: state.breakpoint,
      layers: state.layers,
      updatedLayerIds: [childId],
      properties: {
        top: relativePosition.top,
      },
    });
  });
};

export const setLayerAbsoluteTopAction = (
  state: DraftEditorState,
  id: LayerId,
  value: number
) => {
  const layer = state.layers[id];
  if (!layer) return;

  const isInstrinsicallyPositioned =
    checkIfLayerIsIntrinsicallyPositioned(layer);

  if (isInstrinsicallyPositioned) {
    assignIntrinsicallyPositionedTopProperty(state, layer, value);
    return;
  }

  const relativePosition = getRelativePosition({
    layerId: layer.id,
    position: { top: value, left: 0 },
    layers: state.layers,
  });

  if (relativePosition === null) return;

  setLayerPropertiesAction({
    breakpoint: state.breakpoint,
    layers: state.layers,
    updatedLayerIds: [id],
    properties: { top: relativePosition.top },
  });
};

const assignIntrinsicallyPositionedLeftProperty = (
  state: DraftEditorState,
  layer: IntrinsicallyPositionedLayer,
  value: number
) => {
  const lowestLeftValue = layer.children.reduce((acc, childId) => {
    const childLeft = getLayerRelativePosition(childId).left;

    return childLeft < acc ? childLeft : acc;
  }, Infinity);

  layer.children.forEach((childId) => {
    const leftOffset = getLayerRelativePosition(childId).left;

    const positionFromLeftEdge = leftOffset - lowestLeftValue;

    const relativePosition = getRelativePosition({
      layerId: childId,
      position: { left: value + positionFromLeftEdge, top: 0 },
      layers: state.layers,
    });

    if (relativePosition === null) return;

    setLayerPropertiesAction({
      breakpoint: state.breakpoint,
      layers: state.layers,
      updatedLayerIds: [childId],
      properties: {
        left: relativePosition.left,
      },
    });
  });
};

export const setLayerAbsoluteLeftAction = (
  state: DraftEditorState,
  id: LayerId,
  value: number
) => {
  const layer = state.layers[id];
  if (!layer) return;

  const isInstrinsicallyPositioned =
    checkIfLayerIsIntrinsicallyPositioned(layer);

  if (isInstrinsicallyPositioned) {
    assignIntrinsicallyPositionedLeftProperty(state, layer, value);
    return;
  }

  const relativePosition = getRelativePosition({
    layerId: layer.id,
    position: { left: value, top: 0 },
    layers: state.layers,
  });

  if (relativePosition === null) return;

  setLayerPropertiesAction({
    breakpoint: state.breakpoint,
    layers: state.layers,
    updatedLayerIds: [id],
    properties: { left: relativePosition.left },
  });
};
