import { useSelectedProductTitle } from "@/features/sidebar/components/OptionSelector";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import {
  selectProductId,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { ShopifyProduct } from "@folds/shared";
import { PopoverContent, PopoverPortal } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";

export function ProductSettings() {
  const dispatch = useAppDispatch();

  const [productSelectIsOpen, setProductSelectIsOpen] = useState(false);

  const productId = useAppSelector(selectProductId);
  const productTitle = useSelectedProductTitle(productId);

  const handleSelectProduct = ({
    productHandle,
    productId: updatedProductId,
  }: {
    productId: ShopifyProduct["id"];
    productHandle: ShopifyProduct["handle"];
  }) => {
    dispatch(
      setSelectedLayerProperties({ productHandle, productId: updatedProductId })
    );
    setProductSelectIsOpen(false);
  };

  return (
    <div className="flex flex-col gap-3 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Product</p>
      <Popover.Root
        open={productSelectIsOpen}
        onOpenChange={setProductSelectIsOpen}
      >
        <Popover.Trigger className="truncate rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-left text-xs text-gray-12">
          {productTitle}
        </Popover.Trigger>
        <PopoverPortal>
          <PopoverContent align="center" side="right" sideOffset={21}>
            <ProductSelect onSelect={handleSelectProduct} />
          </PopoverContent>
        </PopoverPortal>
      </Popover.Root>
    </div>
  );
}
