import { ErrorBoundary } from "@sentry/react";

function Fallback() {
  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-64 border-r border-solid border-gray-5 bg-gray-1 p-3 pb-14">
      Something went wrong. Please try again or contact support
    </div>
  );
}

export function SidebarErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ErrorBoundary showDialog fallback={Fallback}>
      {children}
    </ErrorBoundary>
  );
}
