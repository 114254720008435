import { Type, Static } from "@sinclair/typebox";

export const StandalonePage = Type.Object({
  id: Type.String(),
  name: Type.String(),
  shopifyId: Type.Union([Type.String(), Type.Null()]),
  createdAt: Type.String(),
  viewedAt: Type.String(),
  updatedAt: Type.String(),
  publishedAt: Type.Union([Type.String(), Type.Null()]),
  shopifyDomain: Type.String(),
});

export type StandalonePageType = Static<typeof StandalonePage>;
