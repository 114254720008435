import { usePageInformation } from "@/features/editor/api/getPageInformation";
import { usePageId } from "@/features/editor/context/PageId";
import { useAxios } from "@lib/axios";
import { toast } from "@core/toast";

export const useRenamePage = () => {
  const page = usePageId();
  const axios = useAxios();

  const { mutate, data: pageInformation } = usePageInformation();

  const renamePage = async (name: string) => {
    try {
      if (name.length === 0) return;

      if (pageInformation) {
        await mutate({ ...pageInformation, name });
      }

      toast.loading("Renaming page...", { id: "rename-page" });

      await axios.put(`/pages/${page}/name`, {
        name,
      });

      toast.success("Page renamed", { id: "rename-page" });

      await mutate();
    } catch (error) {
      toast.error("There was an issue renaming the page", {
        id: "rename-page",
      });
    }
  };

  return renamePage;
};
