export function TextAlignLeft() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-inherit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83331 4.5C1.83331 4.08579 2.1691 3.75 2.58331 3.75H15.3333C15.7475 3.75 16.0833 4.08579 16.0833 4.5C16.0833 4.91421 15.7475 5.25 15.3333 5.25H2.58331C2.1691 5.25 1.83331 4.91421 1.83331 4.5ZM1.83331 7.5C1.83331 7.08579 2.1691 6.75 2.58331 6.75H12.3333C12.7475 6.75 13.0833 7.08579 13.0833 7.5C13.0833 7.91422 12.7475 8.25 12.3333 8.25H2.58331C2.1691 8.25 1.83331 7.91422 1.83331 7.5ZM1.83331 10.5C1.83331 10.0858 2.1691 9.75 2.58331 9.75H15.3333C15.7475 9.75 16.0833 10.0858 16.0833 10.5C16.0833 10.9142 15.7475 11.25 15.3333 11.25H2.58331C2.1691 11.25 1.83331 10.9142 1.83331 10.5ZM1.83331 13.5C1.83331 13.0858 2.1691 12.75 2.58331 12.75H12.3333C12.7475 12.75 13.0833 13.0858 13.0833 13.5C13.0833 13.9142 12.7475 14.25 12.3333 14.25H2.58331C2.1691 14.25 1.83331 13.9142 1.83331 13.5Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function TextAlignCenter() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-inherit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5ZM3.75 7.5C3.75 7.08579 4.08579 6.75 4.5 6.75H13.5C13.9142 6.75 14.25 7.08579 14.25 7.5C14.25 7.91421 13.9142 8.25 13.5 8.25H4.5C4.08579 8.25 3.75 7.91421 3.75 7.5ZM1.5 10.5C1.5 10.0858 1.83579 9.75 2.25 9.75H15.75C16.1642 9.75 16.5 10.0858 16.5 10.5C16.5 10.9142 16.1642 11.25 15.75 11.25H2.25C1.83579 11.25 1.5 10.9142 1.5 10.5ZM3.75 13.5C3.75 13.0858 4.08579 12.75 4.5 12.75H13.5C13.9142 12.75 14.25 13.0858 14.25 13.5C14.25 13.9142 13.9142 14.25 13.5 14.25H4.5C4.08579 14.25 3.75 13.9142 3.75 13.5Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function TextAlignRight() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-inherit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91663 4.5C2.91663 4.08579 3.25241 3.75 3.66663 3.75H16.4166C16.8308 3.75 17.1666 4.08579 17.1666 4.5C17.1666 4.91421 16.8308 5.25 16.4166 5.25H3.66663C3.25241 5.25 2.91663 4.91421 2.91663 4.5ZM5.91663 7.5C5.91663 7.08579 6.25241 6.75 6.66663 6.75H16.4166C16.8308 6.75 17.1666 7.08579 17.1666 7.5C17.1666 7.91422 16.8308 8.25 16.4166 8.25H6.66663C6.25241 8.25 5.91663 7.91422 5.91663 7.5ZM2.91663 10.5C2.91663 10.0858 3.25241 9.75 3.66663 9.75H16.4166C16.8308 9.75 17.1666 10.0858 17.1666 10.5C17.1666 10.9142 16.8308 11.25 16.4166 11.25H3.66663C3.25241 11.25 2.91663 10.9142 2.91663 10.5ZM5.91663 13.5C5.91663 13.0858 6.25241 12.75 6.66663 12.75H16.4166C16.8308 12.75 17.1666 13.0858 17.1666 13.5C17.1666 13.9142 16.8308 14.25 16.4166 14.25H6.66663C6.25241 14.25 5.91663 13.9142 5.91663 13.5Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function NoTextCase() {
  return (
    <svg
      width="13"
      height="2"
      viewBox="0 0 13 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-inherit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.416687 1C0.416687 0.723858 0.640545 0.5 0.916687 0.5H11.5834C11.8595 0.5 12.0834 0.723858 12.0834 1C12.0834 1.27614 11.8595 1.5 11.5834 1.5H0.916687C0.640545 1.5 0.416687 1.27614 0.416687 1Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function LetterSpacing() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99998 1.33333C2.36817 1.33333 2.66665 1.63181 2.66665 1.99999L2.66665 14C2.66665 14.3682 2.36817 14.6667 1.99998 14.6667C1.63179 14.6667 1.33331 14.3682 1.33331 14L1.33331 1.99999C1.33331 1.63181 1.63179 1.33333 1.99998 1.33333ZM14 1.33333C14.3682 1.33333 14.6666 1.63181 14.6666 1.99999V14C14.6666 14.3682 14.3682 14.6667 14 14.6667C13.6318 14.6667 13.3333 14.3682 13.3333 14V1.99999C13.3333 1.63181 13.6318 1.33333 14 1.33333ZM7.55812 3.90845C7.83671 3.77123 8.16324 3.77123 8.44184 3.90845C8.68665 4.02903 8.81574 4.2404 8.87831 4.35057C8.94538 4.46865 9.0141 4.61991 9.08244 4.77033C9.08577 4.77766 9.08909 4.78498 9.09242 4.79229L11.9402 11.0575C12.0926 11.3926 11.9444 11.7879 11.6092 11.9402C11.274 12.0926 10.8788 11.9444 10.7264 11.6092L9.69192 9.33333L6.30804 9.33333L5.27356 11.6092C5.1212 11.9444 4.72597 12.0926 4.39078 11.9402C4.05559 11.7879 3.90738 11.3926 4.05974 11.0575L6.90754 4.79229C6.91086 4.78497 6.91419 4.77765 6.91752 4.77033C6.98586 4.6199 7.05458 4.46865 7.12165 4.35057C7.18422 4.2404 7.31331 4.02903 7.55812 3.90845ZM6.9141 7.99999L9.08586 8L7.99998 5.61107L6.9141 7.99999Z"
        fill="#687076"
      />
    </svg>
  );
}

export function LineHeight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33331 1.99999C1.33331 1.63181 1.63179 1.33333 1.99998 1.33333H14C14.3682 1.33333 14.6666 1.63181 14.6666 1.99999C14.6666 2.36818 14.3682 2.66666 14 2.66666H1.99998C1.63179 2.66666 1.33331 2.36818 1.33331 1.99999ZM7.55812 3.90845C7.83671 3.77123 8.16324 3.77123 8.44184 3.90845C8.68665 4.02903 8.81574 4.2404 8.87831 4.35057C8.94538 4.46865 9.0141 4.6199 9.08244 4.77033C9.08577 4.77765 9.08909 4.78497 9.09242 4.79229L11.9402 11.0575C12.0926 11.3926 11.9444 11.7879 11.6092 11.9402C11.274 12.0926 10.8788 11.9444 10.7264 11.6092L9.69192 9.33333H6.30804L5.27356 11.6092C5.1212 11.9444 4.72597 12.0926 4.39078 11.9402C4.05559 11.7879 3.90738 11.3926 4.05974 11.0575L6.90754 4.79229C6.91086 4.78497 6.91419 4.77765 6.91752 4.77033C6.98586 4.6199 7.05458 4.46865 7.12165 4.35057C7.18422 4.2404 7.31331 4.02903 7.55812 3.90845ZM6.9141 7.99999H9.08586L7.99998 5.61107L6.9141 7.99999ZM1.33331 14C1.33331 13.6318 1.63179 13.3333 1.99998 13.3333H14C14.3682 13.3333 14.6666 13.6318 14.6666 14C14.6666 14.3682 14.3682 14.6667 14 14.6667H1.99998C1.63179 14.6667 1.33331 14.3682 1.33331 14Z"
        fill="#687076"
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83341 2.83329C3.90042 2.83329 2.33341 4.4003 2.33341 6.33329C2.33341 8.26629 3.90042 9.83329 5.83341 9.83329C7.76641 9.83329 9.33341 8.26629 9.33341 6.33329C9.33341 4.4003 7.76641 2.83329 5.83341 2.83329ZM1.16675 6.33329C1.16675 3.75596 3.25609 1.66663 5.83341 1.66663C8.41074 1.66663 10.5001 3.75596 10.5001 6.33329C10.5001 7.41169 10.1343 8.40466 9.52002 9.19489L12.6626 12.3375C12.8904 12.5653 12.8904 12.9346 12.6626 13.1624C12.4347 13.3902 12.0654 13.3902 11.8376 13.1624L8.69507 10.0199C7.90483 10.6342 6.91184 11 5.83341 11C3.25609 11 1.16675 8.91062 1.16675 6.33329Z"
        fill="#687076"
      />
    </svg>
  );
}

export function Italic() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="fill-inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80998 4.20022C6.80998 3.90198 7.05175 3.66022 7.34998 3.66022H12.75C13.0482 3.66022 13.29 3.90198 13.29 4.20022C13.29 4.49845 13.0482 4.74022 12.75 4.74022H10.8071L8.67713 13.2601H10.65C10.9482 13.2601 11.19 13.5018 11.19 13.8001C11.19 14.0983 10.9482 14.3401 10.65 14.3401H5.24996C4.95173 14.3401 4.70996 14.0983 4.70996 13.8001C4.70996 13.5018 4.95173 13.2601 5.24996 13.2601H7.19282L9.32278 4.74022H7.34998C7.05175 4.74022 6.80998 4.49845 6.80998 4.20022Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function Underline() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="fill-inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90009 3.29999C5.90009 3.02385 5.67624 2.79999 5.40009 2.79999C5.12395 2.79999 4.90009 3.02385 4.90009 3.29999V9.75999C4.90009 11.9691 6.69096 13.76 8.90009 13.76H9.10009C11.3092 13.76 13.1001 11.9691 13.1001 9.75999V3.29999C13.1001 3.02385 12.8762 2.79999 12.6001 2.79999C12.324 2.79999 12.1001 3.02385 12.1001 3.29999V9.75999C12.1001 11.4168 10.7569 12.76 9.10009 12.76H8.90009C7.24324 12.76 5.90009 11.4168 5.90009 9.75999V3.29999ZM4.20005 15.8001C3.97913 15.8001 3.80005 15.9791 3.80005 16.2001C3.80005 16.421 3.97913 16.6001 4.20005 16.6001H13.8001C14.021 16.6001 14.2001 16.421 14.2001 16.2001C14.2001 15.9791 14.021 15.8001 13.8001 15.8001H4.20005Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function LineThrough() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className="fill-inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89992 3.90002C5.89992 3.62388 5.67606 3.40002 5.39992 3.40002C5.12378 3.40002 4.89992 3.62388 4.89992 3.90002V8.60006H2.99985C2.77894 8.60006 2.59985 8.77915 2.59985 9.00006C2.59985 9.22097 2.77894 9.40006 2.99985 9.40006H4.89992V10.36C4.89992 12.5692 6.69078 14.36 8.89992 14.36H9.09992C11.3091 14.36 13.0999 12.5692 13.0999 10.36V9.40006H14.9999C15.2208 9.40006 15.3999 9.22097 15.3999 9.00006C15.3999 8.77915 15.2208 8.60006 14.9999 8.60006H13.0999V3.90002C13.0999 3.62388 12.8761 3.40002 12.5999 3.40002C12.3238 3.40002 12.0999 3.62388 12.0999 3.90002V8.60006H5.89992V3.90002ZM5.89992 9.40006V10.36C5.89992 12.0169 7.24307 13.36 8.89992 13.36H9.09992C10.7568 13.36 12.0999 12.0169 12.0999 10.36V9.40006H5.89992Z"
        className="fill-inherit"
      />
    </svg>
  );
}

export function FontSize() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.66667C2 2.29848 2.29848 2 2.66667 2H5.33333C5.70152 2 6 2.29848 6 2.66667C6 3.03486 5.70152 3.33333 5.33333 3.33333H4.66667V7.33333L11.3333 7.33333V3.33333H10.6667C10.2985 3.33333 10 3.03486 10 2.66667C10 2.29848 10.2985 2 10.6667 2H13.3333C13.7015 2 14 2.29848 14 2.66667C14 3.03486 13.7015 3.33333 13.3333 3.33333H12.6667V12.6667H13.3333C13.7015 12.6667 14 12.9651 14 13.3333C14 13.7015 13.7015 14 13.3333 14H10.6667C10.2985 14 10 13.7015 10 13.3333C10 12.9651 10.2985 12.6667 10.6667 12.6667H11.3333V8.66667L4.66667 8.66667V12.6667H5.33333C5.70152 12.6667 6 12.9651 6 13.3333C6 13.7015 5.70152 14 5.33333 14H2.66667C2.29848 14 2 13.7015 2 13.3333C2 12.9651 2.29848 12.6667 2.66667 12.6667H3.33333V3.33333H2.66667C2.29848 3.33333 2 3.03486 2 2.66667Z"
        className="fill-gray-11"
      />
    </svg>
  );
}
