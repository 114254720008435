import { useAppSelector } from "@core/hooks";
import { selectCascadingVariables } from "@core/features/editor/editorSlice";
import { FillType } from "@folds/shared/types";
import { colord } from "colord";
import { checkIfIsCssVariable } from "@core/utils/checkIfIsCssVariable";

export const useParseInputValue = () => {
  const colorVariables = useAppSelector(selectCascadingVariables);

  const parseInputValue = ({
    color,
    fillType,
    backgroundImage,
  }: {
    backgroundImage: string | null;
    color: string;
    fillType: FillType;
  }) => {
    if (fillType === "image") {
      const isCssVariable = checkIfIsCssVariable(backgroundImage ?? "");

      if (isCssVariable) {
        const foundVariable = colorVariables.find(
          (variable) => `--${variable.id}` === backgroundImage
        );

        if (foundVariable === undefined) return "Image";

        return foundVariable.name;
      }

      return "Image";
    }

    const isCssVariable = checkIfIsCssVariable(color);

    if (isCssVariable) {
      const foundVariable = colorVariables.find(
        (variable) => `--${variable.id}` === color
      );
      if (foundVariable === undefined) return "";
      return foundVariable.name;
    }

    return colord(color).alpha(1).toHex().toUpperCase();
  };

  return parseInputValue;
};
