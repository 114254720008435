import { nanoid } from "@core/lib/nanoid";
import type {
  DraftEditorState,
  EditorState,
} from "@core/features/editor/editorSlice/editorSlice";
import {
  GroupLayer,
  LayerId,
  LayerType,
  Layers,
  SelectedLayerIds,
} from "@folds/shared";

const getParent = (layers: Layers, selectedLayerIds: SelectedLayerIds) => {
  const firstSelectedLayerId = selectedLayerIds[0];

  if (typeof firstSelectedLayerId !== "string") return null;

  const layer = layers[firstSelectedLayerId];

  if (layer === undefined || typeof layer.parentId !== "string") return null;

  const parent = layers[layer.parentId];

  if (parent === undefined || !("children" in parent)) return null;

  return parent;
};

const checkIfIsAbleToGroupSelection = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const selectedLayerIdsParents = selectedLayerIds.map((id) =>
    getParent(layers, [id])
  );

  const isEveryParentTheSame = selectedLayerIdsParents.every(
    (parent, index, array) => {
      if (index === 0) return true;

      return parent?.id === array[0]?.id;
    }
  );

  if (isEveryParentTheSame === false) return false;

  const numberOfSelectedGroupedLayers = selectedLayerIds.reduce((acc, id) => {
    const layer = layers[id];

    if (layer === undefined || typeof layer.parentId !== "string") return acc;

    const parent = layers[layer.parentId];

    if (parent?.type === LayerType.Group) {
      return acc + 1;
    }

    return acc;
  }, 0);

  // Don't allow nested groups
  const isTheParentAGroup =
    selectedLayerIdsParents[0]?.type === LayerType.Group;

  if (isTheParentAGroup === true) return false;

  if (numberOfSelectedGroupedLayers === 1 && selectedLayerIds.length === 1)
    return false;

  return selectedLayerIds.length > 1;
};

const getGroupChildren = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const childrenIds = selectedLayerIds.flatMap((id) => {
    const layer = layers[id];

    if (layer === undefined) return [];

    if (layer.type === LayerType.Group) {
      return layer.children;
    }

    return [id];
  });

  return childrenIds;
};

const filterSelectedLayersOutOfPreviousParentChildren = (
  state: DraftEditorState
) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];

    if (layer === undefined || typeof layer.parentId !== "string") return;

    const parentLayer = state.layers[layer.parentId];

    if (parentLayer === undefined || !("children" in parentLayer)) return;

    parentLayer.children = parentLayer.children.filter(
      (childId) => childId !== id
    );
  });
};

const deleteSelectedGroupLayers = (state: DraftEditorState) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];

    if (layer === undefined) return;

    if (layer.type === LayerType.Group) {
      delete state.layers[id];
    }
  });
};

const updateParentIdOnGroupChildren = (
  state: DraftEditorState,
  { ids, groupLayerId }: { ids: LayerId[]; groupLayerId: LayerId }
) => {
  ids.forEach((id) => {
    const layer = state.layers[id];

    if (layer === undefined) return;

    layer.parentId = groupLayerId;
  });
};

export const groupLayersAction = (state: EditorState) => {
  const isAbleToGroupSelection = checkIfIsAbleToGroupSelection(
    state.layers,
    state.selectedLayerIds
  );

  if (isAbleToGroupSelection === false) return;

  const parent = getParent(state.layers, state.selectedLayerIds);

  if (parent === null) return;

  const childrenIds = getGroupChildren(state.layers, state.selectedLayerIds);

  filterSelectedLayersOutOfPreviousParentChildren(state);
  deleteSelectedGroupLayers(state);

  const groupLayerId = nanoid();

  const groupLayer: GroupLayer = {
    type: LayerType.Group,
    id: groupLayerId,
    parentId: parent.id,
    children: childrenIds,
  };

  updateParentIdOnGroupChildren(state, { ids: childrenIds, groupLayerId });

  state.layers[groupLayerId] = groupLayer;
  parent.children.push(groupLayerId);
  state.selectedLayerIds = [groupLayerId];
};
