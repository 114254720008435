import { SelectedLayerIds } from "@folds/shared/types";

import { getAbsolutePosition } from "@core/utils/getAbsolutePosition";
import { getLayerDimensions } from "@core/utils/getLayerDimensions";

export const getBoundingBoxRect = (ids: SelectedLayerIds) => {
  const topPositionArray: number[] = [];
  const leftPositionArray: number[] = [];
  const bottomPositionArray: number[] = [];
  const rightPositionArray: number[] = [];

  ids.forEach((id) => {
    const absolutePosition = getAbsolutePosition(id);

    topPositionArray.push(absolutePosition.top);
    leftPositionArray.push(absolutePosition.left);

    const { height, width } = getLayerDimensions(id);

    rightPositionArray.push(absolutePosition.left + width);
    bottomPositionArray.push(absolutePosition.top + height);
  });

  const top = Math.min(...topPositionArray);
  const left = Math.min(...leftPositionArray);
  const bottom = Math.max(...bottomPositionArray);
  const right = Math.max(...rightPositionArray);

  return {
    top,
    left,
    height: bottom - top,
    width: right - left,
  };
};
