import { Box, GuideStopLines } from "@folds/shared/types";

export const getSnappedDeltaY = ({
  box,
  mouseDeltaY,
  lines,
  type,
}: {
  mouseDeltaY: number;
  box: Box;
  lines: GuideStopLines["horizontal"];
  type: "top" | "bottom";
}) => {
  switch (type) {
    case "top": {
      const newTop = box.top + mouseDeltaY;

      const indicator = lines.reduce(
        (acc, line) => {
          const distance = Math.abs(newTop - line.y);

          if (distance <= 5) {
            if (acc !== null && distance > acc.distance) return acc;

            return { indicator: line, distance };
          }

          return acc;
        },
        null as null | {
          indicator: GuideStopLines["horizontal"][number];
          distance: number;
        }
      );

      if (indicator === null) return mouseDeltaY;

      return indicator.indicator.y - box.top;
    }
    case "bottom": {
      const newHeight = box.height + mouseDeltaY;
      const newBottom = box.top + +newHeight;

      const indicator = lines.reduce(
        (acc, line) => {
          const distance = Math.abs(newBottom - line.y);

          if (distance <= 5) {
            if (acc !== null && distance > acc.distance) return acc;

            return { indicator: line, distance };
          }

          return acc;
        },
        null as null | {
          indicator: GuideStopLines["horizontal"][number];
          distance: number;
        }
      );

      if (indicator === null) return mouseDeltaY;

      return indicator.indicator.y - box.top - box.height;
    }
  }
};

export const getSnappedMouseX = ({
  box,
  lines,
  mouseDeltaX,
  type,
}: {
  mouseDeltaX: number;
  box: Box;
  lines: GuideStopLines["vertical"];
  type: "right" | "left";
}): number => {
  switch (type) {
    case "left": {
      const newLeft = box.left + mouseDeltaX;

      const indicator = lines.reduce(
        (acc, line) => {
          const distance = Math.abs(newLeft - line.x);

          if (distance <= 5) {
            if (acc !== null && distance > acc.distance) return acc;

            return { indicator: line, distance };
          }

          return acc;
        },
        null as null | {
          indicator: GuideStopLines["vertical"][number];
          distance: number;
        }
      );

      if (indicator === null) return mouseDeltaX;

      return indicator.indicator.x - box.left;
    }
    case "right": {
      const newWidth = box.width + mouseDeltaX;
      const newRight = box.left + newWidth;

      const indicator = lines.reduce(
        (acc, line) => {
          const distance = Math.abs(newRight - line.x);

          if (distance <= 5) {
            if (acc !== null && distance > acc.distance) return acc;

            return { indicator: line, distance };
          }

          return acc;
        },
        null as null | {
          indicator: GuideStopLines["vertical"][number];
          distance: number;
        }
      );

      if (indicator === null) return mouseDeltaX;

      return indicator.indicator.x - box.left - box.width;
    }
  }
};
