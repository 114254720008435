import type { EditorState } from "@core/features/editor/editorSlice/editorSlice";
import { LayerType } from "@folds/shared";

export const ungroupLayerAction = (state: EditorState) => {
  if (state.selectedLayerIds.length !== 1) return;

  const selectedLayerId = state.selectedLayerIds[0];

  if (typeof selectedLayerId !== "string") return;

  const layer = state.layers[selectedLayerId];

  if (layer === undefined || layer.type !== LayerType.Group) return;

  const parentLayer = state.layers[layer.parentId];

  if (parentLayer === undefined || !("children" in parentLayer)) return;

  // Filter out group layer
  parentLayer.children = parentLayer.children.filter(
    (childId) => childId !== selectedLayerId
  );

  // Move children to the parent layer
  parentLayer.children = parentLayer.children.concat(layer.children);

  // Update the perentId of the children
  layer.children.forEach((childId) => {
    const child = state.layers[childId];

    if (child === undefined) return;

    child.parentId = parentLayer.id;
  });

  // Delete the group layer
  delete state.layers[selectedLayerId];

  state.selectedLayerIds = [];
};
