import { getLayersToCopy } from "@/features/editor/hooks/useCopyPaste/getLayersToCopy";
import { getTargetLayerId } from "@/features/editor/hooks/useCopyPaste/getTargetLayerId";
import type { DraftEditorState } from "@core/features/editor/editorSlice";
import {
  getRootLayerIds,
  randomizeLayers,
  updateLayerParentIds,
} from "@core/features/editor/editorSlice/actions/pasteLayersAction";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction";
import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { getHighestZIndex } from "@core/utils/getHighestZIndex";
import { Breakpoint, LayerId } from "@folds/shared";
import { current } from "@reduxjs/toolkit";

const resetLayerPositionOverrides = (
  state: DraftEditorState,
  {
    breakpoint,
    ids,
  }: {
    ids: LayerId[];
    breakpoint: Breakpoint;
  }
) => {
  ids.forEach((id) => {
    const layer = state.layers[id];

    if (layer === undefined) return;

    // We only need to reset the top key since the left key is recalculate for all breakpoints when moved, the top is not
    if (breakpoint !== "tablet" && "tablet" in layer && "top" in layer.tablet) {
      delete layer.tablet.top;
    }

    if (breakpoint !== "mobile" && "mobile" in layer && "top" in layer.mobile) {
      delete layer.mobile.top;
    }

    if (breakpoint !== "tablet" && "hasEditedTabletPosition" in layer) {
      layer.hasEditedTabletPosition = false;
    }

    if (breakpoint !== "mobile" && "hasEditedMobilePosition" in layer) {
      layer.hasEditedMobilePosition = false;
    }

    if (breakpoint !== "desktop" && "hasEditedDesktopPosition" in layer) {
      layer.hasEditedDesktopPosition = false;
    }
  });
};

const setHighestZIndexOnLayersDuplicatedFrom = (
  state: DraftEditorState,
  { ids, targetLayerId }: { targetLayerId: LayerId; ids: LayerId[] }
) => {
  const highestZIndex = getHighestZIndex(state.layers, targetLayerId);

  ids.forEach((layerId) => {
    const layer = state.layers[layerId];
    if (layer === undefined) return;

    if (checkIfLayerIsIntrinsicallyPositioned(layer)) {
      setLayerPropertiesAction({
        breakpoint: state.breakpoint,
        updatedLayerIds: layer.children,
        layers: state.layers,
        properties: { zIndex: highestZIndex + 1 },
      });

      return;
    }

    setLayerPropertiesAction({
      breakpoint: state.breakpoint,
      updatedLayerIds: [layerId],
      layers: state.layers,
      properties: { zIndex: highestZIndex + 1 },
    });
  });
};

export const duplicateLayersAction = (state: DraftEditorState) => {
  if (state.duplicatingLayerIds === null) return;

  const layerIdsToDuplicate = state.duplicatingLayerIds;

  state.duplicatingLayerIds = null;

  const layers = getLayersToCopy(layerIdsToDuplicate, current(state.layers));

  const targetLayerId = getTargetLayerId(layers);

  if (targetLayerId === null || targetLayerId === undefined) {
    return;
  }

  const randomizedLayers = randomizeLayers(layers);

  const rootLayerIds = getRootLayerIds(randomizedLayers);

  const layersWithUpdatedParentIds = updateLayerParentIds(
    randomizedLayers,
    rootLayerIds,
    targetLayerId
  );

  const targetLayer = state.layers[targetLayerId];

  if (targetLayer === undefined || !("children" in targetLayer)) {
    return;
  }

  targetLayer.children.push(...rootLayerIds);

  setHighestZIndexOnLayersDuplicatedFrom(state, {
    ids: layerIdsToDuplicate,
    targetLayerId,
  });

  layersWithUpdatedParentIds.forEach((layer) => {
    state.layers[layer.id] = layer;
  });

  resetLayerPositionOverrides(state, {
    breakpoint: state.breakpoint,
    ids: state.dragging?.draggingLayerIds ?? [],
  });
};
