import { Input } from "@/features/sidebar/components/Input";
import { setSelectedLayerProperties } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { getElement } from "@core/utils";
import { useCallback, useEffect, useRef, useState } from "react";

const calculateLeftPosition = (selectedLayerIds: string[]) => {
  const firstSelectedLayerId = selectedLayerIds[0];

  if (firstSelectedLayerId === undefined) return "";

  const element = getElement(firstSelectedLayerId);

  if (!element) return "";

  return String(element.offsetLeft);
};

const calculateTopPosition = (selectedLayerIds: string[]) => {
  const firstSelectedLayerId = selectedLayerIds[0];

  if (firstSelectedLayerId === undefined) return "";

  const element = getElement(firstSelectedLayerId);

  if (!element) return "";

  return String(element.offsetTop);
};

export function SidebarPositionSettings() {
  const dispatch = useAppDispatch();

  const cleanupObservers = useRef<(() => void)[]>([]);

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [left, setLeft] = useState("0");
  const [top, setTop] = useState("0");

  const recalculatePosition = useCallback(() => {
    const updatedLeftPosition = calculateLeftPosition(selectedLayerIds);
    const updatedTopPosition = calculateTopPosition(selectedLayerIds);
    setLeft(updatedLeftPosition);
    setTop(updatedTopPosition);
  }, [selectedLayerIds]);

  useEffect(() => {
    cleanupObservers.current.forEach((cleanup) => cleanup());

    cleanupObservers.current = [];

    selectedLayerIds.forEach((id) => {
      const element = getElement(id);

      if (!element) return;

      const observer = new MutationObserver(recalculatePosition);

      observer.observe(element, {
        attributes: true,
        attributeFilter: ["style"],
      });

      cleanupObservers.current.push(() => observer.disconnect());
    });

    recalculatePosition();

    return () => {
      cleanupObservers.current.forEach((cleanup) => cleanup());
    };
  }, [recalculatePosition, selectedLayerIds]);

  const handleChangeLeftPosiiton = (value: string) => {
    const newLeftValue = Number(value);
    if (Number.isNaN(newLeftValue)) return;

    dispatch(setSelectedLayerProperties({ left: newLeftValue }));
  };

  const handleChangeTopPosition = (value: string) => {
    const newTopValue = Number(value);
    if (Number.isNaN(newTopValue)) return;

    dispatch(setSelectedLayerProperties({ top: newTopValue }));
  };

  return (
    <div className="flex gap-2">
      <Input
        unit="px"
        icon={<p className="block text-xs text-gray-11">X</p>}
        value={left}
        minValue={null}
        onValueChange={handleChangeLeftPosiiton}
      />
      <Input
        unit="px"
        icon={<p className="block text-xs text-gray-11">Y</p>}
        value={top}
        minValue={null}
        onValueChange={handleChangeTopPosition}
      />
    </div>
  );
}
