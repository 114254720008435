import { INDICATOR_SIZE } from "@/features/editor/components/AltHoverDistanceIndicator/constants";
import { roundDistance } from "@/features/editor/components/AltHoverDistanceIndicator/utils/roundDistance";
import { useAppSelector } from "@core/hooks";
import { getBoundingBoxRect } from "@core/utils";
import { Box, LayerId } from "@folds/shared";
import React, { useEffect, useState } from "react";

const useBelowDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value =
      targetLayerBoundingBox.top -
      (selectionBoundingBox.top + selectionBoundingBox.height);

    setDistance(roundDistance(value));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

function DashedBottomLeftIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const targetBoundingBoxRight =
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const left = targetBoundingBoxRight;
  const width =
    selectionBoundingBox.left +
    selectionBoundingBox.width / 2 -
    targetBoundingBoxRight;

  const style: React.CSSProperties = {
    top: targetLayerBoundingBox.top,
    left,
    width,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

function DashedBottomRightIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const selectionBoundingBoxMiddle =
    selectionBoundingBox.left + selectionBoundingBox.width / 2;

  const left = selectionBoundingBoxMiddle - INDICATOR_SIZE / 2;
  const width = targetLayerBoundingBox.left - selectionBoundingBoxMiddle;

  const style: React.CSSProperties = {
    top: targetLayerBoundingBox.top,
    left,
    width,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

export function BelowIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useBelowDistance(targetLayerId);

  const top = selectionBoundingBox.top + selectionBoundingBox.height;
  const height = targetLayerBoundingBox.top - top;
  const left =
    selectionBoundingBox.left +
    selectionBoundingBox.width / 2 -
    INDICATOR_SIZE / 2;

  const indicatorStyle: React.CSSProperties = {
    top,
    left,
    height,
    width: INDICATOR_SIZE,
  };

  const selectionMiddlePosition =
    selectionBoundingBox.left + selectionBoundingBox.width / 2;

  const isSelectionMiddlePositionToTheRightOfTargetLayer =
    selectionMiddlePosition >
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const isSelectionMiddlePositionToTheLeftOfTargetLayer =
    selectionMiddlePosition < targetLayerBoundingBox.left;

  return (
    <>
      {isSelectionMiddlePositionToTheRightOfTargetLayer && (
        <DashedBottomLeftIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      {isSelectionMiddlePositionToTheLeftOfTargetLayer && (
        <DashedBottomRightIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
        <p
          aria-label="below distance"
          className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
        >
          {distance}
        </p>
      </div>
    </>
  );
}
