import type { Resizing } from "@core/features/editor/editorSlice";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction";
import { getRelativePosition } from "@core/features/editor/editorSlice/actions/utils/dragging";
import { Breakpoint, Layers } from "@folds/shared/types";

const getTopForOnePxHeight = ({
  initialLayerInformation,
  layers,
}: {
  initialLayerInformation: Resizing["initialLayersInformation"][number];
  layers: Layers;
}) => {
  const initialRelativePosition = getRelativePosition({
    layerId: initialLayerInformation.layerId,
    layers,
    position: {
      left: initialLayerInformation.left,
      top: initialLayerInformation.top,
    },
  });

  if (initialRelativePosition === null) return;

  const onePxHeightTop =
    initialRelativePosition.top + initialLayerInformation.height - 1;

  return onePxHeightTop;
};

export const resizeTopSide = ({
  initialLayersInformation,
  deltaY,
  layers,
  breakpoint,
}: {
  initialLayersInformation: Resizing["initialLayersInformation"];
  deltaY: number;
  layers: Layers;
  breakpoint: Breakpoint;
}) => {
  initialLayersInformation.forEach((initialLayerInformation) => {
    const newTop = initialLayerInformation.top + deltaY;
    const newHeight = initialLayerInformation.height - deltaY;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: {
        left: initialLayerInformation.left,
        top: newTop,
      },
    });

    if (relativePosition === null) return;

    if (newHeight < 1) {
      const top = getTopForOnePxHeight({
        initialLayerInformation,
        layers,
      });

      setLayerPropertiesAction({
        layers,
        properties: {
          top,
          height: 1,
        },
        updatedLayerIds: [initialLayerInformation.layerId],
        breakpoint,
      });

      return;
    }

    setLayerPropertiesAction({
      layers,
      properties: {
        top: relativePosition.top,
        height: newHeight,
      },
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

export const resizeRightSide = ({
  initialLayersInformation,
  deltaX,
  layers,
  breakpoint,
}: {
  initialLayersInformation: Resizing["initialLayersInformation"];
  deltaX: number;
  layers: Layers;
  breakpoint: Breakpoint;
}) => {
  initialLayersInformation.forEach((initialLayerInformation) => {
    const newWidth = initialLayerInformation.width + deltaX;

    if (newWidth < 1) {
      setLayerPropertiesAction({
        layers,
        properties: {
          width: 1,
        },
        updatedLayerIds: [initialLayerInformation.layerId],
        breakpoint,
      });
      return;
    }

    setLayerPropertiesAction({
      layers,
      properties: {
        width: newWidth,
      },
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

export const resizeBottomSide = ({
  initialLayersInformation,
  deltaY,
  layers,
  breakpoint,
}: {
  initialLayersInformation: Resizing["initialLayersInformation"];
  deltaY: number;
  layers: Layers;
  breakpoint: Breakpoint;
}) => {
  initialLayersInformation.forEach((initialLayerInformation) => {
    const newHeight = initialLayerInformation.height + deltaY;

    if (newHeight < 1) {
      setLayerPropertiesAction({
        layers,
        properties: {
          height: 1,
        },
        updatedLayerIds: [initialLayerInformation.layerId],
        breakpoint,
      });
      return;
    }

    setLayerPropertiesAction({
      layers,
      properties: {
        height: newHeight,
      },
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

const getLeftForOnePxWidth = ({
  initialLayerInformation,
  layers,
}: {
  initialLayerInformation: Resizing["initialLayersInformation"][number];
  layers: Layers;
}) => {
  const initialRelativePosition = getRelativePosition({
    layerId: initialLayerInformation.layerId,
    layers,
    position: {
      left: initialLayerInformation.left,
      top: initialLayerInformation.top,
    },
  });

  if (initialRelativePosition === null) return;

  const onePxWidthLeft =
    initialRelativePosition.left + initialLayerInformation.width - 1;

  return onePxWidthLeft;
};

export const resizeLeftSide = ({
  initialLayersInformation,
  deltaX,
  layers,
  breakpoint,
}: {
  initialLayersInformation: Resizing["initialLayersInformation"];
  deltaX: number;
  layers: Layers;
  breakpoint: Breakpoint;
}) => {
  initialLayersInformation.forEach((initialLayerInformation) => {
    const newLeft = initialLayerInformation.left + deltaX;
    const newWidth = initialLayerInformation.width - deltaX;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: { left: newLeft, top: initialLayerInformation.top },
    });

    if (relativePosition === null) return;

    if (newWidth < 1) {
      const left = getLeftForOnePxWidth({ initialLayerInformation, layers });

      setLayerPropertiesAction({
        layers,
        properties: {
          left,
          width: 1,
        },
        updatedLayerIds: [initialLayerInformation.layerId],
        breakpoint,
      });

      return;
    }

    setLayerPropertiesAction({
      layers,
      properties: {
        left: relativePosition.left,
        width: newWidth,
      },
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};
