import { useCreatePage } from "@/features/dashboard/hooks/useCreatePage";
import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { useAllPages } from "@/features/sidebar/api/getAllPages";
import { PageSelect } from "@/features/sidebar/components/PageSelect";
import type { PageState } from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog";
import { ShopifyPage } from "@folds/shared";
import {
  ChevronLeft,
  PlusIcon,
} from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog.icons";

const getSelectedPageName = (
  selectedId: number | null,
  products: ShopifyPage[] | undefined
) => {
  if (selectedId === null) return "Select page";

  if (products === undefined) return "Loading...";

  const selectedPage = products.find((page) => page.id === selectedId);

  if (selectedPage === undefined) return null;

  return selectedPage.title;
};

export function CreateStandalonePageDialogContent({
  pageName,
  onBack,
  onPageCreated,
  setPageState,
}: {
  pageName: string;
  onBack: () => void;
  onPageCreated: (id: string) => void;
  setPageState: (state: PageState) => void;
}) {
  const { data: pages } = useAllPages();
  const createPage = useCreatePage(onPageCreated);

  const [pageId, setPageId] = useState<number | null>(null);

  const selectedPageName = getSelectedPageName(pageId, pages);

  const [selectProductPopoverOpen, setSelectProductPopoverOpen] =
    useState(false);

  const handleCreate = async () => {
    if (pageName.length === 0 || pageId === null) return;

    await createPage({
      name: pageName,
      type: "standalone",
      shopifyId: String(pageId),
    });
  };

  const handleSelectPage: React.ComponentProps<
    typeof PageSelect
  >["onSelect"] = (id) => {
    setPageId(id);
    setSelectProductPopoverOpen(false);
  };

  const handleCreatePage = () => {
    setPageState("create-page");
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-medium">
            Create standalone page
          </Dialog.Title>
          <Dialog.Close>
            <CloseDialogIcon />
          </Dialog.Close>
        </div>
        <button
          type="button"
          className="flex w-max items-center gap-2 text-base text-gray-12"
          onClick={onBack}
        >
          <ChevronLeft />
          Back
        </button>
      </div>
      <Popover.Root
        open={selectProductPopoverOpen}
        onOpenChange={setSelectProductPopoverOpen}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <label className="text-sm text-gray-11">Page</label>
            <button
              type="button"
              aria-label="Create new page"
              onClick={handleCreatePage}
            >
              <PlusIcon />
            </button>
          </div>
          <Popover.Trigger className="h-11 w-full rounded border border-gray-6 bg-gray-2 px-4 text-left text-sm">
            {selectedPageName}
          </Popover.Trigger>
        </div>
        <Popover.Portal>
          <Popover.Content className="z-[999] w-[352px]" sideOffset={8}>
            <PageSelect
              onSelect={handleSelectPage}
              inputClassName="flex-shrink-0"
              wrapperClassName="w-full h-72"
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <div className="flex gap-4">
        <Dialog.Close
          type="button"
          className="flex-1 rounded-lg border border-gray-6 bg-gray-2 py-3"
        >
          Cancel
        </Dialog.Close>
        <button
          type="button"
          className={twMerge(
            "flex-1 rounded-lg border border-gray-6 bg-gray-12 py-3 text-white",
            (pageId === null || pageName.length === 0) &&
              "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleCreate}
        >
          Create
        </button>
      </div>
    </>
  );
}
