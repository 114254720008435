import type { EditorState } from "@core/features/editor/editorSlice";
import { getParentAccordionRootId } from "@core/features/editor/editorSlice/actions/dragStartAction";
import { toast } from "@core/toast";
import { LayerId, LayerType, Layers } from "@folds/shared/types";
import { Draft } from "immer";
import invariant from "tiny-invariant";

const getLayerIdToDelete = (id: LayerId, layers: Layers): LayerId | null => {
  const layer = layers[id];

  if (!layer) return null;

  if (layer.type === LayerType.Accordion.Item) {
    const parentAccordionId = getParentAccordionRootId(layers, layer.id);
    if (parentAccordionId === null) return id;

    const accordionLayer = layers[parentAccordionId];

    if (!accordionLayer || accordionLayer.type !== LayerType.Accordion.Root)
      return id;

    return accordionLayer.children.length > 1 ? id : parentAccordionId;
  }

  if (
    layer.type === LayerType.Accordion.Trigger ||
    layer.type === LayerType.Accordion.Content
  ) {
    const parentAccordionId = getParentAccordionRootId(layers, layer.id);
    if (parentAccordionId === null) return id;

    const accordionLayer = layers[parentAccordionId];

    if (!accordionLayer || accordionLayer.type !== LayerType.Accordion.Root)
      return id;

    if (accordionLayer.children.length > 1) {
      return layer.parentId;
    }

    return parentAccordionId;
  }

  if (layer.type === LayerType.OptionSelector.Item) {
    return layer.parentId;
  }

  return id;
};

const checkIfDeletingTheLastBlock = (id: LayerId, layers: Layers) => {
  const breakpointLayer = Object.values(layers).find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (!breakpointLayer || breakpointLayer.type !== LayerType.Breakpoint)
    return false;

  const breakpointLayerChildrenFiltered = breakpointLayer.children.filter(
    (child) => child !== id
  );

  if (breakpointLayerChildrenFiltered.length !== 0) return false;

  return true;
};

const deleteLayer = (layers: Draft<Layers>, deleteLayerId: LayerId) => {
  const layer = layers[deleteLayerId];
  if (!layer || typeof layer.parentId !== "string") return;

  const parentLayer = layers[layer.parentId];

  if (parentLayer && "children" in parentLayer) {
    parentLayer.children = parentLayer.children.filter(
      (childId) => childId !== deleteLayerId
    );
  }

  const layerToBeDeleted = layers[deleteLayerId];
  invariant(layerToBeDeleted, `Layer not found for ${deleteLayerId}`);

  if (layerToBeDeleted.type === LayerType.Breakpoint) return;

  if ("children" in layerToBeDeleted) {
    // Recursively delete all the children
    layerToBeDeleted.children.forEach((childId) => {
      deleteLayer(layers, childId);
    });
  }

  delete layers[deleteLayerId];
};

export const deleteLayerAction = (
  state: Draft<EditorState>,
  layerId: LayerId
) => {
  const { layers } = state;

  const isDeletingTheLastBlock = checkIfDeletingTheLastBlock(layerId, layers);

  if (isDeletingTheLastBlock) return;

  const layerIdToDelete = getLayerIdToDelete(layerId, layers);

  if (layerIdToDelete === null) {
    toast.error("There was an error deleting the layer");
    return;
  }

  deleteLayer(layers, layerIdToDelete);

  // Remove the layer from the selectedLayerIds
  state.selectedLayerIds = state.selectedLayerIds.filter(
    (selectedLayerId) => selectedLayerId !== layerId
  );
};
