import { LayerType, Layers, SelectedLayerIds } from "@folds/shared";

export const checkIfCanGroupLayers = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const numberOfSelectedGroupedLayers = selectedLayerIds.reduce((acc, id) => {
    const layer = layers[id];

    if (layer === undefined || typeof layer.parentId !== "string") return acc;

    const parent = layers[layer.parentId];

    if (parent?.type === LayerType.Group) {
      return acc + 1;
    }

    return acc;
  }, 0);

  if (numberOfSelectedGroupedLayers === 1 && selectedLayerIds.length === 1)
    return false;

  // Don't allow nested groups
  const selectedLayerParentIsAGroup = selectedLayerIds.some((id) => {
    const layer = layers[id];

    if (layer === undefined || typeof layer.parentId !== "string") return false;

    const parent = layers[layer.parentId];

    return parent?.type === LayerType.Group;
  });

  // TODO: Allow grouping of product image carousel layers
  // https://linear.app/folds/issue/FOL-211/grouping-product-image-root-doesnt-work
  const isProductImageCarouselSelected = selectedLayerIds.some((id) => {
    const layer = layers[id];
    return (
      layer?.type === LayerType.ProductImageCarousel.Root ||
      layer?.type === LayerType.ProductImageCarousel.FeaturedImage ||
      layer?.type === LayerType.ProductImageCarousel.Thumbnail ||
      layer?.type === LayerType.ProductImageCarousel.Thumbnails
    );
  });

  if (isProductImageCarouselSelected) return false;

  if (selectedLayerParentIsAGroup) return false;

  return selectedLayerIds.length > 1;
};
