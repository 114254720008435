import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@/features/dashboard/Dashboard.icons";
import { DialogContent, DialogOverlay, DialogPortal } from "@lib/radix";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import {
  appendTemplateToLayers,
  clearPendingDropTemplateLayers,
  replaceLayersWithTemplate,
} from "@core/features/editor/editorSlice";

export function ReplaceExistingLayersWithTemplateDialog() {
  const dispatch = useAppDispatch();
  const pendingDropTemplateLayers = useAppSelector(
    (state) => state.editor.pendingDropTemplateLayers
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      dispatch(clearPendingDropTemplateLayers());
    }
  };

  const handleDontReplaceExistingLayers = () => {
    dispatch(appendTemplateToLayers());
  };

  const handleReplaceExistingLayers = () => {
    dispatch(replaceLayersWithTemplate());
  };

  if (!pendingDropTemplateLayers) {
    return;
  }

  return (
    <Dialog.Root open onOpenChange={handleOpenChange}>
      <DialogPortal>
        <DialogOverlay className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-20" />
        <DialogContent>
          <div className="flex justify-between">
            <Dialog.Title className="text-lg font-medium">
              Replace existing layers?
            </Dialog.Title>
            <Dialog.Close>
              <CloseDialogIcon />
            </Dialog.Close>
          </div>
          <p className="text-base">
            Do you want to replace the existing layers?
          </p>
          <div className="flex gap-3">
            <button
              onClick={handleDontReplaceExistingLayers}
              type="button"
              className="flex-1 rounded border border-gray-6 bg-gray-2 py-3 text-sm"
            >
              Don&apos;t replace
            </button>
            <button
              type="button"
              onClick={handleReplaceExistingLayers}
              className="flex-1 rounded border border-gray-6 bg-red-9 py-3 text-sm text-white"
            >
              Replace
            </button>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}
