import { LayerId, Layers } from "@folds/shared/types";
import invariant from "tiny-invariant";

export const getAncestorIds = (layers: Layers, layerId: LayerId) => {
  const layer = layers[layerId];

  invariant(layer, `Layer ${layerId} not found`);

  const ancestorIds: LayerId[] = [];

  let currentLayer = layer;

  while (currentLayer.parentId !== null) {
    ancestorIds.push(currentLayer.parentId);
    const parentLayer = layers[currentLayer.parentId];

    invariant(parentLayer, `Layer ${currentLayer.parentId} not found`);

    currentLayer = parentLayer;
  }

  return ancestorIds;
};
