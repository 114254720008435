import { useProduct } from "@/features/sidebar/api/getProduct";
import { TextWrapper } from "@core/user-components/Price";
import { TextLayer, generateTextStyles } from "@folds/shared";

export function ProductTitle({ layer }: { layer: TextLayer }) {
  const { data: product, error, isLoading } = useProduct(layer.productId);

  const style = generateTextStyles(layer);

  if (layer.productId === null)
    return (
      <TextWrapper id={layer.id} style={style}>
        Select a product
      </TextWrapper>
    );

  if (isLoading)
    return (
      <TextWrapper id={layer.id} style={style}>
        Loading...
      </TextWrapper>
    );

  if (error !== undefined) {
    return (
      <TextWrapper id={layer.id} style={style}>
        There was an unexpected error
      </TextWrapper>
    );
  }

  if (product === undefined) {
    return (
      <TextWrapper id={layer.id} style={style}>
        Product not found
      </TextWrapper>
    );
  }

  return (
    <TextWrapper id={layer.id} style={style}>
      {product.title}
    </TextWrapper>
  );
}
