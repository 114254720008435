import { calculateMousePointRelativeToCanvas } from "@/features/editor/hooks/useDragSelect/utils/calculateMousePointRelativeToCanvas";
import { calculateSelectionIndicator } from "@/features/editor/hooks/useDragSelect/utils/calculateSelectionIndicator";
import { checkIfSelectionIsIntersecingLayer } from "@/features/editor/hooks/useDragSelect/utils/checkIfSelectionIsIntersecingLayer";
import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { ROOT_LAYER_ID } from "@folds/shared/constants";
import { LayerType, Layers, Point } from "@folds/shared/types";

const getPossibleDragSelectChildrenIds = (layers: Layers) => {
  const page = layers[ROOT_LAYER_ID];

  if (!page || !("children" in page)) return [];

  const rootChildren = page.children.filter((layerId) => {
    const layer = layers[layerId];
    if (!layer) return false;

    return layer.type !== LayerType.Breakpoint;
  });

  const blockLayers = Object.values(layers).filter(
    (layer) => layer.type === LayerType.Block
  );

  const blockChildren = blockLayers.reduce<string[]>((acc, blockLayer) => {
    if (!("children" in blockLayer)) return acc;

    return [...acc, ...blockLayer.children];
  }, []);

  const blockIds = blockLayers.map((blockLayer) => blockLayer.id);

  return [...rootChildren, ...blockChildren, ...blockIds];
};

const getBlockIds = (layers: Layers) => {
  const blockLayers = Object.values(layers).filter(
    (layer) => layer.type === LayerType.Block
  );

  return blockLayers.map((blockLayer) => blockLayer.id);
};

export function dragSelectAction(state: DraftEditorState, mousePoint: Point) {
  if (state.dragSelect === null) return;

  const currentPosition = calculateMousePointRelativeToCanvas(mousePoint, {
    scale: state.scale,
    translateX: state.translateX,
    translateY: state.translateY,
  });

  const selectionBox = calculateSelectionIndicator(
    state.dragSelect.initialPosition,
    currentPosition
  );

  const childrenIds = getPossibleDragSelectChildrenIds(state.layers);

  const intersectingLayerIds = childrenIds.filter((layerId) =>
    checkIfSelectionIsIntersecingLayer({
      selectionBox,
      layerId,
    })
  );

  const blockIds = getBlockIds(state.layers);

  // If the selection box is only intersecting with block layers, then include the block layers in the selection
  // or else exclude the block layers from the selection

  // This is important because if the user is trying to select a block layer,
  // it will work as expected, but they can also select block layer children
  const isOnlyIntersectingWithBlockLayers = intersectingLayerIds.every(
    (layerId) => blockIds.includes(layerId)
  );

  if (isOnlyIntersectingWithBlockLayers) {
    setSelectedLayerIdsAction(state, intersectingLayerIds);
  } else {
    const filteredIntersectingLayerIds = intersectingLayerIds.filter(
      (layerId) => !blockIds.includes(layerId)
    );

    setSelectedLayerIdsAction(state, filteredIntersectingLayerIds);
  }

  state.dragSelect = { ...state.dragSelect, currentPosition };
}
