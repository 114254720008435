import { FillType } from "@folds/shared/types";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { forwardRef } from "react";
import { checkIfIsCssVariable } from "@core/utils/checkIfIsCssVariable";
import { colord } from "colord";

import classes from "../ColorPicker.module.css";

export const FillPopoverTrigger = forwardRef<
  HTMLButtonElement,
  { color: string; fillType: FillType; backgroundImage: string | null }
>(({ color, fillType, backgroundImage }, forwardedRef) => {
  if (fillType === "image") {
    if (backgroundImage === null) {
      return (
        <Popover.Trigger className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6 bg-white">
          <img
            src="/image-placeholder.svg"
            className="h-full w-full object-contain"
            alt="Selected background"
          />
        </Popover.Trigger>
      );
    }

    if (checkIfIsCssVariable(backgroundImage)) {
      return (
        <Popover.Trigger
          ref={forwardedRef}
          className={clsx(
            classes["rainbow-background"],
            "absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6"
          )}
        />
      );
    }

    return (
      <Popover.Trigger className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6 bg-white">
        <img
          src={backgroundImage}
          className="h-full w-full object-contain"
          alt="Selected background"
        />
      </Popover.Trigger>
    );
  }

  if (checkIfIsCssVariable(color)) {
    return (
      <Popover.Trigger
        ref={forwardedRef}
        className={clsx(
          classes["rainbow-background"],
          "absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6"
        )}
      />
    );
  }

  const opaqueColor = colord(color).alpha(1).toRgbString();

  return (
    <Popover.Trigger
      ref={forwardedRef}
      className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 rounded border border-solid border-gray-6"
      style={{ backgroundColor: opaqueColor }}
    />
  );
});

FillPopoverTrigger.displayName = "FillPopoverTrigger";
