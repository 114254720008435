import { setViewport } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import {
  DESKTOP_BREAKPOINT_WIDTH,
  INITIAL_VIEWPORT_SCALE,
} from "@folds/shared/constants";
import { useEffect } from "react";

export const useInitialViewport = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const editorWidth = window.innerWidth - 400;
    const editorHeight = window.innerHeight - 100;

    const scale = INITIAL_VIEWPORT_SCALE;

    const centeredLeft =
      400 +
      editorWidth -
      editorWidth / 2 -
      (DESKTOP_BREAKPOINT_WIDTH * scale) / 2;

    const centeredTop =
      100 + editorHeight - editorHeight / 2 - (1200 * scale) / 2;

    dispatch(
      setViewport({
        scale,
        translateX: centeredLeft,
        translateY: centeredTop,
      })
    );
  }, [dispatch]);
};
