import { modalStore } from "@core/modalStore";
import type { LayerId } from "@folds/shared/types";

export const getElement = (id: LayerId) => {
  const modal = modalStore.get();
  if (!modal) return null;

  const iframeDocument = modal.querySelector("iframe")?.contentDocument;

  const element = iframeDocument?.getElementById(id);
  if (!element) return null;

  return element;
};
