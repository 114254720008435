import type { Resizing } from "@core/features/editor/editorSlice";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction";
import { getRelativePosition } from "@core/features/editor/editorSlice/actions/utils/dragging";
import { Breakpoint, Layers } from "@folds/shared/types";

type AssignTextProperties = {
  type: "text";
  newFontSize: number | undefined;
  newHeight: number;
  newLeft: number;
  newWidth: number | null;
  newTop: number;
  fitContent: boolean;
};

type AssignElementProperties = {
  type: "element";
  newFontSize: number | undefined;
  newHeight: number;
  newLeft: number;
  newWidth: number;
  newTop: number;
  fitContent: boolean;
};

type AssignProperties = AssignTextProperties | AssignElementProperties;

const getPropertiesToAssign = ({
  type,
  newFontSize,
  newHeight,
  newLeft,
  newTop,
  newWidth,
  fitContent,
}: AssignProperties) => {
  // Don't assign height since text layers don't have a height property
  if (type === "text") {
    if (newFontSize === undefined) return {};

    if (newFontSize < 1) {
      return {
        fontSize: 1,
        left: newLeft,
        top: newTop,
        ...(fitContent === false && { width: newWidth }),
      };
    }

    return {
      fontSize: newFontSize,
      left: newLeft,
      top: newTop,
      ...(fitContent === false && { width: newWidth }),
    };
  }

  return {
    height: newHeight,
    left: newLeft,
    top: newTop,
    width: newWidth,
  };
};

const getNewFontSize = ({
  initialFontSize,
  ratio,
}: {
  ratio: number;
  initialFontSize: number | null;
}) => {
  if (typeof initialFontSize !== "number") return undefined;

  const newFontSize = initialFontSize * ratio;
  return newFontSize;
};

export const scaleTopLeft = ({
  resizing,
  deltaX,
  deltaY,
  layers,
  breakpoint,
}: {
  resizing: Resizing;
  layers: Layers;
  deltaX: number;
  deltaY: number;
  breakpoint: Breakpoint;
}) => {
  const { box, initialLayersInformation } = resizing;

  const newDimensions = {
    width: box.width - deltaX,
    height: box.height - deltaY,
  };

  const ratio = Math.min(
    newDimensions.width / box.width,
    newDimensions.height / box.height
  );

  initialLayersInformation.forEach((initialLayerInformation) => {
    const newWidth = initialLayerInformation.width * ratio;
    const newHeight = initialLayerInformation.height * ratio;

    const newDistanceFromRight =
      initialLayerInformation.distanceFromRight * ratio;
    const newDistanceFromBottom =
      initialLayerInformation.distanceFromBottom * ratio;

    const newLeft = box.right - newWidth - newDistanceFromRight;
    const newTop = box.bottom - newHeight - newDistanceFromBottom;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: {
        left: newLeft,
        top: newTop,
      },
    });

    if (relativePosition === null) return;

    const newFontSize = getNewFontSize({
      ratio,
      initialFontSize: initialLayerInformation.fontSize,
    });

    const properties = getPropertiesToAssign({
      newFontSize,
      newHeight,
      newLeft: relativePosition.left,
      newTop: relativePosition.top,
      newWidth,
      type: initialLayerInformation.type,
      fitContent: initialLayerInformation.fitContent,
    });

    setLayerPropertiesAction({
      layers,
      properties,
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

export const scaleTopRight = ({
  resizing,
  deltaX,
  deltaY,
  layers,
  breakpoint,
}: {
  resizing: Resizing;
  layers: Layers;
  deltaX: number;
  deltaY: number;
  breakpoint: Breakpoint;
}) => {
  const { initialLayersInformation, box } = resizing;

  const newDimensions = {
    width: box.width + deltaX,
    height: box.height - deltaY,
  };

  const ratio = Math.min(
    newDimensions.width / box.width,
    newDimensions.height / box.height
  );

  initialLayersInformation.forEach((initialLayerInformation) => {
    const newWidth = initialLayerInformation.width * ratio;
    const newHeight = initialLayerInformation.height * ratio;

    const newDistanceFromBottom =
      initialLayerInformation.distanceFromBottom * ratio;

    const newDistanceFromLeft =
      initialLayerInformation.distanceFromLeft * ratio;

    const newTop = box.bottom - newHeight - newDistanceFromBottom;
    const newLeft = box.left + newDistanceFromLeft;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: {
        left: newLeft,
        top: newTop,
      },
    });

    if (relativePosition === null) return;

    const newFontSize = getNewFontSize({
      ratio,
      initialFontSize: initialLayerInformation.fontSize,
    });

    const properties = getPropertiesToAssign({
      newFontSize,
      newHeight,
      newLeft: relativePosition.left,
      newTop: relativePosition.top,
      newWidth,
      type: initialLayerInformation.type,
      fitContent: initialLayerInformation.fitContent,
    });

    setLayerPropertiesAction({
      layers,
      properties,
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

export const scaleBottomLeft = ({
  resizing,
  deltaX,
  deltaY,
  layers,
  breakpoint,
}: {
  resizing: Resizing;
  layers: Layers;
  deltaX: number;
  deltaY: number;
  breakpoint: Breakpoint;
}) => {
  const { initialLayersInformation, box } = resizing;

  const newDimensions = {
    width: box.width - deltaX,
    height: box.height + deltaY,
  };

  const ratio = Math.min(
    newDimensions.width / box.width,
    newDimensions.height / box.height
  );

  initialLayersInformation.forEach((initialLayerInformation) => {
    const newWidth = initialLayerInformation.width * ratio;
    const newHeight = initialLayerInformation.height * ratio;

    const newDistanceFromTop = initialLayerInformation.distanceFromTop * ratio;
    const newDistanceFromRight =
      initialLayerInformation.distanceFromRight * ratio;

    const newTop = box.top + newDistanceFromTop;
    const newLeft = box.right - newWidth - newDistanceFromRight;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: {
        left: newLeft,
        top: newTop,
      },
    });

    if (relativePosition === null) return;

    const newFontSize = getNewFontSize({
      ratio,
      initialFontSize: initialLayerInformation.fontSize,
    });

    const properties = getPropertiesToAssign({
      newFontSize,
      newHeight,
      newLeft: relativePosition.left,
      newTop: relativePosition.top,
      newWidth,
      fitContent: initialLayerInformation.fitContent,
      type: initialLayerInformation.type,
    });

    setLayerPropertiesAction({
      layers,
      properties,
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};

export const scaleBottomRight = ({
  resizing,
  deltaX,
  deltaY,
  layers,
  breakpoint,
}: {
  resizing: Resizing;
  layers: Layers;
  deltaX: number;
  deltaY: number;
  breakpoint: Breakpoint;
}) => {
  const { initialLayersInformation, box } = resizing;

  const newDimensions = {
    width: box.width + deltaX,
    height: box.height + deltaY,
  };

  const ratio = Math.min(
    newDimensions.width / box.width,
    newDimensions.height / box.height
  );

  initialLayersInformation.forEach((initialLayerInformation) => {
    const newWidth = initialLayerInformation.width * ratio;
    const newHeight = initialLayerInformation.height * ratio;

    const newDistanceFromLeft =
      initialLayerInformation.distanceFromLeft * ratio;
    const newDistanceFromTop = initialLayerInformation.distanceFromTop * ratio;

    const left = box.left + newDistanceFromLeft;
    const top = box.top + newDistanceFromTop;

    const relativePosition = getRelativePosition({
      layerId: initialLayerInformation.layerId,
      layers,
      position: {
        left,
        top,
      },
    });

    if (relativePosition === null) return;

    const newFontSize = getNewFontSize({
      ratio,
      initialFontSize: initialLayerInformation.fontSize,
    });

    const properties = getPropertiesToAssign({
      newFontSize,
      newHeight,
      newLeft: relativePosition.left,
      newTop: relativePosition.top,
      newWidth,
      type: initialLayerInformation.type,
      fitContent: initialLayerInformation.fitContent,
    });

    setLayerPropertiesAction({
      layers,
      properties,
      updatedLayerIds: [initialLayerInformation.layerId],
      breakpoint,
    });
  });
};
