import { IconLayer } from '../types';

export const generateIconStyles = (layer: IconLayer): React.CSSProperties => {
  const { top, left, fill, width, height, zIndex, visible } = layer;

  return {
    position: 'absolute',
    top,
    left,
    width,
    height,
    display: visible ? 'block' : 'none',
    '--icon-color': fill,
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
    zIndex,
  } as React.CSSProperties;
};
