import { useCreatePage } from "@/features/dashboard/hooks/useCreatePage";
import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { useAllProducts } from "@/features/sidebar/api/getAllProducts";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import { ShopifyProduct } from "@folds/shared";
import { ChevronLeft } from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog.icons";

const getSelectedProductTitle = (
  selectedId: number | null,
  products: ShopifyProduct[] | undefined
) => {
  if (selectedId === null) return "Select product";

  if (products === undefined) return "Loading...";

  const selectedProduct = products.find((product) => product.id === selectedId);

  if (selectedProduct === undefined) return null;

  return selectedProduct.title;
};

export function CreateProductPageDialogContent({
  pageName,
  onBack,
  onPageCreated,
}: {
  pageName: string;
  onBack: () => void;
  onPageCreated: (id: string) => void;
}) {
  const { data: products } = useAllProducts();
  const createPage = useCreatePage(onPageCreated);

  const [productId, setProductId] = useState<number | null>(null);

  const selectedProductName = getSelectedProductTitle(productId, products);

  const [selectProductPopoverOpen, setSelectProductPopoverOpen] =
    useState(false);

  const handleCreate = async () => {
    if (pageName.length === 0 || productId === null) return;

    await createPage({
      name: pageName,
      type: "product",
      shopifyId: String(productId),
    });
  };

  const handleSelectProduct: React.ComponentProps<
    typeof ProductSelect
  >["onSelect"] = (product) => {
    setProductId(product.productId);
    setSelectProductPopoverOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-medium">
            Create product page
          </Dialog.Title>
          <Dialog.Close>
            <CloseDialogIcon />
          </Dialog.Close>
        </div>
        <button
          type="button"
          className="flex w-max items-center gap-2 text-base text-gray-12"
          onClick={onBack}
        >
          <ChevronLeft />
          Back
        </button>
      </div>
      <Popover.Root
        open={selectProductPopoverOpen}
        onOpenChange={setSelectProductPopoverOpen}
      >
        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-11">Product</label>
          <Popover.Trigger className="h-11 w-full rounded border border-gray-6 bg-gray-2 px-4 text-left text-sm">
            {selectedProductName}
          </Popover.Trigger>
        </div>
        <Popover.Portal>
          <Popover.Content className="z-[999] w-[352px]" sideOffset={8}>
            <ProductSelect
              inputClassName="flex-shrink-0"
              wrapperClassName="w-full h-72"
              onSelect={handleSelectProduct}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <div className="flex gap-4">
        <Dialog.Close
          type="button"
          className="flex-1 rounded-lg border border-gray-6 bg-gray-2 py-3"
        >
          Cancel
        </Dialog.Close>
        <button
          type="button"
          className={twMerge(
            "flex-1 rounded-lg border border-gray-6 bg-gray-12 py-3 text-white",
            (productId === null || pageName.length === 0) &&
              "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleCreate}
        >
          Create
        </button>
      </div>
    </>
  );
}
