import {
  setLayerAbsoluteLeftAction,
  setLayerAbsoluteTopAction,
} from "@core/features/editor/editorSlice/actions/setLayerAbsolutePositionAction";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getAbsolutePosition, getLayerDimensions } from "@core/utils";

export const setSelectedLayersHorizontalGapAction = (
  state: DraftEditorState,
  gap: number
) => {
  const selectedLayersPositions = state.selectedLayerIds.map((id) => ({
    id,
    box: { ...getAbsolutePosition(id), ...getLayerDimensions(id) },
  }));

  const sortedSelectedLayerPositions = selectedLayersPositions.sort(
    (a, b) => a.box.left - b.box.left
  );

  sortedSelectedLayerPositions.reduce(
    (accLeftPosition, currentLayer, index) => {
      if (index === 0) {
        return currentLayer.box.left + currentLayer.box.width;
      }

      const newLeftPosition = accLeftPosition + gap;
      setLayerAbsoluteLeftAction(state, currentLayer.id, newLeftPosition);

      return newLeftPosition + currentLayer.box.width;
    },
    0
  );
};

export const setSelectedLayersVerticalGapAction = (
  state: DraftEditorState,
  gap: number
) => {
  const selectedLayersPositions = state.selectedLayerIds.map((id) => ({
    id,
    box: { ...getAbsolutePosition(id), ...getLayerDimensions(id) },
  }));

  const sortedSelectedLayerPositions = selectedLayersPositions.sort(
    (a, b) => a.box.top - b.box.top
  );

  sortedSelectedLayerPositions.reduce((accTopPosition, currentLayer, index) => {
    if (index === 0) {
      return currentLayer.box.top + currentLayer.box.height;
    }

    const newTopPosition = accTopPosition + gap;
    setLayerAbsoluteTopAction(state, currentLayer.id, newTopPosition);

    return newTopPosition + currentLayer.box.height;
  }, 0);
};
