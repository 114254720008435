import { useAppSelector } from "@core/hooks";
import { getElement, getLayerDimensions } from "@core/utils";
import { LayerId } from "@folds/shared/types";

const getAbsolutePosition = (id: LayerId) => {
  const canvasElement = getElement("canvas");
  const element = getElement(id);

  if (element === null || canvasElement === null) {
    return { top: 0, left: 0 };
  }

  const scaleString =
    canvasElement.style.transform.match(/scale\(([0-9.-]+)\)/)?.[1];

  if (scaleString === undefined) {
    return { top: 0, left: 0 };
  }

  const scale = Number(scaleString);

  const pageElementRect = canvasElement.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.top / scale - pageElementRect.top / scale,
    left: elementRect.left / scale - pageElementRect.left / scale,
  };
};

export function DraggedIntoLayerIndicator() {
  const draggedIntoLayerId = useAppSelector(
    (state) => state.editor.dragging?.draggedIntoLayerId
  );
  const scale = useAppSelector((state) => state.editor.scale);

  if (typeof draggedIntoLayerId !== "string") return null;

  const { height, width } = getLayerDimensions(draggedIntoLayerId);
  const { top, left } = getAbsolutePosition(draggedIntoLayerId);

  const style: React.CSSProperties = {
    top,
    left,
    width,
    height,
    borderWidth: 2 / scale,
  };

  return (
    <div
      className="pointer-events-none absolute z-20 border-solid border-accent-9"
      style={style}
    />
  );
}
