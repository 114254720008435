import { useAppSelector } from "@core/hooks";
import { getElement } from "@core/utils";
import { SelectedLayerIds } from "@folds/shared";
import { useCallback, useEffect, useState } from "react";

const getWidthInputValue = (selectedLayerIds: SelectedLayerIds) => {
  const selectedElements = selectedLayerIds.map((id) => getElement(id));

  const widthValues = selectedElements.map(
    (element) => element?.offsetWidth ?? 0
  );

  const isEqual = widthValues.every((value) => value === widthValues[0]);

  if (!isEqual) return "Mixed";

  return String(widthValues[0]);
};

const getHeightInputValue = (selectedLayerIds: SelectedLayerIds) => {
  const selectedElements = selectedLayerIds.map((id) => getElement(id));

  const widthValues = selectedElements.map(
    (element) => element?.offsetHeight ?? 0
  );

  const isEqual = widthValues.every((value) => value === widthValues[0]);

  if (!isEqual) return "Mixed";

  return String(widthValues[0]);
};

export const useSizeProperties = () => {
  const [width, setWidth] = useState("0");
  const [height, setHeight] = useState("0");

  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const updateSizing = useCallback(() => {
    const newWidth = getWidthInputValue(selectedLayerIds);
    const newHeight = getHeightInputValue(selectedLayerIds);

    setWidth(newWidth);
    setHeight(newHeight);
  }, [selectedLayerIds]);

  // Resize Observer doesn't working in App Bridge max modal so we can use polling instead
  useEffect(() => {
    const interval = setInterval(updateSizing, 16);
    return () => clearInterval(interval);
  }, [updateSizing]);

  return {
    width,
    height,
  };
};
