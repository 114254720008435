import { assignLayerProperties } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/assignLayerProperties";
import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getParentLayer } from "@core/utils/getParentLayer";
import { Layer, LayerId, Layers } from "@folds/shared";

const getChildrenIds = (layers: Layers, layerId: LayerId): LayerId[] => {
  const layer = layers[layerId];

  if (!layer || !("children" in layer)) return [];

  const parsedLayersIds = layer.children.reduce((acc, id) => {
    const child = layers[id];
    if (!child) return acc;

    if (checkIfLayerIsIntrinsicallyPositioned(child))
      return [...acc, ...child.children];

    return [...acc, id];
  }, [] as LayerId[]);

  return parsedLayersIds;
};

const getLayerIdsToAssignLowestZIndex = ({
  layers,
  layerId,
}: {
  layers: Layers;
  layerId: LayerId;
}) => {
  const layer = layers[layerId];
  if (!layer) return [];

  if (checkIfLayerIsIntrinsicallyPositioned(layer)) {
    return layer.children;
  }

  return [layerId];
};

export const sendLayerToBackAction = (
  state: DraftEditorState,
  layerId: LayerId
) => {
  const layer = state.layers[layerId];
  if (layer === undefined) return;

  const parentLayer = getParentLayer(state.layers, layerId);
  if (parentLayer === null) return;

  const children = getChildrenIds(state.layers, parentLayer.id);

  const layerIdsToAssignLowestZIndex = getLayerIdsToAssignLowestZIndex({
    layerId,
    layers: state.layers,
  });

  children.forEach((childId) => {
    if (layerIdsToAssignLowestZIndex.includes(childId)) return;

    const childLayer = state.layers[childId];

    if (!childLayer || !("zIndex" in childLayer)) return;

    assignLayerProperties({
      breakpoint: state.breakpoint,
      layer: childLayer,
      key: "zIndex",
      value: childLayer.zIndex + 1,
      layers: state.layers,
    });
  });

  const layersToAssignLowestZIndex = layerIdsToAssignLowestZIndex
    .map((id) => state.layers[id])
    .filter((currentLayer) => currentLayer !== undefined) as Layer[];

  layersToAssignLowestZIndex.forEach((currentLayer) => {
    assignLayerProperties({
      breakpoint: state.breakpoint,
      layer: currentLayer,
      key: "zIndex",
      // Minimum value of 1 since the selection cover has a z-index of 0,
      // and we want the layers to be infront of the selection cover so we can unselect them with a shift click
      value: 1,
      layers: state.layers,
    });
  });
};
