import { Static, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { layersSchema } from './layers/layer-types';

export type ShopifySearchResult = {
  url: string;
  title: string;
}[];

const updatePageInformationBodySchema = Type.Union([
  Type.Object({
    type: Type.Literal('home'),
  }),
  Type.Object({
    type: Type.Literal('standalone'),
    id: Type.Number(),
  }),
  Type.Object({
    type: Type.Literal('product'),
    id: Type.Number(),
  }),
  Type.Object({
    type: Type.Literal('not-found'),
  }),
]);

export type UpdatePageInformationBody = Static<
  typeof updatePageInformationBodySchema
>;

export const compiledUpdatePageInformationBodySchema = TypeCompiler.Compile(
  updatePageInformationBodySchema
);

export const compiledComponentApiResponseSchema = TypeCompiler.Compile(
  Type.Array(
    Type.Object({
      _id: Type.Any(),
      description: Type.String(),
      layers: layersSchema,
      products: Type.Any(),
    })
  )
);

export const elementCollectionEnum = {
  Shape: 'shape',
  AddToCartButton: 'add-to-cart-button',
  RedirectButton: 'redirect-button',
  OptionSelector: 'option-selector',
  OptionDropdown: 'option-dropdown',
  Accordion: 'accordion',
  QuantityPicker: 'quantity-picker',
  ProductImageCarousel: 'product-image-carousel',
  ProductTitle: 'product-title',
  ProductPrice: 'product-price',
  SalePrice: 'sale-price',
  Carousel: 'carousel',
} as const;

const elementCollectionSchema = Type.Union([
  Type.Literal(elementCollectionEnum.Shape),
  Type.Literal(elementCollectionEnum.AddToCartButton),
  Type.Literal(elementCollectionEnum.RedirectButton),
  Type.Literal(elementCollectionEnum.OptionSelector),
  Type.Literal(elementCollectionEnum.OptionDropdown),
  Type.Literal(elementCollectionEnum.Accordion),
  Type.Literal(elementCollectionEnum.QuantityPicker),
  Type.Literal(elementCollectionEnum.ProductImageCarousel),
  Type.Literal(elementCollectionEnum.ProductTitle),
  Type.Literal(elementCollectionEnum.ProductPrice),
  Type.Literal(elementCollectionEnum.SalePrice),
  Type.Literal(elementCollectionEnum.Carousel),
]);

export type ElementCollection = Static<typeof elementCollectionSchema>;

export const compiledElementCollection = TypeCompiler.Compile(
  elementCollectionSchema
);

export const blockCollectionEnum = {
  Hero: 'hero',
  Product: 'product',
  ProductCarousel: 'product-carousel',
  Testimonials: 'testimonials',
  Categories: 'categories',
  Features: 'features',
  AsFeaturedIn: 'as-featured-in',
  AboutUs: 'about-us',
  FrequentlyAskedQuestions: 'frequently-asked-questions',
} as const;

const blockCollectionSchema = Type.Union([
  Type.Literal(blockCollectionEnum.Hero),
  Type.Literal(blockCollectionEnum.Product),
  Type.Literal(blockCollectionEnum.ProductCarousel),
  Type.Literal(blockCollectionEnum.Testimonials),
  Type.Literal(blockCollectionEnum.Categories),
  Type.Literal(blockCollectionEnum.Features),
  Type.Literal(blockCollectionEnum.AsFeaturedIn),
  Type.Literal(blockCollectionEnum.AboutUs),
  Type.Literal(blockCollectionEnum.FrequentlyAskedQuestions),
]);

export const compiledBlockCollectionSchema = TypeCompiler.Compile(
  blockCollectionSchema
);

export type BlockCollection = Static<typeof blockCollectionSchema>;

const elementSchema = Type.Object({
  _id: Type.Any(),
  collection: elementCollectionSchema,
  layers: layersSchema,
  products: Type.Any(),
  width: Type.Number(),
  height: Type.Number(),
});

export type Element = Static<typeof elementSchema>;

export const compiledElementSchema = TypeCompiler.Compile(elementSchema);

export const compiledElementsSchema = TypeCompiler.Compile(
  Type.Array(elementSchema)
);

const apiCreateUserBodySchema = Type.Object({
  email: Type.String(),
  firstName: Type.String(),
  lastName: Type.String(),
});

export type ApiCreateUserBody = Static<typeof apiCreateUserBodySchema>;

export const compiledApiCreateUserBodySchema = TypeCompiler.Compile(
  apiCreateUserBodySchema
);

const apiCreatePageBody = Type.Union([
  Type.Object({
    name: Type.String(),
    type: Type.Literal('home'),
    shopifyId: Type.Null(),
  }),
  Type.Object({
    name: Type.String(),
    type: Type.Literal('standalone'),
    shopifyId: Type.String(),
  }),
  Type.Object({
    name: Type.String(),
    type: Type.Literal('product'),
    shopifyId: Type.String(),
  }),
  Type.Object({
    name: Type.String(),
    type: Type.Literal('not-found'),
    shopifyId: Type.Null(),
  }),
]);

export type ApiCreatePageBody = Static<typeof apiCreatePageBody>;

export const compiledApiCreatePageBodySchema =
  TypeCompiler.Compile(apiCreatePageBody);
