import { ShopProvider } from "@/features/dashboard/context/ShopContext";
import { Dashboard } from "@/features/dashboard/Dashboard";
import { useLoadIntercom } from "@/features/dashboard/hooks/useLoadIntercom";
import { SentryErrorBoundary } from "@common/SentryErrorBoundary";
import { store } from "@core/store";
import { Provider } from "react-redux";

export function DashboardRoute() {
  useLoadIntercom();

  return (
    <SentryErrorBoundary>
      <ShopProvider>
        <Provider store={store}>
          <Dashboard />
        </Provider>
      </ShopProvider>
    </SentryErrorBoundary>
  );
}
