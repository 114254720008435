import { theme } from "@theme";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import { DashboardRoute } from "@/routes/Dashboard";
import { ThemeProvider } from "styled-components";
import { captureException, wrapCreateBrowserRouter } from "@sentry/react";
import { useEffect } from "react";

import { ExitFrame } from "@/routes/ExitFrame";
import { Playwright } from "@/routes/Playwright";
import { Toaster } from "@core/Toaster";
import { Authentication } from "@common/Authentication";
import { NotFound } from "@/routes/NotFound";
import { Playground } from "@/routes/Playground";

function Root() {
  return (
    <Authentication>
      <Outlet />
    </Authentication>
  );
}

const sentryBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

function SentryRouteErrorFallback() {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: "fatal" });
  }, [routeError]);

  return <div>Something went wrong. Please try again or contact support.</div>;
}

const router = sentryBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <SentryRouteErrorFallback />,
    children: [
      {
        path: "/",
        element: <DashboardRoute />,
      },
      {
        path: "/exit-frame",
        element: <ExitFrame />,
      },
      {
        path: "/playground",
        element: <Playground />,
      },
      // Seperate route for Playwright tests since we can't use the app bridge modal when the app isn't embedded in Shopify
      {
        path: "playwright/:pageId",
        element: <Playwright />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
