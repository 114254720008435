import { LayerId, LayerType, Layers } from "@folds/shared/types";

export const checkIfIsResizingAnIcon = (
  layers: Layers,
  resizingIds: LayerId[]
) => {
  const includesIcon = resizingIds.some((id) => {
    const layer = layers[id];
    return layer && layer.type === LayerType.Icon;
  });

  return includesIcon;
};
