import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";
import { nanoid } from "@core/lib/nanoid";
import { DEFAULT_CUSTOM_TEXT_PROPERTIES } from "@folds/shared/constants";
import {
  BlockLayer,
  Breakpoint,
  TextLayer,
  Layer,
  LayerId,
  LayerType,
  Layers,
  Position,
} from "@folds/shared/types";

const generateTextLayer = ({
  text,
  parentId,
  top,
  left,
  tabletLeft,
  mobileLeft,
  fontSize,
  lineHeight,
  fontFamily,
}: {
  parentId: LayerId;
  text: string;
  left: number;
  top: number;
  fontSize: number;
  lineHeight: number;
  fontFamily: string;
  tabletLeft?: number;
  mobileLeft?: number;
}): TextLayer => {
  const id = nanoid();

  const textLayer: TextLayer = {
    ...DEFAULT_CUSTOM_TEXT_PROPERTIES,
    id,
    content: "custom",
    productId: null,
    variableId: null,
    fontFamily,
    top,
    text,
    left,
    parentId,
    lineHeight,
    fontSize,
    ...(tabletLeft !== undefined
      ? { tablet: { left: tabletLeft } }
      : { tablet: {} }),
    ...(mobileLeft !== undefined
      ? { mobile: { left: mobileLeft } }
      : { mobile: {} }),
  };

  return textLayer;
};

const calculateTextWidth = (html: string) => {
  const textElement = document.createElement("div");

  textElement.style.width = "max-content";
  textElement.style.position = "absolute";

  // Keep it out of the viewport
  textElement.style.top = "-10000px";
  textElement.style.left = "-10000px";
  textElement.style.zIndex = "-10000";

  textElement.innerHTML = html;

  document.body.appendChild(textElement);

  const width = textElement.offsetWidth;

  document.body.removeChild(textElement);

  return width;
};

const checkIfLayerIsBlock = (layer: Layer | undefined): layer is BlockLayer =>
  layer?.type === LayerType.Block;

export const getResponsiveTextLayer = ({
  targetLayerId,
  layers,
  text,
  position,
  breakpoint,
  fontSize,
  lineHeight,
  fontFamily,
}: {
  layers: Layers;
  targetLayerId: LayerId;
  text: string;
  lineHeight: number;
  fontSize: number;
  position: Position;
  breakpoint: Breakpoint;
  fontFamily: string;
}) => {
  const isTargetLayerABlock = checkIfLayerIsBlock(layers[targetLayerId]);

  if (isTargetLayerABlock) {
    const newTextWidth = calculateTextWidth(text);

    const leftPosition = getLayerResponsiveLeftPosition({
      left: position.left,
      breakpoint,
      width: newTextWidth,
    });

    return generateTextLayer({
      parentId: targetLayerId,
      text,
      top: position.top,
      left: position.left,
      tabletLeft: leftPosition.tablet,
      mobileLeft: leftPosition.mobile,
      fontSize,
      lineHeight,
      fontFamily,
    });
  }

  return generateTextLayer({
    parentId: targetLayerId,
    text,
    top: position.top,
    left: position.left,
    fontSize,
    lineHeight,
    fontFamily,
  });
};
