import { useEventConnector } from "@core/events";
import {
  generateQuanityIconStyles,
  generateQuantityPickerInputStyles,
  generateQuantityPickerStyles,
} from "@folds/shared/layers";
import { QuantityPickerLayer } from "@folds/shared/types";

export function QuantityPicker({ layer }: { layer: QuantityPickerLayer }) {
  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(layer.id);

  const style = generateQuantityPickerStyles(layer);
  const inputStyle = generateQuantityPickerInputStyles(layer);
  const iconStyle = generateQuanityIconStyles();

  return (
    <div
      style={style}
      id={layer.id}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
    >
      <span style={iconStyle}>
        <svg
          height={layer.iconSize}
          width={layer.iconSize}
          fill={layer.iconColor}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
        >
          <path d="M200-440v-80h560v80H200Z" />
        </svg>
      </span>
      <input
        style={{ ...inputStyle, cursor: "default" }}
        type="number"
        value="1"
        data-type="folds-quantity-picker"
        readOnly
      />
      <span style={iconStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={layer.iconSize}
          width={layer.iconSize}
          fill={layer.iconColor}
          viewBox="0 -960 960 960"
        >
          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
        </svg>
      </span>
    </div>
  );
}
