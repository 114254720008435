import { SelectURL } from "@/features/sidebar/components/SelectURL";
import {
  markLayerInformationAsReplaced,
  setLayerProperties,
  setSelectedLayerIds,
  type LayerInformationToReplace,
  clearDroppedLayersInformationToReplace,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { PopoverPortal } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { LayerId, Layers, LayerType } from "@folds/shared";
import { createSelector } from "@reduxjs/toolkit";
import { RootState, store } from "@core/store";
import { useEffect, useState } from "react";
import { useCenterViewportPositionToLayer } from "@/features/editor/hooks/useCenterViewportPositionToLayer";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import {
  OptionSelect,
  useSelectedOptionTitle,
  useSelectedProductTitle,
} from "@/features/sidebar/components/OptionSelector";
import { twMerge } from "tailwind-merge";
import { getAbsolutePosition } from "@core/utils";
import { useModalWindow } from "@core/modalStore";

function ChevronDown() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6.75L9 11.25L13.5 6.75"
        className="stroke-gray-11"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 5.5L5.5 16.5M5.5 5.5L16.5 16.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-gray-11"
      />
    </svg>
  );
}

const selectCurrentValueNumber = createSelector(
  [(state: RootState) => state.editor.droppedLayersInformationToReplace],
  (droppedLayersValuesToReplace) => {
    if (!droppedLayersValuesToReplace) return 1;

    const totalNumberOfReplacedValues = droppedLayersValuesToReplace.reduce(
      (acc, value) => {
        if (value.replaced) {
          return acc + 1;
        }

        return acc;
      },
      0
    );

    return totalNumberOfReplacedValues + 1;
  }
);

const selectValuesLength = createSelector(
  [(state: RootState) => state.editor.droppedLayersInformationToReplace],
  (droppedLayersInformationToReplace) =>
    droppedLayersInformationToReplace?.length ?? 0
);

const selectLayerLink = createSelector(
  [(state: RootState) => state.editor.layers, (_, id: LayerId) => id],
  (layers, id) => {
    const layer = layers[id];

    if (!layer || !("action" in layer) || layer.action?.type !== "redirect")
      return null;

    return layer.action.url;
  }
);

const getBlockLayerId = (layerId: LayerId, layers: Layers): LayerId | null => {
  const layer = layers[layerId];
  if (!layer || layer.type === LayerType.Page) return null;

  if (layer.type === LayerType.Block) {
    return layer.id;
  }

  return getBlockLayerId(layer.parentId, layers);
};

const useSelectLayerAndCenter = (layerId: LayerId) => {
  const dispatch = useAppDispatch();

  const centerViewportPositionToLayer = useCenterViewportPositionToLayer();

  useEffect(() => {
    const { layers } = store.getState().editor;

    const blockLayerId = getBlockLayerId(layerId, layers);

    if (blockLayerId !== null) {
      centerViewportPositionToLayer(blockLayerId);
    }

    dispatch(setSelectedLayerIds([layerId]));
  }, [centerViewportPositionToLayer, dispatch, layerId]);
};

function ReplaceURL({ layerId }: { layerId: LayerId }) {
  const dispatch = useAppDispatch();
  const length = useAppSelector(selectValuesLength);
  const link = useAppSelector((state) => selectLayerLink(state, layerId));
  const currentValueNumber = useAppSelector(selectCurrentValueNumber);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useSelectLayerAndCenter(layerId);

  const handleSelectUrl = (url: string) => {
    dispatch(
      setLayerProperties({
        properties: { action: { type: "redirect", url } },
        breakpoint: "desktop",
        layerIds: [layerId],
      })
    );

    setIsPopoverOpen(false);
  };

  const handleContinue = () => {
    if (link === null) return;

    dispatch(markLayerInformationAsReplaced({ layerId, type: "url" }));
  };

  const handleCancel = () => {
    dispatch(clearDroppedLayersInformationToReplace());
  };

  return (
    <div className="fixed bottom-0 left-[326px] right-0 z-[98] flex items-center justify-between gap-9 border-t border-gray-6 bg-gray-1 px-7 py-3">
      <div className="flex items-center gap-5">
        <p className="text-sm text-gray-12">Select link</p>
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger
            aria-label="Select URL"
            className="flex h-10 w-80 items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-gray-12 "
          >
            <span className="truncate text-sm">
              {link === null ? "Select link" : link}
            </span>
            <div className="flex-shrink-0">
              <ChevronDown />
            </div>
          </Popover.Trigger>
          <PopoverPortal>
            <Popover.Content
              side="bottom"
              align="start"
              className="z-[999] w-80 bg-gray-2"
              sideOffset={4}
            >
              <SelectURL
                wrapperClassName="w-[336px]"
                onSelect={handleSelectUrl}
              />
            </Popover.Content>
          </PopoverPortal>
        </Popover.Root>
      </div>
      <div className="flex items-center gap-9 self-stretch">
        <button
          type="button"
          className={twMerge(
            "h-full w-60 rounded bg-gray-12 text-sm text-gray-1",
            link === null && "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleContinue}
        >
          Continue - {currentValueNumber}/{length}
        </button>
        <button type="button" aria-label="Close" onClick={handleCancel}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

const selectProductIdFromLayer = createSelector(
  [(state: RootState) => state.editor.layers, (_, layerId: LayerId) => layerId],
  (layers, layerId) => {
    const layer = layers[layerId];
    if (!layer) return null;

    if ("productId" in layer && layer.productId !== null) {
      return layer.productId;
    }

    return null;
  }
);

const selectOptionNameFromLayer = createSelector(
  [(state: RootState) => state.editor.layers, (_, layerId: LayerId) => layerId],
  (layers, layerId) => {
    const layer = layers[layerId];
    if (!layer) return null;

    if (!("optionName" in layer)) return null;

    return layer.optionName;
  }
);

function ReplaceProduct({ layerId }: { layerId: LayerId }) {
  const dispatch = useAppDispatch();
  const currentValueNumber = useAppSelector(selectCurrentValueNumber);
  const length = useAppSelector(selectValuesLength);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useSelectLayerAndCenter(layerId);

  const productId = useAppSelector((state) =>
    selectProductIdFromLayer(state, layerId)
  );
  const productTitle = useSelectedProductTitle(productId);

  const handleContinue = () => {
    if (productId === null) return;

    dispatch(markLayerInformationAsReplaced({ layerId, type: "product" }));
  };

  const handleCancel = () => {
    dispatch(clearDroppedLayersInformationToReplace());
  };

  const handleSelectProduct = (product: {
    productId: number;
    productHandle: string;
  }) => {
    dispatch(
      setLayerProperties({
        layerIds: [layerId],
        breakpoint: "desktop",
        properties: {
          productHandle: product.productHandle,
          productId: product.productId,
          productUnavailableHandle: product.productHandle,
          productUnavailableId: product.productId,
        },
      })
    );

    setIsPopoverOpen(false);
  };

  return (
    <div className="fixed bottom-0 left-[326px] right-0 z-[98] flex items-center justify-between gap-9 border-t border-gray-6 bg-gray-1 px-7 py-3">
      <div className="flex items-center gap-5">
        <p className="text-sm text-gray-12">Select product</p>
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger
            aria-label="Select product"
            className="flex h-10 w-80 items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-gray-12 "
          >
            <span className="truncate text-sm">{productTitle}</span>
            <div className="flex-shrink-0">
              <ChevronDown />
            </div>
          </Popover.Trigger>
          <PopoverPortal>
            <Popover.Content
              side="bottom"
              align="start"
              className="z-[999] w-80 bg-gray-2"
              sideOffset={4}
            >
              <ProductSelect
                onSelect={handleSelectProduct}
                wrapperClassName="w-full"
              />
            </Popover.Content>
          </PopoverPortal>
        </Popover.Root>
      </div>
      <div className="flex items-center gap-9 self-stretch">
        <button
          type="button"
          className={twMerge(
            "h-full w-60 rounded bg-gray-12 text-sm text-gray-1",
            productId === null && "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleContinue}
        >
          Continue - {currentValueNumber}/{length}
        </button>
        <button type="button" aria-label="Close" onClick={handleCancel}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

const selectAddToCartProductId = createSelector(
  [(state: RootState) => state.editor.layers, (_, layerId: LayerId) => layerId],
  (layers, layerId) => {
    const layer = layers[layerId];
    if (!layer) return null;

    if (
      !("action" in layer) ||
      layer.action?.type !== "add-to-cart" ||
      layer.action.productId === null ||
      layer.action.productHandle === null
    )
      return null;

    return layer.action.productId;
  }
);

function ReplaceAddToCartAction({ layerId }: { layerId: LayerId }) {
  const dispatch = useAppDispatch();
  const length = useAppSelector(selectValuesLength);
  const currentValueNumber = useAppSelector(selectCurrentValueNumber);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  useSelectLayerAndCenter(layerId);

  const productId = useAppSelector((state) =>
    selectAddToCartProductId(state, layerId)
  );
  const productTitle = useSelectedProductTitle(productId);

  const handleContinue = () => {
    if (productId === null) return;

    dispatch(
      markLayerInformationAsReplaced({ layerId, type: "addToCartAction" })
    );
  };

  const handleCancel = () => {
    dispatch(clearDroppedLayersInformationToReplace());
  };

  const handleSelectProduct = (product: {
    productId: number;
    productHandle: string;
  }) => {
    dispatch(
      setLayerProperties({
        layerIds: [layerId],
        breakpoint: "desktop",
        properties: {
          action: {
            productHandle: product.productHandle,
            productId: product.productId,
            type: "add-to-cart",
          },
          productUnavailableHandle: product.productHandle,
          productUnavailableId: product.productId,
        },
      })
    );

    setIsPopoverOpen(false);
  };

  return (
    <div className="fixed bottom-0 left-[326px] right-0 z-[98] flex items-center justify-between gap-9 border-t border-gray-6 bg-gray-1 px-7 py-3">
      <div className="flex items-center gap-5">
        <p className="text-sm text-gray-12">Select product</p>
        <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <Popover.Trigger
            aria-label="Select product"
            className="flex h-10 w-80 items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-gray-12 "
          >
            <span className="truncate text-sm">{productTitle}</span>
            <div className="flex-shrink-0">
              <ChevronDown />
            </div>
          </Popover.Trigger>
          <PopoverPortal>
            <Popover.Content
              side="bottom"
              align="start"
              className="z-[999] w-80 bg-gray-2"
              sideOffset={4}
            >
              <ProductSelect
                onSelect={handleSelectProduct}
                wrapperClassName="w-full"
              />
            </Popover.Content>
          </PopoverPortal>
        </Popover.Root>
      </div>
      <div className="flex items-center gap-9 self-stretch">
        <button
          type="button"
          className={twMerge(
            "h-full w-60 rounded bg-gray-12 text-sm text-gray-1",
            productId === null && "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleContinue}
        >
          Continue - {currentValueNumber}/{length}
        </button>
        <button type="button" aria-label="Close" onClick={handleCancel}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

function ReplaceOption({ layerId }: { layerId: LayerId }) {
  const dispatch = useAppDispatch();
  const length = useAppSelector(selectValuesLength);
  const currentValueNumber = useAppSelector(selectCurrentValueNumber);
  const [isProductPopoverOpen, setIsProductPopoverOpen] = useState(false);
  const [isOptionPopoverOpen, setIsOptionPopoverOpen] = useState(false);

  useSelectLayerAndCenter(layerId);

  const productId = useAppSelector((state) =>
    selectProductIdFromLayer(state, layerId)
  );
  const optionName = useAppSelector((state) =>
    selectOptionNameFromLayer(state, layerId)
  );

  const productTitle = useSelectedProductTitle(productId);
  const optionTitle = useSelectedOptionTitle(productId, optionName);

  const handleContinue = () => {
    if (optionName === null) return;

    dispatch(markLayerInformationAsReplaced({ layerId, type: "option" }));
  };

  const handleCancel = () => {
    dispatch(clearDroppedLayersInformationToReplace());
  };

  const handleSelectProduct = (product: {
    productId: number;
    productHandle: string;
  }) => {
    dispatch(
      setLayerProperties({
        layerIds: [layerId],
        breakpoint: "desktop",
        properties: {
          productHandle: product.productHandle,
          productId: product.productId,
          optionName: null,
        },
      })
    );

    setIsProductPopoverOpen(false);
  };

  const handleSelectOption = (name: string) => {
    dispatch(
      setLayerProperties({
        layerIds: [layerId],
        breakpoint: "desktop",
        properties: {
          optionName: name,
        },
      })
    );

    setIsOptionPopoverOpen(false);
  };

  return (
    <div className="fixed bottom-0 left-[326px] right-0 z-[98] flex items-center justify-between gap-9 border-t border-gray-6 bg-gray-1 px-7 py-3">
      <div className="flex items-center gap-5">
        <p className="text-sm text-gray-12">Select option</p>
        <Popover.Root
          open={isProductPopoverOpen}
          onOpenChange={setIsProductPopoverOpen}
        >
          <Popover.Trigger
            aria-label="Select product"
            className="flex h-10 w-80 items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-gray-12 "
          >
            <span className="truncate text-sm">{productTitle}</span>
            <div className="flex-shrink-0">
              <ChevronDown />
            </div>
          </Popover.Trigger>
          <PopoverPortal>
            <Popover.Content
              side="bottom"
              align="start"
              className="z-[999] w-80 bg-gray-2"
              sideOffset={4}
            >
              <ProductSelect
                onSelect={handleSelectProduct}
                wrapperClassName="w-full"
              />
            </Popover.Content>
          </PopoverPortal>
        </Popover.Root>
        {typeof productId === "number" ? (
          <Popover.Root
            open={isOptionPopoverOpen}
            onOpenChange={setIsOptionPopoverOpen}
          >
            <Popover.Trigger
              aria-label="Select product"
              className="flex h-10 w-80 items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-gray-12 "
            >
              <span className="truncate text-sm">{optionTitle}</span>
              <div className="flex-shrink-0">
                <ChevronDown />
              </div>
            </Popover.Trigger>
            <PopoverPortal>
              <Popover.Content
                side="bottom"
                align="start"
                className="z-[999] w-80 bg-gray-2"
                sideOffset={4}
              >
                <OptionSelect
                  onSelect={handleSelectOption}
                  wrapperClassName="w-full"
                  productId={productId}
                />
              </Popover.Content>
            </PopoverPortal>
          </Popover.Root>
        ) : (
          <button
            type="button"
            className="flex h-10 w-80 cursor-default items-center justify-between gap-3 rounded border border-gray-6 bg-gray-2 px-4 text-left text-sm text-gray-11"
          >
            Select a product first
          </button>
        )}
      </div>
      <div className="flex items-center gap-9 self-stretch">
        <button
          type="button"
          className={twMerge(
            "h-full w-60 rounded bg-gray-12 text-sm text-gray-1",
            optionName === null && "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleContinue}
        >
          Continue - {currentValueNumber}/{length}
        </button>
        <button type="button" aria-label="Close" onClick={handleCancel}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

const getCurrentValueToReplace = (
  informationToReplace: LayerInformationToReplace[] | null
) => {
  if (!informationToReplace) return null;

  const valuesNotReplaced = informationToReplace.filter(
    (value) => !value.replaced
  );

  const valuesNotReplacedSortedByLowestTopAndLeft = valuesNotReplaced.sort(
    (a, b) => {
      const firstPostiion = getAbsolutePosition(a.layerId);
      const secondPosition = getAbsolutePosition(b.layerId);

      const isTopWithin5Pixels =
        Math.abs(firstPostiion.top - secondPosition.top) < 5;

      if (isTopWithin5Pixels) {
        return firstPostiion.left - secondPosition.left;
      }

      return firstPostiion.top - secondPosition.top;
    }
  );

  return valuesNotReplacedSortedByLowestTopAndLeft[0] ?? null;
};

/**
 * Intercom is hidden when replacing dropped layers information since it blocks the close button
 */
const useHideIntercomWhenReplacingDroppedLayersInformation = () => {
  const modalWindow = useModalWindow();
  const isIntercomLoaded = useAppSelector(
    (state) => state.editor.isIntercomLoaded
  );

  const droppedLayersInformationToReplace = useAppSelector(
    (state) => state.editor.droppedLayersInformationToReplace
  );

  useEffect(() => {
    if (!isIntercomLoaded || !modalWindow?.Intercom) return;

    if (
      !droppedLayersInformationToReplace ||
      droppedLayersInformationToReplace.length === 0
    ) {
      modalWindow.Intercom("update", { hide_default_launcher: false });
      return;
    }

    modalWindow.Intercom("update", { hide_default_launcher: true });
  }, [droppedLayersInformationToReplace, isIntercomLoaded, modalWindow]);
};

export function ReplaceDroppedLayersInformation() {
  useHideIntercomWhenReplacingDroppedLayersInformation();

  const [currentValue, setCurrentValue] =
    useState<LayerInformationToReplace | null>(null);

  const droppedLayersInformationToReplace = useAppSelector(
    (state) => state.editor.droppedLayersInformationToReplace
  );

  useEffect(() => {
    const updatedCurrentValue = getCurrentValueToReplace(
      droppedLayersInformationToReplace
    );

    setCurrentValue(updatedCurrentValue);
  }, [droppedLayersInformationToReplace]);

  if (!currentValue) return null;

  switch (currentValue.type) {
    case "url": {
      return <ReplaceURL layerId={currentValue.layerId} />;
    }
    case "product": {
      return <ReplaceProduct layerId={currentValue.layerId} />;
    }
    case "addToCartAction": {
      return <ReplaceAddToCartAction layerId={currentValue.layerId} />;
    }
    case "option": {
      return <ReplaceOption layerId={currentValue.layerId} />;
    }
  }
}
