import { nanoid } from "@core/lib";
import {
  BlockLayer,
  BreakpointLayer,
  DEFAULT_BLOCK_PROPERTIES,
  DESKTOP_BREAKPOINT_WIDTH,
  LayerType,
  Layers,
  PageLayer,
  ROOT_LAYER_ID,
} from "@folds/shared";

export const generateInitialLayers = (): Layers => {
  const breakpointId = nanoid();
  const initialBlockId = nanoid();

  const breakpointLayer: BreakpointLayer = {
    parentId: ROOT_LAYER_ID,
    id: breakpointId,
    type: LayerType.Breakpoint,
    children: [initialBlockId],
    width: DESKTOP_BREAKPOINT_WIDTH,
    left: 0,
  };

  const initialBlock: BlockLayer = {
    ...DEFAULT_BLOCK_PROPERTIES,
    type: LayerType.Block,
    id: initialBlockId,
    height: 1024,
    parentId: breakpointId,
  };

  const initialRootLayer: PageLayer = {
    parentId: null,
    children: [breakpointId],
    id: ROOT_LAYER_ID,
    type: LayerType.Page,
  };

  return {
    [ROOT_LAYER_ID]: initialRootLayer,
    [breakpointId]: breakpointLayer,
    [initialBlockId]: initialBlock,
  };
};
