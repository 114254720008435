import { resumeHistoryAction } from "@core/features/editor/editorSlice/actions/resumeHistoryAction";
import { setSelectedLayerIdsAction } from "@core/features/editor/editorSlice/actions/setSelectedLayerIdsAction";
import type {
  EditorState,
  InitialLayersInformation,
} from "@core/features/editor/editorSlice/editorSlice";
import { getAbsolutePosition, getElement } from "@core/utils";
import isEqual from "lodash.isequal";

const checkIfLayersHaveNotMoved = (
  initialLayersInformation: InitialLayersInformation
) => {
  const updatedLayersInformation: InitialLayersInformation =
    initialLayersInformation.map(({ layerId, position, type }) => {
      if (type === "intrinsic") {
        const currentPositionEntries = Object.entries(position).map(
          ([positionLayerId]) => {
            const absolutePosition = getAbsolutePosition(positionLayerId);

            const newValue = {
              top: absolutePosition.top,
              left: absolutePosition.left,
            };

            return [positionLayerId, newValue];
          }
        );

        const newPosition = Object.fromEntries(currentPositionEntries);

        return {
          type: "intrinsic",
          layerId,
          position: newPosition,
        };
      }

      const absolutePosition = getAbsolutePosition(layerId);

      const newPosition = {
        top: absolutePosition.top,
        left: absolutePosition.left,
      };

      return {
        layerId,
        position: newPosition,
        type: "extrisic",
      };
    });

  const isInTheSamePositions = initialLayersInformation.every(
    (initialPosition, index) => {
      const currentDraggingPosition = updatedLayersInformation[index];

      if (currentDraggingPosition === undefined) return false;

      return isEqual(initialPosition, currentDraggingPosition);
    }
  );

  return isInTheSamePositions;
};

export const dragEndAction = (state: EditorState) => {
  if (
    state.dragging &&
    typeof state.dragging.initializedDraggingLayerId === "string"
  ) {
    const checkIfIsInTheSamePosition = checkIfLayersHaveNotMoved(
      state.dragging.initialLayersInformation
    );

    if (checkIfIsInTheSamePosition === true) {
      setSelectedLayerIdsAction(state, [
        state.dragging.initializedDraggingLayerId,
      ]);
    }
  }

  // Re-enable pointer events
  // We are resetting all layers to be extra safe
  Object.values(state.layers).forEach((layer) => {
    const element = getElement(layer.id);

    if (element === null) return;

    element.style.pointerEvents = "auto";
  });

  resumeHistoryAction(state);

  state.dragging = null;
  state.duplicatingLayerIds = null;
};
