import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";
import { checkIfIsIntegrationTest } from "@/utils/checkIfIsIntegrationTest";
import { createContext, useContext } from "react";

const isIntegrationTest = checkIfIsIntegrationTest();

const ShopContext = createContext<string | null>(null);

/**
 * Returns the page id from the url.
 */
export const useShopDomain = () => {
  const pageId = useContext(ShopContext);

  if (pageId === null) {
    throw new Error("useShop must be used within a ShopProvider");
  }

  return pageId;
};

declare global {
  interface Window {
    shopify:
      | {
          config: {
            apiKey: string;
            host: string;
            locale: string;
            shop: string;
          };
          idToken: () => Promise<string>;
        }
      | undefined;
  }
}

export function ShopProvider({ children }: { children: React.ReactNode }) {
  const isInsidePlayground = useIsInsidePlayground();

  if (isIntegrationTest || isInsidePlayground) {
    return <ShopContext.Provider value="null">{children}</ShopContext.Provider>;
  }

  const parsedShop = window.shopify.config.shop.split(".myshopify.com")[0];

  if (typeof parsedShop !== "string") {
    return <p>No shop provided</p>;
  }

  return (
    <ShopContext.Provider value={parsedShop}>{children} </ShopContext.Provider>
  );
}
