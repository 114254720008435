import { Layers } from "@folds/shared/types";
import {
  ElementsHook,
  useAccordions,
  useAddToCartButtons,
  useCarousels,
  useOptionDropdowns,
  useOptionSelectors,
  useProductImageCarousels,
  useQuantityPickers,
  useRedirectButtons,
  useShapes,
} from "@/features/sidebar/api/getElements";
import { PreviewLayers } from "@/features/sidebar/components/PreviewLayers";
import { useAppSelector } from "@core/hooks";

const getLayersWithReplacedFontFamilies = (
  layers: Layers,
  fontFamily: string
): Layers => {
  const layersWithReplacedFontFamilies = Object.entries(layers).map(
    ([key, layer]) => {
      if ("fontFamily" in layer) {
        const layerWithUpdatedFontFamily = {
          ...layer,
          fontFamily,
        };

        return [key, layerWithUpdatedFontFamily];
      }

      return [key, layer];
    }
  );

  return Object.fromEntries(layersWithReplacedFontFamilies);
};

const FOUR_COLUMNS_ELEMENT_WIDTH = 54.75;
export const ONE_COLUMN_ELEMENT_WIDTH = 231;

function Elements({
  columns = 1,
  elementsHook,
  targetWidth = ONE_COLUMN_ELEMENT_WIDTH,
  description,
  ...previewLayersProps
}: {
  elementsHook: ElementsHook;
  description: string;
  columns?: number;
} & Partial<React.ComponentProps<typeof PreviewLayers>>) {
  const previouslySelectedFontFamily = useAppSelector(
    (state) => state.editor.previouslySelectedFontFamily
  );

  const { data: elements, error, isLoading } = elementsHook();

  if (isLoading || error !== undefined || elements === undefined)
    return <div className="h-12 animate-pulse bg-gray-3" />;

  return (
    <div
      className="grid gap-1"
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {elements.map((element) => {
        const layersWithReplacedFontFamilies =
          getLayersWithReplacedFontFamilies(
            element.layers,
            previouslySelectedFontFamily
          );

        return (
          <PreviewLayers
            type="element"
            layers={layersWithReplacedFontFamilies}
            products={element.products}
            elementWidth={element.width}
            elementHeight={element.height}
            targetWidth={targetWidth}
            description={description}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...previewLayersProps}
            // eslint-disable-next-line no-underscore-dangle
            key={element._id}
          />
        );
      })}
    </div>
  );
}

export function SidebarElements() {
  return (
    <div className="flex flex-col py-3">
      <div className="mx-3 flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <p className="text-sm">Shapes</p>

          <Elements
            elementsHook={useShapes}
            columns={4}
            description="Shape"
            targetWidth={FOUR_COLUMNS_ELEMENT_WIDTH}
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Add to cart</p>
          <Elements
            elementsHook={useAddToCartButtons}
            description="Add to cart button"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Redirect button</p>
          <Elements
            elementsHook={useRedirectButtons}
            description="Redirect button"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Option selector</p>
          <Elements
            elementsHook={useOptionSelectors}
            description="Option selector"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Option dropdown</p>
          <Elements
            elementsHook={useOptionDropdowns}
            description="Option dropdown"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Accordion</p>
          <Elements elementsHook={useAccordions} description="Accordion" />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Quantity picker</p>
          <div className="grid grid-cols-4 gap-1">
            <Elements
              elementsHook={useQuantityPickers}
              description="Quantity picker"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Product image carousel</p>
          <div className="grid grid-cols-4 gap-1">
            <Elements
              elementsHook={useProductImageCarousels}
              description="Product image carousel"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">Carousel</p>
          <div className="grid grid-cols-4 gap-1">
            <Elements elementsHook={useCarousels} description="Carousel" />
          </div>
        </div>
      </div>
      {/* TODO: Repalce */}
      {/* <div className="flex flex-col gap-1">
        <PreviewLayers
          elementHeight={100}
          elementWidth={100}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          description="Square"
          layers={squareLayers}
          type="element"
          products={[]}
        />
        <PreviewLayers
          elementHeight={100}
          elementWidth={200}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          description="Option dropdown"
          layers={optionDropdownLayers}
          type="element"
          products={[]}
        />
        <PreviewLayers
          elementHeight={500}
          elementWidth={500}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          description="Option dropdown"
          layers={optionSelectorLayers}
          type="element"
          products={[]}
        />
        <PreviewLayers
          elementHeight={75}
          elementWidth={125}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          description="Quantity picker"
          type="element"
          layers={quantityPickerLayers}
          products={[]}
        />
        <PreviewLayers
          elementHeight={500}
          elementWidth={500}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          layers={carouselLayers}
          products={[]}
          type="element"
          description="Carousel"
        />
        <PreviewLayers
          elementHeight={48}
          elementWidth={240}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          layers={redirectButtonLayers}
          products={[]}
          description="Redirect button"
          type="element"
        />
        <PreviewLayers
          elementHeight={24}
          elementWidth={127.95}
          layers={productPriceLayers}
          products={[]}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          description="Product price"
          type="element"
        />
        <PreviewLayers
          layers={productThumbnailImageLayers}
          elementHeight={500}
          elementWidth={500}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          products={[]}
          description="Product thumbnail image carousel"
          type="element"
        />
        <PreviewLayers
          elementHeight={500}
          layers={accordionLayers}
          description="Accordion"
          elementWidth={500}
          products={[]}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          type="element"
        />
        <PreviewLayers
          elementHeight={500}
          elementWidth={500}
          layers={accordionLayers}
          products={[]}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          type="element"
          description="Accordion"
        />
        <PreviewLayers
          layers={textLayers}
          elementHeight={50}
          elementWidth={200}
          description="Text"
          products={[]}
          targetWidth={ONE_COLUMN_ELEMENT_WIDTH}
          type="element"
        />
      </div> */}
    </div>
  );
}
