import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";
import type { EditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getLayerDimensions } from "@core/utils";
import { Layer } from "@folds/shared";
import { Draft } from "@reduxjs/toolkit";

const resetTabletLeftPosition = (layer: Draft<Layer>) => {
  if (!("left" in layer) || !("tablet" in layer)) return;

  const { width } = getLayerDimensions(layer.id);

  const responsiveLeft = getLayerResponsiveLeftPosition({
    breakpoint: "desktop",
    left: layer.left,
    width,
  });

  layer.hasEditedTabletPosition = false;
  layer.tablet.left = responsiveLeft.tablet;

  if (!layer.hasEditedMobilePosition) {
    layer.mobile.left = responsiveLeft.mobile;
  }
};

export const deleteTabletOverridesAction = (state: EditorState) => {
  state.selectedLayerIds.forEach((id) => {
    const layer = state.layers[id];

    if (!layer || !("tablet" in layer)) return;

    layer.tablet = { visible: true };
    resetTabletLeftPosition(layer);
  });
};
