import {
  DESKTOP_BREAKPOINT_WIDTH,
  MOBILE_BREAKPOINT_WIDTH,
  TABLET_BREAKPOINT_WIDTH,
} from "@folds/shared/constants";
import type { Breakpoint } from "@folds/shared/types";

/**
 * Returns the width of a breakpoint
 */
export const getBreakpointWidth = (breakpoint: Breakpoint) => {
  switch (breakpoint) {
    case "desktop":
      return DESKTOP_BREAKPOINT_WIDTH;
    case "tablet":
      return TABLET_BREAKPOINT_WIDTH;
    case "mobile":
      return MOBILE_BREAKPOINT_WIDTH;
  }
};
