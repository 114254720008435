import { type Static, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import type { Layer } from './layers/layer-types';

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type Viewport = {
  scale: number;
  translateX: number;
  translateY: number;
};

export type Point = {
  x: number;
  y: number;
};

export type Position = {
  top: number;
  left: number;
};

export const SidebarStateValues = {
  Styles: 'styles',
  Templates: 'templates',
  Elements: 'elements',
  Text: 'text',
  Images: 'images',
  Apps: 'apps',
  Icons: 'icons',
} as const;

const sidebarStateSchema = Type.Union([
  Type.Literal(SidebarStateValues.Styles),
  Type.Literal(SidebarStateValues.Templates),
  Type.Literal(SidebarStateValues.Elements),
  Type.Literal(SidebarStateValues.Text),
  Type.Literal(SidebarStateValues.Images),
  Type.Literal(SidebarStateValues.Apps),
  Type.Literal(SidebarStateValues.Icons),
]);

export const compiledSidebarStateSchema =
  TypeCompiler.Compile(sidebarStateSchema);

export type SidebarState = Static<typeof sidebarStateSchema>;

export type Side = 'left' | 'right' | 'top' | 'bottom';
export type Corners = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const overrideSchema = Type.Union([
  Type.Literal('Size'),
  Type.Literal('Rounded corners'),
  Type.Literal('Fill'),
  Type.Literal('Layout'),
  Type.Literal('Border'),
  Type.Literal('Typography'),
  Type.Literal('Position'),
]);

export type Override = Static<typeof overrideSchema>;

export type Alignment =
  | 'horizontal-left'
  | 'horizontal-center'
  | 'horizontal-right'
  | 'vertical-top'
  | 'vertical-center'
  | 'vertical-bottom';

export type Box = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export type Size = {
  width: number;
  height: number;
};

const canvasModeSchema = Type.Union([
  /**
   * Select mode. User can select elements.
   */
  Type.Literal('Select'),
  /**
   * Pan mode. User can pan the canvas.
   */
  Type.Literal('Pan'),
]);

export type CanvasMode = Static<typeof canvasModeSchema>;

type UpsertAction = {
  type: 'upsert';
  layer: Layer;
};

type DeleteAction = {
  type: 'delete';
  layerId: LayerId;
};

export type ReturnValue<T> =
  | {
      value: T;
      error: null;
    }
  | {
      value: null;
      error: string;
    };

export type Actions = (DeleteAction | UpsertAction)[];

export type LayerId = string;
export type Modal = HTMLDivElement | null;

type Toast = {
  message: React.ReactNode;
  state: 'normal' | 'success' | 'error' | 'loading';
  id: string;
  customIcon?: React.ReactNode | undefined;
  props?: React.HTMLProps<HTMLDivElement>;
};

export type Toasts = Record<string, Toast>;
