import type {
  DraftEditorState,
  TrackedStateChange,
} from "@core/features/editor/editorSlice/editorSlice";
import {
  applyTrackedState,
  getCurrentChanges,
} from "@core/features/editor/editorSlice/actions/utils/changes";

export const undoAction = (state: DraftEditorState) => {
  const previousState = state.previousStates.shift();

  if (!previousState) return;

  const currentSelectedLayerIds = state.selectedLayerIds;

  const currentChanges = getCurrentChanges(state.layers, previousState.changes);

  applyTrackedState(state, previousState);

  const currentState: TrackedStateChange = {
    selectedLayerIds: currentSelectedLayerIds,
    changes: currentChanges,
  };

  state.futureStates.unshift(currentState);
};
