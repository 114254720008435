import {
  dragStart,
  selectIsDragging,
  startDuplicating,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { type RootState } from "@core/store";
import { getBoundingBoxRect } from "@core/utils";
import { isMiddleClick, isRightClick } from "@core/utils/click";
import { createSelector } from "@reduxjs/toolkit";

const selectSelectionCoverBox = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.selectedLayerIds,
  ],
  (layers, selectedLayerIds) => {
    const selectedLayerIdsWithoutBlocks = selectedLayerIds.filter((id) => {
      const layer = layers[id];

      if (!layer) return false;

      return layer.type !== "block";
    });

    if (selectedLayerIdsWithoutBlocks.length < 2) return null;

    const selectionBox = getBoundingBoxRect(selectedLayerIdsWithoutBlocks);

    return selectionBox;
  }
);

/**
 * Allows the user to drag multiple layers at once.
 */
export function SelectionCover() {
  const selectionCoverBox = useAppSelector(selectSelectionCoverBox);
  const isDragging = useAppSelector(selectIsDragging);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const dispatch = useAppDispatch();
  if (selectionCoverBox === null) return null;

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isMiddleClick(event) || isRightClick(event)) return;

    event.stopPropagation();

    if (event.altKey) {
      dispatch(startDuplicating(selectedLayerIds));
    }

    dispatch(
      dragStart({
        event: { clientX: event.clientX, clientY: event.clientY },
        initializedDraggingLayerId: null,
      })
    );
  };

  return (
    <div
      className="absolute z-[1] cursor-pointer"
      onMouseDown={handleMouseDown}
      style={{
        ...selectionCoverBox,
        ...(isDragging && { pointerEvents: "none" }),
      }}
    />
  );
}
