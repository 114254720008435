import { useShopifyPassword } from "@/features/editor/api/getShopifyPassword";
import { useSaveShopifyPassword } from "@/features/editor/api/saveShopifyPassword";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function StorePassword() {
  const { data: shopifyPassword } = useShopifyPassword();
  const saveShopifyPassword = useSaveShopifyPassword();
  const [password, setPassword] = useState(shopifyPassword?.password ?? "");

  useEffect(() => {
    setPassword(shopifyPassword?.password ?? "");
  }, [shopifyPassword?.password]);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSavePassword = async () => {
    if (shopifyPassword?.password === password || password.length === 0) return;

    await saveShopifyPassword(password);
  };

  if (!shopifyPassword || !shopifyPassword.enabled) return;

  return (
    <div className="flex flex-row items-center justify-between rounded border border-gray-6 bg-gray-3 px-6 py-3 max-[850px]:flex-col max-[850px]:items-start max-[850px]:gap-3">
      <div className="flex flex-col">
        <h3 className="line-clamp-1 text-base font-medium text-gray-12">
          Store password
        </h3>
        <p className="line-clamp-2 text-sm text-gray-11">
          We need this to improve your editor experience
        </p>
      </div>
      <div className="flex items-center gap-1 max-[850px]:w-full max-[700px]:flex-col">
        <input
          className="py h-11 self-stretch rounded border border-gray-6 px-4 text-sm max-[850px]:flex-1 max-[700px]:flex-shrink-0 max-[700px]:basis-auto"
          placeholder="Enter password"
          value={password}
          onChange={handleChangePassword}
        />
        <button
          className={twMerge(
            "h-11 w-28 self-stretch rounded bg-gray-12 text-sm text-gray-1 max-[700px]:w-full",
            (shopifyPassword.password === password || password.length === 0) &&
              "cursor-not-allowed bg-gray-11"
          )}
          type="button"
          onClick={handleSavePassword}
        >
          Update
        </button>
      </div>
    </div>
  );
}
