import { Input } from "@/features/sidebar/components/Input";
import { Toggle } from "@/features/sidebar/components/Toggle";
import {
  selectAllCornersBorderRadius,
  selectBorderBottomLeftRadius,
  selectBorderBottomRightRadius,
  selectBorderTopLeftRadius,
  selectBorderTopRightRadius,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { useState } from "react";
import * as i from "../../Sidebar.icons";

const convertBorderRadiusToString = (value: number | string | null) => {
  if (value === null) return "";

  return String(value);
};

export function SidebarBorderRadiusSettings() {
  const dispatch = useAppDispatch();

  const borderTopLeftRadius = useAppSelector(selectBorderTopLeftRadius);
  const borderTopRightRadius = useAppSelector(selectBorderTopRightRadius);
  const borderBottomRightRadius = useAppSelector(selectBorderBottomRightRadius);
  const borderBottomLeftRadius = useAppSelector(selectBorderBottomLeftRadius);

  const allCornersBorderRadius = useAppSelector(selectAllCornersBorderRadius);

  const [isEditingAllBorderRadiusCorners, setIsEditingAllBorderRadiusCorners] =
    useState(false);

  const handleBorderRadiusChange = (
    value: string,
    type: "all" | "top-left" | "top-right" | "bottom-right" | "bottom-left"
  ) => {
    const borderRadius = Number(value);
    if (Number.isNaN(borderRadius)) return;

    switch (type) {
      case "all":
        dispatch(
          setSelectedLayerProperties({
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
          })
        );
        break;
      case "top-left":
        dispatch(
          setSelectedLayerProperties({ borderTopLeftRadius: borderRadius })
        );
        break;
      case "top-right":
        dispatch(
          setSelectedLayerProperties({ borderTopRightRadius: borderRadius })
        );
        break;
      case "bottom-right":
        dispatch(
          setSelectedLayerProperties({
            borderBottomRightRadius: borderRadius,
          })
        );
        break;
      case "bottom-left":
        dispatch(
          setSelectedLayerProperties({
            borderBottomLeftRadius: borderRadius,
          })
        );
        break;
    }
  };

  return (
    <div className="flex gap-2">
      {isEditingAllBorderRadiusCorners ? (
        <div className="grid flex-1 grid-cols-2 gap-2">
          <Input
            unit="px"
            minValue={0}
            value={convertBorderRadiusToString(borderTopLeftRadius)}
            icon={<i.TopLeftBorderRadius />}
            onValueChange={(value) =>
              handleBorderRadiusChange(value, "top-left")
            }
          />
          <Input
            unit="px"
            minValue={0}
            value={convertBorderRadiusToString(borderTopRightRadius)}
            icon={<i.TopRightBorderRadius />}
            onValueChange={(value) =>
              handleBorderRadiusChange(value, "top-right")
            }
          />
          <Input
            unit="px"
            minValue={0}
            value={convertBorderRadiusToString(borderBottomLeftRadius)}
            icon={<i.BottomLeftBorderRadius />}
            onValueChange={(value) =>
              handleBorderRadiusChange(value, "bottom-left")
            }
          />
          <Input
            unit="px"
            minValue={0}
            value={convertBorderRadiusToString(borderBottomRightRadius)}
            icon={<i.BottomRightBorderRadius />}
            onValueChange={(value) =>
              handleBorderRadiusChange(value, "bottom-right")
            }
          />
        </div>
      ) : (
        <Input
          unit="px"
          minValue={0}
          fill
          value={convertBorderRadiusToString(allCornersBorderRadius)}
          icon={<i.TopLeftBorderRadius />}
          onValueChange={(value) => handleBorderRadiusChange(value, "all")}
        />
      )}
      <Toggle
        icon={i.ExpandAllCorners}
        pressed={isEditingAllBorderRadiusCorners}
        onClick={() =>
          setIsEditingAllBorderRadiusCorners((previous) => !previous)
        }
      />
    </div>
  );
}
