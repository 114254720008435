import {
  BlockLayer,
  ButtonLayer,
  LayerType,
  RectangleLayer,
  TextLayer,
} from '../types';

export const INITIAL_VIEWPORT_SCALE = 0.5;

export const ROOT_LAYER_ID = 'ROOT';

export const DEFAULT_BLOCK_PROPERTIES: Omit<
  BlockLayer,
  'id' | 'parentId' | 'height' | 'pageId'
> = {
  type: LayerType.Block,
  backgroundColor: 'rgba(255,255,255,1)',
  backgroundImage: null,
  backgroundSize: 'cover',
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopWidth: 0,
  borderColor: 'rgba(0,0,0,1)',
  borderStyle: 'solid',
  fillType: 'color',
  children: [],
  mobile: { visible: true, layoutGuideColumns: 4 },
  tablet: { visible: true, layoutGuideColumns: 8 },
  visible: true,
  backgroundImageOverlay: null,
  backgroundPositionX: 0,
  backgroundPositionY: 0,
  layoutGuideColumns: 12,
  layoutGuideColor: 'rgba(255,0,0,0.15)',
  layoutGuideColumnGap: 12,
  layoutGuideEnabled: false,
  layoutGuideMarginBottom: 24,
  layoutGuideMarginLeft: 24,
  layoutGuideMarginRight: 24,
  layoutGuideMarginTop: 24,
};

export const DEFAULT_CUSTOM_TEXT_PROPERTIES: Omit<
  TextLayer,
  | 'id'
  | 'parentId'
  | 'left'
  | 'top'
  | 'pageId'
  | 'text'
  | 'content'
  | 'productId'
> = {
  type: LayerType.Text,
  width: null,
  zIndex: 0,
  hasEditedMobilePosition: false,
  hasEditedTabletPosition: false,
  hasEditedDesktopPosition: false,
  mobile: {},
  tablet: {},
  color: 'rgba(0,0,0,1)',
  fontFamily: 'Inter',
  state: null,
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: 0,
  lineHeight: 120,
  textAlign: 'left',
  tag: 'p',
  textTransform: 'none',
  variableId: null,
  italic: false,
  lineThrough: false,
  underline: false,
  visible: true,
  hover: {},
  productUnavailable: {},
  productUnavailableId: null,
  optionName: null,
  productHandle: null,
  productUnavailableHandle: null,
  action: null,
};

export const IF_STATEMENT_HEIGHT = 70;
export const IF_STATEMENT_VERTICAL_GAP = 10;
export const STATE_VERTICAL_GAP = 40;

export const DESKTOP_BREAKPOINT_WIDTH = 1440;
export const TABLET_BREAKPOINT_WIDTH = 768;
export const MOBILE_BREAKPOINT_WIDTH = 390;

export const DEFAULT_RECTANGLE_PROPERTIES: Omit<
  RectangleLayer,
  'id' | 'parentId' | 'left' | 'top' | 'pageId'
> = {
  type: LayerType.Rectangle,
  fillType: 'color',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderStyle: 'solid',
  borderColor: 'rgba(0,0,0,1)',
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  backgroundSize: 'cover',
  backgroundColor: 'rgba(255,255,255,1)',
  backgroundImage: null,
  height: 1,
  width: 1,
  hasEditedMobilePosition: false,
  hasEditedTabletPosition: false,
  hasEditedDesktopPosition: false,
  mobile: { visible: true },
  tablet: { visible: true },
  zIndex: 0,
  hover: {},
  visible: true,
  action: null,
  productUnavailable: {},
  productUnavailableId: null,
  productUnavailableHandle: null,
  state: null,
  backgroundImageOverlay: null,
  backgroundPositionX: 0,
  backgroundPositionY: 0,
};

export const DEFAULT_BUTTON_PROPERTIES: Omit<
  ButtonLayer,
  'id' | 'parentId' | 'left' | 'top' | 'textHtml' | 'pageId' | 'text'
> = {
  type: LayerType.Button,
  fillType: 'color',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderStyle: 'solid',
  borderColor: 'rgba(0,0,0,1)',
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  backgroundSize: 'cover',
  backgroundColor: 'rgba(255,255,255,1)',
  backgroundImage: null,
  height: 1,
  width: 1,
  hasEditedMobilePosition: false,
  hasEditedTabletPosition: false,
  hasEditedDesktopPosition: false,
  mobile: { visible: true },
  tablet: { visible: true },
  action: null,
  zIndex: 0,
  hover: {},
  state: null,
  hoverChildren: {},
  visible: true,
  children: [],
  productUnavailable: {},
  productUnavailableId: null,
  productUnavailableHandle: null,
  backgroundImageOverlay: null,
  backgroundPositionX: 0,
  backgroundPositionY: 0,
  outlineColor: 'rgba(0,0,0,0)',
  outlineOffset: 2,
  outlineWidth: 0,
};
