import { Input } from "@/features/sidebar/components/Input";
import { Toggle } from "@/features/sidebar/components/Toggle";
import { useSizeProperties } from "@/features/sidebar/hooks/useSizeProperties";
import {
  setTextMaxContentWidth,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { useState } from "react";
import * as i from "@/features/sidebar/Sidebar.icons";
import { Layer, LayerType } from "@folds/shared/types";

function TextWidth({
  handleWidthChange,
  width,
}: {
  width: string;
  handleWidthChange: (value: string) => void;
}) {
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useAppDispatch();

  const handleSetTextContentMaxWidth = () => {
    dispatch(setTextMaxContentWidth());
  };

  return (
    <div className="relative flex-1">
      <Input
        minValue={1}
        unit="px"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        icon={<p className="block text-xs text-gray-11">W</p>}
        value={width}
        fill
        onValueChange={handleWidthChange}
      />
      {isFocused && (
        <div
          onMouseDown={handleSetTextContentMaxWidth}
          className="absolute -bottom-9 left-0 right-0 cursor-pointer rounded-sm border border-gray-6 bg-gray-2 p-2 text-xs"
        >
          Fit content
        </div>
      )}
    </div>
  );
}

export function SidebarSizingSettings({ type }: { type: Layer["type"] }) {
  const dispatch = useAppDispatch();

  const { height, width } = useSizeProperties();

  const [linkWidthAndHeight, setLinkWidthAndHeight] = useState(false);

  const handleWidthChange = (value: string) => {
    const originalWidth = Number(width);
    const originalHeight = Number(height);

    const newWidthValue = Number(value);

    if (Number.isNaN(newWidthValue)) return;

    const isNumberValid =
      !Number.isNaN(originalWidth) && !Number.isNaN(originalHeight);

    if (linkWidthAndHeight === true || type === LayerType.Icon) {
      if (!isNumberValid) return;

      const changeFactor = newWidthValue / originalWidth;

      const newHeightValue = originalHeight * changeFactor;

      dispatch(
        setSelectedLayerProperties({
          width: newWidthValue,
          height: newHeightValue,
        })
      );

      return;
    }

    dispatch(setSelectedLayerProperties({ width: newWidthValue }));
  };

  const handleChangeHeight = (value: string) => {
    const newHeightValue = Number(value);

    const originalWidth = Number(width);
    const originalHeight = Number(height);

    if (Number.isNaN(newHeightValue)) return;

    const isNumberValid =
      !Number.isNaN(originalWidth) && !Number.isNaN(originalHeight);

    if (linkWidthAndHeight === true || type === LayerType.Icon) {
      if (!isNumberValid) return;

      const changeFactor = newHeightValue / originalHeight;

      const newWidthValue = originalWidth * changeFactor;

      dispatch(
        setSelectedLayerProperties({
          width: newWidthValue,
          height: newHeightValue,
        })
      );

      return;
    }

    dispatch(setSelectedLayerProperties({ height: newHeightValue }));
  };

  const handleToggleLinkWidthAndHeight = () => {
    setLinkWidthAndHeight((previous) => !previous);
  };

  return (
    <div className="flex gap-2">
      {type === LayerType.Text ? (
        <TextWidth handleWidthChange={handleWidthChange} width={width} />
      ) : (
        <Input
          minValue={1}
          unit="px"
          icon={<p className="block text-xs text-gray-11">W</p>}
          value={width}
          fill
          onValueChange={handleWidthChange}
        />
      )}
      <Input
        minValue={1}
        unit="px"
        fill
        icon={<p className="block text-xs text-gray-11">H</p>}
        value={height}
        onValueChange={handleChangeHeight}
      />

      {type !== LayerType.Block && (
        <Toggle
          onClick={handleToggleLinkWidthAndHeight}
          pressed={linkWidthAndHeight}
          icon={i.Link}
        />
      )}
    </div>
  );
}
