import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { toast } from "@core/toast";
import { ApiCreatePageBody } from "@folds/shared";

const expectedResponseSchema = TypeCompiler.Compile(
  Type.Object({
    pageId: Type.String(),
  })
);

type OnCreate = (id: string) => Promise<void> | void;

export const useCreatePage = (onCreate: OnCreate) => {
  const axios = useAxios();

  const handleCreateNewPage = async ({
    name,
    shopifyId,
    type,
  }: ApiCreatePageBody) => {
    try {
      toast.loading("Creating page...", { id: "create-page" });

      const response = await axios.post("/pages", {
        name,
        shopifyId,
        type,
      });

      toast.success("Page created", { id: "create-page" });

      const parsedResponse = expectedResponseSchema.Decode(response.data);

      await onCreate(parsedResponse.pageId);
    } catch (error) {
      toast.error("There was an issue creating the page. Please try again.", {
        id: "create-page",
      });
    }
  };

  return handleCreateNewPage;
};
