import { useDrop } from "@/features/editor/dragging/drop";
import { useDragSelect } from "@/features/editor/hooks/useDragSelect";
import { clearSelectedLayers } from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { isMiddleClick, isRightClick } from "@core/utils/click";
import { ROOT_LAYER_ID } from "@folds/shared/constants";

export function CanvasWrapper({ children }: React.PropsWithChildren) {
  const dispatch = useAppDispatch();

  const canvasMode = useAppSelector((state) => state.editor.canvasMode);

  const handleDrop = useDrop(ROOT_LAYER_ID);

  const { handleMouseDown, handleMouseMove, handleMouseUp } = useDragSelect();

  const mouseDownCanvasHandler = (event: React.MouseEvent) => {
    if (isMiddleClick(event) || isRightClick(event)) return;

    handleMouseDown(event);

    // Clear selected layer when clicking on canvas
    dispatch(clearSelectedLayers());
  };

  return (
    <div
      className="h-screen w-screen origin-top-left overflow-hidden bg-[#f2f2f2]"
      onScroll={(event) => event.preventDefault()}
      onDragEnter={(event) => event.preventDefault()}
      onDragOver={(event) => event.preventDefault()}
      onDrop={handleDrop}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      data-canvas
      data-testid="canvas"
      data-instance-id={undefined}
      onMouseDown={mouseDownCanvasHandler}
    >
      {canvasMode === "Pan" && (
        <div className="fixed left-0 top-0 z-50 h-screen w-screen cursor-grab" />
      )}
      {children}
    </div>
  );
}
