import { useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";
import { useCallback, useEffect } from "react";
import { unstable_usePrompt as usePrompt } from "react-router-dom";

const selectHasUnsavedChanges = createSelector(
  [
    (state: RootState) => state.editor.layers,
    (state: RootState) => state.editor.savedLayers,
  ],
  (currentLayers, savedLayers) => !isEqual(currentLayers, savedLayers)
);

export const useUnsavedChangesPrompt = () => {
  const hasUnsavedChanges = useAppSelector(selectHasUnsavedChanges);

  usePrompt({
    when: hasUnsavedChanges,
    message: "You have unsaved changes. Changes you made may not be saved.",
  });

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges === false) return;

      event.preventDefault();
      event.returnValue =
        "You have unsaved changes. Changes you made may not be saved.";
    },
    [hasUnsavedChanges]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
};
