import { createContext, useContext, useMemo } from "react";

const FrameContext = createContext<{
  window: Window | undefined;
  document: Document | undefined;
}>({ document: undefined, window: undefined });

export const useFrame = () => {
  const frame = useContext(FrameContext);

  return frame;
};

export function FrameProvider({
  children,
  document,
  window,
}: {
  children: React.ReactNode;
  window: Window;
  document: Document;
}) {
  const value = useMemo(() => ({ window, document }), [window, document]);

  return (
    <FrameContext.Provider value={value}>{children}</FrameContext.Provider>
  );
}
