import { useFrame } from "@/features/editor/context/FrameContext";
import { getLayersToCopy } from "@/features/editor/hooks/useCopyPaste/getLayersToCopy";
import { getTargetLayerId } from "@/features/editor/hooks/useCopyPaste/getTargetLayerId";
import { checkIfEventTargetIsTextElement } from "@/utils";
import { pasteLayers } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { store } from "@core/store";
import { layerSchema } from "@folds/shared/types";
import { type Static, Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { useEffect } from "react";
import { toast } from "@core/toast";
import { useModalDocument } from "@core/modalStore";

const copyPasteSchema = Type.Object({
  targetLayerId: Type.Union([Type.String(), Type.Null()]),
  layers: Type.Array(layerSchema),
});

const compiledCopyPasteSchema = TypeCompiler.Compile(copyPasteSchema);

export const useCopyPaste = () => {
  const dispatch = useAppDispatch();
  const modalDocument = useModalDocument();
  const { window: iFrameWindow } = useFrame();

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const isEditingTextElement = checkIfEventTargetIsTextElement(
        event.target
      );

      if (isEditingTextElement) return;

      const clipboardData = event.clipboardData?.getData("application/folds");

      if (clipboardData === undefined) return;

      try {
        const jsonClipboardData = JSON.parse(clipboardData);
        event.preventDefault();

        const parsedData = compiledCopyPasteSchema.Decode(jsonClipboardData);

        dispatch(
          pasteLayers({
            layers: parsedData.layers,
            targetLayerId: parsedData.targetLayerId,
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn("Failed to paste: ", error);
      }
    };

    const handleCopy = (event: ClipboardEvent) => {
      const isInputElement = (event.target as HTMLElement).tagName === "INPUT";

      if (isInputElement) return;

      const { layers, selectedLayerIds } = store.getState().editor;

      const layersToCopy = getLayersToCopy(selectedLayerIds, layers);

      if (layersToCopy.length === 0) return;

      event.preventDefault();

      const targetLayerId = getTargetLayerId(layersToCopy);

      if (targetLayerId === null) {
        toast.error(
          "Copying layers in different parents is currently not supported"
        );
        return;
      }

      if (targetLayerId === undefined) {
        toast.error("There was an issue copying the layers");
        return;
      }

      const data: Static<typeof copyPasteSchema> = {
        layers: layersToCopy,
        targetLayerId,
      };

      event.clipboardData?.setData("application/folds", JSON.stringify(data));
    };

    iFrameWindow?.addEventListener("copy", handleCopy);
    iFrameWindow?.addEventListener("paste", handlePaste);
    modalDocument?.addEventListener("copy", handleCopy);
    modalDocument?.addEventListener("paste", handlePaste);

    return () => {
      iFrameWindow?.removeEventListener("copy", handleCopy);
      iFrameWindow?.removeEventListener("paste", handlePaste);
      modalDocument?.removeEventListener("copy", handleCopy);
      modalDocument?.removeEventListener("paste", handlePaste);
    };
  }, [dispatch, iFrameWindow, modalDocument]);
};
