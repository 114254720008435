import styled from "styled-components";
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
} from "styled-system";

interface Props
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    PositionProps {
  children?: React.ReactNode;
}

export const Box = styled.div<Props>`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
`;
