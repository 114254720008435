import {
  MultipleOptions,
  Options,
} from "@/features/sidebar/components/MultipleOptions";
import {
  selectFlexDirection,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { compiledFlexDirectionSchema } from "@folds/shared/types";
import { toast } from "@core/toast";

const options: Options = [
  {
    value: "row",
    icon: <p className="text-xs text-gray-12">Horizontal</p>,
  },
  {
    value: "column",
    icon: <p className="text-xs text-gray-12">Vertical</p>,
  },
];

export function FlexDirectionSettings() {
  const dispatch = useAppDispatch();

  const flexDirection = useAppSelector(selectFlexDirection);

  if (flexDirection === null) return null;

  const handleChange = (value: string) => {
    try {
      const direction = compiledFlexDirectionSchema.Decode(value);

      dispatch(setSelectedLayerProperties({ flexDirection: direction }));
    } catch (error) {
      toast.error("There was an issue change the direction");
    }
  };

  return (
    <div className="flex flex-col gap-3 border-t border-gray-6 p-3">
      <p className="text-sm font-medium text-gray-12">Direction</p>
      <MultipleOptions
        options={options}
        value={String(flexDirection)}
        onValueChange={handleChange}
      />
    </div>
  );
}
