import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";
import { InfoIcon } from "@core/Toast.icons";
import { useAppSelector } from "@core/hooks";

export function PlaygroundChangesNotSavedWarning() {
  const isInsidePlayground = useIsInsidePlayground();

  const droppedLatyrsInformationToReplace = useAppSelector(
    (state) => state.editor.droppedLayersInformationToReplace
  );

  if (!isInsidePlayground) {
    return null;
  }

  const isReplacingDroppedLayersInformation =
    droppedLatyrsInformationToReplace &&
    droppedLatyrsInformationToReplace.length > 0;

  if (isReplacingDroppedLayersInformation === true) {
    return null;
  }

  return (
    <div className="fixed bottom-3 right-3 z-50 flex items-center gap-3 rounded-md border border-blue-6 bg-blue-3 px-2 py-3">
      <InfoIcon />
      <p className="w-64">
        This is a demo. Any changes you make will not be saved
      </p>
    </div>
  );
}
