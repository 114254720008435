import { nanoid } from "@core/lib/nanoid";
import { LayerId, Layers } from "@folds/shared/types";
import { Draft, produce } from "immer";

const replaceParentIds = (
  draft: Draft<Layers>,
  children: LayerId[],
  newId: LayerId
) => {
  children.forEach((childId) => {
    const childLayer = draft[childId];

    if (!childLayer) return;

    childLayer.parentId = newId;
  });
};

/**
 * Replaces any references to the original id with the new id
 */
const replaceChildrenIds = (
  draft: Draft<Layers>,
  originalId: LayerId,
  newId: LayerId
) => {
  Object.values(draft).forEach((layerElement) => {
    if ("checkedChildren" in layerElement) {
      const checkedChild = layerElement.checkedChildren[originalId];

      if (checkedChild !== undefined) {
        layerElement.checkedChildren[newId] = checkedChild;
        delete layerElement.checkedChildren[originalId];
      }
    }

    if ("hoverChildren" in layerElement) {
      const hoverChild = layerElement.hoverChildren[originalId];

      if (hoverChild !== undefined) {
        layerElement.hoverChildren[newId] = hoverChild;
        delete layerElement.hoverChildren[originalId];
      }
    }

    if ("optionUnavailableChildren" in layerElement) {
      const optionUnavailableChild =
        layerElement.optionUnavailableChildren[originalId];

      if (optionUnavailableChild !== undefined) {
        layerElement.optionUnavailableChildren[newId] = optionUnavailableChild;
        delete layerElement.optionUnavailableChildren[originalId];
      }
    }

    if ("children" in layerElement) {
      layerElement.children = layerElement.children.map((child) => {
        if (child === originalId) {
          return newId;
        }

        return child;
      });
    }
  });
};

/**
 * Replaces the current layer ids with random layer ids
 */
export const randomizeLayerIds = ({
  layers,
  rootLayerId,
  targetLayerId,
}: {
  layers: Layers;
  rootLayerId: LayerId;
  targetLayerId: LayerId;
}): { layers: Layers; rootLayerId: LayerId | null } => {
  let newRootLayerId: LayerId | undefined;

  const newLayers = produce(layers, (draft) => {
    Object.values(draft).forEach((layer) => {
      const originalId = layer.id;
      const newId = nanoid();

      if (originalId === rootLayerId) {
        newRootLayerId = newId;
        layer.parentId = targetLayerId;
      }

      layer.id = newId;

      // Replaces the children parentIds
      if ("children" in layer) {
        replaceParentIds(draft, layer.children, newId);
      }

      replaceChildrenIds(draft, originalId, newId);

      // Replaces the original key with the new id
      delete draft[originalId];
      draft[newId] = layer;
    });
  });

  return { layers: newLayers, rootLayerId: newRootLayerId ?? null };
};
