import { useMatchingPreviewProduct } from "@/utils/getMatchingPreviewProduct";
import { ShopifyProduct } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR, { SWRResponse } from "swr";

export const useProduct = (
  id: number | null
): SWRResponse<ShopifyProduct, unknown, unknown> => {
  const axios = useAxios();

  const getProduct = async (key: string) => {
    const result = await axios.get(key);

    return result.data as ShopifyProduct;
  };

  const matchingPreviewProduct = useMatchingPreviewProduct(id);

  const result = useSWR(
    typeof id === "number" && matchingPreviewProduct === null
      ? `/shopify/products/${id}`
      : null,
    getProduct
  );

  if (matchingPreviewProduct !== null) {
    return {
      data: matchingPreviewProduct,
      error: undefined,
      isLoading: false,
      isValidating: false,
      mutate: () => new Promise(() => {}),
    };
  }

  return result;
};
