import { store } from "@core/store";
import { LayerId } from "@folds/shared";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $selectAll } from "lexical";
import { useEffect } from "react";

export function DoubleClickToEditPlugin({ layerId }: { layerId: LayerId }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const contentEditable = editor.getRootElement();

    if (contentEditable === null) return;

    const handleDoubleClick = () => {
      const isEditable = editor.isEditable();
      if (isEditable === true) return;

      const { selectedLayerIds } = store.getState().editor;

      const isSelected = selectedLayerIds.includes(layerId);
      if (isSelected === false) return;

      editor.setEditable(true);

      editor.update(() => {
        $selectAll();
      });
    };

    contentEditable.addEventListener("dblclick", handleDoubleClick);

    return () => {
      contentEditable.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [editor, layerId]);

  return null;
}
