import {
  AccordionChevronLayer,
  AccordionContentLayer,
  AccordionRootLayer,
  AccordionTriggerLayer,
} from '../types';

export const generateAccordionContentWrapperStyles =
  (): React.CSSProperties => ({
    all: 'initial',
    display: 'block',
    transition: 'height 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    overflow: 'clip',
    position: 'relative',
  });

export const generateAccordionContentStyles = (
  layer: AccordionContentLayer
): React.CSSProperties => {
  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    backgroundColor,
  } = layer;

  return {
    all: 'initial',
    backgroundColor,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    position: 'relative',
    display: 'block',
  };
};

export const generateAccordionTriggerStyles = (
  layer: AccordionTriggerLayer
): React.CSSProperties => {
  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    backgroundColor,
  } = layer;

  return {
    all: 'initial',
    backgroundColor,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
  };
};

export const generateAccordionRootStyles = (
  layer: AccordionRootLayer
): React.CSSProperties => {
  const { left, top, width, visible, zIndex } = layer;

  return {
    all: 'initial',
    left,
    width,
    top,
    position: 'absolute',
    zIndex,
    display: visible ? 'block' : 'none',
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};

export const generateAccordionChevronStyles = (
  layer: AccordionChevronLayer
): React.CSSProperties => ({
  position: 'absolute',
  right: 15,
  fill: layer.fill,
});

export const generateAccordionItemStyles = (): React.CSSProperties => ({
  all: 'initial',
  display: 'flex',
  flexDirection: 'column',
});
