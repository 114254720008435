export function ChevronLeft() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7803 3.96967C12.0732 4.26256 12.0732 4.73744 11.7803 5.03033L7.81066 9L11.7803 12.9697C12.0732 13.2626 12.0732 13.7374 11.7803 14.0303C11.4874 14.3232 11.0126 14.3232 10.7197 14.0303L6.21967 9.53033C5.92678 9.23744 5.92678 8.76256 6.21967 8.46967L10.7197 3.96967C11.0126 3.67678 11.4874 3.67678 11.7803 3.96967Z"
        fill="black"
      />
    </svg>
  );
}

export function PlusIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 0.333374C7.45991 0.333374 7.83301 0.70647 7.83301 1.16671V6.16671H12.833C13.2932 6.16671 13.6663 6.5398 13.6663 7.00004C13.6663 7.46028 13.2932 7.83337 12.833 7.83337H7.83301V12.8334C7.83301 13.2936 7.45991 13.6667 6.99967 13.6667C6.53944 13.6667 6.16634 13.2936 6.16634 12.8334V7.83337H1.16634C0.706104 7.83337 0.333008 7.46028 0.333008 7.00004C0.333008 6.5398 0.706104 6.16671 1.16634 6.16671H6.16634V1.16671C6.16634 0.70647 6.53944 0.333374 6.99967 0.333374Z"
        fill="black"
      />
    </svg>
  );
}
