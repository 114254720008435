export function UpArrow() {
  return (
    <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05806 0.683058C5.30214 0.438981 5.69786 0.438981 5.94194 0.683058L10.3169 5.05806C10.561 5.30214 10.561 5.69786 10.3169 5.94194C10.0729 6.18602 9.67714 6.18602 9.43306 5.94194L6.125 2.63388V9.875C6.125 10.2202 5.84518 10.5 5.5 10.5C5.15482 10.5 4.875 10.2202 4.875 9.875V2.63388L1.56694 5.94194C1.32286 6.18602 0.927136 6.18602 0.683058 5.94194C0.438981 5.69786 0.438981 5.30214 0.683058 5.05806L5.05806 0.683058Z"
        fill="#ECEDEE"
      />
    </svg>
  );
}

export function DownArrow() {
  return (
    <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.94194 10.3169C5.69786 10.561 5.30214 10.561 5.05806 10.3169L0.683058 5.94194C0.43898 5.69786 0.43898 5.30214 0.683058 5.05806C0.927136 4.81398 1.32286 4.81398 1.56694 5.05806L4.875 8.36612L4.875 1.125C4.875 0.779822 5.15482 0.5 5.5 0.5C5.84518 0.5 6.125 0.779822 6.125 1.125L6.125 8.36612L9.43306 5.05806C9.67714 4.81398 10.0729 4.81398 10.3169 5.05806C10.561 5.30214 10.561 5.69786 10.3169 5.94194L5.94194 10.3169Z"
        fill="#ECEDEE"
      />
    </svg>
  );
}
