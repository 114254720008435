import { INDICATOR_SIZE } from "@/features/editor/components/AltHoverDistanceIndicator/constants";
import { roundDistance } from "@/features/editor/components/AltHoverDistanceIndicator/utils/roundDistance";
import { useAppSelector } from "@core/hooks";
import { getBoundingBoxRect } from "@core/utils";
import { Box, LayerId } from "@folds/shared";
import React, { useEffect, useState } from "react";

const useParentTopDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value = selectionBoundingBox.top - targetLayerBoundingBox.top;

    setDistance(roundDistance(Math.abs(value)));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

export function ParentTopIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useParentTopDistance(targetLayerId);

  const indicatorStyle: React.CSSProperties = {
    top: targetLayerBoundingBox.top,
    left: selectionBoundingBox.left + selectionBoundingBox.width / 2,
    height: selectionBoundingBox.top - targetLayerBoundingBox.top,
    width: INDICATOR_SIZE,
  };

  return (
    <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
      <p
        aria-label="parent top distance"
        className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

const useParentBottomDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const selectionBottom =
      selectionBoundingBox.top + selectionBoundingBox.height;

    const parentBotton =
      targetLayerBoundingBox.top + targetLayerBoundingBox.height;

    const value = parentBotton - selectionBottom;

    setDistance(roundDistance(Math.abs(value)));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

export function ParentBottomIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useParentBottomDistance(targetLayerId);

  const indicatorStyle: React.CSSProperties = {
    top: selectionBoundingBox.top + selectionBoundingBox.height,
    left: selectionBoundingBox.left + selectionBoundingBox.width / 2,
    height:
      targetLayerBoundingBox.top +
      targetLayerBoundingBox.height -
      (selectionBoundingBox.top + selectionBoundingBox.height),
    width: INDICATOR_SIZE,
  };

  return (
    <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
      <p
        aria-label="parent bottom distance"
        className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

const useParentLeftDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value = selectionBoundingBox.left - targetLayerBoundingBox.left;

    setDistance(roundDistance(Math.abs(value)));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

export function ParentLeftIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useParentLeftDistance(targetLayerId);

  const indicatorStyle: React.CSSProperties = {
    top: selectionBoundingBox.top + selectionBoundingBox.height / 2,
    left: targetLayerBoundingBox.left,
    height: INDICATOR_SIZE,
    width: selectionBoundingBox.left - targetLayerBoundingBox.left,
  };

  return (
    <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
      <p
        aria-label="parent left distance"
        className="absolute left-1/2 top-1 z-50 -translate-x-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

const useParentRightDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value =
      targetLayerBoundingBox.left +
      targetLayerBoundingBox.width -
      (selectionBoundingBox.left + selectionBoundingBox.width);

    setDistance(roundDistance(Math.abs(value)));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

export function ParentRightIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useParentRightDistance(targetLayerId);

  const indicatorStyle: React.CSSProperties = {
    top: selectionBoundingBox.top + selectionBoundingBox.height / 2,
    left: selectionBoundingBox.left + selectionBoundingBox.width,
    height: INDICATOR_SIZE,
    width:
      targetLayerBoundingBox.left +
      targetLayerBoundingBox.width -
      (selectionBoundingBox.left + selectionBoundingBox.width),
  };

  return (
    <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
      <p
        aria-label="parent right distance"
        className="absolute left-1/2 top-1 z-50 -translate-x-1/2 rounded bg-[red] px-1 text-sm text-white"
      >
        {distance}
      </p>
    </div>
  );
}

export function ParentIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  return (
    <>
      <ParentTopIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
      <ParentLeftIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
      <ParentRightIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
      <ParentBottomIndicator
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
        targetLayerId={targetLayerId}
      />
    </>
  );
}
