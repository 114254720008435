import { captureMessage } from "@sentry/react";
import { InternalAxiosRequestConfig, getAdapter } from "axios";
import { RUNNING_SHOE } from "@lib/axios/mocking/constants";
import { generateInitialLayers } from "@lib/axios/mocking/generateInitialLayers";

const parseMethod = (
  method: string | undefined
): "GET" | "POST" | "PUT" | "DELETE" | null => {
  if (typeof method !== "string") {
    return null;
  }

  const uppercaseMethod = method.toUpperCase();

  if (
    uppercaseMethod === "GET" ||
    uppercaseMethod === "POST" ||
    uppercaseMethod === "PUT" ||
    uppercaseMethod === "DELETE"
  ) {
    return uppercaseMethod;
  }

  return "GET";
};

function getPathFromUrl(url: string | undefined) {
  if (typeof url !== "string") return null;

  const path = url.split("?")[0];

  return path ?? url;
}

const checkIfIsPassthroughRequest = ({
  method,
  path,
}: {
  method: "GET" | "POST" | "PUT" | "DELETE";
  path: string;
}) => {
  const isBlocksRequest = path.includes("/blocks") && method === "GET";
  const isTemplatesRequest = path.includes("/templates") && method === "GET";
  const isIconsRequest = path.includes("/icons") && method === "GET";
  const isElementsRequest = path.includes("/elements") && method === "GET";
  const isFontsRequest = path === "/fonts" && method === "GET";

  return (
    isBlocksRequest ||
    isTemplatesRequest ||
    isIconsRequest ||
    isElementsRequest ||
    isFontsRequest
  );
};

const getMockData = (request: {
  path: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
}) => {
  const { method, path } = request;

  if (method === "GET") {
    switch (path) {
      case "/pages/playground": {
        return generateInitialLayers();
      }
      case "/pages/playground/information": {
        return {
          type: "home",
          name: "Playground",
          publishedAt: null,
          shopifyId: null,
        };
      }
      case "/shopify/sections": {
        return {
          desktop: {
            header: null,
            footer: null,
          },
          tablet: {
            header: null,
            footer: null,
          },
          mobile: {
            header: null,
            footer: null,
          },
        };
      }
      case "/shop": {
        return {
          shopifyDomain: "example-shop",
          accessToken: "",
          accessTokenScopes:
            "read_products,write_products,unauthenticated_read_content,unauthenticated_read_product_listings,read_themes,write_themes,read_content,write_content",
          email: "support@folds.app",
          isInstalled: true,
          images: [],
          shopOwner: "John Doe",
          publishedShopifyThemeId: "123456789",
          shopifyStorefrontPassword: null,
          createdAt: "2024-04-04T01:00:44.929Z",
          headerDesktopImage: null,
          headerTabletImage: null,
          headerMobileImage: null,
          footerDesktopImage: null,
          footerTabletImage: null,
          footerMobileImage: null,
          intercomUserHash: "123456789",
        };
      }
      case "/pages": {
        return [];
      }
      case "/images": {
        return [];
      }
      case "/shopify/urls/products": {
        return [
          {
            id: "gid://shopify/Product/7596729401479",
            title: "Running shoe",
            handle: "running-shoe",
            url: "/products/running-shoe",
          },
        ];
      }
      case "/shopify/urls/collections": {
        return [
          {
            id: "all-products",
            title: "All products",
            url: "/collections/all",
          },
          {
            id: "gid://shopify/Collection/281325764743",
            title: "Home page",
            url: "/collections/frontpage",
          },
          {
            id: "gid://shopify/Collection/281325797511",
            title: "Summer collection",
            url: "/collections/automated-collection",
          },
          {
            id: "gid://shopify/Collection/281325797511",
            title: "Spring collection",
            url: "/collections/spring-collection",
          },
          {
            id: "gid://shopify/Collection/281325797511",
            title: "Fall collection",
            url: "/collections/fall-collection",
          },
          {
            id: "gid://shopify/Collection/281325797511",
            title: "Winter collection",
            url: "/collections/winter-collection",
          },
        ];
      }
      case "/shopify/urls/pages": {
        return [
          {
            id: "gid://shopify/Page/86409576583",
            title: "Contact us",
            url: "/pages/contact",
          },
        ];
      }
      case "/shopify/urls/blogs": {
        return [
          {
            id: "gid://shopify/Blog/80265150599",
            title: "News",
            url: "/blogs/news",
          },
          {
            id: "gid://shopify/Blog/80265150599",
            title: "Product updates",
            url: "/blogs/product-updates",
          },
        ];
      }
      case "/shopify/urls/articles": {
        return [
          {
            id: "gid://shopify/Article/557206110343",
            title: "My first blog post",
            url: "/blogs/news/my-first-blog-post",
          },
          {
            id: "gid://shopify/Article/557206110343",
            title: "My second blog post",
            url: "/blogs/news/my-first-blog-post",
          },
        ];
      }
      case "/shopify/urls/policies": {
        return [
          {
            id: "gid://shopify/ShopPolicy/24866816135",
            title: "Shipping Policy",
            url: "https://checkout.shopify.com/57881821319/policies/24866816135.html?locale=en",
          },
          {
            id: "gid://shopify/ShopPolicy/24866783367",
            title: "Terms of Service",
            url: "https://checkout.shopify.com/57881821319/policies/24866783367.html?locale=en",
          },
        ];
      }
      case "/shopify/products": {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return [RUNNING_SHOE];
      }
      case "/shopify/products/7595754717319": {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return RUNNING_SHOE;
      }
      case "/shopify/products/7595754717319/price": {
        return { price: "59.95", compareAtPrice: "69.95" };
      }
      case "/shopify/products/7595754717319/images": {
        return [
          "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_navy_blue_running_shoes_for_an_e-co_1b5a5b44-2abe-4384-84bc-f737ad995b02.webp?v=1711539527",
          "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_black_runner_shoes_for_an_e-commer__4f998c15-291d-4da8-8ea8-bebdd65d3085.webp?v=1711539546",
          "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/0_2_26.webp?v=1711539551",
          "https://cdn.shopify.com/s/files/1/0578/8182/1319/files/saphiradev_Product_image_of_brown_running_shoes_for_an_e-commer_b50b9173-b2d5-41c6-85d1-4aad25f9ab0e.webp?v=1711539709",
        ];
      }
    }
  }

  if (method === "POST") {
    return "Ok";
  }

  if (method === "PUT") {
    return "Ok";
  }

  if (method === "DELETE") {
    return "Ok";
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.error(
      `Missing mock data for the path: ${path} and method: ${method}`
    );

    return [];
  }

  captureMessage(
    `Missing mock data for the path: ${path} and method: ${method}`
  );

  return [];
};

const xhrAdapter = getAdapter("xhr");

/**
 * Mocks the request if the app is running inside the playground
 */
export const mockRequest = (
  config: InternalAxiosRequestConfig,
  isInsidePlayground: boolean
) => {
  if (!isInsidePlayground) return xhrAdapter(config);

  const parsedMethod = parseMethod(config.method);
  const parsedPath = getPathFromUrl(config.url);

  if (typeof parsedMethod !== "string" || typeof parsedPath !== "string") {
    return Promise.resolve({
      data: [],
      status: 200,
      statusText: "Ok",
      headers: {},
      config,
      request: {},
    });
  }

  const isPassthroughRequest = checkIfIsPassthroughRequest({
    method: parsedMethod,
    path: parsedPath,
  });

  if (isPassthroughRequest) {
    return xhrAdapter(config);
  }

  const data = getMockData({
    path: parsedPath,
    method: parsedMethod,
  });

  return Promise.resolve({
    data,
    status: 200,
    statusText: "Ok",
    headers: {},
    config,
    request: {},
  });
};
