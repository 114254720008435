import { useGetProductsInformationFromLayers } from "@/features/editor/components/DevelopmentSaveComponent/useGetProductsInformationFromLayers";
import { store } from "@core/store";
import { getBreakpointId, getChildIdsNested } from "@core/utils";
import { Layers, ROOT_LAYER_ID, ShopifyProduct } from "@folds/shared";
import { useAxios } from "@lib/axios";
import { DialogContent, DialogPortal } from "@lib/radix";
import * as Dialog from "@radix-ui/react-dialog";
import { useEffect, useState } from "react";
import { toast } from "@core/toast";
import { twMerge } from "tailwind-merge";

const checkIfInputsAreValid = ({
  description,
  layersToSave,
  products,
}: {
  description: string;
  products: null | ShopifyProduct[];
  layersToSave: null | Layers;
}) => {
  if (description.length === 0) {
    return false;
  }

  if (products === null) {
    return false;
  }

  if (layersToSave === null) {
    return false;
  }

  return true;
};

const getLayersToSave = (layers: Layers): Layers | null => {
  const breakpointId = getBreakpointId(layers);
  if (breakpointId === null) return null;

  const breakpoint = layers[breakpointId];
  if (breakpoint === undefined) return null;

  const breakpointChildrenIds = getChildIdsNested(layers, breakpointId);

  const updatedLayers = [...breakpointChildrenIds, breakpointId].reduce(
    (acc, layerId) => {
      const layer = layers[layerId];
      if (layer === undefined) return acc;

      if (layer.id === breakpointId) {
        const breakpointWithUpdatedId = { ...layer, id: ROOT_LAYER_ID };

        return { ...acc, [ROOT_LAYER_ID]: breakpointWithUpdatedId };
      }

      if (layer.parentId === breakpointId) {
        const layerWithUpdatedParentId = { ...layer, parentId: ROOT_LAYER_ID };

        return { ...acc, [layer.id]: layerWithUpdatedParentId };
      }

      return { ...acc, [layer.id]: layer };
    },
    {}
  );

  return updatedLayers;
};

/**
 * Saves the selected layer as an element to the database
 *
 * Only available in development mode
 */
export function DevelopmentSaveTemplate() {
  const [description, setDescription] = useState("");
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isDialogExpanded, setIsDialogExpanded] = useState(false);
  const [products, setProducts] = useState<null | ShopifyProduct[]>(null);
  const [layersToSave, setLayersToSave] = useState<null | Layers>(null);

  const getProductInformation = useGetProductsInformationFromLayers();

  const axios = useAxios();

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") return;

    window.folds = {
      ...window.folds,
      toggleSaveTemplate: () => {
        setIsDisplayed((prev) => !prev);
      },
    };
  }, []);

  const handleOpenChange = async (open: boolean) => {
    if (open === false) {
      setIsDialogExpanded(false);
      setProducts(null);
      setLayersToSave(null);
      return;
    }

    const { layers } = store.getState().editor;

    const parsedLayers = getLayersToSave(layers);
    if (parsedLayers === null) return;

    const productsInformaiton = await getProductInformation(parsedLayers);

    setIsDialogExpanded(true);
    setProducts(productsInformaiton);
    setLayersToSave(parsedLayers);
  };

  const handleSave = async () => {
    if (description.length === 0) {
      toast.error("Please write a description");
      return;
    }

    if (products === null) {
      toast.error("No products found");
      return;
    }

    if (layersToSave === null) {
      toast.error("No layers found to save");
      return;
    }

    try {
      toast.loading("Saving template...", { id: "save-template" });

      await axios.post("/templates", {
        description,
        products,
        layers: layersToSave,
      });

      toast.success("Template saved", { id: "save-template" });
    } catch (error) {
      toast.error("Error saving template", { id: "save-template" });
    }
  };

  if (process.env.NODE_ENV !== "development" || isDisplayed === false) return;

  const isInputValud = checkIfInputsAreValid({
    description,
    layersToSave,
    products,
  });

  return (
    <Dialog.Root onOpenChange={handleOpenChange} open={isDialogExpanded}>
      <Dialog.Trigger
        type="button"
        // Prevent unselecting the selected elements
        onMouseDown={(event) => event.stopPropagation()}
        className="red-100 fixed bottom-0 right-[104px] z-50 rounded bg-gray-12 p-3 text-white"
      >
        Save template
      </Dialog.Trigger>
      <DialogPortal>
        <DialogContent className="fixed left-0 top-0 flex h-screen w-96 translate-x-0 translate-y-0 flex-col gap-5 overflow-y-scroll rounded-lg bg-gray-1 p-6 shadow">
          <input
            className="boder rounded border-gray-6 bg-gray-3 px-3 py-2"
            placeholder="Write description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <button
            type="button"
            onClick={handleSave}
            className={twMerge(
              "bg-gray-12 py-2 text-white",
              !isInputValud && "cursor-not-allowed bg-gray-9"
            )}
          >
            Save
          </button>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}
