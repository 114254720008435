import type {
  EditorState,
  InitialLayersInformation,
} from "@core/features/editor/editorSlice";
import { setLayerPropertiesAction } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/setLayerPropertiesAction";
import { checkIfLayerIsIntrinsicallyPositioned } from "@core/features/editor/editorSlice/actions/utils/checkIfLayerIsIntrinsicallyPositioned";
import { nanoid } from "@core/lib/nanoid";
import {
  getAbsolutePosition,
  getBoundingBoxRect,
  getElement,
} from "@core/utils";
import {
  GuideStopLines,
  LayerId,
  Layers,
  Point,
  Position,
} from "@folds/shared/types";
import { Draft } from "immer";

const getOffsetMousePosition = (
  event: { clientX: number; clientY: number },
  initialMousePosition: Point,
  scale: number
): Point => {
  const offsetX = event.clientX / scale - initialMousePosition.x / scale;
  const offsetY = event.clientY / scale - initialMousePosition.y / scale;

  return { x: offsetX, y: offsetY };
};

const getParentBorderWidth = (parentId: LayerId) => {
  const parent = getElement(parentId);

  const borderLeftWidth =
    typeof parent?.style.borderLeftWidth === "string"
      ? Number(parent.style.borderLeftWidth.split("px")[0])
      : 0;

  const borderTopWidth =
    typeof parent?.style.borderTopWidth === "string"
      ? Number(parent.style.borderTopWidth.split("px")[0])
      : 0;

  return {
    top: borderTopWidth,
    left: borderLeftWidth,
  };
};

export const getRelativePosition = ({
  layerId,
  layers,
  position,
}: {
  layerId: LayerId;
  layers: Layers;
  position: Position;
}) => {
  const getParentId = () => {
    const layer = layers[layerId];

    if (!layer || layer.parentId === null) return null;

    const parent = layers[layer.parentId];

    if (!parent) return null;

    const isIntrinsicallyPositioned =
      checkIfLayerIsIntrinsicallyPositioned(parent);

    if (isIntrinsicallyPositioned) {
      return parent.parentId;
    }

    return layer.parentId;
  };

  const parentId = getParentId();

  if (parentId === null) return null;

  const parentAbsolutePosition = getAbsolutePosition(parentId);

  const parentBorder = getParentBorderWidth(parentId);

  const relativePosition: Position = {
    top: position.top - parentAbsolutePosition.top - parentBorder.top,
    left: position.left - parentAbsolutePosition.left - parentBorder.left,
  };

  return relativePosition;
};

const getClosestGuides = (guides: ReturnType<typeof getGuides>) => {
  const horizontal = guides.horizontal.sort((a, b) => a.offset - b.offset)[0];

  const vertical = guides.vertical.sort((a, b) => a.offset - b.offset)[0];

  return {
    horizontal,
    vertical,
  };
};

const getGuides = (
  tolerance: number,
  selectionSnappingEdges: ReturnType<typeof getSelectionSnappingEdges>,
  guideStopLines: GuideStopLines
) => {
  // Gets closest horizontal guide
  const horizontal = selectionSnappingEdges.horizontalEdges.flatMap((edge) => {
    const mappedGuidesWithOffset = guideStopLines.horizontal.map((guide) => {
      const offset = Math.abs(guide.y - edge.value);

      return { ...guide, offset, id: nanoid() };
    });

    const closeGuides = mappedGuidesWithOffset.filter(
      (guide) => guide.offset <= tolerance
    );

    return closeGuides.map((guide) => ({
      ...guide,
      side: edge.side,
      offset: guide.offset,
    }));
  });

  const vertical = selectionSnappingEdges.verticalEdges.flatMap((edge) => {
    const mappedGuidesWithOffset = guideStopLines.vertical.map((guide) => {
      const offset = Math.abs(guide.x - edge.value);

      return { ...guide, offset, id: nanoid() };
    });

    const closeGuides = mappedGuidesWithOffset.filter(
      (guide) => guide.offset <= tolerance
    );

    return closeGuides.map((guide) => ({
      ...guide,
      side: edge.side,
      offset: guide.offset,
    }));
  });

  return {
    horizontal,
    vertical,
  };
};

const getSnappedVerticalOffset = (
  guide: Exclude<ReturnType<typeof getClosestGuides>["horizontal"], undefined>,
  initialBox: ReturnType<typeof getBoundingBoxRect>
): number => {
  const { side, y } = guide;

  switch (side) {
    case "start": {
      return y - initialBox.top;
    }
    case "center": {
      const boxCenter = initialBox.top + initialBox.height / 2;

      return y - boxCenter;
    }
    case "end": {
      const boxEnd = initialBox.top + initialBox.height;

      return y - boxEnd;
    }
  }
};

const getSnappedHorizontalOffset = (
  guide: Exclude<ReturnType<typeof getClosestGuides>["vertical"], undefined>,
  initialBox: ReturnType<typeof getBoundingBoxRect>
) => {
  const { side, x } = guide;

  switch (side) {
    case "start": {
      return x - initialBox.left;
    }
    case "center": {
      const boxCenter = initialBox.left + initialBox.width / 2;

      return x - boxCenter;
    }
    case "end": {
      const boxEnd = initialBox.left + initialBox.width;

      return x - boxEnd;
    }
  }
};

type Side = "start" | "center" | "end";

export type Edges = {
  side: Side;
  value: number;
}[];

export type SelectionSnappingEdges = {
  horizontalEdges: Edges;
  verticalEdges: Edges;
};

const getSnappedOffsetX = ({
  guide,
  initialBox,
  mouseOffset,
}: {
  initialBox: { left: number; top: number; width: number; height: number };
  mouseOffset: Point;
  guide: ReturnType<typeof getClosestGuides>["vertical"];
}) => {
  if (guide === undefined) return mouseOffset.x;

  return getSnappedHorizontalOffset(guide, initialBox);
};

const getSnappedOffsetY = ({
  guide,
  initialBox,
  mouseOffset,
}: {
  initialBox: { left: number; top: number; width: number; height: number };
  mouseOffset: Point;
  guide: ReturnType<typeof getClosestGuides>["horizontal"];
}) => {
  if (guide === undefined) return mouseOffset.y;

  return getSnappedVerticalOffset(guide, initialBox);
};

export const getSelectionSnappingEdges = (
  box: ReturnType<typeof getBoundingBoxRect>
): SelectionSnappingEdges => {
  const { left, top, width, height } = box;

  const horizontalEdges: Edges = [
    { side: "start", value: top },
    { side: "center", value: top + height / 2 },
    { side: "end", value: top + height },
  ];

  const verticalEdges: Edges = [
    { side: "start", value: left },
    { side: "center", value: left + width / 2 },
    { side: "end", value: left + width },
  ];

  return {
    horizontalEdges,
    verticalEdges,
  };
};

export const getInitialLayersInformation = ({
  layers,
  ids,
}: {
  ids: LayerId[];
  layers: Layers;
}) => {
  const initialLayersInformation: InitialLayersInformation = ids.map((id) => {
    const layer = layers[id];

    if (layer && checkIfLayerIsIntrinsicallyPositioned(layer)) {
      const positions = layer.children.reduce((acc, childId) => {
        const position = getAbsolutePosition(childId);

        return {
          ...acc,
          [childId]: position,
        };
      }, {});

      return {
        type: "intrinsic",
        layerId: id,
        position: positions,
      };
    }

    const position = getAbsolutePosition(id);

    return {
      type: "extrisic",
      layerId: id,
      position,
    };
  });

  return initialLayersInformation;
};

const setPointerEventsToNone = (ids: LayerId[]) => {
  ids.forEach((layerId) => {
    const element = getElement(layerId);
    if (!element) return;

    element.style.pointerEvents = "none";
  });
};

export const calculateAbsolutePositions = (
  state: Draft<EditorState>,
  event: { clientX: number; clientY: number },
  scale: number
) => {
  const { dragging, breakpoint, layers } = state;

  if (dragging === null) return;

  const { guideStopLines, initialBox, initialLayersInformation } = dragging;

  const mouseOffset = getOffsetMousePosition(
    event,
    dragging.initialMousePoint,
    scale
  );

  if (Math.abs(mouseOffset.x) < 5 && Math.abs(mouseOffset.y) < 5) {
    return;
  }

  const updatedBox = {
    left: initialBox.left + mouseOffset.x,
    top: initialBox.top + mouseOffset.y,
    width: initialBox.width,
    height: initialBox.height,
  };

  const selectionSnappingEdges = getSelectionSnappingEdges(updatedBox);

  const guides = getGuides(5, selectionSnappingEdges, guideStopLines);

  const closestGuides = getClosestGuides(guides);

  const snappedOffsetX = getSnappedOffsetX({
    initialBox,
    mouseOffset,
    guide: closestGuides.vertical,
  });

  const snappedOffsetY = getSnappedOffsetY({
    guide: closestGuides.horizontal,
    initialBox,
    mouseOffset,
  });

  const scaledOffsetX = snappedOffsetX;
  const scaledOffsetY = snappedOffsetY;

  initialLayersInformation.forEach(({ layerId, position, type }) => {
    if (type === "intrinsic") {
      Object.entries(position).forEach(([childId, childInitialPosition]) => {
        const newAbsolutePosition: Position = {
          top: childInitialPosition.top + scaledOffsetY,
          left: childInitialPosition.left + scaledOffsetX,
        };

        const relativePosition = getRelativePosition({
          layerId: childId,
          layers,
          position: newAbsolutePosition,
        });

        if (!relativePosition) return;

        setLayerPropertiesAction({
          breakpoint,
          layers: state.layers,
          properties: {
            top: relativePosition.top,
            left: relativePosition.left,
          },
          updatedLayerIds: [childId],
        });
      });

      return;
    }

    const newAbsolutePosition: Position = {
      top: position.top + scaledOffsetY,
      left: position.left + scaledOffsetX,
    };

    const relativePosition = getRelativePosition({
      layerId,
      layers,
      position: newAbsolutePosition,
    });

    if (!relativePosition) return;

    setLayerPropertiesAction({
      breakpoint,
      layers: state.layers,
      properties: {
        top: relativePosition.top,
        left: relativePosition.left,
      },
      updatedLayerIds: [layerId],
    });
  });

  // Allows the layers to move into other layers when the cursor is over the dragged layers
  setPointerEventsToNone(dragging.draggingLayerIds);
};

export const calculateAbsolutePositionGuides = ({
  guideStopLines,
  ids,
}: {
  ids: LayerId[];
  guideStopLines: GuideStopLines;
}) => {
  const box = getBoundingBoxRect(ids);

  const selectionSnappingEdges = getSelectionSnappingEdges(box);
  const guides = getGuides(1, selectionSnappingEdges, guideStopLines);

  return {
    horizontalGuides: guides.horizontal,
    verticalGuides: guides.vertical,
  };
};
