import { createContext, useContext } from "react";

const PageIdContext = createContext<string | null>(null);

/**
 * Returns the page id from the url.
 */
export const usePageId = () => {
  const pageId = useContext(PageIdContext);

  if (pageId === null) {
    throw new Error("usePageId must be used within a PageIdProvider");
  }

  return pageId;
};

export function PageIdProvider({
  children,
  pageId,
}: {
  children: React.ReactNode;
  pageId: string;
}) {
  return (
    <PageIdContext.Provider value={pageId}>{children}</PageIdContext.Provider>
  );
}
