import * as i from "@/features/sidebar/Sidebar.icons";
import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { AutoCompleteValues } from "@/features/sidebar/components/Input";
import {
  selectAutoCompleteColorValues,
  selectFillColor,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function FillColorSettings() {
  const dispatch = useAppDispatch();
  const autoCompleteColors = useAppSelector(selectAutoCompleteColorValues);
  const fillColor = useAppSelector(selectFillColor);

  const handleChangeFill = (value: string) => {
    dispatch(setSelectedLayerProperties({ fill: value }));
  };

  const handleRemoveFill = () => {
    dispatch(setSelectedLayerProperties({ fill: "rgb(0,0,0)" }));
  };

  const handleSelectAutoCompleteValue = (data: AutoCompleteValues[number]) => {
    dispatch(setSelectedLayerProperties({ fill: data.value }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-solid border-gray-6 px-3 pb-3 pt-2">
      <div className="flex h-8 items-center justify-between">
        <h3 className="text-sm font-medium">Fill</h3>
        <div className="flex items-center gap-4">
          <button
            onClick={handleRemoveFill}
            type="button"
            aria-label="Delete fill"
          >
            <i.Minus />
          </button>
        </div>
      </div>
      <ColorPicker
        onSelectAutoCompleteValue={handleSelectAutoCompleteValue}
        autoCompleteValues={autoCompleteColors}
        value={fillColor ?? undefined}
        onValueChange={handleChangeFill}
      />
    </div>
  );
}
