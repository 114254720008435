import styled from "styled-components";

export const SpinnerWrapper = styled.div<{
  $size: number;
  $borderWidth: number;
  $color?: string;
}>`
  .loader {
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
    border: ${(props) => props.$borderWidth}px solid
      ${(props) => props.color ?? props.theme.colors.gray300};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  size?: number;
  borderWidth?: number;
  color?: string;
};

export function Spinner({
  size = 48,
  borderWidth = 5,
  color,
  ...rest
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <SpinnerWrapper
      $size={size}
      $borderWidth={borderWidth}
      color={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <span className="loader" />
    </SpinnerWrapper>
  );
}
