import { setPreviouslySelectedFontFamily } from "@core/features/editor/editorSlice";
import { FontPopoverContent } from "@core/features/text/components/FontPopoverContent";
import { useAppDispatch } from "@core/hooks";
import { PopoverContent, PopoverPortal, PopoverRoot } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";

const QUOTES_REGEX = /["]+/g;

type Props = {
  fontFamily: string;
  onValueChange: (value: string) => void;
};

export function SidebarFontPicker({ fontFamily, onValueChange }: Props) {
  const dispatch = useAppDispatch();
  const [popoverExpanded, setPopoverExpanded] = useState(false);

  const handleValueChange = (value: string) => {
    setPopoverExpanded(false);
    onValueChange(value);
    dispatch(setPreviouslySelectedFontFamily(value));
  };

  return (
    <PopoverRoot open={popoverExpanded} onOpenChange={setPopoverExpanded}>
      <Popover.Trigger className="rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-left text-xs text-gray-12">
        {/* Fonts with numbers in their name need to be wrapped in quotes, so we remove it here */}
        {fontFamily.replace(QUOTES_REGEX, "")}
      </Popover.Trigger>
      <PopoverPortal>
        <PopoverContent align="start" side="right" sideOffset={16}>
          <FontPopoverContent onValueChange={handleValueChange} />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
}
