import { useEventConnector } from "@core/events";
import { ROOT_LAYER_ID } from "@folds/shared/constants";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export function Page({ children }: Props) {
  const { handleMouseOver } = useEventConnector(ROOT_LAYER_ID);
  return (
    <div
      aria-label="Page"
      id={ROOT_LAYER_ID}
      data-instance-id={undefined}
      className="absolute"
      onMouseOver={handleMouseOver}
    >
      {children}
    </div>
  );
}
