import { useModal } from "@core/modalStore";
import { useEffect, useState } from "react";

const getModalHeadElement = (
  element: HTMLElement | null
): HTMLHeadElement | null => {
  const head = element?.querySelector("head");

  if (head) {
    return head;
  }

  // Don't search outside of the modal
  if (!element?.parentElement || element.tagName === "UI-MODAL") return null;

  return getModalHeadElement(element.parentElement);
};

/**
 * Gets the <head> tag attached to the modal.
 */
export const useModalHead = () => {
  const modal = useModal();

  const [head, setHead] = useState<HTMLHeadElement | null>(null);

  // The head isn't available immediately, so we need to wait for it to be added to the DOM.
  useEffect(() => {
    const interval = setInterval(() => {
      if (head) return;

      const headElement = getModalHeadElement(modal);

      if (!headElement) return;

      setHead(headElement);
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [head, modal]);

  return head;
};
