import { SidebarElements } from "@/features/sidebar/SidebarElements";
import { SidebarStateOptions } from "@/features/sidebar/SidebarStateOptions";
import { SidebarStyles } from "@/features/sidebar/SidebarStyles";
import { SidebarPageInformation } from "@/features/sidebar/components";
import { Apps } from "@/features/sidebar/components/Apps";
import { Icons } from "@/features/sidebar/components/Icons";
import { Images } from "@/features/sidebar/components/Images";

import { BlocksAndTemplates } from "@/features/sidebar/components/BlocksAndTemplates";
import { TextElements } from "@/features/sidebar/components/TextElements";
import { useAppSelector } from "@core/hooks";
import { SidebarState } from "@folds/shared/types";
import * as Tabs from "@radix-ui/react-tabs";
import { SidebarErrorBoundary } from "@/features/sidebar/components/SidebarErrorBoundary";
import { ReplaceExistingLayersWithTemplateDialog } from "@/features/sidebar/components/ReplaceExistingLayersWithTemplateDialog";
import { useEffect, useState, useTransition } from "react";

function SidebarContent({ sidebarState }: { sidebarState: SidebarState }) {
  switch (sidebarState) {
    case "elements": {
      return <SidebarElements />;
    }
    case "styles": {
      return <SidebarStyles />;
    }
    case "text": {
      return <TextElements />;
    }
    case "images": {
      return <Images />;
    }
    case "templates": {
      return <BlocksAndTemplates />;
    }
    case "apps": {
      return <Apps />;
    }
    case "icons": {
      return <Icons />;
    }
  }
}

export function Sidebar() {
  const sidebarState = useAppSelector((state) => state.editor.sidebarState);
  const [transitionedSidebarState, setTransitionedSidebarState] =
    useState(sidebarState);

  const [, startTransition] = useTransition();

  useEffect(() => {
    // Make updating the sidebar state a non-blocking update
    startTransition(() => {
      setTransitionedSidebarState(sidebarState);
    });
  }, [sidebarState]);

  return (
    <SidebarErrorBoundary>
      <SidebarPageInformation />
      <ReplaceExistingLayersWithTemplateDialog />
      <Tabs.Root
        orientation="vertical"
        className="fixed left-0 top-12 z-50 flex h-[calc(100vh-48px)]"
      >
        <SidebarStateOptions />
        <div className="scroll-without-scrollbar relative w-64 border-r border-solid border-gray-5 bg-gray-1 pb-14">
          <SidebarContent sidebarState={transitionedSidebarState} />
        </div>
      </Tabs.Root>
    </SidebarErrorBoundary>
  );
}
