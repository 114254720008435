import { store } from "@core/store";
import { useCallback, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { useAppDispatch } from "@core/hooks";
import {
  resetEditorState,
  setIsIntercomLoaded,
  setIsSwiperScriptLoaded,
} from "@core/features/editor/editorSlice";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import isEqual from "lodash.isequal";

export function LoseUnsavedChangesConfirmation({
  modalRef,
  clearPageId,
  confirmLoseChangesDialogOpen,
  setConfirmLoseChangesDialogOpen,
}: {
  modalRef: React.RefObject<UIModalElement | null>;
  clearPageId: () => void;
  confirmLoseChangesDialogOpen: boolean;
  setConfirmLoseChangesDialogOpen: (open: boolean) => void;
}) {
  const dispatch = useAppDispatch();

  const resetState = useCallback(() => {
    dispatch(resetEditorState());
    clearPageId();
    setConfirmLoseChangesDialogOpen(false);
  }, [clearPageId, dispatch, setConfirmLoseChangesDialogOpen]);

  const handleModalClose = useCallback(() => {
    const { layers, savedLayers } = store.getState().editor;

    dispatch(setIsIntercomLoaded(false));
    dispatch(setIsSwiperScriptLoaded(false));

    const allChangesSaved = isEqual(layers, savedLayers);

    if (allChangesSaved || Object.keys(layers).length === 0) {
      resetState();
      return;
    }

    setConfirmLoseChangesDialogOpen(true);
  }, [dispatch, resetState, setConfirmLoseChangesDialogOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (!modalElement) return;

    modalElement.addEventListener("hide", handleModalClose);

    return () => {
      modalElement.removeEventListener("hide", handleModalClose);
    };
  }, [handleModalClose, modalRef]);

  const handleDialogOpenChange = async (open: boolean) => {
    if (open) return;

    setConfirmLoseChangesDialogOpen(false);
    await modalRef.current?.show();
  };

  if (confirmLoseChangesDialogOpen) {
    return (
      <Dialog.Root open onOpenChange={handleDialogOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-20" />
          <Dialog.Content className="fixed left-1/2 top-1/2 flex w-96 -translate-x-1/2 -translate-y-1/2 flex-col gap-5 rounded-lg bg-gray-1 p-6 shadow">
            <div className="flex justify-between">
              <Dialog.Title className="text-lg font-medium">
                Unsaved changes
              </Dialog.Title>
              <Dialog.Close>
                <CloseDialogIcon />
              </Dialog.Close>
            </div>
            <p className="text-base">
              Are you sure you want to leave? Changes you made may not be saved.
            </p>
            <div className="flex gap-3">
              <Dialog.Close
                type="button"
                className="flex-1 rounded border border-gray-6 bg-gray-2 py-3 text-sm"
              >
                Cancel
              </Dialog.Close>
              <button
                type="button"
                onClick={resetState}
                className="flex-1 rounded border border-gray-6 bg-red-9 py-3 text-sm text-white"
              >
                Leave
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return null;
}
