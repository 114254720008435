import { checkIfIsIntegrationTest } from "@/utils/checkIfIsIntegrationTest";
import { LoadingPage } from "@common/LoadingPage";
import { useAxios } from "@lib/axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const isIntegrationTest = checkIfIsIntegrationTest();
const allowedPaths = ["/exit-frame", "/playground"];

export function Authentication({ children }: { children: React.ReactNode }) {
  const axios = useAxios();

  const [isLoaded, setIsLoaded] = useState(false);

  const location = useLocation();

  const verifyShop = useCallback(async () => {
    if (isIntegrationTest) {
      return;
    }

    if (allowedPaths.includes(location.pathname)) {
      return;
    }

    try {
      const result = await axios.get("/verify-shop");

      if (result.status !== 200) {
        window.location.href = "/exit-frame";
        return;
      }

      setIsLoaded(true);
    } catch (error) {
      window.location.href = "/exit-frame";
    }
  }, [axios, location.pathname]);

  useEffect(() => {
    verifyShop().catch(() => null);
  }, [verifyShop]);

  if (isIntegrationTest) {
    return children;
  }

  if (allowedPaths.includes(location.pathname)) {
    return children;
  }

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return children;
}
