import { useProduct } from "@/features/sidebar/api/getProduct";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import {
  selectOptionName,
  selectProductId,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { ShopifyProduct } from "@folds/shared";
import { PopoverContent, PopoverPortal } from "@lib/radix";
import * as Popover from "@radix-ui/react-popover";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  onSelect: (optionName: string) => void;
  productId: ShopifyProduct["id"];
  wrapperClassName?: string;
  itemClassName?: string;
  inputClassName?: string;
};

export function OptionSelect({
  onSelect,
  productId,
  wrapperClassName,
  inputClassName,
  itemClassName,
}: Props) {
  const [searchValue, setsSearchValue] = useState("");

  const { data: product, isLoading, error } = useProduct(productId);

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setsSearchValue(event.target.value);
  };

  return (
    <div
      className={twMerge(
        "flex w-56 flex-col gap-1 rounded border border-gray-6 bg-gray-1 p-2",
        wrapperClassName
      )}
    >
      <input
        placeholder="Search options"
        value={searchValue}
        onChange={handleSearchValueChange}
        className={twMerge(
          "h-8 border border-solid border-gray-6 px-3 text-sm text-gray-12",
          inputClassName
        )}
      />
      <div className="flex h-64 flex-col overflow-y-auto">
        {isLoading && (
          <div className="flex flex-col gap-1">
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
            <div className="min-h-[28px] animate-pulse rounded-sm bg-gray-4" />
          </div>
        )}
        {product === undefined && error !== undefined && (
          <p className="whitespace-normal text-center text-sm text-gray-11">
            There was an error
          </p>
        )}
        {product !== undefined && product.options.length === 0 && (
          <p className="whitespace-normal text-center text-sm text-gray-11">
            No options found
          </p>
        )}
        {product !== undefined &&
          product.options.map((option) => (
            <button
              type="button"
              className={twMerge(
                "min-h-[28px] shrink-0 grow-0 basis-auto rounded-sm px-2 py-1 text-left text-xs text-gray-12 hover:bg-gray-4",
                itemClassName
              )}
              key={option.id}
              onClick={() => onSelect(option.name)}
            >
              <p className="line-clamp-2">{option.name}</p>
            </button>
          ))}
      </div>
    </div>
  );
}

export const useSelectedProductTitle = (
  productId: ShopifyProduct["id"] | null
) => {
  const { data: product, isLoading, error } = useProduct(productId);

  if (productId === null) return <p className="truncate">Select a product</p>;

  if (isLoading) {
    return <p className="truncate">Loading...</p>;
  }

  if (error !== undefined)
    return <p className="truncate">There was an error</p>;

  if (product === undefined)
    return <p className="truncate">Product not found</p>;

  return <p className="truncate">{product.title}</p>;
};

export const useSelectedOptionTitle = (
  productId: ShopifyProduct["id"] | null,
  optionName: ShopifyProduct["options"][number]["name"] | null
) => {
  const { data: product, error, isLoading } = useProduct(productId);

  if (productId === null) return <p>Select a product</p>;
  if (optionName === null) return <p>Select an option</p>;

  if (isLoading) return <p>Loading...</p>;

  if (error !== undefined) return <p>There was an error</p>;

  if (product === undefined) return <p>Product not found</p>;

  const option = product.options.find(
    (currentOption) => currentOption.name === optionName
  );

  if (option === undefined) return <p>Option not found</p>;

  return <p>{option.name}</p>;
};

export function OptionSelector() {
  const dispatch = useAppDispatch();

  const productId = useAppSelector(selectProductId);
  const optionName = useAppSelector(selectOptionName);

  const [selectProductOpen, setSelectProductOpen] = useState(false);
  const [selectOptionOpen, setSelectOptionOpen] = useState(false);

  const productTitle = useSelectedProductTitle(productId);
  const optionTitle = useSelectedOptionTitle(productId, optionName);

  const handleSelectProduct = (updatedProductData: {
    productId: ShopifyProduct["id"];
    productHandle: ShopifyProduct["options"][number]["name"];
  }) => {
    dispatch(
      setSelectedLayerProperties({
        productId: updatedProductData.productId,
        productHandle: updatedProductData.productHandle,
        optionName: null,
      })
    );
    setSelectProductOpen(false);
  };

  const handleSelectOption = async (selectedOptionName: string) => {
    setSelectOptionOpen(false);
    dispatch(
      setSelectedLayerProperties({
        optionName: selectedOptionName,
      })
    );
  };

  return (
    <div className="flex flex-col gap-3 border-t border-gray-6 p-3">
      <p className="text-sm font-medium">Option</p>
      <div className="flex flex-col gap-2">
        <Popover.Root
          open={selectProductOpen}
          onOpenChange={setSelectProductOpen}
        >
          <Popover.Trigger className="truncate rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-left text-xs text-gray-12">
            {productTitle}
          </Popover.Trigger>
          <PopoverPortal>
            <PopoverContent align="center" side="right" sideOffset={21}>
              <ProductSelect onSelect={handleSelectProduct} />
            </PopoverContent>
          </PopoverPortal>
        </Popover.Root>
        {typeof productId === "number" ? (
          <Popover.Root
            open={selectOptionOpen}
            onOpenChange={setSelectOptionOpen}
          >
            <Popover.Trigger className="truncate rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-left text-xs text-gray-12">
              {optionTitle}
            </Popover.Trigger>
            <PopoverPortal>
              <PopoverContent align="center" side="right" sideOffset={21}>
                <OptionSelect
                  onSelect={handleSelectOption}
                  productId={productId}
                />
              </PopoverContent>
            </PopoverPortal>
          </Popover.Root>
        ) : (
          <button
            type="button"
            className="cursor-default truncate rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-left text-xs text-gray-10"
          >
            Select a product first
          </button>
        )}
      </div>
    </div>
  );
}
