import { Point, Viewport } from "@folds/shared/types";

export const calculateMousePointRelativeToCanvas = (
  mousePoint: Point,
  viewport: Viewport
) => {
  const { scale, translateX, translateY } = viewport;

  const calculatedTop = mousePoint.y / scale - translateY / scale;
  const calculatedLeft = mousePoint.x / scale - translateX / scale;

  return { top: calculatedTop, left: calculatedLeft };
};
