import { useModalHead } from "@/features/editor/hooks/useModalHead";
import { useEffect } from "react";

export const useLoadWebComponentsInModal = () => {
  const modalHead = useModalHead();

  useEffect(() => {
    if (!modalHead) return;

    const modalDocument = modalHead.ownerDocument;
    const script = modalDocument.createElement("script");

    script.type = "text/javascript";
    script.src = "/webcomponents.js";
    script.async = true;

    modalHead.appendChild(script);
  }, [modalHead]);
};
