import { assignLayerProperties } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/assignLayerProperties";
import { getLayerResponsiveLeftPosition } from "@core/features/editor/editorSlice/actions/utils/getLayerResponsiveLeftPosition";
import { getElement } from "@core/utils";
import { Breakpoint, Layer, LayerId, Layers } from "@folds/shared/types";

export type ResponsivePositionResult = {
  desktop: number | null;
  tablet: number | null;
  mobile: number | null;
};

const checkIfHasEditedTabletPosition = (layer: Layer) => {
  const hasEditedTabletPosition =
    "hasEditedTabletPosition" in layer ? layer.hasEditedTabletPosition : false;

  return hasEditedTabletPosition;
};

const checkIfHasEditedMobilePosition = (layer: Layer) => {
  const hasEditedMobilePosition =
    "hasEditedMobilePosition" in layer ? layer.hasEditedMobilePosition : false;

  return hasEditedMobilePosition;
};

const checkIfHasEditedDesktopPosition = (layer: Layer) => {
  const hasEditedDesktopPosition =
    "hasEditedDesktopPosition" in layer
      ? layer.hasEditedDesktopPosition
      : false;

  return hasEditedDesktopPosition;
};

/**
 * Updates the left position of a layer and cascades the change to lower breakpoints
 * if they haven't been edited yet.
 */
export const setLeftPosition = ({
  breakpoint,
  id,
  layers,
  value,
}: {
  id: LayerId;
  value: number;
  breakpoint: Breakpoint;
  layers: Layers;
}) => {
  const element = getElement(id);
  if (element === null) return;

  const layer = layers[id];
  if (layer === undefined) return;

  const responsiveLeft = getLayerResponsiveLeftPosition({
    breakpoint,
    left: value,
    width: element.offsetWidth,
  });

  const hasEditedDesktopPosition = checkIfHasEditedDesktopPosition(layer);
  const hasEditedTabletPosition = checkIfHasEditedTabletPosition(layer);
  const hasEditedMobilePosition = checkIfHasEditedMobilePosition(layer);

  switch (breakpoint) {
    case "desktop": {
      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value,
        breakpoint: "desktop",
      });

      if (hasEditedTabletPosition) break;

      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value: responsiveLeft.tablet,
        breakpoint: "tablet",
        assignHasEditedPosition: false,
      });

      if (hasEditedMobilePosition) break;

      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value: responsiveLeft.mobile,
        breakpoint: "mobile",
        assignHasEditedPosition: false,
      });

      break;
    }
    case "tablet": {
      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value,
        breakpoint: "tablet",
      });

      if (hasEditedDesktopPosition === false) {
        assignLayerProperties({
          layers,
          layer,
          key: "left",
          value: responsiveLeft.desktop,
          breakpoint: "desktop",
          assignHasEditedPosition: false,
        });
      }

      if (hasEditedMobilePosition === false) {
        assignLayerProperties({
          layers,
          layer,
          key: "left",
          value: responsiveLeft.mobile,
          breakpoint: "mobile",
          assignHasEditedPosition: false,
        });
      }

      break;
    }
    case "mobile": {
      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value,
        breakpoint: "mobile",
      });

      if (hasEditedTabletPosition) return;

      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value: responsiveLeft.tablet,
        breakpoint: "tablet",
        assignHasEditedPosition: false,
      });

      if (hasEditedDesktopPosition) return;

      assignLayerProperties({
        layers,
        layer,
        key: "left",
        value: responsiveLeft.desktop,
        breakpoint: "desktop",
        assignHasEditedPosition: false,
      });

      break;
    }
  }
};
