import { LayerType, Layers } from "@folds/shared/types";

export const getBreakpointId = (layers: Layers) => {
  const breakpointLayer = Object.values(layers).find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (
    breakpointLayer === undefined ||
    breakpointLayer.type !== LayerType.Breakpoint
  )
    return null;

  return breakpointLayer.id;
};
