import { Box } from "@folds/shared";

type Props = {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
};

export const checkIfLayersAreIntersectingVertically = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Props) => {
  const selectionBoxBottom =
    selectionBoundingBox.top + selectionBoundingBox.height;
  const targetLayerBoxBottom =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height;

  if (
    selectionBoundingBox.top > targetLayerBoxBottom ||
    targetLayerBoundingBox.top > selectionBoxBottom
  ) {
    return false;
  }

  return true;
};
