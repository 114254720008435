import { useModalHead } from "@/features/editor/hooks/useModalHead";
import { setIsSwiperScriptLoaded } from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks";
import { useEffect } from "react";

export const useLoadSwiperScriptInModal = () => {
  const modalHead = useModalHead();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!modalHead) return;

    const modalDocument = modalHead.ownerDocument;
    const script = modalDocument.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js";
    script.async = true;

    script.onload = () => {
      dispatch(setIsSwiperScriptLoaded(true));
    };

    modalHead.appendChild(script);
  }, [dispatch, modalHead]);
};
