import { BelowIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/VerticalIndicator/BelowIndicator";
import { AboveIndicator } from "@/features/editor/components/AltHoverDistanceIndicator/VerticalIndicator/AboveIndicator";
import { Box, LayerId } from "@folds/shared";
import { checkIfLayersAreIntersectingVertically } from "@/features/editor/components/AltHoverDistanceIndicator/utils/checkIfLayersAreIntersectingVertically";

const checkIfTargetLayerIsBelow = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) =>
  targetLayerBoundingBox.top + targetLayerBoundingBox.height >
  selectionBoundingBox.top;

export function VerticalIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const isIntersectingVertically = checkIfLayersAreIntersectingVertically({
    selectionBoundingBox,
    targetLayerBoundingBox,
  });

  if (!isIntersectingVertically) {
    const isPositionedBelow = checkIfTargetLayerIsBelow({
      selectionBoundingBox,
      targetLayerBoundingBox,
    });

    if (isPositionedBelow) {
      return (
        <BelowIndicator
          targetLayerId={targetLayerId}
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      );
    }

    return (
      <AboveIndicator
        targetLayerId={targetLayerId}
        selectionBoundingBox={selectionBoundingBox}
        targetLayerBoundingBox={targetLayerBoundingBox}
      />
    );
  }

  return null;
}
