/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TrackedStateChange } from "@core/features/editor/editorSlice";
import type { RootState } from "@core/store";
import { getNewTrackedChange } from "@core/utils/getNewTrackedChange";
import {
  Layer,
  LayerId,
  Layers,
  compiledLayerSchema,
  compiledLayersSchema,
  layersSchema,
} from "@folds/shared/types";
import { StoreCreator } from "@reduxjs/toolkit";
import { captureException, captureMessage } from "@sentry/react";
import isEqual from "lodash.isequal";

const getLayersChanges = ({
  oldLayers,
  newLayers,
}: {
  oldLayers: Layers;
  newLayers: Layers;
}): { deletedLayerIds: LayerId[]; changedLayers: Layer[] } => {
  const changedLayers = Object.values(newLayers).filter((layer) => {
    const oldLayer = oldLayers[layer.id];
    return !isEqual(layer, oldLayer);
  });

  const deletedLayers = Object.values(oldLayers).filter((layer) => {
    const newLayer = newLayers[layer.id];

    if (newLayer === undefined) return true;

    return false;
  });

  const deletedLayerIds = deletedLayers.map((layer) => layer.id);

  return {
    deletedLayerIds,
    changedLayers,
  };
};

const getUpdatedLayers = ({
  newState,
  previousState,
  actionType,
}: {
  newState: RootState;
  previousState: RootState;
  actionType: string;
}): { deletedLayerIds: LayerId[]; changedLayers: Layer[] } => {
  if (actionType === "editor/resumeHistory") {
    const historyPausedLayers =
      previousState.editor.isHistoryEnabled.initialLayers;

    if (historyPausedLayers === null) {
      return {
        deletedLayerIds: [],
        changedLayers: [],
      };
    }

    const result = getLayersChanges({
      newLayers: newState.editor.layers,
      oldLayers: historyPausedLayers,
    });

    return result;
  }

  if (newState.editor.isHistoryEnabled.value === false)
    return { changedLayers: [], deletedLayerIds: [] };

  const result = getLayersChanges({
    newLayers: newState.editor.layers,
    oldLayers: previousState.editor.layers,
  });

  return result;
};

export const syncStoreEnhancer = (createStore: StoreCreator) => {
  const storeCreator: StoreCreator = (reducer, preloadedState) => {
    const dispatchReducer = (state: RootState | undefined, action: any) => {
      const newState = reducer(state as any, action) as RootState;

      const isInitializingStore =
        state === undefined || action.type === "@@INIT";

      if (isInitializingStore) return newState;

      // If the new layers are invalid rollback to the previous state
      const layersAreValid = compiledLayersSchema.Check(newState.editor.layers);

      if (!layersAreValid) {
        captureMessage(
          `Invalid layers state detected during action ${action.type}`,
          "error"
        );

        return state;
      }

      const updatedLayers = getUpdatedLayers({
        actionType: action.type,
        previousState: state,
        newState,
      });

      if (
        updatedLayers.changedLayers.length === 0 &&
        updatedLayers.deletedLayerIds.length === 0
      ) {
        return newState;
      }

      const isSyncingFromOtherClients =
        action.type === "editor/syncLayerChanges";

      const isLoadPageAction =
        action.type === "editor/loadPage/fulfilled" ||
        action.type === "editor/loadPage/pending" ||
        action.type === "editor/loadPage/rejected";

      const isHistoryAction =
        action.type === "editor/undo" ||
        action.type === "editor/redo" ||
        action.type === "editor/resumeHistory";

      const isResetEditorStateAction =
        action.type === "editor/resetEditorState";

      const isHistoryEnabled = newState.editor.isHistoryEnabled.value;

      if (
        !isHistoryEnabled ||
        isSyncingFromOtherClients ||
        isLoadPageAction ||
        isHistoryAction ||
        isResetEditorStateAction
      )
        return newState;

      const newTrackedStateChange = getNewTrackedChange({
        oldLayers: state.editor.layers,
        oldSelectedLayerIds: state.editor.selectedLayerIds,
        newLayers: newState.editor.layers,
      });

      const newPreviousStates: TrackedStateChange[] = [
        newTrackedStateChange,
        ...state.editor.previousStates,
      ];

      // Limit to 250 changes to prevent running out of memory
      if (newPreviousStates.length > 250) {
        newPreviousStates.length = 250;
      }

      return {
        ...newState,
        editor: {
          ...newState.editor,
          previousStates: newPreviousStates,
          futureStates: [],
        },
      };
    };

    return createStore(dispatchReducer as any, preloadedState as any);
  };

  return storeCreator;
};
