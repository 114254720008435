import { useAxios } from "@lib/axios";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import useSWR from "swr";

const searchProductsResultSchema = TypeCompiler.Compile(
  Type.Array(
    Type.Object({
      id: Type.String(),
      title: Type.String(),
      url: Type.String(),
      handle: Type.String(),
    })
  )
);

export const useProductUrlsSearch = (search: string) => {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);

    const parsedResult = searchProductsResultSchema.Decode(result.data);

    return parsedResult;
  };

  return useSWR(`/shopify/urls/products?search=${search}`, getResult);
};
