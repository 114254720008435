import { deleteLayerAction } from "@core/features/editor/editorSlice/actions/deleteLayerAction";
import { randomizeLayers } from "@core/features/editor/editorSlice/actions/pasteLayersAction";
import { getLayerInformationToReplace } from "@core/features/editor/editorSlice/actions/utils/getValuesToReplaceFromLayers";
import { replaceLayersInformationWithNull } from "@core/features/editor/editorSlice/actions/utils/replaceLayersInformationWithNull";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getBreakpointId } from "@core/utils";
import { Layer, LayerId, LayerType, Layers } from "@folds/shared";
import { current } from "@reduxjs/toolkit";

const updateLayersParentIds = (
  layers: Layer[],
  {
    originalParentId,
    updatedParentId,
  }: { originalParentId: LayerId; updatedParentId: LayerId }
) => {
  const updatedLayers = layers.map((layer) => {
    if (layer.parentId === originalParentId) {
      return {
        ...layer,
        parentId: updatedParentId,
      };
    }

    return layer;
  });

  return updatedLayers;
};

export const appendTemplateToLayersAction = (state: DraftEditorState) => {
  const droppedLayers = state.pendingDropTemplateLayers;
  if (!droppedLayers) return;

  const radomizedLayers = randomizeLayers(
    Object.values(current(droppedLayers))
  );

  const breakpointId = getBreakpointId(state.layers);
  if (breakpointId === null) return;

  const templateBreakpointLayer = radomizedLayers.find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (
    templateBreakpointLayer === undefined ||
    templateBreakpointLayer.type !== LayerType.Breakpoint
  )
    return;

  const filteredTemplateLayers = radomizedLayers.filter(
    (layer) => layer.id !== templateBreakpointLayer.id
  );

  const updatedLayersArray = updateLayersParentIds(filteredTemplateLayers, {
    originalParentId: templateBreakpointLayer.id,
    updatedParentId: breakpointId,
  });

  const breakpointLayer = state.layers[breakpointId];

  if (
    breakpointLayer === undefined ||
    breakpointLayer.type !== LayerType.Breakpoint
  )
    return;

  breakpointLayer.children.push(...templateBreakpointLayer.children);

  const updatedLayers = updatedLayersArray.reduce((acc, layer) => {
    acc[layer.id] = layer;
    return acc;
  }, {} as Layers);

  const layersWithReplacedInformation =
    replaceLayersInformationWithNull(updatedLayers);

  const informationToReplace = getLayerInformationToReplace(updatedLayers);
  state.droppedLayersInformationToReplace = informationToReplace;

  Object.values(layersWithReplacedInformation).forEach((layer) => {
    state.layers[layer.id] = layer;
  });
};

export const replaceLayersWithTemplateAction = (state: DraftEditorState) => {
  const droppedLayers = state.pendingDropTemplateLayers;
  if (!droppedLayers) return;

  const radomizedLayers = randomizeLayers(
    Object.values(current(droppedLayers))
  );

  const breakpointId = getBreakpointId(state.layers);
  if (breakpointId === null) return;

  const templateBreakpointLayer = radomizedLayers.find(
    (layer) => layer.type === LayerType.Breakpoint
  );

  if (
    templateBreakpointLayer === undefined ||
    templateBreakpointLayer.type !== LayerType.Breakpoint
  )
    return;

  const filteredTemplateLayers = radomizedLayers.filter(
    (layer) => layer.id !== templateBreakpointLayer.id
  );

  const updatedLayersArray = updateLayersParentIds(filteredTemplateLayers, {
    originalParentId: templateBreakpointLayer.id,
    updatedParentId: breakpointId,
  });

  const breakpointLayer = state.layers[breakpointId];

  if (
    breakpointLayer === undefined ||
    breakpointLayer.type !== LayerType.Breakpoint
  )
    return;

  const originalBreakpointLayerChildren = breakpointLayer.children;

  breakpointLayer.children = templateBreakpointLayer.children;

  const updatedLayers = updatedLayersArray.reduce((acc, layer) => {
    acc[layer.id] = layer;
    return acc;
  }, {} as Layers);

  const layersWithReplacedInformation =
    replaceLayersInformationWithNull(updatedLayers);

  const informationToReplace = getLayerInformationToReplace(updatedLayers);
  state.droppedLayersInformationToReplace = informationToReplace;

  Object.values(layersWithReplacedInformation).forEach((layer) => {
    state.layers[layer.id] = layer;
  });

  originalBreakpointLayerChildren.map((childId) => {
    deleteLayerAction(state, childId);
  });
};
