type Props = {
  onValueChange: (value: string) => void;
  value: string;
};

export function SidebarFontWeightSelect({ onValueChange, value }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onValueChange(event.target.value);
  };

  return (
    <select
      className="h-8 rounded-sm border-r-4 border-transparent bg-gray-2 px-3 text-xs outline outline-1 -outline-offset-1 outline-gray-6"
      onChange={handleChange}
      value={value}
    >
      <option value="100">Thin</option>
      <option value="200">Extra light</option>
      <option value="300">Light</option>
      <option value="400">Regular</option>
      <option value="500">Medium</option>
      <option value="600">Semi bold</option>
      <option value="700">Bold</option>
      <option value="800">Extra bold</option>
      <option value="900">Black</option>
      {value === "Mixed" && <option value="Mixed">Mixed</option>}
    </select>
  );
}
