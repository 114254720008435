import { store } from "@core/store";
import {
  ElementCollection,
  Layer,
  Layers,
  SelectedLayerIds,
  ShopifyProduct,
  Size,
  compiledElementCollection,
  elementCollectionEnum,
} from "@folds/shared";
import { useAxios } from "@lib/axios";
import { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { toast } from "@core/toast";
import { useGetProductsInformationFromLayers } from "@/features/editor/components/DevelopmentSaveComponent/useGetProductsInformationFromLayers";
import { getLayersToSave } from "@/features/editor/components/DevelopmentSaveComponent/utils/getLayersToSave";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { getLayerDimensions } from "@core/utils";
import { getLayersRelativeToTopLeft } from "@/features/editor/components/DevelopmentSaveComponent/utils/getLayersRelativeToTopLeft";
import { DialogContent, DialogPortal } from "@lib/radix";

function SelectCollection({
  onValueChange,
  value,
}: {
  value: ElementCollection;
  onValueChange: (value: ElementCollection) => void;
}) {
  const handleRadioGroupValueChange = (updatedValue: string) => {
    try {
      const parsedValue = compiledElementCollection.Decode(updatedValue);

      onValueChange(parsedValue);
    } catch (error) {
      toast.error("Invalid collection value");
    }
  };

  return (
    <RadioGroup.Root onValueChange={handleRadioGroupValueChange} value={value}>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.Accordion}
      >
        Accordion
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.AddToCartButton}
      >
        Add to cart button
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.OptionDropdown}
      >
        Option dropdown
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.OptionSelector}
      >
        Option selector
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.ProductImageCarousel}
      >
        Product image carousel
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.ProductPrice}
      >
        Product price
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.ProductTitle}
      >
        Product title
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.QuantityPicker}
      >
        Quantity picker
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.RedirectButton}
      >
        Redirect button
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.SalePrice}
      >
        Sale price
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.Shape}
      >
        Shape
      </RadioGroup.Item>
      <RadioGroup.Item
        className="bg-gray-2 p-2 data-[state=checked]:bg-gray-5"
        value={elementCollectionEnum.Carousel}
      >
        Carousel
      </RadioGroup.Item>
    </RadioGroup.Root>
  );
}

const getSelectedLayerDimensons = (
  selectedLayerIds: SelectedLayerIds
): Size | null => {
  const firstSelectedLayerId = selectedLayerIds[0];
  if (firstSelectedLayerId === undefined) return null;

  const position = getLayerDimensions(firstSelectedLayerId);

  return position;
};

const getSelectedLayerType = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
): Layer["type"] | null => {
  const firstSelectedLayerId = selectedLayerIds[0];
  if (firstSelectedLayerId === undefined) return null;

  const layer = layers[firstSelectedLayerId];
  if (layer === undefined) return null;

  return layer.type;
};

const getBreakpointOverridesWithOnlyTheVisibleKey = (
  layers: Layers
): Layers => {
  const updatedLayers = Object.entries(layers).map(([key, layer]) => {
    if ("tablet" in layer && "mobile" in layer) {
      const newTablet = Object.entries(layer.tablet).filter(
        ([currentKey]) => currentKey === "visible"
      );

      const newMobile = Object.entries(layer.mobile).filter(
        ([currentKey]) => currentKey === "visible"
      );

      return [
        key,
        {
          ...layer,
          tablet: Object.fromEntries(newTablet),
          mobile: Object.fromEntries(newMobile),
        },
      ];
    }

    return [key, layer];
  });

  return Object.fromEntries(updatedLayers);
};

/**
 * Saves elements to the server
 *
 * Only render in development environment
 */
export function DevelopmentSaveElement() {
  const axios = useAxios();

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [products, setProducts] = useState<ShopifyProduct[]>([]);
  const [layersToSave, setLayersToSave] = useState<Layers>({});
  const [collection, setCollection] = useState<ElementCollection>("shape");
  const [elementDimensions, setElementDimensions] = useState<Size | null>(null);
  const [elementType, setElementType] = useState<Layer["type"] | null>(null);

  const getProductInformation = useGetProductsInformationFromLayers();

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") return;

    window.folds = {
      ...window.folds,
      toggleSaveElement: () => {
        setIsDisplayed((prev) => !prev);
      },
    };
  }, []);

  if (process.env.NODE_ENV !== "development") return null;

  const handleOpenChange = async (open: boolean) => {
    if (open === false) return;

    const { layers, selectedLayerIds } = store.getState().editor;

    const saveLayers = getLayersToSave(layers, selectedLayerIds);

    if (saveLayers === undefined) return;

    const productInformation = await getProductInformation(saveLayers);
    if (productInformation === null) return;

    const selectedLayerDimensions = getSelectedLayerDimensons(selectedLayerIds);
    if (selectedLayerDimensions === null) return;

    const selectedLayerType = getSelectedLayerType(layers, selectedLayerIds);
    if (selectedLayerType === null) return;

    setElementDimensions(selectedLayerDimensions);
    setProducts(productInformation);
    setLayersToSave(saveLayers);
    setElementType(selectedLayerType);
  };

  const handleSaveLayers = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (Object.keys(layersToSave).length === 0) return;

    if (elementDimensions === null) {
      toast.error("Element dimensions are missing");
      return;
    }

    const parsedBreakpointOverrides =
      getBreakpointOverridesWithOnlyTheVisibleKey(layersToSave);

    const layersWithUpdatedPositions = getLayersRelativeToTopLeft(
      parsedBreakpointOverrides
    );

    try {
      toast.loading("Saving element...", { id: "saving-element" });

      await axios.post("/elements", {
        products,
        layers: layersWithUpdatedPositions,
        collection,
        width: elementDimensions.width,
        height: elementDimensions.height,
      });
      toast.success("Element saved", { id: "saving-element" });
    } catch (error) {
      toast.error("Failed to save element", { id: "saving-element" });
    }
  };

  if (isDisplayed === false) return null;

  return (
    <Dialog.Root onOpenChange={handleOpenChange}>
      <Dialog.Trigger
        type="button"
        // Prevent unselecting the selected elements
        onMouseDown={(event) => event.stopPropagation()}
        className="red-100 fixed bottom-0 right-[232px] z-50 rounded bg-gray-12 p-3 text-white"
      >
        Save element
      </Dialog.Trigger>
      <DialogPortal>
        <DialogContent className="fixed left-0 top-0 flex h-screen w-96 translate-x-0 translate-y-0 flex-col gap-5 overflow-y-scroll rounded-lg bg-gray-1 p-6 shadow">
          <p className="text-sm font-medium">Saving: {elementType}</p>
          <SelectCollection value={collection} onValueChange={setCollection} />
          <button
            onClick={handleSaveLayers}
            type="button"
            className="h-8 w-full rounded-sm bg-gray-12 text-white"
          >
            Save
          </button>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}
