import styled from "styled-components";

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const EnabledText = styled.p<{ $enabled: boolean }>`
  color: ${(props) => (props.$enabled ? "inherit" : "grey")};

  ::first-letter {
    text-transform: capitalize;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
