import type { DraftEditorState } from "@core/features/editor/editorSlice";
import { pauseHistoryAction } from "@core/features/editor/editorSlice/actions/pauseHistoryAction";
import { calculateGuideStopLines } from "@core/features/editor/editorSlice/actions/utils/calculateGuideStopLines";
import {
  calculateAbsolutePositionGuides,
  getInitialLayersInformation,
} from "@core/features/editor/editorSlice/actions/utils/dragging";
import {
  getBoundingBoxRect,
  getLayerType,
  getParentLayerId,
} from "@core/utils";
import {
  LayerId,
  LayerType,
  Layers,
  Point,
  SelectedLayerIds,
} from "@folds/shared/types";

export const getParentAccordionRootId = (
  layers: Layers,
  id: LayerId
): LayerId | null => {
  const layer = layers[id];

  if (!layer || layer.type === LayerType.Page) return null;

  const parentLayer = layers[layer.parentId];
  if (!parentLayer) return null;

  if (parentLayer.type === LayerType.Accordion.Root) return parentLayer.id;

  return getParentAccordionRootId(layers, parentLayer.id);
};

const getDraggingLayerIds = (
  layers: Layers,
  selectedLayerIds: SelectedLayerIds
) => {
  const draggingLayerIds = selectedLayerIds.map((layerId) => {
    const layerType = getLayerType(layers, layerId);

    const isPartOfAnAccordion =
      layerType === LayerType.Accordion.Chevron ||
      layerType === LayerType.Accordion.Content ||
      layerType === LayerType.Accordion.Item ||
      layerType === LayerType.Accordion.Trigger;

    if (isPartOfAnAccordion) {
      // Get accordion id
      const parentAccordionId = getParentAccordionRootId(layers, layerId);
      if (parentAccordionId === null) return layerId;

      return parentAccordionId;
    }

    const isPartOfProductImageCarouselThumbnails =
      layerType === LayerType.ProductImageCarousel.Thumbnail;
    const isOptionSelectorItem = layerType === LayerType.OptionSelector.Item;
    const isCarouselSlide = layerType === LayerType.Carousel.Slide;

    if (
      isPartOfProductImageCarouselThumbnails ||
      isOptionSelectorItem ||
      isCarouselSlide
    ) {
      const parentLayerId = getParentLayerId(layers, layerId);
      if (parentLayerId === null) return layerId;

      return parentLayerId;
    }

    return layerId;
  });

  return draggingLayerIds;
};

export const dragStartAction = (
  state: DraftEditorState,
  event: { clientX: number; clientY: number },
  initializedDraggingLayerId: LayerId | null
) => {
  const { layers, selectedLayerIds } = state;

  const draggingLayerIds = getDraggingLayerIds(layers, selectedLayerIds);

  const guideStopLines = calculateGuideStopLines({
    layers,
    ids: draggingLayerIds,
    breakpoint: state.breakpoint,
  });

  const initialMousePoint: Point = {
    x: event.clientX,
    y: event.clientY,
  };

  const initialBox = getBoundingBoxRect(draggingLayerIds);

  const initialLayersInformation = getInitialLayersInformation({
    layers,
    ids: draggingLayerIds,
  });

  const { horizontalGuides, verticalGuides } = calculateAbsolutePositionGuides({
    guideStopLines,
    ids: draggingLayerIds,
  });

  pauseHistoryAction(state);

  state.dragging = {
    draggingLayerIds,
    draggedIntoLayerId: null,
    guideStopLines,
    initialMousePoint,
    initialBox,
    initialLayersInformation,
    horizontalGuides,
    verticalGuides,
    initializedDraggingLayerId,
  };
};
