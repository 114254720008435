import { getBreakpointWidth } from "@core/utils";
import { Breakpoint, LayerId } from "@folds/shared/types";
import styled from "styled-components";

type SectionWrapperProps = {
  $layerId: LayerId;
  $breakpoint: Breakpoint;
  $scale: number;
  $top: number;
};

export const SectionWrapper = styled.div.attrs(
  ({
    $breakpoint: breakpoint,
    $scale: scale,
    $top: top,
  }: SectionWrapperProps) => {
    const breakpointWidth = getBreakpointWidth(breakpoint);

    const breakpointDiff = 1440 - breakpointWidth;
    const left = breakpointDiff / 2;

    const gap = 10 / scale;

    return {
      style: {
        top,
        left,
        transform: `translateX(calc(-100% - ${gap}px))`,
        borderWidth: 1 / scale,
        overflow: "hidden",
      },
    };
  }
)<SectionWrapperProps>`
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.slate3};
  position: absolute;
  display: flex;
  flex-direction: column;
  border-color: ${(props) => props.theme.colors.slate7};
  border-style: solid;
`;

type BlockActionButtonProps = {
  $scale: number;
};

export const BlockActionsButton = styled.button.attrs(
  ({ $scale: scale }: BlockActionButtonProps) => {
    const scaledButtonSize = 25 / scale;
    const scaledPadding = Math.max(5 / scale, 5);

    return {
      style: {
        width: scaledButtonSize,
        height: scaledButtonSize,
        padding: scaledPadding,
      },
    };
  }
)<BlockActionButtonProps>`
  padding: 0;
  background: none;
  min-height: 25px;
  min-width: 25px;

  &:hover {
    background-color: ${(props) => props.theme.colors.slate4};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.slate5};
  }
`;

export const LineSeparator = styled.hr`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.slate7};
  margin: 0;
`;
