import {
  selectShouldDisplayUngroupLayersAction,
  ungroupLayer,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

export function UngroupLayer() {
  const dispatch = useAppDispatch();

  const shouldDisplayUngroupLayersAction = useAppSelector(
    selectShouldDisplayUngroupLayersAction
  );

  const handleClick = () => {
    dispatch(ungroupLayer());
  };

  if (shouldDisplayUngroupLayersAction === false) return null;

  return (
    <div className="fixed left-[326px] z-50 w-[calc(100vw-326px)]">
      <button
        type="button"
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
        className="absolute left-1/2 top-2 h-10 w-32 -translate-x-full rounded bg-gray-12 text-xs text-gray-1"
      >
        Ungroup
      </button>
    </div>
  );
}
