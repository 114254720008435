import { ShopifyPage } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

type ShopifyPageResponse = {
  id: number;
  title: string;
  shop_id: number;
  handle: string;
  body_html: string;
  author: string;
  created_at: Date;
  updated_at: Date;
  published_at: Date;
  template_suffix: null;
  admin_graphql_api_id: string;
};

export const useShopifyPage = (pageId: ShopifyPage["id"] | null) => {
  const authAxios = useAxios();

  const fetchShopifyPage = async (key: string) => {
    const result = await authAxios.get(key);
    return result.data as ShopifyPageResponse;
  };

  return useSWR(
    typeof pageId === "number" ? `/shopify/pages/${pageId}` : null,
    fetchShopifyPage
  );
};
