import { assignLayerProperties } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/assignLayerProperties";
import { setLeftPosition } from "@core/features/editor/editorSlice/actions/setLayerPropertiesAction/setLeftPosition";
import {
  Breakpoint,
  Layer,
  LayerType,
  Layers,
  SelectedLayerIds,
} from "@folds/shared/types";
import invariant from "tiny-invariant";

const checkIfParentIsABlockLayer = (layer: Layer, layers: Layers): boolean => {
  if (layer.parentId === null) return false;

  const parentLayer = layers[layer.parentId];

  if (parentLayer === undefined) return false;

  return parentLayer.type === LayerType.Block;
};

type SetLayerPropertiesActionArguments = {
  updatedLayerIds: SelectedLayerIds;
  layers: Layers;
  properties: Partial<Layer>;
  breakpoint: Breakpoint;
};

export const setLayerPropertiesAction = ({
  layers,
  properties,
  updatedLayerIds,
  breakpoint,
}: SetLayerPropertiesActionArguments) => {
  updatedLayerIds.forEach((id) => {
    const layer = layers[id];
    invariant(layer, `Layer ${id} not found`);

    Object.entries(properties).forEach(([key, value]) => {
      // Only assign a responsive left position for layers inside a block
      const isParentABlockLayer = checkIfParentIsABlockLayer(layer, layers);

      if (isParentABlockLayer && key === "left" && typeof value === "number") {
        setLeftPosition({ breakpoint, id, layers, value });
        return;
      }

      assignLayerProperties({
        layer,
        key,
        value,
        breakpoint,
        layers,
      });
    });
  });
};
