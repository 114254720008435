import { randomizeLayerIds } from "@/features/editor/utils/randomizeLayerIds";
import { getLayerInformationToReplace } from "@core/features/editor/editorSlice/actions/utils/getValuesToReplaceFromLayers";
import { replaceLayersInformationWithNull } from "@core/features/editor/editorSlice/actions/utils/replaceLayersInformationWithNull";
import type { DraftEditorState } from "@core/features/editor/editorSlice/editorSlice";
import { getBreakpointId } from "@core/utils";
import { LayerType, ROOT_LAYER_ID } from "@folds/shared";
import { BlockAddedEvent } from "@folds/shared/types/src/events";

export const blockAddedEventAction = (
  state: DraftEditorState,
  event: BlockAddedEvent
) => {
  const { layers } = state;

  const breakpointLayerId = getBreakpointId(layers);
  if (breakpointLayerId === null) return;

  const { layers: randomizedLayers, rootLayerId: blockId } = randomizeLayerIds({
    layers: event.layers,
    rootLayerId: ROOT_LAYER_ID,
    targetLayerId: breakpointLayerId,
  });

  if (blockId === null) return;

  const breakpointLayer = layers[breakpointLayerId];

  if (
    breakpointLayer === undefined ||
    breakpointLayer.type !== LayerType.Breakpoint
  )
    return;

  breakpointLayer.children.push(blockId);

  const replacedLayers = replaceLayersInformationWithNull(randomizedLayers);
  const informationToReplace = getLayerInformationToReplace(randomizedLayers);

  state.droppedLayersInformationToReplace = informationToReplace;

  Object.values(replacedLayers).forEach((layer) => {
    layers[layer.id] = layer;
  });
};
