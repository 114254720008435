// Loading spinner: https://tobiasahlin.com/spinkit/
export function LoadingPage() {
  return (
    <div className="sk-folding-cube absolute left-1/2 top-72 -translate-x-1/2 ">
      <div className="sk-cube1 sk-cube" />
      <div className="sk-cube2 sk-cube" />
      <div className="sk-cube4 sk-cube" />
      <div className="sk-cube3 sk-cube" />
    </div>
  );
}
