import {
  deleteVariable,
  editVariable,
  selectOptionName,
  selectProductId,
  selectVariables,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { Option, Variables } from "@folds/shared/types";
import { colord } from "colord";
import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { ImagePicker } from "@/features/sidebar/components/ImagePicker";
import { useProduct } from "@/features/sidebar/api/getProduct";

function DeleteIcon() {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z"
        fill="#11181C"
      />
    </svg>
  );
}

function ImageVariable({
  variable,
  option,
  onEdit,
}: {
  variable: Variables[number];
  option: Option;
  onEdit: (variableId: string) => void;
}) {
  const dispatch = useAppDispatch();

  const handleDeleteVariable = () => {
    dispatch(deleteVariable(variable.id));
  };

  const handleEdit = () => {
    onEdit(variable.id);
  };

  const handleChangeImage = (optionValue: string, image: string) => {
    dispatch(
      editVariable({
        optionValue,
        variableId: variable.id,
        value: image,
      })
    );
  };

  const imageSelects = option.values.map((optionValue) => {
    const imageValue = variable.values.find(
      (checkedValue) => checkedValue.optionValue === optionValue
    );

    const image = imageValue?.value ?? variable.defaultValue;

    return (
      <div key={optionValue} className="flex flex-col gap-1">
        <p className="text-xs text-gray-11">{optionValue}</p>
        <ImagePicker
          value={image}
          onValueChange={(newImage) => handleChangeImage(optionValue, newImage)}
        />
      </div>
    );
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <button
          onClick={handleEdit}
          type="button"
          className="rouned-sm flex h-9 flex-1 items-center border border-gray-6 bg-gray-2 px-3 text-xs"
        >
          {variable.name}
        </button>
        <button
          aria-label="Delete variable"
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-sm border border-gray-6 bg-gray-2"
          onClick={handleDeleteVariable}
        >
          <DeleteIcon />
        </button>
      </div>
      {imageSelects}
    </div>
  );
}

function ColorVariable({
  variable,
  option,
  onEdit,
}: {
  variable: Variables[number];
  option: Option;
  onEdit: (variableId: string) => void;
}) {
  const dispatch = useAppDispatch();

  const handleDeleteVariable = () => {
    dispatch(deleteVariable(variable.id));
  };

  const handleEdit = () => {
    onEdit(variable.id);
  };

  const handleChangeColor = (optionValue: string, color: string) => {
    // Don't allow CSS variables as the color value
    const isValid = colord(color).isValid();

    if (!isValid) return;

    dispatch(
      editVariable({
        optionValue,
        variableId: variable.id,
        value: color,
      })
    );
  };

  const colorPickers = option.values.map((optionValue) => {
    const colorValue = variable.values.find(
      (checkedValue) => checkedValue.optionValue === optionValue
    );

    const color = colorValue?.value ?? variable.defaultValue;

    return (
      <div key={optionValue} className="flex flex-col gap-1">
        <p className="text-xs text-gray-11">{optionValue}</p>
        <ColorPicker
          value={color}
          onValueChange={(newColor) => handleChangeColor(optionValue, newColor)}
        />
      </div>
    );
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <button
          onClick={handleEdit}
          type="button"
          className="rouned-sm flex h-9 flex-1 items-center border border-gray-6 bg-gray-2 px-3 text-xs"
        >
          {variable.name}
        </button>
        <button
          aria-label="Delete variable"
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-sm border border-gray-6 bg-gray-2"
          onClick={handleDeleteVariable}
        >
          <DeleteIcon />
        </button>
      </div>
      {colorPickers}
    </div>
  );
}

function TextVariable({
  variable,
  option,
  onEdit,
}: {
  variable: Variables[number];
  option: Option;
  onEdit: (variableId: string) => void;
}) {
  const dispatch = useAppDispatch();

  const handleDeleteVariable = () => {
    dispatch(deleteVariable(variable.id));
  };

  const handleChangeText = (value: string, text: string) => {
    dispatch(
      editVariable({ optionValue: value, variableId: variable.id, value: text })
    );
  };

  const handleEdit = () => {
    onEdit(variable.id);
  };

  const textInputs = option.values.map((optionValue) => {
    const textValue = variable.values.find(
      (checkedValue) => checkedValue.optionValue === optionValue
    );

    const text = textValue?.value ?? variable.defaultValue;

    return (
      <div key={optionValue} className="flex flex-col gap-1">
        <p className="text-xs text-gray-11">{optionValue}</p>
        <input
          value={text}
          type="text"
          onChange={(event) =>
            handleChangeText(optionValue, event.target.value)
          }
          className="h-8 rounded-sm border border-gray-6 bg-gray-2 px-3 text-xs text-gray-12"
        />
      </div>
    );
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <button
          type="button"
          onClick={handleEdit}
          className="flex h-9 flex-1 items-center rounded-sm border border-gray-6 bg-gray-2 px-3 text-xs"
        >
          {variable.name}
        </button>
        <button
          aria-label="Delete variable"
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-sm border border-gray-6 bg-gray-2"
          onClick={handleDeleteVariable}
        >
          <DeleteIcon />
        </button>
      </div>
      {textInputs}
    </div>
  );
}

export function Variable({ onEdit }: { onEdit: (variableId: string) => void }) {
  const variables = useAppSelector(selectVariables);
  const optionName = useAppSelector(selectOptionName);
  const productId = useAppSelector(selectProductId);

  const { data: product } = useProduct(productId);

  if (variables.length === 0)
    return <p className="text-xs text-gray-11">No variables found</p>;

  const matchingOption = product?.options.find(
    (currentOption) => currentOption.name === optionName
  );

  if (matchingOption === undefined)
    return (
      <p className="text-xs text-gray-11">
        Select an option before adding variables
      </p>
    );

  return variables.map((variable) => {
    switch (variable.type) {
      case "color": {
        return (
          <ColorVariable
            onEdit={onEdit}
            key={variable.id}
            variable={variable}
            option={matchingOption}
          />
        );
      }
      case "text": {
        return (
          <TextVariable
            onEdit={onEdit}
            key={variable.id}
            option={matchingOption}
            variable={variable}
          />
        );
      }
      case "image": {
        return (
          <ImageVariable
            onEdit={onEdit}
            key={variable.id}
            option={matchingOption}
            variable={variable}
          />
        );
      }
    }
  });
}
