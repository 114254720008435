import { Type, Static } from "@sinclair/typebox";

export const ShopInput = Type.Object({
  shopifyDomain: Type.String(),
  accessToken: Type.String(),
  accessTokenScopes: Type.String(),
  email: Type.String(),
  isInstalled: Type.Boolean(),
  images: Type.Array(Type.String()),
  shopOwner: Type.String(),
  publishedShopifyThemeId: Type.Optional(Type.String()),
  shopifyStorefrontPassword: Type.Optional(Type.String()),
  createdAt: Type.String(),
  headerDesktopImage: Type.Optional(Type.String()),
  headerTabletImage: Type.Optional(Type.String()),
  headerMobileImage: Type.Optional(Type.String()),
  footerDesktopImage: Type.Optional(Type.String()),
  footerTabletImage: Type.Optional(Type.String()),
  footerMobileImage: Type.Optional(Type.String()),
});

export type ShopInputType = Static<typeof ShopInput>;
