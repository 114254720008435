import {
  FrameProvider,
  useFrame,
} from "@/features/editor/context/FrameContext";
import { theme } from "@/theme";
import isEqual from "lodash.isequal";
import { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { StyleSheetManager, ThemeProvider } from "styled-components";

export function Iframe({ children }: PropsWithChildren) {
  const [iFrameRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  const [iFrameInformation, setIFrameInformation] = useState<{
    window: Window;
    document: Document;
    target: HTMLElement;
  } | null>(null);

  useEffect(() => {
    // Keeps checking for the iFrame to be ready
    // in the future we can subscribe to the modal load?
    const interval = setInterval(() => {
      const iFrameDocument = iFrameRef?.contentWindow?.document;
      const iFrameWindow = iFrameRef?.contentWindow;
      const target = iFrameDocument?.getElementById("mountHere");

      if (!iFrameDocument || !iFrameWindow || !target) return;

      const value = {
        document: iFrameDocument,
        window: iFrameWindow,
        target,
      };

      setIFrameInformation((previous) =>
        isEqual(previous, value) ? previous : value
      );
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [iFrameRef?.contentWindow, iFrameRef?.contentWindow?.document]);

  return (
    <iframe
      className="h-screen w-screen"
      title="editor"
      id="editor-iframe"
      ref={setIframeRef}
      style={{ overflow: "hidden" }}
      srcDoc={`<!DOCTYPE html><html><head><link href="/global.css" rel="stylesheet" /><script type="text/javascript" src="/webcomponents.js"></script><script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js"></script></head><body id="mountHere"></body></html>`}
    >
      {iFrameInformation !== null &&
        createPortal(
          <FrameProvider
            document={iFrameInformation.document}
            window={iFrameInformation.window}
          >
            {children}
          </FrameProvider>,
          iFrameInformation.target
        )}
    </iframe>
  );
}

const useLoadTailwindStyles = () => {
  const { document: iFrameDocument } = useFrame();

  useEffect(() => {
    const { head } = document;

    // In development tailwind adds a style tag to the head of the document and in production it adds a link tag
    head.querySelectorAll("style").forEach((style) => {
      const duplicatedNode = style.cloneNode(true);
      iFrameDocument?.head.appendChild(duplicatedNode);
    });

    head.querySelectorAll("link").forEach((link) => {
      const duplicatedNode = link.cloneNode(true);
      iFrameDocument?.head.appendChild(duplicatedNode);
    });
  }, [iFrameDocument]);
};

export function IFrameContent({ children }: PropsWithChildren) {
  const { document: iFrameDocument, window: iFrameWindow } = useFrame();

  useLoadTailwindStyles();

  if (!iFrameDocument || !iFrameWindow) return null;

  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager target={iFrameWindow.document.head}>
        {children}
      </StyleSheetManager>
    </ThemeProvider>
  );
}
