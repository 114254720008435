import { Layer } from "@folds/shared/types";

export const getTargetLayerId = (layers: Layer[]) => {
  const rootLayerParentIds = layers
    .filter((layer) => {
      const isAChildLayer = layers.some(
        (layerToCopy) => layerToCopy.id === layer.parentId
      );

      if (isAChildLayer) return false;

      return true;
    })
    .map((layer) => layer.parentId);

  const isEveryParentIdEqual = rootLayerParentIds.every(
    (id) => id === rootLayerParentIds[0]
  );

  if (!isEveryParentIdEqual) return null;

  return rootLayerParentIds[0];
};
