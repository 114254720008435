import {
  selectLiquid,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import {
  useAppDispatch,
  useAppSelector,
  useDebounceHistory,
} from "@core/hooks";

export function LiquidSettings() {
  const liquid = useAppSelector(selectLiquid);
  const dispatch = useAppDispatch();

  const debounceHistory = useDebounceHistory();

  const handleChange = debounceHistory(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(setSelectedLayerProperties({ liquid: event.target.value }));
    }
  );

  return (
    <div className="flex flex-col gap-2 border-t border-gray-6 p-3">
      <h3 className="text-sm font-medium">Liquid</h3>
      <textarea
        onChange={handleChange}
        value={liquid}
        className="w-full border border-gray-6 p-3 text-sm"
        placeholder="Write some code here..."
      />
    </div>
  );
}
