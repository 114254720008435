import { Position } from "@folds/shared/types";

export const calculateSelectionIndicator = (
  initialPosition: Position,
  currentPosition: Position
) => {
  const top = Math.min(initialPosition.top, currentPosition.top);
  const left = Math.min(initialPosition.left, currentPosition.left);
  const width = Math.abs(initialPosition.left - currentPosition.left);
  const height = Math.abs(initialPosition.top - currentPosition.top);

  return { top, left, width, height };
};
