import { useModalWindow } from "@core/modalStore";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "@core/hooks";
import { checkIfIsIntegrationTest } from "@/utils/checkIfIsIntegrationTest";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";

const useIsModalLoaded = () => {
  const modalWindow = useModalWindow();
  const dispatch = useAppDispatch();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleModalLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    modalWindow?.addEventListener("load", handleModalLoad);

    return () => {
      modalWindow?.removeEventListener("load", handleModalLoad);
      setIsLoaded(false);
    };
  }, [dispatch, handleModalLoad, modalWindow]);

  return isLoaded;
};

/**
 * Wait for the CSS to be loaded into the modal before rendering the content. Fixes the flash of unstyled content.
 * Fixes: https://linear.app/folds/issue/FOL-561/fix-flash-of-unstyled-content
 */
export function WaitForModalToLoad({
  children,
}: {
  children: React.ReactNode;
}) {
  const isModalLoaded = useIsModalLoaded();
  const isIntegrationTest = checkIfIsIntegrationTest();
  const isInsidePlayground = useIsInsidePlayground();

  // We use display instead of visibility, because if we used visibility we would have to wait all the images to load
  // We just want to wait for the css to be loaded
  const style: React.CSSProperties = {
    ...(!isModalLoaded && { display: "none" }),
  };

  const loadingTextStyle: React.CSSProperties = {
    marginLeft: 10,
    marginTop: 10,
    ...(isModalLoaded && { display: "none" }),
  };

  if (isIntegrationTest || isInsidePlayground) {
    return children;
  }

  return (
    <div>
      <p style={loadingTextStyle}>
        Loading... <br />
        It might take a few seconds to load the page if you have a slower
        internet connection
      </p>
      <div style={style}>{children}</div>
    </div>
  );
}
