import {
  usePageInformation,
  useUpdatePageInformation,
} from "@/features/editor/api/getPageInformation";
import { PageInformationProductSelector } from "@/features/editor/components/PageInformation/PageInformationProductSelector";
import { PageInformationTitle } from "@/features/editor/components/PageInformation/PageInformationTitle";
import { usePageInformationCurrentValue } from "@/features/editor/components/PageInformation/usePageInformationCurrentValue";
import {
  selectSelectedAccordionItemId,
  selectSelectedCarouselId,
  selectShouldDisplayGroupLayersAction,
  selectShouldDisplayUngroupLayersAction,
} from "@core/features/editor/editorSlice";
import { useAppSelector } from "@core/hooks";
import * as Popover from "@radix-ui/react-popover";
import { Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { useState } from "react";
import { toast } from "@core/toast";
import { ShopifyPage, UpdatePageInformationBody } from "@folds/shared";
import { useShopifyPage } from "@/features/editor/api/getShopifyPage";
import { twMerge } from "tailwind-merge";
import { useUnpublishPage } from "@/features/editor/api/unpublishPage";
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  PopoverContent,
  PopoverPortal,
} from "@lib/radix";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import { PageSelect } from "@/features/sidebar/components/PageSelect";
import { PublishedPageLink } from "@/features/editor/components/PageInformation/PublishedPageLink";

function ChevronDown() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z"
        fill="#687076"
      />
    </svg>
  );
}

const useSelectedPageTitle = (pageId: ShopifyPage["id"] | null) => {
  const { data: page, isLoading, error } = useShopifyPage(pageId);

  if (pageId === null) return <p className="truncate">Select a page</p>;

  if (isLoading) {
    return <p className="truncate">Loading...</p>;
  }

  if (error !== undefined)
    return <p className="truncate">There was an error</p>;

  if (page === undefined) return <p className="truncate">Page not found</p>;

  return <p className="truncate">{page.title}</p>;
};

function PageInformationPageSelector({
  pageId,
  onSelect,
  isPublished,
}: {
  pageId: ShopifyPage["id"] | null;
  onSelect: (id: ShopifyPage["id"]) => void;
  isPublished: boolean;
}) {
  const [isPopoverExpanded, setIsPopoverExpanded] = useState(false);

  const handleSelect = (selectedPageId: ShopifyPage["id"]) => {
    onSelect(selectedPageId);
    setIsPopoverExpanded(false);
  };

  const pageTitle = useSelectedPageTitle(pageId);

  return (
    <Popover.Root open={isPopoverExpanded} onOpenChange={setIsPopoverExpanded}>
      <div className="flex flex-col gap-1">
        <p className="text-xs text-gray-11">Page</p>
        <Popover.Trigger
          className={twMerge(
            "h-9 w-full rounded-sm border-r-8 border-transparent px-3 text-left outline outline-1 -outline-offset-1 outline-gray-6",
            isPublished && "pointer-events-none cursor-not-allowed text-gray-11"
          )}
        >
          {pageTitle}
        </Popover.Trigger>
      </div>
      <PopoverPortal>
        <PopoverContent sideOffset={8}>
          <PageSelect onSelect={handleSelect} />
        </PopoverContent>
      </PopoverPortal>
    </Popover.Root>
  );
}

function UnpublishPage() {
  const unpublishPage = useUnpublishPage();

  return (
    <Dialog.Root>
      <Dialog.Trigger type="button" asChild>
        <button
          type="button"
          className="h-9 rounded bg-gray-12 py-2 text-white"
        >
          Unpublish
        </button>
      </Dialog.Trigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <div className="flex justify-between">
            <Dialog.Title className="text-lg font-medium">
              Unpublish page
            </Dialog.Title>
            <Dialog.Close>
              <CloseDialogIcon />
            </Dialog.Close>
          </div>
          <p className="text-base">
            Are you sure you want to unpublish this page?
          </p>
          <div className="flex gap-3">
            <Dialog.Close
              type="button"
              className="flex-1 rounded border border-gray-6 bg-gray-2 py-3 text-sm"
            >
              Cancel
            </Dialog.Close>
            <Dialog.Close
              type="button"
              onClick={unpublishPage}
              className="flex-1 rounded border border-gray-6 bg-red-9 py-3 text-sm text-white"
            >
              Unpublish
            </Dialog.Close>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog.Root>
  );
}

const pageTypeSchema = TypeCompiler.Compile(
  Type.Union([
    Type.Literal("home"),
    Type.Literal("standalone"),
    Type.Literal("product"),
    Type.Literal("not-found"),
  ])
);

export function PageInformation() {
  const { data: pageInformation, isLoading, error } = usePageInformation();
  const updatePageInformation = useUpdatePageInformation();

  const [isPopoverExpanded, setIsPopoverExpanded] = useState(false);

  const {
    pageType,
    productId,
    setPageType,
    setProductId,
    setStandalonePageId,
    standalonePageId,
  } = usePageInformationCurrentValue(pageInformation);

  const selectedAccordionItemId = useAppSelector(selectSelectedAccordionItemId);
  const selectedSliderId = useAppSelector(selectSelectedCarouselId);

  const shouldDisplayGroupLayersAction = useAppSelector(
    selectShouldDisplayGroupLayersAction
  );
  const shouldDisplayUngroupLayersAction = useAppSelector(
    selectShouldDisplayUngroupLayersAction
  );

  // Should display the add accordion item button instead
  if (selectedAccordionItemId !== null) return null;

  // Should display the add slide button instead
  if (selectedSliderId !== null) return null;

  if (shouldDisplayGroupLayersAction || shouldDisplayUngroupLayersAction)
    return null;

  if (isLoading) {
    return (
      <button
        type="button"
        onMouseDown={(event) => event.stopPropagation()}
        className="pointer-events-auto h-11 w-64 rounded border border-gray-6 bg-gray-2 px-4 text-left text-xs text-gray-12"
      >
        Loading...
      </button>
    );
  }

  if (error !== undefined || pageInformation === undefined) {
    return null;
  }

  const isPublished = pageInformation.publishedAt !== null;

  const handleSaveChanges = async () => {
    if (isPublished || pageType === null) return;

    setIsPopoverExpanded(false);

    switch (pageType) {
      case "home": {
        const updatedData: UpdatePageInformationBody = {
          type: "home",
        };

        await updatePageInformation(updatedData);

        break;
      }
      case "product": {
        if (productId === null) return;

        const updatedData: UpdatePageInformationBody = {
          type: "product",
          id: productId,
        };

        await updatePageInformation(updatedData);

        break;
      }
      case "standalone": {
        if (standalonePageId === null) return;

        const updatedData: UpdatePageInformationBody = {
          type: "standalone",
          id: standalonePageId,
        };

        await updatePageInformation(updatedData);

        break;
      }
      case "not-found": {
        const updatedData: UpdatePageInformationBody = {
          type: "not-found",
        };

        await updatePageInformation(updatedData);
      }
    }
  };

  const handlePopoverOpenChange = (open: boolean) => {
    if (open === false) {
      if (pageInformation.type === "standalone") {
        setStandalonePageId(pageInformation.shopifyId);
      }

      if (pageInformation.type === "product") {
        setProductId(pageInformation.shopifyId);
      }

      setPageType(pageInformation.type);

      setIsPopoverExpanded(false);

      return;
    }

    setIsPopoverExpanded(true);
  };

  const handleSelectPageType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    try {
      const parsedValue = pageTypeSchema.Decode(event.target.value);

      setPageType(parsedValue);
    } catch {
      // TODO: Add sentry logging
      toast.error("Failed to update page type");
    }
  };

  return (
    <Popover.Root
      open={isPopoverExpanded}
      onOpenChange={handlePopoverOpenChange}
    >
      <div className="pointer-events-auto flex gap-2">
        <Popover.Trigger
          type="button"
          onMouseDown={(event) => event.stopPropagation()}
          className="flex h-11 w-64 items-center justify-between rounded border border-gray-6 bg-gray-2 px-4 text-left text-xs text-gray-12"
        >
          <PageInformationTitle pageInformation={pageInformation} />
          <ChevronDown />
        </Popover.Trigger>
        <PublishedPageLink />
      </div>
      <PopoverPortal>
        <PopoverContent
          sideOffset={4}
          className="flex w-64 flex-col gap-4 rounded-md border border-gray-6 bg-gray-1 p-4 text-xs"
          align="start"
        >
          {isPublished === true && (
            <div className="flex flex-col gap-2">
              <p className="border border-gray-6 bg-gray-2 p-2 font-medium">
                Please unpublish this page before making changes to the URL
              </p>
              <UnpublishPage />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <p className="text-xs text-gray-11">Type</p>
            <select
              className={twMerge(
                "h-9 w-full rounded-sm border-r-8 border-transparent px-3 outline outline-1 -outline-offset-1 outline-gray-6",
                isPublished && "pointer-events-none text-gray-11"
              )}
              value={pageType ?? undefined}
              onChange={handleSelectPageType}
            >
              <option value="home">Home page</option>
              <option value="standalone">Standalone page</option>
              <option value="product">Product page</option>
              <option value="not-found">Not found / 404 page</option>
            </select>
          </div>
          {pageType === "product" && (
            <PageInformationProductSelector
              onSelect={setProductId}
              productId={productId}
              isPublished={isPublished}
            />
          )}
          {pageType === "standalone" && (
            <PageInformationPageSelector
              pageId={standalonePageId}
              onSelect={setStandalonePageId}
              isPublished={isPublished}
            />
          )}

          <button
            className={twMerge(
              "h-9 rounded bg-gray-12 text-center text-xs text-white",
              isPublished && "cursor-not-allowed bg-gray-11"
            )}
            type="button"
            onClick={handleSaveChanges}
          >
            Save changes
          </button>
        </PopoverContent>
      </PopoverPortal>
    </Popover.Root>
  );
}
