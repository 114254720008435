import { useCreatePage } from "@/features/dashboard/hooks/useCreatePage";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseDialogIcon } from "@common/DeleteConfirmationDialog";
import { Static, Type } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { toast } from "@core/toast";
import { twMerge } from "tailwind-merge";
import type { PageState } from "@/features/dashboard/components/CreatePageDialog/CreatePageDialog";

const pageTypeSchemea = Type.Union([
  Type.Literal("home"),
  Type.Literal("standalone"),
  Type.Literal("product"),
  Type.Literal("not-found"),
]);

type PageType = Static<typeof pageTypeSchemea>;

const compiledPageType = TypeCompiler.Compile(pageTypeSchemea);

const getActionTitle = (pageType: PageType) => {
  switch (pageType) {
    case "home": {
      return "Create page";
    }
    case "product": {
      return "Continue";
    }
    case "standalone": {
      return "Continue";
    }
    case "not-found": {
      return "Create page";
    }
  }
};

export function SelectPageInformation({
  pageName,
  setPageName,
  setPageType,
  pageType,
  onPageCreated,
  setPageState,
}: {
  pageName: string;
  setPageName: (value: string) => void;
  pageType: PageType;
  setPageType: (value: PageType) => void;
  onPageCreated: (id: string) => void;
  setPageState: (state: PageState) => void;
}) {
  const createPage = useCreatePage(onPageCreated);

  const actionTitle = getActionTitle(pageType);

  const handleChagePageType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const parsedValue = compiledPageType.Decode(event.target.value);

      setPageType(parsedValue);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleContinue = async () => {
    if (pageName.length === 0) return;

    switch (pageType) {
      case "home": {
        await createPage({ name: pageName, type: "home", shopifyId: null });
        break;
      }
      case "product": {
        setPageState("select-product");
        break;
      }
      case "standalone": {
        setPageState("select-page");
        break;
      }
      case "not-found": {
        await createPage({
          name: pageName,
          type: "not-found",
          shopifyId: null,
        });
        break;
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <Dialog.Title className="text-lg font-medium">Create page</Dialog.Title>
        <Dialog.Close>
          <CloseDialogIcon />
        </Dialog.Close>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-12">
            Name{" "}
            <span className="text-gray-11">
              (this is for your reference only)
            </span>
          </label>
          <input
            type="text"
            value={pageName}
            onChange={(event) => setPageName(event.target.value)}
            className="h-11 rounded border border-gray-6 bg-gray-2 px-4 text-sm"
            placeholder="Enter page name"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm text-gray-12">Type</label>
          <select
            onChange={handleChagePageType}
            value={pageType}
            className="h-11 rounded border border-r-[16px] border-transparent bg-gray-2 pl-4 text-sm  outline outline-1 -outline-offset-1 outline-gray-6"
          >
            <option value="home">Home page</option>
            <option value="standalone">Standalone page</option>
            <option value="product">Product page</option>
            <option value="not-found">Not found / 404 page</option>
          </select>
        </div>
      </div>
      <div className="flex gap-4">
        <Dialog.Close
          type="button"
          className="flex-1 rounded-lg border border-gray-6 bg-gray-2 py-3"
        >
          Cancel
        </Dialog.Close>
        <button
          type="button"
          className={twMerge(
            "flex-1 rounded-lg border border-gray-6 bg-gray-12 py-3 text-white",
            pageName.length === 0 && "cursor-not-allowed bg-gray-9"
          )}
          onClick={handleContinue}
        >
          {actionTitle}
        </button>
      </div>
    </>
  );
}
