import { useState } from "react";
import { ProductSelect } from "@/features/sidebar/components/ProductSelect";
import * as Popover from "@radix-ui/react-popover";
import { useShopifyPage } from "@/features/editor/api/getShopifyPage";
import { useSelectedProductTitle } from "@/features/sidebar/components/OptionSelector";
import { ShopifyPage, ShopifyProduct } from "@folds/shared";
import { twMerge } from "tailwind-merge";
import { PopoverContent, PopoverPortal } from "@lib/radix";

export const useSelectedPageTitle = (pageId: ShopifyPage["id"] | null) => {
  const { data: page, isLoading, error } = useShopifyPage(pageId);

  if (pageId === null) return <p className="truncate">Select a page</p>;

  if (isLoading === true) {
    return null;
  }

  if (error !== undefined)
    return <p className="truncate">There was an error</p>;

  if (page === undefined) return <p className="truncate">Page not found</p>;

  return <div className="flex flex-col truncate">/pages/{page.handle}</div>;
};

export function PageInformationProductSelector({
  onSelect,
  productId,
  isPublished,
}: {
  onSelect: (productId: ShopifyProduct["id"]) => void;
  productId: ShopifyProduct["id"] | null;
  isPublished: boolean;
}) {
  const [isProductSelectPopoverExpanded, setIsProductSelectPopoverExpanded] =
    useState(false);

  const handleSelectProduct = (data: {
    productId: ShopifyProduct["id"];
    productHandle: string;
  }) => {
    if (isPublished) return;

    onSelect(data.productId);
    setIsProductSelectPopoverExpanded(false);
  };

  const productTitle = useSelectedProductTitle(productId);

  return (
    <Popover.Root
      open={isProductSelectPopoverExpanded}
      onOpenChange={setIsProductSelectPopoverExpanded}
    >
      <div className="flex flex-col gap-1">
        <p className="text-xs text-gray-11">Product</p>
        <Popover.Trigger
          className={twMerge(
            "h-9 w-full rounded-sm border-r-8 border-transparent px-3 text-left outline outline-1 -outline-offset-1 outline-gray-6",
            isPublished && "pointer-events-none cursor-not-allowed text-gray-11"
          )}
        >
          {productTitle}
        </Popover.Trigger>
      </div>
      <PopoverPortal>
        <PopoverContent sideOffset={8}>
          <ProductSelect onSelect={handleSelectProduct} />
        </PopoverContent>
      </PopoverPortal>
    </Popover.Root>
  );
}
