import React from "react";

const ShopifyModalContext = React.createContext<UIModalElement | null>(null);

export function ShopifyModalProvider({
  children,
  modal,
}: {
  children: React.ReactNode;
  modal: UIModalElement | null;
}) {
  return (
    <ShopifyModalContext.Provider value={modal}>
      {children}
    </ShopifyModalContext.Provider>
  );
}

export const useShopifyModal = () => {
  const modal = React.useContext(ShopifyModalContext);

  return modal;
};
