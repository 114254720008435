import clsx from "clsx";
import * as i from "../Typography.icons";

export function SidebarMarks({
  onToggleLineThrough,
  onToggleItalic,
  onToggleUnderline,
  isItalicized,
  isLineThrough,
  isUnderlined,
}: {
  onToggleLineThrough: () => void;
  onToggleItalic: () => void;
  onToggleUnderline: () => void;
  isItalicized: boolean;
  isLineThrough: boolean;
  isUnderlined: boolean;
}) {
  return (
    <div className="flex h-8 gap-1">
      <button
        type="button"
        aria-label="Italicize text"
        onClick={onToggleItalic}
        className={clsx(
          "flex flex-1 items-center justify-center rounded-sm border border-gray-6 bg-gray-1 fill-gray-11 p-1",
          isItalicized && "bg-gray-5 fill-gray-12"
        )}
      >
        <i.Italic />
      </button>
      <button
        onClick={onToggleUnderline}
        aria-label="Underline text"
        type="button"
        className={clsx(
          "flex flex-1 items-center justify-center rounded-sm border border-gray-6 bg-gray-1 fill-gray-11 p-1",
          isUnderlined && "bg-gray-5 fill-gray-12"
        )}
      >
        <i.Underline />
      </button>
      <button
        type="button"
        aria-label="Strikethrough text"
        onClick={onToggleLineThrough}
        className={clsx(
          "flex flex-1 items-center justify-center rounded-sm border border-gray-6 bg-gray-1 fill-gray-11 p-1",
          isLineThrough && "bg-gray-5 fill-gray-12"
        )}
      >
        <i.LineThrough />
      </button>
    </div>
  );
}
