import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

/**
 * Changes the cursor based on if the text is editable or not
 * The cursor is set to text when the text is editable and default when it is not
 */
export function SyncContentEditableAttributes() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const contentEditable = editor.getRootElement();

    if (contentEditable) {
      contentEditable.style.cursor = "default";
      contentEditable.dataset.isEditable = "false";
    }

    const cleanup = editor.registerEditableListener((editable) => {
      if (contentEditable === null) return;

      if (editable === true) {
        contentEditable.style.cursor = "text";
        contentEditable.dataset.isEditable = "true";
        return;
      }

      contentEditable.style.cursor = "default";
      contentEditable.dataset.isEditable = "false";
    });

    return cleanup;
  }, [editor]);

  return null;
}
