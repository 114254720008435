import { useFrame } from "@/features/editor/context/FrameContext";
import {
  setIsViewportTransforming,
  setViewport,
} from "@core/features/editor/editorSlice";
import { store } from "@core/store";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const checkIfEventIsComingFromIframe = (
  event: WheelEvent,
  iframeDocument: Document | undefined
) => {
  if (iframeDocument === undefined) return false;

  // The PopoverRoot from adds a cover so we can close the popover when clicking outside of it
  // However, this prevents the event from reaching the iframe so we need to check if the event is coming from the iframe cover
  if ((event.target as HTMLElement).id === "iframe-cover") return true;

  return iframeDocument.contains(event.target as HTMLElement);
};

/**
 * Zoom and pan around the canvas
 */
export const useTransformViewport = () => {
  const dispatch = useDispatch();
  const { document: iframeDocument, window: iframeWindow } = useFrame();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScrollStop = useCallback(
    debounce(() => {
      dispatch(setIsViewportTransforming(false));
    }, 100),
    []
  );

  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isCtrlKeyPressed = useRef<boolean | null>(null);

  // Zoom in if pressing meta key or ctrl key else pan around
  const onScroll = useCallback(
    (event: WheelEvent) => {
      const isEventComingFromIframe = checkIfEventIsComingFromIframe(
        event,
        iframeDocument
      );

      if (!isEventComingFromIframe) return;

      event.preventDefault();

      if (isCtrlKeyPressed.current === null) {
        isCtrlKeyPressed.current = event.metaKey || event.ctrlKey;
      }

      if (scrollTimeout.current !== null) {
        clearTimeout(scrollTimeout.current);
      }

      // Stops the viewport from panning when releasing the ctrl key until the timeout is finished
      const newTimeout = setTimeout(() => {
        isCtrlKeyPressed.current = null;
      }, 25);

      scrollTimeout.current = newTimeout;

      const {
        editor: {
          isViewportTransforming,
          scale: viewportScale,
          translateX: viewportTranslateX,
          translateY: viewportTranslateY,
          isViewportLocked,
          canvasMode,
        },
      } = store.getState();

      // Fixes: https://linear.app/folds/issue/FOL-325/fix-scrolling-when-panning-causes-weird-behavior
      if (canvasMode !== "Select") return;

      if (!isViewportTransforming) {
        dispatch(setIsViewportTransforming(true));
      }

      onScrollStop();

      // Stop trackpad from scrolling to previous page but don't stop scrolling up and down
      if (event.deltaX !== 0) {
        event.preventDefault();
      }

      // If locked then don't zoom or pan
      if (isViewportLocked) return;

      const { deltaX, deltaY } = event;

      const isVerticalScroll = Math.abs(deltaY) > Math.abs(deltaX);

      if (isCtrlKeyPressed.current) {
        // Zoom into mouse position
        if (isVerticalScroll) {
          const SENSITIVITY = 0.01;

          let scale = viewportScale - deltaY * SENSITIVITY;

          if (scale > 5) {
            scale = 5;
          }
          if (scale < 0.1) {
            scale = 0.1;
          }

          // Zoom into mouse position
          const ratio = 1 - scale / viewportScale;

          const translateX =
            viewportTranslateX + (event.clientX - viewportTranslateX) * ratio;

          const translateY =
            viewportTranslateY + (event.clientY - viewportTranslateY) * ratio;

          setIsViewportTransforming(false);

          dispatch(
            setViewport({
              scale,
              translateX,
              translateY,
            })
          );
        }
      } else {
        const translateX = viewportTranslateX - deltaX;
        const translateY = viewportTranslateY - deltaY;

        dispatch(setIsViewportTransforming(true));

        dispatch(
          setViewport({
            scale: viewportScale,
            translateX,
            translateY,
          })
        );
      }
    },
    [dispatch, iframeDocument, onScrollStop]
  );

  useEffect(() => {
    window.addEventListener("wheel", onScroll, { passive: false });

    iframeWindow?.addEventListener("wheel", onScroll, {
      passive: false,
    });

    return () => {
      window.removeEventListener("wheel", onScroll);

      iframeWindow?.removeEventListener("wheel", onScroll);
    };
  }, [onScroll, iframeWindow]);
};
