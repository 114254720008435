import styled from "styled-components";

export const Handle = styled.div`
  position: absolute;
  pointer-events: auto;
`;

export const CornerHandle = styled(Handle)`
  outline: 1px solid ${(props) => props.theme.colors.primaryContrast};
`;

export const HandleTopLeft = styled(CornerHandle)`
  background: white;
  cursor: nwse-resize;
  width: 6px;
  height: 6px;
  top: -3px;
  left: -3px;
`;

export const HandleBottomRight = styled(CornerHandle)`
  background: white;
  cursor: nwse-resize;
  width: 6px;
  height: 6px;
  bottom: -3px;
  right: -3px;
`;

export const HandleBottomLeft = styled(CornerHandle)`
  background: white;
  cursor: nesw-resize;
  width: 6px;
  height: 6px;
  bottom: -3px;
  left: -3px;
`;

export const HandleTopRight = styled(CornerHandle)`
  background: white;
  cursor: nesw-resize;
  width: 6px;
  height: 6px;
  top: -3px;
  right: -3px;
`;

export const HandleTopSide = styled(Handle)<{ resizing: boolean }>`
  cursor: ${({ resizing }) => (resizing ? "ns-resize" : "default")};
  pointer-events: ${({ resizing }) => (resizing ? "auto" : "none")};
  top: -2.5px;
  left: 0;
  height: 5px;
  width: 100%;
`;

export const HandleRightSide = styled(Handle)<{ resizing: boolean }>`
  cursor: ${(props) => (props.resizing ? "ew-resize" : "default")};
  top: 0;
  right: -2.5px;
  height: 100%;
  width: 5px;
`;

export const HandleBottomSide = styled(Handle)<{
  resizing: boolean;
}>`
  cursor: ${({ resizing }) => (resizing ? "ns-resize" : "default")};
  pointer-events: ${({ resizing }) => (resizing ? "auto" : "none")};
  bottom: -2.5px;
  left: 0;
  height: 5px;
  width: 100%;
`;

export const HandleLeftSide = styled(Handle)<{ resizing: boolean }>`
  cursor: ${(props) => (props.resizing ? "ew-resize" : "default")};
  top: 0;
  left: -2.5px;
  height: 100%;
  width: 5px;
`;
