import { useShop } from "@/features/editor/api/getShop";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    Intercom?(
      command: "boot",
      options: {
        api_base: string;
        app_id: string;
        name: string;
        email: string;
        created_at: number;
        user_hash: string;
      }
    ): void;

    Intercom?(
      command: "update",
      options: {
        hide_default_launcher: boolean;
      }
    ): void;

    intercomSettings: {
      api_base: string;
      app_id: string;
      name: string;
      email: string;
      created_at: number;
      user_hash: string;
      custom_launcher_selector?: string;
    };
  }
}

export const getUnixTimestampFromDateString = (dateString: string) =>
  Math.floor(new Date(dateString).getTime() / 1000);

export const useLoadIntercom = () => {
  const isLoaded = useRef(false);
  const { data: shop } = useShop();

  useEffect(() => {
    if (isLoaded.current || shop === undefined) return;

    window.Intercom?.("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "mjl5ou8r",
      name: shop.shopOwner,
      email: shop.email,
      created_at: getUnixTimestampFromDateString(shop.createdAt),
      user_hash: shop.intercomUserHash,
    });

    isLoaded.current = true;
  }, [shop]);
};
