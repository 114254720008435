import { generateTypographyStyles, generateFillStyles } from './utils';
import { QuantityPickerLayer } from '../types';

export const generateQuantityPickerStyles = (
  layer: QuantityPickerLayer
): React.CSSProperties => {
  const fillStyles = generateFillStyles(layer);

  const {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderColor,
    borderLeftWidth,
    borderRightWidth,
    borderStyle,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    height,
    width,
    zIndex,
    left,
    top,
    visible,
  } = layer;

  return {
    all: 'initial',
    ...fillStyles,
    display: visible ? 'grid' : 'none',
    gridTemplateColumns: '1fr 1fr 1fr',
    boxSizing: 'border-box',
    zIndex,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    top,
    left,
    position: 'absolute',
    width,
    height,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderStyle,
    borderColor,
    transition: 'margin-top 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s',
  };
};

export const generateQuantityPickerIconStyles = (
  layer: QuantityPickerLayer
): React.CSSProperties => ({
  width: layer.iconSize,
  height: layer.iconSize,
  fill: layer.iconColor,
});

export const generateQuantityPickerInputStyles = (
  layer: QuantityPickerLayer
): React.CSSProperties => {
  const typographyStyles = generateTypographyStyles(layer);

  return {
    all: 'initial',
    ...typographyStyles,
    minWidth: 0,
    height: '100%',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 0,
  };
};

export const generateQuanityIconStyles = (): React.CSSProperties => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
