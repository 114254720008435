import { useProductPrice } from "@/features/sidebar/api/getProductPrice";
import { useEventConnector } from "@core/events";
import { generateTextStyles } from "@folds/shared/layers";
import { TextLayer, LayerId } from "@folds/shared/types";
import { CSSProperties, PropsWithChildren } from "react";

export function TextWrapper({
  style,
  children,
  id,
}: PropsWithChildren<{ style: CSSProperties; id: LayerId }>) {
  const { handleMouseDown, handleContextMenu, handleMouseOver } =
    useEventConnector(id);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <p
      style={style}
      id={id}
      className="select-none"
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenu}
      onMouseOver={handleMouseOver}
    >
      {children}
    </p>
  );
}

export function ProductPrice({ layer }: { layer: TextLayer }) {
  const {
    data: productPrice,
    error,
    isLoading,
  } = useProductPrice(layer.productId);

  const style = generateTextStyles(layer);

  if (layer.productId === null)
    return (
      <TextWrapper id={layer.id} style={style}>
        Select a product
      </TextWrapper>
    );

  if (isLoading)
    return (
      <TextWrapper id={layer.id} style={style}>
        Loading...
      </TextWrapper>
    );

  if (error !== undefined) {
    return (
      <TextWrapper id={layer.id} style={style}>
        There was an unexpected error
      </TextWrapper>
    );
  }

  if (typeof productPrice?.price !== "string")
    return <p style={style}>Cannot get product price</p>;

  return (
    <TextWrapper id={layer.id} style={style}>
      ${productPrice.price}
    </TextWrapper>
  );
}

export function CompareAtPrice({ layer }: { layer: TextLayer }) {
  const {
    data: productPrice,
    error,
    isLoading,
  } = useProductPrice(layer.productId);

  const style = generateTextStyles(layer);

  if (layer.productId === null)
    return (
      <TextWrapper id={layer.id} style={style}>
        Select a product
      </TextWrapper>
    );

  if (isLoading)
    return (
      <TextWrapper id={layer.id} style={style}>
        Loading...
      </TextWrapper>
    );

  if (error !== undefined) {
    return (
      <TextWrapper id={layer.id} style={style}>
        There was an unexpected error
      </TextWrapper>
    );
  }

  if (!productPrice)
    return (
      <TextWrapper id={layer.id} style={style}>
        Cannot get product price
      </TextWrapper>
    );

  if (typeof productPrice.compareAtPrice !== "string")
    return (
      <TextWrapper id={layer.id} style={style}>
        No compare at price available
      </TextWrapper>
    );

  return (
    <TextWrapper id={layer.id} style={style}>
      ${productPrice.compareAtPrice}
    </TextWrapper>
  );
}
