import { SidebarBorderRadiusSettings } from "@/features/sidebar/components/MainInformationSettings/BorderRadiusSettings";
import { SidebarSizingSettings } from "@/features/sidebar/components/MainInformationSettings/SizingSettings";
import { checkIfLayerTypeHasProperty } from "@core/utils";
import { Layer } from "@folds/shared/types";
import { SidebarPositionSettings } from "@/features/sidebar/components/MainInformationSettings/PositionSettings";
import { MainInformationGapSettings } from "@/features/sidebar/components/MainInformationSettings/MainInformationGapSettings";

const checkIfShouldDisplayBorderRadiusSettings = (type: Layer["type"]) => {
  const hasTopLeftRadius = checkIfLayerTypeHasProperty(
    type,
    "borderTopLeftRadius"
  );
  const hasBottomLeftRadius = checkIfLayerTypeHasProperty(
    type,
    "borderBottomLeftRadius"
  );
  const hasBottomRightRadius = checkIfLayerTypeHasProperty(
    type,
    "borderBottomRightRadius"
  );
  const hasTopRightRadius = checkIfLayerTypeHasProperty(
    type,
    "borderTopRightRadius"
  );

  return (
    hasTopLeftRadius &&
    hasBottomLeftRadius &&
    hasBottomRightRadius &&
    hasTopRightRadius
  );
};

export function MainInformationSettings({ type }: { type: Layer["type"] }) {
  const hasBorderRadiusProperties =
    checkIfShouldDisplayBorderRadiusSettings(type);

  return (
    <div className="flex flex-col gap-2 p-3">
      <SidebarPositionSettings />
      <SidebarSizingSettings type={type} />
      {hasBorderRadiusProperties && <SidebarBorderRadiusSettings />}
      <MainInformationGapSettings />
    </div>
  );
}
