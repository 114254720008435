import { App } from "@/App";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.css";
import "./styles/tailwind.css";

// The session replays don't currently work with the editor since the modal is loaded inside a different iframe
Sentry.init({
  dsn: "https://f47cdbfd2dc41f3a33a37634ef521c34@o4506862653341696.ingest.us.sentry.io/4506862657011712",
  integrations: [Sentry.browserTracingIntegration()],
  enabled: process.env.NODE_ENV === "production",
  tracesSampleRate: 0.05,
  profilesSampleRate: 0.05,
  tracePropagationTargets: ["projects.folds.app/api"],
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
