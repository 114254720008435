import { checkIfLayerTypeHasProperty } from "@core/utils";
import { Breakpoint, Layer, Layers } from "@folds/shared/types";
import { Draft } from "immer";

const assignLayerProperty = ({
  layer,
  key,
  value,
  breakpoint,
  layers,
}: {
  layer: Draft<Layer>;
  key: string;
  value: unknown;
  breakpoint: Breakpoint;
  layers: Layers;
}) => {
  if (
    key === "state" ||
    key === "text" ||
    key === "contentType" ||
    key === "tag" ||
    key === "productId" ||
    key === "optionName" ||
    key === "productHandle" ||
    key === "variableId" ||
    key === "content" ||
    key === "productUnavailableId" ||
    key === "productUnavailableHandle" ||
    key === "liquid" ||
    key === "zIndex" ||
    key === "action"
  ) {
    Object.assign(layer, { [key]: value });
    return;
  }

  const state = "state" in layer ? layer.state : null;

  if (breakpoint === "mobile" && "mobile" in layer) {
    switch (state) {
      case "hover": {
        if ("hover" in layer.mobile && layer.mobile.hover) {
          Object.assign(layer.mobile.hover, { [key]: value });
          break;
        }

        Object.assign(layer.mobile, { hover: { [key]: value } });

        break;
      }
      case "checked": {
        if ("checked" in layer.mobile && layer.mobile.checked) {
          Object.assign(layer.mobile.checked, { [key]: value });
          break;
        }

        Object.assign(layer.mobile, { hover: { [key]: value } });

        break;
      }
      case "parent-checked": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("checkedChildren" in parentLayer)) return;

        const { checkedChildren } = parentLayer.mobile;

        if (checkedChildren === undefined) {
          Object.assign(parentLayer.mobile, {
            checkedChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const checkedChildrenForId = checkedChildren[layer.id];

        if (checkedChildrenForId === undefined) {
          Object.assign(checkedChildren, { [key]: value });
          break;
        }

        Object.assign(checkedChildrenForId, {
          [key]: value,
        });

        break;
      }
      case "option-unavailable": {
        if (
          "optionUnavailable" in layer.tablet &&
          layer.tablet.optionUnavailable
        ) {
          Object.assign(layer.tablet.optionUnavailable, { [key]: value });
          break;
        }

        Object.assign(layer.tablet, { optionUnavailable: { [key]: value } });

        break;
      }
      case "product-unavailable": {
        if (
          "productUnavailable" in layer.mobile &&
          layer.mobile.productUnavailable
        ) {
          Object.assign(layer.mobile.productUnavailable, { [key]: value });
          break;
        }

        Object.assign(layer.mobile, { productUnavailable: { [key]: value } });

        break;
      }
      case "parent-hover": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("hoverChildren" in parentLayer)) break;

        const parentHoverChildren = parentLayer.mobile.hoverChildren;

        if (parentHoverChildren === undefined) {
          Object.assign(parentLayer.mobile, {
            hoverChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const parentHoverChildrenForId = parentHoverChildren[layer.id];

        if (parentHoverChildrenForId === undefined) {
          Object.assign(parentHoverChildren, { [key]: value });
          break;
        }

        Object.assign(parentHoverChildrenForId, {
          [key]: value,
        });

        break;
      }
      case "parent-option-unavailable": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("optionUnavailableChildren" in parentLayer))
          break;

        const { optionUnavailableChildren } = parentLayer.mobile;

        if (optionUnavailableChildren === undefined) {
          Object.assign(parentLayer.mobile, {
            optionUnavailableChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const optionUnavailableChildrenForId =
          optionUnavailableChildren[layer.id];

        if (optionUnavailableChildrenForId === undefined) {
          Object.assign(optionUnavailableChildren, {
            [key]: value,
          });
          break;
        }

        Object.assign(optionUnavailableChildrenForId, {
          [key]: value,
        });

        break;
      }
      case null: {
        Object.assign(layer.mobile, { [key]: value });
        break;
      }
    }

    return;
  }

  if (breakpoint === "tablet" && "tablet" in layer) {
    switch (state) {
      case "hover": {
        if ("hover" in layer.tablet && layer.tablet.hover) {
          Object.assign(layer.tablet.hover, { [key]: value });
          break;
        }

        Object.assign(layer.tablet, { hover: { [key]: value } });

        break;
      }
      case "checked": {
        if ("checked" in layer.tablet && layer.tablet.checked) {
          Object.assign(layer.tablet.checked, { [key]: value });
          break;
        }

        Object.assign(layer.tablet, { checked: { [key]: value } });

        break;
      }
      case "option-unavailable": {
        if (
          "optionUnavailable" in layer.tablet &&
          layer.tablet.optionUnavailable
        ) {
          Object.assign(layer.tablet.optionUnavailable, { [key]: value });
          break;
        }

        Object.assign(layer.tablet, { optionUnavailable: { [key]: value } });

        break;
      }
      case "product-unavailable": {
        if (
          "productUnavailable" in layer.tablet &&
          layer.tablet.productUnavailable
        ) {
          Object.assign(layer.tablet.productUnavailable, { [key]: value });
          break;
        }

        Object.assign(layer.tablet, { productUnavailable: { [key]: value } });

        break;
      }
      case "parent-hover": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("hoverChildren" in parentLayer)) return;

        const parentTabletHoverChildren = parentLayer.tablet.hoverChildren;

        if (parentTabletHoverChildren === undefined) {
          Object.assign(parentLayer.tablet, {
            hoverChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const parentHoverChildrenForId = parentTabletHoverChildren[layer.id];

        if (parentHoverChildrenForId === undefined) {
          Object.assign(parentTabletHoverChildren, { [key]: value });
          break;
        }

        Object.assign(parentHoverChildrenForId, {
          [key]: value,
        });

        break;
      }
      case "parent-checked": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("checkedChildren" in parentLayer)) return;

        const { checkedChildren } = parentLayer.tablet;

        if (checkedChildren === undefined) {
          Object.assign(parentLayer.tablet, {
            checkedChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const checkedChildrenForId = checkedChildren[layer.id];

        if (checkedChildrenForId === undefined) {
          Object.assign(checkedChildren, { [key]: value });
          break;
        }

        Object.assign(checkedChildrenForId, {
          [key]: value,
        });

        break;
      }
      case "parent-option-unavailable": {
        const parentLayer = layers[layer.parentId];

        if (!parentLayer || !("optionUnavailableChildren" in parentLayer))
          break;

        const { optionUnavailableChildren } = parentLayer.tablet;

        if (optionUnavailableChildren === undefined) {
          Object.assign(parentLayer.tablet, {
            optionUnavailableChildren: { [layer.id]: { [key]: value } },
          });
          break;
        }

        const optionUnavailableChildrenForId =
          optionUnavailableChildren[layer.id];

        if (optionUnavailableChildrenForId === undefined) {
          Object.assign(optionUnavailableChildren, {
            [key]: value,
          });
          break;
        }

        Object.assign(optionUnavailableChildrenForId, {
          [key]: value,
        });

        break;
      }
      case null: {
        Object.assign(layer.tablet, { [key]: value });
        break;
      }
    }

    return;
  }

  switch (state) {
    case null: {
      Object.assign(layer, { [key]: value });

      break;
    }
    case "product-unavailable": {
      if (!("productUnavailable" in layer)) break;

      Object.assign(layer.productUnavailable, { [key]: value });

      break;
    }
    case "hover": {
      if (!("hover" in layer)) break;

      Object.assign(layer.hover, { [key]: value });

      break;
    }
    case "checked": {
      if (!("checked" in layer)) break;

      Object.assign(layer.checked, { [key]: value });

      break;
    }
    case "parent-checked": {
      if (typeof layer.parentId !== "string") return;

      const parentLayer = layers[layer.parentId];

      if (!parentLayer || !("checkedChildren" in parentLayer)) break;

      const checkedChildrenForId = parentLayer.checkedChildren[layer.id];

      if (checkedChildrenForId !== undefined) {
        Object.assign(checkedChildrenForId, { [key]: value });
        break;
      }

      Object.assign(parentLayer.checkedChildren, {
        [layer.id]: { [key]: value },
      });

      break;
    }
    case "option-unavailable": {
      if (!("optionUnavailable" in layer)) break;

      Object.assign(layer.optionUnavailable, { [key]: value });

      break;
    }
    case "parent-hover": {
      if (typeof layer.parentId !== "string") return;

      const parentLayer = layers[layer.parentId];

      if (!parentLayer || !("hoverChildren" in parentLayer)) break;

      const parentHoverChildrenForId = parentLayer.hoverChildren[layer.id];

      if (parentHoverChildrenForId !== undefined) {
        Object.assign(parentHoverChildrenForId, { [key]: value });
        break;
      }

      Object.assign(parentLayer.hoverChildren, {
        [layer.id]: { [key]: value },
      });

      break;
    }
    case "parent-option-unavailable": {
      if (typeof layer.parentId !== "string") return;

      const parentLayer = layers[layer.parentId];

      if (!parentLayer || !("optionUnavailableChildren" in parentLayer)) break;

      const optionUnavailableChildrenForId =
        parentLayer.optionUnavailableChildren[layer.id];

      if (optionUnavailableChildrenForId !== undefined) {
        Object.assign(optionUnavailableChildrenForId, { [key]: value });
        break;
      }

      Object.assign(parentLayer.optionUnavailableChildren, {
        [layer.id]: { [key]: value },
      });
    }
  }
};

/**
 * When the tablet / desktop breakpoint has never been moved, allow lower breakpoints to change the position of higher breakpoints.
 */
const assignHasEditedPositionProperties = ({
  layer,
  key,
  breakpoint,
  assignHasEditedPosition,
}: {
  layer: Draft<Layer>;
  key: string;
  breakpoint: Breakpoint;
  assignHasEditedPosition: boolean;
}) => {
  if (key !== "top" && key !== "left") return;

  if (assignHasEditedPosition && breakpoint === "desktop") {
    Object.assign(layer, { hasEditedDesktopPosition: true });
  }

  if (assignHasEditedPosition && breakpoint === "tablet") {
    Object.assign(layer, { hasEditedTabletPosition: true });
  }

  if (assignHasEditedPosition && breakpoint === "mobile") {
    Object.assign(layer, { hasEditedMobilePosition: true });
  }
};

export const assignLayerProperties = ({
  layer,
  key,
  value,
  breakpoint,
  layers,
  assignHasEditedPosition = true,
}: {
  layer: Draft<Layer>;
  value: unknown;
  key: string;
  breakpoint: Breakpoint;
  layers: Layers;
  assignHasEditedPosition?: boolean;
}) => {
  const hasProperty = checkIfLayerTypeHasProperty(layer.type, key);
  if (hasProperty === false) return;

  assignHasEditedPositionProperties({
    breakpoint,
    key,
    layer,
    assignHasEditedPosition,
  });

  assignLayerProperty({ layer, key, value, breakpoint, layers });
};
