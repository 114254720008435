import { Layer } from "@folds/shared/types";

export const getLayerName = (layer: Layer) => {
  switch (layer.type) {
    case "rectangle": {
      return "Rectangle";
    }
    case "text": {
      return "Text";
    }
    case "block": {
      return "Block";
    }
    case "button": {
      return "Button";
    }
    case "breakpoint": {
      return "Breakpoint";
    }
    case "page": {
      return "Page";
    }
    case "product-image-carousel-featured-image": {
      return "Featured image";
    }
    case "product-image-carousel-thumbnail": {
      return "Thumbnail";
    }
    case "product-image-carousel-thumbnails": {
      return "Thumbnails carousel";
    }
    case "product-image-carousel-root": {
      return "Product images";
    }
    case "accordion-chevron": {
      return "Chevron";
    }
    case "accordion-content": {
      return "Content";
    }
    case "accordion-item": {
      return "Item";
    }
    case "accordion-root": {
      return "Accordion";
    }
    case "accordion-trigger": {
      return "Trigger";
    }
    case "option-selector-root": {
      return "Option selector";
    }
    case "option-selector-item": {
      return "Option";
    }
    case "carousel-root": {
      return "Carousel";
    }
    case "carousel-slide": {
      return "Slide";
    }
    case "quantity-picker": {
      return "Quantity picker";
    }
    case "option-dropdown": {
      return "Option dropdown";
    }
    case "icon": {
      return "Icon";
    }
    case "group": {
      return "Group";
    }
    case "liquid": {
      return "Liquid";
    }
  }
};
