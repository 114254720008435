import { AllResourcesUrlsSearch } from "@/features/sidebar/components/SelectURL/AllResourcesUrlsSearch";
import { ArticleUrlsSearch } from "@/features/sidebar/components/SelectURL/ArticleUrlsSearch";
import { BlogUrlsSearch } from "@/features/sidebar/components/SelectURL/BlogUrlsSearch";
import { CollectionUrlsSearch } from "@/features/sidebar/components/SelectURL/CollectionUrlsSearch";
import { PageUrlsSearch } from "@/features/sidebar/components/SelectURL/PageUrlsSearch";
import { PolicyUrlsSearch } from "@/features/sidebar/components/SelectURL/PoliciesSearch";
import { ProductSearch } from "@/features/sidebar/components/SelectURL/ProductSearch";
import { SelectCategory } from "@/features/sidebar/components/SelectURL/SelectCategory";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  onSelect: (url: string) => void;
  wrapperClassName?: string;
};

export type Category =
  | null
  | "products"
  | "collections"
  | "pages"
  | "blogs"
  | "articles"
  | "policies";

function SelectURLContent({
  category,
  onSelect,
  searchValue,
  onSelectCategory,
}: {
  category: Category;
  onSelect: (url: string) => void;
  searchValue: string;
  onSelectCategory: (category: Category) => void;
}) {
  const handleBack = () => {
    onSelectCategory(null);
  };

  switch (category) {
    case null: {
      if (searchValue.length > 0) {
        return (
          <AllResourcesUrlsSearch
            onSelect={onSelect}
            searchValue={searchValue}
          />
        );
      }

      return (
        <SelectCategory
          onSelectCategory={onSelectCategory}
          onSelectURL={onSelect}
        />
      );
    }
    case "collections": {
      return (
        <CollectionUrlsSearch
          onSelect={onSelect}
          searchValue={searchValue}
          onBack={handleBack}
        />
      );
    }
    case "articles": {
      return (
        <ArticleUrlsSearch
          searchValue={searchValue}
          onSelect={onSelect}
          onBack={handleBack}
        />
      );
    }
    case "blogs": {
      return (
        <BlogUrlsSearch
          onBack={handleBack}
          searchValue={searchValue}
          onSelect={onSelect}
        />
      );
    }
    case "pages": {
      return (
        <PageUrlsSearch
          onBack={handleBack}
          searchValue={searchValue}
          onSelect={onSelect}
        />
      );
    }
    case "products": {
      return (
        <ProductSearch
          onBack={handleBack}
          onSelect={onSelect}
          searchValue={searchValue}
        />
      );
    }
    case "policies": {
      return (
        <PolicyUrlsSearch
          onBack={handleBack}
          onSelect={onSelect}
          searchValue={searchValue}
        />
      );
    }
  }
}

export function SelectURL({ onSelect, wrapperClassName }: Props) {
  const [searchValue, setSearchValue] = useState("");

  const [category, setCategory] = useState<Category>(null);

  return (
    <div
      className={twMerge(
        "flex w-56 flex-col gap-1 rounded border border-gray-6 bg-gray-1 p-2",
        wrapperClassName
      )}
    >
      <input
        type="text"
        className="h-8 rounded-sm border border-gray-6 bg-gray-2 px-3 py-2 text-sm"
        placeholder="Search for a link"
        onChange={(event) => setSearchValue(event.target.value)}
        value={searchValue}
      />
      <div className="overflow-y-auto">
        <SelectURLContent
          onSelectCategory={setCategory}
          category={category}
          onSelect={onSelect}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
}
