import { SearchIcon } from "@core/features/text/Typography.icons";
import { useFonts } from "@core/features/text/api/getFonts";
import { Font } from "@core/features/text/components/Font";
import { matchSorter } from "match-sorter";
import { useDeferredValue, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";

type Props = {
  onValueChange: (value: string) => void;
};

export function FontPopoverContent({ onValueChange }: Props) {
  const { data: fonts } = useFonts();

  const [inputValue, setInputValue] = useState("");
  const deferredValue = useDeferredValue(inputValue);

  const matches = useMemo(() => {
    if (fonts === undefined) return [];

    // Exclude icon, symbol, and emoji fonts
    const excludedFonts = fonts.filter(
      (font) =>
        !font.family.toLowerCase().includes("icons") &&
        !font.family.toLowerCase().includes("symbols") &&
        !font.family.toLowerCase().includes("emoji")
    );

    const fontValues = excludedFonts.map((font) => ({
      fontFamily: font.family,
      url: font.files.regular,
    }));

    if (deferredValue === "") return fontValues;

    return matchSorter(fontValues, deferredValue, {
      keys: ["fontFamily"],
    });
  }, [deferredValue, fonts]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="flex w-48 flex-col gap-2 rounded border border-solid border-gray-6 bg-gray-1 p-2">
      <div className="relative">
        <input
          className="h-7 w-full rounded-sm border border-solid border-gray-6 bg-gray-2 pl-7 pr-2 text-sm"
          value={inputValue}
          onChange={handleInputChange}
        />
        <div className="absolute left-2 top-1/2 -translate-y-1/2">
          <SearchIcon />
        </div>
      </div>
      <FixedSizeList
        height={288}
        itemCount={matches.length}
        itemSize={24}
        width="100%"
      >
        {({ index, style }) => (
          <Font
            key={matches[index]?.fontFamily}
            fontFamily={matches[index]?.fontFamily}
            onSelect={onValueChange}
            style={style}
            url={matches[index]?.url}
          />
        )}
      </FixedSizeList>
    </div>
  );
}
