import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import {
  selectBackgroundImageOverlay,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";

function PlusIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 2.6665C8.36815 2.6665 8.66663 2.96498 8.66663 3.33317V7.33317H12.6666C13.0348 7.33317 13.3333 7.63165 13.3333 7.99984C13.3333 8.36803 13.0348 8.6665 12.6666 8.6665H8.66663V12.6665C8.66663 13.0347 8.36815 13.3332 7.99996 13.3332C7.63177 13.3332 7.33329 13.0347 7.33329 12.6665V8.6665H3.33329C2.9651 8.6665 2.66663 8.36803 2.66663 7.99984C2.66663 7.63165 2.9651 7.33317 3.33329 7.33317H7.33329V3.33317C7.33329 2.96498 7.63177 2.6665 7.99996 2.6665Z"
        fill="black"
      />
    </svg>
  );
}

function MinusIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z"
        fill="black"
      />
    </svg>
  );
}

export function BackgroundImageOverlaySettings() {
  const backgroundImageOverlay = useAppSelector(selectBackgroundImageOverlay);
  const dispatch = useAppDispatch();

  const handleAddOverlay = () => {
    dispatch(
      setSelectedLayerProperties({
        backgroundImageOverlay: "rgba(0, 0, 0, 0.25)",
      })
    );
  };

  const handleRemoveOverlay = () => {
    dispatch(setSelectedLayerProperties({ backgroundImageOverlay: null }));
  };

  const handleOverlayChange = (value: string) => {
    dispatch(setSelectedLayerProperties({ backgroundImageOverlay: value }));
  };

  return (
    <div className="flex flex-col gap-2 border-t border-gray-6 p-3">
      <div className="flex justify-between">
        <p className="text-sm font-medium text-gray-12">Overlay</p>
        {backgroundImageOverlay === null && (
          <button
            aria-label="Add image overlay"
            type="button"
            onClick={handleAddOverlay}
          >
            <PlusIcon />
          </button>
        )}
        {typeof backgroundImageOverlay === "string" && (
          <button
            type="button"
            aria-label="Remove image overlay"
            onClick={handleRemoveOverlay}
          >
            <MinusIcon />
          </button>
        )}
      </div>
      {typeof backgroundImageOverlay === "string" && (
        <ColorPicker
          onValueChange={handleOverlayChange}
          value={backgroundImageOverlay}
        />
      )}
    </div>
  );
}
