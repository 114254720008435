import { getBreakpointWidth } from "@core/utils";
import {
  DESKTOP_BREAKPOINT_WIDTH,
  MOBILE_BREAKPOINT_WIDTH,
  TABLET_BREAKPOINT_WIDTH,
} from "@folds/shared/constants";
import { Breakpoint } from "@folds/shared/types";

/**
 * Gets the closest side the layer is dropped to
 */
const getClosestSide = (
  breakpoint: Breakpoint,
  left: number
): "left" | "center" | "right" => {
  const breakpointWidth = getBreakpointWidth(breakpoint);

  const distanceFromRight = breakpointWidth - left;

  const distanceFromCenter = Math.abs(left - breakpointWidth / 2);

  const positions: { type: "right" | "left" | "center"; value: number }[] = [
    {
      type: "left",
      value: left,
    },
    {
      type: "right",
      value: distanceFromRight,
    },
    {
      type: "center",
      value: distanceFromCenter,
    },
  ];

  const lowestPosition = positions.reduce((lowest, position) => {
    if (position.value < lowest.value) return position;

    return lowest;
  });

  return lowestPosition.type;
};

/**
 * Creates a responsive left position for a new layer
 */
export const getLayerResponsiveLeftPosition = ({
  breakpoint,
  left,
  width,
}: {
  left: number;
  width: number;
  breakpoint: Breakpoint;
}): { desktop: number; tablet: number; mobile: number } => {
  const breakpointWidth = getBreakpointWidth(breakpoint);

  const side = getClosestSide(breakpoint, left);

  switch (side) {
    case "left": {
      // Calculates position relative to the breakpoint width
      const leftProportion = left / breakpointWidth;

      return {
        desktop: leftProportion * DESKTOP_BREAKPOINT_WIDTH,
        tablet: leftProportion * TABLET_BREAKPOINT_WIDTH,
        mobile: leftProportion * MOBILE_BREAKPOINT_WIDTH,
      };
    }
    case "center": {
      const layerCenter = left + width / 2;
      const distanceFromCenter = layerCenter - breakpointWidth / 2;

      const centerProportion = distanceFromCenter / breakpointWidth;

      const desktopLeftPositionToCenterElementPerfectly =
        DESKTOP_BREAKPOINT_WIDTH / 2 - width / 2;
      const tabletLeftPositionToCenterElementPerfectly =
        TABLET_BREAKPOINT_WIDTH / 2 - width / 2;
      const mobileLeftPositionToCenterElementPerfectly =
        MOBILE_BREAKPOINT_WIDTH / 2 - width / 2;

      return {
        desktop:
          desktopLeftPositionToCenterElementPerfectly +
          centerProportion * DESKTOP_BREAKPOINT_WIDTH,
        tablet:
          tabletLeftPositionToCenterElementPerfectly +
          centerProportion * TABLET_BREAKPOINT_WIDTH,
        mobile:
          mobileLeftPositionToCenterElementPerfectly +
          centerProportion * MOBILE_BREAKPOINT_WIDTH,
      };
    }
    case "right": {
      const layerRight = left + width;
      const distanceFromRight = layerRight - breakpointWidth;

      const rightProportion = distanceFromRight / breakpointWidth;

      const desktopLeftPositionToPinElementPerfectlyRight =
        DESKTOP_BREAKPOINT_WIDTH - width;
      const tabletLeftPositionToPinElementPerfectlyRight =
        TABLET_BREAKPOINT_WIDTH - width;
      const mobileLeftPositionToPinElementPerfectlyRight =
        MOBILE_BREAKPOINT_WIDTH - width;

      return {
        desktop:
          desktopLeftPositionToPinElementPerfectlyRight +
          rightProportion * DESKTOP_BREAKPOINT_WIDTH,
        tablet:
          tabletLeftPositionToPinElementPerfectlyRight +
          rightProportion * TABLET_BREAKPOINT_WIDTH,
        mobile:
          mobileLeftPositionToPinElementPerfectlyRight +
          rightProportion * MOBILE_BREAKPOINT_WIDTH,
      };
    }
  }
};
