import { Toasts } from "@folds/shared";
import { useToasts } from "@core/toast";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import * as i from "./Toast.icons";

const animateToastIn = (element: HTMLElement) => {
  element.animate(
    [
      { transform: "translateY(calc(100% + 12px))" },
      { transform: `translateY(0)` },
    ],
    {
      duration: 125,
      fill: "forwards",
    }
  );
};

function Toast({ toast: toastValue }: { toast: Toasts[string] }) {
  const { className, ...restOfProps } = toastValue.props ?? {};
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    animateToastIn(ref.current);
  }, []);

  switch (toastValue.state) {
    case "normal": {
      const icon = toastValue.customIcon ?? <i.InfoIcon />;

      return (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restOfProps}
          id={`toast-${toastValue.id}`}
          className={twMerge(
            "flex min-w-0 max-w-80 select-none items-center gap-4 break-words rounded-md border border-blue-6 bg-blue-3 px-5 py-[10px] text-base text-blue-12",
            className
          )}
          ref={ref}
        >
          {icon}
          {toastValue.message}
        </div>
      );
    }
    case "error": {
      const icon = toastValue.customIcon ?? <i.DeleteIcon />;

      return (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restOfProps}
          id={`toast-${toastValue.id}`}
          className={twMerge(
            "flex min-w-0 max-w-80 select-none items-center gap-4 break-words rounded-md border border-red-6 bg-red-3 px-5 py-[10px] text-base text-red-12",
            className
          )}
          ref={ref}
        >
          {icon}
          {toastValue.message}
        </div>
      );
    }
    case "success": {
      const icon = toastValue.customIcon ?? <i.CheckIcon />;

      return (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restOfProps}
          id={`toast-${toastValue.id}`}
          className={twMerge(
            "flex min-w-0 max-w-80 select-none items-center gap-4 break-words rounded-md border border-green-6 bg-green-3 px-5 py-[10px] text-base text-green-12",
            className
          )}
          ref={ref}
        >
          {icon}
          {toastValue.message}
        </div>
      );
    }
    case "loading": {
      const icon = toastValue.customIcon ?? <i.LoadingIcon />;

      return (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...toastValue.props}
          id={`toast-${toastValue.id}`}
          className={twMerge(
            "flex min-w-0 max-w-80 select-none items-center gap-4 break-words rounded-md border border-blue-6 bg-blue-3 px-5 py-[10px] text-base text-blue-12",
            className
          )}
          ref={ref}
        >
          {icon}
          {toastValue.message}
        </div>
      );
    }
  }
}

export function Toaster() {
  const toastsValue = useToasts();

  return (
    <div className="fixed bottom-3 left-1/2 z-[9999] flex -translate-x-1/2 flex-col items-center gap-2">
      {Object.entries(toastsValue).map(([id, currentToast]) => (
        <Toast toast={currentToast} key={id} />
      ))}
    </div>
  );
}
