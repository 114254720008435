import { LayerType, Layers, ROOT_LAYER_ID } from "@folds/shared";
import { ElementAddedEvent } from "@folds/shared/types/src/events";

const liquidLayers: Layers = {
  [ROOT_LAYER_ID]: {
    type: LayerType.Liquid,
    hasEditedDesktopPosition: false,
    hasEditedMobilePosition: false,
    hasEditedTabletPosition: false,
    id: ROOT_LAYER_ID,
    left: 0,
    top: 0,
    liquid: "",
    mobile: {},
    parentId: ROOT_LAYER_ID,
    tablet: {},
    zIndex: 0,
  },
};

export function Apps() {
  const handleDragStart = (event: React.DragEvent<HTMLButtonElement>) => {
    const dragEvent: ElementAddedEvent = {
      type: "element",
      layers: liquidLayers,
      width: 316,
    };

    event.dataTransfer.setData("application/json", JSON.stringify(dragEvent));
  };

  return (
    <div className="m-3 flex flex-1 flex-col justify-between gap-2">
      <p className="text-sm text-gray-11">
        No app integrations added yet.
        <br />
        <br />
        If you have an app integration you want us to add{" "}
        <span
          id="open-intercom"
          className="cursor-pointer select-none text-[#0091FF] underline"
        >
          please contact
        </span>{" "}
        us and we&apos;ll try our best to help you.
      </p>
      <button
        type="button"
        draggable="true"
        onDragStart={handleDragStart}
        className="absolute bottom-3 left-3 right-3 cursor-pointer rounded border border-gray-6 bg-gray-3 p-3 text-left text-sm"
      >
        Custom liquid
      </button>
    </div>
  );
}
