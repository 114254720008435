import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $addUpdateTag,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
} from "lexical";
import { useEffect } from "react";

export const SYNC_EDITOR_TAG = "SYNC_EDITOR_TAG";

const $syncPlainText = (text: string) => {
  const root = $getRoot();
  root.clear();

  const paragraph = $createParagraphNode();
  const textNode = $createTextNode(text);

  paragraph.append(textNode);

  root.append(paragraph);

  // So we can ignore the update in the update listener
  $addUpdateTag(SYNC_EDITOR_TAG);
};

export function LexicalSyncPlainTextPlugin({ text }: { text: string }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (text === "") return;

    editor.update(() => {
      const currentText = $getRoot().getTextContent();

      if (currentText === text) return;

      $syncPlainText(text);
    });
  }, [editor, text]);

  return null;
}
