import { compiledElementsSchema } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

const useFetchElements = () => {
  const axios = useAxios();

  const fetchElements = async (key: string) => {
    const response = await axios.get(key);

    return compiledElementsSchema.Decode(response.data);
  };

  return fetchElements;
};

export const useShapes = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/shapes`, fetchElements);
};

export const useAddToCartButtons = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/add-to-cart-buttons`, fetchElements);
};

export const useRedirectButtons = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/redirect-buttons`, fetchElements);
};

export const useOptionSelectors = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/option-selectors`, fetchElements);
};

export const useOptionDropdowns = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/option-dropdowns`, fetchElements);
};

export const useAccordions = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/accordions`, fetchElements);
};

export const useQuantityPickers = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/quantity-pickers`, fetchElements);
};

export const useProductImageCarousels = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/product-image-carousels`, fetchElements);
};

export const useCarousels = () => {
  const fetchElements = useFetchElements();

  return useSWR(`/elements/carousels`, fetchElements);
};

export type ElementsHook =
  | typeof useShapes
  | typeof useAddToCartButtons
  | typeof useRedirectButtons
  | typeof useOptionSelectors
  | typeof useOptionDropdowns
  | typeof useAccordions
  | typeof useQuantityPickers
  | typeof useProductImageCarousels
  | typeof useCarousels;
