import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { DialogContent } from "@lib/radix";
import { SelectPageInformation } from "@/features/dashboard/components/CreatePageDialog/SelectPageInformation";
import { CreateProductPageDialogContent } from "@/features/dashboard/components/CreatePageDialog/CreateProductPageDialogContent";
import { CreateStandalonePageDialogContent } from "@/features/dashboard/components/CreatePageDialog/CreateStandalonePageDialogContent";
import { CreateShopifyPageDialogContent } from "@/features/dashboard/components/CreatePageDialog/CreateShopifyPageDialogContent";
import { usePages } from "@/features/dashboard/api/getPages";

export type PageState =
  | "information"
  | "create-page"
  | "select-page"
  | "select-product";

function CreatePageDialogContent({
  onPageCreated,
}: {
  onPageCreated: (id: string) => void;
}) {
  const [pageType, setPageType] = useState<
    "home" | "standalone" | "product" | "not-found"
  >("home");
  const [pageName, setPageName] = useState("");
  const [pageState, setPageState] = useState<PageState>("information");

  const handleBack = () => {
    setPageState("information");
  };

  const handleCreateShopifyPageBack = () => {
    setPageState("select-page");
  };

  switch (pageState) {
    case "create-page": {
      return (
        <CreateShopifyPageDialogContent onBack={handleCreateShopifyPageBack} />
      );
    }
    case "select-page": {
      return (
        <CreateStandalonePageDialogContent
          onBack={handleBack}
          onPageCreated={onPageCreated}
          pageName={pageName}
          setPageState={setPageState}
        />
      );
    }
    case "select-product": {
      return (
        <CreateProductPageDialogContent
          onBack={handleBack}
          onPageCreated={onPageCreated}
          pageName={pageName}
        />
      );
    }
    case "information": {
      return (
        <SelectPageInformation
          onPageCreated={onPageCreated}
          setPageState={setPageState}
          pageName={pageName}
          pageType={pageType}
          setPageName={setPageName}
          setPageType={setPageType}
        />
      );
    }
  }
}

export function CreatePageDialog({
  onPageCreated,
  children,
}: {
  onPageCreated: (id: string) => void;
  children: React.ReactNode;
}) {
  const { mutate } = usePages();
  const [isDialogExpanded, setIsDialogExpanded] = useState(false);

  const handlePageCreated = async (id: string) => {
    setIsDialogExpanded(false);
    onPageCreated(id);
    await mutate();
  };

  return (
    <Dialog.Root
      modal={false}
      open={isDialogExpanded}
      onOpenChange={setIsDialogExpanded}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <div className="fixed bottom-0 left-0 right-0 top-0 z-[99] bg-black opacity-20" />
        <DialogContent className="flex w-[400px] flex-col gap-6">
          <CreatePageDialogContent onPageCreated={handlePageCreated} />
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
