import { getElement } from "@core/utils/getElement";
import { LayerId, Position } from "@folds/shared/types";

const getParentElement = (currentElement: HTMLElement): HTMLElement | null => {
  if (!currentElement.parentElement) {
    return null;
  }

  // Fixes: https://linear.app/folds/issue/FOL-427/fix-group-indicator-wrong-inside-of-slide
  if (currentElement.parentElement.assignedSlot) {
    return currentElement.parentElement.parentElement;
  }

  if (
    currentElement.parentElement.style.position === "relative" ||
    currentElement.parentElement.style.position === "absolute"
  ) {
    return currentElement.parentElement;
  }

  return getParentElement(currentElement.parentElement);
};

/**
 * Get the offset position of an element with subpixel percision
 */
export const getLayerRelativePosition = (layerId: LayerId): Position => {
  const canvasElement = getElement("canvas");
  const element = getElement(layerId);

  if (element === null || canvasElement === null) {
    return { top: 0, left: 0 };
  }

  const parentElement = getParentElement(element);

  if (parentElement === null) {
    return { top: 0, left: 0 };
  }

  const scaleString =
    canvasElement.style.transform.match(/scale\(([0-9.-]+)\)/)?.[1];

  if (scaleString === undefined) {
    return { top: 0, left: 0 };
  }

  const scale = Number(scaleString);

  const pageElementRect = canvasElement.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();

  const borderLeftWidth =
    typeof parentElement.style.borderLeftWidth === "string"
      ? Number(parentElement.style.borderLeftWidth.split("px")[0])
      : 0;

  const borderTopWidth =
    typeof parentElement.style.borderTopWidth === "string"
      ? Number(parentElement.style.borderTopWidth.split("px")[0])
      : 0;

  const elementAbsoluteTop =
    elementRect.top / scale - pageElementRect.top / scale;

  const parentAbsoluteTop =
    parentRect.top / scale - pageElementRect.top / scale;

  const elementAbsoluteLeft =
    elementRect.left / scale - pageElementRect.left / scale;

  const parentAbsoluteLeft =
    parentRect.left / scale - pageElementRect.left / scale;

  return {
    top: elementAbsoluteTop - parentAbsoluteTop - borderTopWidth,
    left: elementAbsoluteLeft - parentAbsoluteLeft - borderLeftWidth,
  };
};
