import { useDrop } from "@/features/editor/dragging/drop";
import { useDragSelect } from "@/features/editor/hooks/useDragSelect";
import { useEventConnector } from "@core/events";
import { LayoutGuide } from "@core/user-components/Block/LayoutGuide";
import { generateBlockStyles } from "@folds/shared/layers";
import { BlockLayer } from "@folds/shared/types";
import { PropsWithChildren } from "react";

type Props = {
  layer: BlockLayer;
};

export function Block({ children, layer }: PropsWithChildren<Props>) {
  const handleDrop = useDrop(layer.id);

  const dragSelect = useDragSelect();

  const eventConnector = useEventConnector(layer.id);

  const handleMouseDown = (event: React.MouseEvent) => {
    // Prevent double click from selecting text
    // https://linear.app/folds/issue/FOL-290/fix-selecting-text-when-clicking
    if (event.detail > 1) {
      event.preventDefault();
    }

    dragSelect.handleMouseDown(event);
    eventConnector.handleMouseDown(event);
  };

  const style = generateBlockStyles(layer);

  return (
    <folds-block
      id={layer.id}
      onMouseDown={handleMouseDown}
      // onMouseLeave is only tracked on breakpoints to avoid flickering when moving between blocks
      onMouseEnter={eventConnector.handleMouseEnter}
      onContextMenu={eventConnector.handleContextMenu}
      onMouseOver={eventConnector.handleMouseOver}
      aria-label="Block layer"
      // Disable height transition when resizing block in editor
      style={{ ...style, transition: "none" }}
      onDrop={handleDrop}
      data-is-editor="true"
    >
      {layer.layoutGuideEnabled && <LayoutGuide layer={layer} />}
      {children}
    </folds-block>
  );
}
