import { Box } from "@folds/shared";

type Args = {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
};

export const checkLayersAreIntersectingHorizontally = ({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: Args) => {
  const selectionBoxRight =
    selectionBoundingBox.left + selectionBoundingBox.width;
  const targetLayerBoxRight =
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  if (
    selectionBoxRight < targetLayerBoundingBox.left ||
    targetLayerBoxRight < selectionBoundingBox.left
  ) {
    return false;
  }

  return true;
};
