import { useImages } from "@/features/sidebar/api/getImages";
import { useUploadImages } from "@/features/sidebar/hooks";
import { ImageAddedEvent } from "@folds/shared/types/src/events";
import { ChangeEventHandler, useRef } from "react";
import { toast } from "@core/toast";
import Masonry from "react-responsive-masonry";
import { parseImageUrl } from "@/utils/parseImageUrl";
import { useIsInsidePlayground } from "@/features/editor/hooks/useIsInsidePlayground";

function MasonryImages({
  error,
  images,
  isLoading,
}: {
  images: string[] | undefined;
  error: unknown;
  isLoading: boolean;
}) {
  const handleDragStart = (
    event: React.DragEvent<HTMLImageElement>,
    src: string
  ) => {
    const elementEvent: ImageAddedEvent = {
      type: "image",
      src,
    };

    event.dataTransfer.setData(
      "application/json",
      JSON.stringify(elementEvent)
    );
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 gap-2">
        <div className="aspect-square animate-pulse bg-gray-4" />
        <div className="aspect-square animate-pulse bg-gray-4" />
      </div>
    );
  }

  if (error !== undefined) {
    return <p className="text-xs">There was an issue loading the images</p>;
  }

  if (images === undefined) return null;

  if (images.length === 0)
    return <p className="text-sm text-gray-11">No images uploaded yet</p>;

  const parsedImages = images.map((handle) => parseImageUrl(handle)).reverse();

  return (
    <Masonry columnsCount={2} gutter="8px">
      {parsedImages.map((src) => (
        <img
          draggable
          className="cursor-pointer"
          src={src}
          onDragStart={(event) => handleDragStart(event, src)}
          alt="User uploaded"
          key={src}
        />
      ))}
    </Masonry>
  );
}

export function Images() {
  const {
    data: images,
    mutate: revalidateImages,
    isLoading,
    error,
  } = useImages();
  const uploadImages = useUploadImages();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isInsidePlayground = useIsInsidePlayground();

  const handleClickUploadImage = () => {
    fileInputRef.current?.click();
  };

  const clearFileInput = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.value = "";
  };

  const handleUploadImage: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    if (event.target.files === null) return;

    if (isInsidePlayground) {
      toast.error(
        "Image uploads are currently not supported in the playground"
      );
      return;
    }

    await uploadImages(event.target.files, async () => {
      await revalidateImages();
      clearFileInput();
    });
  };

  return (
    <div className="flex flex-col gap-3 p-3">
      <input
        type="file"
        accept="image/*"
        className="hidden"
        ref={fileInputRef}
        onChange={handleUploadImage}
        multiple
      />
      <button
        onClick={handleClickUploadImage}
        type="button"
        className="border border-gray-6 bg-gray-3 py-3 text-center text-xs"
      >
        Upload image
      </button>
      <MasonryImages error={error} images={images} isLoading={isLoading} />
    </div>
  );
}
