import { Editor } from "@/features/editor/Editor";
import { store } from "@core/store";
import { Provider } from "react-redux";

export function Playground() {
  return (
    <Provider store={store}>
      <Editor pageId="playground" />
    </Provider>
  );
}
