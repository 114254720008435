import { Modal } from "@folds/shared";
import { useEffect, useState } from "react";

let modal: Modal | null = null;

const createEmitter = () => {
  const subscriptions = new Map();
  return {
    emit: (value: Modal) => subscriptions.forEach((fn) => fn(value)),
    subscribe: (fn: (modal: Modal) => void) => {
      const key = Symbol("key");
      subscriptions.set(key, fn);
      return () => {
        subscriptions.delete(key);
      };
    },
  };
};

const emitter = createEmitter();

export const modalStore = {
  get() {
    return modal;
  },
  getDocument() {
    return modal?.ownerDocument ?? null;
  },
  getWindow() {
    const document = modal?.ownerDocument;

    return document?.defaultView ?? null;
  },

  updateModal(newModal: Modal) {
    modal = newModal;
    emitter.emit(modal);
  },
  subscribe: (fn: (modal: Modal) => void) => emitter.subscribe(fn), // Allow components to subscribe to changes
};

export const useModal = () => {
  const [modalData, setModalData] = useState(modalStore.get());

  useEffect(() => {
    // Subscribe to modalStore updates
    const unsubscribe = modalStore.subscribe(setModalData);

    // Cleanup subscription on component unmount
    return unsubscribe;
  }, []);

  return modalData;
};

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    modalStore.updateModal(modalElement);
  }, [modalElement]);

  return <div ref={setModalElement}>{children}</div>;
}

export const useModalDocument = () => {
  const modalElement = useModal();

  return modalElement?.ownerDocument ?? null;
};

export const useModalWindow = () => {
  const document = useModalDocument();

  return document?.defaultView ?? null;
};
