import { Type, Static } from "@sinclair/typebox";

export const HomePage = Type.Object({
  id: Type.String(),
  name: Type.String(),
  createdAt: Type.String(),
  viewedAt: Type.String(),
  updatedAt: Type.String(),
  publishedAt: Type.Union([Type.String(), Type.Null()]),
  shopifyDomain: Type.String(),
});

export type HomePageType = Static<typeof HomePage>;
