import { useDrop } from "@/features/editor/dragging/drop";
import { useProduct } from "@/features/sidebar/api/getProduct";
import { useEventConnector } from "@core/events";
import { useAppSelector } from "@core/hooks";
import { RootState } from "@core/store";
import { getParentLayer } from "@core/utils/getParentLayer";
import {
  generateOptionSelectorItemStyles,
  generateOptionSelectorRootStyles,
} from "@folds/shared/layers";
import {
  LayerType,
  type OptionSelectorItemLayer,
  type OptionSelectorRootLayer,
  type LayerId,
  ShopifyProduct,
} from "@folds/shared/types";
import { createSelector } from "@reduxjs/toolkit";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";

const selectVariables = createSelector(
  [(state: RootState) => state.editor.layers, (_, id: LayerId) => id],
  (layers, id) => {
    const parentLayer = getParentLayer(layers, id);

    if (parentLayer === null) return [];

    if (parentLayer.type !== LayerType.OptionSelector.Root) return [];

    return parentLayer.variables;
  }
);

const OptionContext = createContext<{
  productId: ShopifyProduct["id"] | null;
  optionName: string | null;
}>({ optionName: null, productId: null });

export function OptionSelectorRoot({
  layer,
  children,
}: PropsWithChildren<{ layer: OptionSelectorRootLayer }>) {
  const styles = generateOptionSelectorRootStyles(layer);

  const {
    handleMouseDown,
    handleContextMenu,
    handleMouseLeave,
    handleMouseOver,
  } = useEventConnector(layer.id);

  const optionValue = useMemo(
    () => ({
      optionName: layer.optionName,
      productId: layer.productId,
    }),
    [layer.optionName, layer.productId]
  );

  return (
    <OptionContext.Provider value={optionValue}>
      <div
        style={styles}
        id={layer.id}
        aria-label="Option selector"
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
        onContextMenu={handleContextMenu}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </OptionContext.Provider>
  );
}

export const OptionValueContext = createContext<string | null>(null);

export function OptionSelectorItem({
  layer,
  children,
}: {
  layer: OptionSelectorItemLayer;
  children: React.ReactNode;
}) {
  const option = useContext(OptionContext);

  const variables = useAppSelector((state) => selectVariables(state, layer.id));

  const { data: product, error, isLoading } = useProduct(option.productId);

  const style = generateOptionSelectorItemStyles(layer);

  const {
    handleMouseDown,
    handleMouseEnter,
    handleContextMenu,
    handleMouseOver,
  } = useEventConnector(layer.id);

  const handleDrop = useDrop(layer.id);

  const selectedOption = product?.options.find(
    (currentOption) => currentOption.name === option.optionName
  );

  if (
    isLoading ||
    error !== undefined ||
    product === undefined ||
    selectedOption === undefined
  )
    return (
      <div
        id={layer.id}
        onMouseDown={handleMouseDown}
        onMouseOver={handleMouseOver}
      >
        Select an option
      </div>
    );

  const items = selectedOption.values.map((optionValue, index) => {
    const cssVariables = variables.reduce((acc, variable) => {
      const foundValueForOption = variable.values.find(
        (value) => value.optionValue === optionValue
      );

      if (variable.type === "image") {
        if (foundValueForOption === undefined) {
          return {
            ...acc,
            [`--${variable.id}`]: `url(${variable.defaultValue})`,
          };
        }

        return {
          ...acc,
          [`--${variable.id}`]: `url(${foundValueForOption.value})`,
        };
      }

      if (foundValueForOption === undefined) {
        return {
          ...acc,
          [`--${variable.id}`]: variable.defaultValue,
        };
      }

      return {
        ...acc,
        [`--${variable.id}`]: foundValueForOption.value,
      };
    }, {} as React.CSSProperties);

    if (index === 0) {
      return (
        <OptionValueContext.Provider value={optionValue} key={optionValue}>
          <div
            onMouseEnter={handleMouseEnter}
            aria-label="Option"
            style={{ ...cssVariables, ...style }}
            id={layer.id}
            onMouseDown={handleMouseDown}
            onMouseOver={handleMouseOver}
            onContextMenu={handleContextMenu}
            onDrop={handleDrop}
          >
            {children}
          </div>
        </OptionValueContext.Provider>
      );
    }

    return (
      <OptionValueContext.Provider value={optionValue} key={optionValue}>
        <div
          aria-label="Collection item"
          style={{ ...cssVariables, ...style }}
          onMouseDown={handleMouseDown}
          // We intentionally don't have onMouseOver here: https://linear.app/folds/issue/FOL-485/fix-cant-show-alt-distance-from-option-selector-wrapper
        >
          {children}
        </div>
      </OptionValueContext.Provider>
    );
  });

  return items;
}
