import { pauseHistoryAction } from "@core/features/editor/editorSlice/actions/pauseHistoryAction";
import { calculateGuideStopLines } from "@core/features/editor/editorSlice/actions/utils/calculateGuideStopLines";
import { getResizingInitialLayersInformation } from "@core/features/editor/editorSlice/actions/utils/resizing";
import type {
  DraftEditorState,
  Resizing,
} from "@core/features/editor/editorSlice/editorSlice";
import { getBoundingBoxRect } from "@core/utils";
import { Point } from "@folds/shared/types";

export const initializeResizingAction = (
  state: DraftEditorState,
  type: Resizing["type"],
  point: Point,
  shiftKey: boolean
) => {
  const boundingBoxRect = getBoundingBoxRect(state.selectedLayerIds);

  const initialLayersInformation = getResizingInitialLayersInformation({
    layers: state.layers,
    breakpoint: state.breakpoint,
    selectedLayerIds: state.selectedLayerIds,
    box: boundingBoxRect,
  });

  const guideStopLines = calculateGuideStopLines({
    layers: state.layers,
    ids: state.selectedLayerIds,
    breakpoint: state.breakpoint,
  });

  const scaledPoint = {
    x: point.x,
    y: point.y,
  };

  state.resizing = {
    shiftKey,
    type,
    initialMousePoint: scaledPoint,
    initialLayersInformation,
    guideStopLines,
    verticalGuides: [],
    horizontalGuides: [],
    box: {
      ...boundingBoxRect,
      right: boundingBoxRect.left + boundingBoxRect.width,
      bottom: boundingBoxRect.top + boundingBoxRect.height,
    },
  };

  pauseHistoryAction(state);
};
