import { useEffect, useState } from "react";
import {
  KeyboardZoomInstructions,
  TrackpadZoomInstructions,
} from "@/features/editor/components/Onboarding/ZoomInstructions";
import {
  KeyboardMoveInstructions,
  TrackpadMoveInstructions,
} from "@/features/editor/components/Onboarding/MoveInstructions";
import {
  SelectInputType,
  type InputType,
} from "@/features/editor/components/Onboarding/SelectInputType";
import { CompletedOnboarding } from "@/features/editor/components/Onboarding/CompletedOnboarding";

type PageState = "select-input-type" | "zoom" | "move-around" | "completed";

export function Onboarding() {
  const [inputType, setInputType] = useState<InputType>("mouse");
  const [pageState, setPageState] = useState<null | PageState>(null);

  useEffect(() => {
    const isOnboardingAlreadyCompleted = window.localStorage.getItem(
      "folds-onboarding-completed"
    );

    if (isOnboardingAlreadyCompleted === "true") {
      return;
    }

    setPageState("select-input-type");
  }, []);

  const handleCompleteOnboarding = () => {
    window.localStorage.setItem("folds-onboarding-completed", "true");
    setPageState(null);
  };

  const handleContinueMoveAround = () => {
    setPageState("zoom");
  };

  switch (pageState) {
    case "select-input-type": {
      return (
        <SelectInputType
          inputType={inputType}
          setInputType={setInputType}
          onContinue={() => setPageState("move-around")}
          onSkipTutorial={handleCompleteOnboarding}
        />
      );
    }
    case "move-around": {
      if (inputType === "mouse") {
        return (
          <KeyboardMoveInstructions
            onSkipTutorial={handleCompleteOnboarding}
            onContinue={handleContinueMoveAround}
          />
        );
      }

      return (
        <TrackpadMoveInstructions
          onSkipTutorial={handleCompleteOnboarding}
          onContinue={handleContinueMoveAround}
        />
      );
    }
    case "zoom": {
      if (inputType === "mouse") {
        return (
          <KeyboardZoomInstructions
            onSkipTutorial={handleCompleteOnboarding}
            onContinue={() => setPageState("completed")}
          />
        );
      }

      return (
        <TrackpadZoomInstructions
          onSkipTutorial={handleCompleteOnboarding}
          onContinue={() => setPageState("completed")}
        />
      );
    }
    case "completed": {
      return <CompletedOnboarding onComplete={handleCompleteOnboarding} />;
    }
    case null: {
      return null;
    }
  }
}
