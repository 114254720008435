import { ColorPicker } from "@/features/sidebar/components/ColorPicker";
import { Input } from "@/features/sidebar/components/Input";
import * as i from "@/features/sidebar/Sidebar.icons";
import {
  selectLayoutGuideColor,
  selectLayoutGuideColumnGap,
  selectLayoutGuideColumns,
  selectLayoutGuideEnabled,
  selectLayoutGuideHorizontalMargin,
  selectLayoutGuideMarginTop,
  selectLayoutGuideVerticalMargin,
  setSelectedLayerProperties,
} from "@core/features/editor/editorSlice";
import { useAppDispatch, useAppSelector } from "@core/hooks";
import { useState } from "react";

const parseValueChange = (value: string, callback: (value: number) => void) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) return;

  callback(parsedValue);
};

export function LayoutGuideSettings() {
  const dispatch = useAppDispatch();
  const [marginIsExpanded, setMarginIsExpanded] = useState(false);

  const layoutGuideColor = useAppSelector(selectLayoutGuideColor);
  const layoutGuideColumns = useAppSelector(selectLayoutGuideColumns);
  const layoutGuideVerticalMargin = useAppSelector(
    selectLayoutGuideVerticalMargin
  );
  const layoutGuideHorizontalMargin = useAppSelector(
    selectLayoutGuideHorizontalMargin
  );
  const layoutGuideMarginTop = useAppSelector(selectLayoutGuideMarginTop);
  const layoutGuideMarginRight = useAppSelector(selectLayoutGuideMarginTop);
  const layoutGuideMarginBottom = useAppSelector(selectLayoutGuideMarginTop);
  const layoutGuideMarginLeft = useAppSelector(selectLayoutGuideMarginTop);
  const layoutGuideColumnGap = useAppSelector(selectLayoutGuideColumnGap);
  const layoutGuideEnabled = useAppSelector(selectLayoutGuideEnabled);

  const handleColorChange = (color: string) => {
    dispatch(setSelectedLayerProperties({ layoutGuideColor: color }));
  };

  const handleChangeColumns = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideColumns: value }));
  };

  const handleChangeVerticalMargin = (value: number) => {
    dispatch(
      setSelectedLayerProperties({
        layoutGuideMarginTop: value,
        layoutGuideMarginBottom: value,
      })
    );
  };

  const handleChangeHorizontalMargin = (value: number) => {
    dispatch(
      setSelectedLayerProperties({
        layoutGuideMarginLeft: value,
        layoutGuideMarginRight: value,
      })
    );
  };

  const handleChangeTopMargin = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideMarginTop: value }));
  };

  const handleChangeBottomMargin = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideMarginBottom: value }));
  };

  const handleChangeLeftMargin = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideMarginLeft: value }));
  };

  const handleChangeRightMargin = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideMarginRight: value }));
  };

  const handleChangeColumnGap = (value: number) => {
    dispatch(setSelectedLayerProperties({ layoutGuideColumnGap: value }));
  };

  const disableLayoutGuide = () => {
    dispatch(setSelectedLayerProperties({ layoutGuideEnabled: false }));
  };

  const enableLayoutGuide = () => {
    dispatch(setSelectedLayerProperties({ layoutGuideEnabled: true }));
  };

  if (!layoutGuideEnabled) {
    return (
      <div className="flex items-center justify-between border-t border-solid border-gray-6 p-3">
        <h3 className="text-sm font-medium">Layout guide</h3>
        <button
          type="button"
          aria-label="Add layout guide"
          onClick={enableLayoutGuide}
        >
          <i.Plus />
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 border-t border-solid border-gray-6 p-3">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium">Layout guide</h3>
        <button
          type="button"
          aria-label="Add layout guide"
          onClick={disableLayoutGuide}
        >
          <i.Minus />
        </button>
      </div>
      <div className="flex flex-col gap-2">
        <ColorPicker
          onValueChange={handleColorChange}
          value={layoutGuideColor}
        />
        <div className="flex gap-2">
          <Input
            icon={<i.Columns />}
            minValue={0}
            inputType="number"
            onValueChange={(value) =>
              parseValueChange(value, handleChangeColumns)
            }
            unit={null}
            value={String(layoutGuideColumns)}
          />
          <Input
            icon={<i.ColumnSpaceBetween />}
            minValue={0}
            inputType="number"
            onValueChange={(value) =>
              parseValueChange(value, handleChangeColumnGap)
            }
            unit="px"
            value={String(layoutGuideColumnGap)}
          />
        </div>
        {!marginIsExpanded && (
          <div className="grid grid-cols-[1fr_32px] gap-[10px]">
            <div className="flex items-center gap-2">
              <Input
                icon={<i.VerticalMargin />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeVerticalMargin)
                }
                unit="px"
                value={String(layoutGuideVerticalMargin)}
              />
              <Input
                icon={<i.HorizontalMargin />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeHorizontalMargin)
                }
                unit="px"
                value={String(layoutGuideHorizontalMargin)}
              />
            </div>
            <button
              type="button"
              className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-2 fill-gray-11"
              aria-label="Expand margin siddes"
              onClick={() => setMarginIsExpanded(!marginIsExpanded)}
            >
              <i.Sides selected={false} />
            </button>
          </div>
        )}
        {marginIsExpanded && (
          <div className="grid grid-cols-[1fr_32px] gap-[10px]">
            <div className="flex items-center gap-2">
              <Input
                icon={<i.MarginTop />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeTopMargin)
                }
                unit="px"
                value={String(layoutGuideMarginTop)}
              />
              <Input
                icon={<i.MarginLeft />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeLeftMargin)
                }
                unit="px"
                value={String(layoutGuideMarginLeft)}
              />
            </div>
            <button
              type="button"
              className="flex h-8 w-8 items-center justify-center rounded-sm border border-gray-6 bg-gray-5 fill-gray-12"
              aria-label="Expand margin siddes"
              onClick={() => setMarginIsExpanded(!marginIsExpanded)}
            >
              <i.Sides selected />
            </button>
            <div className="flex items-center gap-2">
              <Input
                icon={<i.MarginBottom />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeBottomMargin)
                }
                unit="px"
                value={String(layoutGuideMarginBottom)}
              />
              <Input
                icon={<i.MarginRight />}
                minValue={0}
                inputType="number"
                onValueChange={(value) =>
                  parseValueChange(value, handleChangeRightMargin)
                }
                unit="px"
                value={String(layoutGuideMarginRight)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
