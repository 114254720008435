import { useUninstallShop } from "@/features/dashboard/api/uninstallShop";
import * as i from "@/features/dashboard/Dashboard.icons";
import { DeleteConfirmationDialog } from "@common/DeleteConfirmationDialog";

export function Sidebar() {
  const uninstall = useUninstallShop();

  return (
    <div className="fixed flex h-screen w-56 flex-col justify-between border-r border-gray-6 bg-gray-3 p-3">
      <div className="flex flex-col gap-3">
        <button
          type="button"
          className="flex h-9 items-center gap-2 rounded bg-gray-12 px-3 text-xs text-gray-1"
        >
          <i.PageIcon />
          Pages
        </button>
      </div>
      <DeleteConfirmationDialog
        confirmText="Uninstall"
        body="Are you sure you want to uninstall? This action cannot be undone."
        title="Remove installed code"
        onConfirm={uninstall}
      >
        <button
          type="button"
          className="flex w-full items-center gap-3 rounded border border-red-6 bg-red-3 px-3 py-2 text-left text-sm text-red-11"
        >
          <i.TrashIcon />
          <span>Remove installed code</span>
        </button>
      </DeleteConfirmationDialog>
    </div>
  );
}
