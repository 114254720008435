import { Box, LayerId } from "@folds/shared";
import { useAppSelector } from "@core/hooks";
import { getBoundingBoxRect } from "@core/utils";
import React, { useEffect, useState } from "react";
import { INDICATOR_SIZE } from "@/features/editor/components/AltHoverDistanceIndicator/constants";
import { roundDistance } from "@/features/editor/components/AltHoverDistanceIndicator/utils/roundDistance";

function DashedTopRightIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const top =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height - INDICATOR_SIZE;
  const left = targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const selectionBoundingBoxMiddle =
    selectionBoundingBox.left + selectionBoundingBox.width / 2;
  const width = selectionBoundingBoxMiddle - left + INDICATOR_SIZE / 2;

  const style: React.CSSProperties = {
    top,
    left,
    width,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

function DashedTopLeftIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
}) {
  const top =
    targetLayerBoundingBox.top + targetLayerBoundingBox.height - INDICATOR_SIZE;
  const left = selectionBoundingBox.left + selectionBoundingBox.width / 2;
  const width = targetLayerBoundingBox.left - left - INDICATOR_SIZE / 2;

  const style: React.CSSProperties = {
    top,
    left,
    width,
  };

  return (
    <div
      className="absolute z-50 border border-dashed border-[red]"
      style={style}
    />
  );
}

const useAboveDistance = (targetLayerId: LayerId) => {
  const layers = useAppSelector((state) => state.editor.layers);
  const selectedLayerIds = useAppSelector(
    (state) => state.editor.selectedLayerIds
  );

  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const targetLayerBoundingBox = getBoundingBoxRect([targetLayerId]);
    const selectionBoundingBox = getBoundingBoxRect(selectedLayerIds);

    const value =
      selectionBoundingBox.top -
      (targetLayerBoundingBox.top + targetLayerBoundingBox.height);

    setDistance(roundDistance(value));
  }, [layers, selectedLayerIds, targetLayerId]);

  return distance;
};

export function AboveIndicator({
  selectionBoundingBox,
  targetLayerBoundingBox,
  targetLayerId,
}: {
  selectionBoundingBox: Box;
  targetLayerBoundingBox: Box;
  targetLayerId: LayerId;
}) {
  const distance = useAboveDistance(targetLayerId);

  const indicatorLeft =
    selectionBoundingBox.left + selectionBoundingBox.width / 2;

  const top = targetLayerBoundingBox.top + targetLayerBoundingBox.height;
  const height = selectionBoundingBox.top - top;

  const indicatorStyle: React.CSSProperties = {
    top,
    left: indicatorLeft - INDICATOR_SIZE / 2,
    height,
    width: INDICATOR_SIZE,
  };

  const selectionMiddlePosition =
    selectionBoundingBox.left + selectionBoundingBox.width / 2;

  const isSelectionMiddlePositionToTheRightOfTargetLayer =
    selectionMiddlePosition >
    targetLayerBoundingBox.left + targetLayerBoundingBox.width;

  const isSelectionMiddlePositionToTheLeftOfTargetLayer =
    selectionMiddlePosition < targetLayerBoundingBox.left;

  return (
    <>
      {isSelectionMiddlePositionToTheRightOfTargetLayer && (
        <DashedTopRightIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      {isSelectionMiddlePositionToTheLeftOfTargetLayer && (
        <DashedTopLeftIndicator
          selectionBoundingBox={selectionBoundingBox}
          targetLayerBoundingBox={targetLayerBoundingBox}
        />
      )}
      <div className="absolute z-50 bg-[red]" style={indicatorStyle}>
        <p
          aria-label="above distance"
          className="absolute left-1 top-1/2 z-50 -translate-y-1/2 rounded bg-[red] px-1 text-sm text-white"
        >
          {distance}
        </p>
      </div>
    </>
  );
}
