import { getAbsolutePosition, getLayerDimensions } from "@core/utils";
import { Box, LayerId } from "@folds/shared/types";

export const checkIfSelectionIsIntersecingLayer = ({
  layerId,
  selectionBox,
}: {
  selectionBox: Box;
  layerId: LayerId;
}) => {
  const {
    height: selectionHeight,
    left: selectionLeft,
    top: selectionTop,
    width: selectionWidth,
  } = selectionBox;

  const selectionRight = selectionLeft + selectionWidth;
  const selectionBottom = selectionTop + selectionHeight;

  const { left, top } = getAbsolutePosition(layerId);

  const { height, width } = getLayerDimensions(layerId);

  const right = left + width;
  const bottom = top + height;

  // The selection is above or under the layer
  if (selectionTop > bottom || top > selectionBottom) {
    return false;
  }

  // The selection is to the left or left of the layer
  if (selectionRight < left || right < selectionLeft) {
    return false;
  }

  return true;
};
