import { useProductImages } from "@/features/sidebar/api/getProductImages";
import { useProductImagesCarouselProductId } from "@core/user-components/ProductImageCarousel/Root";
import { SwiperCarousel } from "@core/user-components/SwiperCarousel";
import {
  generateProductFeaturedImageCarouselStyles,
  generateProductFeaturedImageSlideStyles,
} from "@folds/shared/layers";
import { ProductImageCarouselFeaturedImageLayer } from "@folds/shared/types";

export function ProductImageCarouselFeaturedImage({
  layer,
}: {
  layer: ProductImageCarouselFeaturedImageLayer;
}) {
  const productId = useProductImagesCarouselProductId();

  const { data: productImages } = useProductImages(productId);

  const carouselStyle = generateProductFeaturedImageCarouselStyles(layer);
  const slideStyle = generateProductFeaturedImageSlideStyles(layer);

  if (productImages === undefined || productImages.length === 0)
    return (
      <SwiperCarousel layer={layer} style={carouselStyle}>
        <swiper-slide>
          <img
            draggable={false}
            alt="Placeholder"
            src="/image-placeholder.svg"
            style={slideStyle}
          />
        </swiper-slide>
      </SwiperCarousel>
    );

  return (
    <SwiperCarousel layer={layer} style={carouselStyle}>
      {productImages.map((src) => (
        <swiper-slide key={src}>
          <img draggable={false} alt="" src={src} style={slideStyle} />
        </swiper-slide>
      ))}
    </SwiperCarousel>
  );
}
