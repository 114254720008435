import { Type, Static } from "@sinclair/typebox";

export const NotFoundPageInput = Type.Object({
  id: Type.String(),
  name: Type.String(),
  createdAt: Type.String(),
  viewedAt: Type.String(),
  updatedAt: Type.String(),
  publishedAt: Type.Optional(Type.String()),
  shopifyDomain: Type.String(),
});

export type NotFoundPageInputType = Static<typeof NotFoundPageInput>;
