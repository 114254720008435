import { calculateSelectionIndicator } from "@/features/editor/hooks/useDragSelect/utils/calculateSelectionIndicator";
import { useAppSelector } from "@core/hooks";

export function DragSelectSeleciton() {
  const scale = useAppSelector((state) => state.editor.scale);
  const dragSelect = useAppSelector((state) => state.editor.dragSelect);

  if (dragSelect === null) return null;

  const { height, left, top, width } = calculateSelectionIndicator(
    dragSelect.initialPosition,
    dragSelect.currentPosition
  );

  return (
    <div
      className="absolute z-10 select-none border-solid"
      style={{
        top,
        left,
        width,
        height,
        background: "rgba(0, 145, 255, 0.15)",
        borderWidth: 1 / scale,
        borderColor: "#00A3FF",
      }}
    />
  );
}
