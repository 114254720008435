import { ShopifyProduct } from "@folds/shared";
import { useAxios } from "@lib/axios";
import useSWR from "swr";

type ShopifyProducts = ShopifyProduct[];

export const useAllProducts = () => {
  const axios = useAxios();

  const getResult = async (key: string) => {
    const result = await axios.get(key);

    return result.data as ShopifyProducts;
  };

  return useSWR(`/shopify/products`, getResult);
};
