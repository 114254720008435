import { ErrorBoundary } from "@sentry/react";

function Fallback() {
  return (
    <div className="absolute left-[340px] top-[65px]">
      <p>Something went wrong. Please try again or contact support.</p>
    </div>
  );
}

export function CanvasErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ErrorBoundary fallback={Fallback} showDialog>
      {children}
    </ErrorBoundary>
  );
}
