import { useFrame } from "@/features/editor/context/FrameContext";
import {
  finishAltHoverDistance,
  startAltHoverDistance,
} from "@core/features/editor/editorSlice";
import { useAppDispatch } from "@core/hooks/redux";
import { modalStore } from "@core/modalStore";
import { useCallback, useEffect } from "react";

export const useAltHoverDistanceKeyEvents = () => {
  const { document: iFrameDocument, window: iFrameWindow } = useFrame();
  const dispatch = useAppDispatch();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Alt") return;
      dispatch(startAltHoverDistance());
    },
    [dispatch]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Alt") return;

      dispatch(finishAltHoverDistance());
    },
    [dispatch]
  );

  useEffect(() => {
    iFrameDocument?.addEventListener("keydown", handleKeyDown);
    iFrameDocument?.addEventListener("keyup", handleKeyUp);

    const modalDocument = modalStore.getDocument();
    modalDocument?.addEventListener("keydown", handleKeyDown);
    modalDocument?.addEventListener("keyup", handleKeyUp);

    return () => {
      iFrameDocument?.removeEventListener("keydown", handleKeyDown);
      iFrameDocument?.removeEventListener("keyup", handleKeyUp);

      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp, iFrameDocument, iFrameWindow]);
};
