import { getChildIdsNested } from "@core/utils";
import { LayerId, Layers } from "@folds/shared/types";

export const getHighestZIndex = (layers: Layers, targetLayerId: LayerId) => {
  const deepChildren = getChildIdsNested(layers, targetLayerId);

  const highestZIndex = deepChildren.reduce((acc, id) => {
    const layer = layers[id];

    if (!layer || !("zIndex" in layer)) return acc;

    if (layer.zIndex > acc) return layer.zIndex;

    return acc;
  }, 0);

  return highestZIndex;
};
